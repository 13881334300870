/* eslint-disable no-continue */
import { put, take, call, takeLatest, select } from 'redux-saga/effects';
import * as RESULTS from '../constants/results';
import * as actions from '../actions';
import * as selectors from '../selectors';
import request, { apiRequest } from './request';
import Encryption from '../helpers/encryption';
import { REQUEST_NAME_RESULT_DOWNLOADING } from '~/appConstants';

const api = {
  reset: `${process.env.API_URL}/result/reset`,
  sendMessage: `${process.env.API_URL}/result/comment`,
  downloadResults: (lessonId, publication) =>
    `result/lesson/${lessonId}/${publication}`,
  downloadPublication: (lessonId, publication) =>
    `publication/${lessonId}/${publication}`,
};

/**
 * Загрузка урока с результатами прохождения с сервера
 */
const key = 'erdw';

export function* download() {
  while (true) {
    const {
      payload: { lessonId, publications, setLoader },
    } = yield take(RESULTS.DOWNLOAD);
    const responseResults = yield apiRequest(
      api.downloadResults(lessonId, publications),
      {},
      'get',
      {
        name: REQUEST_NAME_RESULT_DOWNLOADING,
      }
    );

    const responsePublication = yield apiRequest(
      api.downloadPublication(lessonId, publications),
      {},
      'get',
      {
        name: REQUEST_NAME_RESULT_DOWNLOADING,
      }
    );

    const encryption = new Encryption();

    if (!responsePublication) {
      continue;
    }

    const { results } = responseResults.data.payload;
    const { lesson: publication } = JSON.parse(
      encryption.decrypt(responsePublication.data.payload, key)
    );

    if (results && publication) {
      const responseLessonError = responsePublication.data.error;
      const responseResultsError = responseResults.data.error;

      if (!responseResultsError && !responseLessonError) {
        yield put(
          actions.results.downloadSuccess(lessonId, results, publication)
        );
        if (setLoader) {
          setLoader(false);
        }
      } else {
        yield put(actions.results.downloadError(responseLessonError));
      }
    }
  }
}

/**
 * Удаление результатов урока
 */
export function* reset() {
  while (true) {
    const {
      payload: { lessonId, publication },
    } = yield take(RESULTS.RESET_ACCEPT);
    const response = yield call(
      request,
      api.reset,
      { lessonId, publication },
      'post'
    );
    if (response) {
      const { error } = response.data;
      if (!error) {
        yield put(actions.results.resetSuccess(lessonId, publication));
      } else {
        yield put(actions.results.resetError(error));
      }
    }
  }
}

/**
 * Отправить сообщение в мессенджере
 */
export function* sendMessage() {
  yield takeLatest(RESULTS.SEND_MESSAGE, function*(action) {
    const state = yield select();
    const {
      resultId,
      message,
      lessonId,
      resultIndex,
      messageIndex,
    } = action.payload;
    const authorName = selectors.auth.getUserName(state);
    const comment = {
      message,
      author: authorName,
      lastModified: Math.round(new Date() / 1000),
    };
    const data = {
      id: resultId,
      comment,
    };

    const response = yield call(request, api.sendMessage, data);

    if (response) {
      const {
        data: { error },
      } = response;
      if (error) {
        yield put(actions.results.sendMessageError(error));
      } else {
        yield put(
          actions.results.sendMessageSuccess({
            comment,
            lessonId,
            resultIndex,
            messageIndex,
          })
        );
      }
    }
  });
}
