import React from 'react';
import PropTypes from 'prop-types';

import { get, some, map } from 'lodash/fp';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Controls from '~/components/Controls';
import Froala from '~/components/Froala';
import Text from '~/components/Text';
import Button from '~/components/Button';
import {
  TALK_CARD_TYPE_QUESTION,
  TALK_CARD_TYPE_INFORMATION,
} from '~/appConstants';
import * as selectors from '~/selectors';
import * as talkActions from '~/actions/builder/block/talk';
import Answer from './BuilderAnswer';
import NextCardSelector from './NextCardSelector';
import * as s from '../styles/BuilderCard.styles';
import Uploader from '~/containers/Uploader';
import UploaderLoader from '~/containers/UploaderLoader';

const FROALA_BUTTONS = [
  'paragraphFormat',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'insertTable',
  'quote',
  'formatOL',
  'formatUL',
  'indent',
  'outdent',
  'insertLink',
  'align',
  'formulaButton',
  'promptButton'
];

const BuilderCard = ({ blockId, cardId, links, i }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('containersBlock');
  const removeCardConfirm = () =>
    dispatch(talkActions.removeCardConfirm({ blockId, cardId }));
  const removeCard = () =>
    dispatch(talkActions.removeCardAccept({ blockId, cardId }));
  const changeQuestion = (question) =>
    dispatch(talkActions.changeCardQuestion({ blockId, cardId, question }));
  const changeInformation = (information) =>
    dispatch(
      talkActions.changeCardInformation({ blockId, cardId, information })
    );
  const changeCardType = (type) =>
    dispatch(talkActions.changeCardType({ blockId, cardId, type }));
  const addAnswer = () => dispatch(talkActions.addAnswer({ blockId, cardId }));
  const removeImage = () =>
    dispatch(talkActions.removeImage({ blockId, cardId }));
  const clone = () => dispatch(talkActions.cloneCard({ blockId, cardId }));
  const clearContent = () =>
    dispatch(talkActions.clearContent({ blockId, cardId }));
  const moveCardToStart = () =>
    dispatch(talkActions.moveCardToStart({ blockId, cardId }));

  const { content } = useSelector(selectors.builder.getBlock(blockId));
  const { cards, answers } = content;
  const currentCard = cards[cardId];
  const { information, question, answersIds, image, type } = currentCard;

  const imageSource = get('source', image);
  const imageStatus = get(['uploader', 'status'], image);
  const imageProgress =
    Number.parseInt(get(['uploader', 'progress'], image) * 100, 0) || 0;

  const linksElement = (
    <div visible={!!links.length} css={s.links}>
      <span css={s.linksText}>{t('talk.answersRelatedToScreen')}</span>
      <span>
        {links.map((link) => (
          <span key={link} css={s.link}>
            <a
              onClick={(e) => {
                e.preventDefault();
                const target = document.querySelector(
                  `#c${link.replace(/\S$/, '')}`
                );
                target.scrollIntoView({ behavior: 'smooth' });
              }}
              href={`#c${link.replace(/\S$/, '')}`}
            >
              {link}
            </a>
          </span>
        ))}
      </span>
    </div>
  );

  const changeCardTarget = (target) =>
    dispatch(talkActions.changeCardTarget({ blockId, cardId, target }));

  const addCard = () =>
    dispatch(talkActions.addCard({ blockId, targetedCardId: cardId }));

  const currentTargets = map(
    (answerId) => answers[answerId].target,
    answersIds
  );

  const hasRelations =
    links.length !== 0 ||
    (type === TALK_CARD_TYPE_INFORMATION && !!currentCard.target) ||
    (type === TALK_CARD_TYPE_QUESTION && some(Boolean, currentTargets));

  return (
    <div id={`c${i + 1}`} css={s.anchorContainer}>
      <s.Card>
        <div css={s.top}>
          <div css={s.topLeft}>
            <div css={s.number}>{i + 1}</div>
            <div css={s.buttonGroup}>
              <Button
                variant={[
                  'switch',
                  type === TALK_CARD_TYPE_QUESTION && 'active',
                ]}
                onClick={() => changeCardType(TALK_CARD_TYPE_QUESTION)}
                text={t('talk.dialogScreen')}
              />
              <Button
                variant={[
                  'switch',
                  type === TALK_CARD_TYPE_INFORMATION && 'active',
                ]}
                onClick={() => changeCardType(TALK_CARD_TYPE_INFORMATION)}
                text={t('talk.informationScreen')}
              />
            </div>
          </div>
          {image && imageSource ? (
            <Button
              minimal
              icon="delete"
              onClick={() => removeImage()}
              text={t('talk.removeImage')}
              variant={['in-talk-card', 'settings-image-remove']}
            />
          ) : (
            <Uploader
              variant={['in-settings', 'in-talk-card']}
              type="settings"
              icon="osh-imageupload"
              blockId={blockId}
              filePath={[blockId, 'content', 'cards', cardId, 'image']}
              title={t('talk.uploadImage')}
              fileType="image"
              uploadType="block"
            />
          )}
        </div>
        <div css={s.controls}>
          <Controls
            usePortal
            type="dropdown"
            items={[
              i !== 0 && {
                icon: 'star-empty',
                text: t('talk.makeStart'),
                action: moveCardToStart,
              },
              {
                icon: 'osh-clearcontent',
                text: t('talk.clearContent'),
                action: clearContent,
              },
              {
                icon: 'osh-duplicate',
                text: t('talk.clone'),
                action: clone,
              },
              i !== 0 && {
                icon: 'osh-trash',
                text: t('talk.remove'),
                action: hasRelations ? removeCardConfirm : removeCard,
              },
            ].filter(Boolean)}
            variant="lessons"
          />
        </div>

        {image &&
          (imageSource ? (
            <div css={s.image}>
              <img src={imageSource} alt="" />
            </div>
          ) : (
            imageStatus === 'uploading' && (
              <UploaderLoader
                icon="osh-imageupload"
                status={imageStatus}
                progress={imageProgress}
              />
            )
          ))}

        {type === TALK_CARD_TYPE_QUESTION && (
          <div>
            <Text
              placeholder={t('talk.inputQuestion')}
              multiline
              value={question}
              editable
              onChange={changeQuestion}
              css={s.question}
            />
            <div css={s.answers}>
              {answersIds.map((answerId, answerIndex) => (
                <Answer
                  i={answerIndex}
                  blockId={blockId}
                  key={answerId}
                  cardId={cardId}
                  answerId={answerId}
                />
              ))}
              {answersIds.length < 4 && (
                <Button
                  icon="plus"
                  css={s.addButton}
                  onClick={addAnswer}
                  text={t('talk.addVariantAnswer')}
                />
              )}
            </div>
            <div css="margin-top: 19px;">{linksElement}</div>
          </div>
        )}
        {type === TALK_CARD_TYPE_INFORMATION && (
          <div>
            <Froala
              mode="editor"
              placeholder={t('talk.inputText')}
              toolbarButtons={FROALA_BUTTONS}
              content={information}
              onChange={changeInformation}
              css={s.information}
            />
            <div css={s.infoBottom}>
              <Button
                css={s.next}
                variant="page-reset-quizes"
                text={t('talk.next')}
                disabled
              />
              <div>
                <NextCardSelector
                  selectedTarget={currentCard.target}
                  onChange={changeCardTarget}
                  onCreateCard={addCard}
                  blockId={blockId}
                  cardId={cardId}
                />
                <div css={s.nextText}>{t('talk.buttonAddress')}</div>
              </div>
            </div>
            <div css="margin-top: -20px;">{linksElement}</div>
          </div>
        )}
      </s.Card>
    </div>
  );
};

const { string, number, arrayOf } = PropTypes;

BuilderCard.propTypes = {
  blockId: string.isRequired,
  cardId: string.isRequired,
  i: number.isRequired,
  links: arrayOf(string),
};

BuilderCard.defaultProps = {
  links: [],
};

export default BuilderCard;
