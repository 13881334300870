import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'lodash/fp';
import { Help, Header } from '~/components';
import { NavUserBar } from '~/containers';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import LessonsScreen from './Lessons';
import CoursesScreen from './Courses';
import withSSRHandler from '~/hocs/withSSRHandler';
import Profile from '~/screens/Teach/Profile/Profile';
import EmailAlert from '~/screens/EmailAlert';

const ScreenStudy = ({ t }) => (
  <div>
    <EmailAlert />
    <Header
      items={[
        {
          text: t('teachHeader.lessonsOlympiads'),
          link: '/study',
        },
        {
          text: t('teachHeader.courses'),
          link: '/study/courses',
        },
      ]}
      displayNotifications
      navbar={<NavUserBar type="learn" />}
    />
    <Switch>
      <Route path="/study/courses" component={CoursesScreen} />
      <Route path="/study/profile" component={Profile} />
      <Route path="/study" component={LessonsScreen} />
    </Switch>
    <Help fixed variant="help-in-folder" />
  </div>
);

const mapStateToProps = (state, ownProps) => {
  const { history: router } = ownProps;
  const userId = selectors.auth.getUserId(state);

  return {
    userId,
    router,
  };
};

const { func } = PropTypes;

ScreenStudy.propTypes = {
  t: func.isRequired,
};

const mapDispatchToProps = {
  downloadResults: actions.study.results.download,
  downloadCourses: actions.study.course.download,
};

export default compose(
  withTranslation('screens'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSSRHandler(({ userId, downloadResults, location, downloadCourses }) => {
    downloadResults(userId, queryString.parse(location.search));
    downloadCourses();
  })
)(ScreenStudy);
