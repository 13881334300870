import React from 'react';
import PropTypes from 'prop-types';
import PlayerBlockComment from '~/components/Block/Comment/PlayerBlockComment';
import BuilderBlockComment from '~/components/Block/Comment/BuilderBlockComment';
import '../../../styles/Block/BlockComment.scss';

const BlockComment = ({ inPlayer, ...props }) =>
  inPlayer ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PlayerBlockComment {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BuilderBlockComment {...props} />
  );

const { bool } = PropTypes;

BlockComment.propTypes = {
  inPlayer: bool,
};

export default BlockComment;
