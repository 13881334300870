import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import * as s from '../styles/AnswerScoreInput.styles';

const AnswerScoreInput = ({ onChange, value }) => (
  <div css={s.container}>
    <Button
      css={s.button}
      minimal
      text="-"
      onClick={() => onChange(value - 1)}
    />
    <input
      type="number"
      onClick={() => {
        if (value === 0) {
          onChange('');
        }
      }}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onChange(+e.target.value)}
      css={s.input}
      value={value}
    />
    <Button
      onClick={() => onChange(value + 1)}
      css={s.button}
      minimal
      text="+"
    />
  </div>
);

const { number, func } = PropTypes;

AnswerScoreInput.propTypes = {
  onChange: func.isRequired,
  value: number.isRequired,
};

export default AnswerScoreInput;
