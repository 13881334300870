import { reduce, compose, set, split, replace, curry } from 'lodash/fp';

export const parseQueryParams = compose(
  reduce((res, string) => set(...split('=', string), res), {}),
  split('&'),
  replace(/^\?/, '')
);

export const removeQueryParam = curry((paramName, url) =>
  url.replace(new RegExp(`${paramName}=[^&]*&?`), '').replace(/(\?|&)$/, '')
);

export const addQueryParam = curry((param, url) =>
  compose(
    // eslint-disable-next-line no-shadow
    (url) => `${url}${/\?/.test(url) ? '&' : '?'}${param}`,
    removeQueryParam(param.split('=')[0])
  )(url)
);
