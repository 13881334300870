import React, { useEffect, useState } from 'react';

import { pure } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Text } from '~/components';
import '../../../styles/Profile.scss';
import { getUserName } from '~/selectors/auth';
import { DefaultButton } from '~/components/Button';
import * as s from './styles/ProfileSecurity.styles';
import { changeName } from '~/actions/auth';
import { isRequestActive } from '~/selectors/ui';
import { REQUEST_NAME_AUTH } from '~/appConstants';
import Link from '~/components/Link';

const ProfileSecurity = () => {
  const { t } = useTranslation('screens');
  const dispatch = useDispatch();

  const currentName = useSelector(getUserName);
  const [name, setName] = useState('');

  useEffect(() => {
    setName(currentName);
  }, [setName, currentName]);

  const isChanged = name !== currentName;
  const isSubmitting = useSelector(isRequestActive(REQUEST_NAME_AUTH));

  return (
    <div className="ProfileIntegration">
      <div className="text">
        <Text value={t('profile.name')} variant="profile-text" />
      </div>
      <div css={s.row}>
        <div className="field" css={s.field}>
          <div className="input">
            <Input
              large
              value={name}
              variant="profile-disabled"
              onChange={setName}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <DefaultButton
          text="Сохранить"
          variant="profile-button"
          minimal
          onClick={() => dispatch(changeName({ name }))}
          disabled={!isChanged || isSubmitting}
        />
      </div>
      <Link to="/recovery" css={s.link}>
        Изменить пароль
      </Link>
    </div>
  );
};

export default pure(ProfileSecurity);
