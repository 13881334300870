import React from 'react';
import PropTypes from 'prop-types';

import objectId from 'bson-objectid';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withState, withHandlers, pure } from 'recompose';
import { useTranslation } from 'react-i18next';

import Popover from '~/components/Popover';
import { DraggedButton as Button } from '~/components/Button';
import Text from '~/components/Text';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';
import * as actions from '../actions';

const CreateLessonButton = ({
  folderId,
  position,
  addFolder,
  addLesson,
  isInCourse,
  showTemplates,
  changeLessonType,
}) => {
  const { t } = useTranslation('containers');

  return (
    <Popover
      usePortal
      target={
        <Button icon="osh-addnew" minimal variant="plus-controls-toggler" />
      }
      content={
        <div className="create">
          <div className="title">
            <Text value={t('folder.create')} />
          </div>
          {[
            {
              text: t('folder.createLesson'),
              type: LESSON_TYPE_REGULAR,
              tooltip: t('folder.createLessonRegularHint'),
              action: () => {
                showTemplates(true);
                changeLessonType(LESSON_TYPE_REGULAR);
              },
            },
            {
              text: t('folder.createExam'),
              type: LESSON_TYPE_EXAM,
              tooltip: t('folder.createLessonExamHint'),
              action: () => {
                addLesson(LESSON_TYPE_EXAM);
              },
            },
            !isInCourse && {
              text: t('folder.createOlympiad'),
              type: LESSON_TYPE_OLYMPIAD,
              tooltip: t('folder.createLessonOlympiadHint'),
              action: () => {
                addLesson(LESSON_TYPE_OLYMPIAD);
              },
            },
          ].map(
            (item) =>
              item && (
                <div key={item.type} className="button">
                  <Button
                    minimal
                    icon={`osh-lesson-${item.type}`}
                    text={item.text}
                    onClick={item.action}
                    noDismissPopoverOnClick={item.noDismissPopoverOnClick}
                    variant={['in-create-dropdown', item.type]}
                    iconTooltipContent={item.tooltip}
                    iconTooltipPosition="right"
                  />
                </div>
              )
          )}
          {!folderId && !isInCourse && (
            <>
              <div className="divider" />
              <div className="button">
                <Button
                  minimal
                  icon="folder-close"
                  text={t('folder.createFolder')}
                  onClick={addFolder}
                  variant={['in-create-dropdown', 'folder']}
                />
              </div>
            </>
          )}
        </div>
      }
      variant="create"
      position={position}
    />
  );
};

const { string, func, oneOf, bool } = PropTypes;

CreateLessonButton.propTypes = {
  folderId: string,
  isInCourse: bool,
  addFolder: func,
  addLesson: func.isRequired,
  position: oneOf([
    // Расположение выпадающего списка контролов
    'top',
    'left',
    'right',
    'bottom',
    'rightTop',
    'topRight',
    'bottomLeft',
  ]),
  showTemplates: func.isRequired,
  changeLessonType: func.isRequired,
};

CreateLessonButton.defaultProps = {
  position: 'bottomLeft',
};

const mapDispatchToProps = {
  addLesson: actions.builder.lesson.add,
};

export default compose(
  withState('isPopoverVisible', 'togglePopoverVisible', false),
  withRouter,
  pure,
  connect(
    undefined,
    mapDispatchToProps
  ),
  withHandlers({
    close: (props) => () => props.showTemplates(false),
    addLesson: ({ addLesson, history, folderId, courseId }) => (type) => {
      const newLessonId = objectId.generate();
      addLesson({ lessonId: newLessonId, folderId, courseId, type });
      history.push(`/builder/lesson/${newLessonId}`);
    },
  })
)(CreateLessonButton);
