import memoize from 'fast-memoize';
import {
  pull,
  uniq,
  curry,
  intersection,
  isArray,
  isEmpty,
  size,
} from 'lodash/fp';

/**
 * Добавляет элемент в массив по индексу
 */
export const insertAt = curry((index, item, array) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
]);

/**
 * Поиск пересечения второго массива со первым
 */
export const intersect = memoize((firstArray, secondArray) =>
  intersection(firstArray, secondArray)
);

/**
 * Добавление элемента в конец массива
 */
export const push = curry((item, array) =>
  isArray(item) ? [...array, ...item] : [...array, item]
);

export const uniqPush = curry((item, array) =>
  isArray(item) ? uniq([...array, ...item]) : uniq([...array, item])
);

/**
 * Добавляет или удаляет элемент в массив (в зависимости от наличия)
 */
export const toggle = curry((item, array) =>
  array.includes(item) ? pull(item, array) : push(item, array)
);

export const getLastIndex = (array) => (isEmpty(array) ? 0 : size(array) - 1);
