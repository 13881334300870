import { withProps } from 'recompose';
import { isEmpty } from 'lodash/fp';

import { countTalkBlockScore } from '~/helpers/result';
import {
  BLOCK_PASS_STATUS_NOT_PASSED,
  BLOCK_PASS_STATUS_NOT_PASSED_YET,
  BLOCK_PASS_STATUS_IN_PROCESS,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
  TALK_CARD_TYPE_FINAL,
} from '~/appConstants';

export default withProps(
  ({
    isLessonComplete,
    answers,
    minimalSuccessScore,
    selectedAnswersIds,
    currentTarget,
  }) => {
    const score = countTalkBlockScore({ answers, selectedAnswersIds });

    const isBlockPassed = currentTarget?.type === TALK_CARD_TYPE_FINAL;
    const isBlockCorrectlyPassed =
      isBlockPassed && score >= minimalSuccessScore;
    const isBlockHasAnswers = !isEmpty(selectedAnswersIds);

    const blockStatus = isBlockPassed
      ? isBlockCorrectlyPassed
        ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
        : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
      : isLessonComplete
      ? BLOCK_PASS_STATUS_NOT_PASSED
      : isBlockHasAnswers
      ? BLOCK_PASS_STATUS_IN_PROCESS
      : BLOCK_PASS_STATUS_NOT_PASSED_YET;

    return {
      blockStatus,
      score,
    };
  }
);
