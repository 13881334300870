export const ADD = '@builder/lesson/Добавление урока';

export const MOVE = '@builder/lesson/Перемещение урока';

export const SAVE = '@builder/lesson/Сохранение урока';

export const CLONE = '@builder/lesson/Клонирование урока';

export const PUBLISH = '@builder/lesson/Публикация урока';

export const PUBLISH_SUCCESS = '@builder/lesson/Урок успешно опубликован';

export const PUBLISH_ERROR = '@builder/lesson/Не удалось опубликовать урок';

export const SAVE_ERROR = '@builder/lesson/Не удалось сохранить урок';

export const CHANGE_NAME = '@builder/lesson/Переименование урока';

export const SAVE_SUCCESS = '@builder/lesson/Урок успешно сохранен';

export const OPEN_SIDEBAR = '@builder/lesson/Открытие панели добавления блоков';

export const CLOSE_SIDEBAR =
  '@builder/lesson/Закрытие панели добавления блоков';

export const REMOVE_CONFIRM = '@builder/lesson/Запрос на удаление урока';

export const REMOVE_CANCEL = '@builder/lesson/Отмена удаления урока';

export const REMOVE_ACCEPT = '@builder/lesson/Подтверждение удаления урока';

export const REMOVE_ERROR = '@builder/lesson/Не удалось удалить урок';

export const REMOVE_SUCCESS = '@builder/lesson/Урок успешно удален';

export const TOGGLE_BIRD_VIEW = '@builder/lesson/Переключение режима Bird View';

export const CHANGE_TIME_LIMIT =
  '@builder/lesson/Изменение ограничения по времени';

export const TOGGLE_TIME_LIMIT =
  '@builder/lesson/Переключение режима ограничения урока по времени';

export const CHANGE_DATE_RANGE =
  '@builder/lesson/Изменение дат доступности урока';

export const CHANGE_EXTERNAL_LINK =
  '@builder/lesson/Изменение ссылки на внешний плеер';

export const TOGGLE_DATE_RANGE =
  '@builder/lesson/Переключение режима доступности урока в определенные даты';

export const IMPORT_FROM_PLAYER = '@builder/lesson/Импортирование из плеера';

export const CHANGE_DESCRIPTION = '@builder/lesson/Изменение описания урока';

export const TOGGLE_LESSON_LTI_ACCESS =
  '@builder/lesson/Переключение ограничения по LTI';

export const CHANGE_OLYMPIAD_SETTINGS =
  '@builder/lesson/Изменить настройки олимпиады';

export const ADD_OLYMPIAD_LEVELING_INTERVAL =
  '@builder/lesson/Добавить диапазон левелинга в олимпиаду';

export const REMOVE_OLYMPIAD_LEVELING_INTERVAL =
  '@builder/lesson/Удалить диапазон левелинга в олимпиаду';

export const CHANGE_MINIMAL_SUCCESS_SCORE =
  '@builder/lesson/Изменить минимальный процент прохождения';

export const DOWNLOAD_AND_CLONE = '@builder/Загрузить и клонировать урок';

export const SET_HAS_UNPUBLISHED_CHANGES =
  '@builder/Установить флаг что уроку необходима новая публикация';
