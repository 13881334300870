import { put, take, call, select } from 'redux-saga/effects';
import * as LIBRARY from '../../../constants/library';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import request from '../../request';

const api = `${process.env.API_URL}/block/save`;

const getBlock = (action, state) =>
  ({
    [LIBRARY.EDIT.SAVE]: selectors.library.getEditingBlock(null, state),
    [LIBRARY.SHARE.SAVE]: selectors.library.getSharedBlock(null, state),
  }[action.type]);

/**
 * Сохранение блока при шаринге или
 * редактировании из окна поиска по
 * библиотеке
 */
export default function* save() {
  while (true) {
    const action = yield take([LIBRARY.EDIT.SAVE, LIBRARY.SHARE.SAVE]);
    const {
      id,
      type,
      terms,
      dates,
      themes,
      subject,
      content,
      persons,
    } = yield select((state) => getBlock(action, state));
    const tags = {
      dates,
      terms,
      themes,
      persons,
    };
    const data = {
      id,
      type,
      tags,
      subject,
      content,
    };
    const response = yield call(request, api, data);
    if (response) {
      const { error, msg } = response;
      if (!error) {
        yield put(actions.library.edit.saveSuccess());
      } else {
        yield put(actions.library.edit.saveError(msg));
      }
    }
  }
}
