import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFinite, compose, trim } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { withHandlers } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as actions from '~/actions';

import withTheming from '~/hocs/withTheming';
import { Button, Overlay, Input, Text, Icon, Tooltip } from '../../components';
import { SelectLocale } from '..';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/Welcome.scss';

const OlympiadWelcome = ({
  t,
  theme,
  values,
  setFieldValue,
  isOpen,
  isMobile,
  handleSubmit,
  fontSize,
  toggleMode,
  inSafeMode,
  lessonTimeLimit,
  getFieldLabel,
  getFieldVariant,
  olympiadSettings: {
    authDescription: olympiadAuthDescription,
    authTitle: olympiadAuthTitle,
    authImage: olympiadAuthImage,
    buttonText: olympiadButtonText,
    partnersLogos: olympiadPartnersLogos,
  },
}) => (
  <Overlay isOpen={isOpen}>
    <div
      className={combineStyles('Welcome', [
        'olympiad',
        inSafeMode && 'safe-mode',
        inSafeMode && theme,
        isMobile && 'mobile',
      ])}
    >
      {olympiadAuthImage?.isShow && olympiadAuthImage?.source && (
        <div className="image">
          <img src={olympiadAuthImage.source} alt="" />
        </div>
      )}
      {olympiadPartnersLogos?.isShow && olympiadPartnersLogos?.source && (
        <div
          className={combineStyles('partners', [
            'olympiad',
            inSafeMode && theme,
          ])}
        >
          <img src={olympiadPartnersLogos.source} alt="" />
        </div>
      )}
      <div
        className={combineStyles('text', [
          'olympiad',
          inSafeMode && fontSize,
          inSafeMode && theme,
        ])}
      >
        <Text
          value={t(olympiadAuthTitle)}
          variant={combineStyles('text-head-dark', [
            'olympiad-welcome-title',
            inSafeMode && fontSize,
            inSafeMode && theme,
          ])}
        />
        {olympiadAuthDescription?.isShow && (
          <Text
            value={t(olympiadAuthDescription.text)}
            variant={combineStyles('text-head-dark', [
              'olympiad-welcome-text',
              inSafeMode && fontSize,
              inSafeMode && theme,
            ])}
          />
        )}
      </div>
      {window.innerWidth <= 450 ? ( // eslint-disable-line more/no-window
        <div
          className={combineStyles('login', [
            'olympiad',
            inSafeMode && fontSize,
          ])}
        >
          <div
            className={combineStyles('input', inSafeMode && [fontSize, theme])}
          >
            <Input
              usePortal
              value={values.name}
              label={getFieldLabel('name', 'welcome.fullnameForCertificate')}
              variant={[
                'welcome',
                'olympiad-input',
                inSafeMode && 'safe-mode',
                inSafeMode && fontSize,
                getFieldVariant('name'),
              ]}
              onChange={(value) => {
                setFieldValue('name', value);
              }}
              placeholder={t('welcome.fullnameForCertificate')}
              onPressEnter={handleSubmit}
            />
          </div>
          <div
            className={combineStyles('input', inSafeMode && [fontSize, theme])}
          >
            <Input
              usePortal
              value={values.email}
              label={getFieldLabel('email', 'welcome.emailForCertificate')}
              variant={[
                'welcome',
                'olympiad-input',
                inSafeMode && 'safe-mode',
                inSafeMode && fontSize,
                getFieldVariant('email'),
              ]}
              onChange={(value) => {
                setFieldValue('email', value);
              }}
              placeholder={t('welcome.emailForCertificate')}
              onPressEnter={handleSubmit}
            />
          </div>
          <div
            className={combineStyles('button', inSafeMode && [fontSize, theme])}
          >
            <Button
              text={t(olympiadButtonText)}
              variant={['player-welcome', inSafeMode && [fontSize, theme]]}
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : (
        <div
          className={combineStyles('login', [
            'olympiad',
            inSafeMode && fontSize,
          ])}
        >
          <div
            className={combineStyles('input', inSafeMode && [fontSize, theme])}
          >
            <Tooltip
              content={t('welcome.dataForCert')}
              variant="input-in-welcome"
              position="top"
            >
              <Input
                usePortal
                value={values.name}
                label={getFieldLabel('name', 'welcome.fullnameForCertificate')}
                variant={[
                  'welcome',
                  'olympiad-input',
                  inSafeMode && 'safe-mode',
                  inSafeMode && fontSize,
                  getFieldVariant('name'),
                ]}
                onChange={(value) => {
                  setFieldValue('name', value);
                }}
                placeholder={t('welcome.fullnameForCertificate')}
                onPressEnter={handleSubmit}
              />
            </Tooltip>
          </div>
          <div
            className={combineStyles('input', inSafeMode && [fontSize, theme])}
          >
            <Tooltip
              content={t('welcome.emailSendInfo')}
              variant="input-in-welcome"
              position="top"
            >
              <Input
                usePortal
                value={values.email}
                label={getFieldLabel('email', 'welcome.emailForCertificate')}
                variant={[
                  'welcome',
                  'olympiad-input',
                  inSafeMode && 'safe-mode',
                  inSafeMode && fontSize,
                  getFieldVariant('email'),
                ]}
                onChange={(value) => {
                  setFieldValue('email', value);
                }}
                placeholder={t('welcome.emailForCertificate')}
                onPressEnter={handleSubmit}
              />
            </Tooltip>
          </div>
          <div
            className={combineStyles('button', inSafeMode && [fontSize, theme])}
          >
            <Button
              text={t(olympiadButtonText)}
              variant={combineStyles(
                'player-welcome',
                inSafeMode && [fontSize, theme]
              )}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
      {isFinite(lessonTimeLimit) && (
        <div className="time-limit">
          <Text
            value={t('welcome.olympiadTime')}
            variant={combineStyles(
              'text-gray',
              inSafeMode && [fontSize, theme]
            )}
          />
          <div className="time">
            <Icon
              name="time"
              variant={combineStyles('gray', inSafeMode && [fontSize, theme])}
            />
            <Text
              value={`${lessonTimeLimit} мин.`}
              variant={combineStyles(
                'text-gray',
                inSafeMode && [fontSize, theme]
              )}
            />
          </div>
        </div>
      )}
      <div className="buttons-container">
        <Button
          text={
            inSafeMode
              ? t('welcome.normalVersion')
              : t('welcome.visuallyImpairedVersion')
          }
          variant={combineStyles(
            'player-toggle-mode',
            inSafeMode && 'safe-mode'
          )}
          icon="eye-open"
          onClick={toggleMode}
        />
        <SelectLocale variant="in-player-auth" />
      </div>
    </div>
  </Overlay>
);

const { bool, func, number, string, object, shape } = PropTypes;

OlympiadWelcome.propTypes = {
  t: func.isRequired,
  theme: string,
  isOpen: bool.isRequired,
  isMobile: bool.isRequired,
  handleSubmit: func.isRequired,
  setFieldValue: func.isRequired,
  values: shape({
    name: string.isRequired,
    email: string.isRequired,
  }),
  fontSize: string,
  inSafeMode: bool.isRequired,
  toggleMode: func,
  lessonTimeLimit: number,
  olympiadSettings: object,
  getFieldLabel: func.isRequired,
  getFieldVariant: func.isRequired,
};

OlympiadWelcome.defaultProps = {
  olympiadSettings: {
    authDescription: {},
    authTitle: '',
    authImage: {},
    buttonText: '',
    partnersLogos: {},
  },
};

const mapDispatchToProps = { login: actions.player.login };

export default compose(
  withTheming,
  withTranslation('containersPlayer'),
  withMobileDetect,
  connect(
    null,
    mapDispatchToProps
  ),
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
    }),
    // eslint-disable-next-line no-shadow
    handleSubmit: (values, { props: { isOlympiadMode, login } }) => {
      login({
        isOlympiadMode,
        userName: values.name,
        email: trim(values.email),
      });
      const node = document.getElementById('userbar');
      setTimeout(() => node.scrollIntoView(), 0);
      setTimeout(() => node.scrollIntoView(), 0);
    },
    validationSchema: Yup.object({
      name: Yup.string().required('welcome.enterFullname'),
      email: Yup.string()
        .trim()
        .email('welcome.enterCorrectEmail')
        .required('welcome.enterEmail'),
    }),
  }),
  withHandlers({
    getFieldLabel: ({ t, errors, touched }) => (name, defaultLabel) =>
      t(errors[name] && touched[name] ? errors[name] : defaultLabel),
    getFieldVariant: ({ errors, touched }) => (name) =>
      errors[name] && touched[name] ? 'error' : undefined,
  })
)(OlympiadWelcome);
