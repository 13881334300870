import {
  assign,
  compact,
  compose,
  defaultTo,
  entries,
  flatten,
  get,
  identity,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  mapValues,
  omitBy,
  pick,
  pickBy,
  reduce,
  size,
  sortBy,
  sum,
  values,
  method,
} from 'lodash/fp';

import {
  indetifyStatus,
  isAnswerCorrect,
  isExamCorrect,
  isMatchCorrect,
} from '~/helpers/block';

import {
  BLOCK_PASS_STATUS_IN_PROCESS,
  BLOCK_PASS_STATUS_NOT_PASSED,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
  TALK_TARGET_TYPE_FINAL,
} from '~/appConstants';

const mapIndex = map.convert({ cap: false });

const blocksStatuses = {
  notPassed: BLOCK_PASS_STATUS_NOT_PASSED,
  inProcess: BLOCK_PASS_STATUS_IN_PROCESS,
  passedCorrectly: BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  passedIncorrectly: BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
};

export const countTalkBlockScore = ({ answers, selectedAnswersIds }) =>
  compose(
    defaultTo(0),
    sum,
    map('score'),
    pick(selectedAnswersIds)
  )(answers);

export const verifyTalkBlock = ({
  block: { content } = {},
  blockResult: { selectedAnswersIds, currentTarget } = {},
}) => {
  if (currentTarget?.type !== TALK_TARGET_TYPE_FINAL) {
    return false;
  }

  const talkBlockScore = countTalkBlockScore({
    answers: content?.answers,
    selectedAnswersIds,
  });
  return talkBlockScore >= content?.minimalSuccessScore;
};

const getCount = (countName, blocks) =>
  compose(
    size,
    compact,
    values,
    pickBy(isEqual(countName)),
    reduce(assign, {})
  )(blocks);

export const verifyBlock = (block, blockResult) => {
  switch (block.type) {
    case 'Weight':
    case 'Quiz':
      return isEqual(
        sortBy(identity, get(['selectedAnswersIds'], blockResult)),
        sortBy(identity, get(['content', 'correctAnswersIds'], block))
      );
    case 'Exam':
      return isExamCorrect({
        userAnswer: get(['userAnswer'], blockResult),
        correctAnswers: get(['content', 'correctAnswers'], block),
        disorderlyCorrectAnswersIds: get(
          ['content', 'disorderlyCorrectAnswersIds'],
          block
        ),
      });
    case 'FillBlank':
      return isEqual(
        mapValues(method('toLowerCase'), get(['userAnswer'], blockResult)),
        mapValues(
          method('toLowerCase'),
          get(['content', 'correctAnswers'], block)
        )
      );
    case 'Match':
      return isMatchCorrect(
        get(['answers'], blockResult),
        get(['content', 'baskets'], block)
      );
    case 'Talk':
      return verifyTalkBlock({ block, blockResult });
    case 'Answer':
      return isAnswerCorrect(blockResult);

    default:
      return undefined;
  }
};

export const getLearners = (results = []) =>
  map(
    (item) => ({
      user: get('userName', item),
      activePage: get(['activePage'], item),
      onlineStatus: get(['onlineStatus'], item),
      lastModified: get(['lastModified'], item),
    }),
    results
  );

export const getBlocksByType = (blockTypes, results = []) =>
  map(
    compose(
      omitBy((block) => !includes(get('type', block), blockTypes)),
      get(['blocks'])
    ),
    results
  );

export const getBlocksIdsByPage = (pageId, results = []) =>
  map(get(['pages', pageId, 'blocksIds']), results);

export const getViewedBlocksIds = (results = []) =>
  map(
    compose(
      flatten,
      values,
      mapValues('displayedBlocksIds'),
      get(['pages'])
    ),
    results
  );

export const getBlocksIdsByType = (blockTypes, results = []) =>
  map(keys, getBlocksByType(blockTypes, results));

const getTotalCount = compose(
  size,
  values,
  reduce(assign, {})
);

export const getBlocksStatsByType = (blockTypes, results = [], publication) =>
  map(
    compose(
      (blocks) => ({
        isCorrect: reduce(assign, {}, blocks),
        totalCount: getTotalCount(blocks),
        correctCount: compose(
          size,
          compact,
          values,
          reduce(assign, {})
        )(blocks),
      }),
      map(([blockId, result]) => ({
        [blockId]: verifyBlock(publication.content.blocks[blockId], result),
      })),
      entries
    ),
    getBlocksByType(blockTypes, results)
  );

const checkBlockIsNotPassed = (blockType, blockResult) => {
  switch (blockType) {
    case 'Quiz':
    case 'Talk':
    case 'Weight':
      return isEmpty(get(['selectedAnswersIds'], blockResult));
    case 'Exam':
    case 'FillBlank':
      return isEmpty(get(['userAnswer'], blockResult));
    case 'Match':
      return isEmpty(get(['answers'], blockResult));
    case 'Answer':
      return isEmpty(get(['reply'], blockResult));

    default:
      return true;
  }
};

export const getLearnersStat = (
  blockTypes,
  learnerViewedBlocksIds = [],
  results = [],
  publication
) =>
  mapIndex(
    compose(
      (blocks) => ({
        blocksResult: reduce(assign, {}, blocks),
        totalCount: getTotalCount(blocks),
        passedCorrectlyCount: getCount(
          BLOCK_PASS_STATUS_PASSED_CORRECTLY,
          blocks
        ),
        passedIncorrectlyCount: getCount(
          BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
          blocks
        ),
        notPassedCount: getCount(BLOCK_PASS_STATUS_NOT_PASSED, blocks),
        inProcessCount: getCount(BLOCK_PASS_STATUS_IN_PROCESS, blocks),
      }),
      map(([blockId, block]) => ({
        [blockId]: checkBlockIsNotPassed(block.type, block)
          ? blocksStatuses.notPassed
          : block.type === 'Quiz'
          ? indetifyStatus(
              block.selectedAnswersIds,
              block.content.correctAnswersIds,
              blocksStatuses
            )
          : verifyBlock(publication.content.blocks[blockId], block)
          ? blocksStatuses.passedCorrectly
          : blocksStatuses.passedIncorrectly,
      })),
      entries,
      (blocks, index) => pick(learnerViewedBlocksIds[index], blocks)
    ),
    getBlocksByType(blockTypes, results)
  );
