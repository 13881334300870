import React from 'react';
import { bool, node } from 'prop-types';
import { pure, compose } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as s from './styles/TopAlert.styles';

const TopAlert = ({ isOpen, children }) =>
  isOpen && <div css={s.container}> {children} </div>;

TopAlert.propTypes = {
  isOpen: bool,
  children: node,
};

TopAlert.defaultProps = {
  isOpen: true,
};

export default compose(
  withMobileDetect,
  pure
)(TopAlert);
