import styled, { css } from 'styled-components';
import { BLOCK_PASS_STATUS_PASSED_CORRECTLY } from '~/appConstants';

export const Container = styled.div`
  padding: ${(props) => (props.theme.isMobile ? '0' : '30px')};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`;

export const name = css`
  font-size: 22px;
  font-family: Gilroy;
  font-weight: 600;
  white-space: pre-wrap;
`;

export const description = css`
  font-size: 18px;
  font-family: CirceRounded;
  font-weight: normal;
  line-height: 1.5;
  color: #8d8d8d;
`;

export const card = css`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;
  border: solid 1px #e5e9ec;
  padding: ${(props) => (props.theme.isMobile ? '24px 16px' : '32px 24px')};
  margin-top: 15px;
`;

export const question = css`
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 600;
  white-space: pre-wrap;
  line-height: 1.17;
  color: #45494e;
  word-break: break-word;
`;

export const image = css`
  width: ${(props) =>
    props.theme.isMobile ? 'calc(100% + 32px)' : 'calc(100% + 48px)'};
  margin: ${(props) =>
    props.theme.isMobile ? '-24px -16px 24px' : '-32px -24px 32px'};
  overflow: hidden;
  border-radius: 3px 3px 0 0;

  > img {
    display: block;
    width: 100%;
  }
`;

export const answers = css`
  width: 100%;
  display: flex;
  flex-flow: ${(props) =>
    props.theme.isMobile ? 'column nowrap' : 'row wrap'};
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.theme.isMobile ? 'flex-start' : 'space-between'};
  margin-top: 10px;
  padding-top: 1px;
`;

export const answer = css`
  border-radius: 3px;
  background-color: #f4f6f7;
  margin: 0;
  font-family: CirceRounded;
  font-size: 15px;
  color: #45494e;
  position: relative;
  width: ${(props) => (props.theme.isMobile ? '100%' : '49.25%')};
  padding: 15px 16px;
  min-height: 96px;
  margin-top: 10px;
  cursor: pointer;
  transition: background 0.2s ease;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
  flex-shrink: 0;

  :hover {
    background: #e9eef0;
    transition: background 0.2s ease;
  }
`;

export const letter = css`
  line-height: 1;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #d0d3d7;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #767b84;
  margin-right: 12px;
`;

export const status = css`
  display: block;
  margin-top: 15px;

  > .text {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: CirceRounded, Tahoma, sans-serif;
    color: #95979a;
    margin-right: 7px;
  }
`;

export const statusMarker = css`
  display: block;
  width: 30px;
  height: 7px;
  border-radius: 2px;
  margin-right: 2px;
  margin-left: 7px;
  background: ${(p) =>
    p.status === BLOCK_PASS_STATUS_PASSED_CORRECTLY ? '#8acf00' : '#f54500'};
`;
