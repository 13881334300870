import * as PLAYER from '../../constants/player';

export default (state = {}, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_LESSON_SUCCESS: {
      return action.payload.blocks;
    }
    default:
      return state;
  }
};
