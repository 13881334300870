import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from '../components';
import * as actions from '../actions';
import '../styles/Status.scss';

const VerifyBlock = ({
  t,
  text,
  reset,
  verify,
  blockId,
  isBlockInVerifyMode,
}) => (
  <div className="VerifyBlock">
    {isBlockInVerifyMode ? (
      <Button
        icon="refresh"
        text={t('verifyblock.retry')}
        variant="block-reset-quizes"
        onClick={() => reset(blockId)}
      />
    ) : (
      <Button
        icon="osh-done"
        text={text || t('verifyblock.check')}
        variant="block-verify-quizes"
        onClick={() => verify(blockId)}
      />
    )}
  </div>
);

const { bool, func, string } = PropTypes;

VerifyBlock.propTypes = {
  t: func.isRequired, // Функция перевода
  text: string, // Текст кнопки
  reset: func.isRequired, // Сбросить результаты выполнения заданий в блоке
  verify: func.isRequired, // Проверить правильные ответы заданий в блоке
  blockId: string.isRequired, // ID блока
  isBlockInVerifyMode: bool, // Блок в режиме проверки?
};
const mapDispatchToProps = {
  reset: actions.player.block.reset,
  verify: actions.player.block.verify,
};

export default compose(
  pure,
  withTranslation('containers'),
  connect(
    undefined,
    mapDispatchToProps
  )
)(VerifyBlock);
