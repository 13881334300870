import React from 'react';
import PropTypes, { string } from 'prop-types';

import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import NoSSR from 'react-no-ssr';

import withTheming from '~/hocs/withTheming';
import { combineStyles } from '../../utils/styles';
import { Page } from '..';
import { Button, Hyperlink, SocialShare } from '../../components';
import LessonHeader from '~/components/LessonHeader';

import OlympiadResult from './OlympiadResult';
import RouterLink from './RouterLink';

import * as s from './styles/PlayerIndex.styles';

const PlayerIndex = ({
  t,
  type,
  theme,
  match,
  result,
  userbar,
  fontSize,
  lessonId,
  inSafeMode,
  lessonName,
  lessonType,
  ltiReturnUrl,
  completeLesson,
  lessonPagesIds,
  isOlympiadMode,
  totalTasksCount,
  isLessonComplete,
  correctTasksCount,
  lessonDescription,
  minimalSuccessScore,
  executedBlocksCount,
  // handleReExecuteLesson,
  olympiadPartnersLogos,
  toggleShowCompleteAlert,
  olympiadDescriptionInResult,
}) => (
  <>
    {inSafeMode && <div className="safemode-stub" />}
    {userbar}
    <LessonHeader
      lessonType={lessonType}
      isOlympiadMode={isOlympiadMode}
      olympiadPartnersLogos={olympiadPartnersLogos}
      lessonName={lessonName}
      isLessonComplete={isLessonComplete}
      olympiadDescriptionInResult={olympiadDescriptionInResult}
      lessonDescription={lessonDescription}
      lessonPagesIds={lessonPagesIds}
      totalTasksCount={totalTasksCount}
      correctTasksCount={correctTasksCount}
      executedBlocksCount={executedBlocksCount}
      minimalSuccessScore={minimalSuccessScore}
    />
    {!(isOlympiadMode && isLessonComplete) && (
      <div className="pages">
        {lessonPagesIds.map((pageId) => (
          <div className="page" key={pageId}>
            <RouterLink to={`${match.url}/${pageId}`}>
              <Page id={pageId} lessonId={lessonId} inThumbnail />
            </RouterLink>
          </div>
        ))}
      </div>
    )}
    <NoSSR onSSR={<div css={s.ssrBottomStub} />}>
      {type === 'learner' ? (
        isOlympiadMode ? (
          isLessonComplete ? (
            <OlympiadResult
              result={result}
              lessonId={lessonId}
              lessonPagesIds={lessonPagesIds}
              ltiReturnUrl={ltiReturnUrl}
              bottom={(
                <SocialShare
                  shareURL={`${
                    // eslint-disable-next-line more/no-window
                    window?.location?.origin
                  }${
                    process.env.BASENAME
                  }shared-olympiad-result/${result.id}`}
                />
              )}
            />
          ) : (
            <div
              className={combineStyles(
                'send-lesson',
                inSafeMode && [fontSize, theme]
              )}
            >
              <Button
                icon="osh-done"
                text={t('index.finishAndResult')}
                variant={combineStyles('player-send-lesson', [
                  'send-olympiad',
                  inSafeMode && fontSize,
                  inSafeMode && theme,
                ])}
                onClick={
                  type === 'learner' &&
                  (() => {
                    completeLesson();
                    toggleShowCompleteAlert(true);
                  })
                }
              />
            </div>
          )
        ) : (
          <div className="send-lesson">
            {!isLessonComplete && completeLesson ? (
              <Button
                icon="osh-done"
                text={t('index.finishAndSend')}
                variant={combineStyles(
                  'player-send-lesson',
                  inSafeMode && [fontSize, theme]
                )}
                onClick={
                  type === 'learner' &&
                  (() => {
                    completeLesson();
                    toggleShowCompleteAlert(true);
                  })
                }
                disabled={executedBlocksCount < totalTasksCount}
              />
            ) : ltiReturnUrl ? (
              <Hyperlink
                newTab
                href={ltiReturnUrl}
                variant="lti-return"
                text={t('index.toLtiCabinet')}
              />
            ) : null}
          </div>
        )
      ) : null}
    </NoSSR>
  </>
);

const { any, func, node, object, oneOf, bool, number, array } = PropTypes;

PlayerIndex.propTypes = {
  t: func.isRequired,
  type: oneOf(['learner', 'teacher']),
  match: any,
  theme: any,
  result: any,
  fontSize: string,
  userbar: node,
  lessonId: string,
  inSafeMode: bool,
  lessonName: string,
  lessonType: string,
  ltiReturnUrl: string,
  completeLesson: func,
  lessonPagesIds: array,
  isOlympiadMode: bool,
  totalTasksCount: number,
  isLessonComplete: bool,
  correctTasksCount: number,
  lessonDescription: string,
  minimalSuccessScore: number,
  executedBlocksCount: number,
  // handleReExecuteLesson: func,
  olympiadPartnersLogos: object,
  toggleShowCompleteAlert: func,
  olympiadDescriptionInResult: bool,
};

PlayerIndex.defaultProps = {
  executedBlocksCount: 0,
  olympiadPartnersLogos: {},
  olympiadDescriptionInResult: true,
};

const mapStateToProps = (state) => ({
  lesson: state.player.lesson,
  result: state.player.result,
});

export default compose(
  withTranslation('containersPlayer'),
  withRouter,
  connect(mapStateToProps),
  withProps(({ lesson }) => ({
    lessonName: lesson.content.name,
    lessonId: lesson.id,
  })),
  withTheming
)(PlayerIndex);
