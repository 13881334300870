import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get, compose, isEmpty, compact } from 'lodash/fp';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withHandlers, withProps, pure } from 'recompose';
import { Button, Switch, Checkbox, Froala, Text } from '../components';
import { Status, QuizComment, BlockComment } from '../components/Block';
import { combineStyles } from '../utils/styles';
import { push } from '../utils/array';
import * as actions from '../actions';
import * as helpers from '../helpers';
import { isPageInExamMode } from '../utils/context';
import withMobileDetect from '~/hocs/withMobileDetect';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../appConstants';
import '../styles/QuizContent.scss';

// Уровень вопроса
const LEVELS = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
]

// Вес ответа
const WEIGHTS = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
]

// Группа вопроса
const GROUPS = [
  { value: 'А', label: 'А' },
  { value: 'Б', label: 'Б' },
  { value: 'В', label: 'В' },
  { value: 'Г', label: 'Г' },
  { value: 'Д', label: 'Д' },
]

const COMPETENCES = [
  { value: 'K1', label: 'K1' },
  { value: 'K2', label: 'K2' },
  { value: 'K3', label: 'K3' },
  { value: 'K4', label: 'K4' },
  { value: 'K5', label: 'K5' },
  { value: 'K6', label: 'K6' },
]

const QuizContent = ({
  t,
  type,
  theme,
  answers,
  blockId,
  comments,
  question,
  inPlayer,
  inReview,
  fontSize,
  isMobile,
  blockType,
  inBuilder,
  setAnswer,
  addAnswer,
  moveAnswer,
  toggleType,
  inSafeMode,
  answersIds,
  description,
  answerOther,
  removeAnswer,
  changeAnswer,
  selectAnswer,
  toggleComment,
  blockComments,
  changeComment,
  addAnswerOther,
  noBlockComment,
  changeQuestion,
  isOlympiadMode,
  inPresentation,
  blockPassStatus,
  inLibrarySearch,
  isPageCompleted,
  isLessonComplete,
  changeAnswerOther,
  removeAnswerOther,
  isNotRegularBlock,
  changeDescription,
  correctAnswersIds,
  answerOtherAnswer,
  isPageInVerifyMode,
  selectedAnswersIds,
  // Для весовых вопросов
  group,
  level,
  weights,
  competence,
  changeLevel,
  changeGroup,
  changeWeight,
  changeCompetence,
}) =>
  inPlayer || inPresentation ? (
    <div className="QuizContent">
      <div
        className={combineStyles(
          'question',
          inPlayer && inSafeMode && [fontSize, theme]
        )}
      >
        {question}
      </div>
      <div
        className={combineStyles(
          'description',
          inPlayer && inSafeMode && [fontSize, theme]
        )}
      >
        <Froala
          mode="preview"
          content={description}
          variant={
            inPlayer ? (inSafeMode ? [fontSize, theme] : undefined) : undefined
          }
        />
      </div>
      <div className="answers">
        {answersIds.map((answerId, answerIndex) => {
          const isAnswerOther = answerId === answerOther.id;
          return (
            <div className="answer" key={answerId}>
              <Checkbox
                theme={theme}
                inSafeMode={inSafeMode}
                commentable={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType) &&
                  !isNotRegularBlock &&
                  !isAnswerOther
                }
                fontSize={fontSize}
                key={answerId}
                type={type}
                order={answerIndex}
                variant={
                  isPageInVerifyMode || isPageCompleted || isLessonComplete
                    ? 'checkbox-verify'
                    : undefined
                }
                style={
                  {
                    Quiz: helpers.block.checkboxStyle(
                      answerId,
                      inReview,
                      inPlayer,
                      inPresentation,
                      correctAnswersIds,
                      selectedAnswersIds,
                      isNotRegularBlock
                    ),
                    Weight: selectedAnswersIds.includes(answerId)
                      ? 'checked'
                      : 'empty',
                    Survey: selectedAnswersIds.includes(answerId)
                      ? 'checked'
                      : 'empty',
                  }[blockType]
                }
                label={isAnswerOther ? t(answerOther.text) : answers[answerId]}
                checked={selectedAnswersIds.includes(answerId)}
                onCheck={selectAnswer(answerId)}
                disabled={
                  {
                    Quiz: isLessonComplete
                      ? true
                      : helpers.block.isCheckboxDisabled(
                          answerId,
                          inPlayer,
                          inReview,
                          inPresentation,
                          isPageCompleted,
                          isNotRegularBlock,
                          correctAnswersIds,
                          selectedAnswersIds
                        ),
                    Weight: isLessonComplete
                      ? true
                      : helpers.block.isCheckboxDisabled(
                          answerId,
                          inPlayer,
                          inReview,
                          inPresentation,
                          isPageCompleted,
                          isNotRegularBlock,
                          correctAnswersIds,
                          selectedAnswersIds
                        ),
                    Survey: isLessonComplete || (isPageCompleted && isPageInExamMode),
                  }[blockType] || isPageInVerifyMode
                }
              />
              {isAnswerOther && selectedAnswersIds.includes(answerOther.id) && (
                <div
                  className={combineStyles('other-answer', [
                    isMobile && 'mobile',
                  ])}
                >
                  <Text
                    variant="survey-other-answer"
                    editable={!isLessonComplete}
                    multiline
                    onChange={changeAnswerOther}
                    value={answerOtherAnswer || ''}
                    confirmOnEnterKey
                    placeholder={t('quizcontent.enterAnswer')}
                  />
                </div>
              )}
              {!isNotRegularBlock && ['Quiz', 'Weight', 'Survey'].includes(blockType) && (
                <QuizComment
                  isCommentVisible={
                    selectedAnswersIds.includes(answerId) && comments[answerId]
                  }
                  inSafeMode={inSafeMode}
                  theme={theme}
                  fontSize={fontSize}
                  content={comments[answerId]}
                />
              )}
            </div>
          );
        })}
      </div>
      <Status
        blockType={blockType}
        correctAnswersIds={correctAnswersIds}
        selectedAnswersIds={selectedAnswersIds}
        isNotRegularBlock={isNotRegularBlock}
      />
      {isOlympiadMode
        ? isLessonComplete &&
          !isEmpty(blockComments) && (
            <>
              <BlockComment
                inPlayer
                blockId={blockId}
                comments={blockComments}
                blockPassStatus={blockPassStatus}
              />
            </>
          )
        : ((!noBlockComment && blockPassStatus) || isLessonComplete) &&
          !isEmpty(blockComments) && (
            <BlockComment
              inPlayer
              blockId={blockId}
              comments={blockComments}
              blockPassStatus={
                isLessonComplete && !blockPassStatus
                  ? 'passedIncorrectly'
                  : blockPassStatus
              }
            />
          )}
    </div>
  ) : inReview ? (
    <div className="QuizContent">
      <div className="question">{question}</div>
      <div className="description">
        <Froala
          mode="preview"
          content={description}
          variant={
            inPlayer ? (inSafeMode ? [fontSize, theme] : undefined) : undefined
          }
        />
      </div>
      <div className="answers">
        {answersIds.map((answerId) => {
          const isAnswerOther = answerId === answerOther.id;
          return (
            <div className="answer" key={answerId}>
              <Checkbox
                type={type}
                variant="checkbox-read"
                commentable={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType) &&
                  !isNotRegularBlock &&
                  !isAnswerOther
                }
                style={
                  {
                    Quiz: helpers.block.checkboxStyle(
                      answerId,
                      inReview,
                      inPlayer,
                      inPresentation,
                      correctAnswersIds,
                      selectedAnswersIds,
                      isNotRegularBlock
                    ),
                    Weight: helpers.block.checkboxStyle(
                      answerId,
                      inReview,
                      inPlayer,
                      inPresentation,
                      correctAnswersIds,
                      selectedAnswersIds,
                      isNotRegularBlock
                    ),
                    Survey: selectedAnswersIds.includes(answerId)
                      ? 'checked'
                      : 'empty',
                  }[blockType]
                }
                label={isAnswerOther ? t(answerOther.text) : answers[answerId]}
                checked={
                  {
                    Quiz:
                      selectedAnswersIds.includes(answerId) ||
                      correctAnswersIds.includes(answerId),
                    Weight:
                      selectedAnswersIds.includes(answerId) ||
                      correctAnswersIds.includes(answerId),
                    Survey: selectedAnswersIds.includes(answerId),
                  }[blockType]
                }
                disabled={
                  {
                    Quiz: helpers.block.isCheckboxDisabled(
                      answerId,
                      inPlayer,
                      inReview,
                      inPresentation,
                      isPageCompleted,
                      isNotRegularBlock,
                      correctAnswersIds,
                      selectedAnswersIds
                    ),
                    Weight: helpers.block.isCheckboxDisabled(
                      answerId,
                      inPlayer,
                      inReview,
                      inPresentation,
                      isPageCompleted,
                      isNotRegularBlock,
                      correctAnswersIds,
                      selectedAnswersIds
                    ),
                    Survey: isPageCompleted && isPageInExamMode,
                  }[blockType]
                }
                readOnly
              />
              {isAnswerOther && selectedAnswersIds.includes(answerOther.id) && (
                <div className="other-answer">
                  <Text
                    variant="survey-other-answer"
                    multiline
                    value={answerOtherAnswer || ''}
                    placeholder={t('quizcontent.enterAnswer')}
                  />
                </div>
              )}
              {!isNotRegularBlock && ['Quiz', 'Survey', 'Weight'].includes(blockType) && (
                <QuizComment
                  isCommentVisible={
                    selectedAnswersIds.includes(answerId) && comments[answerId]
                  }
                  inSafeMode={inSafeMode}
                  theme={theme}
                  fontSize={fontSize}
                  content={comments[answerId]}
                />
              )}
            </div>
          );
        })}
      </div>
      <Status
        blockType={blockType}
        correctAnswersIds={correctAnswersIds}
        selectedAnswersIds={selectedAnswersIds}
        isNotRegularBlock={isNotRegularBlock}
      />
    </div>
  ) : inLibrarySearch ? (
    <div className="QuizContent">
      <div className="question">{question}</div>
      <div className="description">
        <Froala mode="preview" content={description} />
      </div>
      <div className="answers">
        {answersIds.map((answerId, answerIndex) => (
          <Checkbox
            key={answerId}
            type={type}
            order={answerIndex}
            label={answers[answerId]}
            comment={
              ['Quiz', 'Survey', 'Weight'].includes(blockType)
                ? comments[answerId]
                : undefined
            }
            checked={selectedAnswersIds.includes(answerId)}
            disabled
          />
        ))}
      </div>
    </div>
  ) : inBuilder ? (
    <div className={combineStyles('QuizContent', 'in-edit')}>
      <div className="question">
        <Text
          variant="quiz-title"
          editable
          multiline
          onChange={changeQuestion}
          value={question || ''}
          confirmOnEnterKey
          placeholder={t('quizcontent.changeQuestion')}
        />
      </div>
      <div className="description">
        <Froala
          mode="editor"
          onChange={changeDescription}
          content={description}
          placeholder={t('quizcontent.changeDescription')}
          toolbarButtons={[
            'paragraphFormat',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'insertTable',
            'quote',
            'formatOL',
            'formatUL',
            'indent',
            'outdent',
            'insertLink',
            'insertImage',
            'align',
          ]}
        />
      </div>
      {blockType === 'Weight' && (
        <div className="weights">
          <div className="group">
            <Select
              style={{ width: '200px' }}
              value={group}
              options={GROUPS}
              onChange={(option) => changeGroup(option.value)}
              placeholder="Группа вопроса"
              isClearable={false}
              closeMenuOnSelect={false}
              escapeClearsValue={false}
              backspaceRemovesValue={false}
            />
          </div>
          <div className="level">
            <Select
              style={{ width: '200px' }}
              value={level}
              options={LEVELS}
              onChange={(option) => changeLevel(option.value)}
              placeholder="Уровень вопроса"
              isClearable={false}
              escapeClearsValue={false}
              backspaceRemovesValue={false}
            />
          </div>
          <div className="competence">
            <Select
              style={{ width: '200px' }}
              value={competence}
              options={COMPETENCES}
              onChange={(option) => changeCompetence(option.value)}
              placeholder="Компетенция вопроса"
              isClearable={false}
              escapeClearsValue={false}
              backspaceRemovesValue={false}
            />
          </div>
        </div>
      )}
      {blockType !== 'Weight' && (
        <div className="toggle-type">
          <Switch
            label={
              {
                [QUIZ_BLOCK_TYPE_RADIO]:
                  blockType === 'Survey'
                    ? t('quizcontent.oneAnswer')
                    : t('quizcontent.oneTrueAnswer'),
                [QUIZ_BLOCK_TYPE_CHECKBOX]:
                  blockType === 'Survey'
                    ? t('quizcontent.multipleAnswers')
                    : t('quizcontent.multipleTrueAnswers'),
              }[type]
            }
            checked={type === QUIZ_BLOCK_TYPE_CHECKBOX}
            onChange={toggleType}
          />
        </div>
      )}
      <div className="answers">
        {answersIds.map((answerId, answerIndex) => {
          const isAnswerOther = answerId === answerOther.id;
          return (
            <div className="answer" key={answerId}>
              {blockType === 'Weight' && (
                <div className="weight">
                  <Select
                    style={{ width: '65px' }}
                    value={weights[answerId]}
                    options={WEIGHTS}
                    onChange={(option) => changeWeight(answerId, option.value)}
                    placeholder="Вес"
                    isClearable={false}
                    formatGroupLabel={(data) => <div>{data.label}</div>}
                    escapeClearsValue={false}
                    backspaceRemovesValue={false}
                  />
                </div>
              )}
              <Checkbox
                editable
                deletable
                commentable={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType) &&
                  !isNotRegularBlock &&
                  !isAnswerOther
                }
                draggable={!isAnswerOther}
                prefilled={isAnswerOther}
                blockType={blockType}
                type={type}
                order={answerIndex}
                label={isAnswerOther ? t(answerOther.text) : answers[answerId]}
                isNotRegularBlock={isNotRegularBlock}
                checked={
                  {
                    Quiz: correctAnswersIds.includes(answerId),
                    Weight: false,
                    Survey: false,
                  }[blockType]
                }
                onCheck={blockType !== 'Weight' ? setAnswer(answerId) : () => {}}
                comment={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType)
                    ? comments[answerId]
                    : undefined
                }
                onDelete={
                  isAnswerOther ? removeAnswerOther() : removeAnswer(answerId)
                }
                onDragEnd={moveAnswer}
                placeholder={t('quizcontent.enterAnswer')}
                toggleComment={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType)
                    ? () => toggleComment(answerId, comments[answerId])
                    : undefined
                }
                onLabelChange={changeAnswer(answerId)}
                onCommentChange={
                  ['Quiz', 'Survey', 'Weight'].includes(blockType)
                    ? changeComment(answerId)
                    : undefined
                }
                disabled={
                  {
                    Quiz: false,
                    Weight: true,
                    Survey: true,
                  }[blockType]
                }
              />
              {isAnswerOther && (
                <div className="other-preview">
                  <Text value={t('quizcontent.answerOtherStub')} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {blockType === 'Survey' && isEmpty(answerOther) && (
        <div className="add-answer">
          <Button
            icon="osh-addnew-little"
            text={t('quizcontent.addAnswerOther')}
            onClick={addAnswerOther}
            variant="quiz-add-button"
          />
        </div>
      )}
      <div className="add-answer">
        <Button
          icon="osh-addnew-little"
          text={t('quizcontent.addAnswer')}
          onClick={addAnswer}
          variant="quiz-add-button"
        />
      </div>
      {!noBlockComment && (
        <BlockComment blockId={blockId} comments={blockComments} />
      )}
    </div>
  ) : (
    undefined
  );

const { oneOf, arrayOf, string, bool, func, object, shape, any, number } = PropTypes;

QuizContent.propTypes = {
  t: func.isRequired,
  blockId: string, // ID блока
  type: oneOf([QUIZ_BLOCK_TYPE_RADIO, QUIZ_BLOCK_TYPE_CHECKBOX]).isRequired, // Тип теста
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  answers: object.isRequired, // Ответы
  comments: any, // Комментарии
  question: string, // Вопрос
  isMobile: bool.isRequired,
  blockType: oneOf(['Quiz', 'Survey', 'Weight']).isRequired, // Тип блока
  inPlayer: bool, // В плеере?
  inReview: bool, // В окне результатов?
  inBuilder: bool, // В конструкторе?
  addAnswer: func.isRequired, // Добавить ответ
  setAnswer: func, // Установить правильный ответ
  answersIds: arrayOf(string.isRequired).isRequired, // Порядок ответов
  moveAnswer: func.isRequired, // Переместить ответ
  toggleType: func.isRequired, // Переключить вариант теста (один или несколько ответов)
  inSafeMode: bool, // В режиме ОВЗ?
  answerOther: shape({
    id: string,
    text: string,
  }), // Ответ "Другое"
  description: string, // Описание
  selectAnswer: func.isRequired, // Выбрать ответ (для плеера)
  removeAnswer: func.isRequired, // Удалить ответ
  changeAnswer: func.isRequired, // Изменить ответ
  toggleComment: func, // Добавить комментарий к ответу
  changeComment: func, // Изменить комментарий к ответу
  blockComments: object, // Комментарии к блоку
  addAnswerOther: func.isRequired, // Добавить ответ Другое
  isOlympiadMode: bool, // В режиме олимпиады?
  changeQuestion: func.isRequired, // Изменить вопрос
  noBlockComment: bool, // Не отображать комментарий к блоку
  inPresentation: bool, // В режиме презентации?
  inLibrarySearch: bool, // В окне поиска блоков?
  blockPassStatus: string, // Статус блока - пройден/не пройден
  isPageCompleted: bool, // Задания на странице пройдены?
  isLessonComplete: bool, // Блок в режиме просмотра?
  answerOtherAnswer: string, // Ответ на вариант Другое
  changeAnswerOther: func.isRequired, // Изменение ответа в варианте Другое в плеере
  removeAnswerOther: func.isRequired, // Удалить ответ Другое
  isNotRegularBlock: bool, // Блок не находится на контрольной странице или в олимпиаде?
  changeDescription: func.isRequired, // Изменение описания
  correctAnswersIds: arrayOf(string.isRequired), // Правильные ответы
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  selectedAnswersIds: arrayOf(string.isRequired), // Выбранные ответы
  // Дял весовых вопросов
  group: string, // Группа вопроса
  level: number, // Уровень вопроса
  weights: object, // Веса ответов
  competence: string, // Компетенция вопроса
  changeLevel: func.isRequired, // Изменение уровня вопроса
  changeGroup: func.isRequired, // Изменение группы вопроса
  changeWeight: func.isRequired, // Изменение веса ответа
  changeCompetence: func.isRequired, // Изменение компетенции вопроса
};

QuizContent.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  comments: {},
  answerOther: {},
  blockComments: {},
  noBlockComment: false,
  isLessonComplete: false,
  correctAnswersIds: [],
  selectedAnswersIds: [],
  // Дял весовых вопросов
  group: null,
  level: null,
  weights: {},
  competence: null,
};

const mapDispatchToProps = {
  addAnswer: actions.builder.block.quiz.addAnswer,
  setAnswer: actions.builder.block.quiz.setAnswer,
  moveAnswer: actions.builder.block.quiz.moveAnswer,
  toggleType: actions.builder.block.quiz.toggleType,
  selectAnswer: actions.player.block.quiz.selectAnswer,
  removeAnswer: actions.builder.block.quiz.removeAnswer,
  changeAnswer: actions.builder.block.quiz.changeAnswer,
  changeComment: actions.builder.block.quiz.changeComment,
  toggleComment: actions.builder.block.quiz.toggleComment,
  changeQuestion: actions.builder.block.quiz.changeQuestion,
  addAnswerOther: actions.builder.block.quiz.addAnswerOther,
  changeAnswerOther: actions.player.block.quiz.changeAnswerOther,
  removeAnswerOther: actions.builder.block.quiz.removeAnswerOther,
  changeDescription: actions.builder.block.quiz.changeDescription,
  // Для весовых вопросов
  changeGroup: actions.builder.block.quiz.changeGroup,
  changeLevel: actions.builder.block.quiz.changeLevel,
  changeWeight: actions.builder.block.quiz.changeWeight,
  changeCompetence: actions.builder.block.quiz.changeCompetence,
};

const enhance = compose(
  withHandlers({
    addAnswer: (props) => () => props.addAnswer(props.blockId),
    addAnswerOther: ({ addAnswerOther, blockId }) => () =>
      addAnswerOther({ blockId }),
    setAnswer: (props) => (answerId) => () =>
      props.setAnswer(props.blockId, answerId),
    moveAnswer: (props) => (oldIndex, newIndex) =>
      props.moveAnswer(props.blockId, oldIndex, newIndex),
    toggleType: (props) => () => props.toggleType(props.blockId),
    selectAnswer: ({
      isPageCompleted,
      isPageInVerifyMode,
      selectAnswer,
      blockId,
      isNotRegularBlock,
      blockType,
      type,
    }) => (answerId) => () =>
      !isPageCompleted &&
      !isPageInVerifyMode &&
      selectAnswer({
        blockId,
        answerId,
        isNotRegularBlock,
        blockType,
        quizType: type,
      }),
    removeAnswer: (props) => (answerId) => () =>
      props.removeAnswer(props.blockId, answerId),
    removeAnswerOther: (props) => () => () =>
      props.removeAnswerOther(props.blockId),
    changeAnswerOther: (props) => (answer) =>
      props.changeAnswerOther(props.blockId, answer),
    changeAnswer: (props) => (answerId) => (newAnswer) =>
      props.changeAnswer(props.blockId, answerId, newAnswer),
    changeComment: (props) => (answerId) => (newComment) =>
      props.changeComment(props.blockId, answerId, newComment),
    toggleComment: (props) => (answerId, comment) =>
      props.toggleComment(props.blockId, answerId, comment),
    changeQuestion: (props) => (newQuestion) =>
      props.changeQuestion(props.blockId, newQuestion),
    changeDescription: (props) => (newDescription) =>
      props.changeDescription(props.blockId, newDescription),
    // Для весовых вопросов
    changeGroup: (props) => (newGroup) =>
      props.changeGroup(props.blockId, newGroup),
    changeLevel: (props) => (newLevel) =>
      props.changeLevel(props.blockId, newLevel),
    changeWeight: (props) => (answerId, newWeight) =>
      props.changeWeight(props.blockId, answerId, newWeight),
    changeCompetence: (props) => (newCompetence) =>
      props.changeCompetence(props.blockId, newCompetence),
  }),
  withProps(({ answersIds, answerOther }) => {
    const answersIdsWithOther = isEmpty(answerOther)
      ? answersIds
      : compose(
          compact,
          push(get('id', answerOther))
        )(answersIds);

    return { answersIds: answersIdsWithOther };
  })
);

export default compose(
  pure,
  withTranslation('containers'),
  withMobileDetect,
  connect(
    undefined,
    mapDispatchToProps
  ),
  enhance
)(QuizContent);
