import objectId from 'bson-objectid';
import { set } from 'lodash/fp';
import * as BLOCK from '../../../constants/builder/block';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../../../appConstants';

/**
 * Добавление блока Quiz или Survey
 */

export const add = (state) => (next) => (action) => {
  if (action.type === BLOCK.ADD) {
    const firstAnswer = objectId.generate();
    const secondAnswer = objectId.generate();
    const isLibraryOpen = state.getState().library.search.isOpen;
    switch (action.payload.type) {
      case 'Survey': {
        return isLibraryOpen
          ? next(action)
          : next(
              set(
                ['meta', 'content'],
                {
                  type: QUIZ_BLOCK_TYPE_RADIO,
                  answers: {
                    [firstAnswer]: '',
                    [secondAnswer]: '',
                  },
                  answersIdsOrder: [firstAnswer, secondAnswer],
                },
                action
              )
            );
      }

      case 'Weight': {
        return isLibraryOpen
          ? next(action)
          : next(
              set(
                ['meta', 'content'],
                {
                  type: QUIZ_BLOCK_TYPE_RADIO,
                  answers: {
                    [firstAnswer]: '',
                    [secondAnswer]: '',
                  },
                  comments: {},
                  answersIdsOrder: [firstAnswer, secondAnswer],
                  correctAnswersIds: [],
                },
                action
              )
            );
      }

      case 'Quiz': {
        return isLibraryOpen
          ? next(action)
          : next(
              set(
                ['meta', 'content'],
                {
                  type: QUIZ_BLOCK_TYPE_CHECKBOX,
                  answers: {
                    [firstAnswer]: '',
                    [secondAnswer]: '',
                  },
                  comments: {},
                  answersIdsOrder: [firstAnswer, secondAnswer],
                  correctAnswersIds: [],
                },
                action
              )
            );
      }

      case 'Talk': {
        return isLibraryOpen
          ? next(action)
          : next(
              set(
                ['meta', 'content'],
                {
                  minimalSuccessScore: 0,
                  cards: {
                    [firstAnswer]: {
                      id: firstAnswer,
                      question: '',
                      information: '',
                      type: 'question',
                      answersIds: [],
                    },
                  },
                  cardsIds: [firstAnswer],
                },
                action
              )
            );
      }

      default:
        return next(action);
    }
  } else {
    return next(action);
  }
};
