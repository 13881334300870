import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withContext, lifecycle } from 'recompose';
import { withTranslation } from 'react-i18next';
import 'react-select/dist/react-select.css';
import { Tag, Text, Checkbox, Icon, Dialog } from '../../components';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import '../../styles/Library/Search.scss';
import Edit from './Edit';
import Block from '~/containers/Block';

const Search = ({
  t,
  open,
  hide,
  show,
  close,
  types,
  isOpen,
  pageId,
  addBlock,
  isHidden,
  // subjects,
  selectTag,
  removeTag,
  inEditMode,
  toggleType,
  tagsFilter,
  findedTags,
  findedBlocks,
  selectedTags,
  // toggleSubject,
  isTagsLoading,
  isHintsEnabled,
  // isBlocksLoading,
  changeTagsFilter,
}) =>
  isOpen ? (
    <Dialog
      canEscapeKeyClose
      isOpen={isOpen}
      onClose={close}
      variant={[isHidden && 'hidden', 'library-modal']}
    >
      <div className={combineStyles('Search', isHidden && 'hidden')}>
        {inEditMode ? (
          <div className="content">
            <Edit variant="edit-library-block" />
          </div>
        ) : (
          <div className="content">
            <div className="sidebar">
              <div className="header">
                <div className="title">{t('search.library')}</div>
                <div className="tag">
                  <div className="input">
                    <Select
                      name="tags"
                      value={tagsFilter}
                      options={
                        tagsFilter.length >= 3
                          ? findedTags.map((tag, tagIndex) => ({
                              type: tag.type,
                              label: tag.text, // внутренний формат значений react-select
                              value: tagIndex,
                              onRemove: removeTag,
                            }))
                          : []
                      }
                      onChange={({ type, label }) =>
                        selectTag({ type, text: label })
                      }
                      clearable={false}
                      className="select"
                      isLoading={isTagsLoading}
                      placeholder={t('search.selectPlaceholder')}
                      onInputChange={changeTagsFilter}
                      noResultsText={
                        tagsFilter.length >= 3
                          ? t('search.selectNoResult')
                          : t('search.selectMinChars')
                      }
                      optionRenderer={(option) => (
                        <div className="option">
                          <div className="type">
                            <Tag type={option.type} minimal />
                          </div>
                          <div className="value">{option.label}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="hint">
                    <Text
                      value={t('search.forExample')}
                      variant="library-search-sidebar-header-hint-title"
                    />
                    <Text
                      value={t('search.searchExample')}
                      variant="library-search-sidebar-header-hint-example"
                    />
                  </div>
                </div>
              </div>
              <div className="tags">
                {selectedTags.map((tag, tagIndex) => (
                  <div className="tag" key={tagIndex}>
                    <Tag
                      type={tag.type}
                      text={tag.text}
                      index={tagIndex}
                      onRemove={removeTag}
                    />
                  </div>
                ))}
              </div>
              <div className="filters">
                <div className="types">
                  <Text
                    value={t('search.typeSearch')}
                    variant="library-search-sidebar-filters-title"
                  />
                  {[
                    [t('search.typeVideo'), 'Video'],
                    [t('search.typeQuiz'), 'Quiz'],
                    [t('search.typeText'), 'Text'],
                    [t('search.typeExam'), 'Exam'],
                    [t('search.typeAnswer'), 'Answer'],
                    [t('search.typeMatch'), 'Match'],
                    [t('search.typeWeight'), 'Weight'],
                    [t('search.typeImages'), 'Images'],
                    [t('search.typeFillBlank'), 'FillBlank'],
                  ].map(([name, type], index) => (
                    <Checkbox
                      key={index}
                      label={name}
                      checked={types.includes(type)}
                      onCheck={() => toggleType(type)}
                      variant="library-search-sidebar-filters-checkbox"
                    />
                  ))}
                </div>
                {/*
                  <div className="subjects">
                    <Text
                      value="Поиск среди предметов"
                      variant="library-search-sidebar-filters-title"
                    />
                    {['История России', 'Математика', 'Химия', 'Физика'].map((subjectName, subjectIndex) => (
                    <Checkbox
                      key={subjectIndex}
                      label={name}
                      checked={subjects.includes(subjectName)}
                      onCheck={() => toggleSubject(subjectName)}
                      variant="library-search-sidebar-filters-checkbox"
                      disabled
                    />
                  ))}
                </div>
                */}
              </div>
              <div className="info">
                <div className="icon">
                  <Icon
                    name="osh-info"
                    variant="library-search-sidebar-info-icon"
                  />
                </div>
                <Text
                  value={t('search.libraryInProgress')}
                  variant="library-search-sidebar-info-text"
                />
                <Text
                  value={t('search.courseHistory')}
                  variant="library-search-sidebar-info-text-bold"
                />
                <Text
                  value={t('search.vote')}
                  variant="library-search-sidebar-info-text"
                />
              </div>
            </div>
            <div className="blocks">
              {Object.keys(findedBlocks).map((blockId) => (
                <div className="block" key={blockId}>
                  <Block
                    id={blockId}
                    pageId={pageId}
                    onDragEnd={(blockIndex, blockPageId) => {
                      addBlock(
                        blockPageId,
                        findedBlocks[blockId].type,
                        blockIndex,
                        findedBlocks[blockId].content
                      );
                      show();
                    }}
                    onDragStart={hide}
                    onDragFinally={show}
                  />
                  <div className="tags">
                    {findedBlocks[blockId].tags.map((tag, index) => (
                      <div className="tag" key={index}>
                        <Tag type={tag.type} text={tag.text} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="close" onClick={close} />
          </div>
        )}
      </div>
    </Dialog>
  ) : (
    <div
      onClick={open}
      className={combineStyles('Search', [
        'thumbnail',
        isHintsEnabled && 'library-step',
      ])}
    >
      <div className="background">{t('search.library')}</div>
    </div>
  );

const { bool, func, array, string, object } = PropTypes;

Search.propTypes = {
  t: func.isRequired, // Функция перевода
  open: func.isRequired, // Открыть модальное окно
  hide: func.isRequired, // Скрыть модальное окно
  show: func.isRequired, // Показать модальное окно
  close: func.isRequired, // Закрыть модальное окно
  types: array.isRequired, // Типы блоков для поиска
  isOpen: bool.isRequired, // Окно поиска открыто?
  pageId: string, // ID страницы на которую будет добавлен блок
  isHidden: bool.isRequired, // Модальное окно скрыто?
  // subjects: array.isRequired, // Предметы блоков для поиска
  addBlock: func.isRequired, // Вставить найденный блок в конструктор
  selectTag: func.isRequired, // Выбрать тэг
  removeTag: func.isRequired, // Удалить тэг
  inEditMode: bool.isRequired, // В режиме редактирования блока?
  toggleType: func.isRequired, // Переключить тип блоков для поиска
  tagsFilter: string.isRequired, // Поле поиска тегов
  findedTags: array.isRequired, // Найденные по условиям тэги
  selectedTags: array.isRequired, // Тэги выбранные для фильтрации поиска по блокам
  findedBlocks: object.isRequired, // Найденные блоки
  isHintsEnabled: bool, // Подсказки по интерфейсу включены?
  // toggleSubject: func.isRequired, // Переключить предметы блоков для поиска
  isTagsLoading: bool.isRequired, // Идет загрузка тэгов?
  // isBlocksLoading: bool.isRequired, // Идет загрузка блоков?
  changeTagsFilter: func.isRequired, // Изменить поле поиска тэгов
};

Search.defaultProps = {
  pageId: undefined,
  isHintsEnabled: false,
};

const mapStateToProps = (state, { lessonId }) => {
  if (state.builder.lessons[lessonId]) {
    if (!state.builder.lessons[lessonId].content.pagesIds) {
      // eslint-disable-next-line no-console
      console.error('Урок в старом формате');
    }
  }
  return {
    types: state.library.search.types,
    pageId:
      state.builder.lessons[lessonId] &&
      state.builder.lessons[lessonId].content.pagesIds[0],
    isOpen: state.library.search.isOpen,
    subjects: state.library.search.subjects,
    isHidden: state.library.search.isHidden,
    tagsFilter: state.library.search.tags.filter,
    inEditMode: state.library.search.inEditMode,
    findedTags: state.library.search.tags.finded,
    selectedTags: state.library.search.tags.selected,
    findedBlocks: state.library.search.blocks.finded,
    isTagsLoading: state.library.search.tags.isLoading,
    isHintsEnabled: selectors.builder.isHintsEnabled(state),
    isBlocksLoading: state.library.search.blocks.isLoading,
  };
};

const mapDispatchToProps = {
  open: actions.library.search.open,
  show: actions.library.search.show,
  hide: actions.library.search.hide,
  close: actions.library.search.close,
  addBlock: actions.builder.block.add,
  selectTag: actions.library.search.selectTag,
  removeTag: actions.library.search.removeTag,
  toggleType: actions.library.search.toggleType,
  toggleSubject: actions.library.search.toggleSubject,
  changeTagsFilter: actions.library.search.changeTagsFilter,
};

const enhance = compose(
  withContext(
    {
      inLibraryEdit: bool.isRequired,
      inLibrarySearch: bool.isRequired,
    },
    ({ isOpen, inEditMode }) => ({
      inLibraryEdit: isOpen && inEditMode,
      inLibrarySearch: isOpen && !inEditMode,
    })
  ),
  lifecycle({
    componentDidUpdate() {
      // eslint-disable-next-line fp/no-this
      const { isOpen, isHidden } = this.props;
      if ((isOpen && !isHidden) || !isOpen) {
        // eslint-disable-next-line fp/no-mutation
        document.body.style.overflow = 'hidden';
        // eslint-disable-next-line fp/no-mutation
        document.body.classList.remove('librarySlided');
      } else {
        // eslint-disable-next-line fp/no-mutation
        document.body.style.overflow = 'visible';
        // eslint-disable-next-line fp/no-mutation
        document.body.classList.add('librarySlided');
      }
    },
  })
);

export default compose(
  withTranslation('containersLibrary'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Search);
