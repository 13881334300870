import styled, { keyframes } from 'styled-components';
import { prop } from 'styled-tools';

const animation = keyframes`
  0% {
    opacity: 0.45;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.5, 1.5);
  }

  100% {
    opacity: 0.45;
    transform: scale(1, 1);
  }
`;

const duration = 0.9;
const delay = 0.25;

export const Container = styled.div`
  text-align: center;
  width: 100%;

  .dot {
    animation: ${animation} ${duration}s infinite linear;
    background-color: ${prop('color', '#767b84')};
    border-radius: ${prop('size')}px;
    display: inline-block;
    height: ${prop('size')}px;
    width: ${prop('size')}px;
    margin-right: ${prop('size')}px;
    opacity: 0.3;

    &:nth-child(2) {
      animation-delay: ${duration * delay}s;
    }

    &:nth-child(3) {
      animation-delay: ${duration * delay * 2}s;
      margin-right: 0;
    }
  }
`;
