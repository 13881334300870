import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash/fp';
import { withState, compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/MoveTo.scss';
import { DraggedButton as Button } from '~/components/Button';
import Text from '~/components/Text';
import Icon from '~/components/Icon';

const MoveTo = ({
  t,
  close,
  variant,
  courses,
  lessonId,
  courseId,
  isMobile,
  cloneLesson,
  selectCourse,
  currentCourse,
}) => (
  <div className={combineStyles('MoveTo', [isMobile, variant])}>
    <div className="header">
      <Text
        value={t(`moveTo.${courseId ? 'myLessons' : 'courses'}`)}
        variant="moveto-header"
      />
      <Button
        minimal
        icon="osh-remove"
        onClick={close}
        variant="moveto-close"
      />
    </div>
    <div className="content">
      {courseId ? (
        <div
          className={combineStyles(
            'item',
            currentCourse === 'myLessons' && 'active'
          )}
          onClick={() => selectCourse('myLessons')}
        >
          <Text value={t('moveTo.myLessons')} variant="moveto-header" />
        </div>
      ) : (
        map(
          (course) => (
            <div
              key={course.id}
              className={combineStyles(
                'item',
                currentCourse === course.id && 'active'
              )}
              onClick={() => selectCourse(course.id)}
            >
              <Icon name="osh-course" variant="course-list-item" />
              <Text value={course.name} variant="moveto-course-item" />
            </div>
          ),
          courses
        )
      )}
    </div>
    <div className="button">
      <Button
        minimal
        disabled={!currentCourse}
        text={t('moveTo.select')}
        variant="moveto-select"
        onClick={() =>
          cloneLesson({
            id: lessonId,
            courseId: courseId ? undefined : currentCourse,
            notOpenAfterClone: true,
          })
        }
      />
    </div>
  </div>
);

const { func, string, oneOfType, array, object, bool } = PropTypes;

MoveTo.propTypes = {
  t: func, // Функция перевода
  close: func.isRequired, // Закрыть окно результатов
  variant: oneOfType([array, string]), // Вариант оформления
  isMobile: bool.isRequired,
  courses: object, // Курсы пользвоателя
  lessonId: string, // ID урока
  courseId: string,
  cloneLesson: func.isRequired, // Клонирование урока в курс
  selectCourse: func, // Выбрать курс
  currentCourse: string, // Выбранный курс
};

MoveTo.defaultProps = {
  t: false,
  variant: undefined,
  courses: {},
  lessonId: undefined,
  courseId: undefined,
  selectCourse: undefined,
  currentCourse: undefined,
};

const mapStateToProps = (state) => ({
  courses: selectors.builder.getCourses(state),
});

const mapDispatchToProps = {
  cloneLesson: actions.builder.lesson.clone,
};

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  withState('currentCourse', 'selectCourse', undefined),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MoveTo);
