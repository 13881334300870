import { get } from 'lodash/fp';
import { getBlock } from './main';

export const getContentSource = (blockId, state) =>
  get(['content', 'source'], getBlock(blockId, state));

export const getContentFileName = (blockId, state) =>
  get(['content', 'fileName'], getBlock(blockId, state));

export const getContentIsProtected = (blockId, state) =>
  get(['content', 'isProtected'], getBlock(blockId, state));

export const getContentUploaderStatus = (blockId, state) =>
  get(['content', 'uploader', 'status'], getBlock(blockId, state));

export const getContentUploaderProgress = (blockId, state) =>
  get(['content', 'uploader', 'progress'], getBlock(blockId, state));
