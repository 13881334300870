import {
  get,
  map,
  prop,
  size,
  pick,
  curry,
  getOr,
  merge,
  omitBy,
  pickBy,
  reduce,
  compose,
  isEmpty,
} from 'lodash/fp';

import { oneOf } from '~/utils/fp';
import { verifyBlock } from '~/helpers/result';
import { taskTypes } from '~/appConstants';

export const pickNonExamPages = omitBy(prop('inExamMode'));

export const pickPageBlocks = curry((state, { id }) => {
  const pageResult = getOr({}, ['result', 'pages', id], state.player);
  const { displayedBlocksIds } = pageResult;
  return pick(displayedBlocksIds, state.player.blocks);
});

export const pickTaskBlocks = (blocks) =>
  pickBy(
    compose(
      oneOf(taskTypes),
      prop('type')
    ),
    blocks
  );

const pickCorrectTasks = (state, blocks) => {
  const blocksResults = getOr({}, ['result', 'blocks'], state.player);

  return pickBy(
    (block, blockId) => verifyBlock(block, blocksResults[blockId]),
    blocks
  );
};

const getLessonTotalTasks = (state, selectedIds) => {
  const lessonPagesIds = getOr(
    [],
    ['content', 'pagesIds'],
    state.player.lesson
  );

  const lessonPages = pick(lessonPagesIds, state.player.pages);
  const nonExamPages = pickNonExamPages(lessonPages);
  const lessonBlocks = compose(
    reduce(merge, {}),
    map(pickPageBlocks(state))
  )(nonExamPages);

  return isEmpty(selectedIds)
    ? pickTaskBlocks(lessonBlocks)
    : pick(selectedIds, pickTaskBlocks(lessonBlocks));
};

const getPageTotalTasks = (page, selectedIds, state) => {
  const pageBlocks = pickPageBlocks(state, page);
  return isEmpty(selectedIds)
    ? pickTaskBlocks(pageBlocks)
    : pick(selectedIds, pickTaskBlocks(pageBlocks))
};

// Количество конторольных
export const getLessonTotalTasksCount = (state, selectedIds) => {
  const lessonTotalTasks = getLessonTotalTasks(state, selectedIds);
  return size(lessonTotalTasks);
};

export const getLessonCorrectTasksCount = (state, selectedIds) => {
  const lessonTotalTasks = getLessonTotalTasks(state, selectedIds);
  const lessonCorrectTasks = pickCorrectTasks(state, lessonTotalTasks);
  return size(lessonCorrectTasks);
};

export const getPageTotalTasksCount = (page, selectedIds, state) => {
  const pageTotalTasks = getPageTotalTasks(page, selectedIds, state);
  return size(pageTotalTasks);
};

export const getPageCorrectTasksCount = (page, selectedIds, state) => {
  const pageTotalTasks = getPageTotalTasks(page, selectedIds, state);
  const pageCorrectTasks = pickCorrectTasks(state, pageTotalTasks);
  return size(pageCorrectTasks);
};

const checkExecutedBlocks = (blockType, blockResult) => {
  switch (blockType) {
    case 'Quiz':
    case 'Talk':
    case 'Weight':
      return get(['selectedAnswersIds'], blockResult);
    case 'Exam':
    case 'FillBlank':
      return get(['userAnswer'], blockResult);
    case 'Match':
      return get(['answers'], blockResult);
    case 'Answer':
      return get(['reply'], blockResult);

    default:
      return false;
  }
};

const pickExecutedBlocks = (state, blocks) => {
  const blocksResults = getOr({}, ['result', 'blocks'], state.player);

  return pickBy(
    (block, blockId) => checkExecutedBlocks(block.type, blocksResults[blockId]),
    blocks
  );
};

export const getExecutedBlocksCount = (state, blocks) =>
  size(pickExecutedBlocks(state, blocks));
