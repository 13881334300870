import * as RESULTS from '../constants/results';

export const defaultState = null;

const publicOlympiadResult = (state = defaultState, action) => {
  switch (action.type) {
    case RESULTS.DOWNLOAD_PUBLIC_OLYMPIAD_RESULT_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default publicOlympiadResult;
