import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { pure, withProps, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '..';
import { combineStyles } from '../../utils/styles';
import Uploader from '~/containers/Uploader';
import withMobileDetect from '~/hocs/withMobileDetect';
import '../../styles/Block/Video.scss';

const Video = ({
  source,
  blockId,
  onChange,
  isMobile,
  inEditMode,
  inPreviewMode,
}) => {
  const { t } = useTranslation('componentsBlock');

  return (
    <div
      className={combineStyles('Video', [
        isMobile && 'mobile',
        source && 'with-player',
        !source && inPreviewMode && 'empty',
      ])}
    >
      {source ? (
        <div className="content">
          <ReactPlayer
            url={source}
            controls
            width="100%"
            height="auto"
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
                embedOptions: { width: 790, height: 445 },
              },
              vimeo: {
                playerOptions: {
                  width: 790,
                  height: 445,
                  thumbnail_width: 790,
                  thumbnail_height: 445,
                },
              },
              soundcloud: {
                options: {
                  width: 790,
                  height: 430,
                },
              },
              dailymotion: {
                params: {
                  width: '790px',
                  height: '445px',
                },
                options: {
                  width: 790,
                  height: 445,
                },
              },
              twitch: {
                options: {
                  width: 790,
                  height: 445,
                },
              },
            }}
          />
        </div>
      ) : inEditMode ? (
        <div className="uploader">
          <Uploader
            uploadType="block"
            icon="osh-videoupload"
            title={t('video.uploadVideo')}
            blockId={blockId}
            filePath={[blockId, 'content']}
            onURLChange={onChange}
            fileType="media"
            variant="video"
            fileProperty={
              <>
                <div className="line">
                  <span className="label">
                    {t('video.uploadPropertyTitle1')}
                  </span>
                  {t('video.uploadPropertyText1')}
                </div>
                <div className="line">
                  <span className="label">
                    {t('video.uploadPropertyTitle2')}
                  </span>
                  {t('video.uploadPropertyText2')}
                </div>
              </>
            }
          />
        </div>
      ) : inPreviewMode ? (
        <div className="empty">
          <div className="icon">
            <Icon name="osh-videoupload" />
          </div>
          <div className="text">
            <Text
              value={t('video.videoNotLoaded')}
              variant="empty-block-text"
            />
          </div>
        </div>
      ) : (
        undefined
      )}
    </div>
  );
};

const { bool, string, func } = PropTypes;

Video.propTypes = {
  source: string, // URL файла
  blockId: string.isRequired,
  isMobile: bool.isRequired,
  onChange: func.isRequired,
  inEditMode: bool,
  inPreviewMode: bool,
};

Video.defaultProps = {
  source: undefined,
  inEditMode: undefined,
  inPreviewMode: undefined,
};

const enhance = compose(
  pure,
  withMobileDetect,
  withProps(
    ({
      inPlayer,
      inBuilder,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
    })
  ),
  withHandlers({
    onChange: ({ onChange }) => (newURL) => onChange({ source: newURL }),
  })
);

export default enhance(Video);
