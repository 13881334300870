import {
  set,
  unset,
  omit,
  compose,
  update,
  curry,
  pull,
  pullAt,
} from 'lodash/fp';
import * as QUIZ from '../../../constants/builder/block/quiz';
import { push, insertAt } from '../../../utils/array';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../../../appConstants';

export const defaultState = {
  type: QUIZ_BLOCK_TYPE_CHECKBOX, // Тип теста - checkbox или radio
  answers: {}, // Варианты ответов, в формате { 1: 'Текст', 2: 'Текст' }
  comments: {}, // Комментарии к ответам, в формате { 1: 'Текст', 2: 'Текст' }, ID комментария соответствует ID ответа
  question: undefined, // Текст вопроса
  description: undefined, // Описание вопроса
  answersIdsOrder: [], // Порядок ID ответов
  isShuffleAnswers: false, // Перемешивать ответы?
  correctAnswersIds: [], // ID правильных ответов
  displayedAnswersIds: [], // ID выводимых ученику ответов
  // Для весовых вопросов
  level: undefined,
  group: undefined,
  weights: {},
  competence: undefined,
};

const quizReducer = (state = defaultState, action) => {
  switch (action.type) {

    case QUIZ.CHANGE_GROUP: {
      const { newGroup } = action.payload;
      return set('group', newGroup, state)
    }

    case QUIZ.CHANGE_LEVEL: {
      const { newLevel } = action.payload;
      return set('level', newLevel, state)
    }

    case QUIZ.CHANGE_WEIGHT: {
      const { answerId, newWeight } = action.payload;
      return set(['weights', answerId], newWeight, state)
    }

    case QUIZ.CHANGE_COMPETENCE: {
      const { newCompetence } = action.payload;
      return set('competence', newCompetence, state)
    }

    case QUIZ.ADD_ANSWER: {
      const { answerId } = action.payload;
      return compose(
        set(['answers', answerId], ''),
        update('answersIdsOrder', push(answerId))
      )(state);
    }

    case QUIZ.ADD_ANSWER_OTHER: {
      const { answerId } = action.payload;
      return compose(
        set(['answerOther', 'id'], answerId),
        set(['answerOther', 'text'], 'quizcontent.answerOther')
      )(state);
    }

    case QUIZ.REMOVE_ANSWER_OTHER: {
      return unset('answerOther')(state);
    }

    case QUIZ.MOVE_ANSWER: {
      const { oldIndex, newIndex } = action.payload;
      return update(
        'answersIdsOrder',
        compose(
          insertAt(newIndex, state.answersIdsOrder[oldIndex]),
          pullAt(oldIndex)
        ),
        state
      );
    }

    case QUIZ.SET_ANSWER: {
      const { answerId } = action.payload;
      const { type } = state;
      return update(
        'correctAnswersIds',
        (correctAnswersIds) =>
          correctAnswersIds.includes(answerId)
            ? {
                [QUIZ_BLOCK_TYPE_RADIO]: correctAnswersIds,
                [QUIZ_BLOCK_TYPE_CHECKBOX]: pull(answerId, correctAnswersIds),
              }[type]
            : {
                [QUIZ_BLOCK_TYPE_RADIO]: [answerId],
                [QUIZ_BLOCK_TYPE_CHECKBOX]: push(answerId, correctAnswersIds),
              }[type],
        state
      );
    }

    case QUIZ.TOGGLE_TYPE: {
      const { type } = state;
      return compose(
        set(
          'type',
          {
            [QUIZ_BLOCK_TYPE_RADIO]: QUIZ_BLOCK_TYPE_CHECKBOX,
            [QUIZ_BLOCK_TYPE_CHECKBOX]: QUIZ_BLOCK_TYPE_RADIO,
          }[type]
        ),
        set('correctAnswersIds', [])
      )(state);
    }

    case QUIZ.TOGGLE_SHUFFLE: {
      const { isShuffleAnswers, answersIdsOrder } = state;
      return compose(
        set('isShuffleAnswers', !isShuffleAnswers),
        set(
          'displayedAnswersQuantity',
          !isShuffleAnswers ? answersIdsOrder.length : undefined
        )
      )(state);
    }

    case QUIZ.CHANGE_QUANTITY: {
      const { quantity } = action.payload;
      return set('displayedAnswersQuantity', quantity, state);
    }

    case QUIZ.CHANGE_ANSWER: {
      const { answerId, newAnswer } = action.payload;
      return set(['answers', answerId], newAnswer, state);
    }

    case QUIZ.CHANGE_COMMENT: {
      const { answerId, newComment } = action.payload;
      return set(['comments', answerId], newComment, state);
    }

    case QUIZ.TOGGLE_COMMENT: {
      const { answerId, comment } = action.payload;
      return comment === undefined
        ? set(['comments', answerId], '', state)
        : update(['comments'], omit(answerId), state);
    }

    case QUIZ.REMOVE_ANSWER: {
      const { answerId } = action.payload;
      return compose(
        update(['answers'], omit(answerId)),
        update(['comments'], omit(answerId)),
        update(['answersIdsOrder'], pull(answerId)),
        update(['correctAnswersIds'], pull(answerId))
      )(state);
    }

    case QUIZ.CHANGE_QUESTION: {
      const { newQuestion } = action.payload;
      return set('question', newQuestion, state);
    }

    case QUIZ.CHANGE_DESCRIPTION: {
      const { newDescription } = action.payload;
      return set('description', newDescription, state);
    }

    default:
      return state;
  }
};

export default curry(quizReducer);
