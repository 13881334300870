import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Input, Text, Icon } from '../components';
// import { combineStyles } from '../utils/styles';
import '../styles/WelcomePreview.scss';

const WelcomePreview = ({
  t,
  authTitle,
  authImage,
  buttonText,
  partnersLogos,
  authDescription,
}) => (
  <div className="WelcomePreview">
    {authImage.isShow && (
      <div className="image">
        {authImage.source ? (
          <img src={authImage.source} alt="" />
        ) : (
          <div className="preview">
            <Icon name="osh-imageupload" variant="welcome-auth-image-preview" />
          </div>
        )}
      </div>
    )}
    {partnersLogos.isShow && partnersLogos.source && (
      <div className="partners">
        <img src={partnersLogos.source} alt="" />
      </div>
    )}
    <div className="text">
      <Text value={t(authTitle)} variant="olympiad-welcome-title" />
      {authDescription.isShow && (
        <Text value={t(authDescription.text)} variant="olympiad-welcome-text" />
      )}
    </div>
    <div className="login">
      <div className="input">
        <Input
          usePortal
          disabled
          value="Иван Иванов"
          label={t('welcomepreview.fullnameForCertificate')}
          variant="welcome"
          placeholder={t('welcomepreview.fullnameForCertificate')}
        />
      </div>
      <div className="input">
        <Input
          usePortal
          disabled
          value="mail@example.ru"
          label={t('welcomepreview.emailForCertificate')}
          variant="welcome"
          placeholder={t('welcomepreview.emailForCertificate')}
        />
      </div>
      <div className="button">
        <Button text={t(buttonText)} variant="player-welcome" />
      </div>
    </div>
  </div>
);

const { func, string, object } = PropTypes;

WelcomePreview.propTypes = {
  t: func.isRequired, // Функция перевода
  authTitle: string,
  authImage: object,
  buttonText: string,
  partnersLogos: object,
  authDescription: object,
};

WelcomePreview.defaultProps = {
  authTitle: undefined,
  authImage: {},
  buttonText: undefined,
  partnersLogos: {},
  authDescription: {},
};

export default withTranslation('containers')(WelcomePreview);
