import { set, update, get, assign } from 'lodash/fp';
import * as LIBRARY from '../../constants/library';
import * as BLOCK from '../../constants/builder/block';

import quizReducer from '../builder/blocks/quiz';
import examReducer from '../builder/blocks/exam';
import answerReducer from '../builder/blocks/answer';
import imagesReducer from '../builder/blocks/images';

export const defaultState = {
  block: {
    id: null, // ID блока
    type: null, // Тип блока
    terms: [], // Термины блока
    dates: [], // Даты блока
    themes: [], // Темы блока
    persons: [], // Персоны блока
    content: {}, // Содержимое блока
  },
};

const shareReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LIBRARY.EDIT.OPEN: {
      const {
        meta: { block },
      } = action;
      return set('block', block, state);
    }

    case LIBRARY.EDIT.CLOSE:
    case LIBRARY.EDIT.REMOVE_SUCCESS: {
      return set('block', {}, state);
    }

    case LIBRARY.EDIT.CHANGE_DATES: {
      const { newDates } = action.payload;
      return set(['block', 'dates'], newDates, state);
    }

    case LIBRARY.EDIT.CHANGE_TERMS: {
      const { newTerms } = action.payload;
      return set(['block', 'terms'], newTerms, state);
    }

    case LIBRARY.EDIT.CHANGE_THEMES: {
      const { newThemes } = action.payload;
      return set(['block', 'themes'], newThemes, state);
    }

    case LIBRARY.EDIT.CHANGE_PERSONS: {
      const { newPersons } = action.payload;
      return set(['block', 'persons'], newPersons, state);
    }

    case LIBRARY.EDIT.CHANGE_SUBJECT: {
      const { newSubject } = action.payload;
      return set(['block', 'subject'], newSubject, state);
    }

    case BLOCK.CHANGE_CONTENT: {
      const { newContent } = action.payload;
      return get(['meta', 'context', 'inLibraryEdit'], action)
        ? update(
            ['block', 'content'],
            (oldContent) => assign(oldContent, newContent),
            state
          )
        : state;
    }

    case String(action.type.match(/@builder\/block\/.*/)): {
      return get(['meta', 'context', 'inLibraryEdit'], action)
        ? update(
            ['block', 'content'],
            (content) =>
              get(get(['block', 'type'], state), {
                Exam: examReducer,
                Quiz: quizReducer,
                Answer: answerReducer,
                Images: imagesReducer,
              })(content, action),
            state
          )
        : state;
    }

    default:
      return state;
  }
};

export default shareReducer;
