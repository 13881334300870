import { set, curry, omit, update } from 'lodash/fp';
import * as MATCH from '../../../constants/builder/block/match';

export const defaultState = {
  question: undefined, // Текст вопроса
  description: undefined, // Описание вопроса
  baskets: {}, // Порядок ID ответов
};

const matchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case MATCH.ADD_BASKET: {
      const { basketId } = action.payload;
      return set(['baskets', basketId], { answers: [] }, state);
    }

    case MATCH.REMOVE_BASKET: {
      const { basketId } = action.payload;
      return update('baskets', omit(basketId), state);
    }

    case MATCH.CHANGE_ANSWER: {
      const { basketId, newAnswers } = action.payload;
      return set(['baskets', basketId, 'answers'], newAnswers, state);
    }

    case MATCH.CHANGE_BASKET_NAME: {
      const { basketId, newBasketName } = action.payload;
      return set(['baskets', basketId, 'name'], newBasketName, state);
    }

    case MATCH.CHANGE_QUESTION: {
      const { newQuestion } = action.payload;
      return set('question', newQuestion, state);
    }

    case MATCH.CHANGE_DESCRIPTION: {
      const { newDescription } = action.payload;
      return set('description', newDescription, state);
    }

    default:
      return state;
  }
};

export default curry(matchReducer);
