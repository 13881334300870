import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withContext, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { Text, Icon, Input, Button /* Checkbox */ } from '../../components';
import { combineStyles } from '../../utils/styles';
import '../../styles/Library/Edit.scss';
import Block from '~/containers/Block';

const Edit = ({
  t,
  save,
  close,
  dates,
  terms,
  themes,
  remove,
  blockId,
  persons,
  // subject,
  variant,
  changeTerms,
  changeDates,
  changeThemes,
  changePersons,
  // changeSubject,
}) => (
  <div className={combineStyles('Edit', variant)}>
    <div className="title">
      <Text value={t('edit.editBlock')} />
      <Button text={t('edit.backToSearch')} onClick={close} />
    </div>
    <div className="field">
      <div className="label">
        <div className="icon">
          <Icon symbol={1} variant="library-field-label-icon" />
        </div>
        <div className="text">
          <Text
            value={t('edit.editContent')}
            variant="library-field-label-text"
          />
        </div>
      </div>
      <div className="block">
        <Block id={blockId} />
      </div>
    </div>
    {/*
    <div className="field">
      <div className="label">
        <div className="icon">
          <Icon
            symbol={2}
            variant="library-field-label-icon"
          />
        </div>
        <div className="text">
          <Text
            value="Выберите предмет, к которому относится блок"
            variant="library-field-label-text"
          />
        </div>
      </div>
      <div className="switch">
        {['История России', 'Математика', 'Физика'].map((subjectName, subjectIndex) => (
          <Checkbox
            key={subjectIndex}
            type="radio"
            label={subjectName}
            checked={subject === subjectName}
            onCheck={() => changeSubject(subjectName)}
            variant="library-search-sidebar-filters-checkbox"
          />
        ))}
      </div>
    </div>
    */}
    {[
      {
        name: t('edit.theme'),
        index: 2,
        value: themes,
        label: t('edit.themes'),
        onChange: changeThemes,
        placeholder: t('edit.themeDescrption'),
      },
      {
        name: t('edit.persona'),
        index: 3,
        value: persons,
        label: t('edit.persons'),
        onChange: changePersons,
        placeholder: t('edit.personaDescrption'),
      },
      {
        name: t('edit.term'),
        index: 4,
        value: terms,
        label: t('edit.terms'),
        onChange: changeTerms,
        placeholder: t('edit.termDescrption'),
      },
      {
        name: t('edit.date'),
        index: 5,
        label: t('edit.dates'),
        value: dates,
        onChange: changeDates,
        placeholder: t('edit.dateDescrption'),
      },
    ].map((field) => (
      <div className="field" key={field.index}>
        <div className="label">
          <div className="icon">
            <Icon symbol={field.index} variant="library-field-label-icon" />
          </div>
          <div className="text">
            <Text value={field.label} variant="library-field-label-text" />
          </div>
        </div>
        <div className="input">
          <Input
            name={field.name}
            value={field.value}
            variant="library-field-input"
            withTags
            onChange={field.onChange}
            placeholder={field.placeholder}
          />
        </div>
      </div>
    ))}
    <div className="buttons">
      <Button
        text={t('edit.save')}
        variant="library-share-add-block"
        onClick={save}
      />
      <Button
        icon="delete"
        text={t('edit.removeBlock')}
        variant="ghost-gray"
        onClick={remove}
      />
    </div>
  </div>
);

const { string, array, bool, func, oneOfType } = PropTypes;

Edit.propTypes = {
  t: func.isRequired, // Функция перевода
  save: func.isRequired, // Добавить блок в библиотеку
  close: func.isRequired, // Закрыть библиотеку
  dates: array, // Даты, связанные с блоком
  terms: array, // Термины, связанные с блоком
  themes: array, // Темы, связанные с блоком
  remove: func.isRequired, // Удалить блок
  // subject: array, // Тема урока, в которому относится блок
  blockId: string.isRequired, // ID блока
  persons: array, // Персоны, связанные с блоком
  variant: oneOfType([array, string]), // Вариант оформления
  changeDates: func.isRequired, // Изменить даты блока
  changeTerms: func.isRequired, // Изменить термины блока
  changeThemes: func.isRequired, // Изменить темы блока
  changePersons: func.isRequired, // Изменить персон блока
  // changeSubject: func.isRequired, // Изменить тему блока (тема урока)
};

Edit.defaultProps = {
  dates: [],
  terms: [],
  themes: [],
  persons: [],
  variant: undefined,
};

const mapStateToProps = (state) => {
  const {
    id,
    dates,
    terms,
    themes,
    subject,
    persons,
  } = selectors.library.getEditingBlock(null, state);
  return {
    dates,
    terms,
    themes,
    subject,
    persons,
    blockId: id,
  };
};

const mapDispatchToProps = {
  save: actions.library.edit.save,
  close: actions.library.edit.close,
  remove: actions.library.edit.removeRequest,
  changeDates: actions.library.edit.changeDates,
  changeTerms: actions.library.edit.changeTerms,
  changeThemes: actions.library.edit.changeThemes,
  changePersons: actions.library.edit.changePersons,
  changeSubject: actions.library.edit.changeSubject,
};

const enhance = compose(
  withHandlers({
    remove: ({ remove, blockId }) => () => remove(blockId),
  }),
  withContext({ inLibraryEdit: bool.isRequired }, () => ({
    inLibraryEdit: true,
  }))
);

export default compose(
  withTranslation('containersLibrary'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Edit);
