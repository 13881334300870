export const ADD = '@builder/folder/Создание папки';
export const SAVE = '@builder/folder/Сохранение папки';
export const OPEN = '@builder/folder/Открытие папки';
export const EDIT = '@builder/folder/Редактирование папки';
export const RENAME = '@builder/folder/Переименование папки';
export const SAVE_ERROR = '@builder/folder/Не удалось сохранить папку';
export const SAVE_SUCCESS = '@builder/folder/Папка успешно сохранена';
export const REMOVE_ERROR = '@builder/folder/Не удалось удалить папку';
export const REMOVE_ACCEPT = '@builder/folder/Подтверждение удаления папки';
export const REMOVE_CANCEL = '@builder/folder/Отмена удаления папки';
export const REMOVE_SUCCESS = '@builder/folder/Папка успешно удалена';
export const REMOVE_CONFIRM = '@builder/folder/Запрос на удаление папки';
