import styled, { css } from 'styled-components';

export const cardSelect = css`
  padding: 7px 0;
  margin-top: 3px;
  width: 448px;
  border-radius: 3px;
  box-shadow: 0 8px 21px -3px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`;

export const target = css`
  display: flex;
  align-items: center;
`;

export const cards = css`
  max-height: 250px;
  overflow: auto;
  width: 100%;
  margin-top: 7px;
  padding-bottom: 7px;
  border-top: solid 1px #e5e9ec;
`;

export const buttonContent = css`
  display: flex;
  align-items: center;
`;

export const buttonText = css`
  width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const buttonIndex = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -34px;
  margin-right: 13px;
  width: 22px;
  height: 22px;
  border-radius: 60px;
  border: solid 1px #d0d3d7;

  font-family: CirceRounded;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: #45494e;
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  padding-left: 8px;
  align-items: center;
  width: 67px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #d0d3d7;
  font-family: CirceRounded;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  color: ${(p) => (p.selected ? '#45494e' : '#767b84')};

  :hover {
    border: solid 1px #b2b6b8;
  }

  ::after {
    content: '';
    position: absolute;
    right: 8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: #b2b6b8 transparent transparent transparent;
  }
`;
