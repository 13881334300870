import { put, take, call, select } from 'redux-saga/effects';
import * as BUILDER from '~/constants/builder';
import * as actions from '~/actions';
import * as selectors from '~/selectors';
import request from '../../request';

const api = `${process.env.API_URL}/lesson/delete`;

/**
 * Удаление урока с сервера
 */
export default function* remove() {
  while (true) {
    const {
      payload: { id: lessonId },
    } = yield take(BUILDER.LESSON.REMOVE_ACCEPT);
    const lesson = yield select((store) =>
      selectors.builder.getLesson(lessonId, store)
    );
    const { courseId } = lesson.meta;

    // Данные для запроса
    const data = {
      id: lessonId,
    };
    const response = yield call(request, api, data);
    if (response) {
      if (!response.error) {
        yield put(
          actions.builder.lesson.removeSuccess({ id: lessonId, courseId })
        );
      } else {
        yield put(actions.builder.lesson.removeError(response.msg));
      }
    }
  }
}
