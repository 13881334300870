import React from 'react';
import PropTypes from 'prop-types';

import { pure, withState, compose } from 'recompose';

import { combineStyles } from '../utils/styles';
import '../styles/LessonTemplate.scss';
import Icon from '~/components/Icon';
import LinesTruncate from '~/components/LinesTruncate';
import { DraggedButton as Button } from '~/components/Button';
import Hyperlink from '~/components/Hyperlink';

const LessonTemplate = ({
  icon,
  name,
  isHover,
  lessonId,
  courseId,
  isSelected,
  description,
  changeHover,
  cloneLesson,
  changeSelected,
  previewLessonId,
}) => (
  <div
    className="LessonTemplate"
    onMouseEnter={() => changeHover(true)}
    onMouseLeave={() => changeHover(false)}
  >
    <span className="icon" role="img" aria-label="icon">
      {icon}
    </span>
    <div className="bg">
      <Icon name="osh-emptystate" variant="lesson-template" />
    </div>
    <div className="bottom">
      <div className="name">
        <LinesTruncate text={name} variant="template-name" maxLines={2} />
      </div>
      <div className="description">
        <LinesTruncate
          text={description}
          variant="template-descriptions"
          maxLines={3}
        />
      </div>
      <div className={combineStyles('buttons', isHover && 'hovered')}>
        <Button
          loading={isSelected}
          text="Выбрать"
          minimal
          variant="template-select"
          onClick={() => {
            changeSelected(true);
            cloneLesson({ id: lessonId, courseId });
          }}
        />
        <Hyperlink
          text="Посмотреть"
          href={`preview/lesson/${previewLessonId}`}
          newTab
          variant="template-preview"
        />
      </div>
    </div>
  </div>
);

const { string, func, bool } = PropTypes;

LessonTemplate.propTypes = {
  icon: string, // Иконка emoji
  name: string, // Название урока
  isHover: bool, // На карточку навели мышкой?
  lessonId: string.isRequired, // ID урока
  courseId: string,
  changeHover: func, // Функция наведения на карточку
  description: string, // Описание урока
  cloneLesson: func.isRequired,
  isSelected: bool.isRequired,
  changeSelected: func.isRequired,
  previewLessonId: string.isRequired, // ID урока
};

LessonTemplate.defaultProps = {
  icon: undefined,
  name: undefined,
  isHover: false,
  courseId: undefined,
  changeHover: undefined,
  description: undefined,
};

export default compose(
  pure,
  withState('isHover', 'changeHover', false),
  withState('isSelected', 'changeSelected', false)
)(LessonTemplate);
