import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import {
  COURSE_STATUS_NOT_ACTIVE,
  COURSE_STATUS_ACTIVE,
  COURSE_STATUS_FINISHED,
  REQUEST_NAME_CHANGE_COURSE_STATUS,
  PLAN_NAME_FREE,
} from '~/appConstants';
import { Button, Dialog, Text, Collapse } from '~/components';
import { combineStyles } from '~/utils/styles';
import Icon from '~/components/Icon';
import * as s from '~/screens/Teach/styles/CourseStatuses.styles';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import CollapseContent from '~/components/Collapse/CollapseContent';
import plansDataMap from '~/plansDataMap';
import Emoji, { HUG } from '~/components/Emoji';

const courseStatusesList = [
  {
    status: COURSE_STATUS_NOT_ACTIVE,
    icon: 'osh-course-not_active',
    name: 'courseStatus.notActive',
    price: 'courseStatus.notActiveTitle',
    description: 'courseStatus.notActiveDescription',
  },
  {
    status: COURSE_STATUS_ACTIVE,
    icon: 'osh-course-active',
    name: 'courseStatus.active',
    price: 'courseStatus.activeTitle',
    description: 'courseStatus.activeDescription',
  },
  {
    status: COURSE_STATUS_FINISHED,
    icon: 'osh-course-finished',
    name: 'courseStatus.finished',
    price: 'courseStatus.finishedTitle',
    description: 'courseStatus.finishedDescription',
  },
];

const CourseStatuses = ({ close, isOpen, currentStatus, openPlans }) => {
  const { t } = useTranslation('screens');
  const dispatch = useDispatch();
  const { id } = useParams();

  const [selectedStatus, selectStatus] = useState(currentStatus);

  const setStatus = useCallback(
    () =>
      dispatch(
        actions.builder.course.setStatus({
          id,
          status: selectedStatus,
        })
      ),
    [dispatch, id, selectedStatus]
  );

  const { planName: userPlan } = useSelector(
    selectors.auth.getUserSubscription
  );

  const isUserAdmin = useSelector(selectors.auth.isUserAdmin);

  const userPlanData = plansDataMap[userPlan];

  const isStatusChanging = useSelector(
    selectors.ui.isRequestActive(REQUEST_NAME_CHANGE_COURSE_STATUS)
  );
  const activeCoursesCount = useSelector(
    selectors.builder.getActiveCoursesCount
  );

  const canChange =
    selectedStatus !== COURSE_STATUS_ACTIVE ||
    activeCoursesCount < userPlanData.coursesCount ||
    isUserAdmin;

  const activeBottomItem =
    selectedStatus === currentStatus
      ? ''
      : !canChange && COURSE_STATUS_ACTIVE === selectedStatus
      ? 'bottom'
      : 'bottomConfirm';

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      closable
      canEscapeKeyClose
      variant="course-status"
    >
      <div css={s.container}>
        <div className="close">
          <Button icon="osh-remove" onClick={close} variant="settings-close" />
        </div>
        <Text className="title" value={t('courseStatus.title')} />
        <div className="row">
          {courseStatusesList.map((item) => (
            <div className="status" key={item.status}>
              <s.Square
                active={selectedStatus === item.status}
                onClick={() => selectStatus(item.status)}
              >
                <div
                  className={combineStyles(
                    'icon',
                    item.status === COURSE_STATUS_ACTIVE && 'green'
                  )}
                >
                  <Icon name={item.icon} />
                </div>
                <Text className="name" value={t(item.name)} />
              </s.Square>
              <Text className="description" value={t(item.description)} />
            </div>
          ))}
        </div>
        <Collapse activeName={activeBottomItem}>
          <CollapseContent name="bottom">
            <div css={s.bottom}>
              <div css={s.row}>
                <div>
                  <div css={s.row}>
                    <Emoji css={s.emoji} label="hug" symbol={HUG} />
                    <div css={s.anotherPlanText}>
                      <Trans
                        t={t}
                        i18nKey={
                          userPlan === PLAN_NAME_FREE
                            ? 'courseStatus.goToAnotherPlan'
                            : 'courseStatus.goToBiggerPlan'
                        }
                      />
                    </div>
                  </div>

                  <Button
                    text={t('courseStatus.chooseAnotherPlan')}
                    variant="course-confirm big"
                    minimal
                    onClick={openPlans}
                  />
                </div>

                <div css={s.currentPlan}>
                  <div className="title">{t('courseStatus.yourPlan')}</div>
                  <div className="name">{userPlanData.title}</div>
                  <div>
                    <span className="counter">{userPlanData.coursesCount}</span>
                    &nbsp;
                    <span className="active">
                      {t('courseStatus.activeCourses', {
                        count: userPlanData.coursesCount,
                      })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div css={s.space} />
            <div css={s.buttons}>
              <Button
                className="button"
                minimal
                onClick={close}
                variant="course-cancel"
                text={t('courseStatus.cancel')}
              />
            </div>
          </CollapseContent>
          <CollapseContent name="bottomConfirm">
            <div css={s.buttons}>
              <Button
                className="button"
                minimal
                loading={isStatusChanging}
                onClick={setStatus}
                variant="course-confirm"
                text={t('courseStatus.confirm')}
              />
              <Button
                className="button"
                onClick={close}
                minimal
                variant="course-cancel"
                text={t('courseStatus.cancel')}
              />
            </div>
          </CollapseContent>
        </Collapse>
      </div>
    </Dialog>
  );
};

const { bool, func, string } = PropTypes;

CourseStatuses.propTypes = {
  close: func.isRequired,
  isOpen: bool.isRequired,
  currentStatus: string.isRequired,
  openPlans: func.isRequired,
};

export default CourseStatuses;
