import React from 'react';
import PropTypes from 'prop-types';
import Button from '~/components/Button';

import * as s from './styles/FilePreview.styles';

const FilePreview = ({ name, source, remove, extension, isCanRemove }) => (
  <div css={s.item}>
    <a
      href={source}
      css={s.itemLink}
      download
      target="_blank"
      rel="noopener noreferrer"
    >
      <div css={s.extension} extension={extension}>
        {extension}
      </div>
      <div css={s.fileName}>{name}</div>
    </a>
    {isCanRemove && (
      <div className="remove">
        <Button
          minimal
          canDrag={false}
          icon="osh-trash"
          variant={['remove-image', 'remove-file']}
          onClick={remove}
        />
      </div>
    )}
  </div>
);

const { string, bool, func } = PropTypes;

FilePreview.propTypes = {
  name: string,
  source: string,
  remove: func,
  extension: string,
  isCanRemove: bool,
};

FilePreview.defaultProps = {
  name: '',
  extension: '',
};

export default FilePreview;
