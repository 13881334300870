import * as UPLOADER from '../constants/uploader';
import { createAction } from '../utils/actions';

export const startUpload = (
  objectId,
  file,
  filePath,
  fileType,
  uploadType,
  pathLastName,
  withFileName,
  inPlayer,
  isNoticeProgress
) =>
  createAction(UPLOADER.START_UPLOAD, {
    objectId,
    file,
    filePath,
    fileType,
    uploadType,
    pathLastName,
    withFileName,
    inPlayer,
    isNoticeProgress,
  });

export const uploadError = (
  objectId,
  message,
  filePath,
  uploadType,
  pathLastName
) =>
  createAction(
    UPLOADER.UPLOAD_ERROR,
    { objectId, message, filePath, uploadType, pathLastName },
    {},
    true
  );

export const uploadSuccess = (
  objectId,
  source,
  filePath,
  uploadType,
  pathLastName,
  withFileName,
  inPlayer
) =>
  createAction(UPLOADER.UPLOAD_SUCCESS, {
    objectId,
    source,
    filePath,
    uploadType,
    pathLastName,
    withFileName,
    inPlayer,
  });

export const setUploadProgress = (
  objectId,
  newProgress,
  filePath,
  uploadType,
  isNoticeProgress
) =>
  createAction(UPLOADER.SET_UPLOAD_PROGRESS, {
    objectId,
    newProgress,
    filePath,
    uploadType,
    isNoticeProgress,
  });
