import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@blueprintjs/core';

import { DraggedButton as Button } from '~/components/Button';
import '~/styles/Drawer.scss';

const StyledDrawer = ({ onClose, isOpen, children, title, className }) => (
  <Drawer
    className={`Drawer ${className}`}
    backdropClassName="DrawerBackdrop"
    canOutsideClickClose
    onClose={onClose}
    size={345}
    isOpen={isOpen}
  >
    <div className="header">
      <div className="title">{title}</div>
      <div className="close">
        <Button icon="osh-remove" onClick={onClose} variant="settings-close" />
      </div>
    </div>
    {children}
  </Drawer>
);

const { func, bool, node, string } = PropTypes;

StyledDrawer.propTypes = {
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  children: node.isRequired,
  title: string,
  className: string,
};

export default StyledDrawer;
