import objectId from 'bson-objectid';

import * as LESSON from '../../constants/builder/lesson';

import { createAction } from '../../utils/actions';

export const add = ({ lessonId, folderId, courseId, type }) =>
  createAction(LESSON.ADD, {
    id: lessonId || objectId.generate(),
    folderId,
    courseId,
    type,
  });

export const save = (id, message) => createAction(LESSON.SAVE, { id, message });

export const clone = ({
  id,
  courseId,
  lesson,
  pages,
  blocks,
  notOpenAfterClone,
}) =>
  createAction(LESSON.CLONE, {
    id,
    courseId,
    lesson,
    pages,
    blocks,
    notOpenAfterClone,
  });

export const downloadAndClone = ({ id, courseId }) =>
  createAction(LESSON.DOWNLOAD_AND_CLONE, { id, courseId });

export const move = (lessonId, folderId) =>
  createAction(LESSON.MOVE, { lessonId, folderId });

export const publish = (id) => createAction(LESSON.PUBLISH, { id });

export const publishSuccess = (id, publications, publicationsIds) =>
  createAction(LESSON.PUBLISH_SUCCESS, { id, publications, publicationsIds });

export const publishError = (id) => createAction(LESSON.PUBLISH_ERROR, { id });

export const saveError = (id, message) =>
  createAction(LESSON.SAVE_ERROR, { id, message });

export const changeName = (id, newName) =>
  createAction(LESSON.CHANGE_NAME, { id, newName });

export const openSidebar = () => createAction(LESSON.OPEN_SIDEBAR);

export const saveSuccess = ({ id, code, message = 'Урок сохранен' }) =>
  createAction(LESSON.SAVE_SUCCESS, {
    id,
    code,
    message,
  });

export const removeConfirm = (id) =>
  createAction(LESSON.REMOVE_CONFIRM, { id });

export const removeCancel = () => createAction(LESSON.REMOVE_CANCEL);

export const removeAccept = (id) => createAction(LESSON.REMOVE_ACCEPT, { id });

export const removeError = (message) =>
  createAction(LESSON.REMOVE_ERROR, { message });

export const removeSuccess = ({ id, courseId }) =>
  createAction(LESSON.REMOVE_SUCCESS, { id, courseId });

export const closeSidebar = () => createAction(LESSON.CLOSE_SIDEBAR);

export const toggleBirdView = (id) =>
  createAction(LESSON.TOGGLE_BIRD_VIEW, { id });

export const toggleLessonLtiAccess = (id, isLessonLtiAccess) =>
  createAction(LESSON.TOGGLE_LESSON_LTI_ACCESS, { id, isLessonLtiAccess });

export const toggleTimeLimit = (id) =>
  createAction(LESSON.TOGGLE_TIME_LIMIT, { id });

export const changeTimeLimit = (id, newTimeLimit) =>
  createAction(LESSON.CHANGE_TIME_LIMIT, { id, newTimeLimit });

export const changeDateRange = (id, newStartDate, newEndDate) =>
  createAction(LESSON.CHANGE_DATE_RANGE, { id, newStartDate, newEndDate });

export const changeExternalLink = (id, newExternalLink) =>
  createAction(LESSON.CHANGE_EXTERNAL_LINK, { id, newExternalLink });

export const toggleDateRange = (id) =>
  createAction(LESSON.TOGGLE_DATE_RANGE, { id });

export const importFromPlayer = (id) =>
  createAction(LESSON.IMPORT_FROM_PLAYER, { id });

export const changeDescription = (id, newDescription) =>
  createAction(LESSON.CHANGE_DESCRIPTION, { id, newDescription });

export const changeOlympiadSettings = (id, path, value) =>
  createAction(LESSON.CHANGE_OLYMPIAD_SETTINGS, { id, path, value });

export const addOlympiadLevelingInterval = ({ lessonId }) =>
  createAction(LESSON.ADD_OLYMPIAD_LEVELING_INTERVAL, { lessonId });

export const removeOlympiadLevelingInterval = ({ lessonId, index }) =>
  createAction(LESSON.REMOVE_OLYMPIAD_LEVELING_INTERVAL, { lessonId, index });

export const changeMinimalSuccessScore = ({ id, score }) =>
  createAction(LESSON.CHANGE_MINIMAL_SUCCESS_SCORE, { id, score });

export const setHasUnpublishedChanges = ({ id }) =>
  createAction(LESSON.SET_HAS_UNPUBLISHED_CHANGES, { id });
