import { put, call, take } from 'redux-saga/effects';
// import { keyBy, pipe, mapValues } from 'lodash/fp';
import * as COURSE from '~/constants/course';
import * as actions from '~/actions';
import request from '~/sagas/request';

const api = `${process.env.API_URL}/course/join`;

/**
 * Подтвердить участие в курсе
 */
export default function* join() {
  while (true) {
    const {
      payload: { id },
    } = yield take(COURSE.JOIN);
    // Данные для запроса
    const data = { id };
    const response = yield call(request, api, data);
    if (response) {
      if (!response.error) {
        yield put(actions.course.joinSuccess());
      } else {
        yield put(actions.course.joinError(response.msg));
      }
    }
  }
}
