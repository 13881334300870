import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as actions from '~/actions';
import { Icon } from '~/components';
import { SelectLocale } from '~/containers';
import '~/styles/ScreenSelectRole.scss';
import EmailAlert from '~/screens/EmailAlert';

const ScreenSelectRole = ({ t, setRole, role }) =>
  role ? (
    <Redirect to={role === 'teacher' ? '/teach' : '/study'} />
  ) : (
    <div className="ScreenSelectRole">
      <EmailAlert />
      <div className="header">
        <Icon name="osh-logocore" variant="header-logo" />
        <SelectLocale />
      </div>
      <div className="body">
        <div className="title">{t('selectrole.enterAs')}</div>
        <div className="cards">
          <div className="card" onClick={() => setRole('teacher')}>
            <span className="icon" role="img" aria-label="teacher">
              👨‍🏫
            </span>
            <div className="text">{t('selectrole.teacher')}</div>
          </div>
          <div className="card" onClick={() => setRole('student')}>
            <span className="icon" role="img" aria-label="student">
              👨‍🎓
            </span>
            <div className="text">{t('selectrole.student')}</div>
          </div>
        </div>
      </div>
    </div>
  );

const { func, string } = PropTypes;

ScreenSelectRole.propTypes = {
  t: func.isRequired,
  setRole: func.isRequired,
  role: string,
};

const mapDispatchToProps = {
  setRole: actions.auth.setRole,
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation('screens')
)(ScreenSelectRole);
