import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { keyBy } from 'lodash/fp';

import {
  DOWNLOAD_ANSWER_BLOCK,
  DOWNLOAD_ANSWER_BLOCKS,
  updateAnswerBlockStatusSuccess,
  downloadAnswerBlocksSuccess,
  downloadAnswerBlockSuccess,
  sendTeacherCommentSuccess,
  SEND_TEACHER_COMMENT,
  UNLOCK_ANSWER_BLOCK_REVIEW,
} from '~/actions/review';
import { apiRequest } from '~/sagas/request';
import {
  REQUEST_NAME_ANSWER_BLOCK_DOWNLOADING,
  REQUEST_NAME_ANSWER_BLOCKS_LIST_DOWNLOADING,
} from '~/appConstants';

function* watchDownloadAnswerBlocksList() {
  yield takeLatest(DOWNLOAD_ANSWER_BLOCKS, function*(action) {
    const {
      payload: { courseId, lessonId },
    } = action;

    const response = yield apiRequest(
      'result/review-answer-blocks-list',
      { courseId, lessonId },
      'post',
      { name: REQUEST_NAME_ANSWER_BLOCKS_LIST_DOWNLOADING }
    );

    if (!response) {
      return;
    }

    const {
      data: { payload },
    } = response;

    const answerBlocks = keyBy('id', payload.answerBlocks);
    yield put(downloadAnswerBlocksSuccess({ answerBlocks }));
  });
}

function* watchDownloadAnswerBlock() {
  yield takeLatest(DOWNLOAD_ANSWER_BLOCK, function*(action) {
    const {
      payload: { answerBlockResultId },
    } = action;

    const response = yield apiRequest(
      'result/review-answer-block',
      { answerBlockResultId },
      'post',
      { name: REQUEST_NAME_ANSWER_BLOCK_DOWNLOADING }
    );

    if (!response) {
      return;
    }

    const {
      data: { error },
    } = response;

    if (error) {
      return;
    }

    const {
      data: {
        payload: {
          date,
          reply,
          blockId,
          question,
          lessonName,
          courseName,
          isApproved,
          description,
          studentName,
          teacherName,
          studentEmail,
          teacherComment,
          isAlreadyInReview,
          teacherCommentDate,
          isTutor,
          courseId,
          publicationId,
          pageId,
        },
      },
    } = response;

    yield put(
      downloadAnswerBlockSuccess({
        date,
        reply,
        blockId,
        question,
        lessonName,
        courseName,
        isApproved,
        description,
        studentName,
        teacherName,
        studentEmail,
        teacherComment,
        isAlreadyInReview,
        teacherCommentDate,
        isTutor,
        courseId,
        publicationId,
        pageId,
      })
    );
  });
}

function* watchUnlockAnswerBlockReview() {
  yield takeEvery(UNLOCK_ANSWER_BLOCK_REVIEW, function*(action) {
    const {
      payload: { answerBlockResultId },
    } = action;

    yield apiRequest('result/unlock-review-answer-block-comment', {
      answerBlockResultId,
    });
  });
}

function* watchSendTeacherComment() {
  yield takeLatest(SEND_TEACHER_COMMENT, function*(action) {
    const {
      payload: { files, comment, isApproved, answerBlockResultId },
    } = action;

    const response = yield apiRequest(
      'result/review-answer-block-comment',
      {
        files,
        comment,
        isApproved,
        answerBlockResultId,
      },
      'post'
    );

    const {
      data: { error },
    } = response;

    if (error) {
      return;
    }

    const {
      data: {
        payload: {
          comment: teacherComment,
          isApproved: isApprovedResp,
          files: teacherFiles,
          teacherName,
        },
      },
    } = response;

    yield put(
      sendTeacherCommentSuccess({
        teacherFiles,
        teacherComment,
        isApproved: isApprovedResp,
        teacherName,
      })
    );

    yield put(
      updateAnswerBlockStatusSuccess({ isApproved, answerBlockResultId })
    );
  });
}

export default function*() {
  yield all([
    watchDownloadAnswerBlocksList(),
    watchDownloadAnswerBlock(),
    watchSendTeacherComment(),
    watchUnlockAnswerBlockReview(),
  ]);
}
