import { css } from 'styled-components';

export const loader = css`
  width: 100%;
`;

export const tabs = css`
  .bp3-tab-list {
    border-bottom: 1px solid #d0d3d7;
  }
  .bp3-tab {
    color: #979b9e;
  }

  .bp3-tab:hover {
    color: #45494e;
  }

  .bp3-tab[aria-selected='true'] {
    color: #45494e;
  }

  .bp3-tab-indicator {
    background: #e6a42f;
    height: 2px;
  }
`;

export const tabTitle = css`
  padding: 0 12px;
`;
