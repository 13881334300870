import $ from 'jquery';
import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.css';
import { debounce, constant } from 'lodash/fp';
import { withState, pure, compose } from 'recompose';
import PropTypes from 'prop-types';
import objectId from 'bson-objectid';
import { withTranslation } from 'react-i18next';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import { combineStyles } from '../../utils/styles';
import '../../styles/Froala.scss';
import '../../../node_modules/froala-editor/js/froala_editor.pkgd.min';
import '../../../node_modules/froala-editor/css/froala_style.min.css';
import '../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css';
import '../../../node_modules/froala-editor/js/languages/ru';
import '../../../node_modules/font-awesome/css/font-awesome.css';

import {
  htmlAllowedTags,
  htmlAllowedAttrs,
  htmlAllowedEmptyTags,
  htmlRemoveTags,
} from './config.json';

const Froala = ({
  t,
  mode,
  i18n,
  theme,
  isInit,
  variant,
  content,
  onChange,
  className,
  pastePlain,
  changeInit,
  placeholder,
  initOnRender,
  toolbarButtons,
}) => (
  <div className={combineStyles(['Froala', className], variant)}>
    {mode === 'editor' ? (
      <div
        className="editor"
        onMouseEnter={() => !isInit && !initOnRender && changeInit(true)}
      >
        {isInit || initOnRender || !content ? (
          <FroalaEditor
            onModelChange={onChange}
            model={content}
            config={{
              pluginsTranslations: {
                insertFormula: t('froala.insertFormula'),
                close: t('froala.close'),
                insertFillblank: t('froala.insertFillblank'),
                insertPrompt: t('froala.insertPrompt'),
              },
              key: 'hD2B1B2J2C2D5D-17D2E2F2B1E4G1A3B8E7D7nc1QXIa2QZe1UOXATEX==',
              useClasses: true,
              language: i18n.languages[0],
              initOnClick: !initOnRender,
              quickInsertTags: [''],
              placeholderText: placeholder || t('froala.startEnterText'),
              toolbarVisibleWithoutSelection: true,
              charCounterCount: false,
              toolbarInline: true,
              imageUploadURL: `${process.env.API_URL}/upload/image/froala`,
              imageManagerLoadURL: `${process.env.KTZ_API}/images`,
              pastePlain,
              events: {
                'froalaEditor.paste.beforeCleanup': constant(true),
                'froalaEditor.table.inserted': (e, editor, table) =>
                  $(table).wrap('<div class="froala-table-wrapper"></div>'),
              },
              // pasteDeniedTags: pastePlain && ['ol', 'ul', 'li', 'span'],
              // htmlUntouched: true,
              theme,
              toolbarButtons,
              htmlAllowedTags,
              htmlRemoveTags,
              htmlAllowedAttrs,
              htmlAllowedEmptyTags,
            }}
          />
        ) : (
          <FroalaEditorView className="preview" model={content} />
        )}
      </div>
    ) : mode === 'preview' ? (
      <FroalaEditorView className="preview" model={content} />
    ) : (
      undefined
    )}
  </div>
);

const {
  oneOf,
  arrayOf,
  oneOfType,
  string,
  array,
  func,
  bool,
  object,
} = PropTypes;

Froala.propTypes = {
  t: func.isRequired, // Функция перевода
  i18n: object.isRequired,
  mode: oneOf(['editor', 'preview']).isRequired, // Тип редактора
  theme: string, // Класс для кастомизации тулбара
  isInit: bool, // Фрола инициализирвана
  onChange: func, // При изменении контента
  content: string, // Контент
  variant: oneOfType([array, string]), // Вариант оформления
  pastePlain: bool, // Вставка без тегов
  changeInit: func, // Инициализируем фролу
  placeholder: string, // Текст в пустом поле
  className: string,
  initOnRender: bool, // Инициализировать при рендере?
  toolbarButtons: arrayOf(string), // Кнопки внутри тулбара
};

Froala.defaultProps = {
  theme: '',
  isInit: false,
  content: '',
  variant: undefined,
  onChange: undefined,
  pastePlain: false,
  changeInit: undefined,
  placeholder: undefined,
  initOnRender: false,
  toolbarButtons: undefined,
};

// necessary for ssr
if ($.FroalaEditor) {
  /* eslint-disable fp/no-mutation, fp/no-this, object-shorthand, prefer-arrow-callback */
  // Шаблоны попапа (html определяется в initPopup)
  $.extend($.FroalaEditor.POPUP_TEMPLATES, {
    'formula.popup': '[_BUTTONS_][_CUSTOM_LAYER_]',
    'fillblank.popup': '[_BUTTONS_][_CUSTOM_LAYER_]',
    'prompt.popup': '[_BUTTONS_][_CUSTOM_LAYER_]',
  });

  // Блюпритовский темплейт для иконок
  $.extend($.FroalaEditor.ICON_TEMPLATES, {
    blueprint: '<span class="bp3-icon bp3-icon-[NAME]"></span>',
  });

  // Список кнопок, сюда надо занести все кнопки для построения строк в initPopup
  $.extend($.FroalaEditor.DEFAULTS, {
    popupButtons: ['popupClose', '|', 'multButton', 'parButton', 'katexHelp'],
    promptButtons: ['promptClose', '|', 'insertPrompt']
  });

  // Плагин вставки текста с подсказкой
  $.FroalaEditor.PLUGINS.prompt = (editor) => {
    const { language, pluginsTranslations } = editor.opts;
    if ($.FE.LANGUAGE[language]) {
      $.FE.LANGUAGE[language].translation['Insert text with prompt'] = pluginsTranslations.insertPrompt;
      $.FE.LANGUAGE[language].translation.Close = pluginsTranslations.close;
    }
    const hide = () => { editor.popups.hide('prompt.popup') }
    const init = () => {
      const buttons = `<div class="fr-buttons">${editor.button.buildList(editor.opts.promptButtons)}</div>`;
      const template = {
        buttons,
        custom_layer: `
          <div class="custom-layer">
            <div class="fr-input-line">
              <input class="text" type="text" placeholder="Текст" />
            </div>
            <div class="fr-input-line">
              <input class="tooltip" type="text" placeholder="Подсказка" />
            </div>
            <div class="fr-action-buttons">
              <button class="fr-command fr-submit" data-cmd="promptInsert" href="#" type="button">
                ${editor.language.translate('Insert')}
              </button>
            </div>
          </div>
        `,
      };
      const popup = editor.popups.create('prompt.popup', template);
      return popup
    }
    const show = () => {
      if (!editor.popups.get('prompt.popup')) { init(); }
      editor.popups.setContainer('prompt.popup', editor.$tb);
      const button = editor.$tb.find('.fr-command[data-cmd="promptButton"]');
      const left = (button.offset().left + button.outerWidth()) / 2;
      const top = button.offset().top + (editor.opts.toolbarBottom ? 10 : button.outerHeight() - 10);
      editor.popups.show('prompt.popup', left, top, button.outerHeight());
    }
    const insert = () => {
      const popup = editor.popups.get('prompt.popup');
      const text = popup.find('input.text');
      const tooltip = popup.find('input.tooltip');
      editor.selection.restore();
      const html = `
        <span class="prompt">${text.val()}
          <span class="tooltip">${tooltip.val()}</span>
        </span>
      `
      editor.html.insert(html, true);
      hide();
      text.val('');
      tooltip.val('');
      return false;
    }
    return {
      show,
      hide,
      insert,
    }
  }

  $.FroalaEditor.DefineIcon('promptIcon', {
    NAME: 'comment',
    template: 'blueprint',
  });
  $.FroalaEditor.RegisterCommand('promptButton', {
    title: 'Insert text with prompt',
    icon: 'promptIcon',
    undo: false,
    focus: false,
    plugin: 'prompt',
    callback() {
      this.selection.save();
      this.prompt.show();
    },
  });
  $.FroalaEditor.RegisterCommand('promptInsert', {
    focus: false,
    refreshAfterCallback: false,
    callback() { this.prompt.insert() },
  })
  $.FroalaEditor.DefineIcon('promptClose', { NAME: 'times' });
  $.FroalaEditor.RegisterCommand('promptClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback() {
      this.prompt.hidePopup();
    },
  });

  // Плагин вставки TeX формулы с помощью KaTeX
  $.FroalaEditor.PLUGINS.formula = function formulaPlugin(editor) {
    const { language, pluginsTranslations } = editor.opts;

    if ($.FE.LANGUAGE[language]) {
      $.FE.LANGUAGE[language].translation['Insert formula'] =
        pluginsTranslations.insertFormula;
      $.FE.LANGUAGE[language].translation.Close = pluginsTranslations.close;
    }
    function hidePopup() {
      editor.popups.hide('formula.popup');
    }

    function initPopup() {
      // Список кнпок для шаблона
      const popupButtons = `<div class="fr-buttons">${editor.button.buildList(
        editor.opts.popupButtons
      )}</div>`;

      // Загружаем шаблоны, которые мы выше определили как [_BUTTONS_][_CUSTOM_LAYER_].
      const template = {
        buttons: popupButtons,
        custom_layer: `
        <div class="custom-layer">
          <div class="fr-input-line">
            <input class="source" type="text" />
          </div>
          <div class="fr-input-line">
            <div class="preview">Preview</div>
          </div>
          <div class="fr-action-buttons">
            <button class="fr-command fr-submit" data-cmd="linkFormulaInsert" href="#" type="button">
              ${editor.language.translate('Insert')}
            </button>
          </div>
        </div>
      `,
      };

      const $popup = editor.popups.create('formula.popup', template);
      const $input = $popup.find('input.source');
      const $preview = $popup.find('.preview');

      // Показываем превью формулы
      $input.on(
        'keyup paste change',
        debounce(100)(function formulaSourceChange() {
          // KaTeX не дружит с кириллицей
          this.value = this.value.replace(/[а-яё]/gi, '');
          katex.render($input.val(), $preview.get(0));
        })
      );

      return $popup;
    }

    function showPopup() {
      if (!editor.popups.get('formula.popup')) {
        // Попап инициализируется во время первого показа
        initPopup();
      }

      // Контейнер для попапа (из примера)
      editor.popups.setContainer('formula.popup', editor.$tb);

      // Рефрешим попап при открытии?
      // editor.popups.refresh('formula.popup');

      // Получем нашу кнопку из тулбара, для того, чтобы спозиционировать относительно нее
      const $btn = editor.$tb.find('.fr-command[data-cmd="formulaButton"]');

      // Определяем позицию попапа
      // eslint-disable-next-line no-mixed-operators
      const left = $btn.offset().left + $btn.outerWidth() / 2;
      const top =
        $btn.offset().top +
        (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

      // Показываем попап
      editor.popups.show('formula.popup', left, top, $btn.outerHeight());
    }

    // Вставка формулы в редактор
    function insertCallback() {
      const $popup = editor.popups.get('formula.popup');
      const $input = $popup.find('input.source');
      const $preview = $popup.find('.preview');

      editor.selection.restore();
      editor.html.insert(katex.renderToString($input.val()), false);

      // Закрываем и очищаем поля попапа
      hidePopup();
      $input.val('');
      $preview.html('');

      return false;
    }

    // Получаем позицию курсора или выделения
    function getSelection($input) {
      const { selectionStart, selectionEnd } = $input.get(0);

      return {
        selectionStart,
        selectionEnd,
      };
    }

    // Добавление символа в инпут формулы
    function addSym(sym = '') {
      const $popup = editor.popups.get('formula.popup');
      const $input = $popup.find('input.source');
      const position = getSelection($input).selectionEnd;
      const text = $input.val();

      // Вставляем символ по позиции курсора
      $input.val(
        text.slice(0, position) + sym + text.slice(position, text.length)
      );
      $input.trigger('change');
    }

    // Оборачиваем выделение в инпуте формулы
    function wrapSym(left = '', right = '') {
      const $popup = editor.popups.get('formula.popup');
      const $input = $popup.find('input.source');
      const selection = getSelection($input);
      const text = $input.val();

      // Оборачиваем выделенные символы
      if (selection.selectionStart !== selection.selectionEnd) {
        const { selectionStart, selectionEnd } = $input.get(0);

        $input.val(
          text.slice(0, selectionStart) +
            left +
            text.slice(selectionStart, selectionEnd) +
            right +
            text.slice(selectionEnd, text.length)
        );

        $input.trigger('change');
      } else {
        // Вставляем по позиции курсора, если нет выделения
        const position = selection.selectionEnd;
        $input.val(
          text.slice(0, position) +
            left +
            right +
            text.slice(position, text.length)
        );
        $input.trigger('change');
      }
    }

    // Экспортируем публичные методы
    return {
      showPopup,
      hidePopup,
      addSym,
      wrapSym,
      insertCallback,
    };
  };

  // Плагин вставки TeX формулы с помощью KaTeX
  $.FroalaEditor.PLUGINS.fillblank = function fillblankPlugin(editor) {
    const { language, pluginsTranslations } = editor.opts;

    if ($.FE.LANGUAGE[language]) {
      $.FE.LANGUAGE[language].translation['Insert fillblank'] =
        pluginsTranslations.insertFillblank;
    }

    // Вставка пропуска в редактор
    function insertFillblank() {
      if (editor.html.getSelected().slice(1, 5) !== 'span') {
        const blankId = objectId.generate();
        // HTML шаблон поля ввода пропуска
        const insertInput = `<span
        id="${blankId}"
        class="blank fr-deletable"
        contenteditable="true"></span> `;

        editor.selection.restore();
        editor.html.insert(insertInput);
      }
      return false;
    }

    // Экспортируем публичные методы
    return {
      insertFillblank,
    };
  };

  // Кнопка открытия попапа плагина на тулбаре
  $.FroalaEditor.DefineIcon('buttonIcon', {
    NAME: 'derive-column',
    template: 'blueprint',
  });
  $.FroalaEditor.RegisterCommand('formulaButton', {
    title: 'Insert formula',
    icon: 'buttonIcon',
    undo: false,
    focus: false,
    plugin: 'formula',
    callback: function openFormulaPopup() {
      this.selection.save();
      this.formula.showPopup();
    },
  });

  // Кнопка вставки пропуска в редактор
  $.FroalaEditor.DefineIcon('fillblankButtonIcon', {
    NAME: 'osh-input',
    template: 'blueprint',
  });
  $.FroalaEditor.RegisterCommand('fillblankButton', {
    title: 'Insert fillblank',
    icon: 'fillblankButtonIcon',
    undo: false,
    focus: false,
    plugin: 'fillblank',
    callback: function insertFillblank() {
      this.selection.save();
      this.fillblank.insertFillblank();
    },
  });

  // Кнопка закрытия внутри попапа
  $.FroalaEditor.DefineIcon('popupClose', { NAME: 'times' });
  $.FroalaEditor.RegisterCommand('popupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function closeFormulaPopup() {
      this.formula.hidePopup();
    },
  });

  // Кнопка помощи по синтаксису KaTeX'a
  $.FroalaEditor.DefineIcon('katexHelp', {
    NAME: 'help',
    template: 'blueprint',
  });
  $.FroalaEditor.RegisterCommand('katexHelp', {
    title: 'Help',
    undo: false,
    focus: false,
    callback: () =>
      window.open(
        'https://khan.github.io/KaTeX/function-support.html',
        '_blank'
      ),
  });

  $.FroalaEditor.RegisterCommand('linkFormulaInsert', {
    focus: false,
    refreshAfterCallback: false,
    callback: function formulaInsert() {
      this.formula.insertCallback();
    },
  });
}

const enhance = compose(
  withState('isInit', 'changeInit', false),
  withTranslation('components'),
  pure
);

/* eslint-enable */

export default enhance(Froala);
