import { set, compose } from 'lodash/fp';

import editReducer, { defaultState as editState } from './edit';
import shareReducer, { defaultState as shareState } from './share';
import searchReducer, { defaultState as searchState } from './search';

export const defaultState = {
  edit: editState,
  share: shareState,
  search: searchState,
};

const uiReducer = (state = defaultState, action) =>
  compose(
    set('edit', editReducer(state.edit, action)),
    set('share', shareReducer(state.share, action)),
    set('search', searchReducer(state.search, action))
  )(state);

export default uiReducer;
