import * as AUTH from '../../constants/auth';
import { createAction } from '~/utils/actions';

export const logout = () => ({
  type: AUTH.LOGOUT,
});

export const sendRequest = ({ mode, goto, form }) => ({
  type: AUTH.SEND_REQUEST,
  payload: { mode, goto, form },
});

export const sendRecoveryRequest = ({ email }) => ({
  type: AUTH.RECOVERY_REQUEST,
  payload: { email },
});

export const sendNewPassword = ({ password, changePasswordId }) => ({
  type: AUTH.SEND_NEW_PASSWORD,
  payload: { password, changePasswordId },
});

export const requestError = (message) => ({
  type: AUTH.REQUEST_ERROR,
  payload: { message },
});

export const requestSuccess = ({
  key,
  userId,
  userName,
  userEmail,
  isAdmin,
  secret,
  role,
}) => ({
  type: AUTH.REQUEST_SUCCESS,
  payload: { key, userId, userName, userEmail, isAdmin, secret, role },
});

export const requestForbidden = () => ({
  type: AUTH.REQUEST_FORBIDDEN,
});

export const signIn = ({
  userId,
  userName,
  userEmail,
  isAdmin,
  secret,
  role,
  subscription,
  verified,
}) => ({
  type: AUTH.SIGN_IN,
  payload: {
    userId,
    userName,
    userEmail,
    isAdmin,
    secret,
    role,
    subscription,
    verified,
  },
});

export const changeName = ({ name }) =>
  createAction(AUTH.CHANGE_NAME, { name });

export const changeNameSuccess = ({ name }) =>
  createAction(AUTH.CHANGE_NAME_SUCCESS, { name });

export const setRole = (role) => ({
  type: AUTH.SET_ROLE,
  payload: { role },
});

export const setRoleError = (message) => ({
  type: AUTH.SET_ROLE_ERROR,
  payload: { message },
});

export const setRoleSuccess = (role) => ({
  type: AUTH.SET_ROLE_SUCCESS,
  payload: { role },
});

export const verifyEmail = (code) => ({
  type: AUTH.VERIFY_EMAIL,
  payload: { code },
});

export const reSendVerificationEmail = () => ({
  type: AUTH.RESEND_VERIFICATION_EMAIL,
});

export const reSendVerificationEmailSuccess = (date) => ({
  type: AUTH.RESEND_VERIFICATION_EMAIL_SUCCESS,
  payload: { date },
});
