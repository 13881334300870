import objectId from 'bson-objectid';
import * as QUIZ from '../../../constants/builder/block/quiz';
import { createAction } from '../../../utils/actions';

export const addAnswer = (blockId) =>
  createAction(QUIZ.ADD_ANSWER, { blockId, answerId: objectId.generate() });
export const addAnswerOther = ({ blockId }) =>
  createAction(QUIZ.ADD_ANSWER_OTHER, {
    blockId,
    answerId: objectId.generate(),
  });
export const removeAnswerOther = (blockId) =>
  createAction(QUIZ.REMOVE_ANSWER_OTHER, { blockId });
export const setAnswer = (blockId, answerId) =>
  createAction(QUIZ.SET_ANSWER, { blockId, answerId });
export const toggleType = (blockId) =>
  createAction(QUIZ.TOGGLE_TYPE, { blockId });
export const toggleShuffle = (answersIdsOrder, blockId) =>
  createAction(QUIZ.TOGGLE_SHUFFLE, { answersIdsOrder, blockId });
export const changeAnswersQuantity = ({ quantity, blockId }) =>
  createAction(QUIZ.CHANGE_QUANTITY, { quantity, blockId });
export const moveAnswer = (blockId, oldIndex, newIndex) =>
  createAction(QUIZ.MOVE_ANSWER, { blockId, oldIndex, newIndex });
export const changeAnswer = (blockId, answerId, newAnswer) =>
  createAction(QUIZ.CHANGE_ANSWER, { blockId, answerId, newAnswer });
export const removeAnswer = (blockId, answerId) =>
  createAction(QUIZ.REMOVE_ANSWER, { blockId, answerId });
export const changeQuestion = (blockId, newQuestion) =>
  createAction(QUIZ.CHANGE_QUESTION, { blockId, newQuestion });
export const changeDescription = (blockId, newDescription) =>
  createAction(QUIZ.CHANGE_DESCRIPTION, { blockId, newDescription });
export const changeComment = (blockId, answerId, newComment) =>
  createAction(QUIZ.CHANGE_COMMENT, { blockId, answerId, newComment });
export const toggleComment = (blockId, answerId, comment) =>
  createAction(QUIZ.TOGGLE_COMMENT, { blockId, answerId, comment });

// Для весовых вопросов
export const changeGroup = (blockId, newGroup) =>
  createAction(QUIZ.CHANGE_GROUP, { blockId, newGroup });
export const changeLevel = (blockId, newLevel) =>
  createAction(QUIZ.CHANGE_LEVEL, { blockId, newLevel });
export const changeWeight = (blockId, answerId, newWeight) =>
  createAction(QUIZ.CHANGE_WEIGHT, { blockId, answerId, newWeight });
export const changeCompetence = (blockId, newCompetence) =>
  createAction(QUIZ.CHANGE_COMPETENCE, { blockId, newCompetence });
