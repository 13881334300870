import { css, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.05, 1.05);
  }
  50% {
    opacity: .2;
    transform: scale(1, 1);
  }
  100% {
    opacity: .4;
    transform: scale(1.05, 1.05);
  }
`;

export const container = css`
  display: inline-block;
  text-align: center;

  &.center {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
`;

export const dotLoader = css`
  display: inline-flex;
  min-height: 30px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const calculateSize = ({ size }) => {
  if (size === 'medium') {
    return 16;
  }
  return 10;
};

const calculateMargin = ({ size }) => {
  if (size === 'medium') {
    return '3';
  }
  return 2;
};

export const dot = css`
  animation: ${pulseAnimation} 0.9s infinite ease-in-out;
  background-color: #45494e;
  border-radius: 16px;
  height: ${calculateSize}px;
  width: ${calculateSize}px;
  margin: ${calculateMargin}px;

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }
`;
