import { compose, matches, omitBy, set } from 'lodash/fp';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as PLAYER from '~/constants/player';

const previouslyExecutedResults = (state = {}, action) => {
  switch (action.type) {
    case PLAYER.LOGOUT: {
      return {};
    }

    case PLAYER.DOWNLOAD_EXECUTION_SUCCESS: {
      const {
        payload: { result },
      } = action;

      const { id, lessonId, publication, isLtiLesson } = result;
      return compose(
        set(id, { id, lessonId, publication, isLtiLesson }),
        omitBy(matches({ lessonId, publication, isLtiLesson }))
      )(state);
    }

    // TODO: выяснить, почему при установке нового результата старый стрирается из persist'а
    case PLAYER.SET_NEW_RESULT: {
      const {
        payload: { result },
      } = action;

      const { lessonId, publication, isLtiLesson } = result;
      return omitBy(matches({ lessonId, publication, isLtiLesson }))(state);
    }

    case PLAYER.CLEAR_RESULT: {
      const {
        payload: { lessonId, publication },
      } = action;

      return omitBy(matches({ lessonId, publication }))(state);
    }
    case PLAYER.LESSON.SEND_SUCCESS: {
      const { id, lessonId, publication, isLtiLesson } = action.payload.result;

      // TODO Нужно ли сохранять просмотр результата?
      // if (status === RESULT_STATUS_COMPLETE) {
      //   return omitBy(matches({ lessonId, publication }), state);
      // }

      return compose(
        set(id, { id, lessonId, publication, isLtiLesson }),
        omitBy(matches({ lessonId, publication, isLtiLesson }))
      )(state);
    }

    default:
      return state;
  }
};

export default persistReducer(
  { key: 'player.previouslyExecutedResults', storage },
  previouslyExecutedResults
);
