import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const PlanCard = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 24.5%;
  min-height: 240px;
  margin-left: 10px;
  border-radius: 3px;
  border: solid 1px #e5e9ec;
  box-sizing: border-box;

  ${ifProp(
    'active',
    css`
      box-shadow: inset 0px 0px 0px 2px #e6a42f;
    `,
    css`
      &:hover {
        border: solid 1px #d0d3d7;
      }
    `
  )}
  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `
  )}


  :first-child {
    margin-left: 0;
  }
`;

export const divider = css`
  width: 100%;
  border: solid 1px #dde1e6;
  margin: 16px 0;
`;

export const space = css`
  width: 100%;
  height: 30px;
`;

export const infoText = css`
  flex-shrink: 0;
  width: 174px;
  font-family: CirceRounded;
  font-size: 18px;
  color: #767b84;
`;

export const priceText = css`
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  color: #45494e;
`;

export const bottomRow = css`
  display: flex;
  margin-top: 15px;
`;

export const bottomLeft = css`
  width: 361px;
  flex-shrink: 0;
  margin-right: 75px;
`;

export const bottomRight = css`
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 250px;
`;

export const rText = css`
  font-family: CirceRounded;
  font-size: 18px;
  color: #767b84;
`;

export const periodText = css`
  font-family: CirceRounded;
  font-size: 18px;
  color: #45494e;
`;

export const bonusText = css`
  margin-left: 174px;
  margin-top: 6px;
  font-family: CirceRounded;
  font-size: 14px;
  color: #767b84;
`;

export const descriptionText = css`
  font-family: CirceRounded;
  font-size: 14px;
  color: #767b84;
`;

export const buttons = css`
  display: flex;
  margin: 0 0 27px;
  justify-content: center;

  .Checkbox {
    padding-right: 0;
  }
`;

export const terms = css`
  display: flex;
  margin-bottom: 12px;
  justify-content: center;

  .Checkbox {
    padding-right: 0;
  }
  .Froala {
    z-index: 100;
  }
`;

export const icon = css`
  margin-right: 23px;
  margin-bottom: 14px;
`;

export const button = css`
  margin-right: 17px;
`;

export const tinkoffIcon = css`
  margin-left: 6px;
`;

export const bottom = css`
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding: 32px;
  border-radius: 4px;
  background-color: #f4f6f7;
  margin: 0 auto 12px;
  width: 695px;
  min-height: 190px;
`;

export const bottomTitle = css`
  font-family: Gilroy;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #45494e;
`;

export const container = css`
  width: 855px;
  border-radius: 3px;
  padding: 32px 40px 30px 77px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

export const header = css`
  display: flex;
  position: relative;
  align-items: center;
  padding: 30px 20px 0px;

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .more {
    margin-right: 72px;
    .bp3-button {
      padding: 0;
      background: transparent;
    }

    a {
      display: block;
      padding: 10px 22px;
      color: #767b84;
      text-decoration: none;
    }
  }
`;

export const plansRow = css`
  width: 695px;
  padding-left: 3px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
`;

export const title = css`
  font-family: Gilroy;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #45494e;
  padding: 0 16px;
  flex: 1;
`;

export const whiteCard = css`
  width: 220px;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 24px;

  .title {
    font-family: CirceRounded;
    font-size: 9px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #aeb3bd;
  }

  .counter {
    font-family: Gilroy;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.09px;
    color: #45494e;
    margin-top: 10px;
  }
`;

export const emoji = css`
  margin-right: 16px;
  font-size: 34px;
  letter-spacing: -0.09px;
  text-align: center;
  color: #45494e;
`;

export const limitText = css`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: #45494e;
  margin-right: 40px;
`;

export const dotLoader = css`
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  min-height: 32px;
`;

export const otherCard = css`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const modalDescription = css`
  width: 100%;
  margin: 0 auto 12px;
  width: 695px;
  font-family: CirceRounded;
  font-size: 18px;
  color: #45494e;
`;
