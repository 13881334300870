export const ADD = '@builder/page/Добавление страницы';
export const MOVE = '@builder/page/Перемещение страницы';
export const PASTE = '@builder/page/Вставка страницы';
export const CLONE = '@builder/page/Клонирование страницы';
export const REMOVE = '@builder/page/Удаление страницы';
export const CHANGE_NAME = '@builder/page/Переименование страницы';
export const ENABLE_SHUFFLE = '@builder/page/Включение режима рандомизации';
export const DISABLE_SHUFFLE = '@builder/page/Выключение режима рандомизации';
export const TOGGLE_EXAM_MODE =
  '@builder/page/Переключение контрольного режима для страницы';
export const CHANGE_BLOCKS_QUANTITY =
  '@builder/page/Изменение количества отображаемых блоков';
