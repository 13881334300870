import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { combineStyles } from '../utils/styles';
import '../styles/CircleProgressBar.scss';

const CircleProgressBar = ({ total, value, variant, diameter }) => (
  <div className={combineStyles('CircleProgressBar', variant)}>
    <div className="result">
      {value}/{total}
    </div>
    <svg
      viewBox="-38 62 79 79"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={diameter}
      height={diameter}
      className="circle"
    >
      <circle
        className="bar"
        cx="0"
        cy="0"
        r="35"
        stroke="#8acf00"
        strokeOpacity="1"
        strokeLinecap="round"
        fill="none"
        strokeWidth="5"
        transform="rotate(-90 50 50)"
        style={{ strokeDashoffset: total ? 300 - 225 * (value / total) : 0 }}
      />
    </svg>
  </div>
);

const { string, number, oneOfType, array } = PropTypes;

CircleProgressBar.propTypes = {
  value: number, // Текущее значение
  total: number, // Максимальное значение
  variant: oneOfType([array, string]), // Вариант оформления
  diameter: number, // Диаметр круга
};

CircleProgressBar.defaultProps = {
  total: 0,
  value: 0,
  variant: null,
  diameter: 40,
};

export default pure(CircleProgressBar);
