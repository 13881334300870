import { DOWNLOAD_LESSONS_SUCCESS } from '~/constants/tutors';

const defaultState = {};

const lessonsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_LESSONS_SUCCESS: {
      const {
        payload: { lessons },
      } = action;
      return lessons;
    }

    default:
      return state;
  }
};

export default lessonsReducer;
