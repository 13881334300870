import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { map, filter, mapValues, compose, invert, replace } from 'lodash/fp';
import { Popover } from '@blueprintjs/core';

import {
  TALK_TARGET_TYPE_FINAL,
  TALK_TARGET_TYPE_CONTENT_CARD,
  TALK_CARD_TYPE_QUESTION,
} from '~/appConstants';
import { Button } from '~/components';
import * as selectors from '~/selectors';
import * as s from '../styles/NextCardSelector.styles';

const htmlToText = replace(/(<[^>]*>?)|(&nbsp;)/gm, '');

const NextCardSelector = ({
  blockId,
  cardId,
  onChange,
  selectedTarget,
  onCreateCard,
}) => {
  const { t } = useTranslation('containersBlock');
  const { content } = useSelector(selectors.builder.getBlock(blockId));

  const targetsCards = compose(
    map((id) => content.cards[id]),
    filter((id) => id !== cardId)
  )(content.cardsIds);

  const indexesById = compose(
    mapValues((stringIndex) => +stringIndex + 1),
    invert
  )(content.cardsIds);

  return (
    <Popover
      popoverClassName="clear-popover"
      modifiers={{ arrow: false }}
      position="bottom-left"
      target={
        <div css={s.target}>
          <s.Input selected={!!selectedTarget}>
            {selectedTarget
              ? selectedTarget.type === TALK_TARGET_TYPE_FINAL
                ? '✓'
                : indexesById[selectedTarget.cardId]
              : '—'}
          </s.Input>
        </div>
      }
      content={
        <div css={s.cardSelect}>
          <Button
            icon="plus"
            minimal
            variant="navuserbar-dropdown"
            onClick={() => {
              onCreateCard();
              setImmediate(() => {
                const target = document.querySelector(
                  `#c${content.cardsIds.length + 1}`
                );
                if (target) {
                  target.scrollIntoView({ behavior: 'smooth' });
                }
              });
            }}
            text={t('talk.newScreen')}
          />
          <Button
            icon="tick"
            minimal
            variant="navuserbar-dropdown"
            onClick={() => onChange({ type: TALK_TARGET_TYPE_FINAL })}
            text={t('talk.talkEnd')}
          />
          <div css={s.cards}>
            {targetsCards.map(({ id, question, information, type }) => (
              <Button
                minimal
                variant="navuserbar-dropdown"
                onClick={() =>
                  onChange({
                    cardId: id,
                    type: TALK_TARGET_TYPE_CONTENT_CARD,
                  })
                }
                key={id}
                text={
                  <div css={s.buttonContent}>
                    <div css={s.buttonIndex}>{indexesById[id]}</div>
                    <span css={s.buttonText}>
                      {type === TALK_CARD_TYPE_QUESTION
                        ? question
                        : htmlToText(information)}
                      &nbsp;
                    </span>
                  </div>
                }
              />
            ))}
          </div>
        </div>
      }
    />
  );
};

const { string, func, shape } = PropTypes;

NextCardSelector.propTypes = {
  blockId: string.isRequired,
  cardId: string.isRequired,
  onChange: func.isRequired,
  onCreateCard: func.isRequired,
  selectedTarget: shape({
    cardId: string,
    type: string.isRequired,
  }),
};

export default NextCardSelector;
