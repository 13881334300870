export const ADD_BLANK =
  '@builder/block/fillblank/Добавление поля для заполнения';
export const CHANGE_TITLE =
  '@builder/block/fillblank/Изменение текста заголовка';
export const CHANGE_QUESTION =
  '@builder/block/fillblank/Изменение текста вопроса';
export const SAVE_EDITOR_PARAMS =
  '@builder/block/fillblank/Сохранение параметров редактора';
export const SET_CORRECT_ANSWERS =
  '@builder/block/fillblank/Сохранение правильных ответов';
export const CLEAR_CORRECT_ANSWERS =
  '@builder/block/fillblank/Очистка правильных ответов';
