import * as PLAYER from '../../constants/player';
import { createAction } from '../../utils/actions';

import * as lesson from './lesson';
import * as block from './block';
import * as page from './page';

export { page, block, lesson };

export const sendRequest = ({ mode, goto, result, form }) =>
  createAction(PLAYER.SEND_REQUEST, { mode, goto, result, form });

export const login = ({ userName, userId, email }) =>
  createAction(PLAYER.LOGIN, { userName, userId, email });

export const logout = () => createAction(PLAYER.LOGOUT);

export const preLogout = () => createAction(PLAYER.PRE_LOGOUT);

export const changeActivePage = (pageId) =>
  createAction(PLAYER.CHANGE_ACTIVE_PAGE, { pageId });

export const toggleMode = () => createAction(PLAYER.TOGGLE_MODE);

export const changeFontSize = (fontSize) =>
  createAction(PLAYER.CHANGE_FONT_SIZE, { fontSize });

export const changeTheme = (theme) =>
  createAction(PLAYER.CHANGE_THEME, { theme });

export const enterByCode = (code) =>
  createAction(PLAYER.ENTER_BY_CODE, { code });

export const downloadLesson = ({ lessonId, publication }) =>
  createAction(PLAYER.DOWNLOAD_LESSON, { lessonId, publication });

export const downloadLessonPreview = ({ publicationId }) =>
  createAction(PLAYER.DOWNLOAD_LESSON_PREVIEW, { publicationId });

export const setNewResult = ({ result }) =>
  createAction(PLAYER.SET_NEW_RESULT, { result });

export const clearResult = ({ lessonId, publication }) =>
  createAction(PLAYER.CLEAR_RESULT, { lessonId, publication });

export const enterByCodeError = (message) =>
  createAction(PLAYER.ENTER_BY_CODE_ERROR, { message });

export const enterByCodeSuccess = (lessonId) =>
  createAction(PLAYER.ENTER_BY_CODE_SUCCESS, { lessonId });

export const downloadLessonError = (errorType, errorData, errorMessage) =>
  createAction(PLAYER.DOWNLOAD_LESSON_ERROR, {
    errorType,
    errorMessage,
    errorData,
  });

// eslint-disable-next-line no-shadow
export const downloadLessonSuccess = ({ lesson, pages, blocks }) =>
  createAction(PLAYER.DOWNLOAD_LESSON_SUCCESS, { lesson, pages, blocks });

export const resumeExecution = ({ resultId }) =>
  createAction(PLAYER.RESUME_EXECUTION, { resultId });

export const showUserWelcome = () => createAction(PLAYER.SHOW_USER_WELCOME);

export const hideUserWelcome = () => createAction(PLAYER.HIDE_USER_WELCOME);

export const downloadExecutionError = (message) =>
  createAction(PLAYER.DOWNLOAD_EXECUTION_ERROR, { message });

export const downloadExecutionSuccess = ({ result }) =>
  createAction(PLAYER.DOWNLOAD_EXECUTION_SUCCESS, { result });

export const setExecutionTime = ({ newExecutionTime }) =>
  createAction(PLAYER.SET_EXECUTION_TIME, { newExecutionTime });

export const showOfferNewVersion = () =>
  createAction(PLAYER.SHOW_OFFER_NEW_VERSION);
export const hideOfferNewVersion = () =>
  createAction(PLAYER.HIDE_OFFER_NEW_VERSION);

export const checkPreviousExecution = () =>
  createAction(PLAYER.CHECK_PREVIOUS_EXECUTION);
export const downloadUserExecution = () =>
  createAction(PLAYER.DOWNLOAD_PREVIOUS_EXECUTION);
export const downloadUserExecutionSuccess = ({ result }) =>
  createAction(PLAYER.DOWNLOAD_USER_EXECUTION_SUCCESS, { result });
export const clearUserExecution = () =>
  createAction(PLAYER.CLEAR_USER_EXECUTION);
export const updateExecutionResult = () =>
  createAction(PLAYER.UPDATE_EXECUTION_RESULT);
export const updateExecutionResultSuccess = ({ pages }) =>
  createAction(PLAYER.UPDATE_EXECUTION_RESULT_SUCCESS, { pages });
