import {
  set,
  omit,
  update,
  compose,
  pullAt,
  filter,
  pull,
  keys,
  concat,
  get,
  reject,
} from 'lodash/fp';
import { UPLOAD_TYPE_COURSE, COURSE_STATUS_NOT_ACTIVE } from '~/appConstants';
import { push, insertAt } from '../../utils/array';
import uploaderReducer from '../uploader';
import * as COURSE from '../../constants/builder/course';
import * as BUILDER from '../../constants/builder';
import * as UPLOADER from '../../constants/uploader';

export const defaultState = {};

const createCourse = (creatorId, createdAt) => ({
  name: '', // Название курса
  description: '', // Описание курса
  publicMode: false, // Открытый курс
  creatorId, // ID создателя курса
  createdAt, // Время создания курса
  lessonsIds: [], // Угадайте что это ?) это же список ID уроков,
  invites: [], // Массив приглашений
  // TODO: Выглядит как костыль. Попробовать убрать:
  removingInviteEmails: [], // Массив приглашений которые удалятся если действие не отменят
  status: COURSE_STATUS_NOT_ACTIVE, // Статус "Не активен" при создании
});

const applyUploaderReducer = ({ objectId, state, action, filePath }) =>
  set(
    filePath ? [...filePath, 'uploader'] : [objectId, 'content', 'uploader'],
    uploaderReducer(
      get(
        filePath
          ? [...filePath, 'uploader']
          : [objectId, 'content', 'uploader'],
        state
      ),
      action
    )
  );

const coursesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case COURSE.ADD: {
      const {
        payload: { id },
        meta: { creatorId, createdAt },
      } = action;
      return set(id, createCourse(creatorId, createdAt), state);
    }

    case COURSE.CHANGE_NAME: {
      const { id, newName } = action.payload;
      return set([id, 'name'], newName, state);
    }

    case COURSE.CHANGE_DESCRIPTION: {
      const { id, newDescription } = action.payload;
      return set([id, 'description'], newDescription, state);
    }

    case COURSE.SET_STATUS_SUCCESS: {
      const { id, status } = action.payload;
      return set([id, 'status'], status, state);
    }

    case COURSE.TOGGLE_PUBLIC_MODE: {
      const { id } = action.payload;
      return set([id, 'publicMode'], !state[id].publicMode, state);
    }

    case COURSE.REMOVE_ACCEPT: {
      const { id } = action.payload;
      return omit(id, state);
    }

    case COURSE.SEND_STUDENTS_INVITES_SUCCESS: {
      const { id, addedEmails, invalidQuantity } = action.payload;
      return compose(
        set([id, 'lastInvites'], addedEmails),
        set([id, 'invalidInvitesQuantity'], invalidQuantity),
        update([id, 'invites'], push(addedEmails.filter((item) => item.valid)))
      )(state);
    }

    case COURSE.REMOVE_STUDENT: {
      const { id, email } = action.payload;
      return update(
        [id, 'removingInviteEmails'],
        (emails = []) => push(email)(emails),
        state
      );
    }

    case COURSE.REMOVE_STUDENT_CANCEL: {
      const { id, email } = action.payload;
      return update(
        [id, 'removingInviteEmails'],
        filter((removingEmail) => removingEmail !== email),
        state
      );
    }

    case COURSE.REMOVE_STUDENT_SUCCESS: {
      const { id, email } = action.payload;
      return compose(
        // TODO: кажется, что это можно сделать проще
        update(
          [id, 'removingInviteEmails'],
          filter((removingEmail) => removingEmail !== email)
        ),
        update([id, 'invites'], filter((invite) => invite.email !== email))
      )(state);
    }

    case COURSE.SEND_TUTORS_INVITES_SUCCESS: {
      const { id, addedEmails } = action.payload;

      return compose(
        set([id, 'lastInvites'], addedEmails),
        update([id, 'tutors'], push(addedEmails.filter((item) => item.valid)))
      )(state);
    }

    case COURSE.REMOVE_TUTOR_SUCCESS: {
      const { id, email } = action.payload;
      return update([id, 'tutors'], reject({ email }), state);
    }

    case COURSE.MOVE_LESSON: {
      const { id, lessonId, oldIndex, newIndex } = action.payload;

      return update(
        [id, 'lessonsIds'],
        oldIndex > newIndex
          ? compose(
              insertAt(newIndex, lessonId),
              pullAt(oldIndex)
            )
          : compose(
              pullAt(oldIndex),
              insertAt(newIndex, lessonId)
            )
      )(state);
    }

    case BUILDER.DOWNLOAD_COURSES_SUCCESS: {
      const { asTeacher } = action.payload;
      return asTeacher;
    }

    case BUILDER.DOWNLOAD_COURSE_SUCCESS: {
      const { downloadedCourse } = action.payload;
      return set(downloadedCourse.id, downloadedCourse, state);
    }

    case BUILDER.LESSON.REMOVE_SUCCESS: {
      const { id, courseId } = action.payload;
      return courseId
        ? update([courseId, 'lessonsIds'], pull(id), state)
        : state;
    }

    case BUILDER.LESSON.CLONE: {
      const { courseId } = action.payload;
      const { clonedLesson } = action.meta;
      return courseId
        ? update([courseId, 'lessonsIds'], push(keys(clonedLesson)[0]), state)
        : state;
    }

    case BUILDER.LESSON.ADD: {
      const { courseId, id: lessonId } = action.payload;
      return courseId
        ? update([courseId, 'lessonsIds'], push(lessonId), state)
        : state;
    }

    // -------------- Загрузчик файлов --------------

    case UPLOADER.UPLOAD_SUCCESS: {
      const {
        objectId,
        source,
        filePath,
        uploadType,
        pathLastName,
        inPlayer,
      } = action.payload;

      if (uploadType !== UPLOAD_TYPE_COURSE || inPlayer) {
        return state;
      }

      const updateFilePath = pathLastName
        ? concat([...filePath], pathLastName)
        : [...filePath];

      return compose(
        set(
          updateFilePath
            ? [...updateFilePath, 'source']
            : [objectId, 'content', 'source'],
          source
        ),
        applyUploaderReducer({
          objectId,
          state,
          action,
          filePath: updateFilePath,
        })
      )(state);
    }

    case String(action.type.match(/@uploader\/.*/)): {
      const { objectId, filePath, uploadType } = action.payload;

      if (uploadType !== UPLOAD_TYPE_COURSE) return state;

      /* filePath - путь до ссылки на клиенте
      задается в качестве массива в параметрах компонента Uploader,
      если внутри массива есть 'execution' - загрузка выполнена из плеера
      */
      return applyUploaderReducer({ objectId, state, action, filePath })(state);
    }

    default:
      return state;
  }
};

export default coursesReducer;
