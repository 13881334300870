import { css } from 'styled-components';
import noAvatar from '../../../../static/img/no-avatar.png';

export const answerContainer = css`
  display: flex;
  margin-bottom: 16px;
`;

export const studentAnswer = css`
  flex-grow: 1;
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(26, 24, 16, 0.2),
    inset 0 0 0 1px rgba(26, 21, 16, 0.15);
`;

export const studentNoAnswer = css`
  padding: 15px 17px;
  font-family: CirceRounded;
  font-size: 18px;
  line-height: 1.11;
  color: #45494e;
`;

export const teacherAnswer = css`
  flex-grow: 1;
  margin-left: 8px;
  font-size: 18px;
  border-radius: 4px;
  ${({ isApproved }) =>
    isApproved
      ? css`
          box-shadow: inset 0 1px 2px 0 #8acf00, inset 0 0 0 1px #8acf00;
        `
      : css`
          box-shadow: inset 0 1px 2px 0 #f25200, inset 0 0 0 1px #f25200;
        `}
`;

export const avatar = css`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
`;

export const label = css`
  display: inline-block;
  padding: 5px 15px;
  color: white;
  margin-bottom: 12px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;

  ${({ isApproved }) =>
    isApproved === false
      ? css`
          background: #f25200;
        `
      : isApproved === true
      ? css`
          background: #8acf00;
        `
      : css`
          background: #45494e;
        `}
`;

export const answerImages = css`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 17px 16px;
`;

export const taskAnswerUser = css`
  font-family: CirceRounded;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.33;
  flex-grow: 2;
  color: #45494e;
  padding: 8px 17px 0px;
`;

export const taskAnswerUserDate = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #aeb3bd;
  padding: 12px 17px 0px;
`;

export const taskAnswerUserBlock = css`
  display: flex;
`;

export const replyFroala = css`
  padding-top: 2px !important;
`;
