import { css } from 'styled-components';

export const loader = css`
  width: 100%;
`;

export const buttonJoin = css``;

export const buttonReject = css`
  margin-left: 18px;

  .bp3-button {
    color: #767b84 !important;
    background: white !important;
    box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.27),
      0 16px 32px -12px rgba(222, 219, 224, 0.62) !important;
  }

  .bp3-button:hover {
    background: #f0f2f4 !important;
  }

  .bp3-button:active {
    background: #e5e9ec !important;
  }
`;

export const backLink = css`
  margin-left: -46px !important;
  padding-right: 12px;
`;
