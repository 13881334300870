import React from 'react';
import PropTypes from 'prop-types';
import { compose, get } from 'lodash/fp';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { withTranslation } from 'react-i18next';

import withTheming from '~/hocs/withTheming';
import { Text } from '~/components';
import * as actions from '~/actions';
import { combineStyles } from '~/utils/styles';
import '~/styles/Player/Timer.scss';
import { setInterval, clearInterval } from '~/utils/worker-timers';
import { durationToString } from '~/utils/string';

const Timer = ({ t, runTime, timeLimit, fontSize, theme, inSafeMode }) => (
  <div
    className={combineStyles(
      'Timer',
      runTime === timeLimit && 'time-over',
      inSafeMode && theme,
      inSafeMode && fontSize
    )}
  >
    <div className={combineStyles('info', inSafeMode && [theme, fontSize])}>
      <Text
        value={t('timer.timeLeft')}
        variant={
          inSafeMode
            ? ['text-mini-gray -in-timer', fontSize, theme]
            : 'text-mini-gray -in-timer'
        }
      />
    </div>
    <div className={combineStyles('time', inSafeMode && [theme, fontSize])}>
      <Text
        value={durationToString(timeLimit - runTime)}
        variant={
          inSafeMode
            ? [
                `text-subhead-3-${runTime === timeLimit ? 'white' : 'light'}`,
                fontSize,
                theme,
              ]
            : `text-subhead-3-${runTime === timeLimit ? 'white' : 'light'}`
        }
      />
    </div>
  </div>
);

const { func, number, string, bool } = PropTypes;

Timer.propTypes = {
  t: func, // Функция перевода
  // eslint-disable-next-line react/no-unused-prop-types
  onEnd: func, // Действие по окончании таймера
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  runTime: number.isRequired, // Время работы таймера
  timeLimit: number.isRequired, // Время отключения таймера
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
};

Timer.defaultProps = {
  t: func.isRequired,
};

const mapStateToProps = (state) => {
  const runTime = get('runTime', state.player.result);
  const executionTime = get('executionTime', state.player.result);
  return {
    runTime: runTime || executionTime || 0,
    timeLimit: state.player.lesson.meta.timeLimit,
  };
};

const mapDispatchToProps = {
  setRunTime: actions.player.lesson.setRunTime,
};

const enhance = lifecycle({
  /* eslint-disable fp/no-this */
  componentDidMount() {
    const { setRunTime, timeLimit } = this.props;

    if (this.props.runTime < timeLimit) {
      this.timer = setInterval(() => setRunTime(this.props.runTime + 1), 1000);
    }
  },
  componentWillUnmount() {
    if (!this.isStopped) {
      clearInterval(this.timer);
    }
  },
  componentDidUpdate() {
    const { onEnd, runTime, timeLimit } = this.props;
    if (!this.isStopped && runTime === timeLimit) {
      clearInterval(this.timer);
      this.isStopped = true;
      onEnd();
    }
  },
  /* eslint-enable fp/no-this */
});

export default compose(
  withTranslation('containersPlayer'),
  withTheming,
  connect(mapStateToProps, mapDispatchToProps),
  enhance
)(Timer);
