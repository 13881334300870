import { css } from 'styled-components';

export const answerBlocks = css`
  position: relative;
  padding-left: 100px;
  width: 100%;
  max-width: 1060px;

  @media (max-width: 1279px) {
    width: 100%;
    max-width: 1008px;
    padding: 0 24px;
  }

  ${(props) =>
    props.isTaskOpen &&
    css`
      @media (min-width: 1280px) and (max-width: 1680px) {
        width: calc(100% - 584px);
      }
    `}
`;

export const answerBlocksContent = css`
  display: flex;
  padding-top: 34px;
  align-items: flex-start;
  width: 100%;

  /* 
  @media (min-width: 1580px) and (max-width: 1680px) {
    width: calc(100% - 584px);
  }

  @media (min-width: 1480px) and (max-width: 1579px) {
    
  }

  @media (min-width: 1380px) and (max-width: 1479px) {
    
  }

  @media (min-width: 1280px) and (max-width: 1680px) {
    
  }

  @media (min-width: 1280px) and (max-width: 1680px) {
    
  } */
`;

export const answerBlocksTabs = css`
  display: flex;
  width: 100%;
`;

export const filterBlocksSortTitle = css`
  display: flex;
  font-family: CirceRounded;
  align-items: center;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: right;
  color: #8e9195;
  cursor: pointer;
  white-space: nowrap;
`;
