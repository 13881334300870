import { set, curry } from 'lodash/fp';
import * as FEEDBACK from '~/constants/builder/block/feedback';

export const defaultState = {
  question: '',
  description: '',
};

const feedbackReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FEEDBACK.CHANGE_QUESTION: {
      const { newQuestion } = action.payload;
      return set('question', newQuestion, state);
    }

    case FEEDBACK.CHANGE_DESCRIPTION: {
      const { newDescription } = action.payload;
      return set('description', newDescription, state);
    }

    default:
      return state;
  }
};

export default curry(feedbackReducer);
