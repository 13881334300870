import React from 'react';
import { pure } from 'recompose';

// eslint-disable-next-line import/no-unresolved
import logoBig from 'Custom/logo-big.png';
import * as s from './styles/Quote.styles';

const Quote = () => (
  <div css={s.container}>
    <div css={s.logo}>
      <img css={s.logoImg} src={logoBig} alt="core" />
    </div>
  </div>
);

export default pure(Quote);
