import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash/fp';
import NoSSR from 'react-no-ssr';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '~/utils/styles';
import { Text, Help, HelpRow, Button } from '~/components';

import {
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
  COURSE_STATUS_NOT_ACTIVE,
  COURSE_STATUS_FINISHED,
} from '~/appConstants';

const CourseError = ({ isMobile, error }) => {
  const { t } = useTranslation('containersPlayer');
  const courseId = useSelector(get(['player', 'lesson', 'meta', 'courseId']));
  const courseStatus = useSelector(
    get(['player', 'lesson', 'meta', 'courseStatus'])
  );

  const description = {
    [LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON]: {
      [COURSE_STATUS_FINISHED]: 'index.courseHasAlreadyBeenCompleted',
      [COURSE_STATUS_NOT_ACTIVE]: 'index.courseHasNotStartedYet',
    }[courseStatus],
    [LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY]:
      'index.youAreNotCourseMember',
  }[error];

  return (
    <NoSSR>
      <div
        className={combineStyles('Player', [
          isMobile && 'mobile',
          'out-of-dates',
        ])}
      >
        <Helmet>
          <title>{t('index.unavailable')}</title>
        </Helmet>
        <div className="image" />
        <div className="title">
          <Text value={t('index.unavailable')} variant="text-head-dark" />
        </div>
        <div className="only-course-members-text">
          <Trans t={t} i18nKey={description} />
        </div>
        <Link className="no-decoration" to={`/player/course/${courseId}`}>
          <Button variant="publish-lesson" text={`${t('index.goToCourse')}`} />
        </Link>
        {isMobile ? (
          <HelpRow inPlayer />
        ) : (
          <Help inPlayer fixed variant="help-in-folder" />
        )}
      </div>
    </NoSSR>
  );
};

CourseError.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default withMobileDetect(CourseError);
