import { createAction } from '~/utils/actions';

export const SET_IS_MOBILE = '@ui/Определение isMobile';
export const SHOW_NOT_FOUND_PAGE = '@ui/Показать 404 страницу';
export const REQUEST_START = '@ui/Начало http запроса';
export const REQUEST_END = '@ui/Конец http запроса';
export const CLOSE_VERIFICATION_ALERT =
  '@ui/Закрыть алерт о неподтверждённом почтовом ящике';

export const setIsMobile = ({ isMobile }) =>
  createAction(SET_IS_MOBILE, { isMobile });

export const requestStart = ({ name }) => createAction(REQUEST_START, { name });

export const requestEnd = ({ name }) => createAction(REQUEST_END, { name });

export const showNotFoundPage = () => createAction(SHOW_NOT_FOUND_PAGE);

export const closeVerificationAlert = () =>
  createAction(CLOSE_VERIFICATION_ALERT);
