import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../utils/styles';
import '../styles/OnboardingHint.scss';
import Text from '~/components/Text';
import Icon from '~/components/Icon';

const videoOptions = {
  loop: true,
  muted: true,
  autoPlay: true,
};

// <track /> необязателен для <video />, если есть свойство muted
/* eslint-disable jsx-a11y/media-has-caption */

const OnboardingHint = ({ t, type, headText, bodyText, videoPath }) => (
  <div className={combineStyles('OnboardingHint', `${type}-hint`)}>
    <div className="hint-tooltip">
      <div className="head-text">
        <Text value={t(headText)} variant="head-hint-text" />
      </div>
      <Text value={t(bodyText)} variant="body-hint-text" />
      {type === 'library' && (
        <div className="library-hint-warning">
          <div className="icon">
            <Icon name="info-sign" variant="library-hint-warning" />
          </div>
          <div className="text">
            <Text
              variant="library-hint-warning"
              value={t('onboardinghint.libraryTestMode')}
            />
          </div>
        </div>
      )}
      <video
        autoPlay={videoOptions.autoPlay}
        muted={videoOptions.muted}
        loop={videoOptions.loop}
        src={videoPath}
        type="video/mp4"
        className="hint-video"
      />
    </div>
  </div>
);

const { func, string } = PropTypes;

OnboardingHint.propTypes = {
  t: func.isRequired, // Функция перевода
  type: string.isRequired, // Тип подсказки
  headText: string.isRequired, // Заглавный текст внутри подсказки
  bodyText: string.isRequired, // Текст внутри подсказки
  videoPath: string.isRequired, // Путь к видео внутри подсказки
};

export default pure(withTranslation('components')(OnboardingHint));
