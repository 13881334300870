import { css } from 'styled-components';
import noAvatar from '../../../../../static/img/no-avatar.png';

export const container = css`
  display: block;
  position: relative;
`;

export const loader = css`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const addMembers = css`
  background: #fff;
  box-sizing: border-box;
  padding: 35px 95px 40px;
  position: relative;

  > .react-tagsinput {
    width: 554px;
    border-radius: 4px;
    border: 1px solid #d0d3d7;
    background: #fff;
    box-sizing: border-box;
    padding: 13px 13px 5px;

    > span {
      > .react-tagsinput-input {
        font-family: $font-main;
        font-size: 14px;
        height: 35px;
        margin-left: 10px;
        margin-bottom: 8px;
        color: #767b84;
        min-width: 330px;
      }

      > .tag {
        display: inline-block;
        vertical-align: top;
        height: 33px;
        line-height: 33px;
        border-radius: 18px;
        border: 1px solid #f4f6f7;
        background: #f4f6f7;
        padding: 0 45px 0 16px;
        position: relative;
        margin-right: 4px;
        margin-bottom: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;

        &.-error {
          border-color: #f25200;
          background: #fff;
        }

        > .remove {
          width: 25px;
          height: 25px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-content: center;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0, -50%);
          cursor: pointer;
          color: #b2b6b8;
          transition: color 0.2s ease;

          &:hover {
            color: #45494e;
            transition: color 0.2s ease;
          }
        }
      }
    }
  }
`;

export const close = css`
  position: absolute;
  top: 27px;
  left: 27px;
`;

export const status = css`
  margin-top: 35px;
`;

export const statusSuccess = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  color: #8acf00;
`;

export const statusFail = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  color: #f25200;
`;

export const search = css`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  background-color: #fff;
  align-items: center;
  width: 100%;
  height: 95px;
  cursor: pointer;
  z-index: 1;
`;

export const searchClose = css`
  margin-right: 30px;
  margin-left: 27px;
`;

export const searchIcon = css`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  line-height: normal;
  padding-top: 9px;
  box-sizing: border-box;
  padding-right: 6px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -24px;
`;

export const content = css`
  display: flex;
  flex-flow: column nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 29px 26px;
  box-sizing: border-box;
  width: 100%;
`;

export const toggler = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 36px;
  padding-left: 53px;
  position: relative;
`;

export const statusWrong = css`
  font-family: $font-main;
  font-size: 15px;
  line-height: 48px;
  color: #767b84;
  margin-left: 30px;
`;

export const contentContainer = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0 53px;
`;

export const emptyList = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-flow: column nowrap;
  min-height: calc(100vh - 240px);
`;

export const emptyListIcon = css`
  font-size: 64px;
`;

export const emptyListTitle = css`
  font-family: Gilroy;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: -0.09px;
  text-align: center;
  color: #45494e;
  margin-bottom: 8px;
  max-width: 500px;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const emptyListText = css`
  font-family: CirceRounded;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #767b84;
  margin-bottom: 24px;
  max-width: 500px;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const tabs = css`
  width: 755px;
`;

export const members = css`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const membersText = css`
  margin-bottom: 16px;
  font-family: $font-main;
  font-size: 14px;
  color: #95979a;
`;

export const membersTable = css`
  width: 755px;
  border-collapse: collapse;
  table-layout: auto;

  > tbody {
    > tr {
      > td {
        font-family: $font-main;
        font-size: 15px;
        line-height: 1.27;
        color: #767b84;
        padding: 19px;
        background: #fff;
        border-bottom: 1px solid #f0f0f0;

        > .remove {
          display: block;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          transition: all 0.2s ease;
          cursor: pointer;
          opacity: 0;

          &:hover {
            background: #e5e9ec;
            transition: all 0.2s ease;
          }
        }
      }

      &:hover {
        > .control {
          > .remove {
            opacity: 1;
            transition: all 0.2s ease;
          }
        }
      }
    }
  }
`;

export const membersTableName = css`
  padding-left: 32px;
  min-width: 292px;
`;

export const membersTableEmail = css`
  min-width: 280px;
`;

export const tutorsList = css`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: -21px;
`;

export const tutorsItem = css`
  width: 230px;
  height: 198px;
  padding: 24px 24px 0;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 230px;
  margin-right: 20px;
  position: relative;

  > .remove {
    position: absolute;
    top: 13px;
    right: 9px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover > .remove {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
`;

export const tutorsItemAvatar = css`
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
  margin-bottom: 16px;
  position: relative;
`;

export const tutorsItemName = css`
  width: 100%;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #45494e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
`;

export const tutorsItemEmail = css`
  width: 100%;
  font-family: CirceRounded;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  color: #767b84;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
`;

export const tutorsItemStatus = css`
  width: 100%;
  text-align: center;
  font-family: CirceRounded;
  font-size: 14px;
  color: #c5c5c5;
`;
