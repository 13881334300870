import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import NoSSR from 'react-no-ssr';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../../../utils/styles';
import { Help, HelpRow, Text } from '../../../components';

const LtiClosed = ({ t, isMobile }) => (
  <NoSSR>
    <div
      className={combineStyles('Player', ['lti-closed', isMobile && 'mobile'])}
    >
      <Helmet>
        <title>{t('index.unavailable')}</title>
      </Helmet>
      <div className="image" />
      <div className="info">
        <Text
          value={t('index.unavailableDirectLink')}
          variant="text-head-light"
        />
      </div>
      {isMobile ? (
        <HelpRow inPlayer />
      ) : (
        <Help inPlayer fixed variant="help-in-folder" />
      )}
    </div>
  </NoSSR>
);

const { bool, func } = PropTypes;

LtiClosed.propTypes = {
  t: func.isRequired,
  isMobile: bool,
};

export default compose(
  withTranslation('containersPlayer'),
  withMobileDetect
)(LtiClosed);
