import { set, compose } from 'lodash/fp';

import * as UPLOADER from '../constants/uploader';

export const defaultState = {
  status: undefined, // Статус загрузки
  message: undefined, // Сообщение от загрузчика
  progress: undefined, // Прогресс загрузки
};

const uploaderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPLOADER.START_UPLOAD: {
      return compose(
        set('progress', 0),
        set('status', 'uploading')
      )(state);
    }

    case UPLOADER.SET_UPLOAD_PROGRESS: {
      const { newProgress } = action.payload;
      return set('progress', newProgress, state);
    }

    case UPLOADER.UPLOAD_SUCCESS: {
      return compose(
        set('status', 'success'),
        set('progress', undefined)
      )(state);
    }

    case UPLOADER.UPLOAD_ERROR: {
      const { message } = action.payload;
      return compose(
        set('status', 'error'),
        set('message', message),
        set('progress', undefined)
      )(state);
    }

    default:
      return state;
  }
};

export default uploaderReducer;
