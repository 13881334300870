import objectId from 'bson-objectid';
import * as FOLDER from '../../constants/builder/folder';
import { createAction } from '../../utils/actions';

export const add = (id) =>
  createAction(FOLDER.ADD, { id: id || objectId.generate() });
export const save = (id) => createAction(FOLDER.SAVE, { id });
export const open = (id) => createAction(FOLDER.OPEN, { id });
export const edit = (id) => createAction(FOLDER.EDIT, { id });
export const rename = (id, newName) =>
  createAction(FOLDER.RENAME, { id, newName });
export const saveError = () => createAction(FOLDER.SAVE_ERROR);
export const removeCancel = () => createAction(FOLDER.REMOVE_CANCEL);
export const saveSuccess = () => createAction(FOLDER.SAVE_SUCCESS);
export const removeAccept = (id) => createAction(FOLDER.REMOVE_ACCEPT, { id });
export const removeSuccess = (id) =>
  createAction(FOLDER.REMOVE_SUCCESS, { id });
export const removeError = (message) =>
  createAction(FOLDER.REMOVE_ERROR, { message });
export const removeConfirm = (msg, id) =>
  createAction(FOLDER.REMOVE_CONFIRM, { msg, id });
