import styled, { css } from 'styled-components';

const fontMain = "'CirceRounded', Tahoma, sans-serif";

export const container = css`
  width: 773px;
  border-radius: 3px;
  background-color: #fff;
  padding: 40px;
  padding-bottom: 0px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  > .close {
    position: absolute;
    top: 27px;
    right: 32px;
  }

  > .title {
    font-family: Gilroy;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.3;
    color: #45494e;
    margin-bottom: 32px;
  }

  > .row {
    width: 100%;
    margin-bottom: 33px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    > .status {
      width: 220px;

      > .price {
        font-family: ${fontMain};
        font-size: 18px;
        color: #767b84;
        margin-bottom: 9px;
        padding: 0 9px;
      }

      > .description {
        font-family: ${fontMain};
        font-size: 14px;
        color: #767b84;
        padding: 0 9px;
      }
    }
  }
`;

export const space = css`
  margin-top: 32px;
`;

export const Square = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 130px;
  border-radius: 3px;
  margin-bottom: 16px;
  border: 1px solid #e5e9ec;
  box-sizing: border-box;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    border: solid 1px #d0d3d7;
    transition: all 0.2s ease;
  }
  ${(p) => p.active && 'box-shadow: inset 0px 0px 0px 2px #e6a42f;'}

  > .icon {
    font-size: 32px;
    color: #b2b6b8;

    &.-green {
      color: #8acf00;
    }
  }

  > .name {
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #45494e;
    margin-top: 16px;
  }
`;

export const bottom = css`
  width: 693px;
  box-sizing: border-box;
  padding: 24px 32px;
  background-color: #f4f6f7;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const row = css`
  display: flex;
  width: 100%;
`;

export const buttons = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  > .button {
    margin: 0 9px;
  }
`;

export const emoji = css`
  margin-right: 16px;
  margin-bottom: 24px;
  font-size: 34px;
  letter-spacing: -0.09px;
  text-align: center;
  color: #45494e;
`;

export const anotherPlanText = css`
  margin-bottom: 24px;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  color: #45494e;
`;

export const currentPlan = css`
  margin-left: auto;
  padding: 24px;
  width: 220px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;

  > .title {
    font-family: CirceRounded;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #aeb3bd;
  }

  > .name {
    margin-top: 6px;
    font-family: Gilroy;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.09px;
    color: #45494e;
  }

  .counter {
    margin-top: 7px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #45494e;
  }

  .active {
    font-family: CirceRounded;
    font-size: 14px;
    color: #767b84;
  }
`;
