import React from 'react';
import PropTypes from 'prop-types';
import { pure, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { Button, Input, Text } from '~/components';
import * as actions from '~/actions';
import * as selectors from '~/selectors';
import '../../../styles/Profile.scss';

const ProfileIntegration = ({
  t,
  userId,
  userSecret,
  showNotice,
  sendSecret,
}) => (
  <div className="ProfileIntegration">
    <div className="text">
      <Text value={t('profile.integrationText')} variant="profile-text" />
    </div>

    <div className="field">
      <div className="label">
        <Text value={t('profile.key')} variant="profile-field-label" />
      </div>
      <div className="input">
        <Input
          large
          value={userId}
          onCopy={() => showNotice(t('profile.copied'), 'success')}
          copyable
          disabled
          variant="profile-disabled"
        />
      </div>
    </div>

    <div className="field">
      <div className="label">
        <Text value={t('profile.secret')} variant="profile-field-label" />
      </div>
      <div className="input">
        <Input
          large
          value={userSecret}
          onCopy={() => showNotice(t('profile.copied'), 'success')}
          copyable
          disabled
          variant="profile-disabled"
        />
      </div>
      <div className="button">
        <Button
          minimal
          text={t('profile.generateSecret')}
          variant="profile-generate-secret"
          onClick={() => sendSecret(userId)}
        />
      </div>
    </div>
  </div>
);

const { func, string } = PropTypes;

ProfileIntegration.propTypes = {
  t: func.isRequired,
  userId: string,
  userSecret: string,
  showNotice: func.isRequired,
  sendSecret: func.isRequired,
};

ProfileIntegration.defaultProps = {
  userId: undefined,
  userSecret: undefined,
};

const mapStateToProps = (state) => {
  const userId = selectors.auth.getUserId(state);
  const userSecret = selectors.auth.getUserSecret(state);

  return {
    userId,
    userSecret,
  };
};

const mapDispatchToProps = {
  showNotice: actions.notice.show,
  sendSecret: actions.builder.sendSecret,
};

const enhance = lifecycle({
  componentWillMount() {
    // eslint-disable-next-line fp/no-this
    if (!this.props.userSecret) {
      // eslint-disable-next-line fp/no-this
      this.props.sendSecret(this.props.userId);
    }
  },
});

export default compose(
  pure,
  withTranslation('screens'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(ProfileIntegration);
