import { css } from 'styled-components';

export const container = css`
  display: flex;
`;

export const button = css`
  width: 21px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #d0d3d7;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  :first-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  :hover {
    background-color: #e5e9ec;
  }

  .text {
    font-family: CirceRounded;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.43px;
    text-align: center;
    color: #767b84;
  }
`;

export const input = css`
  width: 40px;
  height: 24px;
  border: solid 1px #d0d3d7;
  text-align: center;
  font-family: CirceRounded;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  text-align: center;
  color: #45494e;
  background-color: transparent;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
