import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, get } from 'lodash/fp';
import { pure, withProps, withHandlers } from 'recompose';
import { Froala } from '..';
import '../../styles/Editor.scss';

const Editor = ({
  text,
  theme,
  fontSize,
  onChange,
  inSafeMode,
  inEditMode,
  inPreviewMode,
}) => (
  <div className="Editor">
    {inEditMode ? (
      <Froala
        onChange={onChange}
        content={text}
        variant="text"
        mode="editor"
        pastePlain
        toolbarButtons={[
          'paragraphFormat',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'insertTable',
          'quote',
          'formatOL',
          'formatUL',
          'indent',
          'outdent',
          'insertLink',
          'align',
          'formulaButton',
          'promptButton'
        ]}
        pluginsEnabled={['formula', 'prompt']}
      />
    ) : inPreviewMode ? (
      <Froala
        content={text}
        variant={inSafeMode ? ['text', fontSize, theme] : 'text'}
        mode="preview"
      />
    ) : (
      undefined
    )}
  </div>
);

const { bool, string, func } = PropTypes;

Editor.propTypes = {
  text: string, // Текст
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  onChange: func.isRequired, // При изменении блока
  inSafeMode: bool, // В режиме ОВЗ?
  inEditMode: bool, // В режиме редактирования?
  inPreviewMode: bool, // В режиме просмотра/выполнения?
};

Editor.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  text: undefined,
  inSafeMode: undefined,
  inEditMode: undefined,
  inPreviewMode: undefined,
};

// Логика определения и модификаций ссылок на вебинары в тексте
const webinarId = '\\d{9}';
const webinarUrl = 'https://webinar.sdot.ttc.kz';

// При редактировании ссылки в редакторе, для которой уже добавлено
// имя пользователя, нужно исключить вариант повторного добавления
const removeUserNameFromWebinarLink = (text) =>
  text.replace(
    new RegExp(`"(${webinarUrl})/(speaker|viewer)/(${webinarId}).+"`, 'g'),
    '"$1/$2/$3"'
  );
const addUserNameToWebinarLink = (userName) => (text) =>
  text.replace(
    new RegExp(`(${webinarUrl}/(speaker|viewer)/${webinarId})`, 'g'),
    `$1?userName=${userName}`
  );
const createStudentWebinarLink = (text) =>
  text.replace(
    new RegExp(`(${webinarUrl})/speaker/(${webinarId})`, 'g'),
    '$1/viewer/$2'
  );

const mapStateToProps = (state, props) => {
  const { text } = props;
  if (!text) return {};
  // Небольшое ускорение работы за счет пропуска оставшихся проверок
  if (!new RegExp(webinarUrl).test(text)) return {};
  if (props.inPlayer) {
    // Получение текущего результата прохождения (ученик проходит по LTI)
    const result = get(['player', 'result'], state) || {};
    return {
      text: compose(
        addUserNameToWebinarLink(result.userName),
        createStudentWebinarLink,
        removeUserNameFromWebinarLink
      )(props.text),
    };
  }
  // Получение данных текущей авторизации
  const auth = state.auth || {};
  return {
    text: compose(
      addUserNameToWebinarLink(auth.name),
      removeUserNameFromWebinarLink
    )(props.text),
  };
};

const enhance = compose(
  pure,
  withProps(
    ({
      theme,
      fontSize,
      inPlayer,
      inBuilder,
      inSafeMode,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
      inSafeMode,
      fontSize,
      theme,
    })
  ),
  connect(mapStateToProps),
  withHandlers({
    onChange: ({ onChange }) => (value) => onChange({ text: value }),
  })
);

export default enhance(Editor);
