import { css } from 'styled-components';

export const filterBlocks = css`
  display: flex;
  justify-content: flex-start;
  padding: 0 2px;
  width: 100%;
`;

export const filterBlocksArrow = css`
  margin: 16px 8px;
`;

export const filterIcon = css`
  position: absolute;
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  left: 30px;
`;
