import React from 'react';
import PropTypes from 'prop-types';
import Share from './Share';
import Search from './Search';
import '../../styles/Library/index.scss';

const Library = ({ lessonId }) => (
  <div className="Library">
    <Share />
    <Search lessonId={lessonId} />
  </div>
);

const { string } = PropTypes;

Library.propTypes = {
  lessonId: string.isRequired, // ID урока конструктора, для которого открыта библиотека
};

export default Library;
