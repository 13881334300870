import objectId from 'bson-objectid';
import { set } from 'lodash/fp';
import * as BLOCK from '../../constants/builder/block';

/**
 * Добавление блока Match
 */
export const add = (state) => (next) => (action) => {
  if (action.type === BLOCK.ADD && action.payload.type === 'Match') {
    const isLibraryOpen = state.getState().library.search.isOpen;
    if (isLibraryOpen) {
      return next(action);
    }
    return next(
      set(
        ['meta', 'content'],
        {
          baskets: {
            [objectId.generate()]: { answers: [] },
          },
          combinedAnswers: [],
        },
        action
      )
    );
  } else {
    return next(action);
  }
};
