import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';

import withTheming from '~/hocs/withTheming';
import { Overlay, Text, Button } from '~/components';
import { combineStyles } from '~/utils/styles';
import * as s from './styles/OfferNewLessonPublication.styles';

const OfferNewLessonPublication = ({
  theme,
  isOpen,
  fontSize,
  inSafeMode,
  startNewVersion,
  continueOldVersion,
}) => {
  const { t } = useTranslation('containersPlayer');

  return (
    <Overlay isOpen={isOpen}>
      <div css={s.container}>
        <div css={s.newVersion} />
        <div css={s.title}>
          <Text
            value={t('offerNewPublication.title')}
            variant={combineStyles([
              'text-head-dark',
              'center',
              inSafeMode && fontSize,
              inSafeMode && theme,
            ])}
          />
        </div>
        <div css={s.text}>{t('offerNewPublication.description')}</div>
        <div css={s.buttons}>
          <Button
            text={t('offerNewPublication.startNewVersion')}
            variant={combineStyles(
              'player-welcome',
              inSafeMode && [fontSize, theme]
            )}
            onClick={startNewVersion}
          />
          <Button
            text={t('offerNewPublication.continueOldVersion')}
            variant={combineStyles(
              'player-alert-transparent',
              inSafeMode && [fontSize, theme]
            )}
            onClick={continueOldVersion}
          />
        </div>
      </div>
    </Overlay>
  );
};

const { bool, func, string } = PropTypes;

OfferNewLessonPublication.propTypes = {
  theme: string.isRequired, // Тема внешнего вида плеера
  fontSize: string.isRequired, // Размер шрифта в плеере
  inSafeMode: bool.isRequired, // Плеер в режиме для ОВЗ?
  isOpen: bool.isRequired, // Окно открыто?
  startNewVersion: func.isRequired, // Начать прохождение новой версии
  continueOldVersion: func.isRequired,
};

export default compose(
  pure,
  withTheming
)(OfferNewLessonPublication);
