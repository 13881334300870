import objectId from 'bson-objectid';
import * as MATCH from '../../../constants/builder/block/match';
import { createAction } from '../../../utils/actions';

export const addBasket = (blockId) =>
  createAction(MATCH.ADD_BASKET, { blockId, basketId: objectId.generate() });
export const removeBasket = (blockId, basketId) =>
  createAction(MATCH.REMOVE_BASKET, { blockId, basketId });
export const changeAnswers = (blockId, basketId, newAnswers, combinedAnswers) =>
  createAction(MATCH.CHANGE_ANSWER, {
    blockId,
    basketId,
    newAnswers,
    combinedAnswers,
  });
export const changeBasketName = (blockId, basketId, newBasketName) =>
  createAction(MATCH.CHANGE_BASKET_NAME, { blockId, basketId, newBasketName });
export const changeQuestion = (blockId, newQuestion) =>
  createAction(MATCH.CHANGE_QUESTION, { blockId, newQuestion });
export const changeDescription = (blockId, newDescription) =>
  createAction(MATCH.CHANGE_DESCRIPTION, { blockId, newDescription });
