import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect, useSelector } from 'react-redux';
import { withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { compose, isMatch, map, isEmpty } from 'lodash/fp';
import NoSSR from 'react-no-ssr';
import withMobileDetect from '~/hocs/withMobileDetect';

import Button from '~/components/Button';
import Tabs from '~/components/Tabs';
import CourseThumbnail from '~/components/CourseThumbnail';
import Controls from '~/components/Controls';
import { combineStyles } from '~/utils/styles';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import { TCourse } from '~/@types';
import '~/styles/screens/TeachCourses.scss';
import CoursesMobileInfo from './components/CoursesMobileInfo';
import {
  REQUEST_NAME_COURSE_DOWNLOADING,
  TUTOR_STATUS_ACTIVE,
} from '~/appConstants';
import DotLoader from '~/components/DotLoader';
import { isRequestActive } from '~/selectors/ui';

import * as s from './styles/Courses.styles';

const ScreenTeachCourses = ({
  t,
  isMobile,
  addCourse,
  editCourse,
  allCourses,
  tutorsCourses,
  downloadCourses,
  watchTutorCourse,
  removeCourseRequest,
}) => {
  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_COURSE_DOWNLOADING)
  );

  useEffect(() => {
    downloadCourses();
  }, [downloadCourses]);

  const teacherCoursesElement = (
    <div className="items">
      {allCourses.map((course) => (
        <div key={course.id} className="item">
          {/* TODO: тут передаётся remove, но в CourseThumbnail такого свойства нет */}
          <CourseThumbnail
            course={course}
            onClick={() => editCourse(course.id)}
            remove={removeCourseRequest}
          >
            <div className="control" onClick={(e) => e.stopPropagation()}>
              <Controls
                usePortal
                type="dropdown"
                items={[
                  {
                    icon: 'osh-edit',
                    text: t('lessonthumbnails.edit'),
                    action: () => editCourse(course.id),
                  },
                  {
                    icon: 'osh-trash',
                    text: t('lessonthumbnails.remove'),
                    action: () => removeCourseRequest(course.id),
                  },
                ]}
                variant="course"
              />
            </div>
          </CourseThumbnail>
        </div>
      ))}
    </div>
  );

  return (
    <div className={combineStyles('ScreenTeachCourses', isMobile && 'mobile')}>
      {isMobile && <CoursesMobileInfo />}
      <NoSSR>
        <div className="control">
          <Button icon="osh-addnew" variant="add-course" onClick={addCourse} />
        </div>
      </NoSSR>
      <div className="content">
        {isLoading ? (
          <DotLoader css={s.loader} className="center" />
        ) : (
          <NoSSR onSSR={<DotLoader css={s.loader} />}>
            {isEmpty(tutorsCourses) ? (
              teacherCoursesElement
            ) : (
              <Tabs css={s.tabs} onChange={() => {}}>
                <Tabs.Tab
                  id="as-teacher"
                  title={<div css={s.tabTitle}>Мои курсы</div>}
                  panel={teacherCoursesElement}
                />
                <Tabs.Tab
                  id="as-tutor"
                  title={<div css={s.tabTitle}>Я тьютор</div>}
                  panel={
                    <div className="items">
                      {map((course) => {
                        const { tutorInvite } = course;

                        const isTutorActive = isMatch(
                          { status: TUTOR_STATUS_ACTIVE },
                          tutorInvite
                        );

                        return (
                          <div key={course.id} className="item">
                            <CourseThumbnail
                              course={course}
                              isDim={tutorInvite && !isTutorActive}
                              onClick={() => watchTutorCourse(course.id)}
                            />
                          </div>
                        );
                      }, tutorsCourses)}
                    </div>
                  }
                />
              </Tabs>
            )}
          </NoSSR>
        )}
      </div>
    </div>
  );
};

const { func, arrayOf, bool, object } = PropTypes;

ScreenTeachCourses.propTypes = {
  t: func.isRequired,
  isMobile: bool.isRequired,
  addCourse: func.isRequired, // Добавить курс
  editCourse: func.isRequired,
  allCourses: arrayOf(TCourse), // Список курсов
  tutorsCourses: object, // Список курсов
  removeCourseRequest: func.isRequired, // Запрос на удаление курса
  downloadCourses: func.isRequired,
  watchTutorCourse: func.isRequired,
};

const mapStateToProps = (state) => {
  const userId = selectors.auth.getUserId(state);
  const allCourses = selectors.builder.getSortedCourses(state);
  const tutorsCourses = selectors.tutors.getCoursesList(state);

  return {
    userId,
    allCourses,
    tutorsCourses,
  };
};

const mapDispatchToProps = {
  addCourse: actions.builder.course.add,
  removeCourseRequest: actions.builder.course.removeConfirm,
  downloadCourses: actions.builder.downloadCourses,
};

export default compose(
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation('components'),
  withHandlers({
    editCourse: ({ history }) => (id) => history.push(`/teach/course/${id}`),
    watchTutorCourse: ({ history }) => (id) =>
      history.push(`/tutor/course/${id}`),
  })
)(ScreenTeachCourses);
