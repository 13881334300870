import bowser from 'bowser';
import { pick } from 'lodash/fp';

// ! loaded with raw-loader (see webpack.config.js)
import worker from 'worker-timers-worker/build/es5/bundle';
import { load } from 'worker-timers-broker/build/es5/bundle';

const inIE = bowser.msie || bowser.msedge;

const getTimers = () => {
  if (process.env.SSR) {
    return {};
  }

  if (inIE) {
    return pick(
      ['setInterval', 'setTimeout', 'clearInterval', 'clearTimeout'],
      window
    );
  }

  const blob = new Blob([worker], {
    type: 'application/javascript; charset=utf-8',
  });
  const url = URL.createObjectURL(blob);
  const timers = load(url);
  URL.revokeObjectURL(url);
  return timers;
};

const { clearInterval, clearTimeout, setInterval, setTimeout } = getTimers();

export { setInterval, setTimeout, clearInterval, clearTimeout };
