import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { withHandlers } from 'recompose';
import { combineStyles } from '../utils/styles';
import { Text, Icon, Input } from '~/components';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as selectors from '../selectors';
import * as actions from '../actions';

import '../styles/Uploader.scss';
import UploaderLoader from '~/containers/UploaderLoader';

const getUploadProgress = (progress, imageProgress) =>
  imageProgress
    ? Number.parseInt(imageProgress * 100, 0) || 0
    : Number.parseInt(progress * 100, 0) || 0;

const Uploader = ({
  t,
  type,
  icon,
  title,
  status,
  onClick,
  imageId,
  fileURL,
  variant,
  filePath,
  fileType,
  imageURL,
  progress,
  subtitle,
  inPlayer,
  isMobile,
  embedText,
  uploadType,
  imageStatus,
  onURLChange,
  startUpload,
  fileProperty,
  pathLastName,
  onlyWithLink,
  imageProgress,
  imagesQuantity,
}) =>
  type === 'answer' ? (
    <div className={combineStyles('Uploader', variant)}>
      <Dropzone
        onClick={onClick}
        onDrop={(files) => {
          const [file] = files;
          if (file) {
            startUpload(
              file,
              filePath,
              fileType,
              uploadType,
              undefined,
              inPlayer
            );
          }
        }}
        className="dropzone"
      />
      <div className="title">
        <div className="icon">
          <Icon name="paperclip" variant={variant} />
        </div>
        {title && (
          <div className={combineStyles('text', isMobile && 'mobile')}>
            <Text value={title} variant={['uploader-title', variant]} />
          </div>
        )}
      </div>
    </div>
  ) : type === 'settings' ? (
    <div className={combineStyles('Uploader', variant)}>
      <Dropzone
        onClick={onClick}
        onDrop={(files) => {
          const [file] = files;
          if (file) {
            startUpload(file, filePath, fileType, uploadType, pathLastName);
          }
        }}
        className="dropzone"
      />
      <div className="title">
        <div className="icon">
          <Icon name={icon} variant={variant} />
        </div>
        <div className="text">
          <Text value={title} variant={['uploader-title', variant]} />
        </div>
      </div>
    </div>
  ) : status === 'uploading' || imageStatus === 'uploading' ? (
    <UploaderLoader
      icon={icon}
      variant={variant}
      status={status || imageStatus}
      progress={getUploadProgress(progress, imageProgress)}
    />
  ) : status === 'success' || fileURL || imageURL ? (
    <div />
  ) : (
    <div
      className={combineStyles('Uploader', [variant, status || imageStatus])}
    >
      <div className="icon">
        <Icon name={icon} size={75} variant={variant} />
      </div>
      <div className="title">
        <div className="text">
          <Text value={title} variant={['uploader-title', variant]} />
        </div>
        {!onlyWithLink && (
          <div className="icon">
            <Icon name="paperclip" size={25} variant={variant} />
          </div>
        )}
      </div>
      {subtitle && (
        <div className="subtitle">
          <Text value={subtitle} variant={['uploader-subtitle', variant]} />
        </div>
      )}
      {!onlyWithLink && (
        <div className="description">
          <Text
            value={t('uploader.dropText')}
            variant={['uploader-description', variant]}
          />
        </div>
      )}
      {!onlyWithLink && (
        <Dropzone
          onDrop={(files) => {
            const [file] = files;
            if (file) {
              startUpload(file, filePath, fileType, uploadType);
            }
          }}
          className="dropzone"
        />
      )}
      <div className="input">
        <Input
          large
          value={imageId ? imageURL : fileURL}
          usePortal
          variant="uploader-input"
          onChange={onURLChange}
          placeholder={
            onlyWithLink
              ? embedText || t('uploader.fileLink')
              : imagesQuantity > 2
              ? t('uploader.link')
              : t('uploader.directLink')
          }
        />
      </div>
      {fileProperty && !fileURL && (
        <div className="property">{fileProperty}</div>
      )}
    </div>
  );

const {
  bool,
  func,
  array,
  oneOf,
  string,
  number,
  object,
  oneOfType,
} = PropTypes;

Uploader.propTypes = {
  t: func.isRequired, // Функция перевода
  type: string, // Тип аплоадера
  icon: string, // Иконка
  title: string, // Заголовок
  status: oneOf(['success', 'error', 'uploading']), // Статус загрузки
  variant: oneOfType([array, string]), // Вариант оформления
  imageId: string, // ID картинки
  fileURL: string, // URL загруженного файла,
  onClick: func, // При клике
  subtitle: string, // Ползаголовок
  filePath: array, // Путь до картинки
  fileType: string, // Тип загружаемого файла,
  imageURL: string, // URL загруженного файла,
  progress: number, // Прогресс загрузки
  inPlayer: bool, // Загрузка из плеера?
  isMobile: bool,
  embedText: string, // Текст блока упражнение
  uploadType: string, // Куда загружать - блок или урок
  imageStatus: oneOf(['success', 'error', 'uploading']), // Статус загрузки картинки
  startUpload: func.isRequired, // Начать загрузку
  onURLChange: func, // При изменении ссылки
  fileProperty: object, // Описание свойств загружаемого файла
  pathLastName: string, // Название поля, последнего в пути filePath
  onlyWithLink: bool, // Загрузка только с помощью указания ссылки
  imageProgress: number, // Прогресс загрузки картинки
  imagesQuantity: number, // Количество картинок в блоке
};

Uploader.defaultProps = {
  fileType: 'image',
  inPlayer: false,
  uploadType: 'block',
};

const mapStateToProps = (
  state,
  { lessonId, courseId, blockId, imageId, uploadType, pathLastName }
) => {
  const status =
    uploadType === 'lesson'
      ? selectors.builder.lesson.getContentUploaderStatus(
          lessonId,
          pathLastName,
          state
        )
      : uploadType === 'course'
      ? selectors.builder.course.getContentUploaderStatus({
          courseId,
          pathLastName,
          state,
        })
      : selectors.builder.block.getContentUploaderStatus(blockId, state);
  const imageStatus = selectors.builder.images.getImageUploaderStatus(
    blockId,
    imageId,
    state
  );
  const fileURL =
    uploadType === 'lesson'
      ? selectors.builder.lesson.getContentSource(lessonId, pathLastName, state)
      : uploadType === 'course'
      ? selectors.builder.course.getContentSource({
          courseId,
          pathLastName,
          state,
        })
      : selectors.builder.block.getContentSource(blockId, state);
  const imageURL = selectors.builder.images.getContentImageSource(
    blockId,
    imageId,
    state
  );
  const progress =
    uploadType === 'lesson'
      ? selectors.builder.lesson.getContentUploaderProgress(
          lessonId,
          pathLastName,
          state
        )
      : uploadType === 'course'
      ? selectors.builder.course.getContentUploaderProgress({
          courseId,
          pathLastName,
          state,
        })
      : selectors.builder.block.getContentUploaderProgress(blockId, state);
  const imageProgress = selectors.builder.images.getImageUploaderProgress(
    blockId,
    imageId,
    state
  );

  return {
    status,
    fileURL,
    imageURL,
    progress,
    imageStatus,
    imageProgress,
  };
};

const mapDispatchToProps = {
  startUpload: actions.uploader.startUpload,
};

const enhance = withHandlers({
  startUpload: ({
    startUpload,
    blockId,
    lessonId,
    withFileName,
    isNoticeProgress,
  }) => (file, filePath, fileType, uploadType, pathLastName, inPlayer) =>
    lessonId || blockId
      ? startUpload(
          lessonId || blockId,
          file,
          filePath,
          fileType,
          uploadType,
          pathLastName,
          withFileName,
          inPlayer,
          isNoticeProgress
        )
      : startUpload(
          undefined,
          file,
          filePath,
          fileType,
          uploadType,
          undefined,
          withFileName,
          inPlayer,
          isNoticeProgress
        ),
});

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Uploader);
