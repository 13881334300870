import { css } from 'styled-components';

export const container = css`
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) =>
    props.theme.isMobile &&
    css`
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    `}
`;

export const logo = css`
  display: block;
  z-index: 10;
  color: #000;

  ${(props) =>
    props.theme.isMobile
      ? css`
          height: 23px;
          position: relative;
          padding: 24px 30px;
        `
      : css`
          position: absolute;
          height: 26px;
          top: 48px;
          left: 48px;
        `}
`;

export const logoImg = css`
  display: block;
  height: 26px;

  ${(props) =>
    props.theme.isMobile &&
    css`
      height: 23px;
    `}
`;
