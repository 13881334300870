import i18next from 'i18next';
import { ru, en, kz } from './translates';

export const options = {
  debug: false,

  resources: {
    ru: {
      common: ru,
      components: ru.components,
      componentsAuth: ru.components.auth,
      componentsBlock: ru.components.block,
      componentsQuiz: ru.components.block.quiz,
      containers: ru.containers,
      containersAuth: ru.containers.auth,
      containersBlock: ru.containers.block,
      containersLibrary: ru.containers.library,
      containersPlayer: ru.containers.player,
      sagas: ru.sagas,
      screens: ru.screens,
    },
    en: {
      common: en,
      components: en.components,
      componentsAuth: en.components.auth,
      componentsBlock: en.components.block,
      componentsQuiz: en.components.block.quiz,
      containers: en.containers,
      containersAuth: en.containers.auth,
      containersBlock: en.containers.block,
      containersLibrary: en.containers.library,
      containersPlayer: en.containers.player,
      sagas: en.sagas,
      screens: en.screens,
    },
    kz: {
      common: kz,
      components: kz.components,
      componentsAuth: kz.components.auth,
      componentsBlock: kz.components.block,
      componentsQuiz: kz.components.block.quiz,
      containers: kz.containers,
      containersAuth: kz.containers.auth,
      containersBlock: kz.containers.block,
      containersLibrary: kz.containers.library,
      containersPlayer: kz.containers.player,
      sagas: kz.sagas,
      screens: kz.screens,
    },
  },

  ns: [
    'common',
    'components',
    'componentsAuth',
    'componentsBlock',
    'componentsQuiz',
    'containers',
    'screens',
    'containersAuth',
    'containersBlock',
    'containersLibrary',
    'containersPlayer',
  ],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },

  whitelist: ['en', 'ru', 'kz'],
  nonExplicitWhitelist: true,
  fallbackLng: ['en', 'ru', 'kz'],
  load: 'languageOnly',
  wait: true,
  detection: {
    caches: ['cookie'],
  },
};

function createI18n({ detector }) {
  const i18n = i18next.createInstance();
  i18n.use(detector).init(options);
  return i18n;
}

export default createI18n;
