import * as QUIZ from './quiz';
import * as EXAM from './exam';
import * as MATCH from './match';
import * as ANSWER from './answer';
import * as IMAGES from './images';
import * as DOCUMENT from './document';
import * as FEEDBACK from './feedback';
import * as FILLBLANK from './fillblank';

export { QUIZ, EXAM, MATCH, ANSWER, IMAGES, DOCUMENT, FEEDBACK, FILLBLANK };

export const ADD = '@builder/block/Добавление блока';
export const MOVE = '@builder/block/Перемещение блока';
export const CLEAN = '@builder/block/Очистка содержимого блока';
export const CLONE = '@builder/block/Клонирование блока';
export const REMOVE = '@builder/block/Удаление блока';
export const CHANGE_COMMENT = '@builder/block/Изменить комментарий к блоку';

// Для изменения контента блоков без собственных редюсеров
export const CHANGE_CONTENT = '@builder/block/Изменение содержимого блока';
