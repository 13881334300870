import { get, pick } from 'lodash/fp';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import * as selectors from '../../../selectors';
import { apiRequest } from '~/sagas/request';

/**
 * Сохранение урока на сервер
 */
export default function* save() {
  yield takeLatest(BUILDER.LESSON.SAVE, function*(action) {
    const state = yield select();
    const { id, message } = action.payload;
    const allPages = selectors.builder.getPages(state);
    const allBlocks = selectors.builder.getBlocks(state);
    const allLessons = selectors.builder.getLessons(state);
    const lesson = pick(id, allLessons);
    const lessonPages = helpers.lesson.getPages(id, allLessons, allPages);
    const lessonBlocks = helpers.lesson.getBlocks(
      id,
      allLessons,
      allPages,
      allBlocks
    );

    const data = {
      id,
      courseId: get([id, 'meta', 'courseId'], lesson),
      content: {
        pages: lessonPages,
        blocks: lessonBlocks,
        lesson,
      },
    };

    const response = yield apiRequest('lesson/save', data);

    if (!response) {
      yield put(actions.builder.lesson.saveError(id));
      return;
    }

    const {
      msg,
      error,
      payload: { code },
    } = response.data;

    if (error) {
      yield put(actions.builder.lesson.saveError(id, msg));
    }

    yield put(
      actions.builder.lesson.saveSuccess({
        id,
        code,
        message,
      })
    );
  });
}
