import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { login } from '~/actions/player';
import { Overlay, Text } from '../../components';
import { SelectLocale } from '..';
import Auth from './Auth';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/Welcome.scss';

const Welcome = ({ t, isOpen, isMobile }) => (
  <Overlay isOpen={isOpen}>
    <div className={combineStyles('Welcome', [isMobile && 'mobile'])}>
      <div className={combineStyles('text', [])}>
        <Text
          value={t('welcome.enter')}
          variant={combineStyles('text-head-dark', [])}
        />
      </div>
      <Auth />
      <div className="buttons-container">
        <div />
        <SelectLocale variant="in-player-auth" />
      </div>
    </div>
  </Overlay>
);

const { bool, func } = PropTypes;

Welcome.propTypes = {
  t: func.isRequired, // Функция перевода
  isOpen: bool.isRequired, // Окно открыто?
  isMobile: bool.isRequired,
};

const mapDispatchToProps = { login };

export default compose(
  withTranslation('containersPlayer'),
  withMobileDetect,
  connect(
    null,
    mapDispatchToProps
  )
)(Welcome);
