import { css } from 'styled-components';

export const container = css`
  position: relative;
  width: 100%;
  display: inline-block;
`;

export const dropdownWrap = css`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
`;

export const dropdownButton = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  width: 50px;

  > .bp3-button {
    height: 100%;
    width: 100%;
    margin: 0;

    &::before {
      position: relative;
      top: 0;
      left: 0;
      margin: 0;
      color: #d0d3d7 !important;
      font-size: 20px !important;
    }
  }
`;

export const dropdownList = css`
  position: absolute;
  margin: 1px;
  border-radius: 3px;
  box-shadow: 0 8px 21px -3px rgba(0, 0, 0, 0.12);
  z-index: 1;
  width: calc(100% - 2px);
  top: 50px;
  background-color: white;
  overflow-y: scroll;
  transition: all 0.3s;
  max-height: ${(props) => (props.isVisible ? '229px' : '0')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  padding: ${(props) => (props.isVisible ? '7px 0' : '0')};
`;

export const dropdownItem = css`
  display: flex;
  padding: 7px 20px;
  height: 43px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f2f5f8;
  }
  background-color: ${(props) => (props.isSelected ? '#eef2f5' : 'white')};
`;

export const noResult = css`
  display: flex;
  align-items: center;
  padding: 14px 20px;
  font-size: 15px;
  height: 58px;
  opacity: 0.35;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #45494e;
  cursor: default;
`;

export const inputLabel = css`
  position: absolute;
  top: -16px;
  z-index: 1;
  width: 25px;
  height: 12px;
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.3px;
  margin-bottom: 7px;
  color: #afafaf;
  text-transform: uppercase;
`;
