import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { DropTarget } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '~/utils/styles';
import Controls from '~/components/Controls';
import Icon from '~/components/Icon';
import Text from '~/components/Text';

const TeachFolderThumbnail = ({
  t,
  id,
  name,
  open,
  edit,
  rename,
  remove,
  isOver,
  isEditMode,
  connectDropTarget,
}) => {
  const controls = (
    <Controls
      usePortal
      type="dropdown"
      variant="folders"
      items={[
        {
          icon: 'osh-edit',
          text: t('folderthumbnail.rename'),
          action: () => edit(id),
        },
        {
          icon: 'osh-trash',
          text: t('folderthumbnail.remove'),
          action: () => remove(id),
        },
      ]}
    />
  );
  return isEditMode ? (
    <div className={combineStyles('folder-icon', 'in-edit-mode')} key={id}>
      <Icon
        variant={combineStyles('lessons-folder', 'in-edit-mode')}
        name="osh-folderbig"
      />
      <Text
        value={name}
        multiline
        onConfirm={(newName) => rename(id, newName)}
        editable
        confirmOnEnterKey
        placeholder={t('folderthumbnail.changeFolderName')}
        variant={combineStyles('folder-name', 'in-edit-mode')}
      />
      {controls}
    </div>
  ) : (
    connectDropTarget(
      <div className="folder-icon" key={id}>
        <div className="container" onClick={() => open(id)}>
          <Icon
            variant={combineStyles('lessons-folder', isOver && 'dnd-hover')}
            name="osh-folder"
          />
          <Text value={name} multiline variant="folder-name" />
        </div>
        {controls}
      </div>
    )
  );
};

const { func, string, bool } = PropTypes;

TeachFolderThumbnail.propTypes = {
  t: func.isRequired, // Функция перевода
  id: string.isRequired, // ID папки
  name: string, // Название папки
  open: func.isRequired, // Открыть папку
  edit: func.isRequired, // Включение режима редактирования папки
  rename: func.isRequired, // Срабатывает на onConfirm текста
  remove: func.isRequired, // Удаление папки
  isOver: bool, // dnd окончен?
  isEditMode: bool.isRequired, // Папка в режиме редактирования?
  connectDropTarget: func, // Подключает DnD
};

TeachFolderThumbnail.defaultProps = {
  name: undefined,
  isOver: undefined,
  connectDropTarget: undefined,
};

const dropTarget = {
  drop(props, monitor) {
    const { lessonId } = monitor.getItem();
    props.onDragEnd(lessonId, props.id);
  },
};

const collectDropTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default compose(
  pure,
  withTranslation('components'),
  DropTarget('lesson', dropTarget, collectDropTarget)
)(TeachFolderThumbnail);
