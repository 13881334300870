import { set, update, concat, cloneDeep } from 'lodash/fp';
import * as selectors from '../selectors';
import * as LIBRARY from '../constants/library';

/**
 * Клонирование блока в область данных библиотеки
 * для последующего редактирования, независимо от конструктора
 *
 * Используется для редактирования блока в окне шаринга или
 * редактирования блока из окна поиска по библиотеке
 */
export const cloneBlock = (state) => (next) => (action) => {
  if ([LIBRARY.SHARE.OPEN, LIBRARY.EDIT.OPEN].includes(action.type)) {
    const { blockId } = action.payload;
    const store = state.getState();
    const block = cloneDeep(
      {
        [LIBRARY.EDIT.OPEN]: selectors.library.getSearchedBlock(blockId, store),
        [LIBRARY.SHARE.OPEN]: selectors.builder.getBlock(blockId, store),
      }[action.type]
    );
    const tagConverter = {
      'Имя личности': 'persons',
      Тема: 'themes',
      Дата: 'dates',
      Термин: 'terms',
    };
    const tagsByType = (block.tags || []).reduce(
      (tags, tag) => update(tagConverter[tag.type], concat(tag.text), tags),
      {}
    );
    return next(
      set(
        ['meta', 'block'],
        {
          id: blockId,
          type: block.type,
          content: block.content,
          ...tagsByType,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};
