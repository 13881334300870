/* eslint-disable generator-star-spacing */

import { get, merge, omit, compose } from 'lodash/fp';
import { all, debounce, put, select, takeEvery } from 'redux-saga/effects';
import { push, replace as routerReplace } from 'connected-react-router';

import {
  UPLOAD_TYPE_COURSE,
  REQUEST_NAME_CHANGE_COURSE_STATUS,
} from '~/appConstants';
import * as UPLOADER from '~/constants/uploader';
import * as actions from '~/actions';
import * as BUILDER from '../../../constants/builder';
import * as selectors from '../../../selectors';
import { removeQueryParam } from '~/utils/query-params';
import { apiRequest } from '../../request';

/**
 * Сохранение курса на сервер
 */
function* saveCourse(action) {
  const {
    payload: { id },
    type,
  } = action;

  const state = yield select();

  const data = compose(
    omit([
      'publications',
      'publicationsIds',
      'results',
      'users',
      'lessons',
      'userId',
      'status',
      'removingInviteEmails',
    ]),
    merge({ id }),
    get(id),
    selectors.builder.getCourses
  )(state);

  const response = yield apiRequest('course/save', data);

  if (!response) {
    return;
  }

  if (response.data.error) {
    yield put(actions.builder.course.saveError());
    return;
  }

  yield put(actions.builder.course.saveSuccess());

  if (BUILDER.COURSE.ADD === type) {
    yield put(push(`/teach/course/${id}`));
  }
}

function* watchCourseSave() {
  yield takeEvery(
    [BUILDER.LESSON.REMOVE_SUCCESS, BUILDER.LESSON.CLONE, BUILDER.LESSON.ADD],
    function* onChangeCourse({ payload: { courseId } }) {
      if (courseId) {
        yield put(actions.builder.course.save(courseId));
      }
    }
  );

  // TODO: выглядит так, что нужно выделить экшн SAVE,
  //  а остальные должны вызывать его через сагу
  yield takeEvery(
    [
      actions.builder.course.add,
      actions.builder.course.save,
      actions.builder.course.changeName,
      actions.builder.course.togglePublic,
      actions.builder.course.changeDescription,
    ],
    saveCourse
  );
  yield debounce(1000, BUILDER.COURSE.MOVE_LESSON, saveCourse);
}

function* watchCourseCoverUpload() {
  yield takeEvery(UPLOADER.UPLOAD_SUCCESS, function*(action) {
    const {
      filePath: [courseId],
      uploadType,
      inPlayer,
    } = action.payload;

    if (uploadType !== UPLOAD_TYPE_COURSE || inPlayer) {
      return;
    }

    yield put(actions.builder.course.save(courseId));
  });
}

function* watchCourseSetStatus() {
  yield takeEvery(actions.builder.course.setStatus, function*(action) {
    const { id, status } = action.payload;
    const response = yield apiRequest(
      'course/change_status',
      { id, status },
      'post',
      { name: REQUEST_NAME_CHANGE_COURSE_STATUS }
    );

    if (!response) {
      return;
    }

    if (response.data.error) {
      yield put(actions.builder.course.setStatusError());
      return;
    }

    yield put(actions.builder.course.setStatusSuccess({ id, status }));
    const state = yield select();
    const { search, pathname } = state.router.location;
    const cleanPath = pathname + removeQueryParam('modal', search);
    yield put(routerReplace(cleanPath));
  });
}

export default function* save() {
  yield all([
    watchCourseSave(),
    watchCourseCoverUpload(),
    watchCourseSetStatus(),
  ]);
}
