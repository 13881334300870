import * as SEARCH from '../../constants/library/search';
import { createAction } from '../../utils/actions';

export const open = () => createAction(SEARCH.OPEN);
export const hide = () => createAction(SEARCH.HIDE);
export const show = () => createAction(SEARCH.SHOW);
export const close = () => createAction(SEARCH.CLOSE);
export const selectTag = (tag) => createAction(SEARCH.SELECT_TAG, { tag });
export const removeTag = (tagIndex) =>
  createAction(SEARCH.REMOVE_TAG, { tagIndex });
export const importTags = (tags) => createAction(SEARCH.IMPORT_TAGS, { tags });
export const toggleType = (type) => createAction(SEARCH.TOGGLE_TYPE, { type });
export const importBlocks = (blocks) =>
  createAction(SEARCH.IMPORT_BLOCKS, { blocks });
export const toggleSubject = (subject) =>
  createAction(SEARCH.TOGGLE_SUBJECT, { subject });
export const changeTagsFilter = (newTagsFilter) =>
  createAction(SEARCH.CHANGE_TAGS_FILTER, { newTagsFilter });
