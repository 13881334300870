import React from 'react';
import { compose, pure } from 'recompose';
import { replace } from 'lodash/fp';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';
import { COURSE_STATUS_ACTIVE } from '~/appConstants';
import { combineStyles } from '../utils/styles';
import '../styles/CourseThumbnail.scss';
import Icon from '~/components/Icon';
import LinesTruncate from '~/components/LinesTruncate';

const CourseThumbnail = ({
  t,
  isDim,
  course: { name, cover, status },
  onClick,
  isMobile,
  children,
}) => (
  <div
    className={combineStyles('CourseThumbnail', [
      isMobile && 'mobile',
      isDim && 'dim',
    ])}
    onClick={onClick}
  >
    <div
      className="cover"
      style={cover && { backgroundImage: `url("${cover?.source}")` }}
    >
      {status && (
        <div className={combineStyles('status', replace('_', '', status))}>
          {t(`course.status_${status}`)}
        </div>
      )}
      {!cover && <Icon name="osh-imageupload" />}
    </div>
    <div className="content">
      <div className="name">
        <LinesTruncate text={name} maxLines={2} />
        {status && (
          <div
            className={combineStyles(
              'icon',
              status === COURSE_STATUS_ACTIVE && 'active'
            )}
          >
            <Icon name={`osh-course-${status}`} />
          </div>
        )}
      </div>
    </div>
    {children}
  </div>
);

const { func, object, node, bool, string } = PropTypes;

CourseThumbnail.propTypes = {
  t: func.isRequired,
  course: object.isRequired,
  onClick: func.isRequired,
  children: node,
  isMobile: bool.isRequired,
  status: string,
  isDim: bool,
};

export default compose(
  withMobileDetect,
  withTranslation('containers'),
  pure
)(CourseThumbnail);
