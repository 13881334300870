import cx from 'classnames';
import { split, compose, map, compact } from 'lodash/fp';

export const applyVariant = compose(
  map((variant) => `-${variant}`),
  compact,
  split(' '),
  cx
);

export const combineStyles = (className, variants) =>
  cx(className, applyVariant(variants));
