import * as EDIT from '../../constants/library/edit';
import { createAction } from '../../utils/actions';

export const open = (blockId) => createAction(EDIT.OPEN, { blockId });
export const save = () => createAction(EDIT.SAVE);
export const close = () => createAction(EDIT.CLOSE);
export const saveError = (message) =>
  createAction(EDIT.SAVE_ERROR, { message });
export const saveSuccess = () => createAction(EDIT.SAVE_SUCCESS);
export const removeError = (blockId) =>
  createAction(EDIT.REMOVE_ERROR, { blockId });
export const removeRequest = (blockId, callback) =>
  createAction(EDIT.REMOVE_REQUEST, { blockId, callback });
export const removeSuccess = (blockId) =>
  createAction(EDIT.REMOVE_SUCCESS, { blockId });
export const changeTerms = (newTerms) =>
  createAction(EDIT.CHANGE_TERMS, { newTerms });
export const changeDates = (newDates) =>
  createAction(EDIT.CHANGE_DATES, { newDates });
export const changeThemes = (newThemes) =>
  createAction(EDIT.CHANGE_THEMES, { newThemes });
export const changePersons = (newPersons) =>
  createAction(EDIT.CHANGE_PERSONS, { newPersons });
export const changeSubject = (newSubject) =>
  createAction(EDIT.CHANGE_SUBJECT, { newSubject });
