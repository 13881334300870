import React from 'react';
import PropTypes from 'prop-types';
import objectId from 'bson-objectid';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { pure, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Dialog, Text, Icon, Button, LessonTemplate } from '../components';
import * as actions from '../actions';
import '../styles/Templates.scss';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';

const templatesList =
  process.env.BUILD_NAME === 'develop'
    ? [
        {
          name: 'Тестовый шаблон',
          description: `Опыт слушателя - главный ресурс обучения. Шаблон оценивает опыт, знания
    и понимание концепций в начале онлайн-курса и собирает ожидания слушателей.`,
          icon: '🔬',
          lessonId: '5e56257d0859ebca19ab2495',
          previewLessonId: '5e5625a31381125a69027f0d',
        },
      ]
    : [
        {
          name: 'Диагностика',
          description: `Опыт слушателя - главный ресурс обучения. Шаблон оценивает опыт, знания
      и понимание концепций в начале онлайн-курса и собирает ожидания слушателей.`,
          icon: '🔬',
          lessonId: '5e56303065c12d9bb1167616',
          previewLessonId: '5e56303d5bce46201a226f7c',
        },
        {
          name: 'Информационный материал к онлайн-курсу',
          description: `Основа любого онлайн-курса - информация. Используйте данный шаблон,
      чтобы эффективно подать материал и мгновенно проверить его понимание.`,
          icon: '📖',
          lessonId: '5e56304365c12d9bb1167630',
          previewLessonId: '5e56304b5bce4620576d9c18',
        },
        {
          name: 'Итоговый тест по разделу/курсу',
          description: `Аттестация - это необходимый этап в каждом курсе. Данный шаблон
      позволит объективно оценить прирост знаний слушателей курса.`,
          icon: '📝',
          lessonId: '5e56305365c12d9bb116766c',
          previewLessonId: '5e56305a5bce46152314a82c',
        },
        {
          name: 'Перевернутый класс перед тренингом/мастер-классом',
          description: `Материал в формате “перевернутый класс” можно отправить слушателям
      до тренинга, чтобы они уже обладали основными концепциями к очной
      встрече, а Вы - средним уровнем их знаний.`,
          icon: '🙃',
          lessonId: '5e56306265c12d9bb1167685',
          previewLessonId: '5e5630685bce460e2602952e',
        },
        {
          name: 'Feedback по итогам мастер-класса',
          description: `Шаблон позволяет с помощью опросов получить обратную
      связь от аудитории по итогам тренинга и указать задание.`,
          icon: '💬',
          lessonId: '5e56307165c12d9bb11676a7',
          previewLessonId: '5e5630765bce4620576d9c19',
        },
      ];

const Templates = ({
  t,
  close,
  isOpen,
  courseId,
  addLesson,
  lessonType,
  downloadAndClone,
}) => {
  const textLessonType = {
    [LESSON_TYPE_EXAM]: 'templates.examLessonNew',
    [LESSON_TYPE_REGULAR]: 'templates.regularLessonNew',
    [LESSON_TYPE_OLYMPIAD]: 'templates.olympiadLessonNew',
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      variant="templates"
      className="Templates"
    >
      <div className="Templates">
        <div className="header">
          <Text
            variant="templates-title"
            value={t(textLessonType[lessonType])}
          />
          <Text
            variant="templates-text"
            value={t('templates.newLessonDescription')}
          />
          <div className="close">
            <Button
              icon="osh-remove"
              text={t('templates.close')}
              onClick={close}
              variant="dialog-close"
            />
          </div>
        </div>

        <div className="content">
          <div className="lesson">
            <div className="empty-lesson" onClick={() => addLesson(lessonType)}>
              <div className="icon">
                <Icon name="osh-addnew" variant="empty-lesson" />
              </div>
              <Text
                value={t('templates.emptyLesson')}
                variant="empty-lesson-name"
              />
              <Text
                variant="empty-lesson-text"
                value={t('templates.emptyLessonDescription')}
              />
            </div>
          </div>

          {templatesList.map((item, index) => (
            <div className="lesson" key={index}>
              <LessonTemplate
                courseId={courseId}
                icon={item.icon}
                name={item.name}
                lessonId={item.lessonId}
                previewLessonId={item.previewLessonId}
                description={item.description}
                cloneLesson={downloadAndClone}
              />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

const { func, bool, string } = PropTypes;

Templates.propTypes = {
  t: func.isRequired, // Функция перевода
  close: func.isRequired, // Закрыть окно результатов
  isOpen: bool, // Окно результатов открыто?
  courseId: string,
  addLesson: func.isRequired, // Добавить урок
  lessonType: string,
  downloadAndClone: func.isRequired,
};

Templates.defaultProps = {
  isOpen: false,
  courseId: undefined,
  lessonType: LESSON_TYPE_REGULAR,
};

const mapDispatchToProps = {
  addLesson: actions.builder.lesson.add,
  downloadAndClone: actions.builder.lesson.downloadAndClone,
};

export default compose(
  withTranslation('containers'),
  pure,
  connect(
    undefined,
    mapDispatchToProps
  ),
  withHandlers({
    close: (props) => () => props.showTemplates(false),
    addLesson: ({ addLesson, router, folderId, courseId }) => (type) => {
      const newLessonId = objectId.generate();
      addLesson({
        lessonId: newLessonId,
        folderId,
        courseId,
        type,
      });
      router.push(`/builder/lesson/${newLessonId}`);
    },
  })
)(Templates);
