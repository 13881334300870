import { get, getOr, filter, compose } from 'lodash/fp';
import { getCourse } from './main';

export const getActiveStudents = (id, state) =>
  compose(
    filter({ status: 'active' }),
    get('invites'),
    getCourse(id)
  )(state);

export const getStudents = (id, state) => {
  const course = getCourse(id, state);
  const removingInviteEmails = getOr([], 'removingInviteEmails', course);
  return compose(
    filter(({ email }) => !removingInviteEmails.includes(email)),
    get('invites')
  )(course);
};

export const getTutors = (id, state) => {
  const course = getCourse(id, state);
  const removingInviteEmails = getOr([], 'removingInviteEmails', course);
  return compose(
    filter(({ email }) => !removingInviteEmails.includes(email)),
    get('invites')
  )(course);
};

export const getLastInvites = (id, state) => {
  const course = getCourse(id, state);
  return get('lastInvites', course);
};

export const getInvalidQuantity = (id, state) => {
  const course = getCourse(id, state);
  return get('invalidInvitesQuantity', course);
};

export const getContentSource = ({ courseId, pathLastName, state }) =>
  get(
    pathLastName ? ['cover', pathLastName, 'source'] : ['cover', 'source'],
    getCourse(courseId, state)
  );

export const getContentUploaderStatus = ({ courseId, pathLastName, state }) =>
  get(
    pathLastName
      ? ['cover', pathLastName, 'uploader', 'status']
      : ['cover', 'uploader', 'status'],
    getCourse(courseId, state)
  );

export const getContentUploaderProgress = ({ courseId, pathLastName, state }) =>
  get(
    pathLastName
      ? ['cover', pathLastName, 'uploader', 'progress']
      : ['cover', 'uploader', 'progress'],
    getCourse(courseId, state)
  );
