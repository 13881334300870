/* eslint-disable fp/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from '@blueprintjs/core';

import { combineStyles } from '~/utils/styles';
import '~/styles/Tabs.scss';

// TODO: компонент называется Tabs, экспортирует StyledTabs -- не по конвеншану

const StyledTabs = (props) => {
  const { variant } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Tabs className={combineStyles('Tabs', variant)} {...props} />;
};

const TitleWithCounter = ({ title, count, isActive }) => (
  <div className="TitleWithCounter">
    <div className={combineStyles('badge', isActive && 'active')}>{count}</div>
    <div className={combineStyles('title', isActive && 'active')}>{title}</div>
  </div>
);

const { oneOfType, array, number, string, bool } = PropTypes;

StyledTabs.propTypes = {
  variant: oneOfType([array, string]),
};

TitleWithCounter.propTypes = {
  count: number.isRequired,
  title: string.isRequired,
  isActive: bool,
};

// TODO: экспортировать на каждый компонент один файл

StyledTabs.Tab = Tab;
StyledTabs.TitleWithCounter = TitleWithCounter;
StyledTabs.Expander = Tabs.Expander;

export default StyledTabs;
