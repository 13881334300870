import * as page from './page';
import * as block from './block';
import * as folder from './folder';
import * as course from './course';
import * as lesson from './lesson';
import * as profile from '../profile';
import * as BUILDER from '../../constants/builder';
import { createAction, createErrorAction } from '../../utils/actions';

export const showHints = () => createAction(BUILDER.SHOW_HINTS);
export const sendSecret = (userId) =>
  createAction(BUILDER.SEND_SECRET, { userId });
export const sendSecretError = createErrorAction(BUILDER.SEND_SECRET_ERROR);
export const sendSecretSuccess = (secret) =>
  createAction(BUILDER.SEND_SECRET_SUCCESS, { secret });
export const downloadLesson = ({ id }) =>
  createAction(BUILDER.DOWNLOAD_LESSON, { id });
export const downloadLessons = ({ userId }) =>
  createAction(BUILDER.DOWNLOAD_LESSONS, { userId });
export const downloadLessonError = createErrorAction(
  BUILDER.DOWNLOAD_LESSON_ERROR
);
export const downloadLessonsError = createErrorAction(
  BUILDER.DOWNLOAD_LESSONS_ERROR
);
export const downloadLessonSuccess = ({
  downloadedLessons,
  downloadedPages,
  downloadedBlocks,
}) =>
  createAction(BUILDER.DOWNLOAD_LESSON_SUCCESS, {
    downloadedLessons,
    downloadedPages,
    downloadedBlocks,
  });
export const downloadLessonsSuccess = (
  downloadedLessons,
  downloadedPages,
  downloadedBlocks,
  downloadedFolders
) =>
  createAction(BUILDER.DOWNLOAD_LESSONS_SUCCESS, {
    downloadedLessons,
    downloadedPages,
    downloadedBlocks,
    downloadedFolders,
  });
export const downloadCourses = (userId) =>
  createAction(BUILDER.DOWNLOAD_COURSES, { userId });
export const downloadCoursesError = createErrorAction(
  BUILDER.DOWNLOAD_COURSES_ERROR
);
export const downloadCoursesSuccess = ({ asTeacher, asTutor }) =>
  createAction(BUILDER.DOWNLOAD_COURSES_SUCCESS, { asTeacher, asTutor });

export const downloadCourse = (id) =>
  createAction(BUILDER.DOWNLOAD_COURSE, { id });

export const downloadCourseError = createErrorAction(
  BUILDER.DOWNLOAD_COURSE_ERROR
);
export const downloadCourseSuccess = (
  downloadedCourse,
  downloadedLessons,
  downloadedPages,
  downloadedBlocks
) =>
  createAction(BUILDER.DOWNLOAD_COURSE_SUCCESS, {
    downloadedCourse,
    downloadedLessons,
    downloadedPages,
    downloadedBlocks,
  });

export { page, block, lesson, folder, course, profile };
