import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
  LESSON_EXECUTE_STATUS_PROCESS,
  LESSON_EXECUTE_STATUS_COMPLETE,
  LESSON_EXECUTE_STATUS_INCOMPLETE,
} from '~/appConstants';

import withMobileDetect from '~/hocs/withMobileDetect';
import withTheming from '~/hocs/withTheming';
import { combineStyles } from '~/utils/styles';
import { Text, ProgressBar, Icon } from '~/components';
import '~/styles/Player/LessonHeader.scss';

const LessonHeader = ({
  theme,
  fontSize,
  isMobile,
  lessonType,
  inSafeMode,
  lessonName,
  isOlympiadMode,
  totalTasksCount,
  isLessonComplete,
  correctTasksCount,
  lessonDescription,
  minimalSuccessScore,
  executedBlocksCount,
  olympiadPartnersLogos,
  olympiadDescriptionInResult,
}) => {
  const { t } = useTranslation('containersPlayer');
  const notEmptyLessonName = lessonName || t('index.unnamed');
  const isTasksInLesson = totalTasksCount > 0;
  const isExamLesson = lessonType === LESSON_TYPE_EXAM;
  const isRegularLesson = lessonType === LESSON_TYPE_REGULAR;
  const isExamPassed =
    totalTasksCount === 0 ||
    Math.ceil((correctTasksCount / totalTasksCount) * 100) >=
      minimalSuccessScore;
  const lessonStatus = !isLessonComplete
    ? LESSON_EXECUTE_STATUS_PROCESS
    : isExamLesson
    ? isExamPassed
      ? LESSON_EXECUTE_STATUS_COMPLETE
      : LESSON_EXECUTE_STATUS_INCOMPLETE
    : LESSON_EXECUTE_STATUS_COMPLETE;

  const textLessonType = {
    [LESSON_TYPE_EXAM]: 'index.examLesson',
    [LESSON_TYPE_REGULAR]: 'index.regularLesson',
    [LESSON_TYPE_OLYMPIAD]: 'index.olympiadLesson',
  };

  const iconLessonType = {
    [LESSON_TYPE_EXAM]: 'osh-lesson-exam',
    [LESSON_TYPE_REGULAR]: 'osh-lesson-regular',
    [LESSON_TYPE_OLYMPIAD]: 'osh-lesson-olympiad',
  };

  const textLessonStatus = {
    [LESSON_EXECUTE_STATUS_COMPLETE]: 'index.completeStatus',
    [LESSON_EXECUTE_STATUS_INCOMPLETE]: 'index.incompleteStatus',
    [LESSON_EXECUTE_STATUS_PROCESS]: 'index.processStatus',
  };

  return (
    <div className={combineStyles('LessonHeader', [inSafeMode && theme, process.env.CUSTOM_NAME])}>
      <Helmet>
        <title>{notEmptyLessonName}</title>
        <meta property="og:title" content={notEmptyLessonName} />
        <meta name="twitter:title" content={notEmptyLessonName} />
      </Helmet>

      {!isOlympiadMode && (
        <div className={combineStyles('topline', lessonStatus)}>
          <div className={combineStyles('status', lessonStatus)}>
            {t(textLessonStatus[lessonStatus])}
          </div>

          {isTasksInLesson && isRegularLesson && (
            <div className="right">
              <div className="regular-result">
                <div className="value">
                  {`${Math.ceil((correctTasksCount / totalTasksCount) * 100)}%`}
                </div>
                <div className="text">
                  <Text value={t('index.myResult')} />
                </div>
              </div>
            </div>
          )}

          {isTasksInLesson && isExamLesson && (
            <div className="right">
              <div className="minimal">
                <div className="value">{minimalSuccessScore}%</div>
                <div className="text">
                  <Text value={t('index.minimalScore')} />
                </div>
              </div>
              <div className="regular-result">
                <div className={combineStyles('icon', lessonStatus)}>
                  {isLessonComplete ? (
                    `${Math.ceil((correctTasksCount / totalTasksCount) * 100)}%`
                  ) : (
                    <Icon size={24} name="eye-off" />
                  )}
                </div>
                <div className="text">
                  <Text value={t('index.myResult')} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isExamLesson && (
        <div className={combineStyles('type', lessonType)}>
          <div className="icon">
            <Icon name={iconLessonType[lessonType]} />
          </div>
          <div className="text">
            <Text value={t(textLessonType[lessonType])} />
          </div>
        </div>
      )}

      {isOlympiadMode &&
        olympiadPartnersLogos.isShow &&
        olympiadPartnersLogos.source && (
          <div
            className={combineStyles('partners', [
              'olympiad',
              inSafeMode && theme,
            ])}
          >
            <img src={olympiadPartnersLogos.source} alt="" />
          </div>
        )}
      <div
        className={combineStyles(
          'name',
          isOlympiadMode && !olympiadPartnersLogos.isShow && 'pad-top'
        )}
      >
        <Text
          value={lessonName}
          variant={combineStyles(
            'player-lesson-name',
            inSafeMode && [fontSize, theme]
          )}
        />
      </div>
      {(!isLessonComplete ||
        (isLessonComplete && olympiadDescriptionInResult)) && (
        <div className="description">
          <Text
            value={lessonDescription}
            variant={combineStyles(
              'player-lesson-description',
              inSafeMode && [fontSize, theme]
            )}
          />
        </div>
      )}
      <div className="info">
        {!isOlympiadMode && isTasksInLesson && (
          <div className="summary">
            <div className="mark">
              {`${executedBlocksCount}/${totalTasksCount}`}
              <div className="text">
                <Text value={t('index.completedTasks')} />
              </div>
            </div>
            <ProgressBar
              width={isMobile ? 270 : 395}
              value={executedBlocksCount / totalTasksCount}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const { bool, string, object, number } = PropTypes;

LessonHeader.propTypes = {
  theme: string,
  fontSize: string,
  isMobile: bool,
  lessonType: string,
  inSafeMode: bool.isRequired,
  lessonName: string,
  isOlympiadMode: bool.isRequired,
  totalTasksCount: number.isRequired,
  isLessonComplete: bool.isRequired,
  correctTasksCount: number.isRequired,
  lessonDescription: string,
  minimalSuccessScore: number,
  executedBlocksCount: number,
  olympiadPartnersLogos: object,
  olympiadDescriptionInResult: bool,
};

LessonHeader.defaultProps = {};

export default compose(
  withTheming,
  withMobileDetect
)(LessonHeader);
