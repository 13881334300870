import { put, takeLatest, all } from 'redux-saga/effects';
import { get, assign, values, compose, map, reduce, keyBy } from 'lodash/fp';
import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import { apiRequest } from '../../request';
import { REQUEST_NAME_COURSE_DOWNLOADING } from '~/appConstants';

function* watchDownloadAll() {
  yield takeLatest(BUILDER.DOWNLOAD_COURSES, function*() {
    const response = yield apiRequest('course/list', null, 'get', {
      name: REQUEST_NAME_COURSE_DOWNLOADING,
    });

    if (!response) {
      return;
    }

    const {
      data: { error, payload },
    } = response;

    if (error) {
      yield put(actions.builder.downloadCoursesError(error));
      return;
    }

    const { asTeacher, asTutor } = payload;

    yield put(
      actions.builder.downloadCoursesSuccess({
        asTeacher: keyBy('id', asTeacher),
        asTutor: keyBy('id', asTutor),
      })
    );
  });
}

function* watchDownloadOne() {
  yield takeLatest(BUILDER.DOWNLOAD_COURSE, function*({ payload: { id } }) {
    const response = yield apiRequest(`course/${id}`, null, 'get', {
      name: REQUEST_NAME_COURSE_DOWNLOADING,
      showNotFoundOn404: true,
    });
    if (response) {
      const {
        data: { error, payload: course },
      } = response;

      const parseData = (type) =>
        compose(
          reduce(assign, {}),
          map(get(['content', type])),
          values
        )(course.lessons);

      const downloadedPages = parseData('pages');
      const downloadedBlocks = parseData('blocks');
      const downloadedLessons = parseData('lesson');

      if (!error) {
        yield put(
          actions.builder.downloadCourseSuccess(
            course,
            downloadedLessons,
            downloadedPages,
            downloadedBlocks
          )
        );
      } else {
        yield put(actions.builder.downloadCourseError(error));
      }
    }
  });
}

export default function*() {
  yield all([watchDownloadOne(), watchDownloadAll()]);
}
