import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import Text from '~/components/Text';
import Icon from '~/components/Icon';
import Input from '~/components/Input';
import Button from '~/components/Button';
import Froala from '~/components/Froala';
import Uploader from '~/containers/Uploader';

import * as selectors from '~/selectors';
import * as actions from '~/actions';
import * as s from './styles/Leveling.styles';
import { combineStyles } from '~/utils/styles';

function Leveling({ lessonId, selectLevel }) {
  const { t, i18n } = useTranslation('containers');

  const [opened, setOpened] = useState(0);
  const dispatch = useDispatch();
  const { levels = [] } = useSelector(
    selectors.builder.lesson.getOlympiadSettings(lessonId)
  );

  const changeOlympiadSettings = (path) => (value) =>
    dispatch(
      actions.builder.lesson.changeOlympiadSettings(lessonId, path, value)
    );
  const addOlympiadLevelingInterval = () =>
    dispatch(
      actions.builder.lesson.addOlympiadLevelingInterval({
        lessonId,
      })
    );

  const removeOlympiadLevelingInterval = (index) =>
    dispatch(
      actions.builder.lesson.removeOlympiadLevelingInterval({
        lessonId,
        index,
      })
    );

  return (
    <div css={s.body}>
      <div>
        {levels.map(({ image, ...result }, i) => (
          <s.Row key={result.id}>
            <div
              onClick={() => {
                setOpened(i === opened ? null : i);
                selectLevel(i);
              }}
              css={s.rowTitle}
            >
              <span css={s.rowTitleText}>
                {result.minScore || 0}-{result.maxScore || 0}
              </span>
              &nbsp;
              <span css={s.score}>баллов</span>
              <Icon up={i === opened} css={s.icon} name="chevron-down" />
              {levels.length > 1 && (
                <Icon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeOlympiadLevelingInterval(i);
                  }}
                  css={s.removeIcon}
                  name="osh-remove"
                />
              )}
            </div>
            <AnimateHeight height={opened === i ? 'auto' : 0}>
              <div css={s.subtitle}>Диапазон баллов</div>
              <div css={s.interval}>
                <Input
                  type="number"
                  hideZeroOnClick
                  debounceTime={0}
                  onChange={changeOlympiadSettings(['levels', i, 'minScore'])}
                  css={s.inputLittle}
                  value={result.minScore}
                />

                <div css={s.divider}>—</div>
                <Input
                  type="number"
                  hideZeroOnClick
                  debounceTime={0}
                  onChange={changeOlympiadSettings(['levels', i, 'maxScore'])}
                  css={s.inputLittle}
                  value={result.maxScore}
                />
              </div>
              <div css={s.subtitle}>Подзаголовок</div>
              <Input
                onChange={changeOlympiadSettings(['levels', i, 'subtitle'])}
                css={s.input}
                value={
                  i18n.exists(`containers:${result.subtitle}`)
                    ? t(result.subtitle)
                    : result.subtitle
                }
              />
              <div css={s.subtitle}>Текст</div>
              <Froala
                mode="editor"
                css={s.froalaInput}
                content={
                  i18n.exists(`containers:${result.text}`)
                    ? t(result.text)
                    : result.text
                }
                toolbarInline={false}
                toolbarButtons={['insertLink']}
                onChange={changeOlympiadSettings(['levels', i, 'text'])}
              />

              <div css={s.imageContainer}>
                <div className={combineStyles('image', 'big')}>
                  {image.source ? (
                    <div className="preview">
                      <img css={s.img} src={image.source} alt="" />
                      <Button
                        minimal
                        icon="delete"
                        onClick={() =>
                          changeOlympiadSettings([
                            'levels',
                            i,
                            'image',
                            'source',
                          ])(null)
                        }
                        text={t('settings.removeImage')}
                        variant="settings-image-remove"
                      />
                    </div>
                  ) : (
                    <div css={s.uploader}>
                      <Uploader
                        variant="in-settings"
                        type="settings"
                        icon="osh-imageupload"
                        lessonId={lessonId}
                        pathLastName="image"
                        filePath={[
                          lessonId,
                          'meta',
                          'settings',
                          'olympiad',
                          'levels',
                          i,
                        ]}
                        title={t('settings.uploadImage')}
                        fileType="image"
                        uploadType="lesson"
                      />
                      <div css={s.imageHint}>
                        <Text
                          value={t('settings.levelingImageHint1')}
                          variant="settings-uploader-hint"
                        />
                        <Text
                          value={t('settings.levelingImageHint2')}
                          variant="settings-uploader-hint"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </AnimateHeight>
          </s.Row>
        ))}
        <div onClick={addOlympiadLevelingInterval} css={s.addIntervalButton}>
          <Icon css={s.addNewLittle} name="osh-addnew-little" /> Добавить
          диапазон
          <Icon
            position="top"
            css={s.addNewHintIcon}
            name="help"
            variant="small-gray"
            tooltip={t('settings.maxAddIntervalHintIcon')}
          />
        </div>
        <div css={s.addIntervalHint}>
          {t('settings.levelingAddIntervalHint')}
        </div>
      </div>
    </div>
  );
}

const { string, func } = PropTypes;

Leveling.propTypes = {
  selectLevel: func.isRequired,
  lessonId: string.isRequired,
};

export default Leveling;
