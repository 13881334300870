import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, isEmpty, size, reverse, filter, uniq } from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import { withState, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import TagsInput from 'react-tagsinput';

import {
  COURSE_STATUS_ACTIVE,
  REQUEST_NAME_SEND_INVITES,
  REQUEST_NAME_COURSE_DOWNLOADING,
} from '~/appConstants';
import Icon from '~/components/Icon';
import Text from '~/components/Text';
import Tabs from '~/components/Tabs';
import Input from '~/components/Input';
import Button from '~/components/Button';
import Hyperlink from '~/components/Hyperlink';
import Emoji, { STUDENT } from '~/components/Emoji';
import DotLoader from '~/components/DotLoader';
import { isEmail } from '~/utils/validation';
import * as actions from '~/actions';
import * as helpers from '~/helpers';
import * as selectors from '~/selectors';
import { combineStyles } from '~/utils/styles';
import { isRequestActive } from '~/selectors/ui';
import * as s from './styles/CourseScreen.styles';

const CourseStudents = ({
  t,
  name,
  tags,
  sendInfo,
  students,
  activeTab,
  changeTab,
  tagsChange,
  lastInvites,
  sendInvites,
  searchValue,
  showSendInfo,
  courseStatus,
  removeStudent,
  setSearchValue,
  isActiveSearch,
  isInvitesSending,
  setIsActiveSearch,
  toggleAddStudents,
  isAddStudentsShow,
  invalidInvitesQuantity,
}) => {

  const query = new URLSearchParams(useLocation().search)
  const type = query.get('type')
  const filteredStudents = students.filter((student) =>
    `${student.name}|${student.email}`
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  );

  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_COURSE_DOWNLOADING)
  );

  useEffect(() => {
    if (!size(lastInvites)) {
      return;
    }

    showSendInfo(lastInvites);
    tagsChange([]);
  }, [lastInvites, tagsChange, showSendInfo]);

  const isCourseActive = courseStatus === COURSE_STATUS_ACTIVE;

  return isLoading ? (
    <DotLoader css={s.loader} />
  ) : (
    <div css={s.container}>
      {isAddStudentsShow && (
        <div css={s.addMembers}>
          <div css={s.close}>
            <Button
              minimal
              icon="osh-remove"
              onClick={() => toggleAddStudents(false)}
              variant="add-students-close"
            />
          </div>
          <Text
            variant="add-students"
            value={t('courseStudents.addStudents')}
          />

          <TagsInput
            value={tags}
            addKeys={[13, 32]}
            onChange={(tag) => tagsChange(uniq(tag))}
            renderTag={(tag) => (
              <span
                className={combineStyles('tag', [!isEmail(tag.tag) && 'error'])}
                key={tag.key}
              >
                {tag.tag}
                <div className="remove" onClick={() => tag.onRemove(tag.key)}>
                  <Icon name="osh-remove" variant="remove-student" />
                </div>
              </span>
            )}
            tagProps={{ type: name }}
            variant="add-students"
            addOnPaste
            pasteSplit={(data) => {
              const separators = [
                ' ',
                ',',
                ';',
                '\\(',
                '\\)',
                '\\*',
                '/',
                ':',
                '\\?',
                '\n',
                '\r',
              ];
              return data
                .split(new RegExp(separators.join('|')))
                .map(
                  (d) =>
                    isEmail(d.toLowerCase().trim()) && d.toLowerCase().trim()
                );
            }}
            inputProps={{ placeholder: t('courseStudents.enterEmail') }}
          />
          {!isEmpty(helpers.course.filterEmails(tags)) && (
            <Button
              text={t('courseStudents.add')}
              variant="add-students"
              loading={isInvitesSending}
              onClick={() => sendInvites(tags)}
            />
          )}

          <div css={s.status}>
            {!isEmpty(sendInfo.filter((email) => email.valid)) && (
              <div css={s.statusSuccess}>
                <Icon name="tick-circle" variant="course-email-send" />
                <Text
                  value={`${t('courseStudents.successSendText', {
                    count: size(sendInfo.filter((email) => email.valid)),
                  })}`}
                  variant="course-email-send"
                />
              </div>
            )}
            {!isEmpty(sendInfo) && invalidInvitesQuantity > 0 && (
              <div css={s.statusFail}>
                <Icon name="warning-sign" variant="course-email-send" />
                <Text
                  value={`${t('courseStudents.failSendText', {
                    count: invalidInvitesQuantity,
                  })}`}
                  variant="course-email-send"
                />
              </div>
            )}
          </div>
        </div>
      )}

      {isActiveSearch && (
        <div css={s.search}>
          <div css={s.searchClose}>
            <Icon
              onClick={() => {
                setSearchValue('');
                setIsActiveSearch(false);
              }}
              name="osh-remove"
              size={40}
              color="#b2b6b8"
            />
          </div>
          <Input
            debounceTime={0}
            variant="in-monitoring-search"
            placeholder={t('courseStudents.searchPlaceholder')}
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
      )}

      <div css={s.content}>
        <div css={s.toggler}>
          {!isEmpty(students) && (
            <div
              css={s.searchIcon}
              onClick={() => !isActiveSearch && setIsActiveSearch(true)}
            >
              {!isActiveSearch && (
                <Icon name="search" color="#767b84" size={20} />
              )}
            </div>
          )}
          {!isAddStudentsShow && type !== 'tutor' && (
            <Button
              disabled={!isCourseActive}
              text={t('courseStudents.addStudent')}
              icon="osh-addnew"
              variant="add-user-toggler"
              onClick={() => {
                toggleAddStudents(true);
                showSendInfo([]);
              }}
            />
          )}
          {!isCourseActive && (
            <div css={s.statusWrong}>
              <Text value={t('courseStudents.wrongStatus')} />
            </div>
          )}
        </div>
        <div css={s.contentContainer}>
          {!isAddStudentsShow && isEmpty(students) && (
            <div css={s.emptyList}>
              <div css={s.emptyListIcon}>
                <Emoji symbol={STUDENT} label="student" />
              </div>
              <div css={s.emptyListTitle}>
                <Text value={t('courseStudents.emptyListTitle')} />
              </div>
              <div css={s.emptyListText}>
                <Text value={t('courseStudents.emptyListText')} />
              </div>
              <Hyperlink
                newTab
                href="https://help-ru.coreapp.ai/courses"
                text={t('courseStudents.emptyListDetails')}
                variant="add-students-to-course-details"
              />
            </div>
          )}
          {!isEmpty(students) && (
            <div css={s.tabs}>
              <Tabs
                onChange={(_id) => changeTab(_id)}
                selectedTabId={activeTab}
              >
                {[
                  ['all', filteredStudents],
                  ['active', filter({ status: 'active' }, filteredStudents)],
                  ['invited', filter({ status: 'invited' }, filteredStudents)],
                ].map(([_id, data]) => (
                  <Tabs.Tab
                    key={_id}
                    id={_id}
                    title={
                      <Tabs.TitleWithCounter
                        count={size(data)}
                        title={t(`courseStudents.tabs.${_id}`)}
                        isActive={_id === activeTab}
                      />
                    }
                    panel={
                      !isEmpty(students) && (
                        <div css={s.members}>
                          {activeTab === 'active' ? (
                            <div css={s.membersText}>
                              {t('courseStudents.tabActiveText')}
                            </div>
                          ) : activeTab === 'invited' ? (
                            <div css={s.membersText}>
                              {t('courseStudents.tabInvitedText')}
                            </div>
                          ) : (
                            undefined
                          )}

                          <table css={s.membersTable}>
                            <tbody>
                              {data.map((student) => (
                                <tr key={student.email}>
                                  <td css={s.membersTableName}>
                                    {student.name || '...'}
                                  </td>
                                  <td css={s.membersTableEmail}>
                                    {student.email}
                                  </td>
                                  <td>
                                    {t(
                                      `courseStudents.status${student.status}`
                                    )}
                                  </td>
                                  {type !== 'tutor' && (
                                    <td className="control">
                                      <div className="remove">
                                        <Icon
                                          onClick={() =>
                                            removeStudent(student.email)
                                          }
                                          name="osh-remove"
                                          size={39}
                                          color="#b2b6b8"
                                          style={{
                                            marginLeft: '-2px',
                                            position: 'relative',
                                            top: '-5px',
                                          }}
                                        />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )
                    }
                  />
                ))}
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const { func, string, array, bool, number } = PropTypes;

CourseStudents.propTypes = {
  t: func.isRequired,
  name: string,
  tags: array,
  sendInfo: array, // Отправленные инвайты
  students: array,
  activeTab: string.isRequired,
  changeTab: func.isRequired,
  tagsChange: func,
  showSendInfo: func,
  sendInvites: func.isRequired,
  lastInvites: array,
  searchValue: string.isRequired,
  courseStatus: string.isRequired,
  removeStudent: func.isRequired,
  setSearchValue: func.isRequired,
  isActiveSearch: bool.isRequired,
  isInvitesSending: bool.isRequired,
  setIsActiveSearch: func.isRequired,
  toggleAddStudents: func.isRequired,
  isAddStudentsShow: bool.isRequired,
  invalidInvitesQuantity: number,
};

CourseStudents.defaultProps = {
  tags: [],
  sendInfo: [],
  students: [],
};

const mapStateToProps = (state, ownProps) => {
  const { id = ownProps.match.params.id } = ownProps;
  const students = reverse(selectors.builder.course.getStudents(id, state));
  const courseStatus = selectors.builder.getCourseStatus(id)(state);
  const invalidInvitesQuantity = selectors.builder.course.getInvalidQuantity(
    id,
    state
  );
  const lastInvites = selectors.builder.course.getLastInvites(id, state);

  const isInvitesSending = selectors.ui.isRequestActive(
    REQUEST_NAME_SEND_INVITES
  )(state);

  return {
    id,
    students,
    lastInvites,
    courseStatus,
    isInvitesSending,
    invalidInvitesQuantity,
  };
};

const mapDispatchToProps = {
  sendInvites: actions.builder.course.sendStudentsInvites,
  removeStudent: actions.builder.course.removeStudent,
};

const enhance = compose(
  withState('tags', 'tagsChange', []),
  withState('isAddStudentsShow', 'toggleAddStudents', false),
  withState('sendInfo', 'showSendInfo', []),
  withState('activeTab', 'changeTab', 'all'),
  withState('searchValue', 'setSearchValue', ''),
  withState('isActiveSearch', 'setIsActiveSearch', false),
  withHandlers({
    sendInvites: ({ sendInvites, id }) => (tags) =>
      sendInvites({
        courseId: id,
        emails: tags,
      }),
    removeStudent: ({ removeStudent, id }) => (email) =>
      removeStudent({ id, email }),
  })
);

export default compose(
  withTranslation('screens'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(CourseStudents);
