import * as RESULTS from '../constants/results';
import { createAction } from '../utils/actions';

export const open = (lessonId) => createAction(RESULTS.OPEN, { lessonId });
export const close = () => createAction(RESULTS.CLOSE);
export const download = (lessonId, publications, setLoader) =>
  createAction(RESULTS.DOWNLOAD, { lessonId, publications, setLoader });
export const selectResult = (resultIndex) =>
  createAction(RESULTS.SELECT_RESULT, { resultIndex });
export const selectPage = (pageId) =>
  createAction(RESULTS.SELECT_PAGE, { pageId });
export const downloadError = (message) =>
  createAction(RESULTS.DOWNLOAD_ERROR, { message });
export const downloadSuccess = (lessonId, results, publication) =>
  createAction(RESULTS.DOWNLOAD_SUCCESS, { lessonId, results, publication });
export const resetConfirm = (lessonId, publication) =>
  createAction(RESULTS.RESET_CONFIRM, { lessonId, publication });
export const resetCancel = () => createAction(RESULTS.RESET_CANCEL);
export const resetAccept = (lessonId, publication) =>
  createAction(RESULTS.RESET_ACCEPT, { lessonId, publication });
export const resetError = (message) =>
  createAction(RESULTS.RESET_ERROR, { message });
export const resetSuccess = (lessonId, publication) =>
  createAction(RESULTS.RESET_SUCCESS, { lessonId, publication });
export const sendMessage = ({
  resultId,
  lessonId,
  newMessage,
  resultIndex,
  messageIndex,
}) =>
  createAction(RESULTS.SEND_MESSAGE, {
    resultId,
    message: newMessage,
    lessonId,
    resultIndex,
    messageIndex,
  });
export const sendMessageError = (message) =>
  createAction(RESULTS.SEND_MESSAGE_ERROR, { message });
export const sendMessageSuccess = ({
  comment,
  lessonId,
  resultIndex,
  messageIndex,
}) =>
  createAction(RESULTS.SEND_MESSAGE_SUCCESS, {
    comment,
    lessonId,
    resultIndex,
    messageIndex,
  });
export const downloadPublicOlympiadResult = ({ resultId }) =>
  createAction(RESULTS.DOWNLOAD_PUBLIC_OLYMPIAD_RESULT, { resultId });
export const downloadPublicOlympiadResultSuccess = ({
  lessonContent,
  lessonId,
  olympiadSettings,
  publication,
  checkedResult,
  userName,
}) =>
  createAction(RESULTS.DOWNLOAD_PUBLIC_OLYMPIAD_RESULT_SUCCESS, {
    lessonContent,
    lessonId,
    olympiadSettings,
    publication,
    checkedResult,
    userName,
  });
export const downloadPublicOlympiadResultError = ({ resultId }) =>
  createAction(RESULTS.DOWNLOAD_PUBLIC_OLYMPIAD_RESULT_ERROR, { resultId });
