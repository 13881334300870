import React from 'react';
import { connect } from 'react-redux';
import { lifecycle, withStateHandlers } from 'recompose';
import { compose, constant } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { Intent, ProgressBar } from '@blueprintjs/core';

import * as actions from '~/actions';
import withToaster from '~/hocs/withToaster';
import '../styles/Notice.scss';

const types = {
  error: Intent.DANGER,
  success: Intent.SUCCESS,
  default: Intent.PRIMARY,
  warning: Intent.WARNING,
};

const Notice = constant(null);

const mapStateToProps = (state) => ({
  id: state.notice.id,
  type: state.notice.type,
  actionText: state.notice.actionText,
  message: state.notice.message,
  error: state.notice.error,
  progress: state.notice.progress,
});

const enhance = compose(
  withTranslation('containers'),
  connect(mapStateToProps),
  withToaster,
  withStateHandlers(
    {},
    {
      showToast: ({ key }, { toaster }) => (fileProgress) => ({
        key: toaster.show(
          {
            icon: 'cloud-upload',
            timeout: fileProgress < 1 ? 0 : 500,
            message: <ProgressBar intent="primary" value={fileProgress / 1} />,
          },
          key
        ),
      }),
    }
  ),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentWillReceiveProps(nextProps) {
      const { t, dispatch } = this.props;
      const { type, message, error, actionText, id, progress } = nextProps;

      if (progress) {
        this.props.showToast(progress);
      } else if (
        this.props.toaster &&
        this.props.id !== id &&
        (message || error)
      ) {
        this.props.toaster.show({
          intent: types[type],
          message: `${t(message)}${error ? `: ${t(error)}` : ''}`,
          timeout: 2000,
          action: actionText && {
            onClick: () => dispatch(actions.notice.onAction(id)),
            text: t(actionText),
          },
          onDismiss: () => dispatch(actions.notice.onDismiss(id)),
        });
      }
    },
    /* eslint-enable fp/no-this */
  })
);

export default enhance(Notice);
