export const OPEN = '@library/share/block/Открыть окно шаринга блоков';
export const SAVE = '@library/share/block/Добавить блок в библиотеку';
export const CLOSE =
  '@library/share/block/Закрыть окно добавления блока в библиотеку';
export const CHANGE_TERMS = '@library/share/block/Изменить термины для блока';
export const CHANGE_DATES = '@library/share/block/Изменить даты для блока';
export const CHANGE_THEMES = '@library/share/block/Изменить темы для блока';
export const CHANGE_PERSONS =
  '@library/share/block/Изменить персоналии для блока';
export const CHANGE_SUBJECT = '@library/share/block/Изменить предмет для блока';
