// import memoize from 'fast-memoize';
import { findDOMNode } from 'react-dom';

/**
 * Определяет наличие хэндлера на платформе
 */
export const isTouchDevice =
  document.documentElement && 'ontouchstart' in document.documentElement;

/**
 * Определяет позицию для вставки элемента
 */
export const getDropPosition = (source, receiver) => {
  // eslint-disable-next-line react/no-find-dom-node
  const receiverBoundingRect = findDOMNode(receiver).getBoundingClientRect();
  const receiverMiddleY =
    (receiverBoundingRect.bottom - receiverBoundingRect.top) / 2;
  const sourceOffset = source.getClientOffset();
  const sourceHoverY = sourceOffset.y - receiverBoundingRect.top;
  return sourceHoverY > receiverMiddleY ? 'bottom' : 'top';
};

const emptyWrapper = (_) => _;

/**
 * Условное подключение коннекторов DnD
 */
export const connectDnD = (condition, connector) =>
  condition ? connector : emptyWrapper;
