import { set } from 'lodash/fp';
import { GET_PAYMENT_HISTORY_SUCCESS } from '~/actions/profile';
import { SET_EXTRA_DAYS } from '~/actions/payment';

const defaultState = {
  history: null,
  extraDays: 0,
};

const payment = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PAYMENT_HISTORY_SUCCESS: {
      const { history } = action.payload;
      return set('history', history, state);
    }
    case SET_EXTRA_DAYS: {
      const { extraDays } = action.payload;
      return set('extraDays', extraDays, state);
    }
    default:
      return state;
  }
};

export default payment;
