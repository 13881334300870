import localeRu from 'date-fns/locale/ru';
import localeEn from 'date-fns/locale/en';
import formatDate from 'date-fns/format';

const locales = {
  ru: localeRu,
  en: localeEn,
  kz: localeRu,
};

export const formatCardDate = (date, i18n) =>
  formatDate(date * 1000, 'D MMMM YYYY в HH:mm', {
    locale: locales[i18n.languages[0]],
  });
