import { css } from 'styled-components';

export const prolongationSwitch = css`
  & {
    > .bp3-switch,
    > .bp3-switch:hover {
      > input:checked ~ .bp3-control-indicator {
        background: #8acf00 !important;
      }
    }

    > .bp3-switch {
      font-size: 14px;
      color: #45494e;
    }
  }
`;

export const changePlan = css`
  padding: 42px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin-top: 42px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #45494e;
    margin-right: 84px;
  }
`;

export const cardIcon = css`
  line-height: 0;
  margin-right: 24px;
`;

export const cardNumber = css`
  font-family: CirceRounded;
  font-size: 16px;
  line-height: 1.25;
  color: #767b84;
`;

export const cardLeft = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const cardRight = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const cardButton = css`
  margin-left: 10px;
`;
