import {
  get,
  set,
  pull,
  size,
  unset,
  update,
  compose,
  identity,
} from 'lodash/fp';

import * as PLAYER from '../../constants/player';
import { uniqPush, push } from '../../utils/array';
import * as LESSON from '../../constants/player/lesson';
import { RESULT_STATUS_COMPLETE } from '../../appConstants';
import * as UPLOADER from '../../constants/uploader';
import contentReducer from './contentReducer';
import {
  ADD_NEW_REPLY_SUCCESS,
  REMOVE_FILE_FROM_REPLY,
} from '~/actions/player/block/answer';

export default (state = null, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_EXECUTION_SUCCESS: {
      return action.payload.result;
    }

    case PLAYER.SET_NEW_RESULT: {
      // eslint-disable-next-line more/no-duplicated-chains
      return action.payload.result;
    }

    case PLAYER.CLEAR_RESULT: {
      return null;
    }

    case PLAYER.LOGIN: {
      const { userName, userId, email } = action.payload;
      return compose(
        set('userName', userName),
        set('userId', userId),
        set('onlineStatus', true),
        set('mail', email)
      )(state);
    }

    case PLAYER.PRE_LOGOUT: {
      return set('onlineStatus', false, state);
    }

    case PLAYER.LOGOUT: {
      return null;
    }

    case PLAYER.SET_EXECUTION_TIME: {
      const { newExecutionTime } = action.payload;
      return set('executionTime', newExecutionTime, state);
    }

    case PLAYER.CHANGE_ACTIVE_PAGE: {
      const { pageId } = action.payload;

      return compose(
        set('activePage', pageId),
        set('onlineStatus', true),
        pageId ? update('viewedPagesIds', uniqPush(pageId)) : identity
      )(state);
    }

    case LESSON.SET_RUN_TIME: {
      const { newRunTime } = action.payload;
      return set('runTime', newRunTime, state);
    }

    case LESSON.COMPLETE: {
      return compose(
        set('onlineStatus', false),
        set('status', RESULT_STATUS_COMPLETE)
        // set('viewedPagesIds', []) // CON-1789
      )(state);
    }

    case PLAYER.BLOCK.RESET: {
      const {
        payload: { id },
      } = action;

      return update('verifyModeBlocksIds', pull(id), state);
    }

    case PLAYER.BLOCK.VERIFY: {
      const {
        payload: { id },
      } = action;
      return update(
        'verifyModeBlocksIds',
        (blocksIds) => uniqPush(id, blocksIds || []),
        state
      );
    }

    case PLAYER.PAGE.VERIFY: {
      const {
        payload: { id },
      } = action;
      return update(
        'verifyModePagesIds',
        (pagesIds) => uniqPush(id, pagesIds || []),
        state
      );
    }

    case PLAYER.PAGE.COMPLETE: {
      const {
        payload: { id },
      } = action;
      return update(
        'completedPagesIds',
        (pagesIds) => uniqPush(id, pagesIds || []),
        state
      );
    }

    case PLAYER.UPDATE_EXECUTION_RESULT_SUCCESS: {
      const { pages } = action.payload;

      return set('pages', pages, state);
    }

    case ADD_NEW_REPLY_SUCCESS: {
      const {
        payload: { blockId, reply },
      } = action;

      return update(['blocks', blockId, 'reply'], push(reply), state);
    }

    case REMOVE_FILE_FROM_REPLY: {
      const {
        payload: { id, reply, blockId },
      } = action;

      return unset([
        'blocks',
        blockId,
        'reply',
        size(reply) - 1,
        'newStudentFiles',
        id,
      ])(state);
    }

    case String(action.type.match(/@player\/.*/)): {
      return get(['meta', 'context', 'inPlayer'], action) ||
        get(['meta', 'context', 'inPreview'], action)
        ? contentReducer(state, action)
        : state;
    }

    case UPLOADER.UPLOAD_SUCCESS: {
      const { source, filePath, inPlayer } = action.payload;

      if (inPlayer) {
        return set(['blocks', ...filePath, 'source'], source, state);
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};
