import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { DropTarget } from 'react-dnd';
import { Link as RouterLink } from 'react-router-dom';
import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import Icon from './Icon';
import '../styles/Link.scss';

const Link = ({
  to,
  text,
  isOver,
  active,
  disabled,
  variant,
  onClick,
  withIcon,
  canDrag,
  isMobile,
  children,
  className,
  connectDropTarget,
}) => {
  const WrapperComponent = to ? RouterLink : 'div';

  const Component = (
    <WrapperComponent
      to={to}
      onClick={!disabled ? onClick : undefined}
      className={combineStyles(
        ['Link', className],
        [
          isOver && canDrag && 'dnd-hover',
          variant,
          active && 'active',
          isMobile && 'mobile',
        ]
      )}
    >
      {children || (
        <>
          {withIcon && (
            <div className="icon">
              <Icon name={withIcon} variant={variant} />
            </div>
          )}
          {text}
        </>
      )}
    </WrapperComponent>
  );

  return canDrag ? connectDropTarget(Component) : Component;
};

const { string, bool, array, oneOfType, func } = PropTypes;

Link.propTypes = {
  text: string, // Текст
  isOver: bool, // dnd окончен?
  canDrag: bool, // dnd подключен?
  active: bool, // Ссылка активна?
  variant: oneOfType([array, string]), // Вариант оформления
  to: string, // адрес ссылки
  onClick: func, // При клике
  isMobile: bool.isRequired,
  withIcon: string, // Ссылка с иконкой
  className: string,
  disabled: bool, // Ссылка отключена (отмена действия по нажатию)
  children: array,
  connectDropTarget: func, // Подключение dnd
};

Link.defaultProps = {
  active: false,
  variant: null,
  disabled: false,
};

const dropTarget = {
  drop(props, monitor) {
    props.onDragEnd(monitor.getItem().lessonId, props.folderId);
  },
};

const collectDropTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default compose(
  pure,
  withMobileDetect,
  DropTarget('lesson', dropTarget, collectDropTarget)
)(Link);
