import {
  split,
  sortBy,
  identity,
  compose,
  padCharsStart,
  join,
  map,
  filter,
  isNumber,
} from 'lodash/fp';

/**
 * Разбить строку и отсортировать полученный массив
 */
export const splitStringAndSort = compose(
  sortBy(identity),
  split('')
);

export const durationToString = (duration = 0) => {
  const seconds = Number(duration) || 0;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  return compose(
    join(':'),
    map(padCharsStart('0', 2)),
    filter(isNumber)
  )([hours || null, minutes % 60, seconds % 60]);
};
