import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import { compose, pick, uniq, map, get, getOr, isEmpty } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import withTheming from '~/hocs/withTheming';
import { combineStyles } from '../utils/styles';
import * as context from '../utils/context';
import '../styles/BlocksTypes.scss';
import Icon from '~/components/Icon';

const blocksIcons = {
  Survey: {
    icon: 'osh-block-quiz-radio',
    tooltipKey: 'blocksTypes.survey',
  },
  Text: {
    icon: 'osh-block-text',
    tooltipKey: 'blocksTypes.text',
  },
  Quiz: {
    icon: 'osh-block-quiz',
    tooltipKey: 'blocksTypes.quiz',
  },
  Exam: {
    icon: 'osh-block-autoopenquestion',
    tooltipKey: 'blocksTypes.exam',
  },
  Video: {
    icon: 'osh-block-video',
    tooltipKey: 'blocksTypes.video',
  },
  Embed: {
    icon: 'osh-block-interactive',
    tooltipKey: 'blocksTypes.embed',
  },
  VR: {
    icon: 'osh-block-vr',
    tooltipKey: 'blocksTypes.vr',
  },
  Answer: {
    icon: 'osh-block-openquestion',
    tooltipKey: 'blocksTypes.answer',
  },
  Match: {
    icon: 'osh-block-classification',
    tooltipKey: 'blocksTypes.match',
  },
  Weight: {
    icon: 'osh-block-classification',
    tooltipKey: 'blocksTypes.weight',
  },
  Images: {
    icon: 'osh-block-img',
    tooltipKey: 'blocksTypes.images',
  },
  Document: {
    icon: 'osh-block-page',
    tooltipKey: 'blocksTypes.document',
  },
  FillBlank: {
    icon: 'osh-block-fill-blank',
    tooltipKey: 'blocksTypes.fillblank',
  },
  Talk: {
    icon: 'osh-dialog',
    tooltipKey: 'blocksTypes.talk',
  },
  Instruction: {
    icon: 'osh-block-instruction',
    tooltipKey: 'blocksTypes.instruction',
  },
  Feedback: {
    icon: 'osh-block-feedback',
    tooltipKey: 'blocksTypes.feedback',
  },
};

const BlocksTypes = ({
  t,
  theme,
  variant,
  fontSize,
  inResult,
  inSafeMode,
  inBirdView,
  blocksTypes,
}) => (
  <div
    className={combineStyles('BlocksTypes', [
      variant,
      inResult && 'in-result',
      inBirdView && 'bird-view',
      inSafeMode && [fontSize, theme],
    ])}
  >
    {blocksTypes.map(
      (blockType, index) =>
        // TODO how blockType can be empty?
        blockType && (
          <div className="block" key={index}>
            <Icon
              name={blocksIcons[blockType].icon}
              tooltip={t(blocksIcons[blockType].tooltipKey)}
              variant={[
                'page-preview-block',
                inResult && 'preview-in-result',
                inSafeMode && [fontSize, theme],
                variant,
              ]}
            />
          </div>
        )
    )}
  </div>
);

const { string, array, bool, func } = PropTypes;

BlocksTypes.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string.isRequired, // Цветовая тема в режиме ОВЗ
  variant: string, // Вариант оформления
  fontSize: string.isRequired, // Размер шрифта в режиме ОВЗ
  inResult: bool, // В просморе результатов?
  inBirdView: bool, // В режиме бёрдвью?
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
  blocksTypes: array, // Типы блоков страницы в предпросмотре
};

BlocksTypes.defaultProps = {
  inResult: false,
  blocksTypes: [],
};

const mapStateToProps = (state, ownProps) => {
  const {
    pageId,
    inResult,
    inPlayer,
    inBuilder,
    inBirdView,
    inThumbnail,
  } = ownProps;
  const contexts = {
    inPlayer,
    inBuilder: inResult || inBuilder,
  };
  const page = context.getPages(contexts)(state)[pageId];
  
  // Фильтрация блоков для опросника
  const currentResult = inPlayer && state.player.result;
  const selectedBlocksIds = currentResult ? currentResult.selectedBlocksIds : []
  const allBlocks = isEmpty(selectedBlocksIds)
    ? context.getBlocks(contexts)(state)
    : pick(selectedBlocksIds, context.getBlocks(contexts)(state));

  const result = get(['player', 'result'], state);

  const pageBlocks = pick(
    inPlayer
      ? getOr([], ['pages', pageId, 'displayedBlocksIds'], result)
      : page.blocksIds
  )(allBlocks);

  const blocksTypes =
    inThumbnail || inBirdView || inResult
      ? compose(
          uniq,
          map('type')
        )(pageBlocks)
      : [];

  return { blocksTypes };
};

export default compose(
  pure,
  withTranslation('components'),
  connect(mapStateToProps),
  withTheming
)(BlocksTypes);
