import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Tooltip.scss';

const positions = {
  top: Blueprint.Position.TOP,
  left: Blueprint.Position.LEFT,
  right: Blueprint.Position.RIGHT,
  bottom: Blueprint.Position.BOTTOM,
  rightTop: Blueprint.Position.RIGHT_TOP,
};

const Tooltip = ({
  usePortal,
  content,
  variant,
  position,
  children,
  disabled,
  className,
  hoverOpenDelay,
  portalClassName,
  tooltipClassName,
}) => (
  <div className={combineStyles(['Tooltip', className], variant)}>
    <Blueprint.Tooltip
      position={positions[position]}
      usePortal={usePortal}
      content={content}
      disabled={disabled}
      hoverOpenDelay={hoverOpenDelay}
      tooltipClassName={tooltipClassName}
      portalClassName={portalClassName}
    >
      {children}
    </Blueprint.Tooltip>
  </div>
);

const {
  array,
  string,
  oneOf,
  oneOfType,
  arrayOf,
  element,
  bool,
  number,
} = PropTypes;

Tooltip.propTypes = {
  usePortal: bool, // Рендерить тултип в одном блоке с кнопкой?
  variant: oneOfType([array, string]), // Вариант оформления
  className: string,
  content: oneOfType([
    // Содержание тултипа
    element.isRequired,
    arrayOf(element.isRequired).isRequired,
    string,
  ]).isRequired,
  children: oneOfType([
    // Внутренний контент
    arrayOf(element.isRequired).isRequired,
    element.isRequired,
  ]).isRequired,
  position: oneOf(['top', 'left', 'right', 'bottom', 'rightTop']), // Позиция
  disabled: bool, // Тултип показывается?ƒ
  hoverOpenDelay: number, // Задержка перед открытием тултипа при ховере
  tooltipClassName: string, // Класс для тултипа
  portalClassName: string, // Дополнительный класс для портала
};

Tooltip.defaultProps = {
  variant: undefined,
  disabled: false,
  position: Blueprint.Position.LEFT,
  usePortal: false,
  hoverOpenDelay: 100,
  tooltipClassName: undefined,
  portalClassName: undefined,
};

export default pure(Tooltip);
