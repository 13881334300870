import * as NOTICE from '../constants/notice';
import { createAction } from '../utils/actions';

export const show = (message, type) =>
  createAction(NOTICE.SHOW, { message, type });
export const showSuccess = ({ message, actionText, id }) =>
  createAction(NOTICE.SHOW, { message, actionText, id, type: 'success' });
export const showError = ({ message, actionText, id }) =>
  createAction(NOTICE.SHOW, { message, actionText, id, type: 'error' });
export const onDismiss = (id) => createAction(NOTICE.ON_DISMISS, { id });
export const onAction = (id) => createAction(NOTICE.ON_ACTION, { id });
