import { css } from 'styled-components';
import lampImg from '../../../static/img/lamp.png';

export const container = css`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

export const errorCode = css`
  font-family: Gilroy;
  font-size: 100px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: -0.26px;
  color: #45494e;
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
`;

export const errorImg = css`
  width: 80%;
  max-width: 500px;
  height: 200px;
  background-size: contain;
  background-image: url(${lampImg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const errorText = css`
  font-family: CirceRounded;
  font-size: 18px;
  text-align: center;
  color: #767b84;
`;

export const errorButton = css`
  margin: 40px 0 30px;
`;
