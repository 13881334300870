import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import plansDataMap from '~/plansDataMap';
import {
  // PLAN_NAME_CUSTOM,
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
  PLAN_NAME_NUM10,
} from '~/appConstants';

import * as s from './styles/PlanContent.styles';

const PlanContent = ({ planName }) => {
  const { t } = useTranslation('components');

  const plansData = plansDataMap[planName];

  return (
    <>
      <div css={s.name}>{plansData.title}</div>
      <div css={s.activeCourses}>
        <Trans
          i18nKey={
            plansData.activeCoursesText || 'subscriptionPlans.activeCourses'
          }
          t={t}
          values={{ count: plansData.coursesCount }}
        />
      </div>
      <div css={s.more}>
        <Trans i18nKey="subscriptionPlans.plus" t={t} />
      </div>
      <div css={s.divider} />
      <div css={s.price}>{plansData.price}</div>
      <div css={s.monthPrice}>
        {t(plansData.bottomText || 'subscriptionPlans.monthPrice')}
      </div>
    </>
  );
};

const { oneOf } = PropTypes;

PlanContent.propTypes = {
  planName: oneOf([
    PLAN_NAME_FREE,
    PLAN_NAME_NUM1,
    PLAN_NAME_NUM3,
    PLAN_NAME_NUM7,
    PLAN_NAME_NUM10,
    // PLAN_NAME_CUSTOM,
  ]),
};

export default PlanContent;
