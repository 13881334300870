import PropTypes from 'prop-types';
import React from 'react';

import { compose, pure, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { combineStyles } from '~/utils/styles';
import { Button, Froala, Icon, Text } from '~/components';
import * as actions from '~/actions';

const BuilderBlockComment = ({
  t,
  theme,
  blockId,
  fontSize,
  addComment,
  inSafeMode,
  toggleComment,
  changeComment,
  isCommentsShow,
  isAddedComment,
  comments: { fail: failComment, success: successComment },
}) => (
  <div className={combineStyles('BlockComment', inSafeMode && theme)}>
    {!failComment && !successComment && !isAddedComment && (
      <div className="button">
        <Button
          minimal
          icon="osh-commented"
          variant="add-block-comment"
          text={t('comment.addComment')}
          onClick={() => {
            addComment(true);
            toggleComment(true);
          }}
        />
        <div className="icon">
          <Icon
            name="help"
            variant="small-gray"
            tooltip={t('comment.addCommentHint')}
          />
        </div>
      </div>
    )}

    {(failComment || successComment || isAddedComment) && (
      <>
        <div className="header">
          <div
            className="toggler"
            onClick={() => toggleComment(!isCommentsShow)}
          >
            <Icon name="osh-commented" variant="block-comment-title" />
            <Text
              variant={
                inSafeMode
                  ? ['block-comment-title', theme]
                  : 'block-comment-title'
              }
              value={t('comment.title')}
            />
            <div className="arrow">
              <Icon
                name={isCommentsShow ? 'chevron-up' : 'chevron-down'}
                variant="block-comment-toggler"
              />
            </div>
          </div>
          <div className="remove">
            <Button
              minimal
              icon="cross"
              variant="remove-block-comment"
              onClick={() => {
                changeComment(blockId, 'fail', undefined);
                changeComment(blockId, 'success', undefined);
                addComment(false);
              }}
            />
          </div>
        </div>

        {isCommentsShow && (
          <div className="content">
            <div className="comment">
              <div className="title">
                <div className="icon">
                  <Icon
                    name="help"
                    variant="small-gray"
                    tooltip={t('comment.failAnswerCommentHint')}
                  />
                </div>
                <Text
                  variant="block-comment-fail"
                  value={t('comment.failAnswerComment')}
                />
              </div>
              <div className="text">
                <Froala
                  mode="editor"
                  placeholder={t('comment.enterComment')}
                  content={failComment}
                  variant={
                    inSafeMode
                      ? ['block-comment', theme, fontSize]
                      : 'block-comment'
                  }
                  onChange={(value) => changeComment(blockId, 'fail', value)}
                  toolbarButtons={['bold', 'italic', 'insertLink']}
                />
              </div>
            </div>

            <div className="comment">
              <div className="title">
                <div className="icon">
                  <Icon
                    name="help"
                    variant="small-gray"
                    tooltip={t('comment.successAnswerCommentHint')}
                  />
                </div>
                <Text
                  variant="block-comment-success"
                  value={t('comment.successAnswerComment')}
                />
              </div>
              <div className="text">
                <Froala
                  mode="editor"
                  placeholder={t('comment.enterComment')}
                  content={successComment}
                  variant={
                    inSafeMode
                      ? ['block-comment', theme, fontSize]
                      : 'block-comment'
                  }
                  onChange={(value) => changeComment(blockId, 'success', value)}
                  toolbarButtons={['bold', 'italic', 'insertLink']}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

const { string, bool, func, object } = PropTypes;

BuilderBlockComment.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Цветовая тема в режиме ОВЗ
  blockId: string, // ID блока
  comments: object, // Комментарии
  fontSize: string, // Размер шрифта в режиме ОВЗ
  addComment: func.isRequired, // Функция добавления комментария
  inSafeMode: bool, // В режиме ОВЗ?
  changeComment: func, // Функция изменения комментария
  toggleComment: func.isRequired, // Показать/скрыть комментарии
  isCommentsShow: bool.isRequired, // Комментарии показаны?
  isAddedComment: bool.isRequired, // Комментарий добавлен?
};

BuilderBlockComment.defaultProps = {
  theme: 'light',
  comments: {},
  fontSize: 'small',
  inSafeMode: false,
};

const mapDispatchToProps = {
  changeComment: actions.builder.block.changeComment,
};

export default compose(
  pure,
  withState('isAddedComment', 'addComment', false),
  withState('isCommentsShow', 'toggleComment', false),
  withTranslation('componentsBlock'),
  connect(
    undefined,
    mapDispatchToProps
  )
)(BuilderBlockComment);
