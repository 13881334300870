import React from 'react';
import PropTypes from 'prop-types';
import Resizable from 're-resizable';
import { pure } from 'recompose';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../styles/Resizer.scss';

const Resizer = ({ width, blockId, imageId, children, changeSize }) => (
  <Resizable
    size={{ width, height: 'auto' }}
    minWidth={300}
    maxWidth={790}
    className="resizer"
    onResizeStop={(_, __, ___, delta) =>
      changeSize(blockId, imageId, width + delta.width)
    }
    handleClasses={{ bottomRight: 'handler' }}
    enable={{
      top: false,
      left: false,
      right: false,
      bottom: false,
      topLeft: false,
      topRight: false,
      bottomLeft: false,
      bottomRight: true,
    }}
  >
    {children}
  </Resizable>
);

const { any, func, string, number } = PropTypes;

Resizer.propTypes = {
  width: number, // Ширина блока
  blockId: string, // ID блока мультикартинки
  imageId: string, // ID картинки
  children: any.isRequired, // Внутренний контент
  changeSize: func, // При изменении ширины
};

const mapDispatchToProps = {
  changeSize: actions.builder.block.images.changeSize,
};

Resizer.defaultProps = {
  width: 790,
  blockId: undefined,
  imageId: undefined,
  changeSize: undefined,
};

export default compose(
  pure,
  connect(
    null,
    mapDispatchToProps
  )
)(Resizer);
