import { put, takeLatest } from 'redux-saga/effects';
import { keyBy, mapValues } from 'lodash/fp';
import * as actions from '~/actions';
import { apiRequest } from './request';
import {
  REQUEST_NAME_COURSE_DOWNLOADING,
  REQUEST_NAME_RESULT_DOWNLOADING,
} from '~/appConstants';

const api = {
  studyList: 'course/study_list',
  results: (userId) => `result/user/${userId}`,
};

export function* downloadAllCourses() {
  yield takeLatest(actions.study.course.download, function* _() {
    const response = yield apiRequest(api.studyList, null, 'get', {
      name: REQUEST_NAME_COURSE_DOWNLOADING,
    });

    if (response) {
      const {
        data: { error, payload: courses },
      } = response;

      if (!error) {
        yield put(actions.study.course.downloadSuccess(keyBy('id', courses)));
      } else {
        yield put(actions.study.course.downloadError(error));
      }
    }
  });
}

/**
 * Загрузка и импортирование всех прохождений пользователя
 */
export function* downloadAllResults() {
  yield takeLatest(actions.study.results.download, function* _(action) {
    const { userId, search } = action.payload;
    const addResult = search.addResult ? `addResult=${search.addResult}` : '';
    const url = api.results(userId) + addResult;
    const response = yield apiRequest(url, null, 'get', {
      name: REQUEST_NAME_RESULT_DOWNLOADING,
    });
    if (response) {
      const {
        error,
        payload: { results },
      } = response.data;
      const downloadedResults = mapValues(
        (result) => ({
          lessonName: result.lessonName,
          comments: result.comments,
          passedAt: result.modified,
          lessonId: result.lessonId,
          publication: result.publication,
          isLtiLesson: result.isLtiLesson,
          lessonSettings: result.lessonSettings,
          isLessonComplete: result.isLessonComplete,
        }),
        keyBy('id', results)
      );
      if (!error) {
        yield put(actions.study.results.downloadSuccess(downloadedResults));
      } else {
        yield put(actions.study.results.downloadError(error));
      }
    }
  });
}
