import Auth from './Auth';
import Page from './Page';
import Pages from './Pages';
import Block from './Block';
import Share from './Share';
import Blocks from './Blocks';
import Notice from './Notice';
import MoveTo from './MoveTo';
import Lesson from './Lesson';
import Confirm from './Confirm';
import Folders from './Folders';
import Sidebar from './Sidebar';
import Library from './Library';
import Results from './Results';
import Settings from './Settings';
import Uploader from './Uploader';
import UploaderLoader from './UploaderLoader';
import Messenger from './Messenger';
import PrintPage from './PrintPage';
import Templates from './Templates';
import Onboarding from './Onboarding';
import UserResults from './UserResults';
import VerifyBlock from './VerifyBlock';
import QuizContent from './QuizContent';
import NewPassword from './Auth/NewPassword';
import NavUserBar from './NavUserBar';
import SelectLocale from './SelectLocale';
import Presentation from './Presentation';
import WelcomePreview from './WelcomePreview';
import PassRecovery from './Auth/PassRecovery';
import MonitoringStudentsList from './MonitoringStudentsList';

export {
  Auth,
  Page,
  Share,
  Pages,
  Block,
  Blocks,
  Lesson,
  Notice,
  MoveTo,
  Confirm,
  Folders,
  Sidebar,
  Library,
  Results,
  Uploader,
  UploaderLoader,
  Settings,
  Messenger,
  PrintPage,
  Templates,
  Onboarding,
  NavUserBar,
  UserResults,
  VerifyBlock,
  QuizContent,
  NewPassword,
  PassRecovery,
  SelectLocale,
  Presentation,
  WelcomePreview,
  MonitoringStudentsList,
};
