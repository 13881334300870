import React from 'react';
import PropTypes from 'prop-types';

import { withState, withHandlers, lifecycle, compose } from 'recompose';
import Modal from 'react-modal';
import Lightbox from 'react-image-lightbox';
import { withTranslation } from 'react-i18next';

import { combineStyles } from '../../utils/styles';
import '../../styles/Block/Image.scss';
import { DraggedButton as Button } from '~/components/Button';
import Resizer from '~/components/Resizer';
import Icon from '~/components/Icon';
import Uploader from '~/containers/Uploader';
import Text from '~/components/Text';

// Вынести Resizer за пределы компонента

const Image = ({
  t,
  type,
  theme,
  width,
  source,
  variant,
  imageId,
  blockId,
  fontSize,
  inPlayer,
  changeURL,
  inBirdView,
  imageIndex,
  imagesList,
  inSafeMode,
  inEditMode,
  changeImage,
  removeImage,
  description,
  openLightbox,
  inPreviewMode,
  isLightboxOpen,
  imagesQuantity,
  inPresentation,
  isLessonComplete,
  changeDescription,
  updateBlockInPresentation,
}) => (
  <div
    className={combineStyles('Image', [
      variant,
      inPreviewMode && 'preview-mode',
      inBirdView && 'bird-view',
      inPresentation && 'in-presentation',
    ])}
  >
    {source &&
      (inEditMode || (type === 'answer-image' && inPlayer)) &&
      !isLessonComplete && (
        <div className="button">
          <Button
            minimal
            icon={
              type === 'answer-image' && inPlayer
                ? 'osh-trash'
                : 'osh-clearcontent'
            }
            variant="remove-image"
            onClick={removeImage}
          />
        </div>
      )}
    {type === 'answer-image' && source ? (
      <div
        className="content"
        onClick={() => {
          openLightbox(!isLightboxOpen);
          changeImage(imagesList.indexOf(source));
        }}
      >
        <img
          src={source}
          alt={t('image.image')}
          width={width}
          className="image"
          onLoad={() =>
            updateBlockInPresentation
              ? inPresentation && updateBlockInPresentation()
              : undefined
          }
        />
        <div className="icon">
          <Icon name="zoom-in" variant="image-zoom" />
        </div>
      </div>
    ) : (
      <Resizer width={width} imageId={imageId} blockId={blockId}>
        {source ? (
          <div
            className="content"
            onClick={() => {
              openLightbox(!isLightboxOpen);
              changeImage(imagesList.indexOf(source));
            }}
          >
            <img
              src={source}
              alt={t('image.image')}
              width={width}
              className="image"
              onLoad={() =>
                updateBlockInPresentation
                  ? inPresentation && updateBlockInPresentation()
                  : undefined
              }
            />
            <div className="icon">
              <Icon name="zoom-in" variant="image-zoom" />
            </div>
          </div>
        ) : inEditMode ? (
          <div className="uploader">
            <Uploader
              uploadType="block"
              variant={variant}
              icon="osh-imageupload"
              title={t('image.uploadImage')}
              blockId={blockId}
              imageId={imageId}
              filePath={[blockId, 'content', 'collection', imageId]}
              onURLChange={(newURL) => changeURL(newURL)}
              imagesQuantity={imagesQuantity}
              fileType="image"
            />
          </div>
        ) : inPreviewMode ? (
          <div className="empty">
            <div className="icon">
              <Icon name="osh-imageupload" variant={variant} />
            </div>
            <div className="text">
              <Text
                value={t('image.imageNotLoaded')}
                variant={['empty-block-text', variant]}
              />
            </div>
          </div>
        ) : (
          undefined
        )}
        {inEditMode ? (
          <Text
            value={description}
            variant={['image-description', variant]}
            editable
            multiline
            onChange={changeDescription}
            placeholder={t('image.addDescription')}
          />
        ) : description ? (
          <Text
            value={description}
            variant={[
              'image-description',
              variant,
              inSafeMode && fontSize,
              inSafeMode && theme,
            ]}
            multiline
          />
        ) : null}
      </Resizer>
    )}

    {isLightboxOpen && (
      <Lightbox
        mainSrc={imagesList[imageIndex]}
        nextSrc={imagesList[(imageIndex + 1) % imagesList.length]}
        prevSrc={
          imagesList[(imageIndex + imagesList.length - 1) % imagesList.length]
        }
        onCloseRequest={() => openLightbox(!isLightboxOpen)}
        onMovePrevRequest={() =>
          changeImage((imageIndex + imagesList.length - 1) % imagesList.length)
        }
        onMoveNextRequest={() =>
          changeImage((imageIndex + 1) % imagesList.length)
        }
        imageCaption={description}
      />
    )}
  </div>
);

const { string, number, bool, arrayOf, func, oneOfType, array } = PropTypes;

Image.propTypes = {
  t: func.isRequired, // Функция перевода
  source: string, // URL
  inPlayer: bool, // В плеере?
  type: string, // Тип изображения
  width: number, // Ширина картинки
  inSafeMode: bool, // В режиме ОВЗ?
  inPresentation: bool, // В презентации
  blockId: string, // ID блока
  inPreviewMode: bool, // В режиме просмотра?
  description: string, // Описание изображения
  theme: string, // Цветовая тема в режиме ОВЗ
  inEditMode: bool, // В режиме редактирования?
  fontSize: string, // Размер шрифта в режиме ОВЗ
  imageId: string.isRequired, // ID блока картинки
  changeURL: func, // Функция изменения URL картинки
  inBirdView: bool, // Блок на странице в режиме bird view
  removeImage: func, // Функция очистки source картинки
  imagesQuantity: number, // Количество картинок в блоке
  isLightboxOpen: bool, // Увеличенная картинка открыта?
  imageIndex: number, // Индекс изображения в модальном окне
  variant: oneOfType([array, string]), // Вариант оформления
  openLightbox: func, // Функция показа увеличенной картинки
  imagesList: arrayOf(string), // Список ссылок на изображения
  changeImage: func, // Переключение изображения в модальном окне
  isLessonComplete: bool, // Урок завершен?
  changeDescription: func.isRequired, // Поменять описание изображения
  updateBlockInPresentation: func, // Обновление страницы в презентации
};

Image.defaultProps = {
  width: 790,
  imageIndex: 0,
  theme: 'light',
  type: undefined,
  fontSize: 'small',
  source: undefined,
  variant: undefined,
  inPlayer: undefined,
  changeURL: undefined,
  inBirdView: undefined,
  inSafeMode: undefined,
  isLightboxOpen: false,
  inEditMode: undefined,
  imagesList: undefined,
  changeImage: undefined,
  removeImage: undefined,
  description: undefined,
  openLightbox: undefined,
  inPreviewMode: undefined,
  inPresentation: undefined,
  imagesQuantity: undefined,
  isLessonComplete: false,
  updateBlockInPresentation: undefined,
};

const enhance = compose(
  withTranslation('componentsBlock'),
  withState('isLightboxOpen', 'openLightbox', false),
  withState('imageIndex', 'changeImage', 0),
  withHandlers({
    changeURL: ({ changeURL, blockId, imageId }) => (newURL) =>
      changeURL(blockId, imageId, newURL),
    removeImage: ({ removeImage, blockId, imageId }) => () =>
      removeImage(imageId, blockId),
    changeDescription: ({ blockId, imageId, changeDescription }) => (
      newDescription
    ) => changeDescription(blockId, imageId, newDescription),
  }),
  lifecycle({
    componentWillMount() {
      Modal.setAppElement('body');
    },
  })
);

export default enhance(Image);
