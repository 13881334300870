import React from 'react';

import bowser from 'bowser';
import { withState, pure, lifecycle } from 'recompose';
import { DragSource } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose, filter } from 'lodash/fp';

import '../styles/Controls.scss';
import lessonImg from '../../static/img/dnd/lesson.png';
import { combineStyles } from '../utils/styles';
import Popover from '~/components/Popover';
import { DraggedButton as Button } from '~/components/Button';
import Hyperlink from '~/components/Hyperlink';
import Tooltip from '~/components/Tooltip';
import MoveTo from '~/containers/MoveTo';
import Icon from '~/components/Icon';

const Controls = ({
  t,
  icon,
  type,
  items,
  variant,
  canDrag,
  lessonId,
  courseId,
  position,
  usePortal,
  buttonText,
  isMoveToOpen,
  toggleMoveTo,
  isPopoverVisible,
  connectDragSource,
  togglePopoverVisible,
}) =>
  type === 'dropdown' ? (
    <Popover
      usePortal={usePortal}
      popoverWillOpen={() => togglePopoverVisible(true)}
      popoverWillClose={() => togglePopoverVisible(false)}
      target={
        canDrag ? (
          connectDragSource(
            <div>
              <Button
                icon={icon}
                minimal
                variant={`${variant}-controls-toggler`}
              />
            </div>
          )
        ) : variant === 'block' ? (
          <Tooltip
            usePortal={false}
            position="bottom"
            hoverOpenDelay={1000}
            variant="block-control"
            disabled={isPopoverVisible}
            tooltipClassName="dark-theme"
            content={
              <div>
                <strong>{t('controls.drag')}</strong> {t('controls.to')}
                <br />
                {t('controls.move')}
                <br />
                <strong>{t('controls.click')}</strong> {t('controls.to')}
                <br />
                {t('controls.open')}
                <br />
              </div>
            }
          >
            <Button
              icon={icon}
              minimal
              text={buttonText}
              variant={`${variant}-controls-toggler`}
            />
          </Tooltip>
        ) : (
          <Button
            icon={icon}
            minimal
            text={buttonText}
            variant={`${variant}-controls-toggler`}
          />
        )
      }
      content={
        !isMoveToOpen ? (
          items.map((item, index) =>
            React.isValidElement(item) ? (
              <div key={index} className="custom-item">
                {item}
              </div>
            ) : (
              <div key={index} className="button">
                {item.buttonType === 'hyperlink' ? (
                  <Hyperlink
                    icon={item.icon}
                    text={item.text}
                    href={item.href}
                    newTab
                    variant={['in-controls-dropdown', variant]}
                  />
                ) : (
                  <Button
                    icon={item.icon}
                    text={item.text}
                    minimal
                    canDrag
                    onClick={item.action}
                    noDismissPopoverOnClick={item.noDismissPopoverOnClick}
                    variant={['in-controls-dropdown', variant]}
                  />
                )}
              </div>
            )
          )
        ) : (
          <MoveTo
            lessonId={lessonId}
            courseId={courseId}
            close={() => toggleMoveTo(false)}
          />
        )
      }
      variant={variant}
      position={position}
    />
  ) : type === 'panel' ? (
    <div className={combineStyles('Controls', [variant, type])}>
      {filter((item) => !item.disabled, items).map((item, index) => (
        <div className="button" key={index}>
          {item.buttonType === 'hyperlink' ? (
            <Hyperlink
              icon={item.icon}
              text={item.text}
              href={item.href}
              newTab
              variant={['lesson-controls', variant]}
            />
          ) : (
            <>
              <Button
                icon={item.icon}
                minimal
                disabled={item.disabled}
                onClick={item.action}
                text={item.text}
                variant={['lesson-controls', item.variant]}
              />
              {item.spinner && (
                <Icon name="osh-savewarning-refresh" variant="spinner" />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  ) : null;

const {
  arrayOf,
  bool,
  shape,
  string,
  func,
  oneOf,
  oneOfType,
  element,
  array,
} = PropTypes;

Controls.propTypes = {
  t: func.isRequired, // Функция перевода
  icon: string, // Иконка для выпадающего меню
  canDrag: bool, // Подключен dnd?
  lessonId: string, // ID урока
  courseId: string,
  buttonText: string, // Тект для кнопки открытия выпадающего меню
  items: arrayOf(
    // Массив внутренних элементов контрола
    oneOfType([
      shape({
        icon: string, // Иконка
        action: func, // Действие при нажатии
        tooltip: oneOfType([string, element]), // Всплывающая подсказка
        variant: oneOfType([array, string]), // Вариант оформления
        spinner: bool, // Есть загрузочный спиннер?
        buttonType: oneOf([
          // Тип кнопок в выпадашке
          'hyperlink',
          'button',
        ]),
      }),
      element,
    ]).isRequired
  ).isRequired,
  type: oneOf([
    // Тип контролов
    'panel', // В виде статичной панели
    'dropdown', // в виде выпадающего списка
  ]).isRequired,
  usePortal: bool, // Рендерить поповер в одном блоке с кнопкой?
  variant: oneOfType([array, string]), // Вариант оформления
  position: oneOf([
    // Расположение выпадающего списка контролов
    'top',
    'left',
    'right',
    'bottom',
    'rightTop',
    'topRight',
  ]),
  isMoveToOpen: bool, // Окно перемещения урока открыто?
  toggleMoveTo: func, // Функция открытия окна перемещения урока
  connectDragSource: func, // Подключение dnd
  isPopoverVisible: bool.isRequired, // Флаг открытости поповера
  togglePopoverVisible: func.isRequired, // Переключатель флага isPopoverOpen
};

Controls.defaultProps = {
  canDrag: undefined,
  icon: 'osh-settings',
  usePortal: undefined,
  variant: undefined,
  lessonId: undefined,
  courseId: undefined,
  position: undefined,
  buttonText: undefined,
  isMoveToOpen: false,
  toggleMoveTo: undefined,
  connectDragSource: undefined,
};

const dragSource = {
  beginDrag: (props) => ({ lessonId: props.lessonId }),
  endDrag: (props, monitor) => monitor.didDrop(),
};

const collectDragSource = (connect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});

const enhance = lifecycle({
  componentDidMount() {
    const image = new Image();
    // eslint-disable-next-line fp/no-mutation
    image.src = lessonImg;
    image.addEventListener(
      'load',
      () =>
        // eslint-disable-next-line fp/no-this
        !bowser.msie && !bowser.msedge && this.props.connectDragPreview(image)
    );
  },
});

export default compose(
  withTranslation('components'),
  DragSource('lesson', dragSource, collectDragSource),
  withState('isPopoverVisible', 'togglePopoverVisible', false),
  enhance,
  pure
)(Controls);
