import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { Provider } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import { I18nextProvider } from 'react-i18next';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { lifecycle } from 'recompose';
// import ym from 'react-yandex-metrika';
// import { hotjar } from 'react-hotjar';
import { ThemeProvider } from 'styled-components';

import withMobileDetect from '~/hocs/withMobileDetect';
import withTheming from '~/hocs/withTheming';
import { Confirm, Notice } from './containers';
import { isTouchDevice } from './utils/drag';
import { DragLayer } from './components';
import Header from './Header';
import Router from './routes/Router';

const App = ({
  i18n,
  store,
  history,
  isMobile,
  inSafeMode,
  theme,
  fontSize,
}) => (
  <ThemeProvider theme={{ isMobile, inSafeMode, theme, fontSize }}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <div>
          <Header />
          <Notice />
          <Confirm />
          <DragLayer />
          <Router history={history} />
        </div>
      </Provider>
    </I18nextProvider>
  </ThemeProvider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  inSafeMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
};

const setDnDBackend = isTouchDevice
  ? TouchBackend({ enableTouchEvents: true })
  : HTML5Backend;

const enhance = compose(
  withTheming,
  withMobileDetect,
  lifecycle({
    componentDidMount() {
      // if (store.getState().auth.id) {
      //   ym.setUserId(store.getState().auth.id);
      //   ym.userParams({
      //     id: store.getState().auth.id,
      //     email: store.getState().auth.email,
      //   });
      // }
      // hotjar.initialize(758077, 6);
    },
  }),
  DragDropContext(setDnDBackend)
);

export default enhance(App);
