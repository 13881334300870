import React from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format';
import { connect, useSelector } from 'react-redux';
import { Select } from '@blueprintjs/select';
import { withContext, lifecycle, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  isNumber,
  isEmpty,
  compose,
  filter,
  map,
  get,
  keys,
  defaultsDeep,
  pick,
  values,
  sumBy,
} from 'lodash/fp';

import {
  BLOCK_PASS_STATUS_NOT_PASSED,
  BLOCK_PASS_STATUS_IN_PROCESS,
  REQUEST_NAME_RESULT_DOWNLOADING,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import '../styles/Results.scss';
import watchPatternImage from '../../static/img/watch-pattern.png';

import { Button, Dialog, Text, Tooltip, Icon, Hyperlink } from '../components';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as helpers from '../helpers';
import * as actions from '../actions';
import Page from '~/containers/Page';
import Block from '~/containers/Block';
import Messenger from '~/containers/Messenger';
import DotLoader from '~/components/DotLoader';
import { isRequestActive } from '~/selectors/ui';

const apiUrl = process.env.API_URL;

const Results = ({
  t,
  i18n,
  close,
  isOpen,
  results,
  statMode,
  showStat,
  learners,
  lessonId,
  setLoader,
  blocksIds,
  chartData,
  usersData,
  activePage,
  selectPage,
  lessonName,
  blocksStats,
  withoutData,
  learnersStat,
  publications,
  resetConfirm,
  selectResult,
  blocksByType,
  showMessenger,
  lessonPagesIds,
  isOlympiadMode,
  downloadResults,
  isLoaderPosible,
  isMessengerVisible,
  selectedPublication,
  selectedResultIndex,
  isHideMessengerHover,
  learnerViewedBlocksIds,
  hoverShowMessengerHelper,
  changeSelectedPublication,
}) => {
  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_RESULT_DOWNLOADING)
  );

  return (
    !withoutData && (
      <Dialog
        isOpen={isOpen}
        onClose={close}
        variant="results"
        className="Results"
      >
        <div className="Results">
          {!isOlympiadMode ? (
            <div className="header">
              <Text value={t('results.monitoring')} variant="results-title" />
              <Text
                value={lessonName && `«${lessonName}»`}
                variant="results-lesson-name"
              />
              <div className="reset-results">
                <Button
                  icon="osh-remove"
                  text={t('results.close')}
                  onClick={close}
                  variant="dialog-close"
                />
              </div>
            </div>
          ) : (
            <>
              <div className={combineStyles('header', 'in-olympiad')}>
                <Text
                  value={t('results.olympid')}
                  variant="results-olympiad-title"
                />
                <Text value={lessonName} variant="results-olympiad-name" />
              </div>
              <div className="reset-results">
                <Button
                  icon="osh-remove"
                  text={t('results.close')}
                  onClick={close}
                  variant="dialog-close"
                />
              </div>
            </>
          )}
          <div
            className={combineStyles(
              'publication',
              isOlympiadMode && 'in-olympiad'
            )}
          >
            {!isEmpty(publications) && (
              <Select
                filterable={false}
                items={publications.map(
                  (item) =>
                    `${t('results.version')} ${format(
                      item * 1000,
                      'DD.MM.YYYY HH:mm'
                    )}`
                )}
                itemRenderer={(item, { modifiers, index }) => (
                  <li key={index}>
                    <Button
                      minimal
                      text={item}
                      active={modifiers.active}
                      onClick={() => {
                        setLoader(true);
                        downloadResults(lessonId, index + 1, setLoader);
                        changeSelectedPublication(index + 1);
                        selectResult(undefined);
                        selectPage(undefined);
                      }}
                    />
                  </li>
                )}
              >
                <Button
                  text={`${t('results.version')} ${format(
                    (publications[selectedPublication - 1] ||
                      publications[publications.length - 1]) * 1000,
                    'DD.MM.YYYY HH:mm'
                  )}`}
                />
              </Select>
            )}
          </div>
          {!isOlympiadMode ? (
            isLoaderPosible && isLoading ? (
              <DotLoader className="center" />
            ) : (
              <>
                {!isEmpty(results) ? (
                  <>
                    <div
                      className={combineStyles('content', [
                        isMessengerVisible && 'messenger-visible',
                        isHideMessengerHover && 'helper-hovered',
                      ])}
                    >
                      <div className="learners-list">
                        <div className="subtitle">
                          <Text
                            value={t('results.allResults')}
                            variant="results-subtitle"
                          />
                        </div>
                        <Button
                          onClick={() => {
                            showStat(!statMode);
                            selectResult(undefined);
                          }}
                          minimal
                          icon="vertical-bar-chart-asc"
                          text={`${
                            statMode
                              ? t('results.statOff')
                              : t('results.statOn')
                          } ${t('results.statText')}`}
                          variant={['swtich-stat', statMode && 'dark']}
                        />
                        <div className="subtitle">
                          <Text
                            value={t('results.lookApart')}
                            variant="results-subtitle"
                          />
                        </div>
                        <div className="title">
                          <Text
                            value={t('results.lastname')}
                            variant="results-list-title"
                          />
                          <Text
                            value={t('results.tasksCompleted')}
                            variant="results-list-title"
                          />
                        </div>
                        {learners.map((learner, resultIndex) => (
                          <div
                            key={resultIndex}
                            onClick={() => {
                              selectResult(resultIndex);
                              selectPage(undefined);
                            }}
                            className={combineStyles(
                              'learner',
                              resultIndex === selectedResultIndex && 'active'
                            )}
                          >
                            {learner.lastModified
                              ? learner.onlineStatus &&
                                (+new Date() - learner.lastModified) / 3600000 <
                                  3 && (
                                  <div className="status">
                                    <div className="online" />
                                  </div>
                                )
                              : ''}
                            <div className="name">
                              <Text
                                value={learner.user}
                                variant="results-learner-name"
                              />
                            </div>
                            <div className="stats">
                              <Text
                                value={
                                  learnersStat[resultIndex].passedCorrectlyCount
                                }
                                variant="results-learner-stats-correct-quizes-count"
                              />
                              <Text
                                value={learnersStat[resultIndex].totalCount}
                                variant="results-learner-stats-total-quizes-count"
                              />
                            </div>
                          </div>
                        ))}
                        {learners.length > 0 && (
                          <Button
                            onClick={() =>
                              resetConfirm(
                                lessonId,
                                selectedPublication || publications.length
                              )
                            }
                            text={t('results.reset')}
                            variant="result-reset"
                          />
                        )}
                      </div>
                      <div className="lesson-pages">
                        <div className="pages">
                          <div
                            className={combineStyles('page', [
                              'result-all-pages',
                              !activePage && 'selected',
                            ])}
                            onClick={() => selectPage(undefined)}
                          >
                            <Text
                              value={t('results.allPages')}
                              variant="result-all-pages"
                            />
                          </div>
                          {lessonPagesIds.map((pageId) => (
                            <div
                              key={pageId}
                              className="page"
                              onClick={() => selectPage(pageId)}
                            >
                              <Page
                                inResult
                                inBuilder={false}
                                id={pageId}
                                lessonId={lessonId}
                                variant={
                                  activePage === pageId ? 'selected' : undefined
                                }
                                usersData={usersData(pageId) || []}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {statMode ? (
                        chartData.totalCount ? (
                          <div className="lesson-stat">
                            <div className="chart">
                              <div className="container">
                                <svg viewBox="0 0 64 64" className="pie">
                                  <defs>
                                    <pattern
                                      id="watch-pattern"
                                      x="0"
                                      y="0"
                                      patternUnits="userSpaceOnUse"
                                      height="240"
                                      width="240"
                                    >
                                      <image
                                        x="0"
                                        y="0"
                                        width="100%"
                                        height="100%"
                                        xlinkHref={watchPatternImage}
                                      />
                                    </pattern>
                                  </defs>
                                  {/* Правильные ответы */}
                                  <circle
                                    r="25%"
                                    cx="50%"
                                    cy="50%"
                                    style={{
                                      strokeDasharray: `${(chartData.passedCorrectly /
                                        (chartData.totalCount || 1)) *
                                        100.5} 1000`,
                                      stroke: '#8ccd0f',
                                      strokeDashoffset: 0,
                                    }}
                                  />
                                  {/* Неправильные ответы */}
                                  <circle
                                    r="25%"
                                    cx="50%"
                                    cy="50%"
                                    style={{
                                      strokeDasharray: `${(chartData.passedIncorrectly /
                                        (chartData.totalCount || 1)) *
                                        100.5} 1000`,
                                      stroke: '#f25200',
                                      strokeDashoffset: `-${(chartData.passedCorrectly /
                                        (chartData.totalCount || 1)) *
                                        100.5}`,
                                    }}
                                  />
                                  {/* В процессе */}
                                  <circle
                                    r="25%"
                                    cx="50%"
                                    cy="50%"
                                    style={{
                                      strokeDasharray: `${(chartData.inProcess /
                                        (chartData.totalCount || 1)) *
                                        100.5} 1000`,
                                      stroke: 'url(#watch-pattern)',
                                      strokeDashoffset: `-${(chartData.passedCorrectly /
                                        (chartData.totalCount || 1)) *
                                        100.5 +
                                        (chartData.passedIncorrectly /
                                          (chartData.totalCount || 1)) *
                                          100.5}`,
                                    }}
                                  />
                                </svg>
                              </div>
                              <div className="legend">
                                <div className="item">
                                  <div className="mark -correctly">
                                    <span className="bp3-icon bp3-icon-osh-check" />
                                  </div>
                                  {`${t('results.persentTrue')} - ${Math.round(
                                    (chartData.passedCorrectly /
                                      (chartData.totalCount || 1)) *
                                      100
                                  )}%`}
                                </div>
                                <div className="item">
                                  <div className="mark -incorrectly">
                                    <span className="bp3-icon bp3-icon-osh-addnew-little" />
                                  </div>
                                  {`${t('results.persentFalse')} - ${Math.round(
                                    (chartData.passedIncorrectly /
                                      (chartData.totalCount || 1)) *
                                      100
                                  )}%`}
                                </div>
                                <div className="item">
                                  <div className="mark -notpassed" />
                                  {`${t('results.persentNone')} - ${Math.round(
                                    (chartData.notPassed /
                                      (chartData.totalCount || 1)) *
                                      100
                                  )}%`}
                                </div>
                                <div className="item">
                                  <div className="mark -inprocess">
                                    <span className="bp3-icon bp3-icon-time" />
                                  </div>
                                  {`${t(
                                    'results.persentInProgress'
                                  )} - ${Math.round(
                                    (chartData.inProcess /
                                      (chartData.totalCount || 1)) *
                                      100
                                  )}%`}
                                </div>
                              </div>
                            </div>
                            <div className="details">
                              <div className="fixed">
                                <div className="table">
                                  <div className="line">
                                    <div className="user" />
                                  </div>
                                  {learnersStat.map((item, index) => (
                                    <div className="line" key={index}>
                                      <div className="user">
                                        {results[index].userName}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="scroll">
                                <div className="table">
                                  <div className="line">
                                    {!blocksByType.length && (
                                      <div className="cell">&nbsp;</div>
                                    )}
                                    {blocksByType.map((item, index) => (
                                      <div
                                        className="cell"
                                        key={`line${index}`}
                                      >
                                        <Tooltip
                                          content={item.content.question}
                                          position="right"
                                          variant="question-hint"
                                          tooltipClassName="black-theme"
                                          usePortal
                                        >
                                          <Text
                                            value={index + 1}
                                            variant="stat-head"
                                          />
                                        </Tooltip>
                                      </div>
                                    ))}
                                  </div>
                                  {learnersStat.map((item, index) => (
                                    <div className="line" key={index}>
                                      {blocksIds[0].map((key) => (
                                        <div className="cell" key={key}>
                                          {!Object.keys(
                                            item.blocksResult
                                          ).includes(key) ? (
                                            <div className="mark -notviewed">
                                              <span className="bp3-icon bp3-icon-eye-off" />
                                            </div>
                                          ) : item.blocksResult[key] ===
                                            BLOCK_PASS_STATUS_PASSED_CORRECTLY ? (
                                            <div className="mark -correctly">
                                              <span className="bp3-icon bp3-icon-osh-check" />
                                            </div>
                                          ) : item.blocksResult[key] ===
                                            BLOCK_PASS_STATUS_PASSED_INCORRECTLY ? (
                                            <div className="mark -incorrectly">
                                              <span className="bp3-icon bp3-icon-osh-addnew-little" />
                                            </div>
                                          ) : item.blocksResult[key] ===
                                            BLOCK_PASS_STATUS_IN_PROCESS ? (
                                            <div className="mark -inprocess">
                                              <span className="bp3-icon bp3-icon-time" />
                                            </div>
                                          ) : item.blocksResult[key] ===
                                            BLOCK_PASS_STATUS_NOT_PASSED ? (
                                            <div className="mark -notpassed" />
                                          ) : (
                                            undefined
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Text
                            value={t('results.noTasks')}
                            variant="result-empty-page"
                          />
                        )
                      ) : (
                        <div className="lesson-blocks">
                          {isNumber(selectedResultIndex) &&
                            !isEmpty(blocksIds) &&
                            blocksIds[selectedResultIndex].map((blockId) => (
                              <div
                                key={blockId}
                                className={combineStyles(
                                  'block',
                                  !learnerViewedBlocksIds[
                                    selectedResultIndex
                                  ].includes(blockId)
                                    ? 'not_viewed'
                                    : {
                                        [true]: 'correct',
                                        [false]: 'incorrect',
                                      }[
                                        blocksStats[selectedResultIndex]
                                          .isCorrect[blockId]
                                      ]
                                )}
                              >
                                {learnerViewedBlocksIds[
                                  selectedResultIndex
                                ].includes(blockId) && (
                                  <Block
                                    id={blockId}
                                    isCorrect={
                                      blocksStats[selectedResultIndex]
                                        .isCorrect[blockId]
                                    }
                                    lessonId={lessonId}
                                    resultIndex={selectedResultIndex}
                                    results={results}
                                    userData={learners[selectedResultIndex]}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    {isNumber(selectedResultIndex) &&
                      !isEmpty(blocksIds) &&
                      blocksIds[selectedResultIndex] && (
                        <>
                          <div
                            className={combineStyles('lesson-comments', [
                              isMessengerVisible && 'messenger-visible',
                            ])}
                          >
                            <Messenger
                              lessonId={lessonId}
                              resultIndex={selectedResultIndex}
                              messages={results[selectedResultIndex].comments}
                              showMessenger={showMessenger}
                              userName={learners[selectedResultIndex].user}
                              resultId={results[selectedResultIndex].id}
                            />
                          </div>
                          <div className="lesson-comment">
                            <Button
                              minimal
                              icon="comment"
                              variant="open-messenger"
                              onClick={() => showMessenger(!isMessengerVisible)}
                            />
                            <div
                              className={combineStyles(
                                'text',
                                i18n.languages[0]
                              )}
                            >
                              <Text
                                value={t('results.feedback')}
                                variant="messenger-open-text"
                              />
                            </div>
                          </div>
                          {isMessengerVisible && (
                            <div
                              className="lesson-comment-helper"
                              onMouseEnter={() =>
                                hoverShowMessengerHelper(true)
                              }
                              onMouseLeave={() =>
                                hoverShowMessengerHelper(false)
                              }
                              onClick={() => {
                                showMessenger(false);
                                hoverShowMessengerHelper(false);
                              }}
                            />
                          )}
                        </>
                      )}
                  </>
                ) : (
                  <div className="empty">
                    <Text
                      value={t('results.noResults')}
                      variant="results-empty"
                    />
                  </div>
                )}
              </>
            )
          ) : (
            <div className={combineStyles('content', 'in-olympiad')}>
              <div className="download">
                <Text
                  value={t('results.downloadResultsTitle')}
                  variant="results-download-title"
                />
                <Text
                  value={t('results.downloadResultsText')}
                  variant="results-download-text"
                />
                <div className="buttons">
                  <div className="button">
                    <div className="header">
                      <span className="icon" role="img" aria-label="">
                        👨‍💻
                      </span>
                      <Text
                        value={t('results.downloadByMembersTitle')}
                        variant="download-result-block-title"
                      />
                    </div>
                    <div className="text">
                      <Text
                        value={t('results.downloadByMembersText')}
                        variant="download-result-block-text"
                      />
                    </div>
                    <div className="link">
                      <Icon name="import" variant="download-result-file" />
                      <Text
                        value={t('results.downloadButton')}
                        variant="download-result-file"
                      />
                    </div>
                    <Hyperlink
                      newTab
                      href={`${apiUrl}/export/stat/${lessonId}/${selectedPublication ||
                        1}/full`}
                      variant="results-download"
                    />
                  </div>
                  <div className="button">
                    <div className="header">
                      <span className="icon" role="img" aria-label="">
                        ❓
                      </span>
                      <Text
                        value={t('results.downloadByQuestionsTitle')}
                        variant="download-result-block-title"
                      />
                    </div>
                    <div className="text">
                      <Text
                        value={t('results.downloadByQuestionsText')}
                        variant="download-result-block-text"
                      />
                    </div>
                    <div className="link">
                      <Icon name="import" variant="download-result-file" />
                      <Text
                        value={t('results.downloadButton')}
                        variant="download-result-file"
                      />
                    </div>
                    <Hyperlink
                      newTab
                      href={`${apiUrl}/export/stat/${lessonId}/${selectedPublication ||
                        1}`}
                      variant="results-download"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    )
  );
};

const { bool, string, array, func, number, arrayOf, object } = PropTypes;

// Все propTypes с данными в компоненте не являются обязательными
// из-за возможности рендеринга с одним передаваемым параметром - isEmpty
Results.propTypes = {
  t: func.isRequired, // Функция перевода
  i18n: object.isRequired,
  close: func.isRequired, // Закрыть окно результатов
  isOpen: bool, // Окно результатов открыто?
  results: array, // Массив результатов
  statMode: bool.isRequired, // Режим просмотра статистики включен?
  showStat: func.isRequired, // Включить режим просмотра статистики
  learners: array, // Список учеников
  lessonId: string, // ID урока
  usersData: func, // Данные о пользователях для страницы
  blocksIds: array, // Списки ID блоков
  setLoader: func,
  selectPage: func.isRequired, // Показать результаты конкретной страницы
  lessonName: string, // Имя урока
  activePage: string, // ID выбранной страницы
  blocksStats: array, // Статистика блокам
  selectResult: func.isRequired, // Выбрать результаты для просмотра
  showMessenger: func, // Функиця показа комментариев
  lessonPagesIds: arrayOf(string), // ID страниц урока
  isOlympiadMode: bool, // Урок в режиме олимпиады?
  isLoaderPosible: bool,
  downloadResults: func, // Функция загрузки результатов
  isMessengerVisible: bool, // Чат виден?
  isHideMessengerHover: bool, // Функция закрытия чата
  selectedPublication: number, // Номер выбранной публикации
  selectedResultIndex: number, // Индекс выбранных результатов
  learnerViewedBlocksIds: array, // Массив Id блоков, показанных ученику
  hoverShowMessengerHelper: func, // Функция скрытия комментариев
  changeSelectedPublication: func,
};

const BLOCKS = ['Quiz', 'Exam', 'Match', 'Weight', 'FillBlank', 'Talk', 'Answer'];

Results.defaultProps = {
  isOpen: false,
  results: [],
  learners: [],
  blocksIds: [],
  activePage: undefined,
  blocksStats: [],
  showMessenger: undefined,
  lessonPagesIds: [],
  isOlympiadMode: false,
  downloadResults: undefined,
  isMessengerVisible: false,
  selectedPublication: undefined,
  isHideMessengerHover: false,
  learnerViewedBlocksIds: [],
  hoverShowMessengerHelper: undefined,
  changeSelectedPublication: undefined,
};

const mapStateToProps = (state) => {
  const {
    isOpen,
    resultIndex,
    lessonId,
    activePage,
  } = selectors.results.getData(state);
  if (lessonId) {
    const {
      content: { name: lessonName },
      meta: { publications },
    } = selectors.builder.getLesson(lessonId, state);

    const premadeResults = selectors.results.getResults(lessonId, state);
    const publication = selectors.results.getPublication(lessonId, state);
    // ToDo убрать { ...publication, ...publication.content } когда у publication будет тоже плоская структура
    // такая херня творится потому, что у publication и results теперь разная структура
    // а нам нужно смержить их через defaultsDeep
    const results = premadeResults.map((item) =>
      defaultsDeep(item, { ...publication, ...publication.content })
    );
    const learners = helpers.result.getLearners(results);
    const lessonPagesIds = publication.content
      ? (!isEmpty(publication) && keys(publication.content.pages)) || []
      : undefined;

    const blocksIds = activePage
      ? helpers.result.getBlocksIdsByPage(activePage, results)
      : helpers.result.getBlocksIdsByType(BLOCKS, results);
    const blocksStats = helpers.result.getBlocksStatsByType(
      BLOCKS,
      results,
      publication
    );
    const selectedResultIndex =
      resultIndex > blocksIds.length ? 0 : resultIndex;
    const learnerViewedBlocksIds = helpers.result.getViewedBlocksIds(results);
    const learnersStat = helpers.result.getLearnersStat(
      BLOCKS,
      learnerViewedBlocksIds,
      results,
      publication
    );

    const sum = (field) => sumBy(field)(learnersStat);
    const chartData = {
      passedCorrectly: sum(`${BLOCK_PASS_STATUS_PASSED_CORRECTLY}Count`),
      passedIncorrectly: sum(`${BLOCK_PASS_STATUS_PASSED_INCORRECTLY}Count`),
      notPassed: sum(`${BLOCK_PASS_STATUS_NOT_PASSED}Count`),
      inProcess: sum(`${BLOCK_PASS_STATUS_IN_PROCESS}Count`),
      totalCount: sum('totalCount'),
    };
    const allBlocksByType = helpers.result.getBlocksByType(BLOCKS, [
      publication,
    ]);
    const blocksByType = values(pick(blocksIds[0], allBlocksByType[0]));
    const usersData = (pageId) =>
      compose(
        map((result) => ({
          userName: result.userName,
          onlineStatus: get(['onlineStatus'], result),
          lastModified: get(['lastModified'], result),
        })),
        filter((result) => get(['activePage'], result) === pageId)
      )(results);
    return {
      isOpen,
      results,
      learners,
      lessonId,
      blocksIds,
      usersData,
      chartData,
      activePage,
      lessonName,
      blocksStats,
      learnersStat,
      publications,
      blocksByType,
      lessonPagesIds,
      selectedResultIndex,
      learnerViewedBlocksIds,
    };
  } else {
    return { withoutData: true };
  }
};

const mapDispatchToProps = {
  close: actions.results.close,
  selectPage: actions.results.selectPage,
  resetConfirm: actions.results.resetConfirm,
  selectResult: actions.results.selectResult,
  downloadResults: actions.results.download,
};

const contextEnhance = withContext({ inReview: bool.isRequired }, () => ({
  inReview: true,
}));

const enhance = lifecycle({
  componentDidMount() {
    const {
      downloadResults,
      lessonId,
      changeRequestInterval,
      publications,
      selectedPublication,
      isOlympiadMode,
      setLoader,
    } = this.props; // eslint-disable-line fp/no-this
    if (!isOlympiadMode && !isEmpty(publications)) {
      downloadResults(
        lessonId,
        selectedPublication || publications.length,
        setLoader
      );
      changeRequestInterval(
        setInterval(() => {
          downloadResults(
            lessonId,
            // eslint-disable-next-line fp/no-this
            this.props.selectedPublication || publications.length,
            setLoader
          );
        }, 5000)
      );
    }
  },
  componentWillUnmount() {
    // eslint-disable-next-line fp/no-this
    const { intervalValue, selectResult, isOlympiadMode } = this.props;
    if (!isOlympiadMode) {
      clearInterval(intervalValue);
      selectResult(undefined);
    }
  },
});

export default compose(
  withTranslation('containers'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('intervalValue', 'changeRequestInterval', undefined),
  withState('isLoaderPosible', 'setLoader', true),
  withState(
    'selectedPublication',
    'changeSelectedPublication',
    (props) => props.publications.length
  ),
  withState('statMode', 'showStat', false),
  withState('isMessengerVisible', 'showMessenger', false),
  withState('isHideMessengerHover', 'hoverShowMessengerHelper', false),
  contextEnhance,
  enhance
)(Results);
