import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import { pure } from 'recompose';
import { combineStyles } from '../utils/styles';
import '../styles/LinesTruncate.scss';

const LinesTruncate = ({ text, variant, maxLines, ellipsis }) => (
  <div className={combineStyles('LinesTruncate', variant)}>
    <LinesEllipsis
      text={text}
      maxLine={maxLines}
      ellipsis={ellipsis}
      trimRight
      basedOn="letters"
    />
  </div>
);

const { number, string, oneOfType, array } = PropTypes;

LinesTruncate.propTypes = {
  text: string, // Текст
  variant: oneOfType([array, string]), // Вариант оформления
  maxLines: number, // Максимальное количество строк
  ellipsis: string, // Чем обрезать текст
};

LinesTruncate.defaultProps = {
  text: undefined,
  variant: undefined,
  maxLines: undefined,
  ellipsis: '...',
};

export default pure(LinesTruncate);
