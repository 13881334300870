import React from 'react';
import PropTypes from 'prop-types';

import * as s from './styles/Emoji.styles';

// ! Редакторы иногда отображают unicode emoji символы как пустые строки
export const HUG = '🤗';
export const ALERT = '‼️';
export const MAN_SHRUGGING = '🤷‍♂️';
export const SAD = '🥺';
export const HAPPY = '🤓';
export const UNHAPPY = '🙁';
export const TASK = '📝';
export const BLOCKED = '🙅‍♂️';
export const STUDENT = '👨‍💻';
export const TUTOR = '👩‍💼';

const Emoji = ({ label, symbol, className }) => (
  <span
    className={className}
    css={s.icon}
    role="img"
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
);

const { string } = PropTypes;

Emoji.propTypes = {
  label: string,
  symbol: string.isRequired,
  className: string,
};

export default Emoji;
