/* eslint-disable more/no-window */

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { compose, pure } from 'recompose';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window && window.scrollTo && window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default compose(pure)(ScrollToTop);
