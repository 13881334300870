import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, isEmpty, times } from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import NoSSR from 'react-no-ssr';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { withHandlers, withState } from 'recompose';
import Plans from '../Plans';
import Icon from '~/components/Icon';
import {
  Link,
  Text,
  Button,
  CourseLessonThumbnail,
  Help,
  Drawer,
  Switch,
} from '~/components';
import { Templates } from '~/containers';
import Uploader from '~/containers/Uploader';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import CourseShare from '~/screens/Teach/Course/CourseShare';
import CourseStatuses from '~/screens/Teach/Course/CourseStatuses';
import { useQueryParameters } from '~/hooks/useQueryParameters';
import { usePaymentNotice } from '~/hooks/usePaymentNotice';
import {
  MODAL_COURSE_STATUS,
  MODAL_NAME_PLANS,
  LESSON_TYPE_REGULAR,
  REQUEST_NAME_COURSE_DOWNLOADING,
} from '~/appConstants';
import DotLoader from '~/components/DotLoader';
import CreateLessonButton from '~/containers/CreateLessonButton';

import '~/styles/Course.scss';
import { isRequestActive } from '~/selectors/ui';
import * as s from './styles/CourseIndex.styles';

const { func, string, array, bool, object } = PropTypes;

const SSRPanel = () => (
  <div css={s.panelLoader}>
    {times(
      (index) => (
        <div key={index}>
          <div />
          <div />
        </div>
      ),
      5
    )}
  </div>
);

const HeaderBg = ({ coverImageUrl }) => (
  <div
    className="background"
    style={
      coverImageUrl && {
        backgroundImage: `url("${coverImageUrl}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    }
  >
    {!coverImageUrl && <Icon name="osh-imageupload" />}
  </div>
);

HeaderBg.propTypes = {
  coverImageUrl: string,
};

const SwitchRow = ({ checked, onChange, text, helpText }) => (
  <div className="SwitchRow">
    <div className="text">
      <Text value={text} variant="settings-label" />
      {helpText && (
        <div className="icon">
          <Icon
            name="help"
            position="bottom"
            variant="small-gray"
            tooltip={helpText}
          />
        </div>
      )}
    </div>
    <Switch checked={checked} onChange={onChange} />
  </div>
);

SwitchRow.propTypes = {
  checked: bool,
  onChange: func.isRequired,
  text: string,
  helpText: string,
};

const Course = ({
  t,
  id,
  name,
  router,
  status,
  changeName,
  isDragOver,
  lessonsIds,
  publicMode,
  description,
  togglePublic,
  coverImageUrl,
  currentStatus,
  isSettingsOpened,
  changeLessonType,
  changeDescription,
  setIsSettingsOpened,
  lessonsPublications,
}) => {
  const [isShareOpen, setShareOpen] = useState(false);
  const [templatesIsOpen, showTemplates] = useState(false);
  const {
    parameters: { modal },
    removeQueryParam,
    addQueryParam,
  } = useQueryParameters();

  const { paymentNotice } = usePaymentNotice();

  useEffect(() => {
    paymentNotice();
  }, [paymentNotice]);

  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_COURSE_DOWNLOADING)
  );

  return (
    <div className="Course">
      <CourseShare
        id={id}
        close={() => setShareOpen(false)}
        isOpen={isShareOpen}
        publicMode={publicMode}
      />
      <Plans
        isOpen={modal === MODAL_NAME_PLANS}
        close={() => removeQueryParam('modal')}
        goBack={() => addQueryParam(`modal=${MODAL_COURSE_STATUS}`)}
      />
      <CourseStatuses
        id={id}
        close={() => removeQueryParam('modal')}
        isOpen={modal === MODAL_COURSE_STATUS}
        currentStatus={currentStatus}
        openPlans={() => addQueryParam(`modal=${MODAL_NAME_PLANS}`)}
      />
      {!isMobile && (
        <Templates
          isOpen={templatesIsOpen}
          router={router}
          folderId={null}
          courseId={id}
          showTemplates={showTemplates}
        />
      )}
      <div className="container">
        <div className="info-container">
          <HeaderBg coverImageUrl={coverImageUrl} />
          <div className="info">
            <div className="header">
              <div className="left">
                <Link
                  variant="course-back-teacher"
                  withIcon="arrow-left"
                  to="/teach/courses"
                />
                <NoSSR>
                  {!isLoading && (
                    <div className="status">
                      <Button
                        minimal
                        variant="course-status-select"
                        text={t(`course.status_${status}`)}
                        onClick={() =>
                          addQueryParam(`modal=${MODAL_COURSE_STATUS}`)
                        }
                        icon={`osh-course-${status}`}
                      />
                    </div>
                  )}
                </NoSSR>
              </div>
              <NoSSR>
                {!isLoading && (
                  <div className="uploader">
                    <Uploader
                      isNoticeProgress
                      variant="course-cover"
                      type="settings"
                      icon="media"
                      courseId={id}
                      filePath={[id, 'cover']}
                      title={t(
                        coverImageUrl ? 'course.changeCover' : 'course.addCover'
                      )}
                      fileType="image"
                      uploadType="course"
                    />
                  </div>
                )}
              </NoSSR>
            </div>
            <div className="center">
              {!isLoading && (
                <>
                  <Text
                    value={name}
                    variant="course-name"
                    confirmOnEnterKey
                    editable
                    onChange={changeName}
                    multiline
                    placeholder={t('course.enterCourseName')}
                  />
                  <Text
                    value={description}
                    variant="course-description"
                    editable
                    onChange={changeDescription}
                    multiline
                    placeholder={t('course.enterCourseDescription')}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="content">
          {!isMobile && (
            <div className="control">
              <CreateLessonButton
                isInCourse
                courseId={id}
                variant="plus"
                position="bottom"
                showTemplates={showTemplates}
                changeLessonType={changeLessonType}
              />
            </div>
          )}
          <NoSSR onSSR={<DotLoader css={s.loader} />}>
            {isLoading ? (
              <DotLoader css={s.loader} />
            ) : isEmpty(lessonsIds) ? (
              <div className="empty">
                <div className="icon">🏗</div>
                <div className="text">
                  <Text value={t('course.addLessonDescription')} />
                </div>
              </div>
            ) : (
              <div
                className="lessons-list"
                style={isDragOver ? { backgroundColor: 'red' } : {}}
              >
                {lessonsIds.map((lessonId, index) => (
                  <CourseLessonThumbnail
                    id={lessonId}
                    courseId={id} // ToDo Передается только для драгндропа, мб что-то сделать с этим
                    index={index}
                    key={lessonId}
                    isHintsEnabled
                    lessonsPublications={lessonsPublications}
                  />
                ))}
              </div>
            )}
          </NoSSR>
        </div>
      </div>
      <div className="panel">
        <NoSSR onSSR={<SSRPanel />}>
          <div className="section">
            <Button
              minimal
              icon="osh-share"
              text={t('course.share')}
              variant="lesson-controls"
              onClick={() => setShareOpen(true)}
            />
          </div>
          <div className="section">
            <Button
              minimal
              icon="person"
              text={t('course.students')}
              variant="lesson-controls"
              onClick={() => router.push(`/teach/course/${id}/students`)}
            />
          </div>
          <div className="section">
            <Button
              minimal
              icon="horizontal-bar-chart"
              text={t('course.monitoring')}
              variant="lesson-controls"
              onClick={() => router.push(`/teach/course/${id}/monitoring`)}
            />
          </div>
          <div className="section">
            <Button
              minimal
              icon="osh-teacher"
              text={t('course.tutors')}
              variant="lesson-controls"
              onClick={() => router.push(`/teach/course/${id}/tutors`)}
            />
          </div>
          <div className="section">
            <Button
              minimal
              icon="cog"
              text={t('course.settings')}
              variant="lesson-controls"
              onClick={() => setIsSettingsOpened(true)}
            />
          </div>
          <div className="section">
            <Help inPanel disabledTooltip variant="help-in-course-panel" />
          </div>
        </NoSSR>
      </div>
      <Drawer
        onClose={() => setIsSettingsOpened(false)}
        isOpen={isSettingsOpened}
        title={t('course.settingsTitle')}
        className="CourseSettings"
      >
        <SwitchRow
          checked={publicMode}
          onChange={togglePublic}
          text={t('course.publicMode')}
          helpText={t('course.publicModeHint')}
        />
      </Drawer>
    </div>
  );
};

Course.propTypes = {
  t: func.isRequired,
  id: string.isRequired,
  name: string,
  status: string,
  router: object.isRequired,
  changeName: func.isRequired,
  lessonsIds: array,
  isDragOver: bool,
  publicMode: bool.isRequired,
  description: string,
  togglePublic: func.isRequired,
  coverImageUrl: string,
  currentStatus: string,
  isSettingsOpened: bool.isRequired,
  changeLessonType: func.isRequired,
  changeDescription: func.isRequired,
  lessonsPublications: array,
  setIsSettingsOpened: func.isRequired,
};

Course.defaultProps = {
  lessonsIds: [],
  isDragOver: false,
  currentStatus: '',
  lessonsPublications: [],
};

const mapStateToProps = (state, ownProps) => {
  const { id = ownProps.match.params.id, history: router } = ownProps;
  const course = selectors.builder.getCourse(id, state) || {};

  const coverImageUrl = course.cover?.source;
  const { name, description, status, cover, publicMode = false } = course;
  const lessonsIds = selectors.builder.getCourseLessonsIds(id)(state);
  const lessonsPublications = selectors.builder.getCourseLessonsPublications(
    id
  )(state);
  const currentStatus = selectors.builder.getCourseStatus(id)(state);

  return {
    id,
    name,
    cover,
    router,
    status,
    publicMode,
    lessonsIds,
    description,
    currentStatus,
    coverImageUrl,
    lessonsPublications,
  };
};

const mapDispatchToProps = {
  changeName: actions.builder.course.changeName,
  changeDescription: actions.builder.course.changeDescription,
  togglePublic: actions.builder.course.togglePublic,
};

export default compose(
  withTranslation('containers'),
  withState('isSettingsOpened', 'setIsSettingsOpened', false),
  withState('lessonType', 'changeLessonType', LESSON_TYPE_REGULAR),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    changeName: ({ id, changeName }) => (newName) => changeName(id, newName),
    changeDescription: ({ id, changeDescription }) => (newDescription) =>
      changeDescription(id, newDescription),
    togglePublic: ({ id, togglePublic }) => () => togglePublic(id),
  })
)(Course);
