import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pure } from 'recompose';
import { isEmpty, map } from 'lodash/fp';

import Dialog from '~/components/Dialog';
import Button from '~/components/Button';
import DotLoader from '~/components/DotLoader';
import Icon from '~/components/Icon';
import NotificationItem from './NotificationItem';
import * as s from '../styles/Notification.styles';
import {
  downloadNotifications,
  markAsViewed,
} from '~/actions/study/notifications';
import { isRequestActive } from '~/selectors/ui';
import {
  REQUEST_NAME_DOWNLOAD_NOTIFICATIONS,
  REQUEST_NAME_SET_VIEWED_NOTIFICATIONS,
} from '~/appConstants';
import {
  getUnviewedNotifications,
  getViewedNotifications,
} from '~/selectors/notification';
import withMobileDetect from '~/hocs/withMobileDetect';

const Notification = ({ close, isOpen, isMobile }) => {
  const { t } = useTranslation('components');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadNotifications());
  }, [dispatch]);

  const isDownloading = useSelector(
    isRequestActive(REQUEST_NAME_DOWNLOAD_NOTIFICATIONS)
  );

  const isSettingViewed = useSelector(
    isRequestActive(REQUEST_NAME_SET_VIEWED_NOTIFICATIONS)
  );

  const unviewedNotifications = useSelector(getUnviewedNotifications);
  const viewedNotifications = useSelector(getViewedNotifications);

  return (
    <Dialog
      canEscapeKeyClose
      isOpen={isOpen}
      variant="notification"
      className="Notification"
    >
      <div css={s.close}>
        <Button
          minimal
          icon={isMobile ? 'arrow-left' : 'osh-remove'}
          variant="notification-close"
          onClick={() => close(false)}
        />
      </div>
      <div css={s.container}>
        {isDownloading ? (
          <div css={s.loaderContainer}>
            <DotLoader css={s.loader} />
          </div>
        ) : (
          <>
            <div css={s.header}>
              <div css={s.headerTitle}>{t('notification.title')}</div>
              {!isEmpty(unviewedNotifications) && (
                <div
                  css={s.headerMarkAll}
                  onClick={() => {
                    const ids = map('id', unviewedNotifications);
                    dispatch(markAsViewed({ ids }));
                  }}
                >
                  {isSettingViewed ? (
                    <div css={s.loaderContainerSmall}>
                      <DotLoader css={s.loader} size="small" />
                    </div>
                  ) : (
                    t('notification.markAllAsRead')
                  )}
                </div>
              )}
            </div>

            {!isEmpty(unviewedNotifications) && (
              <>
                <div css={s.typeTitle}>{t('notification.typeUnread')}</div>
                {map(
                  ({ id, data, created, isViewed, type }) => (
                    <div css={s.content} key={id}>
                      <NotificationItem
                        data={data}
                        date={created}
                        isUnread={!isViewed}
                        id={id}
                        type={type}
                      />
                    </div>
                  ),
                  unviewedNotifications
                )}
              </>
            )}

            {!isEmpty(viewedNotifications) && (
              <>
                <div css={s.typeTitle}>{t('notification.typeRead')}</div>
                {map(
                  ({ id, data, created, isViewed, type }) => (
                    <div css={s.content} key={id}>
                      <NotificationItem
                        data={data}
                        date={created}
                        isUnread={!isViewed}
                        id={id}
                        type={type}
                      />
                    </div>
                  ),
                  viewedNotifications
                )}
              </>
            )}

            {isEmpty(viewedNotifications) && isEmpty(unviewedNotifications) && (
              <div css={s.emptyContainer}>
                <div css={s.empty}>
                  <Icon name="notifications" variant="notification-bell" />
                  <div css={s.emptyLabel}>{t('notification.empty')}</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

const { bool, func } = PropTypes;

Notification.propTypes = {
  close: func,
  isOpen: bool, // Функция перевода
  isMobile: bool,
};

Notification.defaultProps = {
  isOpen: false,
};

export default withMobileDetect(pure(Notification));
