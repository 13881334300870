import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import objectId from 'bson-objectid';
import memoize from 'fast-memoize';
import { drop, join, compose, get, last, size, isEmpty } from 'lodash/fp';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { combineStyles } from '../../utils/styles';
import { Button, Froala, Icon, Text } from '../../components';
import FilePreview from '~/components/FilePreview';
import * as actions from '../../actions';
import '../../styles/Answer.scss';
import Uploader from '~/containers/Uploader';

import * as s from './styles/Answer.styles';
import {
  addNewReply,
  removeFileFromReply,
} from '~/actions/player/block/answer';
import { isRequestActive } from '~/selectors/ui';
import { REQUEST_NAME_ADD_NEW_REPLY } from '~/appConstants';

const dateFormat = 'DD.MM.YY HH:mm';

const renderFilePreview = memoize(({ item, remove, isCanRemove }) => {
  const { source } = item[1];
  const filenameWithHash = source
    // eslint-disable-next-line no-useless-escape
    .replace(/^.*[\\\/]/, '')
    .replace(/\.[^/.]+$/, '')
    .split('-');
  const filename = join('-', drop(1, filenameWithHash));
  const extension = source.match(/[^.]+$/)[0];

  return (
    <div key={item[0]}>
      <FilePreview
        name={filename}
        source={item[1].source}
        extension={extension}
        isCanRemove={isCanRemove}
        remove={remove}
      />
    </div>
  );
});

const ResultReply = ({
  t,
  theme,
  reply,
  blockId,
  inPlayer,
  fontSize,
  question,
  inSafeMode,
  description,
}) => {
  const [newAnswer, setNewAnswer] = useState('');
  const dispatch = useDispatch();
  const resultId = useSelector(get(['player', 'result', 'id']));
  const userName = useSelector(get(['player', 'result', 'userName']));
  const lastIndex = reply ? size(reply) - 1 : 0;
  const imagesReply = get([lastIndex, 'newStudentFiles'], reply);
  const isSubmitting = useSelector(isRequestActive(REQUEST_NAME_ADD_NEW_REPLY));

  const isLastApproved = compose(
    get('isApproved'),
    last
  )(reply);

  return (
    <div className="Answer">
      {reply && (
        <div css={s.label} isApproved={isLastApproved}>
          {isLastApproved === true
            ? t('answer.approved')
            : isLastApproved === false
            ? t('answer.rejected')
            : t('answer.pending')}
        </div>
      )}
      <div className="question">{question}</div>
      <div className="description">
        <Froala mode="preview" content={description} />
      </div>
      {reply ? (
        reply.map(
          (
            {
              studentAnswer,
              studentAnswerDate,
              studentFiles,
              teacherComment,
              teacherFiles,
              teacherName,
              isApproved,
              teacherCommentDate,
            },
            i
          ) => (
            <Fragment key={i}>
              <div css={s.answerContainer}>
                <div css={s.studentAnswer}>
                  <div css={s.taskAnswerUserBlock}>
                    <div css={s.taskAnswerUser}>{userName}</div>
                    <div css={s.taskAnswerUserDate}>
                      {studentAnswerDate &&
                        format(studentAnswerDate, dateFormat)}
                    </div>
                  </div>
                  <Froala
                    css={s.replyFroala}
                    variant={
                      inSafeMode
                        ? ['answer-text', fontSize, theme]
                        : 'answer-text'
                    }
                    mode="preview"
                    content={studentAnswer || t('answer.noAnswer')}
                  />
                  {!isEmpty(studentFiles) && (
                    <div css={s.answerImages}>
                      {Object.entries(studentFiles).map((item) =>
                        renderFilePreview({ item })
                      )}
                    </div>
                  )}
                </div>
                <div css={s.avatar} />
              </div>
              {teacherComment && (
                <div css={s.answerContainer}>
                  <div css={s.avatar} />
                  <div css={s.teacherAnswer} isApproved={isApproved}>
                    <div css={s.taskAnswerUserBlock}>
                      <div css={s.taskAnswerUser}>{teacherName}</div>
                      <div css={s.taskAnswerUserDate}>
                        {teacherCommentDate &&
                          format(teacherCommentDate, dateFormat)}
                      </div>
                    </div>
                    <Froala
                      css={s.replyFroala}
                      mode="preview"
                      variant="answer-text"
                      content={teacherComment}
                    />
                    {!isEmpty(teacherFiles) && (
                      <div css={s.answerImages}>
                        {Object.entries(teacherFiles).map((item) =>
                          renderFilePreview({ item })
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          )
        )
      ) : (
        <div css={s.answerContainer}>
          <div css={s.studentAnswer}>
            <div css={s.studentNoAnswer}>{t('answer.noAnswer')}</div>
          </div>
        </div>
      )}
      {isLastApproved === false && inPlayer && (
        <>
          <div className="reply">
            <Froala
              variant={
                inSafeMode ? ['answer-text', fontSize, theme] : 'answer-text'
              }
              pastePlain
              mode="editor"
              theme="simple"
              toolbarButtons={[]}
              content={newAnswer}
              onChange={setNewAnswer}
              placeholder={t('answer.changeAnswer')}
            />
          </div>
          <div
            className={combineStyles('images', inSafeMode && [fontSize, theme])}
          >
            {imagesReply &&
              Object.entries(imagesReply).map((item) =>
                renderFilePreview({
                  item,
                  remove: () =>
                    dispatch(
                      removeFileFromReply({ id: item[0], blockId, reply })
                    ),
                  isCanRemove: true,
                })
              )}
            <div
              className={combineStyles(
                'uploader',
                inSafeMode && [fontSize, theme]
              )}
            >
              <Uploader
                isNoticeProgress
                uploadType="block"
                variant={
                  inSafeMode
                    ? ['answer-uploader', fontSize, theme]
                    : 'answer-uploader'
                }
                type="answer"
                icon="paperclip"
                blockId={blockId}
                filePath={[
                  blockId,
                  'reply',
                  lastIndex,
                  'newStudentFiles',
                  objectId.generate(),
                ]}
                title={t('answer.attachFile')}
                fileType="any"
                inPlayer
              />
              <div className="tooltip">
                <Icon
                  usePortal
                  tooltip={
                    <div>
                      <div>{t('answer.attachFileTooltipLine1')}</div>
                      <div>{t('answer.attachFileTooltipLine2')}</div>
                      <div>{t('answer.attachFileTooltipLine3')}</div>
                    </div>
                  }
                  name="help"
                  position="right"
                  variant="sidebar-tooltip"
                />
              </div>
            </div>
          </div>
          <Button
            icon="osh-done"
            text={t('answer.sendToVerification')}
            variant="block-verify-quizes"
            onClick={() => {
              dispatch(
                addNewReply({
                  reply: {
                    studentAnswer: newAnswer,
                    studentFiles: imagesReply,
                    studentAnswerDate: Date.now(),
                  },
                  resultId,
                  blockId,
                })
              );
            }}
            loading={isSubmitting}
            disabled={!newAnswer && !isSubmitting}
          />
        </>
      )}
    </div>
  );
};

const { bool, string, func, array } = PropTypes;

ResultReply.propTypes = {
  t: func.isRequired,
  theme: string.isRequired,
  reply: array.isRequired,
  blockId: string.isRequired,
  inPlayer: bool.isRequired,
  fontSize: string.isRequired,
  question: string.isRequired,
  inSafeMode: bool.isRequired,
  description: string,
};

const Answer = ({
  t,
  reply,
  theme,
  blockId,
  fontSize,
  question,
  inReview,
  inPlayer,
  inBuilder,
  inSafeMode,
  description,
  changeReply,
  removeImage,
  inLibraryEdit,
  inLibraryShare,
  inPresentation,
  changeQuestion,
  inLibrarySearch,
  isPageCompleted,
  isLessonComplete,
  changeDescription,
}) => {
  const lastIndex = reply ? size(reply) - 1 : 0;
  const studentAnswer = get([lastIndex, 'studentAnswer'], reply);
  const imagesReply = get([lastIndex, 'studentFiles'], reply);

  return (inPlayer || inPresentation) && !isLessonComplete ? (
    <div className="Answer">
      <div
        className={combineStyles('question', inSafeMode && [fontSize, theme])}
      >
        {question}
      </div>
      <div
        className={combineStyles(
          'description',
          inSafeMode && [fontSize, theme]
        )}
      >
        <Froala mode="preview" content={description} />
      </div>
      <div className="reply">
        <Froala
          variant={
            inSafeMode ? ['answer-text', fontSize, theme] : 'answer-text'
          }
          pastePlain
          theme="simple"
          toolbarButtons={[]}
          mode={isPageCompleted ? 'preview' : 'editor'}
          content={studentAnswer}
          onChange={changeReply}
          placeholder={t('answer.changeAnswer')}
        />
      </div>
      {inPlayer && (
        <div
          className={combineStyles('images', inSafeMode && [fontSize, theme])}
        >
          {imagesReply &&
            Object.entries(imagesReply).map((item) =>
              renderFilePreview({
                item,
                remove: () => removeImage(item[0], blockId),
                isCanRemove: true,
              })
            )}
          <div
            className={combineStyles(
              'uploader',
              inSafeMode && [fontSize, theme]
            )}
          >
            <Uploader
              isNoticeProgress
              uploadType="block"
              variant={
                inSafeMode
                  ? ['answer-uploader', fontSize, theme]
                  : 'answer-uploader'
              }
              type="answer"
              icon="paperclip"
              blockId={blockId}
              filePath={[
                blockId,
                'reply',
                lastIndex,
                'studentFiles',
                objectId.generate(),
              ]}
              title={t('answer.attachFile')}
              fileType="any"
              inPlayer
            />
            <div className="tooltip">
              <Icon
                usePortal
                tooltip={
                  <div>
                    <div>{t('answer.attachFileTooltipLine1')}</div>
                    <div>{t('answer.attachFileTooltipLine2')}</div>
                    <div>{t('answer.attachFileTooltipLine3')}</div>
                  </div>
                }
                name="help"
                position="right"
                variant="sidebar-tooltip"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : inReview || isLessonComplete ? (
    <ResultReply
      reply={reply}
      question={question}
      description={description}
      t={t}
      theme={theme}
      blockId={blockId}
      inPlayer={inPlayer}
      fontSize={fontSize}
      inSafeMode={inSafeMode}
      imagesReply={imagesReply}
      removeImage={removeImage}
      isLessonComplete={isLessonComplete}
    />
  ) : inLibrarySearch ? (
    <div className="Answer">
      <div className="question">{question}</div>
      <div className="description">
        <Froala mode="preview" content={description} />
      </div>
      <div className="reply">
        <Text value={t('answer.answersField')} variant="answer-text" />
      </div>
    </div>
  ) : inBuilder || inLibraryEdit || inLibraryShare ? ( // В конструкторе или окне редактирования блока из библиотеки
    <div className="Answer">
      <div className="question">
        <Text
          value={question}
          variant="answer-question"
          editable
          multiline
          onChange={changeQuestion}
          placeholder={t('answer.changeQuestion')}
        />
      </div>
      <div className="description">
        <Froala
          mode="editor"
          toolbarButtons={[
            'bold',
            'align',
            'quote',
            'italic',
            'indent',
            'outdent',
            'formatUL',
            'formatOL',
            'underline',
            'insertLink',
            'insertTable',
            'insertImage',
            'strikeThrough',
            'paragraphFormat',
          ]}
          content={description}
          onChange={changeDescription}
        />
      </div>
      <div className="reply">
        <Text value={t('answer.answersField')} variant="answer-text" />
      </div>
      <div className="image-reply">
        <Icon name="paperclip" />
        <Text value={t('answer.attachFileHint')} variant="image-answer" />
      </div>
    </div>
  ) : (
    undefined
  );
};

Answer.propTypes = {
  t: func.isRequired, // Функция перевода
  reply: array, // Ответ
  theme: string, // Цветовая тема в режиме ОВЗ
  blockId: string.isRequired, // ID блока
  question: string, // Вопрос
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inPlayer: bool, // В плеере?
  inReview: bool, // В окне поиска по библиотеке?
  inBuilder: bool, // В конструкторе?
  inSafeMode: bool, // В режиме ОВЗ?
  removeImage: func.isRequired, // Очистка картинки
  description: string, // Описание
  changeReply: func.isRequired, // Изменение ответа
  inLibraryEdit: bool, // В окне редактирования библиотеки?
  inLibraryShare: bool, // В окне шаринга библиотеки?
  inPresentation: bool, // В режиме презентации?
  changeQuestion: func.isRequired, // Изменение вопроса
  isPageCompleted: bool, // Задания на странице пройдены?
  inLibrarySearch: bool, // В окне поиска по библиотеке?
  isLessonComplete: bool, // Урок завершен?
  changeDescription: func.isRequired, // Изменение описания
};

Answer.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  isLessonComplete: false,
};

const mapDispatchToProps = {
  changeReply: actions.player.block.answer.changeReply,
  removeImage: actions.player.block.answer.removeImage,
  changeQuestion: actions.builder.block.answer.changeQuestion,
  changeDescription: actions.builder.block.answer.changeDescription,
};

const handlersEnhance = withHandlers({
  changeReply: (props) => (newReply) =>
    props.changeReply(props.blockId, newReply),
  changeQuestion: (props) => (newQuestion) =>
    props.changeQuestion(props.blockId, newQuestion),
  changeDescription: (props) => (newDescription) =>
    props.changeDescription(props.blockId, newDescription),
});

export default compose(
  withTranslation('containersBlock'),
  connect(
    null,
    mapDispatchToProps
  ),
  handlersEnhance
)(Answer);
