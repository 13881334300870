import styled, { css } from 'styled-components';

export const anchorContainer = css`
  padding-top: 24px;
`;

export const Card = styled.div`
  position: relative;
  width: 717px;
  padding: 32px 32px 26px 24px;
  background: #ffffff;
`;

export const controls = css`
  position: absolute;
  right: -26px;
  top: 26px;

  .bp3-popover-target:not(.bp3-popover-open) {
    opacity: 0;
  }

  ${Card}:hover & .bp3-popover-target {
    opacity: 1;
  }
`;

export const buttonGroup = css`
  display: flex;

  > *:not(:first-child) > .bp3-button {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: solid 0.7px #d0d3d7;
    border-left-width: 0;
    box-shadow: none;
  }
  > *:not(:last-child) > .bp3-button {
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-left-width: 0;
    border: solid 0.7px #d0d3d7;
    border-top-right-radius: 0;
    box-shadow: none;
  }
`;

export const top = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 39px;
`;

export const linksText = css`
  display: inline-block;
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  color: #aeb3bd;
  text-transform: uppercase;
  margin-right: 12px;
`;

export const links = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: ${(p) => (p.visible ? 1 : 0)};
`;

export const link = css`
  font-family: CirceRounded;
  font-size: 14px;
  text-align: right;
  color: #767b84;

  a {
    color: #767b84;
  }

  :not(:last-child)::after {
    content: ', ';
  }
`;

export const topLeft = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const addButton = css`
  min-height: 96px;
  margin-bottom: 10px;
  display: flex;

  > .bp3-button {
    width: 326px;
    border-radius: 3px;
    border: solid 1px #d0d3d7;
    outline: none;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      border: none;
      background-color: #f4f6f7;
      background-image: none;
      box-shadow: none;
      outline: none;
    }

    &::before {
      color: #aeb3bd;
    }

    > .text {
      font-family: CirceRounded;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.32px;
      color: #aeb3bd;
    }
  }
`;

export const number = css`
  margin-right: 18px;
  width: 37px;
  height: 37px;
  border-radius: 100px;
  border: solid 1px #d0d3d7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const answers = css`
  margin-bottom: -10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${'' /* width: calc(326px + 326px + 10px); */}
`;

export const question = css`
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  margin-bottom: 17px;
  color: #45494e;

  > .bp3-editable-text {
    width: 100%;
    margin: 0;

    &::before {
      display: none !important;
    }

    > .bp3-editable-text-input,
    > .bp3-editable-text-content {
      border: none !important;
      outline: none !important;
      padding: 0 !important;
      background: none !important;
      box-shadow: none !important;
    }
  }
`;

export const information = css`
  font-family: CirceRounded;
  font-size: 18px;
  color: #45494e;
  margin-bottom: 32px;
  min-height: 49px;
`;

export const infoBottom = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const next = css`
  width: 171px;
  margin-right: 24px;
`;

export const nextText = css`
  margin-top: 8px;
  font-family: CirceRounded;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0.27px;
  color: #9aa1ac;
  text-transform: uppercase;
`;

export const image = css`
  display: block;
  margin: 0 -32px 20px -24px;

  > img {
    display: block;
    width: 100%;
  }
`;
