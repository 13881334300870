// TODO refactor

import React from 'react';
import PropTypes from 'prop-types';
import { compose, isEqual, isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import { pure, withProps, withHandlers } from 'recompose';
import { withTranslation, useTranslation } from 'react-i18next';
import { combineStyles } from '../../utils/styles';

import {
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import { Froala, Text } from '~/components';
import { BlockComment } from '~/components/Block';
import * as actions from '~/actions';
import '../../styles/Block/FillBlank.scss';
import VerifyBlock from '~/containers/VerifyBlock';

const FillBlank = ({
  theme,
  title,
  blockId,
  comment,
  question,
  inReview,
  inPlayer,
  fontSize,
  isCorrect,
  inSafeMode,
  inEditMode,
  changeTitle,
  inPreviewMode,
  inPresentation,
  changeQuestion,
  isOlympiadMode,
  changeUserAnswer,
  isLessonComplete,
  isPageInExamMode,
  parseQuestionToHtml,
  isBlockInVerifyMode,
}) => {
  const { t } = useTranslation('containersBlock');

  return (
    <div className="FillBlank">
      {inPreviewMode || inReview || isLessonComplete ? (
        <>
          <div
            className={combineStyles('title', inSafeMode && [fontSize, theme])}
          >
            {title}
          </div>
          <div
            className="text"
            onBlur={inPreviewMode && changeUserAnswer}
            onClick={(e) => e.preventDefault()}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: parseQuestionToHtml(question) }}
          />
        </>
      ) : inEditMode ? (
        <>
          <div className="title">
            <Text
              value={title}
              variant="fillblank-question"
              editable
              multiline
              onChange={changeTitle}
              placeholder={t('fillblank.changeTitle')}
            />
          </div>
          <div className="question">
            <Froala
              initOnRender
              onChange={changeQuestion}
              content={question}
              variant="fillblank"
              mode="editor"
              theme="fillblank"
              pastePlain
              toolbarButtons={['fillblankButton']}
              pluginsEnabled={['fillblank']}
            />
            {!isPageInExamMode && (
              <BlockComment blockId={blockId} comments={comment} />
            )}
          </div>
        </>
      ) : (
        undefined
      )}
      {(inPlayer || inPresentation) && (
        <>
          {isOlympiadMode
            ? isLessonComplete &&
              !isEmpty(comment) && (
                <BlockComment
                  inPlayer
                  blockId={blockId}
                  comments={comment}
                  blockPassStatus={
                    isCorrect
                      ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                      : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
                  }
                />
              )
            : !isPageInExamMode &&
              (isBlockInVerifyMode || isLessonComplete) &&
              !isEmpty(comment) && (
                <BlockComment
                  inPlayer
                  blockId={blockId}
                  comments={comment}
                  blockPassStatus={
                    isCorrect
                      ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                      : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
                  }
                />
              )}
          {isOlympiadMode || isPageInExamMode || isLessonComplete ? (
            undefined
          ) : (
            <VerifyBlock
              blockId={blockId}
              isBlockInVerifyMode={isBlockInVerifyMode}
            />
          )}
        </>
      )}
    </div>
  );
};

const { string, bool, func, object } = PropTypes;

FillBlank.propTypes = {
  title: string,
  blockId: string.isRequired, // ID блока
  comment: object, // Комментарии к блоку
  question: string, // Текст вопроса
  inPlayer: bool, // В плеере?
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inSafeMode: bool, // В режиме ОВЗ?
  inReview: bool, // В режиме просмотре результатов
  isCorrect: bool.isRequired, // Задание выполнено верно?
  inEditMode: bool, // В режиме редактирования?
  changeTitle: func,
  inPreviewMode: bool, // В режиме просмотра/выполнения?
  inPresentation: bool, // В режиме презентации?
  isOlympiadMode: bool, // В режиме олимпиады?
  isPageInExamMode: bool, // Страница является контрольной?
  isLessonComplete: bool, // Урок завершен?
  changeQuestion: func.isRequired, // Сохранение вопроса
  changeUserAnswer: func.isRequired, // Функция заполнения ответов
  isBlockInVerifyMode: bool, // Блок в режиме проверки?
  parseQuestionToHtml: func.isRequired, // Перевод строки вопроса в HTML код
};

FillBlank.defaultProps = {
  theme: 'light',
  comment: {},
  fontSize: 'small',
  isLessonComplete: false,
};

const mapDispatchToProps = {
  changeTitle: actions.builder.block.fillblank.changeTitle,
  changeQuestion: actions.builder.block.fillblank.changeQuestion,
  changeUserAnswer: actions.player.block.fillblank.changeUserAnswer,
};

const enhance = compose(
  connect(
    undefined,
    mapDispatchToProps
  ),
  withTranslation(),
  withProps(
    ({
      inReview,
      inPlayer,
      inBuilder,
      correctAnswers,
      userAnswer,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inReview,
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
      isCorrect: isEqual(correctAnswers, userAnswer),
    })
  ),
  withHandlers({
    verify: (props) => () => props.verify(props.blockId),
    reset: (props) => () => props.reset(props.blockId),
    changeTitle: ({ changeTitle, blockId }) => (title) =>
      changeTitle(blockId, title),
    changeQuestion: (props) => (value) => {
      const newCorrectAnswers = {};
      // Regexp пропусков, которые имеют id
      const regexp = /<span[^>]*id="(.+?)".*?>(.*?)<\/span>/g;

      // Перебираем все вхождения пропусков в текст вопроса
      // Записываем id: value ответов
      /* eslint-disable fp/no-mutation */
      const getBlankData = (matchElement) => {
        if (matchElement) {
          newCorrectAnswers[matchElement[1]] = matchElement[2]
            .replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&#039;/g, "'")
            .replace(/&#39;/g, "'")
            .replace(/#39;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/&plusmn;/g, '±')
            .replace(/&sect;/g, '§')
            .trim();
          getBlankData(regexp.exec(value));
        }
      };
      getBlankData(regexp.exec(value));

      props.changeQuestion(props.blockId, value, newCorrectAnswers);
    },
    changeUserAnswer: (props) => (event) => {
      const question = event.currentTarget.childNodes;

      // Ищем все элементы с id внутри вопроса и записываем id: value ответов пользователя
      return [...question]
        .filter((childElement) => childElement.innerHTML.match(/id="(.*?)"/gi))
        .map((childElement) =>
          childElement.innerHTML.match(/id="(.*?)"/gi).map((item) => {
            const answerId = item.split('"')[1];
            return props.changeUserAnswer(
              props.blockId,
              answerId,
              document.getElementById(answerId).textContent.trim()
            );
          })
        );
    },
    parseQuestionToHtml: ({
      i18n,
      inReview,
      userAnswer,
      inPreviewMode,
      isOlympiadMode,
      correctAnswers,
      isPageCompleted,
      isLessonComplete,
      isPageInVerifyMode,
      isBlockInVerifyMode,
    }) => (question) =>
      question
        ? question.replace(
            /<span[^>]*id="(.+?)".*?>.*?<\/span>/g,
            (match, id) =>
              inReview || inPreviewMode
                ? // В режиме прохождения:
                  // Добавляем введенные пользователем ответы
                  // Добавляем классы оформления и подсветки верных/неверных ответов
                  // В style прописана формула определения ширины поля для ввода ответа (количество символов * 11)
                  `<span
          id="${id}"
          contenteditable="${
            isLessonComplete
              ? false
              : !(
                  inReview ||
                  isPageInVerifyMode ||
                  isBlockInVerifyMode ||
                  isPageCompleted
                )
          }"
          style="width: ${correctAnswers[id].length * 11 || 0}px"
          class="blank lang_${i18n.languages[0]} ${
                    userAnswer
                      ? (inReview ||
                          isPageInVerifyMode ||
                          isBlockInVerifyMode ||
                          isLessonComplete) &&
                        !isOlympiadMode
                        ? isEqual(
                            correctAnswers[id].toLowerCase(),
                            userAnswer[id].toLowerCase()
                          )
                        : ''
                      : ''
                  }">
            ${userAnswer ? (userAnswer[id] ? userAnswer[id] : '') : ''}
          </span>`
                : undefined
          )
        : '',
  }),
  pure
);

export default enhance(FillBlank);
