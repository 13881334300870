import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, isEmpty, last } from 'lodash/fp';
import { withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import * as selectors from '../selectors';
import * as actions from '../actions';
import { combineStyles } from '../utils/styles';
import '../styles/Share.scss';
import Dialog from '~/components/Dialog';
import { DraggedButton as Button } from '~/components/Button';
import Text from '~/components/Text';
import Input from '~/components/Input';
import Icon from '~/components/Icon';

// eslint-disable-next-line more/no-window
const host = `${window.location.host}${process.env.BASENAME}`;

/* eslint-disable more/no-duplicated-chains */

const Share = ({
  t,
  close,
  isOpen,
  variant,
  isMobile,
  lessonId,
  lessonCode,
  lessonName,
  showNotice,
  sendMetrika,
  externalLink,
  isUserOffline,
  publishLesson,
  isLessonUpdated,
  lessonPublications,
  lessonPublicationsIds,
  courseId,
}) => (
  <Dialog
    isOpen={isOpen}
    onClose={close}
    closable
    canEscapeKeyClose
    variant={combineStyles('share', variant)}
  >
    <div
      className={combineStyles('Share', isMobile && 'mobile')}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="close">
        <Button
          minimal
          icon="osh-remove"
          onClick={close}
          variant="profile-close"
        />
      </div>
      <div className="title">
        <Text
          value={t('share.sendLessonTitle')}
          variant="share-learner-title"
        />
        {lessonName && (
          <Text value={`"${lessonName}"`} variant="share-learner-name" />
        )}
      </div>
      {(isLessonUpdated || isEmpty(lessonPublications) || isUserOffline) && (
        <div className="public">
          <div className="republish-notice">
            {isUserOffline && (
              <div className="republish-lesson-notice">
                <div className="icon">
                  <Icon name="info-sign" variant="republish-lesson" />
                </div>
                <div className="text">
                  <Text
                    value={t('share.noConnection')}
                    variant="republish-lesson"
                  />
                </div>
              </div>
            )}
            {isLessonUpdated && !isEmpty(lessonPublications) && (
              <div className="republish-lesson-notice">
                <div className="icon">
                  <Icon name="info-sign" variant="republish-lesson" />
                </div>
                <div className="text">
                  <Text
                    value={t('share.notSavedChanges')}
                    variant="republish-lesson"
                  />
                </div>
              </div>
            )}
          </div>
          {isEmpty(lessonPublications) && (
            <div className="description">
              <Text
                value={t('share.publishLesson')}
                variant="republish-lesson"
              />
            </div>
          )}

          <div className="publish">
            <Button
              text={
                !isEmpty(lessonPublications)
                  ? t('share.republish')
                  : t('share.publish')
              }
              variant={
                isUserOffline
                  ? ['publish-lesson', 'disabled']
                  : 'publish-lesson'
              }
              onClick={(event) => {
                event.stopPropagation();
                publishLesson();
              }}
              disabled={isUserOffline}
            />
          </div>
        </div>
      )}
      {!isEmpty(lessonPublications) && (
        <div className="types">
          <div
            className={combineStyles('learner', [
              !isEmpty(courseId) && 'single',
            ])}
          >
            <div className="title">
              <Text
                value={t('share.sendLesson')}
                variant="share-learner-title"
              />
            </div>
            <div className="image" />
            {!isEmpty(lessonPublications) && (
              <div className="sources">
                <div className="code">
                  <div className="info">
                    <div className="text">
                      <Text value={t('share.byCode')} variant="mini-gray" />
                    </div>
                    <div className="icon">
                      <Icon
                        name="help"
                        variant="small-gray"
                        tooltip={t('share.byCodeHint')}
                      />
                    </div>
                  </div>
                  <div className="symbols">
                    <Text value={lessonCode} variant="freaky-dark" />
                  </div>
                </div>
                <div className="link">
                  <div className="info">
                    <Text value={t('share.link')} variant="mini-gray" />
                  </div>
                  <div className="input">
                    <Input
                      large
                      value={`${externalLink ||
                        // eslint-disable-next-line more/no-window
                        `${window.location.protocol}//${host}`}player/lesson/${lessonId}`}
                      onCopy={() => {
                        showNotice(t('share.linkCopied'), 'success');
                        sendMetrika('SHARE_LINK', 'schoolboy');
                      }}
                      copyable
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {isEmpty(courseId) && (
            <div className="teacher">
              <Text
                value={t('share.shareTeachers')}
                variant="share-teacher-title"
              />
              <Text
                value={t('share.shareTeachersHint')}
                variant="share-teacher-text"
              />
              <Input
                large
                value={`${externalLink ||
                  // eslint-disable-next-line more/no-window
                  `${window.location.protocol}//${host}`}preview/lesson/${last(
                  lessonPublicationsIds
                )}`}
                onCopy={() => {
                  showNotice(t('share.linkCopied'), 'success');
                  sendMetrika('SHARE_LINK', 'teacher');
                }}
                copyable
              />
            </div>
          )}
        </div>
      )}
    </div>
  </Dialog>
);

const { bool, func, string, number, arrayOf, oneOfType, array } = PropTypes;

Share.propTypes = {
  t: func, // Функция перевода
  close: func.isRequired, // Закрыть окно результатов
  isOpen: bool, // Окно результатов открыто?
  isMobile: bool.isRequired,
  variant: oneOfType([array, string]), // Вариант оформления
  lessonId: string.isRequired, // ID урока
  lessonName: string, // Название урока
  showNotice: func.isRequired, // Показать всплывающее сообщение
  lessonCode: string, // Уникальный код урока
  sendMetrika: func, // Отсылаем достижение цели метрики
  externalLink: string, // Ссылка на внешний плеео
  isUserOffline: bool, // Пользователь в оффлайне?
  publishLesson: func.isRequired, // Опубликовать урок
  isLessonUpdated: bool, // Урок изменен?
  lessonPublications: arrayOf(number), // Список публикаций урока
  lessonPublicationsIds: arrayOf(string),
  courseId: string,
};

Share.defaultProps = {
  t: false,
  isOpen: false,
  isUserOffline: false,
  isLessonUpdated: false,
};

const mapStateToProps = (state, { lessonId }) => ({
  lessonCode: selectors.builder.lesson.getCode(lessonId, state),
  externalLink: selectors.builder.lesson.getExternalLink(lessonId, state),
  isUserOffline: selectors.builder.isOffline(state),
  isLessonUpdated: selectors.builder.lesson.hasUnpublishedChanges(lessonId)(
    state
  ),
  lessonPublications: selectors.builder.lesson.getPublications(lessonId, state),
  lessonPublicationsIds: selectors.builder.lesson.getPublicationsIds(
    lessonId,
    state
  ),
  courseId: selectors.builder.lesson.getCourseId(lessonId, state),
});

const mapDispatchToProps = {
  showNotice: actions.notice.show,
  sendMetrika: actions.metrika.send,
  publishLesson: actions.builder.lesson.publish,
};

const enhance = withHandlers({
  publishLesson: (props) => () => props.publishLesson(props.lessonId),
});

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Share);
