import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import { withTranslation } from 'react-i18next';
import { compose, isEmpty, union, keys, indexOf } from 'lodash/fp';
import {
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { Icon, Popover } from '../components';
import DotLoader from '~/components/DotLoader';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/Presentation.scss';
import Block from '~/containers/Block';

/* eslint-disable fp/no-class, react/prop-types, fp/no-this */

class Presentation extends React.Component {
  componentWillMount() {
    window.addEventListener(
      'keydown',
      // eslint-disable-next-line unicorn/prefer-event-key
      ({ keyCode }) => {
        if (
          (keyCode === 38 || keyCode === 39) &&
          this.props.isNextBlockExists
        ) {
          this.forwardButton.click();
        }
        if (
          (keyCode === 37 || keyCode === 40) &&
          this.props.isPreviousBlockExists
        ) {
          this.backButton.click();
        }
      },
      true
    );
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', () => {});
  }

  render() {
    const {
      t,
      lessonId,
      blocksIds,
      goToBlock,
      blockScale,
      blockOpacity,
      goToNextBlock,
      currentBlockId,
      goToPreviousBlock,
      isNextBlockExists,
      viewedBlocksIndexes,
      isPreviousBlockExists,
    } = this.props;
    return isEmpty(blocksIds) ? (
      <div className="Presentation">
        <div className="empty">
          <DotLoader />
        </div>
      </div>
    ) : (
      <div className={combineStyles('Presentation')}>
        <div className="blocks">
          {blocksIds.map((blockId) => (
            <div
              id={blockId}
              key={blockId}
              style={{
                display: currentBlockId === blockId ? 'block' : 'none',
                opacity: blockOpacity,
                transform:
                  currentBlockId === blockId && `scale(${blockScale || 1}) `,
              }}
              className="block"
            >
              <Block id={blockId} lessonId={lessonId} />
            </div>
          ))}
        </div>
        <div className="container">
          <div className="controls">
            <div
              ref={(button) => {
                this.backButton = button;
              }}
              onClick={isPreviousBlockExists ? goToPreviousBlock : undefined}
              className={combineStyles(
                'go-to-previous-page',
                !isPreviousBlockExists && 'disabled'
              )}
            >
              <Icon name="osh-arrow" variant="in-presentation-prev" />
            </div>
            <div
              ref={(button) => {
                this.forwardButton = button;
              }}
              onClick={isNextBlockExists ? goToNextBlock : undefined}
              className={combineStyles(
                'go-to-next-page',
                !isNextBlockExists && 'disabled'
              )}
            >
              <Icon name="osh-arrow" variant="in-presentation-next" />
            </div>
            <div
              // eslint-disable-next-line more/no-window
              onClick={window.close}
              className="close-presentation"
            >
              {t('presentation.close')}
            </div>
          </div>
        </div>
        <div className="progress">
          {blocksIds.map((_, blockIndex) => (
            <Popover
              key={blockIndex}
              target={
                <div
                  key={blockIndex}
                  onClick={() => goToBlock(blockIndex)}
                  className={combineStyles('bar', [
                    viewedBlocksIndexes.includes(blockIndex) && 'filled',
                    indexOf(currentBlockId, blocksIds) === blockIndex && 'big',
                  ])}
                />
              }
              content={
                <div className="preview">
                  <div className="scale">
                    <div className="block">
                      <Block
                        id={blocksIds[blockIndex]}
                        isViewed={viewedBlocksIndexes.includes(blockIndex)}
                        lessonId={lessonId}
                        inPreview
                        inThumbnail
                      />
                    </div>
                  </div>
                </div>
              }
              variant="presentation-preview"
              position="top"
              interaction="hover"
            />
          ))}
        </div>
      </div>
    );
  }
}

/* eslint-enable */

const { bool, func, array, string, number } = PropTypes;

Presentation.propTypes = {
  t: func.isRequired, // Функция перевода
  goToBlock: func.isRequired, // Перейти на страницу
  blocksIds: array.isRequired, // Список ID всех страниц
  blockScale: number.isRequired, // Масштаб страницы
  blockOpacity: number.isRequired,
  goToNextBlock: func.isRequired, // Перейти на следующую страницу
  currentBlockId: string, // ID текущей страницы
  isNextBlockExists: bool.isRequired, // Есть ли следующая страница?
  goToPreviousBlock: func.isRequired, // Перейти на предыдущую страницу
  viewedBlocksIndexes: array.isRequired, // Индексы просмотренных страниц
  isPreviousBlockExists: bool.isRequired, // Есть ли предыдущая страница?
};

const mapStateToProps = (state, ownProps) => {
  const { blockIndex, blockScale, viewedBlocksIndexes, lessonId } = ownProps;
  const allBlocks = selectors.presentation.getBlocks(state);
  const blocksIds = keys(allBlocks);
  const blocksCount = blocksIds.length;
  const currentBlockId = blocksIds[blockIndex];
  const isNextBlockExists = blockIndex < blocksCount - 1;
  const isPreviousBlockExists = blockIndex > 0;
  const currentBlockTalkScreen = selectors.presentation.getTalkScreenId(
    currentBlockId,
    state
  );

  return {
    lessonId,
    blocksIds,
    blockScale,
    currentBlockId,
    isNextBlockExists,
    viewedBlocksIndexes,
    isPreviousBlockExists,
    currentBlockTalkScreen,
  };
};

const mapDispatchToProps = {
  startPresentation: actions.presentation.startPresentation,
};

const getBlockSize = (blockIndex, rootComponent) =>
  // eslint-disable-next-line react/no-find-dom-node
  findDOMNode(rootComponent).children[0].children[
    blockIndex
  ].getBoundingClientRect();

export default compose(
  withTranslation('containers'),
  withState('component', 'setComponent', null),
  withState('blockScale', 'changeBlockScale', 1),
  withState('blockOpacity', 'changeBlockOpacity', 1),
  withState('blockIndex', 'changeBlockIndex', 0),
  withState('viewedBlocksIndexes', 'addBlockToViewed', [0]),
  withProps(({ match }) => ({
    lessonId: match.params.lessonId,
  })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    calculateBlockScale: (props) => (blockIndex = 0) => {
      const { changeBlockScale, component, changeBlockOpacity } = props;
      if (component) {
        const { width: blockWidth, height: blockHeight } = getBlockSize(
          blockIndex,
          component
        );
        // eslint-disable-next-line more/no-window
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        const scale = Math.min(
          windowWidth / blockWidth,
          windowHeight / blockHeight
        );
        if (scale !== 1) {
          changeBlockScale(scale);
        }
      }
      changeBlockOpacity(1);
    },
  }),
  withHandlers({
    goToNextBlock: (props) => () => {
      const newBlockIndex = props.blockIndex + 1;
      props.changeBlockIndex(newBlockIndex);
      props.addBlockToViewed(union(props.viewedBlocksIndexes, [newBlockIndex]));
      props.calculateBlockScale(newBlockIndex);
    },
    goToBlock: (props) => (blockIndex) => {
      props.changeBlockIndex(blockIndex);
      props.addBlockToViewed(union(props.viewedBlocksIndexes, [blockIndex]));
      props.calculateBlockScale(blockIndex);
    },
    goToPreviousBlock: (props) => () => {
      const newBlockIndex = props.blockIndex - 1;
      props.changeBlockIndex(newBlockIndex);
      props.addBlockToViewed(union(props.viewedBlocksIndexes, [newBlockIndex]));
      props.calculateBlockScale(newBlockIndex);
    },
  }),
  withContext(
    {
      inPresentation: bool.isRequired,
      updateBlockInPresentation: func.isRequired,
    },
    (props) => ({
      inPresentation: true,
      updateBlockInPresentation: () => {
        setTimeout(() => {
          props.calculateBlockScale();
        }, 100);
      },
    })
  ),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      const {
        lessonId,
        blockIndex,
        setComponent,
        startPresentation,
        changeBlockOpacity,
        calculateBlockScale,
      } = this.props;

      setComponent(this);
      startPresentation({ lessonId });

      changeBlockOpacity(0);
      setTimeout(() => {
        calculateBlockScale(blockIndex);
      }, 1000);
    },
    componentDidUpdate(nextProps) {
      const {
        blockIndex,
        changeBlockOpacity,
        calculateBlockScale,
        currentBlockTalkScreen,
      } = this.props;

      if (
        blockIndex !== nextProps.blockIndex ||
        currentBlockTalkScreen !== nextProps.currentBlockTalkScreen
      ) {
        changeBlockOpacity(0);
        setTimeout(() => {
          calculateBlockScale(blockIndex);
        }, 100);
      }
    },
    /* eslint-enable fp/no-this */
  })
)(Presentation);
