import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../styles/SocialShare.scss';
import Hyperlink from '~/components/Hyperlink';
import Text from '~/components/Text';
import Icon from '~/components/Icon';

const socialList = [
  {
    icon: 'osh-vk',
    url: 'http://vk.com/share.php?url=',
  },
  {
    icon: 'osh-fb',
    url: 'http://www.facebook.com/sharer.php?u=',
  },
  // {
  //   icon: 'osh-twitter',
  //   url: 'http://www.twitter.com/share?url=',
  // },
];

const SocialShare = ({ t, noLabel, shareURL, previewMode }) => (
  <div className="SocialShare">
    {!noLabel && <Text className="label" value={t('socialshare.share')} />}

    {socialList.map((social) =>
      previewMode ? (
        <Icon name={social.icon} variant="helprow-social" />
      ) : (
        <Hyperlink
          newTab
          icon={social.icon}
          href={`${social.url}${shareURL}`}
          variant="helprow-social"
        />
      )
    )}
  </div>
);

const { string, bool, func } = PropTypes;

SocialShare.propTypes = {
  t: func.isRequired, // Функция перевода
  noLabel: bool, // Показывать только иконки
  shareURL: string, // Ссылка для шаринга
  previewMode: bool,
};

SocialShare.defaultProps = {
  noLabel: false,
};

export default pure(withTranslation('components')(SocialShare));
