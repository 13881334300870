import * as auth from './auth';
// import * as metrika from './metrika';
import * as library from './library';
import * as builder from './builder';
import * as context from './context';

export default [
  context.getPageContext,
  context.getBlockContext,
  auth.setLoggedIn,
  // metrika.send,
  builder.quiz.add,
  builder.exam.add,
  builder.match.add,
  builder.block.clone,
  builder.images.add,
  builder.page.clone,
  builder.page.remove,
  builder.page.paste,
  builder.lesson.add,
  builder.lesson.clone,
  builder.folder.add,
  builder.course.add,
  builder.lesson.remove,
  builder.lesson.detectChanges,
  builder.lesson.checkPublicationInvalidate,
  library.cloneBlock,
];
