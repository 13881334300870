import { put, take, call } from 'redux-saga/effects';
import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import request from '../../request';

const api = `${process.env.API_URL}/folder/delete`;

/**
 * Удаление папки с сервера
 */
export default function* remove() {
  while (true) {
    const {
      payload: { id: folderId },
    } = yield take(BUILDER.FOLDER.REMOVE_ACCEPT);
    // Данные для запроса
    const data = {
      id: folderId,
    };
    const response = yield call(request, api, data);
    if (response) {
      if (!response.error) {
        yield put(actions.builder.folder.removeSuccess(folderId));
      } else {
        yield put(actions.builder.folder.removeError(response.msg));
      }
    }
  }
}
