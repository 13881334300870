import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../utils/styles';
import { Text, Icon } from '~/components';
import '../styles/Uploader.scss';

const UploaderLoader = ({ t, icon, status, variant, progress }) => (
  <div className={combineStyles('Uploader', [variant, 'uploading'])}>
    <div className="icon">
      <Icon
        name={icon}
        size={75}
        variant="uploading"
        style={
          status === 'uploading'
            ? {
                backgroundImage: `linear-gradient(
              to right,
              #f2c800 0%,
              #f2c800 ${progress}%,
              #eee ${progress}%,
              #eee 100%
            )`,
              }
            : {}
        }
      />
    </div>
    <div className="title">
      <div className="text">
        <Text
          value={`${t('uploader.upload')} ${progress} %`}
          variant={['uploader-uploading-title', variant]}
        />
      </div>
    </div>
  </div>
);

const { array, string, func, number, oneOf, oneOfType } = PropTypes;

UploaderLoader.propTypes = {
  t: func.isRequired, // Функция перевода
  icon: string, // Иконка
  status: oneOf(['success', 'error', 'uploading']), // Статус загрузки
  variant: oneOfType([array, string]), // Вариант оформления
  progress: number, // Прогресс загрузки
};

UploaderLoader.defaultProps = {
  icon: undefined,
  status: undefined,
  variant: undefined,
  progress: undefined,
};

export default withTranslation('containers')(UploaderLoader);
