import {
  set,
  omit,
  update,
  concat,
  uniqWith,
  pullAt,
  isEqual,
  compose,
} from 'lodash/fp';
import * as LIBRARY from '../../constants/library';
import { toggle } from '../../utils/array';

export const defaultState = {
  tags: {
    filter: '', // Поле для поиска тегов в библиотеке
    finded: [], // Найденные по условиям тэги
    selected: [], // Выбранные для фильтрации блоков тэги
    isLoading: false, // Идет поиск тегов по библиотеке?
  },
  blocks: {
    finded: {}, // Найденные по условиям блоки
    isLoading: false, // Идет загрузка блоков?
  },
  types: [], // Типы блоков для поиска
  isOpen: false, // Окно поиска открыто?
  subjects: [], // Предметы уроков блока для поиска
  isHidden: false, // Окно скрыто?
  inEditMode: false, // В режиме редактирования блока?
};

const searchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LIBRARY.SEARCH.OPEN: {
      return set('isOpen', true, state);
    }

    case LIBRARY.SEARCH.HIDE: {
      return set('isHidden', true, state);
    }

    case LIBRARY.SEARCH.SHOW: {
      return set('isHidden', false, state);
    }

    case LIBRARY.EDIT.OPEN: {
      return set('inEditMode', true, state);
    }

    case LIBRARY.EDIT.CLOSE:
    case LIBRARY.EDIT.REMOVE_SUCCESS: {
      return compose(
        set('isOpen', true),
        set('inEditMode', false)
      )(state);
    }

    case LIBRARY.SEARCH.CLOSE: {
      return compose(
        set('isOpen', false),
        set('isHidden', false)
      )(state);
    }

    case LIBRARY.SEARCH.TOGGLE_TYPE: {
      const { type } = action.payload;
      return compose(
        update(['types'], toggle(type)),
        set(['blocks', 'isLoading'], true)
      )(state);
    }

    case LIBRARY.SEARCH.TOGGLE_SUBJECT: {
      const { subject } = action.payload;
      return compose(
        update(['subjects'], toggle(subject)),
        set(['blocks', 'isLoading'], true)
      )(state);
    }

    case LIBRARY.SEARCH.IMPORT_TAGS: {
      const { tags } = action.payload;
      return compose(
        set(['tags', 'finded'], tags),
        set(['tags', 'isLoading'], false)
      )(state);
    }

    case LIBRARY.SEARCH.SELECT_TAG: {
      const tag = omit(['value', 'onRemove'], action.payload.tag);
      return compose(
        update(['tags', 'selected'], (tags) =>
          uniqWith(isEqual, concat(tag, tags))
        ),
        set(['tags', 'filter'], ''),
        set(['blocks', 'isLoading'], true)
      )(state);
    }

    case LIBRARY.SEARCH.REMOVE_TAG: {
      const { tagIndex } = action.payload;
      return update(['tags', 'selected'], pullAt(tagIndex), state);
    }

    case LIBRARY.SEARCH.IMPORT_BLOCKS: {
      const { blocks } = action.payload;
      return compose(
        set(['blocks', 'finded'], blocks),
        set(['blocks', 'isLoading'], false)
      )(state);
    }

    case LIBRARY.SEARCH.CHANGE_TAGS_FILTER: {
      const { newTagsFilter } = action.payload;
      return compose(
        set(['tags', 'filter'], newTagsFilter),
        set(['tags', 'isLoading'], newTagsFilter.length > 3)
      )(state);
    }

    default:
      return state;
  }
};

export default searchReducer;
