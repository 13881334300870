import { compose, set, update, unset } from 'lodash/fp';

import {
  REMOVE_FILE,
  CLEAR_FILES_TO_SEND,
  DOWNLOAD_ANSWER_BLOCK_SUCCESS,
  DOWNLOAD_ANSWER_BLOCKS_SUCCESS,
  SEND_TEACHER_COMMENT_SUCCESS,
  UPDATE_ANSWER_BLOCK_STATUS_SUCCESS,
} from '~/actions/review';
import { getLastIndex } from '~/utils/array';
import * as UPLOADER from '~/constants/uploader';
import { UPLOAD_TYPE_ANSWER } from '~/appConstants';
import uploaderReducer from '~/reducers/uploader';

const defaultState = {
  answerBlocks: {},
  answerBlockResult: undefined,
};

const reviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_ANSWER_BLOCKS_SUCCESS: {
      const { answerBlocks } = action.payload;

      return set('answerBlocks', answerBlocks, state);
    }

    case DOWNLOAD_ANSWER_BLOCK_SUCCESS: {
      const {
        date,
        reply,
        blockId,
        question,
        lessonName,
        courseName,
        isApproved,
        description,
        studentName,
        teacherName,
        studentEmail,
        teacherComment,
        isAlreadyInReview,
        teacherCommentDate,
        isTutor,
        courseId,
        publicationId,
        pageId,
      } = action.payload;

      return set(
        'answerBlockResult',
        {
          date,
          reply,
          blockId,
          question,
          lessonName,
          courseName,
          isApproved,
          description,
          studentName,
          teacherName,
          studentEmail,
          teacherComment,
          isAlreadyInReview,
          teacherCommentDate,
          isTutor,
          courseId,
          publicationId,
          pageId,
        },
        state
      );
    }

    case SEND_TEACHER_COMMENT_SUCCESS: {
      const {
        teacherComment,
        teacherFiles,
        isApproved,
        teacherName,
      } = action.payload;

      return update(
        ['answerBlockResult', 'reply'],
        (oldReply) => {
          const lastIndex = getLastIndex(oldReply);
          return compose(
            set([lastIndex, 'teacherComment'], teacherComment),
            set([lastIndex, 'teacherFiles'], teacherFiles),
            set([lastIndex, 'isApproved'], isApproved),
            set([lastIndex, 'teacherName'], teacherName)
          )(oldReply);
        },
        state
      );
    }

    case REMOVE_FILE: {
      const { id } = action.payload;

      return unset(['filesToSend', id])(state);
    }

    case CLEAR_FILES_TO_SEND: {
      return unset('filesToSend')(state);
    }

    case UPLOADER.UPLOAD_SUCCESS: {
      const { filePath, uploadType, source } = action.payload;

      if (uploadType !== UPLOAD_TYPE_ANSWER) {
        return state;
      }

      return compose(
        set([...filePath, 'source'], source),
        update(filePath, (fileState) => uploaderReducer(fileState, action))
      )(state);
    }

    case String(action.type.match(/@uploader\/.*/)): {
      const { filePath, uploadType } = action.payload;

      if (uploadType !== UPLOAD_TYPE_ANSWER) return state;

      return update(
        filePath,
        (fileState) => uploaderReducer(fileState, action),
        state
      );
    }

    case UPDATE_ANSWER_BLOCK_STATUS_SUCCESS: {
      const { isApproved, answerBlockResultId } = action.payload;

      return set(
        ['answerBlocks', answerBlockResultId, 'isApproved'],
        isApproved,
        state
      );
    }

    default:
      return state;
  }
};

export default reviewReducer;
