import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { map, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import ReviewLine from './ReviewLine';
import * as s from './styles/ReviewAnswerBlockList.styles';
import * as rs from './styles/Review.styles';
import Emoji, { TASK } from '~/components/Emoji';

const ReviewAnswerBlockList = ({
  answerBlocks,
  currentAnswerBlockResultId,
  setCurrentAnswerBlockResultId,
}) => {
  const { t } = useTranslation('screens');

  return isEmpty(answerBlocks) ? (
    <div css={rs.noTasks}>
      <div css={rs.noTasksEmoji}>
        <Emoji symbol={TASK} label="task" />
      </div>
      <div css={rs.noTasksText}>{t('review.noAnswers')}</div>
    </div>
  ) : (
    map(
      (answerBlock) => (
        <div
          css={s.lineContainer}
          key={answerBlock.id}
          onClick={() => setCurrentAnswerBlockResultId(answerBlock.id)}
        >
          <ReviewLine
            answerBlock={answerBlock}
            selected={currentAnswerBlockResultId === answerBlock.id}
          />
        </div>
      ),
      answerBlocks
    )
  );
};

const { any, func, array } = PropTypes;

ReviewAnswerBlockList.propTypes = {
  answerBlocks: array,
  currentAnswerBlockResultId: any,
  setCurrentAnswerBlockResultId: func,
};

export default pure(ReviewAnswerBlockList);
