import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pure, lifecycle, compose, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash/fp';

import { Button, Popover, Input, Switch, Text, Icon } from '../../components';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as helpers from '../../helpers';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '../../appConstants';
import QuizContent from '~/containers/QuizContent';

const Weight = ({
  type,
  theme,
  answers,
  blockId,
  comment,
  comments,
  question,
  inPlayer,
  inReview,
  fontSize,
  blockType,
  inBuilder,
  inSafeMode,
  description,
  inLibraryEdit,
  isOlympiadMode,
  inLibraryShare,
  inPresentation,
  inLibrarySearch,
  isPageCompleted,
  answersIdsOrder,
  blockPassStatus,
  isLessonComplete,
  isShuffleAnswers,
  isNotRegularBlock,
  correctAnswersIds,
  isPageInExamMode,
  isPageInVerifyMode,
  selectedAnswersIds,
  quizShuffledAnswers,
  // Для весовых вопросов
  group,
  level,
  weights,
  competence,
}) => (
  <QuizContent
    type={type}
    level={level}
    theme={theme}
    blockId={blockId}
    blockType={blockType}
    answers={answers}
    comments={comments}
    question={question}
    inPlayer={inPlayer}
    inBuilder={inBuilder}
    fontSize={fontSize}
    inReview={inReview}
    answersIds={
      (inPlayer || inPresentation || inReview) && isShuffleAnswers
        ? quizShuffledAnswers
        : answersIdsOrder
    }
    inSafeMode={inSafeMode}
    description={description}
    blockComments={comment}
    inLibraryEdit={inLibraryEdit}
    isOlympiadMode={isOlympiadMode}
    noBlockComment={isPageInExamMode}
    inLibraryShare={inLibraryShare}
    inPresentation={inPresentation}
    blockPassStatus={blockPassStatus}
    inLibrarySearch={inLibrarySearch}
    isPageCompleted={isPageCompleted}
    isLessonComplete={isLessonComplete}
    isShuffleAnswers={isShuffleAnswers}
    isNotRegularBlock={isNotRegularBlock}
    correctAnswersIds={correctAnswersIds}
    isPageInVerifyMode={isPageInVerifyMode}
    selectedAnswersIds={selectedAnswersIds}
    // Для весовых вопросов
    group={group}
    weights={weights}
    competence={competence}
  />
);

const { oneOf, arrayOf, string, bool, object, any, number } = PropTypes;

Weight.propTypes = {
  type: oneOf([QUIZ_BLOCK_TYPE_RADIO, QUIZ_BLOCK_TYPE_CHECKBOX]).isRequired, // Тип теста
  theme: string, // Цветовая тема в режиме ОВЗ
  blockId: string.isRequired, // ID блока
  blockType: string.isRequired, // Тип блока
  fontSize: string, // Размер шрифта в режиме ОВЗ
  answers: object.isRequired, // Ответы
  comment: object, // Комментарии к блоку
  comments: any, // Комментарии к ответам
  question: string, // Вопрос
  inPlayer: bool, // В плеере?
  inReview: bool, // В окне результатов?
  inBuilder: bool, // В конструкторе?
  inSafeMode: bool, // В режиме ОВЗ?
  description: string, // Описание
  inLibraryEdit: bool, // В окне редактирования библиотеки?
  inLibraryShare: bool, // В окне шаринга библиотеки?
  inPresentation: bool, // В режиме презентации?
  isOlympiadMode: bool, // В режиме олимпиады?
  inLibrarySearch: bool, // В окне поиска блоков?
  blockPassStatus: string, // Статус блока - пройден/не пройден
  answersIdsOrder: arrayOf(string.isRequired).isRequired, // Порядок ответов
  isPageCompleted: bool, // Задания на странице пройдены?
  isPageInExamMode: bool, // Страница является контрольной?
  isLessonComplete: bool, // Блок в режиме просмотра?
  isNotRegularBlock: bool, // Блок не находится на контрольной странице или в олимпиаде?
  isShuffleAnswers: bool, // Перемешать массив ответов
  correctAnswersIds: arrayOf(string.isRequired).isRequired, // Правильные ответы
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  selectedAnswersIds: arrayOf(string.isRequired), // Выбранные ответы
  quizShuffledAnswers: arrayOf(string.isRequired), // Перемешанные ответы для плеера
  // Дял весовых вопросов
  group: string, // Группа вопроса
  level: number, // Уровень вопроса
  weights: object, // Веса ответов
  competence: string, // Компетенция вопроса
};

Weight.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  comment: {},
  comments: {},
  inPlayer: undefined,
  question: undefined,
  inReview: undefined,
  inBuilder: undefined,
  inSafeMode: undefined,
  description: undefined,
  inLibraryEdit: undefined,
  inPresentation: undefined,
  inLibraryShare: undefined,
  isOlympiadMode: undefined,
  inLibrarySearch: undefined,
  isPageCompleted: undefined,
  blockPassStatus: undefined,
  isLessonComplete: false,
  isShuffleAnswers: false,
  isPageInExamMode: undefined,
  isNotRegularBlock: undefined,
  isPageInVerifyMode: undefined,
  selectedAnswersIds: [],
  quizShuffledAnswers: [],
};

const mapStateToProps = (state, ownProps) => {
  const {
    blockId,
    inReview,
    inPresentation,
    shuffledAnswers,
    answersIdsOrder,
    isShuffleAnswers,
    correctAnswersIds,
    selectedAnswersIds,
  } = ownProps;

  const blockStatus = {
    passedCorrectly: BLOCK_PASS_STATUS_PASSED_CORRECTLY,
    passedIncorrectly: BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
  };
  const quizShuffledAnswers = inReview
    ? shuffledAnswers
    : inPresentation
    ? selectors.presentation.getQuizShuffledAnswers(blockId, state)
    : selectors.player.getQuizShuffledAnswers(blockId, state);

  const displayedAnswersQuantity =
    selectors.builder.quiz.getDisplayedAnswersQuantity(blockId, state) ||
    selectors.player.displayedAnswersQuantity(blockId, state) ||
    selectors.presentation.displayedAnswersQuantity(blockId, state) ||
    answersIdsOrder.length;
  const blockPassStatus = helpers.block.indetifyStatus(
    selectedAnswersIds,
    correctAnswersIds,
    blockStatus
  );

  return {
    inReview,
    answersIdsOrder,
    displayedAnswersQuantity,
    isShuffleAnswers,
    blockPassStatus,
    quizShuffledAnswers,
  };
};

const mapDispatchToProps = {
  toggleShuffle: actions.builder.block.quiz.toggleShuffle,
  saveShuffleAnswers: actions.player.block.quiz.saveShuffleAnswers,
  changeAnswersQuantity: actions.builder.block.quiz.changeAnswersQuantity,
};

const additionalControls = (properties) => {
  const {
    t,
    toggleShuffle,
    answersIdsOrder,
    displayedAnswersQuantity,
    isShuffleAnswers,
    correctAnswersIds,
    changeAnswersQuantity,
  } = properties;
  return (
    <div className="buttons">
      <Popover
        icon="random"
        position="bottom"
        variant="shuffle-answers"
        target={<Button icon="random" variant="block-controls-toggler" />}
        content={
          <>
            <div className="switch">
              <div className="icon">
                <Icon
                  name="help"
                  variant="small-gray"
                  tooltip={t('quiz.randomHint')}
                />
              </div>
              <Text value={t('quiz.randomEnable')} variant="shuffle-answers" />
              <Switch
                label={
                  isShuffleAnswers
                    ? t('quiz.randomEnabled')
                    : t('quiz.randomDisabled')
                }
                checked={isShuffleAnswers}
                onChange={toggleShuffle}
                variant="shuffle-answers"
              />
            </div>
            {isShuffleAnswers && (
              <div className="quantity">
                <div className="icon">
                  <Icon
                    name="help"
                    variant="small-gray"
                    tooltip={t('quiz.randomQuantityHint')}
                  />
                </div>
                <Text
                  value={t('quiz.randomQuantity')}
                  variant="shuffle-answers"
                />
                <Input
                  type="numeric"
                  value={toString(
                    displayedAnswersQuantity || answersIdsOrder.length
                  )}
                  minValue={correctAnswersIds.length}
                  maxValue={answersIdsOrder.length}
                  variant="quiz-quantity"
                  onChange={changeAnswersQuantity}
                />
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

const enhance = compose(
  withHandlers({
    toggleShuffle: ({ toggleShuffle, answersIdsOrder, blockId }) => () =>
      toggleShuffle(answersIdsOrder, blockId),
    changeAnswersQuantity: ({ blockId, changeAnswersQuantity }) => (quantity) =>
      changeAnswersQuantity({ quantity: toNumber(quantity), blockId }),
  }),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      this.props.setControls(additionalControls(this.props));

      if (!this.props.quizShuffledAnswers) {
        this.props.saveShuffleAnswers(
          this.props.blockId,
          helpers.block.shuffleQuizAnswers(
            this.props.displayedAnswersQuantity,
            this.props.answersIdsOrder,
            this.props.correctAnswersIds
          )
        );
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isShuffleAnswers !== this.props.isShuffleAnswers ||
        prevProps.displayedAnswersQuantity !==
          this.props.displayedAnswersQuantity
      ) {
        this.props.setControls(additionalControls(this.props));
      }
    },
    /* eslint-enable */
  })
);

export default compose(
  pure,
  withTranslation('containersBlock'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Weight);
