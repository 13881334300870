import React from 'react';
import PropTypes from 'prop-types';
import { curry, compose } from 'lodash/fp';
import { pure, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Text } from '..';
import * as helpers from '../../helpers';
import '../../styles/Block/Embed.scss';
import Uploader from '~/containers/Uploader';

const extractDomain = (url) =>
  (url.includes('//') ? url.split('/')[2] : url.split('/')[0])
    .split(':')[0]
    .split('www.')[0]
    .toLowerCase();

const handleChange = curry((onChange, value) => {
  const whiteList = ['learningapps.org', '3dvieweronline.com'];
  if (whiteList.includes(extractDomain(value))) {
    // Вытаскиваем домен из ссылки
    const domain = value
      .replace('http://', '')
      .replace('https://', '')
      .replace('<iframe src="', '')
      .split(/[/?#]/)[0];
    switch (domain) {
      case 'learningapps.org': {
        // Проверяем корректность ссылки learningapps
        const isCorrect = /\/view/i.test(value);
        // Вытаскиваем код упражнения
        const exerciseCode = value.match(/\d+/)[0];
        onChange({
          source: isCorrect
            ? helpers.lesson.clearURL(value)
            : `//learningapps.org/view${exerciseCode}`,
        });
        break;
      }

      default: {
        onChange({ source: helpers.lesson.clearURL(value) });
      }
    }
  }
});

const Embed = ({ t, source, blockId, onChange, inEditMode, inPreviewMode }) => (
  <div className="Embed">
    {source ? (
      <div className="content">
        <iframe
          src={source}
          title="Embed"
          width="750px"
          height="500px"
          scrolling="no"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    ) : inEditMode ? (
      <div className="uploader">
        <Uploader
          uploadType="block"
          title={t('embed.loadLesson')}
          blockId={blockId}
          filePath={[blockId, 'content']}
          onURLChange={handleChange(onChange)}
          onlyWithLink
          embedText={t('embed.lessonLink')}
        />
      </div>
    ) : inPreviewMode ? (
      <div className="empty">
        <div className="text">
          <Text value={t('embed.lessonNotLoaded')} variant="empty-block-text" />
        </div>
      </div>
    ) : (
      undefined
    )}
  </div>
);

const { string, bool, func } = PropTypes;

Embed.propTypes = {
  t: func.isRequired, // Функция перевода
  source: string, // URL
  blockId: string.isRequired, // ID блока
  onChange: func.isRequired, // При изменении блока
  inEditMode: bool, // В режиме редактирования?
  inPreviewMode: bool, // В режиме просмотра/выполнения?
};

Embed.defaultProps = {
  source: undefined,
  inEditMode: undefined,
  inPreviewMode: undefined,
};

const enhance = compose(
  pure,
  withTranslation('componentsBlock'),
  withProps(
    ({
      inPlayer,
      inBuilder,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
    })
  )
);

export default enhance(Embed);
