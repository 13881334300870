/* eslint-disable no-continue */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as RESULTS from '../constants/results';
import * as actions from '../actions';
import request from './request';

const api = {
  downloadPublicOlympiadResult: (resultId) =>
    `${process.env.API_URL}/public-olympiad-result/${resultId}`,
};

export function* downloadPublicOlympiadResult() {
  yield takeLatest(RESULTS.DOWNLOAD_PUBLIC_OLYMPIAD_RESULT, function*(action) {
    const { resultId } = action.payload;

    const response = yield call(
      request,
      api.downloadPublicOlympiadResult(resultId),
      {},
      'get'
    );
    if (response) {
      const {
        data: { error, payload },
      } = response;
      if (error) {
        yield put(actions.results.downloadPublicOlympiadResultError(error));
      } else {
        yield put(actions.results.downloadPublicOlympiadResultSuccess(payload));
      }
    }
  });
}
