import { css } from 'styled-components';

export const container = css`
  padding: 30px;
`;

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 717px;
  height: 294px;
  border-radius: 3px;
  border: solid 1px #e5e9ec;
  cursor: pointer;
`;

export const icon = css`
  margin-bottom: 30px;

  > .bp3-icon:before {
    font-size: 66px;
    color: #eeeeee;
  }
`;

export const actionText = css`
  width: 589px;
  margin-bottom: 24px;
  font-family: CirceRounded;
  font-size: 18px;
  text-align: center;
  color: #45494e;
`;

export const dialog = css`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
`;

export const header = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #45494e;
  width: 100%;
`;

export const headerBackLink = css`
  margin-left: 16px;
  margin-right: 140px;
`;

export const headerCounter = css`
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 46px;
  border-radius: 4px;
  border: solid 1px #d0d3d7;
  background-color: #fff;
  font-family: CirceRounded;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #45494e;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const helpIcon = css`
  margin-right: auto;
  margin-left: 8px;
`;

export const headerText = css`
  height: 34px;
  font-family: CirceRounded;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c5c5c5;
`;

export const body = css`
  width: 100%;
  padding-bottom: calc(90vh - 60px);
  padding-top: 8px;
  height: calc(100vh - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const addCard = css`
  margin-top: 24px;
`;

export const name = css`
  margin-bottom: 8px;
`;

export const description = css`
  margin-bottom: 16px;
`;
