import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Popover.scss';

const positions = {
  top: Blueprint.Position.TOP,
  topLeft: Blueprint.Position.TOP_LEFT,
  topRight: Blueprint.Position.TOP_RIGHT,
  left: Blueprint.Position.LEFT,
  leftTop: Blueprint.Position.LEFT_TOP,
  leftBottom: Blueprint.Position.LEFT_BOTTOM,
  right: Blueprint.Position.RIGHT,
  rightTop: Blueprint.Position.RIGHT_TOP,
  rightBottom: Blueprint.Position.RIGHT_BOTTOM,
  bottom: Blueprint.Position.BOTTOM,
  bottomLeft: Blueprint.Position.BOTTOM_LEFT,
  bottomRight: Blueprint.Position.BOTTOM_RIGHT,
};

const interactions = {
  click: Blueprint.PopoverInteractionKind.CLICK,
  hover: Blueprint.PopoverInteractionKind.HOVER,
  targetOnly: Blueprint.PopoverInteractionKind.CLICK_TARGET_ONLY,
};

const Popover = ({
  target,
  variant,
  content,
  position,
  disabled,
  usePortal,
  interaction,
  popoverWillOpen,
  popoverDidOpen,
  popoverWillClose,
  popoverDidClose,
  captureDismiss,
}) => (
  <Blueprint.Popover
    autoFocus={false}
    usePortal={usePortal}
    target={target}
    content={content}
    position={positions[position]}
    canEscapeKeyClose
    disabled={disabled}
    interactionKind={interactions[interaction]}
    popoverClassName={combineStyles('Popover', variant)}
    useSmartArrowPositioning
    onOpening={popoverWillOpen}
    onOpened={popoverDidOpen}
    onClosing={popoverWillClose}
    onClosed={popoverDidClose}
    captureDismiss={captureDismiss}
  />
);

const {
  string,
  bool,
  oneOfType,
  array,
  arrayOf,
  oneOf,
  element,
  func,
} = PropTypes;

Popover.propTypes = {
  target: element.isRequired, // Элемент, при нажатии на который открывается поповер
  variant: oneOfType([array, string]), // Вариант оформления
  content: oneOfType([arrayOf(element), element]).isRequired, // Внутренний контент
  position: string, // Позиционирование поповера
  disabled: bool, // Поповер отключен?
  usePortal: bool, // Рендеринг поповера в одном блоке с кнопкой?
  captureDismiss: bool,
  interaction: oneOf(['click', 'hover']), // Вид взаимодействия
  popoverWillOpen: func,
  popoverDidOpen: func,
  popoverWillClose: func,
  popoverDidClose: func,
};

Popover.defaultProps = {
  variant: undefined,
  position: 'bottom',
  disabled: false,
  usePortal: false,
  captureDismiss: false,
  interaction: 'click',
  popoverWillOpen: undefined,
  popoverDidOpen: undefined,
  popoverWillClose: undefined,
  popoverDidClose: undefined,
};

export default pure(Popover);
