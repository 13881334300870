/* eslint-disable generator-star-spacing */

import { put, takeLatest } from 'redux-saga/effects';
import { compose, head, mapValues, values } from 'lodash/fp';

import {
  downloadLessonSuccess,
  START_PRESENTATION,
} from '~/actions/presentation';
import { createEmptyResultData } from '~/sagas/player/lesson/execution';
import request from '~/sagas/request';
import { setKeysAsIds } from '~/sagas/player/lesson/download';
import * as actions from '~/actions';

const api = (lessonId) => `${process.env.API_URL}/lesson/${lessonId}`;

export function* watchStartPresentation() {
  yield takeLatest(START_PRESENTATION, function*(action) {
    const {
      payload: { lessonId },
    } = action;

    const response = yield request(api(lessonId), null, 'get', {
      showNotFoundOn404: true,
    });

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      return;
    }

    const {
      lesson: { content },
    } = payload;

    const contentWithKeys = mapValues(setKeysAsIds, content);
    const { lesson, pages, blocks } = contentWithKeys;

    const newResultData = yield createEmptyResultData();

    yield put(
      actions.presentation.setNewResult({
        result: {
          lessonId,
          ...newResultData,
        },
      })
    );

    yield put(
      downloadLessonSuccess({
        lesson: compose(
          head,
          values
        )(lesson),
        pages,
        blocks,
      })
    );
  });
}
