import {
  set,
  omit,
  pull,
  size,
  unset,
  pullAt,
  update,
  compose,
} from 'lodash/fp';

import * as EXAM from '../../constants/player/block/exam';
import * as QUIZ from '../../constants/player/block/quiz';
import * as TALK from '../../constants/player/block/talk';
import * as MATCH from '../../constants/player/block/match';
import * as ANSWER from '../../constants/player/block/answer';
import * as FEEDBACK from '../../constants/player/block/feedback';
import * as FILLBLANK from '../../constants/player/block/fillblank';
import { push, getLastIndex } from '../../utils/array';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../../appConstants';

/**
 * Редюсер для изменения страниц
 * и блоков в плеере и в презентации
 */
const contentReducer = (state, action) => {
  switch (action.type) {
    // -------------- Блоки --------------

    case EXAM.CHANGE_USER_ANSWER: {
      const { blockId, newUserAnswer } = action.payload;
      return set(['blocks', blockId, 'userAnswer'], newUserAnswer, state);
    }

    case FILLBLANK.CHANGE_USER_ANSWER: {
      const { blockId, answerId, newUserAnswer } = action.payload;
      return set(
        ['blocks', blockId, 'userAnswer', answerId],
        newUserAnswer || '',
        state
      );
    }

    case MATCH.SET_COMBINED_ANSWERS: {
      const { combinedAnswers, blockId } = action.payload;
      return compose(
        set(['blocks', blockId, 'progress'], []),
        set(['blocks', blockId, 'combinedAnswers'], combinedAnswers)
      )(state);
    }

    // TODO можно упростить?
    case MATCH.MOVE_ANSWER: {
      const { blockId, answer, newBasketId, oldBasketId } = action.payload;
      return compose(
        update(
          ['blocks', blockId, 'progress'],
          push({ [newBasketId]: answer })
        ),
        update(
          ['blocks', blockId, 'answers', newBasketId, 'selectedAnswers'],
          (selectedAnswers = []) => push(answer, selectedAnswers)
        ),
        (oldState) => {
          if (oldBasketId) {
            return update(
              ['blocks', blockId, 'answers', oldBasketId, 'selectedAnswers'],
              (selectedAnswers) =>
                pullAt(selectedAnswers.indexOf(answer), selectedAnswers),
              oldState
            );
          } else {
            return update(
              ['blocks', blockId, 'combinedAnswers'],
              pull(answer),
              oldState
            );
          }
        }
      )(state);
    }

    case QUIZ.SELECT_ANSWER: {
      const {
        answerId,
        blockId,
        isNotRegularBlock,
        quizType,
        blockType,
      } = action.payload;
      // TODO передавать тип блока в action (чтобы вынести код в отдельный редьюсер)
      const withAnswers = update(
        ['blocks', blockId, 'selectedAnswersIds'],
        (selectedAnswersIds = []) =>
          selectedAnswersIds.includes(answerId)
            ? {
                [QUIZ_BLOCK_TYPE_RADIO]: selectedAnswersIds,
                [QUIZ_BLOCK_TYPE_CHECKBOX]: pull(answerId, selectedAnswersIds),
              }[quizType]
            : {
                [QUIZ_BLOCK_TYPE_RADIO]: {
                  Quiz: isNotRegularBlock ? [answerId] : push(answerId, selectedAnswersIds),
                  Weight: [answerId],
                  Survey: [answerId],
                }[blockType],
                [QUIZ_BLOCK_TYPE_CHECKBOX]: push(answerId, selectedAnswersIds),
              }[quizType],
        state
      );
      if (blockType === 'Weight') {
        const blocks = state.content || {}
        const weightBlocksIds = Object
          .keys(blocks)
          .filter((id) => blocks[id]?.type === 'Weight')
        const raw = weightBlocksIds
          .reduce((result, id) => {
            const block = blocks[id]
            const competence = block?.content.competence // string
            const group = block?.content.group // string
            const level = block?.content.level // number
            const weights = block?.content.weights // object
            const answers = withAnswers.blocks?.[id]?.selectedAnswersIds || []
            if (!competence || !group || level === undefined) return result
            return {
              ...result,
              questions: {
                byId: {
                  ...result.questions?.byId,
                  levels: { ...result.questions?.byId?.levels, [id]: level },
                  groups: { ...result.questions?.byId?.groups, [id]: group },
                  competences: { ...result.questions?.byId?.competences, [id]: competence },
                },
                byType: {
                  ...result.questions?.byType,
                  competences: {
                    ...result.questions?.byType?.competences,
                    [competence]: [...result.questions?.byType?.competences?.[competence] || [], id]
                  },
                  levels: {
                    ...result.questions?.byType?.levels,
                    [level]: [...result.questions?.byType?.levels?.[level] || [], id]
                  },
                  groups: {
                    ...result.questions?.byType?.groups,
                    [group]: [...result.questions?.byType?.groups?.[group] || [], id]
                  },
                },
              },
              answers: {
                ...result.answers,
                byId: {
                  ...result.answers?.byId,
                  selected: {
                    ...result.answers?.byId?.selected,
                    [id]: answers
                  },
                  weights: {
                    ...result.answers?.byId?.weights,
                    [id]: weights,
                  }
                }
              }
            }
          }, {})
        const computed = Object
          .keys(raw.questions?.byType?.competences || {})
          .map((competence) => {
            const idsByCompetence = raw.questions?.byType?.competences?.[competence]
            // ID вопросов по группам внутри компетенций
            const idsByGroup = idsByCompetence.reduce((result, id) => {
              // Имя группы
              const group = raw.questions?.byId?.groups?.[id]
              if (!group) return result
              return { ...result, [group]: [...result[group] || [], id] }
            }, {})
            // Суммы весов ответов по группам
            const weightedGroups = Object.keys(idsByGroup).map((group) => {
              // ID вопросов внутри группы
              const groupIds = idsByGroup[group] || []
              // Сумма весов ответов внутри группы
              const weightsSum = groupIds.reduce((result, id) => {
                // Выбранный ответ (в массиве всегда 1 или 0 элементов)
                const selected = raw.answers?.byId?.selected?.[id]?.[0]
                // Вес ответа
                const weight = raw.answers?.byId?.weights?.[id]?.[selected]
                if (!weight) return result
                // Уровень вопроса
                const level = raw.questions?.byId?.levels?.[id]
                if (!level) return result
                // Веса ответов вопросов с уровнем >0 суммируются, <0 вычитаются
                return result + (level > 0 ? weight : -weight)
              }, 0)
              // Количество вопросов в группе с уровнем > 0
              const poistiveLevelsCount = groupIds.reduce((result, id) => {
                const level = raw.questions?.byId?.levels?.[id]
                return level > 0 ? result + 1 : result 
              }, 0)
              const averageWeights = weightsSum / poistiveLevelsCount
              return { alias: group, level: averageWeights }
            })
            return { alias: competence, nzds: weightedGroups }
          })
        return {
          ...withAnswers,
          competences: { raw, computed }
        }
      }
      return withAnswers
    }

    case QUIZ.SAVE_SHUFFLE_ANSWERS: {
      const { blockId, shuffledAnswers } = action.payload;
      return set(
        ['blocks', blockId, 'shuffledAnswers'],
        shuffledAnswers,
        state
      );
    }

    case QUIZ.CHANGE_ANSWER_OTHER: {
      const { blockId, answer } = action.payload;
      return set(['blocks', blockId, 'answerOtherAnswer'], answer)(state);
    }

    case ANSWER.CHANGE_REPLY: {
      const { newReply, blockId, date } = action.payload;
      return update(
        ['blocks', blockId, 'reply'],
        (oldReply = []) =>
          compose(
            set([getLastIndex(oldReply), 'studentAnswer'], newReply),
            set([getLastIndex(oldReply), 'studentAnswerDate'], date)
          )(oldReply),
        state
      );
    }

    case ANSWER.REMOVE_IMAGE: {
      const { imageId, blockId } = action.payload;
      return update(
        ['blocks', blockId, 'reply'],
        (oldReply) => {
          const lastIndex = size(oldReply) - 1;
          return unset([lastIndex, 'studentFiles', imageId], oldReply);
        },
        state
      );
    }

    case TALK.SELECT_ANSWER: {
      const { blockId, answerId, target, score } = action.payload;
      return compose(
        set(['blocks', blockId, 'score'], score),
        set(['blocks', blockId, 'currentTarget'], target),
        update(
          ['blocks', blockId, 'selectedAnswersIds'],
          (selectedAnswersIds = []) => push(answerId, selectedAnswersIds)
        )
      )(state);
    }

    case TALK.NEXT_SCREEN: {
      const { blockId, target } = action.payload;
      return set(['blocks', blockId, 'currentTarget'], target)(state);
    }

    case FEEDBACK.CHANGE_REPLY: {
      const { newReply, blockId } = action.payload;
      return set(['blocks', blockId, 'userAnswer', 'reply'], newReply, state);
    }

    case FEEDBACK.REMOVE_IMAGE: {
      const { imageId, blockId } = action.payload;
      return update(
        ['blocks', blockId, 'userAnswer', 'files'],
        omit(imageId),
        state
      );
    }

    default:
      return state;
  }
};

export default contentReducer;
