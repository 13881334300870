import {
  compose,
  difference,
  first,
  flatten,
  get,
  isArray,
  isEmpty,
  isObject,
  keys,
  map,
  mapKeys,
  mapValues,
  matchesProperty,
  mergeWith,
  omit,
  pick,
  pickBy,
  set,
  some,
  update,
  values,
  zipObject,
} from 'lodash/fp';
import objectId from 'bson-objectid';

export const generateId = objectId.generate;

export const getPages = (lessonId, allLessons, allPages) =>
  pick(get([lessonId, 'content', 'pagesIds'], allLessons), allPages);

export const getPagesIds = (lessonId, allLessons, allPages) =>
  keys(getPages(lessonId, allLessons, allPages));

export const getBlocksIdsByType = (blockType, allBlocks) =>
  compose(
    keys,
    pickBy(matchesProperty('type', blockType))
  )(allBlocks);

export const convertTimestamp = (milliseconds) =>
  Math.round(milliseconds / 1000);

export const getBlocks = (lessonId, allLessons, allPages, allBlocks) =>
  pick(
    compose(
      flatten,
      values,
      mapValues('blocksIds')
    )(getPages(lessonId, allLessons, allPages)),
    allBlocks
  );
// at(
//   compose(
//     flatten,
//     map('blocksIds')
//   )(
//     at(
//       get([lessonId, 'pagesIds'], allPages),
//       allPages,
//     )
//   )
// )(allBlocks);

export const getUserAnswers = (lessonId, allLessons, allPages, allBlocks) =>
  mapValues(
    pick('execution'),
    getBlocks(lessonId, allLessons, allPages, allBlocks)
  );

export const getBlocksIds = (lessonId, allLessons, allPages, allBlocks) =>
  keys(getBlocks(lessonId, allLessons, allPages, allBlocks));

export const isCloned = (lessonId, allLessons) =>
  compose(
    some((originalLessonId) => originalLessonId === lessonId),
    map((lesson) => lesson.meta.originalLessonId),
    values
  )(allLessons);

export const getClonesIds = (lessonId, allLessons) =>
  compose(
    keys,
    pickBy((lesson) => get(['meta', 'originalLessonId'], lesson) === lessonId)
  )(allLessons);

export const getCloneId = (lessonId, allLessons) =>
  first(getClonesIds(lessonId, allLessons));

export const merge = (newData) => (oldData) =>
  mergeWith(
    (object, source) =>
      isArray(object)
        ? source
        : some((value) => !isObject(value) && isEmpty(value), values(object))
        ? source
        : undefined,
    oldData,
    newData
  );

export const sync = (oldData, newData) =>
  compose(
    merge(newData),
    omit(difference(keys(oldData), keys(newData)))
  );

export const clone = (
  folderId,
  lessonId,
  userId,
  pagesIds,
  blocksIds,
  allLessons,
  allPages,
  allBlocks,
  courseId
) => {
  const originalClonedLessonIdMapper = { [lessonId]: generateId() };
  const getClonedLessonId = () => get(lessonId, originalClonedLessonIdMapper);
  const originalClonedPageIdMapper = zipObject(
    pagesIds,
    map(() => generateId(), pagesIds)
  );
  const getClonedPageId = (pageId) => get(pageId, originalClonedPageIdMapper);
  const blockIdMapper = zipObject(
    blocksIds,
    map(() => generateId(), blocksIds)
  );
  const getNewBlockId = (blockId) => get(blockId, blockIdMapper);
  const clonedLesson = {
    [getClonedLessonId()]: compose(
      set(
        ['content', 'name'],
        `${get([lessonId, 'content', 'name'], allLessons) || ''}${
          !courseId ? ' - копия' : ''
        }`
      ),
      set(['meta', 'publications'], []),
      set(['meta', 'hasUnpublishedChanges'], true),
      set(['meta', 'demo'], undefined),
      set(['meta', 'creatorId'], userId),
      set(['meta', 'courseId'], courseId),
      set(['meta', 'folderId'], undefined),
      set(['meta', 'createdAt'], convertTimestamp(Date.now())),
      set(['meta', 'originalLessonId'], lessonId),
      update(['content', 'pagesIds'], map(getClonedPageId)),
      get(lessonId)
    )(allLessons),
  };
  const clonedPages = mapValues(
    update('blocksIds', map(getNewBlockId)),
    mapKeys(getClonedPageId, pick(pagesIds, allPages))
  );
  const clonedBlocks = mapKeys(getNewBlockId, pick(blocksIds, allBlocks));
  return {
    pages: clonedPages,
    lesson: clonedLesson,
    blocks: clonedBlocks,
  };
};

export const clearURL = (url) =>
  url ? `//${url.replace(/^[a-z]{0,5}:?\/\//i, '')}` : '';
