import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { get } from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import { Text, Link } from '~/components';
import { results } from '~/actions';
import withSSRHandler from '~/hocs/withSSRHandler';

import LessonHeader from '~/components/LessonHeader';
import OlympiadResult from '~/components/OlympiadResult';

import * as s from './styles/SharedOlympiadResult.styles';

const SharedOlympiadResult = () => {
  const { t } = useTranslation('screens');
  const data = useSelector(get('publicOlympiadResult'));

  if (!data) {
    return null;
  }

  const {
    lessonId,
    publication,
    checkedResult,
    userName,
    olympiadSettings,
    lessonContent,
  } = data;

  return (
    <div css={s.container}>
      <LessonHeader
        isOlympiadMode
        isLessonComplete
        totalTasksCount={checkedResult.totalCount}
        correctTasksCount={checkedResult.correctCount}
        olympiadPartnersLogos={olympiadSettings.partnersLogos}
        lessonName={lessonContent.name}
        lessonDescription={lessonContent.description}
        olympiadDescriptionInResult={olympiadSettings.descriptionInResult}
      />
      <OlympiadResult
        top={
          <div css={s.topContainer}>
            <div css={s.avatar} />
            {userName}
          </div>
        }
        bottom={
          <div>
            <Text
              value={t('sharedOlympiadResult.getInvolvedToo')}
              css={s.bottomText}
            />
            <Link
              variant="player-alert"
              text={t('sharedOlympiadResult.participate')}
              to={`/player/lesson/${lessonId}/${publication}`}
            />
          </div>
        }
        totalTasksCount={checkedResult.totalCount}
        correctTasksCount={checkedResult.correctCount}
        levels={olympiadSettings.levels}
      />
    </div>
  );
};

SharedOlympiadResult.propTypes = {};

const mapDispatchToProps = {
  downloadPublicOlympiadResult: results.downloadPublicOlympiadResult,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withSSRHandler(({ match, downloadPublicOlympiadResult }) => {
    const { resultId } = match.params;
    downloadPublicOlympiadResult({ resultId });
  })
)(SharedOlympiadResult);
