export const CHANGE_QUESTION = '@builder/block/exam/Изменение вопроса';
export const CHANGE_DESCRIPTION = '@builder/block/exam/Изменение описания';
export const ADD_CORRECT_ANSWER =
  '@builder/block/exam/Добавление нового правильного ответа';
export const CHANGE_CORRECT_ANSWER =
  '@builder/block/exam/Изменение текста правильного ответа';
export const REMOVE_CORRECT_ANSWER =
  '@builder/block/exam/Удаление правильного ответа';
export const TOGGLE_CORRECT_ANSWER_TYPE =
  '@builder/block/exam/Изменение типа правильного ответа';
