import { set, curry, compose } from 'lodash/fp';
import * as FILLBLANK from '../../../constants/builder/block/fillblank';

export const defaultState = {
  question: undefined, // Текст вопроса
  correctAnswers: {}, // Правильные ответы
};

const fillblankReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FILLBLANK.CHANGE_TITLE: {
      const { title } = action.payload;
      return set('title', title)(state);
    }

    case FILLBLANK.CHANGE_QUESTION: {
      const { newQuestion, newCorrectAnswers } = action.payload;
      return compose(
        set('question', newQuestion),
        set('correctAnswers', newCorrectAnswers)
      )(state);
    }

    default:
      return state;
  }
};

export default curry(fillblankReducer);
