export const setHintPosition = (step) => {
  switch (step) {
    case 0: {
      return { top: '-7px' };
    }

    case 2: {
      return { top: '-300px' };
    }

    case 3: {
      return { top: '-150px' };
    }

    default: {
      return { top: '0px' };
    }
  }
};
