import React from 'react';
import { number } from 'prop-types';

import '~/styles/ProgressBar.scss';

const ProgressBar = ({ value, width }) => (
  <div className="ProgressBar" style={{ width }}>
    <div className="value" style={{ width: width * value }} />
  </div>
);

ProgressBar.propTypes = {
  value: number.isRequired,
  width: number,
};

ProgressBar.defaultProps = {
  width: 80,
};

export default ProgressBar;
