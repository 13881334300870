import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import Headroom from 'react-headroom';
import { combineStyles } from '../utils/styles';
import '../styles/Sticky.scss';

const Sticky = ({ variant, children }) => (
  <div className={combineStyles('Sticky', variant)}>
    <Headroom>{children}</Headroom>
  </div>
);

const { string, any, oneOfType, array } = PropTypes;

Sticky.propTypes = {
  children: any.isRequired, // Внутренний контент
  variant: oneOfType([array, string]), // Вариант оформления
};

Sticky.defaultProps = {
  variant: null,
};

export default pure(Sticky);
