import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Switch.scss';

const Switch = ({ label, checked, variant, disabled, onChange }) => (
  <div className={combineStyles('Switch', variant)}>
    <Blueprint.Switch
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);

const { string, bool, func, array, oneOfType } = PropTypes;

Switch.propTypes = {
  label: string, // Метка
  checked: bool, // Включен?
  variant: oneOfType([array, string]), // Вариант оформления
  onChange: func, // Изменить состояние
  disabled: bool,
};

Switch.defaultProps = {
  label: '',
  checked: false,
  variant: undefined,
  onChange: undefined,
  disabled: false,
};

export default pure(Switch);
