import memoize from 'fast-memoize';
import * as selectors from '../selectors';

/**
 * Создание объекта из переменных контекста
 */
export const create = memoize(({ ...contexts }) => contexts); // eslint-disable-line fp/no-rest-parameters

/**
 * Получение блока из контекста
 *
 * Возможен случай когда активны два контекста,
 * например когда окно результатов открыто в
 * конструкторе, то будут активны (в true) переменные
 * контекста inBuilder и inReview, или когда библиотека
 * открыта из конструктора, тогда значение берется из
 * последнего активного контекста в списке выбора
 */
export const getBlock = (contexts) =>
  ({
    [contexts.inBuilder]: selectors.builder.getBlock,
    [contexts.inPlayer]: selectors.player.getBlock,
    [contexts.inReview]: selectors.results.getResultBlock,
    [contexts.inPresentation]: selectors.presentation.getBlock,
    [contexts.inLibraryEdit]: selectors.library.getEditingBlock,
    [contexts.inLibraryShare]: selectors.library.getSharedBlock,
    [contexts.inLibrarySearch]: selectors.library.getSearchedBlock,
  }.true);

/**
 * Получение блоков из контекста
 */
export const getBlocks = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.getBlocks,
    [contexts.inReview]: selectors.results.getResultBlocks,
    [contexts.inBuilder]: selectors.builder.getBlocks,
    [contexts.inPresentation]: selectors.presentation.getBlocks,
  }.true);

/**
 * Получение страниц из контекста
 */
export const getPages = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.getPages,
    [contexts.inBuilder]: selectors.builder.getPages,
    [contexts.inPresentation]: selectors.presentation.getPages,
  }.true);

/**
 * Получение уроков из контекста
 */
export const getLessons = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.getLessons,
    [contexts.inBuilder]: selectors.builder.getLessons,
  }.true);

/**
 * Завершено ли прохождение контрольной
 * страницы в плеере или презентации
 */
export const isPageCompleted = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.isPageCompleted,
    [contexts.inPresentation]: selectors.presentation.isPageCompleted,
  }.true);

/**
 * Блок в плеере или презентации находится
 * в режиме проверки правильных ответов
 */
export const isBlockInVerifyMode = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.isBlockInVerifyMode,
    [contexts.inPresentation]: selectors.presentation.isBlockInVerifyMode,
  }.true);

/**
 * Страница в плеере или презентации находится
 * в режиме проверки правильных ответов
 */
export const isPageInVerifyMode = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.isPageInVerifyMode,
    [contexts.inPresentation]: selectors.presentation.isPageInVerifyMode,
  }.true);

/**
 * Страница в плеере или презентации контрольная?
 */
export const isPageInExamMode = (contexts) =>
  ({
    [contexts.inPlayer]: selectors.player.isPageInExamMode,
    [contexts.inPresentation]: selectors.presentation.isPageInExamMode,
  }.true);
