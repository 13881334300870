import {
  get,
  map,
  join,
  size,
  some,
  keys,
  first,
  slice,
  pickBy,
  reduce,
  sortBy,
  concat,
  compose,
  isEmpty,
  isEqual,
  shuffle,
  includes,
  cloneDeep,
  difference,
  flattenDeep,
  intersection,
  last,
  isBoolean,
  toLower,
} from 'lodash/fp';
import objectId from 'bson-objectid';
import memoize from 'fast-memoize';
import omitDeep from 'omit-deep-lodash';
import { splitStringAndSort } from '../utils/string';

export const generateId = objectId.generate;

export const clone = (blockId, allBlocks) => ({
  [generateId()]: cloneDeep(get(blockId, allBlocks)),
});

export const getPageId = (blockId, allPages) =>
  compose(
    first,
    keys,
    pickBy((page) => includes(blockId, get('blocksIds', page)))
  )(allPages);

export const checkIsBlockApproved = (reply) => {
  const isLastApproved = compose(
    get('isApproved'),
    last
  )(reply);

  if (isBoolean(isLastApproved)) {
    return isLastApproved;
  }

  const prevIndex = size(reply) - 2;
  return get([prevIndex, 'isApproved'], reply);
};

export const isAnswerCorrect = ({ reply } = {}) =>
  !!checkIsBlockApproved(reply);

export const isExamCorrect = memoize(
  ({ userAnswer, correctAnswers, disorderlyCorrectAnswersIds }) =>
    some((answerId) => {
      if (includes(answerId, disorderlyCorrectAnswersIds)) {
        return isEqual(
          splitStringAndSort(toLower(userAnswer)),
          splitStringAndSort(get(answerId, correctAnswers).toLowerCase())
        );
      } else {
        return (
          toLower(userAnswer) === get(answerId, correctAnswers).toLowerCase()
        );
      }
    }, keys(correctAnswers))
);

export const isMatchCorrect = (userAnswers, correctAnswers) =>
  compose(
    reduce((item, accumulator) => !!(item * accumulator), true),
    map((basketId) => {
      if (userAnswers && userAnswers[basketId]) {
        return isEmpty(
          difference(
            correctAnswers[basketId].answers,
            userAnswers[basketId].selectedAnswers
          )
        );
      } else {
        return false;
      }
    })
  )(keys(correctAnswers));

export const indetifyStatus = (
  selectedAnswers,
  correctAnswers,
  status,
  isNotRegularBlock
) =>
  // Если выбран хотя бы один ответ
  // И блок находится не в обычном уроке, а в олимпиаде или на контрольной странице
  isNotRegularBlock && !isEmpty(selectedAnswers)
    ? status.saved
    : // Если не выбран ни один ответ
    isEmpty(selectedAnswers)
    ? status.notPassed
    : // Если выбраны все правильные ответы
    size(correctAnswers) ===
        size(intersection(correctAnswers, selectedAnswers)) &&
      size(correctAnswers) === size(selectedAnswers)
    ? status.passedCorrectly
    : // Если выбраны все правильные ответы и хотя бы один неправильный
    size(correctAnswers) < size(selectedAnswers) &&
      size(correctAnswers) ===
        size(intersection(correctAnswers, selectedAnswers))
    ? status.passedIncorrectly
    : // В процессе выполнения, если выбран хотя бы один ответ
      // и не выполняются предыдущие условия
      status.inProcess;

export const indetifyMatchStatus = (
  correctMatchAnswers,
  selectedMatchAnswers,
  status,
  isNotRegularBlock
) =>
  isNotRegularBlock && !isEmpty(selectedMatchAnswers)
    ? status.saved
    : isEmpty(selectedMatchAnswers) && status.notPassed;

export const checkboxStyle = (
  answerId,
  inReview,
  inPlayer,
  inPresentation,
  correctAnswers,
  selectedAnswers,
  isNotRegularBlock
) =>
  inPlayer || inPresentation
    ? // Если выбран ответ и блок находится не в обычном уроке
      // А в олимпиаде или на контрольной странице
      isNotRegularBlock && selectedAnswers.includes(answerId)
      ? 'checked'
      : // Если выбран верный ответ
      selectedAnswers.includes(answerId) && correctAnswers.includes(answerId)
      ? 'success'
      : // Если выбран неверный ответ
      selectedAnswers.includes(answerId) && !correctAnswers.includes(answerId)
      ? 'error'
      : undefined
    : inReview
    ? // Пункт отмечен и является верным?
      (selectedAnswers.includes(answerId) && correctAnswers.includes(answerId)
        ? 'success'
        : undefined) ||
      // Пункт отмечен и является неверным?
      (selectedAnswers.includes(answerId) && !correctAnswers.includes(answerId)
        ? 'error'
        : undefined) ||
      // Пункт не отмечен и является верным?
      (!selectedAnswers.includes(answerId) && correctAnswers.includes(answerId)
        ? 'missed'
        : undefined) ||
      undefined
    : undefined;

export const isCheckboxDisabled = (
  answerId,
  inPlayer,
  inReview,
  inPresentation,
  isPageCompleted,
  isNotRegularBlock,
  correctAnswers,
  selectedAnswers
) =>
  inPlayer || inPresentation
    ? // Если страница в олимпиаде или контрольная
      isPageCompleted ||
      (isNotRegularBlock
        ? undefined
        : (!isEmpty(correctAnswers) &&
            size(correctAnswers) ===
              size(intersection(correctAnswers, selectedAnswers))) ||
          selectedAnswers.includes(answerId))
    : inReview
    ? // Если блок находится в обычном уроке
      isNotRegularBlock
      ? undefined
      : (!isEmpty(correctAnswers) &&
          size(correctAnswers) ===
            size(intersection(correctAnswers, selectedAnswers))) ||
        selectedAnswers.includes(answerId)
    : undefined;

export const generateExamCorrectAnswerHint = memoize(
  ({ correctAnswers, disorderlyCorrectAnswersIds }) =>
    compose(
      join(', '),
      map((answerId) =>
        includes(answerId, disorderlyCorrectAnswersIds)
          ? `${get(
              answerId,
              correctAnswers
            )} (или любая другая последовательность этих цифр)`
          : get(answerId, correctAnswers)
      ),
      keys
    )(correctAnswers)
);

// Получение массива со всеми ответами каждой корзины и их перемешивание
export const shuffleBasketsAnswers = (baskets) =>
  shuffle(flattenDeep(map((basketId) => basketId.answers, baskets)));

export const shuffleQuizAnswers = (
  displayedAnswersQuantity,
  answersIdsOrder,
  correctAnswersIds
) =>
  compose(
    sortBy(() => Math.random() - 0.5),
    concat(correctAnswersIds),
    slice(0, displayedAnswersQuantity - correctAnswersIds.length),
    sortBy(() => Math.random() - 0.5),
    difference(answersIdsOrder)
  )(correctAnswersIds);

export const cleanTalkFromTargets = (clonedBlock) =>
  omitDeep(clonedBlock, 'target');
