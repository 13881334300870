import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { lifecycle } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import withTheming from '~/hocs/withTheming';
import Text from '~/components/Text';
import Froala from '~/components/Froala';
import Hyperlink from '~/components/Hyperlink';
import ChartThermometer from '~/components/ChartThermometer';
import { combineStyles } from '~/utils/styles';
import '~/styles/Player/OlympiadResult.scss';

const OlympiadResult = ({
  t,
  top,
  theme,
  levels,
  bottom,
  fontSize,
  inSafeMode,
  ltiReturnUrl,
  selectedLevel,
  totalTasksCount,
  correctTasksCount,
}) => {
  const passedLevel =
    selectedLevel ||
    levels.find(
      ({ minScore, maxScore }) =>
        +minScore <= correctTasksCount && correctTasksCount <= +maxScore
    ) ||
    levels[0];

  // eslint-disable-next-line more/no-duplicated-chains
  const imgSource = passedLevel?.image?.source && `https:${passedLevel?.image?.source}`;

  return (
    <div
      id="olympiadResult"
      className={combineStyles(
        'OlympiadResult',
        inSafeMode && [fontSize, theme]
      )}
    >
      {top}
      <div className={combineStyles('text', inSafeMode && [fontSize, theme])}>
        <Text
          value={`${t('olympiadresult.yourResult')} – ${correctTasksCount} ${t(
            'olympiadresult.outOf'
          )} ${totalTasksCount}`}
          variant={
            inSafeMode
              ? ['welcome', 'olympiad-result-title', fontSize, theme]
              : ['welcome', 'olympiad-result-title']
          }
        />
      </div>
      <ChartThermometer
        theme={theme}
        fontSize={fontSize}
        inSafeMode={inSafeMode}
        valueMax={totalTasksCount}
        valueCurrent={correctTasksCount}
      />
      <div className="content">
        {passedLevel.subtitle && (
          <Text
            value={t(passedLevel.subtitle)}
            variant="olympiad-result-subtitle"
          />
        )}
        {passedLevel.text && (
          <Froala
            mode="preview"
            content={passedLevel.text}
            variant="olympiad-result-text"
          />
        )}
        {imgSource && (
          <div className="image">
            <Helmet>
              <meta property="og:image" content={encodeURI(imgSource)} />
            </Helmet>
            <img className="img" src={imgSource} alt="" />
          </div>
        )}
        {ltiReturnUrl && (
          <Hyperlink
            newTab
            href={ltiReturnUrl}
            variant="lti-return"
            text={t('index.toLtiCabinet')}
          />
        )}
      </div>
      {bottom}
    </div>
  );
};
const { bool, string, number, func, array, object, node } = PropTypes;

OlympiadResult.propTypes = {
  t: func.isRequired, // Функция перевода
  top: node,
  theme: string, // Цветовая тема в режиме ОВЗ
  bottom: node,
  levels: array,
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
  ltiReturnUrl: string, // Ссылка возврата на платформу при доступе по LTI
  selectedLevel: object,
  totalTasksCount: number, // Общее количество заданий
  correctTasksCount: number, // Количество верно пройденных заданий
};

OlympiadResult.defaultProps = {
  theme: 'light',
  levels: [],
  fontSize: 'small',
  ltiReturnUrl: undefined,
  totalTasksCount: undefined,
  correctTasksCount: undefined,
};

const enhance = lifecycle({
  componentDidUpdate() {
    const olympiadResult = document.getElementById('olympiadResult');
    setTimeout(() => olympiadResult.scrollIntoView(), 0);
  },
});

export default compose(
  withTranslation('containersPlayer'),
  withTheming,
  enhance
)(OlympiadResult);
