import objectId from 'bson-objectid';
import * as IMAGES from '../../../constants/builder/block/images';
import { createAction } from '../../../utils/actions';

export const changeQuantity = (count, id, collection) =>
  createAction(IMAGES.CHANGE_QUANTITY, {
    collection,
    blockId: id,
    count,
    imageId: objectId.generate(),
  });

export const changeURL = (blockId, imageId, newContent) =>
  createAction(IMAGES.CHANGE_URL, { blockId, imageId, newContent });

export const changeSize = (blockId, imageId, newWidth) =>
  createAction(IMAGES.CHANGE_SIZE, { blockId, imageId, newWidth });

export const removeImage = (imageId, blockId) =>
  createAction(IMAGES.REMOVE_IMAGE, { imageId, blockId });

export const changeDescription = (blockId, imageId, newDescription) =>
  createAction(IMAGES.CHANGE_DESCRIPTION, { blockId, imageId, newDescription });
