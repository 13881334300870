import {
  compose,
  omitBy,
  pick,
  pickBy,
  prop,
  reduce,
  size,
  curry,
} from 'lodash/fp';

import { taskTypes } from '~/appConstants';
import { oneOf } from '~/utils/fp';

export const getTotalTasksCount = curry((lessonId, state) =>
  compose(
    reduce((result, { isShuffleBlocks, blocksIds, displayedBlocks }) => {
      // ! Если включена рандомизация блоков на странице, подразумевается,
      // ! что все блоки на этой странице имеют свой балл,
      // ! что на самом деле не верно и нигде не проверяется.
      // ! На страницу с рандомизацией вперемешку с остальными могут
      // ! попасть блоки которые балла не имеют.
      // ! Нужно в будущем как-то пофиксить это на стороне логики самого приложения
      if (isShuffleBlocks) {
        return result + displayedBlocks;
      }

      const tasks = compose(
        pickBy(
          compose(
            oneOf(taskTypes),
            prop('type')
          )
        ),
        pick(blocksIds)
      )(state.builder.blocks);

      return result + size(tasks);
    }, 0),
    omitBy(prop('inExamMode')),
    pick(state.builder.lessons[lessonId].content.pagesIds)
  )(state.builder.pages)
);
