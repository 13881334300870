import React from 'react';
import PropTypes from 'prop-types';

import TalkBuilder from './TalkBuilder';
import TalkPlayer from './TalkPlayer';
import TalkReview from './TalkReview';

const Talk = ({ inPlayer, inReview, inPresentation, ...props }) => {
  const Component =
    inPlayer || inPresentation
      ? TalkPlayer
      : inReview
      ? TalkReview
      : TalkBuilder;
  return React.createElement(Component, props);
};

const { string, bool } = PropTypes;

Talk.propTypes = {
  inPlayer: bool,
  inReview: bool,
  inPresentation: bool,
  blockId: string.isRequired,
  lessonId: string.isRequired,
};

export default Talk;
