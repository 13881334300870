import objectId from 'bson-objectid';
import { set } from 'lodash/fp';
import * as BLOCK from '../../constants/builder/block';

/**
 * Добавление блока Images
 */
export const add = (state) => (next) => (action) => {
  if (action.type === BLOCK.ADD && action.payload.type === 'Images') {
    const isLibraryOpen = state.getState().library.search.isOpen;
    const imageId = objectId.generate();
    if (isLibraryOpen) {
      return next(action);
    }
    return next(
      set(
        ['meta', 'content'],
        {
          collection: {
            [imageId]: {
              source: '',
              description: '',
            },
          },
        },
        action
      )
    );
  } else {
    return next(action);
  }
};
