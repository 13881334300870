import { assign, update, compose } from 'lodash/fp';
import * as PLAYER from '../constants/player';

export const defaultState = {
  player: {
    pages: {},
    blocks: {},
    lessons: {},
  },
};

const cacheReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_LESSON_SUCCESS: {
      const {
        downloadedPages,
        downloadedBlocks,
        downloadedLesson,
      } = action.payload;
      return compose(
        update(['player', 'blocks'], (cachedBlocks) =>
          assign(cachedBlocks, downloadedBlocks)
        ),
        update(['player', 'pages'], (cachedBlocks) =>
          assign(cachedBlocks, downloadedPages)
        ),
        update(['player', 'lessons'], (cachedBlocks) =>
          assign(cachedBlocks, downloadedLesson)
        )
      )(state);
    }

    default:
      return state;
  }
};

export default cacheReducer;
