import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as LIBRARY from '../../constants/library';
import * as actions from '../../actions';
import request from '../request';

const api = (tagsFilter) => `${process.env.API_URL}/tag/${tagsFilter}`;

/**
 * Поиск тегов, связанных с блоками в библиотеке
 */
export function* find() {
  yield takeLatest(LIBRARY.SEARCH.CHANGE_TAGS_FILTER, function* _() {
    const tagsFilter = yield select(
      (state) => state.library.search.tags.filter
    );
    if (tagsFilter.length >= 3) {
      // Минимальная количество символов для поиска тэга
      const response = yield call(request, api(tagsFilter), null, 'get');
      if (response) {
        const { error, msg } = response;
        if (!error) {
          const tagConverter = {
            persons: 'Имя личности',
            themes: 'Тема',
            dates: 'Дата',
            terms: 'Термин',
          };
          const formattedTags = response.data.map((tag) => ({
            type: tagConverter[tag.category],
            text: tag.tag,
          }));
          yield put(actions.library.search.importTags(formattedTags));
        } else {
          yield put(actions.notice.show(msg));
        }
      }
    }
  });
}
