import * as PAGE from './page';
import * as BLOCK from './block';
import * as LESSON from './lesson';

export { PAGE, BLOCK, LESSON };

export const SEND_REQUEST = '@player/Отправить запрос на логин/регистрацию';
export const LOGIN = '@player/Залогиниться в плеере';
export const LOGOUT = '@player/Разлогиниться в плеере';
export const PRE_LOGOUT = '@player/Отправить данные перед выходом из плеера';
export const TOGGLE_MODE = '@player/Переключить режим отображения плеера';
export const CHANGE_THEME = '@player/Выбрать цветовую тему в плеере';
export const ENTER_BY_CODE = '@player/Войти в плеер по коду урока';
export const DOWNLOAD_LESSON = '@player/Загрузка урока с сервера';
export const DOWNLOAD_LESSON_PREVIEW =
  '@player/Загрузка превью урока с сервера';
export const SET_NEW_RESULT = '@player/Установка нового прохождения';
export const CLEAR_RESULT = '@player/Удалить старый результат';
export const CHANGE_FONT_SIZE = '@player/Выбрать размер шрифта в плеере';
export const CHANGE_ACTIVE_PAGE = '@player/Смена активной страницы';
export const ENTER_BY_CODE_ERROR = '@player/Ошибка входа плеер по коду урока';
export const ENTER_BY_CODE_SUCCESS =
  '@player/Успешный вход в плеер по коду урока';
export const DOWNLOAD_LESSON_ERROR = '@player/Ошибка загрузки урока с сервера';
export const DOWNLOAD_LESSON_SUCCESS =
  '@player/Загрузка урока с сервера выполнена';
export const SHOW_USER_WELCOME = '@player/Показать popup user welcome';
export const HIDE_USER_WELCOME = '@player/Скрыть popup user welcome';

export const SET_EXECUTION_TIME =
  '@player/lesson/Установка времени прохождения';
export const RESUME_EXECUTION = '@player/lesson/Продолжение прохождения';
export const DOWNLOAD_EXECUTION_ERROR =
  '@player/lesson/Ошибка загрузки прохождения с сервера';
export const DOWNLOAD_EXECUTION_SUCCESS =
  '@player/lesson/Загрузка прохождения с сервера выполнена';

export const SHOW_OFFER_NEW_VERSION =
  '@player/Показать popup offer new version';
export const HIDE_OFFER_NEW_VERSION =
  '@player/Скрыть popup с предложением пройти новую версию';

export const CHECK_PREVIOUS_EXECUTION =
  '@player/lesson/Проверить наличие последнего прохождения для ученика';
export const DOWNLOAD_PREVIOUS_EXECUTION =
  '@player/lesson/Загрузка последнего прохождения для ученика';
export const DOWNLOAD_USER_EXECUTION_SUCCESS =
  '@player/lesson/Успешная загрузка последнего прохождения для ученика с сервера выполнена';
export const CLEAR_USER_EXECUTION =
  '@player/lesson/Сбросить результаты последнего прохождения';
export const UPDATE_EXECUTION_RESULT =
  '@player/lesson/Обновить данные результата прохождения';
export const UPDATE_EXECUTION_RESULT_SUCCESS =
  '@player/lesson/Данные результата прохождения обновлены';
