import { set, curry } from 'lodash/fp';
import * as ANSWER from '../../../constants/builder/block/answer';

export const defaultState = {
  question: undefined, // Текст вопроса
  description: undefined, // Описание
};

const answerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ANSWER.CHANGE_QUESTION: {
      const { newQuestion } = action.payload;
      return set('question', newQuestion, state);
    }

    case ANSWER.CHANGE_DESCRIPTION: {
      const { newDescription } = action.payload;
      return set('description', newDescription, state);
    }

    default:
      return state;
  }
};

export default curry(answerReducer);
