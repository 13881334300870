import React from 'react';
import PropTypes from 'prop-types';
import { compose, includes } from 'lodash/fp';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import '../styles/Basket.scss';
import DraggableTag from '~/components/DraggableTag';

const Basket = ({
  key,
  answers,
  variant,
  basketId,
  inReview,
  inPlayer,
  userAnswers,
  inPresentation,
  isOlympiadMode,
  isPageInExamMode,
  isLessonComplete,
  connectDropTarget,
  isPageInVerifyMode,
  basketSelectedAnswers,
}) =>
  connectDropTarget(
    <div className={combineStyles('Basket', variant)} key={key}>
      <div className="input">
        {(inReview ? userAnswers : basketSelectedAnswers).map(
          (answer, index) => (
            <DraggableTag
              key={index}
              value={answer}
              inPlayer={inPlayer || inPresentation}
              noDrag={inReview || isLessonComplete}
              basketId={basketId}
              inReview={inReview}
              noCheckMode={
                isPageInExamMode || (isOlympiadMode && !isPageInVerifyMode)
              }
              variant={includes(answer, answers) ? 'success' : 'error'}
            />
          )
        )}
      </div>
    </div>
  );

const { string, func, bool, array, oneOfType } = PropTypes;

Basket.propTypes = {
  key: string, // Ключ
  name: string, // Имя корзины
  answers: array, // Все заданные ответы для этой корзины
  variant: oneOfType([array, string]), // Вариант оформления
  inReview: bool, // В результатах?
  inPlayer: bool,
  blockId: string.isRequired, // ID блока
  basketId: string.isRequired, // ID корзины
  userAnswers: array, // Ответы пользователя
  inPresentation: bool,
  isOlympiadMode: bool, // В олимпиаде?
  isPageInExamMode: bool, // На контрольной странице?
  isLessonComplete: bool, // Урок завершен?
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  connectDropTarget: func, // Подключает DnD
};

Basket.defaultProps = {
  answers: [],
  userAnswers: [],
  isLessonComplete: false,
};

const dropTarget = {
  drop(props, monitor) {
    const { answer, basketId: oldBasketId } = monitor.getItem();
    const { blockId, moveAnswer, basketId } = props;
    moveAnswer(blockId, answer, basketId, oldBasketId);
  },
  canDrop(props, monitor) {
    const { basketId: oldBasketId } = monitor.getItem();
    const { basketId } = props;

    return basketId !== oldBasketId;
  },
};

const collectDropTarget = (connectTarget, monitor) => ({
  connectDropTarget: connectTarget.dropTarget(),
  isOver: monitor.isOver(),
});

const mapStateToProps = (state, ownProps) => {
  const { blockId, basketId, inPresentation } = ownProps;
  const basketSelectedAnswers = inPresentation
    ? selectors.presentation.getBasketSelectedAnswers(
        blockId,
        basketId,
        state
      ) || []
    : selectors.player.getBasketSelectedAnswers(blockId, basketId, state) || [];
  return { basketSelectedAnswers };
};

export default compose(
  DropTarget('tag', dropTarget, collectDropTarget),
  connect(
    mapStateToProps,
    undefined
  )
)(Basket);
