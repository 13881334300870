import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const CollapseContent = styled.div`
  width: 100%;
  opacity: ${ifProp('visible', 1, 0)};
  position: absolute;
  ${ifProp(
    'withAnimation',
    css`
      transition: opacity 0.3s ease;
    `
  )}
`;

const { bool, node } = PropTypes;

CollapseContent.propTypes = {
  visible: bool,
  withAnimation: bool,
  children: node,
};

export default CollapseContent;
