import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { compose } from 'lodash/fp';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import { withTranslation } from 'react-i18next';
import * as Blueprint from '@blueprintjs/core';
import { DragSource, DropTarget } from 'react-dnd';

import { DraggedButton as Button } from '~/components/Button';
import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import '../styles/Checkbox.scss';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../appConstants';
import Froala from '~/components/Froala';
import Icon from '~/components/Icon';
import Text from '~/components/Text';

//! Убрать из компонента
const styles = {
  success: 'bp3-checkbox-success',
  missed: 'bp3-checkbox-error',
  error: 'bp3-checkbox-error',
};

const Checkbox = ({
  t,
  type,
  theme,
  label,
  style,
  comment,
  onCheck,
  checked,
  variant,
  isMobile,
  disabled,
  readOnly,
  fontSize,
  onDelete,
  editable,
  prefilled,
  deletable,
  draggable,
  inSafeMode,
  isDragging,
  commentable,
  placeholder,
  onLabelChange,
  toggleComment,
  onCommentChange,
  connectDropTarget,
  connectDragSource,
}) =>
  editable ? ( // Режим редактирования
    connectDragSource(
      connectDropTarget(
        <div
          style={{ opacity: isDragging ? 0 : 1 }}
          className={combineStyles('Checkbox', [isMobile && 'mobile', variant])}
        >
          <Blueprint.Checkbox
            checked={checked}
            onChange={onCheck}
            className={classNames(
              styles[style],
              'checkbox',
              'bp3-checkbox-edit',
              // Внешний вид радиокнопки
              { 'bp3-radio': type === QUIZ_BLOCK_TYPE_RADIO }
            )}
          />
          {disabled && <Icon name="lock" variant="checkbox-locked" />}
          <Froala
            mode={prefilled ? 'preview' : 'editor'}
            content={label}
            toolbarButtons={['bold', 'italic', 'underline', 'strikeThrough']}
            placeholder={placeholder || label}
            onChange={onLabelChange}
            variant="in-checkbox"
          />
          {commentable && comment !== undefined && (
            <>
              <Text
                editable
                multiline
                value={comment}
                onChange={onCommentChange}
                placeholder={t('checkbox.changeComment')}
                variant={['answer-comment', 'in-edit']}
              />
              <div className="remove">
                <Button
                  minimal
                  icon="delete"
                  text={t('checkbox.remove')}
                  onClick={toggleComment}
                  variant="quiz-remove-answer-comment"
                />
              </div>
            </>
          )}
          <div className="controls">
            {commentable && (
              <div className="comment">
                <Button
                  icon={
                    comment !== undefined ? 'osh-commented' : 'osh-comment-2'
                  }
                  minimal
                  variant="in-checkbox"
                  onClick={comment === undefined ? toggleComment : undefined}
                  buttonTooltipContent={
                    <div>{t('checkbox.commentTooltip')}</div>
                  }
                />
              </div>
            )}
            {draggable && (
              <div className="drag">
                <Button icon="osh-draglittle" minimal variant="in-checkbox" />
              </div>
            )}
            {deletable && (
              <div className="delete">
                <Button
                  type="danger"
                  icon="osh-remove"
                  minimal
                  onClick={onDelete}
                  variant="in-checkbox"
                />
              </div>
            )}
          </div>
        </div>
      )
    )
  ) : (
    // Режим просмотра
    <div
      className={combineStyles('Checkbox', [
        inSafeMode && fontSize,
        inSafeMode && theme,
        'in-player',
        variant,
        isMobile && 'mobile',
      ])}
    >
      <Blueprint.Checkbox
        checked={checked}
        readOnly={readOnly}
        onChange={onCheck}
        disabled={disabled}
        className={classNames(
          styles[style],
          style,
          'checkbox',
          // Внешний вид радиокнопки
          { 'bp3-radio': type === QUIZ_BLOCK_TYPE_RADIO }
        )}
      />
      {style === 'error' && (
        <div className="error-label">
          <Text value={t('checkbox.wrongAnswer')} variant="in-checkbox-error" />
          <Icon name="osh-incorrect" variant="incorrect" />
        </div>
      )}
      {style === 'success' && (
        <Text
          value={t('checkbox.correctAnswer')}
          variant="in-checkbox-success"
        />
      )}
      {style === 'checked' && (
        <Text
          value={t('checkbox.selectedAnswer')}
          variant="in-checkbox-checked"
        />
      )}
      {style === 'missed' && (
        <div className="missed-label">
          <Text
            value={t('checkbox.notSelectedCorrectAnswer')}
            variant="in-checkbox-error"
          />
          <Icon name="osh-missed" variant="missed" />
        </div>
      )}
      <Froala
        mode="preview"
        content={label}
        variant={[
          variant,
          inSafeMode
            ? ['in-checkbox', fontSize, theme]
            : ['in-checkbox', 'in-player'],
        ]}
      />
    </div>
  );

const { bool, func, oneOf, array, string, oneOfType } = PropTypes;

Checkbox.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inSafeMode: bool, // В режиме ОВЗ?
  isMobile: bool.isRequired,
  type: oneOf([QUIZ_BLOCK_TYPE_RADIO, QUIZ_BLOCK_TYPE_CHECKBOX]), // Тип компонента
  label: string, // Текст рядом с чекбоксом (радиокнопкой)
  style: oneOf(['error', 'empty', 'success', 'missed', 'checked']), // Стиль чекбокса (радиокнопки)
  variant: oneOfType([array, string]), // Вариант оформления
  onCheck: func, // Вызывается при выборе чекбокса (радиокнопки)
  comment: string, // Текст комментария к ответу
  checked: bool, // Чекбокс выбран?
  readOnly: bool, // Режим только для чтения
  editable: bool, // Компонент в режиме редактирования?
  disabled: bool, // Компонент не активен?
  onDelete: func, // Выполняется при удалении чекбокса (радиокнопки)
  deletable: bool, // Можно удалить?
  commentable: bool,
  draggable: bool, // Можно перетаскивать?
  prefilled: bool, // Поле с предзаполненным ответом?
  isDragging: bool.isRequired, // Флаг перетаскивания
  placeholder: string, // Плейсхолдер в отсутствии метки
  toggleComment: func, // Добавить комментарий к ответу
  onLabelChange: func, // Вызывается при изменении метки чекбокса
  onCommentChange: func, // Вызывается при изменении коментария к ответу
  connectDragSource: func.isRequired, // Преобразование в перетаскиваемый компонент
  connectDropTarget: func.isRequired, // Преобразование в приемник перетаскиваемых компонентов
};

Checkbox.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  type: QUIZ_BLOCK_TYPE_CHECKBOX,
  label: '',
  placeholder: '',
};

const dragSource = {
  beginDrag: (props) => ({ order: props.order }),
};

const dropTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().order;
    const hoverIndex = props.order;
    if (dragIndex === hoverIndex) {
      return;
    }
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.onDragEnd(dragIndex, hoverIndex);
    monitor.getItem().order = hoverIndex; // eslint-disable-line no-param-reassign, fp/no-mutation
  },
};

const collectDragSource = (connect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
});

const collectDropTarget = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});

export default compose(
  withTranslation('components'),
  withMobileDetect,
  DropTarget('checkbox', dropTarget, collectDropTarget),
  DragSource('checkbox', dragSource, collectDragSource),
  pure
)(Checkbox);
