import { css } from 'styled-components';

export const final = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 70px 0 55px;
  width: 100%;

  > .title {
    text-transform: uppercase;
    font-family: CirceRounded;
    font-size: 9px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #aeb3bd;
    margin-bottom: 9px;
  }

  > .score {
    font-family: Gilroy;
    font-size: 45px;
    font-weight: bold;
    color: #45494e;
    margin-bottom: 18px;
  }

  > .status {
    font-family: CirceRounded;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #767b84;
  }
`;
