import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { Collapse } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';
import { compose, withStateHandlers } from 'recompose';
import { exportResults } from '~/helpers/course'
import { RESULT_STATUS_COMPLETE } from '~/appConstants';
import '~/styles/MonitoringStudentsList.scss';
import Text from '~/components/Text';
import Button from '~/components/Button';
import ProgressBar from '~/components/ProgressBar';
import Icon from '~/components/Icon';

const { array, object, func } = PropTypes;

const Row = ({ children, widths }) => (
  <div className="Row">
    {React.Children.map(children, (element, i) => (
      <div className="cell" style={{ width: widths[i], }}>
        {element}
      </div>
    ))}
  </div>
);

Row.propTypes = {
  children: array.isRequired,
  widths: array.isRequired,
};

const headerWidths = [277, 40 + 120, 90, 120];
const studentWidths = [277, 40, 90, 45, 75, 40, 120, 20];
const lessonWidths = [277, 40 + 95, 40, 75, 40, 124];

const MonitoringStudentsList = ({
  // name, 
  dates,
  students,
  opened,
  toggleOpened,
  publications,
  t,
}) => (
  <div className="MonitoringStudentsList">
    {!!students.length && (
      <Row widths={headerWidths}>
        <Text
          variant="monitoring-table-header"
          value={t('monitoringStudentsList.students')}
        />
        <Text
          variant="monitoring-table-header"
          value={t('monitoringStudentsList.lessonsLearned')}
        />
        <Text
          variant="monitoring-table-header"
          value={t('monitoringStudentsList.time')}
        />
        <Text
          variant="monitoring-table-header"
          value={t('monitoringStudentsList.result')}
        />
      </Row>
    )}
    {students.filter((student) => {
      const [start, end] = dates
      if (!start || !end) return true
      const results = Object.values(student.results)
      return results.length && results.every((result) => {
        const date = new Date(+result.date)
        return date >= start && date <= end
      })
    }).map((student, index) => (
      <div key={index} onClick={() => toggleOpened(student.email)}>
        <div className="card">
          <Row widths={studentWidths}>
            <div>
              <Text variant="monitoring-list" value={student.name} />
              <Text
                variant={['monitoring-list', 'mini', 'light']}
                value={student.email}
              />
            </div>
            <div className="Flex">
              <Text
                variant="monitoring-list"
                value={student.completedResultsSize}
              />
              <Text
                variant={['monitoring-list', 'light']}
                value={`/${publications.length}`}
              />
            </div>
            <ProgressBar value={student.percentCompleted} />

            <Text
              variant={['monitoring-list', 'time']}
              value={student.executionTime}
            />
            <Text
              variant={['monitoring-list', 'light']}
              value={t('monitoringStudentsList.min')}
            />
            <div className="Flex -right">
              <Text
                variant="monitoring-list"
                value={Math.round((student.percentResult || 0) * 100)}
              />
              <Text variant={['monitoring-list', 'light']} value="%" />
            </div>
            <ProgressBar value={student.percentResult} />
            <Icon
              name="chevron-down"
              variant={['table-expander', opened[student.email] && 'open']}
            />
          </Row>
        </div>
        <Collapse isOpen={opened[student.email]}>
          {publications.filter((publication) => {
            const [start, end] = dates
            if (!start || !end) return true
            const result = student.results[publication.lessonId]
            if (!result) return false
            const date = new Date(+result.date)
            return date >= start && date <= end
          }).map(({ lessonId }, i) => {
            const result = student.results[lessonId];
            const isComplete = result?.status === RESULT_STATUS_COMPLETE;
            return (
              <div className="lesson" key={lessonId}>
                <Row widths={lessonWidths}>
                  <Text
                    variant={[
                      'monitoring-list',
                      !isComplete && 'light',
                      'mini',
                    ]}
                    value={`${i + 1} ${t('monitoringStudentsList.lesson')}`}
                  />
                  {isComplete && (
                    <Text
                      variant={['monitoring-list', 'mini']}
                      value={`${t('monitoringStudentsList.lessonStatus.completed')} ${
                        result ? `${moment(result.date).format('DD.MM.YY в hh:mm')}` : ''
                      }`}
                    />
                  )}
                  {isComplete && (
                    <Text
                      variant={['monitoring-list', 'mini', 'time']}
                      value={result.executionTime}
                    />
                  )}
                  {isComplete && (
                    <Text
                      variant={['monitoring-list', 'light', 'mini']}
                      value={t('monitoringStudentsList.min')}
                    />
                  )}
                  {isComplete && (
                    <div className="Flex -right">
                      <Text
                        variant={['monitoring-list', 'mini']}
                        value={Math.round(result.value * 100)}
                      />
                      <Text
                        variant={['monitoring-list', 'light', 'mini']}
                        value="%"
                      />
                    </div>
                  )}
                  {isComplete && <ProgressBar value={result.value} />}
                </Row>
              </div>
            );
          })}
        </Collapse>
      </div>
    ))}
    <div className="export">
      <Button
        text="Выгрузить результаты"
        onClick={() => exportResults(students, publications, 'Мониторинг')}
      />
    </div>
  </div>
);

MonitoringStudentsList.propTypes = {
  // name: string,
  dates: array.isRequired,
  students: array.isRequired,
  publications: array.isRequired,
  opened: object.isRequired,
  toggleOpened: func.isRequired,
  t: func.isRequired,
};

export default compose(
  withTranslation('containers'),
  withStateHandlers(
    { opened: {} },
    {
      toggleOpened: ({ opened }) => (id) => ({
        opened: {
          ...opened,
          [id]: !opened[id],
        },
      }),
    }
  )
)(MonitoringStudentsList);
