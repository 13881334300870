import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Dialog.scss';
import '../styles/Backdrop.scss';

const Dialog = ({
  isOpen,
  onClose,
  variant,
  closable,
  children,
  usePortal,
  canEscapeKeyClose,
}) => (
  <Blueprint.Dialog
    isOpen={isOpen}
    onClose={onClose}
    usePortal={usePortal}
    lazy={false}
    transitionDuration={300}
    transitionName="dialog-transition"
    portalClassName={combineStyles('Portal', variant)}
    backdropClassName={combineStyles('Backdrop', variant)}
    className={combineStyles('Dialog', variant)}
    canOutsideClickClose={closable}
    canEscapeKeyClose={canEscapeKeyClose}
    enforceFocus={false}
  >
    {children}
  </Blueprint.Dialog>
);

const { bool, oneOfType, array, string, func, any } = PropTypes;

Dialog.propTypes = {
  isOpen: bool, // Открыт?
  variant: oneOfType([array, string]), // Вариант оформления
  onClose: func, // Действие при закрытии
  closable: bool, // Можно закрыть по клику снаружи?
  children: any.isRequired, // Внутренний контент
  usePortal: bool, // Рендерить в портале
  canEscapeKeyClose: bool, // Закрывается по esc?
};

Dialog.defaultProps = {
  isOpen: false,
  onClose: undefined,
  variant: undefined,
  closable: false,
  usePortal: true,
  canEscapeKeyClose: false,
};

export default pure(Dialog);
