import React from 'react';
import PropTypes from 'prop-types';

import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';

import { REQUEST_NAME_RESULT_DOWNLOADING } from '~/appConstants';
import * as selectors from '~/selectors';
import { UserResults } from '~/containers';
import DotLoader from '~/components/DotLoader';
import { combineStyles } from '~/utils/styles';
import { isRequestActive } from '~/selectors/ui';
import '~/styles/Folder.scss';

const ScreenStudyLessons = ({ isMobile, router, allResults }) => {
  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_RESULT_DOWNLOADING)
  );

  return isLoading ? (
    <DotLoader className="center" />
  ) : (
    <div className={combineStyles('Folder', isMobile && 'mobile')}>
      <UserResults router={router} allResults={allResults} />
    </div>
  );
};

const { object, bool } = PropTypes;

ScreenStudyLessons.propTypes = {
  isMobile: bool.isRequired,
  router: object.isRequired,
  allResults: object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { history: router } = ownProps;
  const allResults = selectors.study.getResults(state);

  return {
    router,
    allResults,
  };
};
export default compose(
  withMobileDetect,
  connect(mapStateToProps)
)(ScreenStudyLessons);
