import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withProps, lifecycle, compose, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Icon, Text, Button, Popover, Input } from '../../components';
import { Image } from '../../components/Block';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import '../../styles/Block/Images.scss';

const Images = ({
  t,
  theme,
  blockId,
  fontSize,
  changeURL,
  collection,
  inSafeMode,
  inEditMode,
  inBirdView,
  removeImage,
  inPreviewMode,
  inPresentation,
  changeDescription,
  updateBlockInPresentation,
}) => (
  <div className="Images">
    <div
      className={combineStyles('images', [
        `quantity-${Object.keys(collection).length}`,
        inBirdView && 'bird-view',
      ])}
    >
      {collection ? (
        Object.entries(collection).map((item, index) => (
          <div className="image" key={index}>
            <Image
              key={index}
              theme={theme}
              blockId={blockId}
              imageId={item[0]}
              fontSize={fontSize}
              changeURL={changeURL}
              width={item[1].width}
              inEditMode={inEditMode}
              inSafeMode={inSafeMode}
              source={item[1].source}
              removeImage={removeImage}
              inPreviewMode={inPreviewMode}
              inPresentation={inPresentation}
              description={item[1].description}
              changeDescription={changeDescription}
              imagesQuantity={Object.keys(collection).length}
              updateBlockInPresentation={updateBlockInPresentation}
              variant={[
                `multi-${Object.keys(collection).length}`,
                inBirdView && 'bird-view',
              ]}
              imagesList={Object.entries(collection).map(
                (image) => image[1].source
              )}
            />
          </div>
        ))
      ) : (
        <div className="empty">
          <div className="icon">
            <Icon name="osh-imageupload" />
          </div>
          <div className="text">
            <Text value={t('images.notUploaded')} variant="empty-block-text" />
          </div>
        </div>
      )}
    </div>
  </div>
);

const { string, object, bool, func } = PropTypes;

Images.propTypes = {
  t: func.isRequired, // Функция перевода
  inSafeMode: bool, // В режиме ОВЗ?
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  blockId: string.isRequired, // ID блока
  changeURL: func.isRequired, // Экшн смены урла картинки
  collection: object, // Выбранные ответы
  inEditMode: bool, // В режиме редактирования?
  inBirdView: bool, // Блок на странице в режиме bird view
  removeImage: func.isRequired, // Очистка картинки
  inPreviewMode: bool, // В режиме просмотра?
  inPresentation: bool, // В презентации
  updateBlockInPresentation: func, // Обновление страницы в презентации
  changeDescription: func.isRequired, // Поменять описание изображения
};

Images.defaultProps = {
  inSafeMode: undefined,
  theme: 'light',
  fontSize: 'small',
  collection: undefined,
  inEditMode: undefined,
  inBirdView: undefined,
  inPreviewMode: undefined,
  inPresentation: undefined,
  updateBlockInPresentation: undefined,
};

const additionalControls = (properties) => {
  const { cleanImages, collection, changeQuantity } = properties;
  return (
    <div className="buttons">
      <Button
        minimal
        icon="osh-clearcontent"
        variant="remove-image"
        onClick={cleanImages}
      />
      <Popover
        icon="osh-multiimage"
        position="bottom"
        variant="images-quantity"
        target={
          <Button icon="osh-multiimage" variant="block-controls-toggler" />
        }
        content={
          <div className="input">
            <Input
              type="numeric"
              value={Object.keys(collection).length}
              minValue={1}
              maxValue={4}
              variant="images-quantity"
              onChange={changeQuantity}
            />
          </div>
        }
      />
    </div>
  );
};

const enhance = compose(
  withHandlers({
    cleanImages: ({ removeImage, blockId, collection }) => () =>
      Object.entries(collection).map((item) => removeImage(item[0], blockId)),
    changeQuantity: ({ changeQuantity, blockId, collection }) => (count) =>
      changeQuantity(count, blockId, collection),
  }),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      this.props.setControls(additionalControls(this.props));
    },
    componentDidUpdate(prevProps) {
      if (prevProps.collection !== this.props.collection) {
        this.props.setControls(additionalControls(this.props));
      }
    },
  }),
  withProps(
    ({
      theme,
      inPlayer,
      fontSize,
      inBuilder,
      inSafeMode,
      inEditMode,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        inEditMode ||
        (inBuilder && !inLibrarySearch) ||
        inLibraryEdit ||
        inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
      inSafeMode,
      fontSize,
      theme,
    })
  )
);

const mapStateToProps = (state, ownProps) => {
  const { lessonId, collection, setControls } = ownProps;
  const inBirdView = selectors.builder.lesson.inBirdView(lessonId, state);
  return {
    inBirdView,
    collection,
    setControls,
  };
};

const mapDispatchToProps = {
  changeURL: actions.builder.block.images.changeURL,
  removeImage: actions.builder.block.images.removeImage,
  changeQuantity: actions.builder.block.images.changeQuantity,
  changeDescription: actions.builder.block.images.changeDescription,
};

export default compose(
  withTranslation('containersBlock'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Images);
