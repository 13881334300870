import { set, omit, compose } from 'lodash/fp';
import * as FOLDER from '../../constants/builder/folder';
import * as BUILDER from '../../constants/builder';

export const defaultState = {};

const createFolder = (creatorId, createdAt) => ({
  name: '', // Имя папки
  isEditMode: true, // В режиме редактирования?
  creatorId, // ID создателя папки
  createdAt, // Время создания папки
});

const foldersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FOLDER.ADD: {
      const {
        payload: { id },
        meta: { creatorId, createdAt },
      } = action;
      return set(id, createFolder(creatorId, createdAt), state);
    }

    case FOLDER.EDIT: {
      const { id } = action.payload;
      return set([id, 'isEditMode'], true, state);
    }

    case FOLDER.RENAME: {
      const { id, newName } = action.payload;
      return compose(
        set([id, 'name'], newName),
        set([id, 'isEditMode'], false)
      )(state);
    }

    case FOLDER.REMOVE_ACCEPT: {
      const { id } = action.payload;
      return omit(id, state);
    }

    case BUILDER.DOWNLOAD_LESSONS_SUCCESS: {
      const { downloadedFolders } = action.payload;
      return downloadedFolders;
    }

    default:
      return state;
  }
};

export default foldersReducer;
