import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withContext, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Text,
  Icon,
  Input,
  Button,
  Dialog /* Checkbox */,
} from '../../components';
import * as actions from '../../actions';
import '../../styles/Library/Share.scss';
import Block from '~/containers/Block';

const Share = ({
  t,
  close,
  dates,
  terms,
  themes,
  isOpen,
  blockId,
  persons,
  // subject,
  saveAndExit,
  changeTerms,
  changeDates,
  changeThemes,
  changePersons,
  // changeSubject,
}) => (
  <Dialog isOpen={isOpen} onClose={close} variant="library" className="Library">
    <Button
      text={t('share.close')}
      icon="osh-remove"
      onClick={close}
      variant={['dialog-close', 'library']}
    />
    <div className="addition-library">
      <div className="border" />
      <div className="title">
        <Text value={t('share.addBlock')} />
      </div>
      <div className="field">
        <div className="label">
          <div className="icon">
            <Icon symbol={1} variant="library-field-label-icon" />
          </div>
          <div className="text">
            <Text
              value={t('share.editContent')}
              variant="library-field-label-text"
            />
          </div>
        </div>
        <div className="block">
          <Block id={blockId} />
        </div>
      </div>
      {/*
      <div className="field">
        <div className="label">
          <div className="icon">
            <Icon
              symbol={2}
              variant="library-field-label-icon"
            />
          </div>
          <div className="text">
            <Text
              value="Выберите предмет, к которому относится блок"
              variant="library-field-label-text"
            />
          </div>
        </div>
        <div className="block">
          {['История России', 'Математика', 'Физика'].map((subjectName, subjectIndex) => (
            <Checkbox
              key={subjectIndex}
              type="radio"
              label={subjectName}
              // onCheck={() => changeSubject(subjectName)}
              checked={subjectName === 'История России'}
              disabled={subjectName !== 'История России'}
              variant="library-search-sidebar-filters-checkbox"
            />
          ))}
        </div>
      </div>
      */}
      {[
        {
          name: t('edit.theme'),
          index: 2,
          value: themes,
          label: t('edit.themes'),
          onChange: changeThemes,
          placeholder: t('edit.themeDescrption'),
        },
        {
          name: t('edit.persona'),
          index: 3,
          value: persons,
          label: t('edit.persons'),
          onChange: changePersons,
          placeholder: t('edit.personaDescrption'),
        },
        {
          name: t('edit.term'),
          index: 4,
          value: terms,
          label: t('edit.terms'),
          onChange: changeTerms,
          placeholder: t('edit.termDescrption'),
        },
        {
          name: t('edit.date'),
          index: 5,
          label: t('edit.dates'),
          value: dates,
          onChange: changeDates,
          placeholder: t('edit.dateDescrption'),
        },
      ].map((field) => (
        <div className="field" key={field.index}>
          <div className="label">
            <div className="icon">
              <Icon symbol={field.index} variant="library-field-label-icon" />
            </div>
            <div className="text">
              <Text value={field.label} variant="library-field-label-text" />
            </div>
          </div>
          <div className="input">
            <Input
              name={field.name}
              value={field.value}
              variant="library-field-input"
              onChange={field.onChange}
              withTags
              placeholder={field.placeholder}
            />
          </div>
        </div>
      ))}
      <div className="add-block">
        <Button
          text={t('edit.addToLibrary')}
          variant="library-share-add-block"
          onClick={saveAndExit}
        />
      </div>
    </div>
  </Dialog>
);

const { string, bool, array, func } = PropTypes;

Share.propTypes = {
  t: func.isRequired, // Функция перевода
  close: func.isRequired, // Закрыть библиотеку
  dates: array, // Даты, связанные с блоком
  terms: array, // Термины, связанные с блоком
  themes: array, // Темы, связанные с блоком
  isOpen: bool.isRequired, // Библиотека открыта?
  // subject: string, // Тема урока, в которому относится блок
  blockId: string, // ID блока
  persons: array, // Персоны, связанные с блоком
  saveAndExit: func, // Функция сохранения блока и закрытия окна
  changeDates: func.isRequired, // Изменить даты блока
  changeTerms: func.isRequired, // Изменить термины блока
  changeThemes: func.isRequired, // Изменить темы блока
  changePersons: func.isRequired, // Изменить персон блока
  // changeSubject: func.isRequired, // Изменить тему блока (тема урока)
};

Share.defaultProps = {
  dates: [],
  terms: [],
  themes: [],
  blockId: undefined,
  persons: [],
  saveAndExit: undefined,
};

const mapStateToProps = (state) => {
  const {
    id,
    dates,
    terms,
    themes,
    subject,
    persons,
  } = state.library.share.block;
  const { isOpen } = state.library.share;
  return {
    dates,
    terms,
    themes,
    isOpen,
    subject,
    persons,
    blockId: id,
  };
};

const mapDispatchToProps = {
  save: actions.library.share.save,
  close: actions.library.share.close,
  changeDates: actions.library.share.changeDates,
  changeTerms: actions.library.share.changeTerms,
  changeThemes: actions.library.share.changeThemes,
  changePersons: actions.library.share.changePersons,
  changeSubject: actions.library.share.changeSubject,
};

const enhance = withContext({ inLibraryShare: bool.isRequired }, () => ({
  inLibraryShare: true,
}));

const handlersEnhance = withHandlers({
  saveAndExit: (props) => () => {
    props.save();
    props.close();
  },
});

export default compose(
  withTranslation('containersLibrary'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance
)(enhance(Share));
