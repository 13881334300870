import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { SelectLocale } from '..';
import { Quote, Text } from '../../components';
import { combineStyles } from '../../utils/styles';
import '../../styles/Auth/PassRecovery.scss';

const RecoverySent = ({ t, isMobile }) => (
  <div className={combineStyles('PassRecovery', isMobile && 'mobile')}>
    <SelectLocale variant="in-auth" />
    <Quote />
    <div className="content">
      <span className="email" role="img" aria-label="email">
        ✉️
      </span>
      <Text
        value={t('recovery.recoverySentTitle')}
        variant="pass-recovery-title"
      />
      <Text value={t('recovery.recoverySent')} variant="pass-recovery-text" />
    </div>
  </div>
);

const { func, bool } = PropTypes;

RecoverySent.propTypes = {
  t: func.isRequired,
  isMobile: bool.isRequired,
};

export default compose(
  withTranslation('containersAuth'),
  withMobileDetect
)(RecoverySent);
