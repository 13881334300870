import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { pure, compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import NoSSR from 'react-no-ssr';
import { get } from 'lodash/fp';

import withMobileDetect from '~/hocs/withMobileDetect';

import {
  COURSE_STATUS_ACTIVE,
  COURSE_STATUS_FINISHED,
  COURSE_STATUS_NOT_ACTIVE,
  RESULT_STATUS_COMPLETE,
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { combineStyles } from '../utils/styles';
import { TLesson, TPublication } from '../@types';
import Alert from '~/containers/player/Alert';
import Icon from '~/components/Icon';
import Text from '~/components/Text';
import LinesTruncate from '~/components/LinesTruncate';
import '../styles/PlayerLessonThumbnail.scss';

const PlayerLessonThumbnail = ({
  t,
  index,
  result,
  lesson,
  lessonId,
  isMobile,
  lessonType,
  isUserActive,
  showNotice,
  courseStatus,
  isExamPassed,
  // reexecuteLesson,
  isLessonBlocked,
  isLoggedIn,
  publication: { number },
}) => {
  
  const [isShowBlockedAlert, toggleShowBlockedAlert] = useState(false);
  const isCourseActive = courseStatus === COURSE_STATUS_ACTIVE;

  const status = result?.status || 'published';
  const isComplete = result?.status === RESULT_STATUS_COMPLETE;
  const Root =
    isUserActive && !isComplete && isCourseActive && !isLessonBlocked
      ? Link
      : 'div';

  const iconLessonType = {
    [LESSON_TYPE_EXAM]: 'osh-lesson-exam',
    [LESSON_TYPE_REGULAR]: 'osh-lesson-regular',
    [LESSON_TYPE_OLYMPIAD]: 'osh-lesson-olympiad',
  };

  if (!lesson) return null
  const {
    meta: { type },
    content: { name },
  } = lesson
  return (
    <Root
      className={combineStyles('PlayerLessonThumbnail', [
        isMobile && 'mobile',
        isUserActive && 'clickable',
        status,
        isLessonBlocked && 'blocked',
      ])}
      // ToDo у нас есть сразу ID публикации, мб сделать роут который принимает его вместо lessonId + number
      to={
        isCourseActive
          ? isComplete
            ? `/player/resume/${result.id}`
            : `/player/lesson/${lessonId}/${number}`
          : null
      }
      onClick={
        isUserActive && !isCourseActive && !isComplete
          ? {
              [COURSE_STATUS_NOT_ACTIVE]: () =>
                showNotice(t('playerlessonthumbnails.courseNotActive')),
              [COURSE_STATUS_FINISHED]: () =>
                showNotice(t('playerlessonthumbnails.courseFinished')),
            }[courseStatus]
          : undefined
      }
    >
      {isLessonBlocked && (
        <div
          className="lesson-block"
          onClick={() => isLoggedIn && toggleShowBlockedAlert(true)}
        />
      )}

      <div className={combineStyles('index', type)}>{index + 1}</div>
      <div className="content">
        {isMobile ? (
          <LinesTruncate
            text={name}
            variant={[
              'player-lesson-thumbnail-name',
              `player-lesson-thumbnail-name-${status}`,
            ]}
            maxLines={2}
          />
        ) : (
          <Text
            value={name}
            variant={`player-lesson-thumbnail-name-${status}`}
          />
        )}
        {lessonType === LESSON_TYPE_EXAM && isComplete ? (
          <Text
            value={`${t(
              `playerlessonthumbnails.${
                isExamPassed ? 'complete' : 'examNotPassed'
              }`
            )}`}
            variant={`player-lesson-thumbnail-status-${
              isExamPassed ? 'complete' : 'notpassed'
            }`}
          />
        ) : (
          status !== 'published' && (
            <Text
              value={`${t(`playerlessonthumbnails.${status}`)}`}
              variant={`player-lesson-thumbnail-status-${status}`}
            />
          )
        )}
      </div>
      {lessonType && (
        <div className={combineStyles('type', isLessonBlocked && 'blocked')}>
          <Icon
            name={iconLessonType[lessonType]}
            variant="lesson-thumbnail-type"
          />
        </div>
      )}
      {isLessonBlocked && (
        <div className="lock">
          <Icon name="lock" />
        </div>
      )}
      {isComplete && !isLessonBlocked && (
        <NoSSR>
          <div className="buttons">
            <Link to={`/player/resume/${result.id}`}>
              {t('playerlessonthumbnails.toResults')}
            </Link>
            {/* {isCourseActive && (
              <button type="button" onClick={reexecuteLesson}>
                {t('playerlessonthumbnails.replay')}
              </button>
            )} */}
          </div>
        </NoSSR>
      )}

      {isShowBlockedAlert && (
        <Alert
          isOpen={isShowBlockedAlert}
          onCloseWindow={toggleShowBlockedAlert}
          isLessonBlocked={isLessonBlocked}
          isAuthAfterComplete={false}
        />
      )}
    </Root>
  );
};

const { func, bool, string, number, shape } = PropTypes;

PlayerLessonThumbnail.propTypes = {
  t: func.isRequired,
  // reexecuteLesson: func.isRequired,
  lessonId: string.isRequired,
  index: number.isRequired,
  isUserActive: bool.isRequired,
  isMobile: bool.isRequired,
  isLoggedIn: bool.isRequired,
  lesson: TLesson, // TODO: что же это ?)
  result: shape({
    id: string.isRequired,
    status: string.isRequired,
    correctCount: number.isRequired,
    executionTime: number.isRequired,
    isPassed: bool.isRequired,
    lessonId: string.isRequired,
    publication: number.isRequired,
    totalCount: number.isRequired,
    userId: string.isRequired,
  }),
  lessonType: string,
  showNotice: func.isRequired,
  isExamPassed: bool,
  isLessonBlocked: bool,
  courseStatus: string.isRequired,
  publication: TPublication,
};

const mapStateToProps = (state, ownProps) => {
  const publication = selectors.course.getPublication(ownProps.id)(state);
  const lessonId = get(['lessonId'], publication);
  const lesson = get(['content', 'lesson', lessonId], publication);
  const lessonType = get(['meta', 'type'], lesson);
  const result = selectors.course.getResultByLessonId(lessonId, state);

  return {
    lesson,
    result,
    lessonId,
    lessonType,
    publication,
  };
};

const mapDispatchToProps = {
  showNotice: actions.notice.show,
  reexecuteLesson: actions.player.lesson.reexecute,
};

export default compose(
  withTranslation('components'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withHandlers({
    reexecuteLesson: ({ lessonId, reexecuteLesson, publication }) => () => {
      reexecuteLesson({ lessonId, publication: publication.number });
    },
    showNotice: ({ showNotice }) => (message) => showNotice(message, 'error'),
  }),
  withProps(({ publication }) => ({
    isLessonBlocked: publication.isBlocked,
    isExamPassed: publication.isExamPassed,
  })),
  pure
)(PlayerLessonThumbnail);
