import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';

import * as s from './styles/Review.styles';

const ReviewLine = ({ answerBlock, selected }) => {
  const { question, courseName, lessonName, studentName } = answerBlock;

  return (
    <div css={s.line} selected={selected}>
      <div css={s.user}>
        <div css={s.userName}>{studentName}</div>
      </div>
      <div css={s.center}>
        <div css={s.question}>{question}</div>
        <div css={s.breadcrumbs}>
          {courseName && (
            <>
              {courseName}
              <span>‣</span>
            </>
          )}
          {lessonName}
        </div>
      </div>
    </div>
  );
};

const { bool, object } = PropTypes;

ReviewLine.propTypes = {
  selected: bool,
  answerBlock: object,
};

export default pure(ReviewLine);
