import {
  assign,
  compose,
  concat,
  get,
  includes,
  merge,
  omit,
  set,
  update,
} from 'lodash/fp';

import quizReducer, { defaultState as Quiz } from './quiz';
import examReducer, { defaultState as Exam } from './exam';
import matchReducer, { defaultState as Match } from './match';
import answerReducer, { defaultState as Answer } from './answer';
import imagesReducer, { defaultState as Images } from './images';
import feedbackReducer, { defaultState as Feedback } from './feedback';
import documentReducer, { defaultState as Document } from './document';
import fillblankReducer, { defaultState as FillBlank } from './fillblank';
import talkReducer, { defaultState as Talk } from './talk';

import uploaderReducer from '../../uploader';

import * as PAGE from '../../../constants/builder/page';
import * as BLOCK from '../../../constants/builder/block';
import * as LESSON from '../../../constants/builder/lesson';
import * as BUILDER from '../../../constants/builder';
import * as UPLOADER from '../../../constants/uploader';

export const defaultState = {};

const blocksReducersMap = {
  Exam: examReducer,
  Quiz: quizReducer,
  Match: matchReducer,
  Answer: answerReducer,
  Images: imagesReducer,
  Survey: quizReducer,
  Weight: quizReducer,
  Document: documentReducer,
  FillBlank: fillblankReducer,
  Feedback: feedbackReducer,
  Talk: talkReducer,
};

const createBlock = (type, content) => ({
  type,
  content:
    content ||
    {
      Quiz,
      Exam,
      Text: {},
      Match,
      Answer,
      Weight: Quiz,
      Images,
      Instruction: {
        type: 'info-sign', // Внутренний тип блока
        selectedBlock: 'instruction.info', // Текущий выбранный блок
      },
      Video: {},
      Embed: {},
      VR: {},
      Document,
      Survey: Quiz,
      FillBlank,
      Feedback,
      Talk,
    }[type],
});

const applyUploaderReducer = (blockId, state, action, filePath) =>
  set(
    filePath ? [...filePath, 'uploader'] : [blockId, 'content', 'uploader'],
    uploaderReducer(
      get(
        filePath ? [...filePath, 'uploader'] : [blockId, 'content', 'uploader'],
        state
      ),
      action
    )
  );

const blocksReducer = (state = defaultState, action) => {
  switch (action.type) {
    // -------------- Блоки --------------

    case BLOCK.ADD: {
      const { payload, meta } = action;
      const content =
        meta && meta.content
          ? merge(payload.content, meta.content)
          : payload.content;
      return set(payload.id, createBlock(payload.type, content), state);
    }

    case BLOCK.CLONE: {
      const {
        meta: { clonedBlock },
      } = action;
      return assign(state, clonedBlock);
    }

    case BLOCK.CLEAN: {
      const { id } = action.payload;
      return set(id, createBlock(get([id, 'type'], state)), state);
    }

    case BLOCK.REMOVE: {
      const { id } = action.payload;
      return omit(id, state);
    }

    case BLOCK.CHANGE_COMMENT: {
      const { id, type, comment } = action.payload;
      return set([id, 'content', 'comment', type], comment, state);
    }

    case BLOCK.CHANGE_CONTENT: {
      const { id, newContent } = action.payload;
      return get(['meta', 'context', 'inBuilder'], action)
        ? update(
            [id, 'content'],
            (oldContent) => assign(oldContent, newContent),
            state
          )
        : state;
    }

    case String(action.type.match(/@builder\/block\/.*/)): {
      const { blockId } = action.payload;
      const inBuilder = get(['meta', 'context', 'inBuilder'], action);

      if (!inBuilder || !blockId) {
        return state;
      }

      const blockType = get([blockId, 'type'], state);
      return update(
        [blockId, 'content'],
        (content) => blocksReducersMap[blockType](content, action),
        state
      );
    }

    // -------------- Загрузчик файлов --------------

    case UPLOADER.UPLOAD_SUCCESS: {
      const {
        objectId,
        source,
        filePath,
        uploadType,
        pathLastName,
        withFileName,
        inPlayer,
      } = action.payload;

      if (uploadType === 'lesson' || uploadType === 'course') return state;

      const updateFilePath = pathLastName
        ? concat([...filePath], pathLastName)
        : [...filePath];

      const fileName = source
        .replace(/^.*[/]/, '') // Вычленение имени файла
        .replace(/^[^-](.*?)(?=-|$)[-]/, '') // Обрезание хеша
        .replace(/[.][^.]*$/, ''); // Обрезание расширения

      if (inPlayer) {
        return state;
      } else {
        return compose(
          set(
            updateFilePath
              ? [...updateFilePath, 'fileName']
              : [objectId, 'content', 'fileName'],
            withFileName ? fileName : undefined
          ),
          set(
            updateFilePath
              ? [...updateFilePath, 'source']
              : [objectId, 'content', 'source'],
            source
          ),
          applyUploaderReducer(objectId, state, action, updateFilePath)
        )(state);
      }
    }

    case String(action.type.match(/@uploader\/.*/)): {
      const { objectId, filePath, uploadType } = action.payload;

      if (uploadType === 'lesson' || uploadType === 'course') return state;

      /* updateFilePath - путь до ссылки на клиенте
      задается в качестве массива в параметрах компонента Uploader,
      если внутри массива есть 'execution' - загрузка выполнена из плеера
      */
      if (includes('execution', filePath)) {
        return state;
      } else {
        return applyUploaderReducer(objectId, state, action, filePath)(state);
      }
    }

    // -------------- Страницы --------------

    case PAGE.CLONE: {
      const {
        meta: { clonedBlocks },
      } = action;
      return assign(state, clonedBlocks);
    }

    case PAGE.PASTE: {
      const {
        meta: { storedBlocks },
      } = action;
      return assign(state, storedBlocks);
    }

    case PAGE.REMOVE: {
      const {
        meta: { removedBlocksIds },
      } = action;
      return omit(removedBlocksIds, state);
    }

    // -------------- Уроки --------------

    case LESSON.CLONE: {
      const {
        meta: { clonedBlocks },
      } = action;
      return assign(state, clonedBlocks);
    }

    case LESSON.REMOVE_SUCCESS: {
      const {
        meta: { removedBlocksIds },
      } = action;
      return omit(removedBlocksIds, state);
    }

    case LESSON.IMPORT_FROM_PLAYER: {
      const {
        meta: { clonedBlocks },
      } = action;
      return assign(clonedBlocks, state);
    }

    // -------------- Конструктор --------------

    case BUILDER.DOWNLOAD_LESSON_SUCCESS: {
      const { downloadedBlocks } = action.payload;
      return assign(downloadedBlocks, state);
    }

    case BUILDER.DOWNLOAD_COURSE_SUCCESS:
    case BUILDER.DOWNLOAD_LESSONS_SUCCESS: {
      const { downloadedBlocks } = action.payload;
      return downloadedBlocks;
    }

    // -------------- ????? --------------

    default:
      return state;
  }
};

export default blocksReducer;
