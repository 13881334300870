import { put, take, call } from 'redux-saga/effects';
import * as LIBRARY from '../../../constants/library';
import * as actions from '../../../actions';
import request from '../../request';

const api = `${process.env.API_URL}/block/delete`;

/**
 * Удаление блока из библиотеки
 */
export default function* remove() {
  while (true) {
    const {
      payload: { blockId },
    } = yield take(LIBRARY.EDIT.REMOVE_REQUEST);
    const data = { id: blockId };
    const response = yield call(request, api, data);
    if (response) {
      const { error } = response;
      if (!error) {
        yield put(actions.library.edit.removeSuccess());
      } else {
        yield put(actions.library.edit.removeError());
      }
    }
  }
}
