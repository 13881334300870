import React from 'react';
import PropTypes from 'prop-types';

import NoSSR from 'react-no-ssr';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import formatDate from 'date-fns/format';
import { compose, withProps } from 'recompose';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '~/utils/styles';
import DotLoader from '~/components/DotLoader';
import Text from '~/components/Text';
import Help from '~/components/Help';
import HelpRow from '~/components/HelpRow';

const formatLessonDate = ({ label, date }) =>
  date ? `${label} ${formatDate(date * 1000, 'DD.MM.YYYY, HH:mm')}` : '';

const Closed = ({ t, isMobile, lessonStartDate, lessonEndDate }) => (
  <div
    className={combineStyles('Player', [isMobile && 'mobile', 'out-of-dates'])}
  >
    <Helmet>
      <title>{t('index.unavailable')}</title>
    </Helmet>
    <div className="image" />
    <div className="title">
      <Text value={t('index.unavailable')} variant="text-head-dark" />
    </div>
    <NoSSR
      onSSR={
        <div className="info">
          <DotLoader />
        </div>
      }
    >
      {(lessonStartDate || lessonEndDate) && (
        <div className="info">
          <Text
            value={`${t(
              'index.openAccess'
            )} ${lessonStartDate} ${lessonEndDate}`}
            variant="text-head-light"
          />
        </div>
      )}
    </NoSSR>
    {isMobile ? (
      <HelpRow inPlayer />
    ) : (
      <Help inPlayer fixed variant="help-in-folder" />
    )}
  </div>
);

const { bool, func, string } = PropTypes;

Closed.propTypes = {
  t: func.isRequired,
  lessonStartDate: string.isRequired,
  lessonEndDate: string.isRequired,
  isMobile: bool.isRequired,
};

export default compose(
  withTranslation('containersPlayer'),
  withMobileDetect,
  withProps(({ t, lesson: { meta: { startDate, endDate } } }) => ({
    lessonStartDate: formatLessonDate({
      label: t('index.from'),
      date: startDate,
    }),
    lessonEndDate: formatLessonDate({
      label: t('index.to'),
      date: endDate,
    }),
  }))
)(Closed);
