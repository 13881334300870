import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { getContext, withHandlers } from 'recompose';
import { compose, get, indexOf, keys, isEmpty } from 'lodash/fp';
import withTheming from '~/hocs/withTheming';

import { combineStyles } from '../utils/styles';
import { intersect } from '../utils/array';
import * as context from '../utils/context';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { Stub } from '../components';
import '../styles/Blocks.scss';
import Block from '~/containers/Block';
import { LESSON_TYPE_OLYMPIAD } from '~/appConstants';

const Blocks = ({
  ids,
  theme,
  pageId,
  variant,
  lessonId,
  getOrder,
  fontSize,
  inBuilder,
  moveBlock,
  isInModal,
  inSafeMode,
  inBirdView,
  inExamMode,
  isOlympiadMode,
  isLessonComplete,
}) => {
  const location = useLocation();
  const emphasizedBlockId = location.hash.slice(1);

  useEffect(() => {
    const blockElement = document.getElementById(emphasizedBlockId);
    blockElement && setTimeout(() => blockElement.scrollIntoView(), 0);
  }, [ids, emphasizedBlockId]);

  return (
    <div
      className={combineStyles('Blocks', [
        variant,
        isInModal && 'in-modal',
        inSafeMode && [fontSize, theme],
        inBuilder && 'constructor',
        inBirdView && 'bird-view',
        inExamMode && 'exam-mode',
      ])}
    >
      {ids.map((blockId) => (
        <div
          id={blockId}
          key={blockId}
          style={{ order: getOrder(blockId) }}
          className="block"
        >
          <Block
            id={blockId}
            theme={theme}
            pageId={pageId}
            variant={variant}
            lessonId={lessonId}
            fontSize={fontSize}
            onDragEnd={moveBlock}
            inSafeMode={inSafeMode}
            index={getOrder(blockId)}
            isOlympiadMode={isOlympiadMode}
            isLessonComplete={isLessonComplete}
            isEmphasized={blockId === emphasizedBlockId}
          />
        </div>
      ))}
      {inBuilder && (
        <div className="stub" style={{ order: ids.length }}>
          <Stub type="Block" index={ids.length} pageId={pageId} />
        </div>
      )}
    </div>
  );
};

const { bool, arrayOf, string, oneOfType, array, func } = PropTypes;

Blocks.propTypes = {
  theme: string.isRequired, // Цветовая тема в режиме ОВЗ
  fontSize: string.isRequired, // Размер шрифта в режиме ОВЗ
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
  isOlympiadMode: bool, // В режиме олимпиады?
  lessonId: string.isRequired, // ID урока
  ids: arrayOf(string.isRequired), // Массив ID блоков страницы
  pageId: string.isRequired, // ID страницы блоков
  variant: oneOfType([array, string]), // Вариант оформления
  getOrder: func.isRequired, // Получение порядкового номера блока
  inBuilder: bool, // В конструкторе?
  isInModal: bool, // Режим просмотра страницы в плеере
  moveBlock: func.isRequired, // Передвинуть блок
  inExamMode: bool, // Страница контрольная?
  inBirdView: bool, // В режиме бёрдвью?
  isLessonComplete: bool, // Урок пройден?
};

Blocks.defaultProps = {
  isOlympiadMode: false,
  variant: undefined,
  isInModal: undefined,
  inBuilder: undefined,
  inExamMode: undefined,
  inBirdView: undefined,
  ids: [],
  isLessonComplete: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const { pageId, inPlayer, inBuilder, inPrintPage, inPresentation } = ownProps;

  const isOlympiadMode =
    selectors.player.getLessonType(state) === LESSON_TYPE_OLYMPIAD;
  const contexts = { inPlayer, inBuilder, inPresentation };

  const page = context.getPages(contexts)(state)[pageId];
  
  // Фильтрация блоков для опросника
  const currentResult = state.player.result;
  const selectedBlocksIds = currentResult ? currentResult.selectedBlocksIds : []
  const blocksIds =
    inPlayer && !inPrintPage
      ? get(['pages', pageId, 'displayedBlocksIds'], currentResult)
      : page?.blocksIds || [];
  const displayedBlocksIds = isEmpty(selectedBlocksIds)
    ? blocksIds
    : blocksIds.filter((blockId) => selectedBlocksIds.includes(blockId))

  const order =
    inPlayer && !inPrintPage ? displayedBlocksIds : page?.blocksIds || [];
  const ids =
    inPlayer && !inPrintPage
      ? displayedBlocksIds
      : intersect(order, keys(context.getBlocks(contexts)(state)));

  return { ids, order, pageId, inBuilder, isOlympiadMode };
};

const mapDispatchToProps = {
  moveBlock: actions.builder.block.move,
};

const contextEnhance = getContext({
  inPreview: bool,
  inPlayer: bool,
  inBuilder: bool,
  inPresentation: bool,
});

const handlersEnhance = withHandlers({
  getOrder: ({ order }) => (blockId) => indexOf(blockId, order),
});

export default compose(
  contextEnhance,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance,
  withTheming
)(Blocks);
