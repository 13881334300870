import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFinite, compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import withTheming from '~/hocs/withTheming';
import { login } from '~/actions/player';
import { Button, Overlay, Text, Icon } from '../../components';
import { SelectLocale } from '..';
import Auth from './Auth';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/Welcome.scss';

const Welcome = ({
  t,
  theme,
  isOpen,
  isMobile,
  fontSize,
  toggleMode,
  inSafeMode,
  lessonTimeLimit,
}) => (
  <Overlay isOpen={isOpen}>
    <div
      className={combineStyles('Welcome', [
        inSafeMode && 'safe-mode',
        inSafeMode && theme,
        isMobile && 'mobile',
      ])}
    >
      <div
        className={combineStyles('text', [
          inSafeMode && fontSize,
          inSafeMode && theme,
        ])}
      >
        <Text
          value={t('welcome.enter')}
          variant={combineStyles('text-head-dark', [
            inSafeMode && fontSize,
            inSafeMode && theme,
          ])}
        />
      </div>
      <Auth />
      {isFinite(lessonTimeLimit) && (
        <div className="time-limit">
          <Text
            value={t('welcome.lessonTime')}
            variant={combineStyles(
              'text-gray',
              inSafeMode && [fontSize, theme]
            )}
          />
          <div className="time">
            <Icon
              name="time"
              variant={combineStyles('gray', inSafeMode && [fontSize, theme])}
            />
            <Text
              value={`${lessonTimeLimit} мин.`}
              variant={combineStyles(
                'text-gray',
                inSafeMode && [fontSize, theme]
              )}
            />
          </div>
        </div>
      )}
      <div className="buttons-container">
        <Button
          text={
            inSafeMode
              ? t('welcome.normalVersion')
              : t('welcome.visuallyImpairedVersion')
          }
          variant={combineStyles(
            'player-toggle-mode',
            inSafeMode && 'safe-mode'
          )}
          icon="eye-open"
          onClick={toggleMode}
        />
        <SelectLocale variant="in-player-auth" />
      </div>
    </div>
  </Overlay>
);

const { bool, func, number, string } = PropTypes;

Welcome.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Тема внешнего вида плеера
  isOpen: bool.isRequired, // Окно открыто?
  isMobile: bool.isRequired,
  fontSize: string, // Размер шрифта в плеере
  inSafeMode: bool.isRequired, // Плеер в режиме для ОВЗ?
  toggleMode: func, // Переключение мода внешнего вида плеера
  lessonTimeLimit: number, // Время на прохождение урока
};

const mapDispatchToProps = { login };

export default compose(
  withTheming,
  withTranslation('containersPlayer'),
  withMobileDetect,
  connect(
    null,
    mapDispatchToProps
  )
)(Welcome);
