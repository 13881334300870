import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose, withHandlers, withState, withStateHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { REQUEST_NAME_AUTH_SAVE_PASSWORD } from '~/appConstants';
import Text from '~/components/Text';
import Input from '~/components/Input';
import Button from '~/components/Button';
import Quote from '~/components/Auth/Quote';
import { combineStyles } from '~/utils/styles';
import * as actions from '~/actions';
import SelectLocale from '~/containers/SelectLocale';
import { isRequestActive } from '~/selectors/ui';
import '~/styles/Auth/NewPassword.scss';

const NewPassword = ({
  t,
  password,
  isMobile,
  setPassword,
  passwordLabel,
  sendNewPassword,
  passwordVariant,
}) => {
  const isSubmitting = useSelector(
    isRequestActive(REQUEST_NAME_AUTH_SAVE_PASSWORD)
  );

  return (
    <div className={combineStyles('NewPassword', isMobile && 'mobile')}>
      <SelectLocale variant="in-auth" />
      <Quote />
      <div className="content">
        <Text
          value={t('recovery.newPasswordTitle')}
          variant="pass-recovery-title"
        />
        <div className="form">
          <Input
            type="password"
            large
            value={password}
            onChange={setPassword}
            variant={passwordVariant}
            label={t(`login.${passwordLabel}`)}
            placeholder={t('recovery.enterPassword')}
          />
        </div>
        <div className="send">
          <Button
            fill
            text={t('recovery.send')}
            type="primary"
            large
            loading={isSubmitting}
            disabled={isSubmitting}
            variant="auth-send"
            onClick={sendNewPassword}
          />
        </div>
      </div>
    </div>
  );
};

const { func, string, bool } = PropTypes;

NewPassword.propTypes = {
  t: func.isRequired,
  sendNewPassword: func.isRequired,
  password: string.isRequired,
  isMobile: bool.isRequired,
  setPassword: func.isRequired,
  passwordLabel: string.isRequired,
  passwordVariant: string,
};

NewPassword.defaultProps = {
  passwordVariant: null,
};

const mapDispatchToProps = {
  sendNewPassword: actions.auth.sendNewPassword,
};

export default compose(
  withTranslation('containersAuth'),
  withMobileDetect,
  connect(
    undefined,
    mapDispatchToProps
  ),
  withState('password', 'setPassword', ''),
  withStateHandlers(
    () => ({
      passwordVariant: null,
      passwordLabel: 'password',
      isPasswordValid: true,
    }),
    {
      validate: (_, { password }) => () => {
        const isPasswordLongEnough = password.trim().length >= 6;

        return {
          passwordVariant: isPasswordLongEnough ? null : 'error',
          passwordLabel: isPasswordLongEnough ? 'password' : 'shortPassword',
          isPasswordValid: isPasswordLongEnough,
        };
      },
    }
  ),
  withHandlers({
    setPassword: ({ validate, setPassword }) => (password) => {
      setPassword(password, () => validate());
    },
  }),
  withHandlers({
    sendNewPassword: ({
      sendNewPassword,
      password,
      isPasswordValid,
      match,
    }) => () => {
      if (isPasswordValid) {
        sendNewPassword({
          password,
          changePasswordId: match.params.changePasswordId,
        });
      }
    },
  })
)(NewPassword);
