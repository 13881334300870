import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Select } from '@blueprintjs/select';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { DraggedButton as Button } from '~/components/Button';
import { combineStyles } from '~/utils/styles';
import '../styles/SelectLocale.scss';

const locales = {
  ru: 'Русский',
  en: 'English',
  kz: 'Қазақ'
};

const localesForMinimal = {
  ru: 'RU',
  en: 'EN',
  kz: 'KZ'
};

const SelectLocale = ({ i18n, minimal, variant, isMobile }) => (
  <div
    className={combineStyles('SelectLocale', [isMobile && 'mobile', variant])}
  >
    <Select
      filterable={false}
      items={i18n.languages}
      popoverProps={{
        popoverClassName: 'select-locale-popover',
        canEscapeKeyClose: true,
        position: minimal ? 'bottom' : 'bottom-left',
      }}
      itemRenderer={(item, { modifiers, index }) => (
        <li key={index}>
          <Button
            minimal
            text={locales[item]}
            variant="select-locale"
            active={modifiers.active}
            onClick={() => i18n.changeLanguage(item)}
          />
        </li>
      )}
    >
      <Button
        minimal
        text={(minimal ? localesForMinimal : locales)[i18n.languages[0]]}
        variant={[
          'current-locale',
          variant,
          minimal && 'current-locale-minimal',
        ]}
      />
    </Select>
  </div>
);

const { oneOfType, array, string, object, bool } = PropTypes;

SelectLocale.propTypes = {
  i18n: object.isRequired,
  minimal: bool,
  isMobile: bool.isRequired,
  variant: oneOfType([array, string]),
};

export default compose(
  withTranslation(),
  withMobileDetect,
  pure
)(SelectLocale);
