import { css } from 'styled-components';
import newVersionImg from '../../../../static/img/new-version.png';

export const container = css`
  background: white;
  border-radius: 5px;
  box-sizing: border-box;

  ${(props) =>
    props.theme.isMobile
      ? css`
          padding: 30px;
          margin: 0 17px;
          max-width: calc(100vw - 34px);
        `
      : css`
          padding: 20px 35px 37px;
          width: 650px;
        `}
`;

export const title = css`
  margin: 0 20px;
  text-align: center;
`;

export const text = css`
  display: flex;
  margin: 16px 20px;
  opacity: 0.9;
  font-family: CirceRounded;
  font-size: 19px;
  text-align: center;
  color: #45494e;
  justify-content: center;
`;

export const buttons = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Button {
    width: 100%;
    margin: 8px 4px;

    .bp3-button {
      width: 100%;
    }
  }

  ${(props) =>
    props.theme.isMobile &&
    css`
      flex-wrap: wrap;
    `}
`;

export const newVersion = css`
  margin: 36px auto 24px;
  width: 64px;
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    width: 64px;
    height: 64px;
    position: absolute;
    box-sizing: border-box;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(${newVersionImg});
  }
`;
