import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../../../utils/styles';
import { Text, Froala } from '../..';
import '../../../styles/QuizComment.scss';

const QuizComment = ({
  t,
  isCommentVisible,
  inSafeMode,
  theme,
  fontSize,
  content,
}) =>
  isCommentVisible ? (
    <div className={combineStyles('QuizComment', inSafeMode && theme)}>
      <Text
        variant={inSafeMode ? ['comment-title', theme] : 'comment-title'}
        value={t('comment.answerComment')}
      />
      <Froala
        mode="preview"
        content={content}
        variant={
          inSafeMode
            ? ['answer-comment', 'in-player', theme, fontSize]
            : ['answer-comment', 'in-player']
        }
      />
    </div>
  ) : null;

const { string, bool, oneOfType, func } = PropTypes;

QuizComment.propTypes = {
  t: func.isRequired, // Функция перевода
  isCommentVisible: oneOfType([bool, string]), // Комментарий показан?
  inSafeMode: bool, // В режиме ОВЗ?
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  content: string, // Текст комментария
};

QuizComment.defaultProps = {
  isCommentVisible: undefined,
  inSafeMode: false,
  theme: 'light',
  fontSize: 'small',
  content: undefined,
};

export default pure(withTranslation('componentsQuiz')(QuizComment));
