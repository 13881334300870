import React from 'react';
import PropTypes from 'prop-types';

import 'moment/locale/ru'; // eslint-disable-line import/no-extraneous-dependencies
import TagsInput from 'react-tagsinput';
import classNames from 'classnames';
import { isEmpty, debounce } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NumericInput } from '@blueprintjs/core';
import {
  withState,
  withPropsOnChange,
  withStateHandlers,
  pure,
  compose,
  lifecycle,
} from 'recompose';

import { combineStyles } from '../utils/styles';
import '../styles/Input.scss';
import Tag from '~/components/Tag';
import Icon from '~/components/Icon';
import { DraggedButton as Button } from '~/components/Button';
import Tooltip from '~/components/Tooltip';
import Controls from '~/components/Controls';

const Input = ({
  t,
  info,
  icon,
  name,
  type,
  large,
  label,
  value,
  helper,
  maxLen,
  onCopy,
  variant,
  readonly,
  controls,
  minValue,
  maxValue,
  copyable,
  stepSize,
  withTags,
  basketId,
  disabled,
  onChange,
  className,
  autoFocus,
  usePortal,
  direction,
  uppercase,
  moveAnswer,
  innerValue,
  placeholder,
  onPressEnter,
  hideZeroOnClick,
  changeInnerValue,
  isPasswordVisible,
  debouncedOnChange,
  withVisibilityToggler,
  togglePasswordVisibility,
}) => (
  <div
    className={combineStyles(['Input', className], [variant, label && 'label'])}
  >
    {type === 'numeric' ? (
      <NumericInput
        min={minValue}
        max={maxValue}
        value={value}
        stepSize={stepSize}
        leftIcon={icon}
        onKeyDown={(e) => e.preventDefault()}
        onValueChange={onChange}
        majorStepSize={stepSize}
        minorStepSize={stepSize}
      />
    ) : type === 'number' ? (
      <NumericInput
        min={minValue}
        max={maxValue}
        value={value}
        leftIcon={icon}
        className="-number-input"
        onValueChange={onChange}
      />
    ) : (
      <div
        className={classNames('bp3-form-group', {
          'bp3-large': large,
          'bp3-inline': usePortal,
          'bp3-disabled': disabled,
        })}
      >
        {label && (
          <div className="bp3-label">
            {label}
            {info && <span className="bp3-text-muted">{info}</span>}
          </div>
        )}
        <div className="bp3-form-content">
          <div
            className={classNames('bp3-input-group', {
              'bp3-large': large,
              'input-copy': copyable,
              'bp3-disabled': disabled,
            })}
          >
            {icon && direction === 'left' && <Icon name={icon} />}
            {withTags ? (
              <TagsInput
                value={value || []}
                onChange={onChange}
                tagProps={{
                  type: name,
                  variant,
                  readonly,
                  moveAnswer,
                  basketId,
                }}
                renderTag={Tag}
                variant={variant}
                disabled={readonly}
                className={combineStyles('bp3-input', ['tags', variant])}
                inputProps={{ placeholder, className: 'input' }}
              />
            ) : (
              <input
                id={name}
                dir="auto"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                type={
                  withVisibilityToggler
                    ? isPasswordVisible
                      ? 'text'
                      : 'password'
                    : type
                }
                // Выбор источника данных для инпута - redux или локальный state
                // ? что делает этот код? TODO: refactor
                // ! FIXME fix Input elements should not switch from uncontrolled to controlled (or vice versa).
                value={
                  innerValue
                    ? innerValue === value
                      ? value
                      : innerValue
                    : value
                }
                // value={value}
                onClick={() => {
                  if (hideZeroOnClick && value === '0') {
                    onChange('');
                  }
                }}
                // Вызывает onChange только если микросостояние инпута изменилось
                onBlur={(event) => {
                  const newValue = event.target.value;

                  if (type === 'number' && newValue === '') {
                    onChange('0');
                  } else if (newValue !== value) {
                    onChange(newValue);
                  }
                }}
                onKeyUp={(event) =>
                  event.keyCode === 13 &&
                  onPressEnter &&
                  onPressEnter(event.target.value)
                }
                disabled={disabled && 'disabled'}
                readOnly={readonly}
                // Изменение внутреннего состояния инпута
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const formattedValue = inputValue
                    ? compose(
                        // eslint-disable-next-line unicorn/prefer-string-slice
                        (string) => (maxLen ? string.substring(0, 4) : string),
                        (string) => (uppercase ? string.toUpperCase() : string)
                      )(inputValue)
                    : inputValue;
                  if (formattedValue) {
                    changeInnerValue(formattedValue);
                  } else {
                    [changeInnerValue, onChange].forEach((updater) =>
                      updater(formattedValue)
                    );
                  }
                  debouncedOnChange(inputValue);
                }}
                className="bp3-input"
                placeholder={placeholder}
                onMouseLeave={(event) =>
                  event.target.value !== value && onChange(event.target.value)
                }
              />
            )}
            {withVisibilityToggler && (
              <Button
                onClick={togglePasswordVisibility}
                variant="auth-password"
                icon={isPasswordVisible ? 'eye-open' : 'eye-off'}
                minimal
              />
            )}
            {icon && direction === 'right' && <Icon name={icon} />}
          </div>
          {helper && <div className="bp3-form-helper-text">{helper}</div>}
          {copyable && (
            <Tooltip
              content={t('input.copyLink')}
              position="top"
              variant="input-copy"
            >
              <CopyToClipboard onCopy={onCopy} text={value}>
                <Button icon="osh-duplicate" variant="input-copy" minimal />
              </CopyToClipboard>
            </Tooltip>
          )}
          {!isEmpty(controls) && (
            <Controls
              type="dropdown"
              usePortal
              items={controls}
              variant="input"
              position="rightTop"
            />
          )}
        </div>
      </div>
    )}
  </div>
);

const { string, bool, oneOf, number, oneOfType, func, array } = PropTypes;

Input.propTypes = {
  t: func.isRequired, // Функция перевода
  info: string, // Дополнительный текст справа от метки
  name: string, // Имя инпута
  icon: string, // Имя иконки из Blueprint
  type: oneOf([
    // Тип инпута
    'text', // Текстовый инпут с возможностью показа тэгов
    'number',
    'numeric', // Числовой инпут
    'password', // Пароль
  ]),
  autoFocus: bool,
  hideZeroOnClick: bool,
  large: bool, // Увеличенный размер?
  label: string, // Метка у инпута
  value: oneOfType([
    array, // Значение инпута с тэгами
    number, // Значение числового инпута
    string, // Значение обычного инпута
  ]),
  className: string,
  helper: string, // Дополнительный текст снизу под инпутом
  maxLen: number, // Максимальное количество введенных символов
  onCopy: func, // Действие при копировании ссылки из инпута
  variant: oneOfType([array, string]), // Вариант исполнения
  readonly: bool, // Доступен только для чтения
  minValue: number, // Минимальное значение числового инпута
  maxValue: number, // Максимальное значение числового инпута
  basketId: string, // ID корзины в которой находится Input в блоке Match
  controls: array, // Контролы инпута
  copyable: bool, // C кнопкой копирования содержимого?
  withTags: bool, // При вводе добавляются теги?
  stepSize: number, // Шаг числового инпута
  disabled: bool, // Не активен?
  onChange: func, // Вызывается при изменении значения инпута
  usePortal: bool, // Инлайновое исполнение (в строчку)
  uppercase: bool, // Символы только в верхнем регистре
  direction: oneOf(['left', 'right']), // Расположение иконки
  innerValue: oneOfType([array, string]), // Внутреннее значение
  moveAnswer: func, // Переместить тег в блоке Match
  placeholder: string, // Текст в пустом поле
  onPressEnter: func, // Вызывается при нажатии Enter
  debouncedOnChange: func,
  changeInnerValue: func.isRequired, // Изменение внутреннего значения
  isPasswordVisible: bool.isRequired, // Пароль виден?
  withVisibilityToggler: bool, // С переключателем видимости пароля?
  togglePasswordVisibility: func.isRequired, // Скрыть/показать пароль
};

Input.defaultProps = {
  type: 'text',
  large: false,
  readonly: false,
  autoFocus: false,
  copyable: false,
  withTags: false,
  disabled: false,
  controls: [],
  direction: 'left',
  usePortal: false,
  uppercase: false,
  stepSize: 1,
  withVisibilityToggler: false,
};

const enhance = compose(
  withState('innerValue', 'changeInnerValue', ''),
  withStateHandlers(
    {
      isPasswordVisible: false,
    },
    {
      togglePasswordVisibility: (
        { isPasswordVisible },
        { disabled }
      ) => () => ({
        isPasswordVisible: disabled ? isPasswordVisible : !isPasswordVisible,
      }),
    }
  ),
  withPropsOnChange([], ({ onChange = () => {}, debounceTime = 500 }) => ({
    debouncedOnChange: debounceTime
      ? debounce(debounceTime, onChange)
      : onChange,
  })),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      // eslint-disable-next-line fp/no-this
      if (nextProps.value !== this.props.value) {
        // eslint-disable-next-line fp/no-this
        this.props.changeInnerValue(nextProps.value);
      }
    },
  })
);

export default compose(
  withTranslation('components'),
  enhance,
  pure
)(Input);
