import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import useInterval from '@use-it/interval';

import * as s from '~/components/styles/NotificationsIcon.styles';
import Icon from '~/components/Icon';
import { getNotificationsCount } from '~/actions/study/notifications';
import * as selectors from '~/selectors';

const NotificationsIcon = ({ onClick }) => {
  const dispatch = useDispatch();
  const count = useSelector(selectors.notification.getNotificationsCount);

  useEffect(() => {
    dispatch(getNotificationsCount());
  }, [dispatch]);

  useInterval(() => dispatch(getNotificationsCount()), 30000);

  return (
    <div css={s.notification} onClick={onClick}>
      {count > 0 && (
        <div css={s.notificationCounter}>{count > 99 ? '99+' : count}</div>
      )}
      <Icon name="notifications" />
    </div>
  );
};

const { func } = PropTypes;

NotificationsIcon.propTypes = {
  onClick: func,
};

export default NotificationsIcon;
