import { get, includes, compose } from 'lodash/fp';

export const getLesson = (state) => get(['presentation', 'lesson'], state);
export const getBlocks = (state) => get(['presentation', 'blocks'], state);
export const getBlock = (blockId, state) => get(blockId, getBlocks(state));
export const getPages = (state) => get(['presentation', 'pages'], state);
export const getPage = (pageId, state) => get(pageId, getPages(state));

// Блок
export const isBlockInVerifyMode = (blockId, state) =>
  compose(
    includes(blockId),
    get(['player', 'result', 'verifyModeBlocksIds'])
  )(state);

// Страница

export const isPageCompleted = (pageId, state) =>
  compose(
    includes(pageId),
    get(['player', 'result', 'completedPagesIds'])
  )(state);

export const isPageInExamMode = (pageId, state) =>
  get('inExamMode', getPage(pageId, state));

export const isPageInVerifyMode = (pageId, state) =>
  compose(
    includes(pageId),
    get(['player', 'result', 'verifyModePagesIds'])
  )(state);

export const getDocumentFileName = (blockId, state) =>
  get(['content', 'fileName'], getBlock(blockId, state));

export const getDocumentIsProtected = (blockId, state) =>
  get(['content', 'isProtected'], getBlock(blockId, state));

export const getQuizShuffledAnswers = (blockId, state) =>
  get(['blocks', blockId, 'shuffledAnswers'], state.presentation.result);

export const displayedAnswersQuantity = (blockId, state) =>
  get(['content', 'displayedAnswersQuantity'], getBlock(blockId, state));

// Блок Match
export const getCombinedAnswers = (blockId, state) =>
  get(['blocks', blockId, 'combinedAnswers'], state.presentation.result);

export const getBasketSelectedAnswers = (blockId, basketId, state) =>
  get(
    ['blocks', blockId, 'answers', basketId, 'selectedAnswers'],
    state.presentation.result
  );

export const getTalkScreenId = (blockId, state) =>
  get(
    ['blocks', blockId, 'currentTarget', 'cardId'],
    state.presentation.result
  );
