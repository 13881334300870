import React from 'react';
import { pure, compose } from 'recompose';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import '../styles/Thumbnail.scss';
import Icon from '~/components/Icon';
import LinesTruncate from '~/components/LinesTruncate';
import Text from '~/components/Text';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';

const Thumbnail = ({
  icon,
  title,
  variant,
  onClick,
  isMobile,
  withBadge,
  pagesCount,
  lessonType,
  description,
}) => {
  const { t } = useTranslation('components');

  const iconTooltipByType = {
    [LESSON_TYPE_EXAM]: 'thumbnail.exam',
    [LESSON_TYPE_REGULAR]: 'thumbnail.regular',
    [LESSON_TYPE_OLYMPIAD]: 'thumbnail.olympiad',
  };

  const iconLessonType = {
    [LESSON_TYPE_EXAM]: 'osh-lesson-exam',
    [LESSON_TYPE_REGULAR]: 'osh-lesson-regular',
    [LESSON_TYPE_OLYMPIAD]: 'osh-lesson-olympiad',
  };

  return (
    <div
      className={combineStyles('Thumbnail', [isMobile && 'mobile', variant])}
      onClick={onClick}
    >
      {withBadge && <div className="lesson-badge" />}
      <div className="type">
        <Icon
          name={iconLessonType[lessonType]}
          variant={`${variant}-thumbnail-type`}
          tooltip={t(iconTooltipByType[lessonType])}
          position="top"
        />
      </div>
      {icon && (
        <div className="icon">
          <Icon name={icon} variant={`${variant}-thumbnail-icon`} />
        </div>
      )}
      <div className="title">
        <LinesTruncate
          text={title}
          variant={`${variant}-thumbnail-title`}
          maxLines={3}
        />
        <Text value={pagesCount} variant={`${variant}-thumbnail-pages`} />
      </div>
      {description && (
        <div className="description">
          <Text
            value={description}
            variant={`${variant}-thumbnail-description`}
          />
        </div>
      )}
    </div>
  );
};

const { string, array, oneOfType, bool, func, number } = PropTypes;

Thumbnail.propTypes = {
  icon: string, // Иконка
  title: string, // Заголовок
  onClick: func,
  variant: oneOfType([array, string]), // Вариант оформления
  isMobile: bool.isRequired,
  description: string, // Описание
  withBadge: bool,
  lessonType: string,
  pagesCount: number.isRequired, // Количество страниц в урока
};

Thumbnail.defaultProps = {
  icon: undefined,
  title: undefined,
  variant: undefined,
  onClick: undefined,
  withBadge: undefined,
  description: undefined,
  lessonType: LESSON_TYPE_REGULAR,
};

export default compose(
  withMobileDetect,
  pure
)(Thumbnail);
