import React from 'react';
import PropTypes from 'prop-types';

import { compose, lifecycle } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import {
  AUTH_MODE_ANONYMOUS,
  AUTH_MODE_LOGIN,
  AUTH_MODE_REGISTER,
} from '~/appConstants';
import '../../styles/Auth/SwitchMode.scss';
import { combineStyles } from '../../utils/styles';
import Link from '~/components/Link';

const SwitchMode = ({
  t,
  mode,
  setMode,
  context,
  isMobile,
  isLoading,
  isAuthAfterComplete,
}) => (
  <div
    className={combineStyles('SwitchMode', [
      isAuthAfterComplete && 'auth-after-complete',
      `in-${context}`,
      isMobile && 'mobile',
    ])}
  >
    {context === 'player' && !isAuthAfterComplete && (
      <Link
        text={t('switchmode.signinWithourRegistration')}
        active={mode === AUTH_MODE_ANONYMOUS}
        variant={[
          'auth-switch-link',
          'in-player',
          'anonym',
          isMobile && 'mobile',
        ]}
        onClick={() => setMode(AUTH_MODE_ANONYMOUS)}
      />
    )}
    <Link
      text={
        context === 'player'
          ? t('switchmode.signinWithAccount')
          : t('switchmode.signin')
      }
      active={mode === AUTH_MODE_LOGIN || isAuthAfterComplete}
      variant={[
        'auth-switch-link',
        'login',
        isMobile && 'mobile',
        context === 'player' ? 'in-player' : undefined,
      ]}
      onClick={() => setMode(AUTH_MODE_LOGIN)}
      disabled={isLoading}
    />
    {/* {context !== 'player' && <div className="separator">|</div>} */}
    {/* <Link */}
    {/* text={ */}
    {/* context === 'player' */}
    {/* ? t('switchmode.createAccountAndSignin') */}
    {/* : t('switchmode.registration') */}
    {/* } */}
    {/* active={mode === AUTH_MODE_REGISTER} */}
    {/* variant={[ */}
    {/* 'auth-switch-link', */}
    {/* 'reg', */}
    {/* isMobile && 'mobile', */}
    {/* context === 'player' ? 'in-player' : undefined, */}
    {/* ]} */}
    {/* onClick={() => setMode(AUTH_MODE_REGISTER)} */}
    {/* disabled={isLoading} */}
    {/* /> */}
  </div>
);

const { oneOf, func, bool, string } = PropTypes;

SwitchMode.propTypes = {
  t: func.isRequired, // Функция перевода
  context: string.isRequired, // Текущее местоположение компонента
  mode: oneOf([AUTH_MODE_REGISTER, AUTH_MODE_LOGIN, AUTH_MODE_ANONYMOUS]),
  isMobile: bool.isRequired,
  isLoading: bool.isRequired, // Идет запрос на сервер?
  setMode: func.isRequired, // Выбрать тип авторизации
  isAuthAfterComplete: bool, // Авторизация предложена после завершения урока?
};

SwitchMode.defaultProps = {
  isAuthAfterComplete: false,
};

export default compose(
  withTranslation('componentsAuth'),
  withMobileDetect,
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      if (this.props.isAuthAfterComplete) {
        this.props.setMode(AUTH_MODE_LOGIN);
      }
    },
    /* eslint-enable fp/no-this */
  })
)(SwitchMode);
