import { get, getOr, includes, curry } from 'lodash/fp';
import { getLesson, getUIPath } from './main';

export const isUnsaved = curry((lessonId, state) =>
  includes(lessonId, get(getUIPath('unsavedLessonsIds'), state))
);
export const hasUnpublishedChanges = curry((lessonId, state) =>
  getOr(false, ['meta', 'hasUnpublishedChanges'], getLesson(lessonId, state))
);
export const isPersisting = curry((lessonId, state) =>
  includes(lessonId, get(getUIPath('persistingLessonsIds'), state))
);
export const isUploaded = (lessonId, state) =>
  includes(lessonId, get(getUIPath('uploadedLessonsIds'), state));
export const inBirdView = (lessonId, state) =>
  includes(lessonId, get(getUIPath('inBirdViewLessonsIds'), state));
export const isSidebarOpen = (state) =>
  get(getUIPath(['isLessonSidebarOpen']), state);

export const getCreatorId = (lessonId, state) =>
  get(['meta', 'creatorId'], getLesson(lessonId, state));
export const getCode = (lessonId, state) =>
  get(['meta', 'code'], getLesson(lessonId, state));
export const getName = (lessonId, state) =>
  get(['content', 'name'], getLesson(lessonId, state));
export const getCourseId = (lessonId, state) =>
  get(['meta', 'courseId'], getLesson(lessonId, state));
export const getDescription = (lessonId, state) =>
  get(['content', 'description'], getLesson(lessonId, state));
export const getEndDate = (lessonId, state) =>
  get(['meta', 'endDate'], getLesson(lessonId, state));
export const getStartDate = (lessonId, state) =>
  get(['meta', 'startDate'], getLesson(lessonId, state));
export const getTimeLimit = (lessonId, state) =>
  get(['meta', 'timeLimit'], getLesson(lessonId, state));
export const getLessonType = (lessonId, state) =>
  get(['meta', 'type'], getLesson(lessonId, state));
export const withDateRange = (lessonId, state) =>
  get(['meta', 'withDateRange'], getLesson(lessonId, state));
export const getPublications = curry((lessonId, state) =>
  get(['meta', 'publications'], getLesson(lessonId, state))
);
export const getPublicationsIds = curry((lessonId, state) =>
  get(['meta', 'publicationsIds'], getLesson(lessonId, state))
);
export const getFolderId = (lessonId, state) =>
  get(['meta', 'folderId'], getLesson(lessonId, state));
export const getExternalLink = (lessonId, state) =>
  get(['meta', 'settings', 'externalLink'], getLesson(lessonId, state));
export const getLessonLtiAccess = (lessonId, state) =>
  get(['meta', 'ltiAccess'], getLesson(lessonId, state));
export const getOlympiadSettings = curry((lessonId, state) =>
  get(['meta', 'settings', 'olympiad'], getLesson(lessonId, state))
);
export const getMinimalSuccessScore = (lessonId, state) =>
  get(['meta', 'settings', 'minimalSuccessScore'], getLesson(lessonId, state));

export const getContentSource = (lessonId, pathLastName, state) =>
  get(
    pathLastName
      ? ['meta', 'settings', 'olympiad', pathLastName, 'source']
      : ['meta', 'settings', 'olympiad', 'source'],
    getLesson(lessonId, state)
  );
export const getContentUploaderStatus = (lessonId, pathLastName, state) =>
  get(
    pathLastName
      ? ['meta', 'settings', 'olympiad', pathLastName, 'uploader', 'status']
      : ['meta', 'settings', 'olympiad', 'uploader', 'status'],
    getLesson(lessonId, state)
  );
export const getContentUploaderProgress = (lessonId, pathLastName, state) =>
  get(
    pathLastName
      ? ['meta', 'settings', 'olympiad', pathLastName, 'uploader', 'progress']
      : ['meta', 'settings', 'olympiad', 'uploader', 'progress'],
    getLesson(lessonId, state)
  );
