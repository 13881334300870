import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 16px;
  flex-shrink: 0;
  width: 326px;
  min-height: 96px;
  border-radius: 3px;
  background-color: #f4f6f7;
  margin-bottom: 10px;
`;

export const BottomTitle = styled.div`
  width: 64px;
  margin: 0 8px;
  font-family: CirceRounded;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0.27px;
  color: #9aa1ac;
  text-transform: uppercase;
  text-align: ${(p) => (p.right ? 'right' : 'left')};
`;

export const removeButton = css`
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s ease;

  .bp3-button {
    border-radius: 24px;
    border: none;
    position: relative;
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 6px 0 rgba(255, 255, 255, 0.27);
    background-color: #fff;

    &:hover {
      background: #fff;
    }

    &::before {
      color: #bdbdbd;
    }

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(to bottom, #efefef 2%, #d9d9d9);
      content: '';
      z-index: -1;
      border-radius: 16px;
    }
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

export const answers = css`
  display: flex;
  margin: -5px;
  flex-wrap: wrap;
  width: calc(326px + 326px + 20px);
`;

export const top = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const bottom = css`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

export const letter = css`
  line-height: 1;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: solid 1px #d0d3d7;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #767b84;
`;

export const title = css`
  margin-top: 1px;
  width: 260px;
  margin-left: 10px;
  font-family: CirceRounded;
  font-size: 15px;
  color: #45494e;
`;
