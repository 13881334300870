import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '~/utils/styles';
import { TLesson } from '~/@types';
import '../../styles/CourseLessonThumbnail.scss';
import Icon from '~/components/Icon';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_OLYMPIAD,
  LESSON_TYPE_REGULAR,
} from '~/appConstants';
import Text from '~/components/Text';
import { formatCardDate } from '~/utils/format';

const iconLessonType = {
  [LESSON_TYPE_EXAM]: 'osh-lesson-exam',
  [LESSON_TYPE_REGULAR]: 'osh-lesson-regular',
  [LESSON_TYPE_OLYMPIAD]: 'osh-lesson-olympiad',
};

const CourseLessonThumbnail = ({
  t,
  i18n,
  lesson,
  index,
  isMobile,
  publication,
}) => {
  if (!lesson) return null
  const {
    meta: { type },
    content: { name },
  } = lesson
  const Root = publication ? Link : 'div';

  return (
    <Root
      to={publication ? `/preview/lesson/${publication.id}` : null}
      target={publication ? '_blank' : null}
      className={combineStyles('CourseLessonThumbnail', [
        isMobile && 'mobile',
        !publication && 'no-publication',
      ])}
    >
      <div className={combineStyles('number', type)}>{index + 1}</div>
      <div className="content">
        <div className="title">{name}</div>
        <div className="status">
          {!publication ? (
            <Text
              variant="course-thumbnail-red"
              value={t('lessonthumbnails.notPublished')}
            />
          ) : (
            <Text
              variant="course-thumbnail-grey"
              value={`${t('lessonthumbnails.publishedAt')} (${formatCardDate(
                publication.modified,
                i18n
              )})`}
            />
          )}
        </div>
      </div>

      <div className="type">
        <Icon
          name={iconLessonType[type]}
          variant="lesson-thumbnail-type"
          position="top"
        />
      </div>
    </Root>
  );
};
const { func, bool, number, object } = PropTypes;

CourseLessonThumbnail.propTypes = {
  t: func.isRequired,
  i18n: object.isRequired,
  index: number.isRequired,
  isMobile: bool.isRequired,
  lesson: TLesson.isRequired,
  publication: object,
};

export default compose(
  withRouter,
  withTranslation('components'),
  withMobileDetect
)(CourseLessonThumbnail);
