export const OPEN = '@library/edit/block/Открыть окно редактирования блока';
export const SAVE =
  '@library/edit/block/Сохранить изменения блока в библиотеку';
export const CLOSE = '@library/edit/block/Закрыть окно редактирования блока';
export const SAVE_ERROR =
  '@library/edit/block/Ошибка сохранения изменения блока библиотеки';
export const SAVE_SUCCESS =
  '@library/edit/block/Изменения блока библиотеки успешно сохранены';
export const CHANGE_TERMS =
  '@library/edit/block/Редактировать термины для блока';
export const CHANGE_DATES = '@library/edit/block/Редактировать даты для блока';
export const REMOVE_ERROR =
  '@library/edit/block/Ошибка удаления блока на сервере';
export const CHANGE_THEMES = '@library/edit/block/Редактировать темы для блока';
export const REMOVE_REQUEST = '@library/edit/block/Удалить блок на сервере';
export const REMOVE_SUCCESS =
  '@library/edit/block/Блок успешно удален на сервере';
export const CHANGE_PERSONS =
  '@library/edit/block/Редактировать персоналии для блока';
export const CHANGE_SUBJECT =
  '@library/edit/block/Редактировать предмет для блока';
