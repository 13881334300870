import * as FILLBLANK from '../../../constants/builder/block/fillblank';
import { createAction } from '../../../utils/actions';

export const changeTitle = (blockId, newTitle) =>
  createAction(FILLBLANK.CHANGE_TITLE, {
    blockId,
    title: newTitle,
  });

export const changeQuestion = (blockId, newQuestion, newCorrectAnswers) =>
  createAction(FILLBLANK.CHANGE_QUESTION, {
    blockId,
    newQuestion,
    newCorrectAnswers,
  });
