import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const Container = styled.div`
  width: 100%;
  height: ${prop('height', 0)}px;
  /*
    ToDo nik
    если убрать overflow то анимация будет красивее, но появляется скролл
    т.к. модалка для маленьких окон смещается ниже,
    и большой скрытый блок вызывает скролл
  */
  overflow: hidden;
  position: relative;
  ${ifProp(
    'withAnimation',
    css`
      transition: height 0.3s ease;
    `
  )}
`;

const { number, bool } = PropTypes;

Container.propTypes = {
  height: number,
  withAnimation: bool,
};

export { Container };
