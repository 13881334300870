/* eslint-disable max-classes-per-file */

import React from 'react';

import bowser from 'bowser';

import quizImage from '../../static/img/dnd/quiz.png';
import textImage from '../../static/img/dnd/text.png';
import embedImage from '../../static/img/dnd/embed.png';
import matchImage from '../../static/img/dnd/match.png';
import videoImage from '../../static/img/dnd/video.png';
import imagesImage from '../../static/img/dnd/image.png';
import answerImage from '../../static/img/dnd/answer.png';
import pageImage from '../../static/img/dnd/page.png';

/**
 * НOC для добавления состояния в компонент
 */
export const withDnDListener = (value) => (Component) =>
  // eslint-disable-next-line react/display-name
  class extends React.Component {
    // eslint-disable-next-line react/state-in-constructor
    state = { [value]: undefined };

    render() {
      return React.createElement(
        Component,
        {
          ...this.props, // eslint-disable-line fp/no-this
          [value]: this.state[value], // eslint-disable-line fp/no-this
        },
        null
      );
    }
  };

/**
 * НOC для добавления превью в виде изображения при DnD компонента
 */
export const withDnDImagePreview = (Component) =>
  // eslint-disable-next-line react/display-name
  class extends React.Component {
    componentDidMount() {
      const dndImages = {
        VR: embedImage,
        Quiz: quizImage,
        Text: textImage,
        Embed: embedImage,
        Match: matchImage,
        Video: videoImage,
        Images: imagesImage,
        Answer: answerImage,
        FillBlank: answerImage,
      };
      const image = new Image();
      // eslint-disable-next-line fp/no-this, react/prop-types, fp/no-mutation
      image.addEventListener(
        'load',
        () =>
          // eslint-disable-next-line fp/no-this, react/prop-types
          !bowser.msie && !bowser.msedge && this.props.connectDragPreview(image)
      );
      // eslint-disable-next-line fp/no-this, react/prop-types, fp/no-mutation
      image.src = dndImages[this.props.type] || pageImage;
    }

    render() {
      // eslint-disable-next-line fp/no-this
      return React.createElement(Component, { ...this.props }, null);
    }
  };
