import { assign, set } from 'lodash/fp';
import * as COURSE from '../constants/course';

const defaultState = {
  course: {},
  publications: {},
  results: {},
  resultsByLessonId: {},
};

const courseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case COURSE.DOWNLOAD_COURSE_SUCCESS: {
      const {
        course,
        publications,
        results,
        resultsByLessonId,
      } = action.payload;
      return assign(state, {
        course,
        publications,
        results,
        resultsByLessonId,
      });
    }

    case COURSE.JOIN_SUCCESS: {
      return set(['course', 'userStatus'], 'active', state);
    }

    default:
      return state;
  }
};

export default courseReducer;
