import { get } from 'lodash/fp';

export const getData = (state) => get('results', state);

export const getResults = (lessonId, state) =>
  get(['results', 'lessons', lessonId, 'results'], state) || [];

export const getPublication = (lessonId, state) =>
  get(['results', 'lessons', lessonId, 'publication'], state) || {};

export const getResult = (lessonId, resultIndex, state) =>
  get(resultIndex, getResults(lessonId, state));

export const getResultBlocks = (state, lessonId, resultIndex, results) =>
  get([resultIndex, 'content', 'blocks'], results);

export const getResultBlock = (
  blockId,
  state,
  lessonId,
  resultIndex,
  results
) => get(blockId, getResultBlocks(state, lessonId, resultIndex, results));

export const getResultLesson = (lessonId, state, resultIndex) =>
  get(['content', 'lesson'], getResult(lessonId, resultIndex, state));

export const getResultPages = (lessonId, state, resultIndex) =>
  get(['content', 'pages'], getResult(lessonId, resultIndex, state));
