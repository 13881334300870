import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { Select } from '@blueprintjs/select';
import { get, map, size } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Tabs } from '~/components';
import ReviewAnswerBlockList from './ReviewAnswerBlockList';
import ReviewAnswerBlocksFilter from './ReviewAnswerBlocksFilter';
import {
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_NOT_CHECKED,
} from '~/appConstants';
import * as s from './styles/ReviewAnswerBlocksContainer.styles';

const ReviewAnswerBlocksContainer = ({
  onSort,
  onFilter,
  activeTab,
  filterData,
  onTabClick,
  answerBlocks,
  onFilterReset,
  currentAnswerBlockResultId,
  setCurrentAnswerBlockResultId,
}) => {
  const [sortField, setSortField] = useState('newFirst');
  const { t } = useTranslation('screens');

  const onChangeSort = useCallback(
    (sort) => {
      setSortField(sort);
      onSort(sort);
    },
    [onSort]
  );

  return (
    <div css={s.answerBlocks} isTaskOpen={!!currentAnswerBlockResultId}>
      <ReviewAnswerBlocksFilter
        filterData={filterData}
        onFilter={onFilter}
        onFilterReset={onFilterReset}
      />
      <div css={s.answerBlocksContent}>
        <div css={s.answerBlocksTabs}>
          <Tabs
            variant="in-review"
            onChange={onTabClick}
            renderActiveTabPanelOnly
            selectedTabId={activeTab}
          >
            {map(
              (tabName) => (
                <Tabs.Tab
                  key={tabName}
                  id={tabName}
                  title={
                    <Tabs.TitleWithCounter
                      count={size(get(tabName, answerBlocks))}
                      title={t(`review.filter.${tabName}`)}
                      isActive={tabName === activeTab}
                    />
                  }
                  panel={
                    <ReviewAnswerBlockList
                      answerBlocks={get(tabName, answerBlocks)}
                      currentAnswerBlockResultId={currentAnswerBlockResultId}
                      setCurrentAnswerBlockResultId={
                        setCurrentAnswerBlockResultId
                      }
                    />
                  }
                />
              ),
              [STATUS_NOT_CHECKED, STATUS_APPROVED, STATUS_REJECTED]
            )}
            <Tabs.Expander />

            <Select
              filterable={false}
              items={['newFirst', 'oldFirst']}
              popoverProps={{
                popoverClassName: 'select-locale-popover',
                canEscapeKeyClose: true,
              }}
              itemRenderer={(item, { modifiers, index }) => (
                <li key={index}>
                  <Button
                    minimal
                    text={t(`review.sortBy.${item}`)}
                    variant="select-locale"
                    active={modifiers.active}
                    onClick={() => onChangeSort(item)}
                  />
                </li>
              )}
            >
              <div css={s.filterBlocksSortTitle}>
                {t(`review.sortBy.${sortField}`)}&nbsp;
                <Icon name="chevron-down" variant="sort-expander" />
              </div>
            </Select>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const { any, func, object, string } = PropTypes;

ReviewAnswerBlocksContainer.propTypes = {
  onSort: func,
  onFilter: func,
  onTabClick: func,
  activeTab: string,
  filterData: object,
  onFilterReset: func,
  answerBlocks: object,
  currentAnswerBlockResultId: any,
  setCurrentAnswerBlockResultId: func,
};
export default pure(ReviewAnswerBlocksContainer);
