import {
  assign,
  compose,
  first,
  indexOf,
  keys,
  omit,
  pull,
  pullAt,
  set,
  unset,
  update,
} from 'lodash/fp';
import { insertAt } from '../../utils/array';

import * as PAGE from '../../constants/builder/page';
import * as BLOCK from '../../constants/builder/block';
import * as LESSON from '../../constants/builder/lesson';
import * as BUILDER from '../../constants/builder';

export const defaultState = {};

const createPage = () => ({
  name: undefined,
  blocksIds: [],
  isShuffleBlocks: false,
});

const pagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    // -------------- Страницы --------------

    case PAGE.ADD: {
      const { id } = action.payload;
      return set(id, createPage(), state);
    }

    case PAGE.CLONE: {
      const {
        meta: { clonedPage },
      } = action;
      return assign(state, clonedPage);
    }

    case PAGE.PASTE: {
      const {
        meta: { storedPages },
      } = action;
      return assign(state, storedPages);
    }

    case PAGE.REMOVE: {
      const { id } = action.payload;
      return omit(id, state);
    }

    case PAGE.CHANGE_NAME: {
      const { id, newName } = action.payload;
      return set([id, 'name'], newName, state);
    }

    case PAGE.TOGGLE_EXAM_MODE: {
      const { id } = action.payload;
      return set([id, 'inExamMode'], !state[id].inExamMode, state);
    }

    case PAGE.ENABLE_SHUFFLE: {
      const { displayedBlocks, id } = action.payload;
      return compose(
        set([id, 'isShuffleBlocks'], true),
        set([id, 'displayedBlocks'], displayedBlocks)
      )(state);
    }

    case PAGE.DISABLE_SHUFFLE: {
      const { id } = action.payload;
      return compose(
        set([id, 'isShuffleBlocks'], false),
        unset([id, 'displayedBlocks'])
      )(state);
    }

    case PAGE.CHANGE_BLOCKS_QUANTITY: {
      const { count, id } = action.payload;
      return set([id, 'displayedBlocks'], count, state);
    }

    // -------------- Блоки --------------

    case BLOCK.ADD: {
      const { id, index, pageId } = action.payload;
      return update([pageId, 'blocksIds'], insertAt(index, id), state);
    }

    case BLOCK.MOVE: {
      const { id, oldIndex, newIndex, oldPageId, newPageId } = action.payload;
      return compose(
        update([newPageId, 'blocksIds'], insertAt(newIndex, id)),
        update([oldPageId, 'blocksIds'], pullAt(oldIndex))
      )(state);
    }

    case BLOCK.CLONE: {
      const {
        payload: { id, pageId },
        meta: { clonedBlock },
      } = action;
      return update(
        [pageId, 'blocksIds'],
        (blocks) =>
          insertAt(indexOf(id, blocks), first(keys(clonedBlock)), blocks),
        state
      );
    }

    case BLOCK.REMOVE: {
      const { id, pageId } = action.payload;
      return update([pageId, 'blocksIds'], pull(id), state);
    }

    // -------------- Уроки --------------

    case LESSON.ADD: {
      const {
        meta: { pageId },
      } = action;
      return set(pageId, createPage(pageId), state);
    }

    case LESSON.REMOVE_SUCCESS: {
      const { id } = action.payload;
      const {
        meta: { removedPagesIds },
      } = action;
      return omit([id, removedPagesIds], state);
    }

    case LESSON.CLONE: {
      const {
        meta: { clonedPages },
      } = action;
      return assign(state, clonedPages);
    }

    case LESSON.IMPORT_FROM_PLAYER: {
      const {
        meta: { clonedPages },
      } = action;
      return assign(clonedPages, state);
    }

    // -------------- Конструктор --------------

    case BUILDER.DOWNLOAD_LESSON_SUCCESS: {
      const { downloadedPages } = action.payload;
      return assign(downloadedPages, state);
    }

    case BUILDER.DOWNLOAD_COURSE_SUCCESS:
    case BUILDER.DOWNLOAD_LESSONS_SUCCESS: {
      const { downloadedPages } = action.payload;
      return downloadedPages;
    }

    default:
      return state;
  }
};

export default pagesReducer;
