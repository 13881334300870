import * as SHARE from '../../constants/library/share';
import { createAction } from '../../utils/actions';

export const open = (blockId) => createAction(SHARE.OPEN, { blockId });
export const save = () => createAction(SHARE.SAVE);
export const close = () => createAction(SHARE.CLOSE);
export const changeTerms = (newTerms) =>
  createAction(SHARE.CHANGE_TERMS, { newTerms });
export const changeDates = (newDates) =>
  createAction(SHARE.CHANGE_DATES, { newDates });
export const changeThemes = (newThemes) =>
  createAction(SHARE.CHANGE_THEMES, { newThemes });
export const changePersons = (newPersons) =>
  createAction(SHARE.CHANGE_PERSONS, { newPersons });
export const changeSubject = (newSubject) =>
  createAction(SHARE.CHANGE_SUBJECT, { newSubject });
