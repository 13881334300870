/* eslint-disable generator-star-spacing */

import {
  add,
  get,
  set,
  head,
  values,
  indexOf,
  compose,
  mapValues,
} from 'lodash/fp';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import * as PLAYER from '~/constants/player';
import * as actions from '~/actions';
import { apiRequest } from '~/sagas/request';

import Encryption from '~/helpers/encryption';
import {
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
  LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
  LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN,
} from '~/appConstants';
import * as selectors from '~/selectors';

const mapValuesWithKey = mapValues.convert({ cap: false });
export const setKeysAsIds = mapValuesWithKey((value, key) =>
  set('id', key, value)
);

const key = 'erdw';

/**
 * Загрузка урока в плеер
 */
function* watchDownload() {
  yield takeEvery(PLAYER.DOWNLOAD_LESSON, function*(action) {
    const {
      payload: { lessonId, publication },
    } = action;

    const state = yield select();
    const previouslyLtiResultId = selectors.player.getPreviouslyLtiResultId(
      lessonId,
      publication,
      state
    );
    // resultId нужен для lti уроков, чтобы проверить что это прохождение для LTI урока
    const resultId = yield select(get(['player', 'result', 'id']));
    if (previouslyLtiResultId && !resultId) {
      yield put(replace(`/player/resume/${previouslyLtiResultId}`));
      return;
    }

    const response = yield apiRequest(
      `publication/${lessonId}${publication ? `/${publication}` : ''}`,
      { resultId },
      'post',
      { showNotFoundOn404: true }
    );

    if (!response) {
      return;
    }

    const { error, msg, payload = {} } = response.data;

    if (!error) {
      const encryption = new Encryption();

      const {
        lesson: { content },
      } = JSON.parse(encryption.decrypt(payload, key));

      const contentWithKeys = mapValues(setKeysAsIds, content);
      const { lesson, pages, blocks } = contentWithKeys;

      yield put(
        actions.player.downloadLessonSuccess({
          lesson: compose(
            head,
            values
          )(lesson),
          pages,
          blocks,
        })
      );

      if (resultId) {
        yield put(actions.player.updateExecutionResult());
      }

      return;
    }

    if (msg === 'not_active_course_lesson') {
      yield put(
        actions.player.downloadLessonError(
          LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
          {
            courseId: payload.courseId,
            courseStatus: payload.courseStatus,
          }
        )
      );
      return;
    }

    if (msg === 'course_lesson') {
      yield put(
        actions.player.downloadLessonError(
          LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
          {
            courseId: payload.courseId,
          }
        )
      );
      return;
    }

    if (payload.startDate || payload.endDate) {
      yield put(
        actions.player.downloadLessonError(
          LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          }
        )
      );
      return;
    }

    if (msg === 'lesson_lti_closed') {
      yield put(
        actions.player.downloadLessonError(
          LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED
        )
      );
      return;
    }

    yield put(
      actions.player.downloadLessonError(LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN)
    );
  });
}

function* watchDownloadPreview() {
  yield takeEvery(PLAYER.DOWNLOAD_LESSON_PREVIEW, function*(action) {
    const {
      payload: { publicationId },
    } = action;

    const response = yield apiRequest(
      `publication/preview/${publicationId}`,
      null,
      'post',
      { showNotFoundOn404: true }
    );

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (!error) {
      const {
        lesson: { content },
      } = payload;

      const contentWithKeys = mapValues(setKeysAsIds, content);
      const { lesson, pages, blocks } = contentWithKeys;

      const lessonPublication = compose(
        head,
        values
      )(lesson);

      const publicationIndex = compose(
        indexOf(publicationId),
        get(['meta', 'publicationsIds'])
      )(lessonPublication);

      yield put(
        actions.player.downloadLessonSuccess({
          lesson: set(
            ['meta', 'publication'],
            publicationIndex >= 0 ? add(1, publicationIndex) : undefined
          )(lessonPublication),
          pages,
          blocks,
        })
      );
      return;
    }

    yield put(
      actions.player.downloadLessonError(LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN)
    );
  });
}

export default function*() {
  yield all([watchDownload(), watchDownloadPreview()]);
}
