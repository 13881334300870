import React from 'react';
import PropTypes from 'prop-types';
import * as s from './styles/Loader.styles';

const Loader = ({ className, size, color }) => (
  <s.Container size={size} className={className} color={color}>
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </s.Container>
);

const { string, number, oneOf } = PropTypes;

Loader.propTypes = {
  className: string,
  color: oneOf(['white']),
  size: number,
};

Loader.defaultProps = {
  size: 7,
};

export default Loader;
