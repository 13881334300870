import React from 'react';
import { pure, compose } from 'recompose';
import PropTypes from 'prop-types';
import withMobileDetect from '~/hocs/withMobileDetect';
import Tooltip from './Tooltip';
import { combineStyles } from '../utils/styles';
import '../styles/Icon.scss';

const createClassName = ({ variant, className, isMobile }) =>
  combineStyles(['Icon', className], [isMobile && 'mobile', variant]);

const Icon = ({
  name,
  size,
  style,
  large,
  color,
  symbol,
  variant,
  tooltip,
  onClick,
  position,
  className,
  isMobile,
  usePortal,
}) =>
  tooltip ? (
    <Tooltip
      content={tooltip}
      position={position || 'right'}
      usePortal={usePortal}
      hoverOpenDelay={100}
      variant={variant}
      className={className}
    >
      <div
        style={{ fontSize: size, color }}
        className={createClassName({ variant, isMobile })}
      >
        {name && (
          <span
            className={`bp3-icon${large ? '-large' : ''} bp3-icon-${name}`}
          />
        )}
        {symbol && <span>{symbol}</span>}
      </div>
    </Tooltip>
  ) : (
    <div
      style={{ fontSize: size, color }}
      onClick={onClick}
      className={createClassName({ className, variant, isMobile })}
    >
      {name && (
        <span
          className={`bp3-icon${large ? '-large' : ''} bp3-icon-${name}`}
          style={style}
        />
      )}
      {symbol && <span>{symbol}</span>}
    </div>
  );

const {
  bool,
  func,
  number,
  string,
  oneOfType,
  array,
  element,
  object,
} = PropTypes;

Icon.propTypes = {
  name: string, // Имя иконки
  className: string,
  size: number, // Размер иконки
  style: object, // Стили анимации загрузки
  color: string, // Цвет иконки
  large: bool, // Увеличенный размер
  symbol: oneOfType([number, string]), // Произвольный символ вместо иконки
  variant: oneOfType([array, string]), // Вариант оформления
  tooltip: oneOfType([string, element]), // Текст тултипа
  isMobile: bool.isRequired,
  position: string, // Позиция тултипа
  onClick: func, // Действие по клику
  usePortal: bool, // Рендерить тултип в одном блоке с кнопкой?
};

Icon.defaultProps = {
  position: 'right',
  usePortal: false,
};

export default compose(
  withMobileDetect,
  pure
)(Icon);
