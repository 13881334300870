import { pick } from 'lodash/fp';
import { take, call, select, put } from 'redux-saga/effects';
import * as BUILDER from '../../../constants/builder';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import request from '../../request';

const api = `${process.env.API_URL}/folder/save`;

/**
 * Сохранение папок на сервер
 */
export default function* save() {
  while (true) {
    const {
      payload: { id },
    } = yield take(BUILDER.FOLDER.SAVE);
    const state = yield select();
    const allFolders = selectors.builder.getFolders(state);
    const folder = pick(id, allFolders);
    const data = {
      id,
      content: {
        folder,
      },
    };
    const response = yield call(request, api, data);
    if (response) {
      const { error } = response.data;
      if (!error) {
        yield put(actions.builder.folder.saveSuccess());
      } else {
        yield put(actions.builder.folder.saveError());
      }
    }
  }
}
