import { css } from 'styled-components';

export const loader = css`
  width: 100%;
`;

export const panelLoader = css`
  & > div {
    display: flex;
    align-items: center;

    & > div:first-child {
      width: 25px;
      height: 25px;
      background: #e5e9ec;
      margin: 18px 0 18px 34px;
      border-radius: 100%;
    }

    & > div:last-child {
      height: 10px;
      width: 80px;
      margin-left: 20px;
      background: #e5e9ec;
      flex: 1;
      border-radius: 2px;
    }
  }
`;
