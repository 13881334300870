import * as study from '../../actions/study';

export const defaultState = {};

const coursesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case String(study.course.downloadSuccess): {
      const { downloadedCourses } = action.payload;
      return downloadedCourses;
    }

    default:
      return state;
  }
};

export default coursesReducer;
