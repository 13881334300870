import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { DragSource } from 'react-dnd';
import * as Blueprint from '@blueprintjs/core';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { compose, lifecycle } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';

import withTheming from '~/hocs/withTheming';
import { combineStyles } from '../utils/styles';
import Tooltip from './Tooltip';
import Icon from './Icon';
import Loader from './Loader';
import '../styles/Button.scss';

const LOADER_DELAY = 500;

const Button = ({
  text,
  icon,
  fill,
  large,
  canDrag,
  isMobile,
  fontSize,
  className,
  inSafeMode,
  theme,
  minimal,
  variant,
  loading,
  onClick,
  disabled,
  noDismissPopoverOnClick,
  isDragging,
  connectDragSource,
  iconTooltipContent,
  iconTooltipPosition,
  buttonTooltipContent,
}) => {
  // Показываем лоадер с задержкой, скрываем МИНИМУМ через {LOADER_DELAY} после показа
  // чтобы лоадер не мигал если запрос быстро отработал
  const [displayLoader, setDisplayLoader] = useState(false);
  const timerRef = useRef({});
  useEffect(() => {
    clearTimeout(timerRef.current.timer);
    if (loading === timerRef.current.loading) {
      return;
    }
    const { startTime } = timerRef.current;
    const diff = loading || !startTime ? 0 : Date.now() - startTime;
    const delay = LOADER_DELAY - diff;

    // eslint-disable-next-line fp/no-mutation
    timerRef.current = {
      timer: setTimeout(() => setDisplayLoader(loading), delay),
      startTime: Date.now() + delay,
      loading,
    };
  }, [loading]);
  const content = (
    <div
      onClick={!disabled ? onClick : undefined}
      className={cx(
        'bp3-button',
        !noDismissPopoverOnClick && Blueprint.Classes.POPOVER_DISMISS,
        icon && `bp3-icon-${icon}`,
        {
          'block-is-dragging': isDragging,
          'bp3-fill': fill,
          'bp3-large': large,
          'bp3-minimal': minimal,
          'bp3-loading': displayLoader,
          'bp3-disabled': disabled,
        }
      )}
    >
      {iconTooltipContent && !isDragging && (
        <div className="tooltip">
          <Icon
            usePortal
            tooltip={iconTooltipContent}
            name="help"
            position={iconTooltipPosition}
            variant="sidebar-tooltip"
          />
        </div>
      )}
      {buttonTooltipContent && !isDragging && (
        <Tooltip
          usePortal
          content={buttonTooltipContent}
          position="right"
          variant="sidebar-hint"
          tooltipClassName="dark-theme"
          // variant="block-control"
          hoverOpenDelay={1000}
        >
          <div className="hint" />
        </Tooltip>
      )}
      {displayLoader && <Loader color="white" className="loader" />}
      {text && <span className="text">{text}</span>}
    </div>
  );
  return (
    <div
      className={combineStyles(
        ['Button', className],
        [
          variant,
          isMobile && 'mobile',
          inSafeMode && fontSize,
          inSafeMode && theme,
        ]
      )}
    >
      {canDrag ? connectDragSource(content) : content}
    </div>
  );
};

const { bool, string, oneOf, oneOfType, func, array, object, node } = PropTypes;

Button.propTypes = {
  fill: bool, // Растянуть?
  text: node, // Текст кнопки
  icon: string, // Имя иконки
  large: bool, // Увеличенный размер?
  canDrag: bool,
  theme: string,
  className: string,
  isMobile: bool.isRequired,
  fontSize: string,
  inSafeMode: bool,
  variant: oneOfType([array, string]), // Вариант оформления
  minimal: bool, // Минимальное исполнение?
  loading: bool,
  onClick: func,
  disabled: bool,
  noDismissPopoverOnClick: bool, // Не закрывать поповер при клике
  isDragging: bool, // В процессе перемещения?
  iconTooltipContent: string, // Иконка с подсказкой
  buttonTooltipContent: object, // Подсказка о действии с кнопкой
  iconTooltipPosition: oneOf([
    'top',
    'left',
    'right',
    'bottom',
    'rightTop',
    'topRight',
    'bottomLeft',
  ]),
  connectDragSource: func, // Подключение в DnD
};

Button.defaultProps = {
  noDismissPopoverOnClick: false,
  iconTooltipPosition: 'left',
  connectDragSource: (_) => _,
};

const dragSource = {
  beginDrag: (props) => ({
    dragData: props.dragData,
    onDragEnd: props.onDragEnd,
  }),
  canDrag: (props) => props.canDrag,
};

const collectDragSource = (connect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});

const DefaultButton = withMobileDetect(Button);

const DraggedButton = compose(
  DragSource((props) => `sidebar-${props.type}`, dragSource, collectDragSource),
  lifecycle({
    // componentWillMount() {
    //   withMobileDetect();
    // },
    componentDidMount() {
      /* eslint-disable-next-line fp/no-this */
      const { canDrag, connectDragPreview } = this.props;
      if (canDrag) {
        connectDragPreview(getEmptyImage(), {
          captureDraggingState: true,
        });
      }
    },
  })
)(DefaultButton);

const ThemedButton = withTheming(DefaultButton);

export default DraggedButton;

export { DefaultButton, ThemedButton, DraggedButton };
