import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import {
  QUIZ_BLOCK_TYPE_CHECKBOX,
  QUIZ_BLOCK_TYPE_RADIO,
} from '../../appConstants';
import QuizContent from '~/containers/QuizContent';

const Survey = ({
  type,
  theme,
  answers,
  blockId,
  blockType,
  question,
  inPlayer,
  inReview,
  fontSize,
  comments,
  inBuilder,
  inSafeMode,
  description,
  answerOther,
  inLibraryEdit,
  inLibraryShare,
  inPresentation,
  inLibrarySearch,
  isPageCompleted,
  answersIdsOrder,
  isLessonComplete,
  answerOtherAnswer,
  isNotRegularBlock,
  isPageInVerifyMode,
  selectedAnswersIds,
}) => (
  <QuizContent
    noBlockComment
    type={type}
    theme={theme}
    blockId={blockId}
    blockType={blockType}
    answers={answers}
    comments={comments}
    question={question}
    inPlayer={inPlayer}
    inBuilder={inBuilder}
    fontSize={fontSize}
    inReview={inReview}
    inSafeMode={inSafeMode}
    answersIds={answersIdsOrder}
    description={description}
    answerOther={answerOther}
    inLibraryEdit={inLibraryEdit}
    inLibraryShare={inLibraryShare}
    inPresentation={inPresentation}
    inLibrarySearch={inLibrarySearch}
    isPageCompleted={isPageCompleted}
    isLessonComplete={isLessonComplete}
    answerOtherAnswer={answerOtherAnswer}
    isNotRegularBlock={isNotRegularBlock}
    isPageInVerifyMode={isPageInVerifyMode}
    selectedAnswersIds={selectedAnswersIds}
  />
);

const { oneOf, arrayOf, string, bool, object } = PropTypes;

Survey.propTypes = {
  type: oneOf([QUIZ_BLOCK_TYPE_RADIO, QUIZ_BLOCK_TYPE_CHECKBOX]).isRequired, // Тип теста
  theme: string, // Цветовая тема в режиме ОВЗ
  blockId: string.isRequired, // ID блока
  blockType: string.isRequired, // Тип блока
  fontSize: string, // Размер шрифта в режиме ОВЗ
  answers: object.isRequired, // Ответы
  question: string, // Вопрос
  inPlayer: bool, // В плеере?
  comments: object,
  inReview: bool, // В окне результатов?
  inBuilder: bool, // В конструкторе?
  inSafeMode: bool, // В режиме ОВЗ?
  answerOther: object, // Ответ "Другое"
  description: string, // Описание
  inLibraryEdit: bool, // В окне редактирования библиотеки?
  inLibraryShare: bool, // В окне шаринга библиотеки?
  inPresentation: bool, // В режиме презентации?
  inLibrarySearch: bool, // В окне поиска блоков?
  answersIdsOrder: arrayOf(string.isRequired).isRequired, // Порядок ответов
  isPageCompleted: bool, // Задания на странице пройдены?
  isLessonComplete: bool, // Блок в режиме просмотра?
  isNotRegularBlock: bool, // Блок не находится на контрольной странице или в олимпиаде?
  answerOtherAnswer: string, // Ответ в плеере на вариант Другое
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  selectedAnswersIds: arrayOf(string.isRequired), // Выбранные ответы
};

Survey.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  comments: {},
  answerOther: {},
  isLessonComplete: false,
  selectedAnswersIds: [],
};

export default pure(Survey);
