import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { combineStyles } from '../utils/styles';
import '../styles/Hyperlink.scss';
import Icon from '~/components/Icon';

const Hyperlink = ({ text, href, icon, newTab, variant, download }) => (
  <a
    className={combineStyles('Hyperlink', variant)}
    href={href}
    target={newTab ? '_blank' : '_self'}
    download={download}
  >
    {icon && <Icon name={icon} variant={['in-hyperlink', variant]} />}
    {text}
  </a>
);

const { string, bool, array, oneOfType } = PropTypes;

Hyperlink.propTypes = {
  icon: string, // Иконка около ссылки
  href: string, // URL ссылки
  text: string, // Текст
  newTab: bool, // Открыть в новой вкладке
  variant: oneOfType([array, string]), // Вариант оформления
  download: bool, // Ссылка для скачивания файла?
};

Hyperlink.defaultProps = {
  icon: null,
  href: undefined,
  text: undefined,
  newTab: false,
  variant: null,
  download: false,
};

export default pure(Hyperlink);
