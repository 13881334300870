import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as actions from '~/actions';
import { Button, Dialog, Input, Text } from '~/components';
import { combineStyles } from '~/utils/styles';
import '~/styles/Share.scss';
import Icon from '~/components/Icon';

// eslint-disable-next-line more/no-window
const host = `${window.location.host}${process.env.BASENAME}`;

/* eslint-disable more/no-duplicated-chains */

const CourseShare = ({
  t,
  id,
  close,
  isOpen,
  showNotice,
  publicMode,
  isMobile,
}) => (
  <Dialog
    isOpen={isOpen}
    onClose={close}
    closable
    canEscapeKeyClose
    variant="share"
  >
    <div
      className={combineStyles('Share', [isMobile && 'mobile', 'course'])}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="close">
        <Button
          minimal
          icon="osh-remove"
          onClick={close}
          variant="profile-close"
        />
      </div>
      <div className="title">
        <Text value={t('courseShare.title')} variant="share-learner-title" />
      </div>
      <Text variant="share-course-text" value={t('courseShare.description')} />
      <div className="input">
        <Input
          large
          // eslint-disable-next-line more/no-window
          value={`${window.location.protocol}//${host}player/course/${id}`}
          onCopy={() => {
            showNotice(t('courseShare.linkCopied'), 'success');
          }}
          copyable
          variant="course-share-link"
        />
      </div>
      {!publicMode && (
        <div className="info-course-text-wrapper">
          <Icon
            variant="info-course-text-icon"
            name="info-sign"
            color="#767B84"
            size={25}
          />
          <Text variant="info-course-text" value={t('courseShare.notice')} />
        </div>
      )}
    </div>
  </Dialog>
);

const { bool, func, string } = PropTypes;

CourseShare.propTypes = {
  t: func.isRequired,
  id: string.isRequired,
  close: func.isRequired, // Закрыть окно результатов
  isMobile: bool.isRequired,
  isOpen: bool.isRequired, // Окно результатов открыто?
  showNotice: func.isRequired,
  publicMode: bool.isRequired,
};

const mapDispatchToProps = {
  showNotice: actions.notice.show,
};

export default compose(
  withTranslation('screens'),
  withMobileDetect,
  connect(
    null,
    mapDispatchToProps
  )
)(CourseShare);
