import React from 'react';
import PropTypes from 'prop-types';
import { pure, compose } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import '../styles/HelpRow.scss';
import Hyperlink from '~/components/Hyperlink';

const Help = ({ variant, inPlayer, isMobile }) => (
  <div
    className={combineStyles('HelpRow', [
      isMobile && 'mobile',
      inPlayer && 'in-player',
      variant,
    ])}
  >
    <div className="mail">
      <Hyperlink
        icon="osh-mail"
        text="help@coreapp.ai"
        href="mailto:help@coreapp.ai"
        variant="helprow-mail"
      />
    </div>
    <div className="social">
      {!inPlayer && (
        <>
          <div className="icon">
            <Hyperlink
              newTab
              icon="osh-fb"
              href="https://facebook.com/coreappai/"
              variant="helprow-social"
            />
          </div>
          <div className="icon">
            <Hyperlink
              newTab
              icon="osh-vk"
              href="https://vk.com/coreapp"
              variant="helprow-social"
            />
          </div>
        </>
      )}
      <div className="icon">
        <Hyperlink
          newTab
          icon="osh-tg"
          href="https://t.me/coreapp"
          variant="helprow-social"
        />
      </div>
    </div>
  </div>
);

const { string, bool } = PropTypes;

Help.propTypes = {
  variant: string, // Вариант оформления
  inPlayer: bool, // В плеере?
  isMobile: bool.isRequired,
};

Help.defaultProps = {
  variant: undefined,
  inPlayer: false,
};

export default compose(
  withMobileDetect,
  pure
)(Help);
