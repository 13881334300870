import React from 'react';
import memoize from 'fast-memoize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { entries, sortBy, compose, pickBy, find } from 'lodash/fp';
import withMobileDetect from '~/hocs/withMobileDetect';
import { Text, FolderThumbnail } from '../components';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/Folders.scss';

const renderFolderThumbnail = memoize(
  (
    t,
    index,
    folderId,
    allLessons,
    isEditMode,
    folderName,
    openFolder,
    moveLesson,
    editFolder,
    renameFolder,
    removeFolderRequest
  ) => (
    <FolderThumbnail
      key={index}
      id={folderId}
      isEditMode={isEditMode}
      name={folderName}
      open={openFolder}
      onDragEnd={moveLesson}
      edit={editFolder}
      rename={renameFolder}
      remove={() =>
        removeFolderRequest(
          find(
            {
              meta: { folderId },
            },
            allLessons
          )
            ? t('folders.folderWithLessons')
            : t('folders.folderWithoutLessons'),
          folderId
        )
      }
    />
  )
);

const Folders = ({
  t,
  isMobile,
  openFolder,
  foldersList,
  allLessons,
  editFolder,
  moveLesson,
  renameFolder,
  removeFolderRequest,
}) => (
  <div className={combineStyles('Folders', isMobile && 'mobile')}>
    {isMobile && (
      <div className="mobile-info">
        <div className="icon">
          <span role="img" aria-label="crane">
            🏗️
          </span>
        </div>
        <div className="text">{t('folder.mobileInfo')}</div>
      </div>
    )}
    {foldersList.length >= 1 && (
      <div className="folders-title">
        <Text value={t('folders.folders')} variant="folders-list-title" />
      </div>
    )}
    <div className="folders-list">
      {foldersList.map(([folderId, { name: folderName, isEditMode }], index) =>
        renderFolderThumbnail(
          t,
          index,
          folderId,
          allLessons,
          isEditMode,
          folderName,
          openFolder,
          moveLesson,
          editFolder,
          renameFolder,
          removeFolderRequest
        )
      )}
    </div>
  </div>
);

const { array, func, object, bool } = PropTypes;

Folders.propTypes = {
  t: func.isRequired, // Функция перевода
  isMobile: bool.isRequired,
  openFolder: func.isRequired, // Открыть папку
  allLessons: object.isRequired, // Список всех уроков
  foldersList: array.isRequired, // Список папок
  moveLesson: func.isRequired, // Переместить урок
  editFolder: func.isRequired, // Редактировать папку
  renameFolder: func.isRequired, // Переименовать папку
  removeFolderRequest: func.isRequired, // Запрос на удаление папки
};

const getUserSortedFoldersIds = memoize((userId, allFolders) =>
  compose(
    sortBy(([, folder]) => folder.name.toLowerCase()),
    entries,
    pickBy((folder) => folder.creatorId === userId)
  )(allFolders)
);

const mapStateToProps = (state, ownProps) => {
  const { history: router } = ownProps;
  const userId = selectors.auth.getUserId(state);
  const allFolders = selectors.builder.getFolders(state);
  const allLessons = selectors.builder.getLessons(state);
  const foldersList = getUserSortedFoldersIds(userId, allFolders);
  return {
    userId,
    router,
    allLessons,
    foldersList,
  };
};

const mapDispatchToProps = {
  editFolder: actions.builder.folder.edit,
  saveFolder: actions.builder.folder.save,
  renameFolder: actions.builder.folder.rename,
  moveLesson: actions.builder.lesson.move,
  removeFolderRequest: actions.builder.folder.removeConfirm,
};

const handlersEnhance = withHandlers({
  renameFolder: ({ renameFolder, saveFolder }) => (id, newName) => {
    renameFolder(id, newName);
    saveFolder(id);
  },
});

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance
)(Folders);
