import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { replace as routerReplace } from 'connected-react-router';

import { parseQueryParams, removeQueryParam } from '~/utils/query-params';
import { subscribePlanSuccess, subscribePlanError } from '~/actions/payment';

export const usePaymentNotice = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { paymentStatus } = parseQueryParams(search);
  const paymentNotice = () => {
    if (paymentStatus) {
      if (paymentStatus === 'success') {
        dispatch(subscribePlanSuccess({ planName: '' }));
      }
      if (paymentStatus === 'error') {
        const errorText = '';
        dispatch(subscribePlanError(errorText));
      }
      const cleanPath = pathname + removeQueryParam('paymentStatus', search);
      dispatch(routerReplace(cleanPath));
    }
  };

  return { paymentNotice };
};
