// import React from 'react';
// import { compose } from 'lodash/fp';
// import { pure, withProps } from 'recompose';
// import { Uploader } from '../../containers';
// import { Icon, Text } from '../../components';
// import '../../styles/Block/Document.scss';

import VR from './VR';
import Video from './Video';
import Embed from './Embed';
import Image from './Image';
import Editor from './Editor';
import Status from './Quiz/Status';
import QuizComment from './Quiz/QuizComment';
import BlockComment from './Comment/BlockComment';

export { Editor, VR, Embed, Image, Video, Status, QuizComment, BlockComment };

// const createBlock = (name, icon, title, emptyText, content) =>
//   compose(
//     pure,
//     withProps(({
//       inPlayer,
//       inBuilder,
//       inLibraryEdit,
//       inPresentation,
//       inLibraryShare,
//       inLibrarySearch,
//     }) => ({
//       inEditMode: (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
//       inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
//     }))
//   )(({
//     source,
//     blockId,
//     onChange,
//     inEditMode,
//     inPreviewMode,
//   }) => (
//     <div className={name}>
//       {source ? (
//         <div className="content">
//           {content(source)}
//         </div>
//       ) : inEditMode ? (
//         <div className="uploader">
//           <Uploader
//             icon={icon}
//             title={title}
//             blockId={blockId}
//             onURLChange={(newURL) => onChange({ source: newURL })}
//           />
//         </div>
//       ) : inPreviewMode ? (
//         <div className="empty">
//           <div className="icon">
//             <Icon name={icon} />
//           </div>
//           <div className="text">
//             <Text
//               value={emptyText}
//               variant="empty-block-text"
//             />
//           </div>
//         </div>
//       ) : undefined}
//     </div>
//   ));

// export const Document = createBlock(
//   'Document',
//   'osh-documentupload',
//   'Загрузить документ .pdf .pptx .doc .rtf',
//   'Документ не загружен',
//   (source) => (
//     <iframe
//       src={`https://view.officeapps.live.com/op/embed.aspx?src=${source}`}
//       width="476px"
//       title="document"
//       height="288px"
//       frameBorder={0}
//     />
//   ),
// );
