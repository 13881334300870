import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as s from '~/components/Collapse/styles/Collapse.styles';

const { string, node } = PropTypes;

const Collapse = ({ activeName, children }) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);
  const [withAnimation, setWithAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => setWithAnimation(true), 500);
  }, []);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, [activeName, children]);

  return (
    <s.Container withAnimation={withAnimation} height={height}>
      {React.Children.map(children, (child) =>
        child.props.name === activeName
          ? React.cloneElement(child, { withAnimation, visible: true, ref })
          : React.cloneElement(child, { withAnimation, visible: false })
      )}
    </s.Container>
  );
};

Collapse.propTypes = {
  activeName: string.isRequired,
  children: node,
};

export default Collapse;
