import { get, set, update, compose, assign } from 'lodash/fp';
import * as LIBRARY from '../../constants/library';
import * as BLOCK from '../../constants/builder/block';

import quizReducer from '../builder/blocks/quiz';
import examReducer from '../builder/blocks/exam';
import answerReducer from '../builder/blocks/answer';
import imagesReducer from '../builder/blocks/images';

export const defaultState = {
  block: {
    id: null, // ID блока
    terms: null, // Термины блока
    dates: null, // Даты блока
    themes: null, // Темы блока
    subject: null, // Предмет блока
    persons: null, // Персоны блока
    content: {}, // Содержимое блока
  },
  isOpen: false, // Окно шаринга открыто?
};

const shareReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LIBRARY.SHARE.CLOSE: {
      return set('isOpen', false, state);
    }

    case LIBRARY.SHARE.OPEN: {
      const {
        meta: { block },
      } = action;
      return compose(
        set('isOpen', true),
        set('block', block)
      )(state);
    }

    case LIBRARY.SHARE.CHANGE_DATES: {
      const { newDates } = action.payload;
      return set(['block', 'dates'], newDates, state);
    }

    case LIBRARY.SHARE.CHANGE_TERMS: {
      const { newTerms } = action.payload;
      return set(['block', 'terms'], newTerms, state);
    }

    case LIBRARY.SHARE.CHANGE_THEMES: {
      const { newThemes } = action.payload;
      return set(['block', 'themes'], newThemes, state);
    }

    case LIBRARY.SHARE.CHANGE_PERSONS: {
      const { newPersons } = action.payload;
      return set(['block', 'persons'], newPersons, state);
    }

    case LIBRARY.SHARE.CHANGE_SUBJECT: {
      const { newSubject } = action.payload;
      return set(['block', 'subject'], newSubject, state);
    }

    case BLOCK.CHANGE_CONTENT: {
      const { newContent } = action.payload;
      return get(['meta', 'context', 'inLibraryShare'], action)
        ? update(
            ['block', 'content'],
            (oldContent) => assign(oldContent, newContent),
            state
          )
        : state;
    }

    case String(action.type.match(/@builder\/block\/.*/)): {
      return get(['meta', 'context', 'inLibraryShare'], action)
        ? update(
            ['block', 'content'],
            (content) =>
              get(get(['block', 'type'], state), {
                Exam: examReducer,
                Quiz: quizReducer,
                Answer: answerReducer,
                Images: imagesReducer,
              })(content, action),
            state
          )
        : state;
    }

    default:
      return state;
  }
};

export default shareReducer;
