import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { get, head } from 'lodash/fp';

import appleIcon57 from '../static/img/favicon/apple-icon-57x57.png';
import appleIcon60 from '../static/img/favicon/apple-icon-60x60.png';
import appleIcon72 from '../static/img/favicon/apple-icon-72x72.png';
import appleIcon76 from '../static/img/favicon/apple-icon-76x76.png';
import appleIcon114 from '../static/img/favicon/apple-icon-114x114.png';
import appleIcon120 from '../static/img/favicon/apple-icon-120x120.png';
import appleIcon144 from '../static/img/favicon/apple-icon-144x144.png';
import appleIcon152 from '../static/img/favicon/apple-icon-152x152.png';
import appleIcon180 from '../static/img/favicon/apple-icon-180x180.png';
import favicon32 from '../static/img/favicon/favicon-32x32.png';
import favicon96 from '../static/img/favicon/favicon-96x96.png';
import favicon16 from '../static/img/favicon/favicon-16x16.png';
import msIcon144 from '../static/img/favicon/ms-icon-144x144.png';

const Header = () => {
  const { i18n } = useTranslation('components');

  return (
    <Helmet htmlAttributes={{ lang: head(get('languages', i18n)) }}>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />

      <meta
        name="keywords"
        content="Core,e-learning publications, online education"
      />

      <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57} />
      <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60} />
      <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72} />
      <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76} />
      <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114} />
      <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
      <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-TileImage" content={msIcon144} />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="Coreapp.ai - decentralized online education platform"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Coreapp.ai - decentralized online education platform"
      />

      <title>Coreapp.ai - decentralized online education platform</title>
    </Helmet>
  );
};

export default Header;
