import React from 'react';
import PropTypes from 'prop-types';

import { withState, pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { compose } from 'lodash/fp';
import NoSSR from 'react-no-ssr';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../utils/styles';
import '../styles/Help.scss';
import Tooltip from '~/components/Tooltip';
import Hyperlink from '~/components/Hyperlink';
// import Popover from '~/components/Popover';
// import { DraggedButton as Button } from '~/components/Button';

// const items = [
//   {
//     icon: 'osh-book',
//     text: 'help.instruction',
//     href: 'https://help.sdot.ttc.kz',
//   },
//   {
//     noNewTab: true,
//     icon: 'osh-mail',
//     text: 'help.email',
//     href: 'mailto:help@coreapp.ai',
//   },
//   {
//     icon: 'osh-tg',
//     text: 'help.telegram',
//     href: 'https://t.me/coreapp',
//   },
//   {
//     icon: 'osh-vk',
//     text: 'help.vk',
//     href: 'https://vk.com/coreapp',
//   },
//   {
//     icon: 'osh-fb',
//     text: 'help.facebook',
//     href: 'https://facebook.com/coreappai/',
//   },
// ];

// const playerItems = [
//   {
//     noNewTab: true,
//     icon: 'osh-mail',
//     text: 'help.email',
//     href: 'mailto:help@coreapp.ai',
//   },
//   {
//     icon: 'osh-tg',
//     text: 'help.telegram',
//     href: 'https://t.me/coreapp',
//   },
// ];

const Help = ({
  t,
  fixed,
  inPanel,
  variant,
  inPlayer,
  isMobile,
  // togglePopover,
  disabledTooltip,
  isPopoverOpened,
}) => (
  <NoSSR>
    <div
      className={combineStyles('Help', [
        isMobile && 'mobile',
        variant,
        fixed && 'fixed',
      ])}
    >
      {((!inPlayer && !isPopoverOpened) || inPanel) && (
        <Tooltip
          usePortal={false}
          position="left"
          hoverOpenDelay={1000}
          variant={['block-control', inPanel && 'link-in-panel']}
          tooltipClassName="dark-theme"
          content={t('help.instruction')}
          disabled={disabledTooltip}
        >
          <Hyperlink
            newTab
            icon="osh-book"
            href="https://help.sdot.ttc.kz"
            text={inPanel ? t('help.instruction') : null}
            variant={
              inPanel
                ? ['lesson-controls', 'help-in-panel']
                : ['help-button', variant]
            }
          />
        </Tooltip>
      )}

      {/* <Popover
        usePortal
        icon="osh-multiimage"
        position={inPanel ? 'rightBottom' : 'top'}
        variant={['help-popover', inPanel && 'in-panel']}
        popoverWillOpen={() => togglePopover(true)}
        popoverWillClose={() => togglePopover(false)}
        target={
          <Tooltip
            usePortal={false}
            position="left"
            hoverOpenDelay={1000}
            variant={['block-control', inPanel && 'link-in-panel']}
            tooltipClassName="dark-theme"
            content={t('help.help')}
            disabled={isPopoverOpened || disabledTooltip}
          >
            <Button
              minimal
              icon="help"
              text={inPanel ? t('help.help') : null}
              variant={
                inPanel
                  ? ['lesson-controls', 'help-in-panel']
                  : `${variant}-controls-toggler`
              }
            />
          </Tooltip>
        }
        content={(inPlayer ? playerItems : items).map((item) => (
          <Tooltip
            key={item.icon}
            usePortal={false}
            position="left"
            hoverOpenDelay={1000}
            variant="block-control"
            tooltipClassName="dark-theme"
            content={t(item.text)}
            disabled={disabledTooltip}
          >
            <Hyperlink
              newTab={!item.noNewTab}
              icon={item.icon}
              href={item.href}
              variant={['help-button', variant]}
            />
          </Tooltip>
        ))}
      /> */}
    </div>
  </NoSSR>
);

const { string, func, bool } = PropTypes;

Help.propTypes = {
  t: func.isRequired, // Функция перевода
  fixed: bool,
  inPanel: bool,
  isMobile: bool.isRequired,
  variant: string, // Вариант оформления
  inPlayer: bool, // В плеере?
  // togglePopover: func.isRequired, // Переключение видимости поповера
  disabledTooltip: bool,
  isPopoverOpened: bool.isRequired, // Поповер открыт?
};

Help.defaultProps = {
  inPanel: false,
  variant: undefined,
  inPlayer: false,
  disabledTooltip: false,
};

export default compose(
  pure,
  withTranslation('components'),
  withMobileDetect,
  withState('isPopoverOpened', 'togglePopover', false)
)(Help);
