import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, filter, size } from 'lodash/fp';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withProps } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';
import NavUserBar from '~/containers/NavUserBar';
import Header from '~/components/Header';

import * as s from './styles/Course.styles';

import {
  Icon,
  Link,
  Text,
  Button,
  CircleProgressBar,
  PlayerLessonThumbnail,
} from '~/components';
import {
  COURSE_STATUS_ACTIVE,
  COURSE_STATUS_FINISHED,
  COURSE_STATUS_NOT_ACTIVE,
  RESULT_STATUS_COMPLETE,
  COURSE_USER_STATUS_INVITED,
  COURSE_USER_STATUS_ACTIVE,
  COURSE_USER_STATUS_NOT_INVITED,
} from '~/appConstants';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import { combineStyles } from '~/utils/styles';
import '~/styles/Player/Course.scss';
import Welcome from './Welcome';
import withSSRHandler from '~/hocs/withSSRHandler';
import DotLoader from '~/components/DotLoader';

const Course = ({ t, id, isMobile, downloadCourse }) => {
  const course = useSelector(selectors.course.getCourse);
  const userName = useSelector(selectors.auth.getUserName);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const isUserAnonymous = !useSelector(selectors.auth.isLoggedIn);

  const coverImageUrl = course.cover?.source;

  const isUploaded = !!course && id === course.id;

  const {
    name,
    status,
    results,
    userStatus,
    publicMode,
    description,
    publicationsIds = [],
  } = course || {};

  const completedLessonsCount = compose(
    size,
    filter({ status: RESULT_STATUS_COMPLETE })
  )(results);

  const [isWelcomeShow, toggleWelcome] = useState(false);

  const dispatch = useDispatch();

  const join = useCallback(() => dispatch(actions.course.join({ id })), [
    dispatch,
    id,
  ]);

  const isCourseActive = status === COURSE_STATUS_ACTIVE;
  const isCourseNotActive = status === COURSE_STATUS_NOT_ACTIVE;
  const isCourseFinished = status === COURSE_STATUS_FINISHED;
  const isUserActive = userStatus === COURSE_USER_STATUS_ACTIVE;
  const isUserInvited = userStatus === COURSE_USER_STATUS_INVITED;
  const isUserNotInvited = userStatus === COURSE_USER_STATUS_NOT_INVITED;

  useEffect(() => {
    !publicMode && isLoggedIn && isUserNotInvited && downloadCourse({ id });
  }, [publicMode, isLoggedIn, isUserNotInvited, downloadCourse, id]);

  return (
    <div className={combineStyles('PlayerCourse', isMobile && 'mobile')}>
      <Welcome isOpen={isWelcomeShow && isUserAnonymous} />
      <div
        className="info"
        style={
          coverImageUrl && {
            backgroundImage: `
              linear-gradient(
                to bottom,
                rgba(69, 73, 78, 0.55) 0%,
                rgba(69, 73, 78, 0.9) 100%
              ),
              url("${coverImageUrl}")
            `,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }
        }
      >
        <div className="header">
          <Header
            displayNotifications={isLoggedIn}
            leftLink={
              <Link
                variant="course-back-student"
                withIcon="arrow-left"
                to="/study/courses"
                text={t('course.toCourse')}
              />
            }
            theme="dark"
            navbar={<NavUserBar variant="dark" type="learn" />}
          />
        </div>
        {isUploaded && (
          <div className="center">
            <div className={combineStyles('status', status)}>
              <div
                className={combineStyles('icon', isCourseActive && 'active')}
              >
                <Icon name={`osh-course-${status}`} />
              </div>
              <div className="text">
                <Text value={t(`course.status_${status}`)} />
              </div>
            </div>
            <Text value={name} variant="course-name" />
            <Text value={description} variant="course-description" />
          </div>
        )}
        {isUploaded && (
          <div className="bottom">
            <CircleProgressBar
              course={course}
              total={publicationsIds.length}
              value={completedLessonsCount}
              variant="in-player-course"
            />
            <Text
              value={t('course.lessonsCompleted')}
              variant="course-counter"
            />
          </div>
        )}
      </div>
      <div className="content">
        {isUploaded ? (
          <div className="lessons-list">
            {publicationsIds.map((publicationId, index) => (
              <PlayerLessonThumbnail
                id={publicationId}
                key={publicationId}
                index={index}
                courseStatus={status}
                isUserActive={isUserActive}
                isLoggedIn={isLoggedIn}
              />
            ))}
          </div>
        ) : (
          <DotLoader css={s.loader} />
        )}
      </div>

      {isCourseNotActive && !isUserActive ? (
        <div className="footer">
          <Text
            value={t('course.courseNotActive')}
            variant="join-course-wrong"
          />
        </div>
      ) : isCourseFinished && !isUserActive ? (
        <div className="footer">
          <Text
            value={t('course.courseFinished')}
            variant="join-course-wrong"
          />
        </div>
      ) : (
        isCourseActive && (
          <>
            {!isLoggedIn && (
              <div className="footer">
                <Button
                  minimal
                  text={t('course.enter')}
                  variant="join-course"
                  onClick={() => toggleWelcome(true)}
                />
                <Text
                  value={t('course.enterText')}
                  variant="join-course-wrong"
                />
              </div>
            )}

            {isLoggedIn && (isUserInvited || (publicMode && !isUserActive)) && (
              <div className="footer">
                <Button
                  minimal
                  text={t(publicMode ? 'course.joinPublic' : 'course.join')}
                  variant="join-course"
                  onClick={join}
                />
              </div>
            )}

            {userName && !publicMode && isUserNotInvited && (
              <div className="footer">
                <Text
                  value={t('course.wrongUser')}
                  variant="join-course-wrong"
                />
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

const { string, func, bool } = PropTypes;

Course.propTypes = {
  t: func.isRequired,
  id: string.isRequired,
  isMobile: bool.isRequired,
  downloadCourse: func,
};

const mapDispatchToProps = {
  downloadCourse: actions.course.downloadCourse,
};

export default compose(
  withTranslation('containersPlayer'),
  withProps(({ match }) => ({
    id: match.params.id,
  })),
  connect(
    null,
    mapDispatchToProps
  ),
  withSSRHandler(({ downloadCourse, id }) => {
    downloadCourse({ id });
  }),
  withMobileDetect
)(Course);
