import React, { useState, useCallback, useEffect } from 'react';

import { pure } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { useGetAnswerBlocks } from './hooks/useGetAnswerBlocks';
import { downloadCourses } from '~/actions/builder';
import { downloadTutorLessons } from '~/actions/tutors';

import ReviewTask from './ReviewTask';
import ReviewAnswerBlocksContainer from './ReviewAnswerBlocksContainer';
import DotLoader from '~/components/DotLoader';
import { isRequestActive } from '~/selectors/ui';
import {
  REQUEST_NAME_ANSWER_BLOCKS_LIST_DOWNLOADING,
  STATUS_NOT_CHECKED,
} from '~/appConstants';
import Emoji, { TASK } from '~/components/Emoji';

import * as s from './styles/Review.styles';

const Review = () => {
  const { t } = useTranslation('screens');
  const [
    currentAnswerBlockResultId,
    setCurrentAnswerBlockResultId,
  ] = useState();
  const [activeTab, setActiveTab] = useState(STATUS_NOT_CHECKED);

  const {
    filterData,
    answerBlocks,
    isActiveFilter,
    onSortChange,
    onFilterReset,
    onFilterChange,
  } = useGetAnswerBlocks();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadCourses());
    dispatch(downloadTutorLessons());
  }, [dispatch]);

  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_ANSWER_BLOCKS_LIST_DOWNLOADING)
  );

  const onTabChange = useCallback((tabId) => setActiveTab(tabId), []);

  return isLoading ? (
    <DotLoader className="center" />
  ) : (
    <div css={s.container}>
      <div css={s.content}>
        {isEmpty(answerBlocks) && !isActiveFilter ? (
          <div css={s.noTasks}>
            <div css={s.noTasksEmoji}>
              <Emoji symbol={TASK} label="task" />
            </div>
            <div css={s.noTasksText}>{t('review.noAnswers')}</div>
          </div>
        ) : (
          <ReviewAnswerBlocksContainer
            activeTab={activeTab}
            filterData={filterData}
            answerBlocks={answerBlocks}
            onSort={onSortChange}
            onFilter={onFilterChange}
            onTabClick={onTabChange}
            onFilterReset={onFilterReset}
            currentAnswerBlockResultId={currentAnswerBlockResultId}
            setCurrentAnswerBlockResultId={setCurrentAnswerBlockResultId}
          />
        )}
      </div>
      {currentAnswerBlockResultId && (
        <ReviewTask
          key={currentAnswerBlockResultId}
          answerBlockResultId={currentAnswerBlockResultId}
          close={() => setCurrentAnswerBlockResultId(undefined)}
        />
      )}
    </div>
  );
};

export default pure(Review);
