import React from 'react';
import PropTypes from 'prop-types';

import { branch, compose, pure, renderNothing, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import { combineStyles } from '~/utils/styles';
import { Froala, Text } from '~/components';

const PlayerBlockComment = ({
  t,
  theme,
  inSafeMode,
  blockPassStatus,
  displayedComment,
}) => (
  <div className={combineStyles('BlockComment', inSafeMode && theme)}>
    <div
      className={combineStyles(
        'comment',
        blockPassStatus === BLOCK_PASS_STATUS_PASSED_CORRECTLY
          ? 'passed-correctly'
          : blockPassStatus === BLOCK_PASS_STATUS_PASSED_INCORRECTLY &&
              'passed-incorrectly'
      )}
    >
      <div className="title">
        <Text
          variant={
            blockPassStatus === BLOCK_PASS_STATUS_PASSED_CORRECTLY
              ? 'block-comment-success'
              : blockPassStatus === BLOCK_PASS_STATUS_PASSED_INCORRECTLY
              ? 'block-comment-fail'
              : ''
          }
          value={t('comment.commentForTask')}
        />
      </div>
      <div className="text">
        <Froala
          mode="preview"
          variant="block-comment"
          content={displayedComment}
        />
      </div>
    </div>
  </div>
);

const { string, bool, func } = PropTypes;

PlayerBlockComment.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Цветовая тема в режиме ОВЗ
  displayedComment: string.isRequired,
  inSafeMode: bool, // В режиме ОВЗ?
  blockPassStatus: string, // Статус пройденности блока
};

PlayerBlockComment.defaultProps = {
  theme: 'light',
  inSafeMode: false,
};

export default compose(
  pure,
  withTranslation('componentsBlock'),
  withProps(({ blockPassStatus, comments }) => {
    const { fail, success } = comments || {};

    const displayedComment =
      blockPassStatus === BLOCK_PASS_STATUS_PASSED_CORRECTLY
        ? success
        : blockPassStatus === BLOCK_PASS_STATUS_PASSED_INCORRECTLY
        ? fail
        : null;
    return { displayedComment };
  }),
  branch(({ displayedComment }) => !displayedComment, renderNothing)
)(PlayerBlockComment);
