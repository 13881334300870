/*
 * Вопрос с автопроверкой
 * */

import React from 'react';
import PropTypes from 'prop-types';

import { compose, isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import { withProps, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import { Text, Input, Button, Icon, Switch, Froala } from '../../components';
import { BlockComment } from '../../components/Block';
import * as helpers from '../../helpers';
import * as actions from '../../actions';
import { combineStyles } from '../../utils/styles';
import '../../styles/Exam.scss';
import VerifyBlock from '~/containers/VerifyBlock';

const Exam = ({
  t,
  theme,
  blockId,
  comment,
  inPlayer,
  inReview,
  question,
  fontSize,
  inBuilder,
  isCorrect,
  userAnswer,
  inSafeMode,
  description,
  inLibraryEdit,
  inLibraryShare,
  inPresentation,
  changeQuestion,
  correctAnswers,
  isOlympiadMode,
  inLibrarySearch,
  isPageCompleted,
  addCorrectAnswer,
  changeUserAnswer,
  isLessonComplete,
  isPageInExamMode,
  changeDescription,
  correctAnswerHint,
  isPageInVerifyMode,
  changeCorrectAnswer,
  removeCorrectAnswer,
  isBlockInVerifyMode,
  toggleCorrectAnswerType,
  disorderlyCorrectAnswersIds,
}) =>
  inPlayer || inPresentation ? (
    <div
      className={combineStyles(
        'Exam',
        inPlayer && inSafeMode && [fontSize, theme]
      )}
    >
      <div
        className={combineStyles(
          'question',
          inPlayer && inSafeMode && [fontSize, theme]
        )}
      >
        <Text
          value={question}
          variant={
            inPlayer ? (inSafeMode ? [fontSize, theme] : undefined) : undefined
          }
        />
      </div>
      <div
        className={combineStyles(
          'description',
          inPlayer && inSafeMode && [fontSize, theme]
        )}
      >
        <Froala
          mode="preview"
          content={description}
          variant={
            inPlayer ? (inSafeMode ? [fontSize, theme] : undefined) : undefined
          }
        />
      </div>
      {(isPageInVerifyMode || isBlockInVerifyMode || isLessonComplete) &&
      !isOlympiadMode ? (
        <div
          className={combineStyles(
            'answers',
            inPlayer && inSafeMode && [fontSize, theme]
          )}
        >
          {isCorrect ? (
            <div
              className={combineStyles(
                'correct',
                inPlayer && inSafeMode && [fontSize, theme]
              )}
            >
              <div
                className={combineStyles(
                  'label',
                  inPlayer && inSafeMode && [fontSize, theme]
                )}
              >
                Введен верный ответ
              </div>
              <div
                className={combineStyles(
                  'text',
                  inPlayer && inSafeMode && [fontSize, theme]
                )}
              >
                {userAnswer}
              </div>
            </div>
          ) : (
            <div
              className={combineStyles(
                'user',
                inPlayer && inSafeMode && [fontSize, theme]
              )}
            >
              <div
                className={combineStyles(
                  'label',
                  inPlayer && inSafeMode && [fontSize, theme]
                )}
              >
                {userAnswer ? t('exam.answerWrong') : t('exam.answerEmpty')}
              </div>
              {userAnswer && <div className="text">{userAnswer}</div>}
            </div>
          )}
        </div>
      ) : (
        <div
          className={combineStyles(
            'user-answer',
            inPlayer && inSafeMode && [fontSize, theme]
          )}
        >
          <Input
            value={userAnswer}
            large
            disabled={
              isBlockInVerifyMode || isPageCompleted || isLessonComplete
            }
            onChange={changeUserAnswer}
            placeholder={t('exam.changeAnswer')}
            variant={
              inPlayer
                ? inSafeMode
                  ? ['exam-answer', fontSize, theme]
                  : 'exam-answer'
                : 'exam-answer'
            }
          />
        </div>
      )}
      {isOlympiadMode
        ? isLessonComplete &&
          !isEmpty(comment) && (
            <BlockComment
              inPlayer
              blockId={blockId}
              comments={comment}
              blockPassStatus={
                isCorrect
                  ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                  : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
              }
            />
          )
        : !isPageInExamMode &&
          (isBlockInVerifyMode || isLessonComplete) &&
          !isEmpty(comment) && (
            <BlockComment
              inPlayer
              blockId={blockId}
              comments={comment}
              blockPassStatus={
                isCorrect
                  ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                  : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
              }
            />
          )}
      {(inPlayer || inPresentation) &&
        (isOlympiadMode || isPageInExamMode || isLessonComplete ? (
          undefined
        ) : (
          <VerifyBlock
            blockId={blockId}
            isBlockInVerifyMode={isBlockInVerifyMode}
          />
        ))}
    </div>
  ) : inReview ? (
    <div className="Exam">
      <div className="question">
        <Text value={question} variant="exam-question" />
      </div>
      <div className="description">
        <Froala
          mode="preview"
          content={description}
          variant={
            inPlayer ? (inSafeMode ? [fontSize, theme] : undefined) : undefined
          }
        />
      </div>
      <div className="answers">
        {!isCorrect && (
          <div className="user">
            <div className="label">
              {userAnswer ? t('exam.answerWrong') : t('exam.answerEmpty')}
            </div>
            {userAnswer && <div className="text">{userAnswer}</div>}
          </div>
        )}
        <div className="correct">
          <div className="label">
            {isCorrect ? t('exam.answerEnteredTrue') : t('exam.answerTrue')}
          </div>
          <div className="text">
            {isCorrect ? userAnswer : correctAnswerHint}
          </div>
        </div>
      </div>
    </div>
  ) : inLibrarySearch ? (
    <div className="Exam">
      <div className="question">
        <Text value={question} variant="exam-question" multiline />
      </div>
      <div className="description">
        <Froala mode="preview" content={description} />
      </div>
      <div className="correct-answers">
        {Object.entries(correctAnswers).map(([answerId, answerText]) => (
          <div className="correct-answer" key={answerId}>
            <Input
              value={answerText}
              large
              label={
                disorderlyCorrectAnswersIds.includes(answerId)
                  ? t('exam.taskDescription')
                  : undefined
              }
              variant="in-exam"
              disabled
            />
          </div>
        ))}
      </div>
    </div>
  ) : inBuilder || inLibraryEdit || inLibraryShare ? (
    <div className="Exam">
      <div className="question">
        <Text
          value={question}
          variant="exam-question"
          editable
          multiline
          onChange={changeQuestion}
          confirmOnEnterKey
          placeholder={t('exam.changeQuestion')}
        />
      </div>
      <div className="description">
        <Froala
          mode="editor"
          content={description}
          onChange={changeDescription}
          toolbarButtons={[
            'paragraphFormat',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'insertTable',
            'quote',
            'formatOL',
            'formatUL',
            'indent',
            'outdent',
            'insertLink',
            'insertImage',
            'align',
          ]}
        />
      </div>
      <div className="correct-answers">
        {Object.entries(correctAnswers).map(([answerId, answerText]) => (
          <div className="correct-answer" key={answerId}>
            <Input
              value={answerText}
              large
              label={
                disorderlyCorrectAnswersIds.includes(answerId)
                  ? t('exam.taskDescription')
                  : undefined
              }
              variant="in-exam"
              controls={[
                {
                  icon: 'osh-trash',
                  text: 'Удалить',
                  action: removeCorrectAnswer(answerId),
                },
                <div key="" className="toggler">
                  <div className="info">
                    <Text
                      value={t('exam.taskInfo')}
                      variant="exam-input-controls-custom-element-info"
                    />
                    <Icon
                      name="help"
                      tooltip={t('exam.tooltip')}
                      variant="exam-input-controls-custom-element-info"
                    />
                  </div>
                  <div className="switch">
                    <Switch
                      label={t('exam.anyOrder')}
                      checked={disorderlyCorrectAnswersIds.includes(answerId)}
                      onChange={toggleCorrectAnswerType(answerId)}
                    />
                  </div>
                </div>,
              ]}
              onChange={changeCorrectAnswer(answerId)}
              placeholder={t('exam.changeAnswer')}
            />
          </div>
        ))}
      </div>
      <div className="add-correct-answer">
        <Button
          icon="osh-addnew-little"
          text={t('exam.addAnswer')}
          onClick={addCorrectAnswer}
          variant="quiz-add-button"
        />
      </div>
      {!isPageInExamMode && (
        <BlockComment blockId={blockId} comments={comment} />
      )}
    </div>
  ) : (
    undefined
  );

const { bool, func, array, string, object } = PropTypes;

Exam.propTypes = {
  t: func.isRequired,
  comment: object, // Комментарии к блоку
  blockId: string.isRequired,
  inReview: bool, // В окне просмотра результатов
  inPlayer: bool, // В плеере?
  question: string, // Вопрос
  theme: string, // Цветовая тема в режиме ОВЗ
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inSafeMode: bool, // В режиме ОВЗ?
  isCorrect: bool.isRequired, // Задание выполнено верно?
  inBuilder: bool, // В конструкторе?
  userAnswer: string, // Ответ, введенный пользователем
  description: string, // Описание
  inLibraryEdit: bool, // В окне редактирования библиотеки?
  inLibraryShare: bool, // В окне шаринга библиотеки?
  inPresentation: bool, // В режиме презентации?
  correctAnswers: object.isRequired, // Ответы
  changeQuestion: func.isRequired, // Изменить вопрос
  inLibrarySearch: bool, // В окне поиска по библиотеке?
  isPageCompleted: bool, // Задания на странице пройдены?
  changeUserAnswer: func.isRequired, // Изменить ответ пользователя
  addCorrectAnswer: func.isRequired, // Добавить новый вариант правильного ответа
  isLessonComplete: bool, // Урок завершен?
  correctAnswerHint: string.isRequired, // Подсказка для правильного ответа
  changeDescription: func.isRequired, // Изменить описание
  isOlympiadMode: bool, // В режиме олимпиады?
  isPageInExamMode: bool, // Страница является контрольной?
  isBlockInVerifyMode: bool, // Блок в режиме проверки?
  isPageInVerifyMode: bool, // Страница в режиме проверки?
  changeCorrectAnswer: func.isRequired, // Изменить вариант правильного ответа
  removeCorrectAnswer: func.isRequired, // Удалить вариант правильного ответа
  toggleCorrectAnswerType: func.isRequired, // Переключить режим "произвольной последовательности" ответа
  disorderlyCorrectAnswersIds: array.isRequired, // ID ответов типа - "произвольная последовательность"
};

Exam.defaultProps = {
  theme: 'light',
  userAnswer: '',
  comment: {},
  fontSize: 'small',
  isLessonComplete: false,
};

const mapDispatchToProps = {
  changeQuestion: actions.builder.block.exam.changeQuestion,
  changeUserAnswer: actions.player.block.exam.changeUserAnswer,
  addCorrectAnswer: actions.builder.block.exam.addCorrectAnswer,
  changeDescription: actions.builder.block.exam.changeDescription,
  changeCorrectAnswer: actions.builder.block.exam.changeCorrectAnswer,
  removeCorrectAnswer: actions.builder.block.exam.removeCorrectAnswer,
  toggleCorrectAnswerType: actions.builder.block.exam.toggleCorrectAnswerType,
};

export default compose(
  withTranslation('containersBlock'),
  connect(
    undefined,
    mapDispatchToProps
  ),
  withHandlers({
    changeQuestion: (props) => (newQuestion) =>
      props.changeQuestion(props.blockId, newQuestion),
    changeUserAnswer: (props) => (newAnswer) => {
      const trimmedAnswer = newAnswer.replace(/&nbsp;/g, ' ').trim();
      props.changeUserAnswer(props.blockId, trimmedAnswer);
    },
    addCorrectAnswer: (props) => () => props.addCorrectAnswer(props.blockId),
    changeDescription: (props) => (newDescription) =>
      props.changeDescription(props.blockId, newDescription),
    changeCorrectAnswer: (props) => (answerId) => (newAnswer) => {
      const trimmedAnswer = newAnswer.replace(/&nbsp;/g, ' ').trim();
      props.changeCorrectAnswer(props.blockId, answerId, trimmedAnswer);
    },
    removeCorrectAnswer: (props) => (answerId) => () =>
      props.removeCorrectAnswer(props.blockId, answerId),
    toggleCorrectAnswerType: (props) => (answerId) => () =>
      props.toggleCorrectAnswerType(props.blockId, answerId),
  }),
  withProps(({ userAnswer, correctAnswers, disorderlyCorrectAnswersIds }) => ({
    isCorrect: helpers.block.isExamCorrect({
      userAnswer,
      correctAnswers,
      disorderlyCorrectAnswersIds,
    }),
    correctAnswerHint: helpers.block.generateExamCorrectAnswerHint({
      correctAnswers,
      disorderlyCorrectAnswersIds,
    }),
  }))
)(Exam);
