import { compose, set } from 'lodash/fp';
import componentsRu from '../../components/translates/default.json';
import componentsEn from '../../components/translates/default.en.json';
import componentsKz from '../../components/translates/default.kz.json';
import componentsAuthRu from '../../components/Auth/translates/default.json';
import componentsAuthEn from '../../components/Auth/translates/default.en.json';
import componentsAuthKz from '../../components/Auth/translates/default.kz.json';
import componentsBlockRu from '../../components/Block/translates/default.json';
import componentsBlockEn from '../../components/Block/translates/default.en.json';
import componentsBlockKz from '../../components/Block/translates/default.kz.json';
import componentsQuizRu from '../../components/Block/Quiz/translates/default.json';
import componentsQuizEn from '../../components/Block/Quiz/translates/default.en.json';
import componentsQuizKz from '../../components/Block/Quiz/translates/default.kz.json';
import componentsWeightRu from '../../components/Block/Weight/translates/default.json';
import componentsWeightEn from '../../components/Block/Weight/translates/default.en.json';
import componentsWeightKz from '../../components/Block/Weight/translates/default.kz.json';

import containersRu from '../../containers/translates/default.json';
import containersEn from '../../containers/translates/default.en.json';
import containersKz from '../../containers/translates/default.kz.json';
import screensRu from '../../screens/translates/default.json';
import screensEn from '../../screens/translates/default.en.json';
import screensKz from '../../screens/translates/default.kz.json';
import containersAuthRu from '../../containers/Auth/translates/default.json';
import containersAuthEn from '../../containers/Auth/translates/default.en.json';
import containersAuthKz from '../../containers/Auth/translates/default.kz.json';
import containersBlockRu from '../../containers/Block/translates/default.json';
import containersBlockEn from '../../containers/Block/translates/default.en.json';
import containersBlockKz from '../../containers/Block/translates/default.kz.json';
import containersLibraryRu from '../../containers/Library/translates/default.json';
import containersLibraryEn from '../../containers/Library/translates/default.en.json';
import containersLibraryKz from '../../containers/Library/translates/default.kz.json';
import containersPlayerRu from '../../containers/player/translates/default.json';
import containersPlayerEn from '../../containers/player/translates/default.en.json';
import containersPlayerKz from '../../containers/player/translates/default.kz.json';

import sagasRu from '../../sagas/translates/default.json';
import sagasEn from '../../sagas/translates/default.en.json';
import sagasKz from '../../sagas/translates/default.kz.json';

const ru = compose(
  set(['containers', 'player'], containersPlayerRu),
  set(['containers', 'library'], containersLibraryRu),
  set(['containers', 'block'], containersBlockRu),
  set(['containers', 'auth'], containersAuthRu),
  set(['containers'], containersRu),
  set(['screens'], screensRu),
  set(['components', 'block', 'weight'], componentsWeightRu),
  set(['components', 'block', 'quiz'], componentsQuizRu),
  set(['components', 'block'], componentsBlockRu),
  set(['components', 'auth'], componentsAuthRu),
  set(['components'], componentsRu),
  set(['sagas'], sagasRu)
)({});

const en = compose(
  set(['containers', 'player'], containersPlayerEn),
  set(['containers', 'library'], containersLibraryEn),
  set(['containers', 'block'], containersBlockEn),
  set(['containers', 'auth'], containersAuthEn),
  set(['containers'], containersEn),
  set(['screens'], screensEn),
  set(['components', 'block', 'weight'], componentsWeightEn),
  set(['components', 'block', 'quiz'], componentsQuizEn),
  set(['components', 'block'], componentsBlockEn),
  set(['components', 'auth'], componentsAuthEn),
  set(['components'], componentsEn),
  set(['sagas'], sagasEn)
)({});

const kz = compose(
  set(['containers', 'player'], containersPlayerKz),
  set(['containers', 'library'], containersLibraryKz),
  set(['containers', 'block'], containersBlockKz),
  set(['containers', 'auth'], containersAuthKz),
  set(['containers'], containersKz),
  set(['screens'], screensKz),
  set(['components', 'block', 'weight'], componentsWeightKz),
  set(['components', 'block', 'quiz'], componentsQuizKz),
  set(['components', 'block'], componentsBlockKz),
  set(['components', 'auth'], componentsAuthKz),
  set(['components'], componentsKz),
  set(['sagas'], sagasKz)
)({});

export { ru, en, kz };
