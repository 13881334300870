import { css } from 'styled-components';

export const terms = css`
  display: flex;
  margin-bottom: 12px;
  justify-content: center;

  .Checkbox {
    padding-right: 0;
  }
  .Froala {
    z-index: 100;
  }
`;
