import { buffers, eventChannel, END } from 'redux-saga';

/**
 * Канал загрузки файлов
 * https://decembersoft.com/posts/file-upload-progress-with-redux-saga/
 */
export default function uploadFile(endpoint, file) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = () => {
      emitter({ error: true });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    // eslint-disable-next-line fp/no-mutation
    xhr.onreadystatechange = () => {
      const { responseText, readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({
            success: true,
            response: responseText,
          });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open('POST', endpoint, true);
    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null; // eslint-disable-line fp/no-mutation
      xhr.abort();
    };
  }, buffers.sliding(2));
}
