import React from 'react';
import PropTypes from 'prop-types';
import { compose, keys, includes, size } from 'lodash/fp';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  withContext,
  withHandlers,
  withState,
  lifecycle,
  pure,
} from 'recompose';
import { Button, Controls, Text, Icon } from '../components';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/Lesson.scss';
import { isRequestActive } from '~/selectors/ui';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
  REQUEST_NAME_LESSON_PUBLISH,
} from '~/appConstants';
import Results from '~/containers/Results';
import Share from '~/containers/Share';
import Settings from '~/containers/Settings';
import Sidebar from '~/containers/Sidebar';
import Pages from '~/containers/Pages';

const Lesson = ({
  t,
  id,
  name,
  save,
  type,
  addPage,
  pastePage,
  publish,
  courseId,
  folderId,
  isUnsaved,
  changeName,
  inBirdView,
  goToCourse,
  goToFolder,
  publication,
  goToLessons,
  showResults,
  isUserAdmin,
  description,
  isShareOpen,
  showSettings,
  isPersisting,
  isResultsOpen,
  isSidebarOpen,
  isSettingsOpen,
  toggleBirdView,
  isOlympiadMode,
  toggleShareOpen,
  isLessonUploaded,
  changeDescription,
  openInPresentation,
  minimalSuccessScore,
  hasUnpublishedChanges,
  changeMinimalSuccessScore,
}) => (
  <div
    className={combineStyles('Lesson', [
      inBirdView && 'bird-view',
      isSidebarOpen && 'with-sidebar',
    ])}
  >
    {isResultsOpen && <Results isOlympiadMode={isOlympiadMode} />}
    <Share
      lessonId={id}
      icon="osh-share"
      isOpen={isShareOpen}
      close={() => toggleShareOpen(false)}
      text={t('lesson.controlShare')}
    />
    {isSettingsOpen && (
      <Settings
        lessonId={id}
        isUserAdmin={isUserAdmin}
        isOlympiadMode={isOlympiadMode}
      />
    )}
    <Sidebar
      type={inBirdView ? 'page' : 'block'}
      variant={inBirdView ? 'bird-view' : undefined}
      lessonId={id}
      isInFolder={!!folderId}
      inBirdView={inBirdView}
      goToFolder={goToFolder}
      goToLessons={goToLessons}
      goToCourse={courseId ? goToCourse : null}
    />
    <div className={combineStyles('scroll', type)}>
      {type && type !== LESSON_TYPE_REGULAR && (
        <div className="header">
          <div className={combineStyles('type', type)}>
            <div className="icon">
              <Icon name={`osh-lesson-${type}`} />
            </div>
            <div className="text">
              <Text value={t(`lesson.${type}Lesson`)} />
            </div>
          </div>
          <div className="tooltip">
            <Icon
              name="help"
              variant="small-gray"
              position="rightTop"
              tooltip={t(`lesson.${type}LessonHint`)}
            />
          </div>

          {type === LESSON_TYPE_EXAM && (
            <div className="score">
              <div className="input">
                <input
                  type="number"
                  className="field"
                  onClick={() => {
                    changeMinimalSuccessScore('');
                  }}
                  onChange={(e) => changeMinimalSuccessScore(+e.target.value)}
                  onBlur={(e) => changeMinimalSuccessScore(+e.target.value)}
                  value={minimalSuccessScore}
                />
                <div className="percent">%</div>
              </div>
              <div className="text">
                <Text value={t('lesson.examLessonScoreText')} />
              </div>
              <div className="icon">
                <Icon
                  name="help"
                  position="bottom"
                  variant="small-gray"
                  tooltip={t('lesson.examLessonScoreHint')}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="name">
        <Text
          value={name || ''}
          variant="lesson-name"
          editable
          onChange={changeName}
          multiline
          placeholder={t('lesson.enterLessonName')}
        />
        <Text
          value={description || ''}
          variant="lesson-description"
          editable
          onChange={changeDescription}
          multiline
          placeholder={t('lesson.enterLessonDescription')}
        />
      </div>
      <div className="pages">
        <Pages lessonId={id} publication={publication} />
      </div>
      {!inBirdView && (
        <div className="controls">
          <Button
            text={t('lesson.addPage')}
            icon="osh-addnew"
            minimal
            onClick={addPage}
            variant={['add-page', inBirdView && 'bird-view']}
          />
          <Button
            text={t('lesson.pastePage')}
            icon="import"
            minimal
            onClick={pastePage}
            variant={['paste-page', inBirdView && 'bird-view']}
          />
        </div>
      )}
    </div>
    {!inBirdView && (
      <div className="panel">
        <div className="section">
          <Controls
            type="panel"
            items={[
              {
                icon: 'cloud-upload',
                action: publish,
                variant: [
                  'publish-controls-toggler',
                  hasUnpublishedChanges && 'not-published',
                ],
                text: (
                  <div className="content">
                    <div className="status">
                      {t(
                        `lesson.controlPublish.${
                          hasUnpublishedChanges ? 'notPublished' : 'published'
                        }`
                      )}
                    </div>
                    <div className="text">
                      {t('lesson.controlPublish.text')}
                    </div>
                  </div>
                ),
              },
              {
                icon: 'osh-share',
                action: toggleShareOpen,
                variant: [
                  'share-controls-toggler',
                  !isLessonUploaded && 'disabled',
                ],
                text: t('lesson.controlShare'),
              },
              {
                icon: 'osh-results',
                action: showResults,
                text: t('lesson.controlResults'),
              },
              {
                icon: 'osh-videoupload',
                href: 'https://webinar.sdot.ttc.kz/account',
                text: t('lesson.controlWebinar'),
                buttonType: 'hyperlink',
              },
              {
                icon: 'osh-documentupload',
                href: `${process.env.KTZ_API}/questions?lessonId=${id}`,
                text: t('lesson.controlImportQuestions'),
                buttonType: 'hyperlink',
              }
            ]}
            variant="lesson"
          />
        </div>
        <div className="section">
          <Controls
            type="panel"
            items={[
              {
                icon: 'osh-birdview',
                action: toggleBirdView,
                variant: inBirdView ? 'pushed' : undefined,
                text: t('lesson.controlBirdView'),
              },
              {
                icon: 'osh-play',
                action: openInPresentation,
                text: t('lesson.controlPresentation'),
              },
              {
                icon: 'cog',
                action: showSettings,
                text: t('lesson.controlSettings'),
              },
            ]}
            variant="lesson"
          />
        </div>
        <div className="section">
          <Controls
            type="panel"
            items={[
              {
                icon: 'help',
                text: t('lesson.controlInstruction'),
                href: 'https://help.sdot.ttc.kz',
                buttonType: 'hyperlink',
              },
              isPersisting ? {
                icon: 'osh-savewarning-base',
                variant: 'gray',
                text: t('lesson.saveProgress'),
                spinner: isPersisting,
              } : isUnsaved ? {
                icon: 'osh-savewarning-bad',
                action: save,
                variant: 'red',
                text: t('lesson.connectionError'),
              } : {
                icon: 'osh-savewarning-good',
                variant: 'green',
                text: t('lesson.saved'),
              },
            ]}
            variant="lesson"
          />
        </div>
      </div>
    )}
    {inBirdView && (
      <Button
        icon="osh-remove"
        onClick={toggleBirdView}
        variant="birdview-close"
      />
    )}
  </div>
);

const { bool, string, func, number } = PropTypes;

Lesson.propTypes = {
  t: func.isRequired, // Функция перевода
  id: string.isRequired, // ID урока
  save: func.isRequired, // Сохранение урока
  name: string, // Имя урока
  type: string,
  courseId: string, // Имя урока
  folderId: string,
  isShareOpen: bool, // Окно результатов открыто?
  isResultsOpen: bool, // Окно результатов открыто?
  isSettingsOpen: bool, // Окно настроект открыто?
  isUserAdmin: bool.isRequired, // Пользователь имеет статус админа?
  isOlympiadMode: bool, // Включен режим олимпиады?
  addPage: func.isRequired, // Добавление новой страницы
  pastePage: func.isRequired, // Вставка страницы из внутреннего клипборда
  isUnsaved: bool, // В уроке есть несохраненные данные?
  inBirdView: bool, // В режиме бёрдвью?
  changeName: func.isRequired, // Переименование урока
  publication: number,
  isPersisting: bool, // Урок находится в процессе сохранения?
  goToLessons: func.isRequired, // Переход к списку уроков
  goToCourse: func.isRequired,
  goToFolder: func.isRequired,
  description: string, // Описание урока
  showResults: func.isRequired, // Показ результатов прохождения урока
  showSettings: func.isRequired, // Показ окна настроек урока
  isSidebarOpen: bool.isRequired, // Сайдбар открыт?
  toggleBirdView: func.isRequired, // Переключить режим бёрдвью
  toggleShareOpen: func,
  publish: func.isRequired,
  minimalSuccessScore: number,
  isLessonUploaded: bool, // Урок уже был загружен на сервер?
  hasUnpublishedChanges: bool.isRequired,
  changeDescription: func.isRequired, // Изменение описания урока
  openInPresentation: func.isRequired, // Открыть в режиме презентации
  changeMinimalSuccessScore: func, // Именить минимальный процент прохождения Контрольного урока
};

Lesson.defaultProps = {
  name: undefined,
  isUnsaved: undefined,
  inBirdView: undefined,
  description: undefined,
  isShareOpen: false,
  isPersisting: undefined,
  isResultsOpen: undefined,
  isSettingsOpen: undefined,
  isOlympiadMode: false,
  toggleShareOpen: undefined,
  isLessonUploaded: false,
};

const mapStateToProps = (state, ownProps) => {
  const { id = ownProps.match.params.id, history: router } = ownProps;
  const userId = selectors.auth.getUserId(state);
  const { isOpen } = selectors.results.getData(state);
  const courseId = selectors.builder.lesson.getCourseId(id, state);
  const name = selectors.builder.lesson.getName(id, state);
  const isUnsaved = selectors.builder.lesson.isUnsaved(id, state);
  const inBirdView = selectors.builder.lesson.inBirdView(id, state);
  const description = selectors.builder.lesson.getDescription(id, state);
  const isUserAdmin = selectors.auth.isUserAdmin(state);
  const isPersisting = selectors.builder.lesson.isPersisting(id, state);
  const isSidebarOpen = selectors.builder.lesson.isSidebarOpen(state);
  const type = selectors.builder.lesson.getLessonType(id, state);
  const isOlympiadMode = type === LESSON_TYPE_OLYMPIAD;
  const isSettingsOpen = state.settings.isOpen;
  const isLessonUploaded = selectors.builder.lesson.isUploaded(id, state);
  const minimalSuccessScore = selectors.builder.lesson.getMinimalSuccessScore(
    id,
    state
  );
  const creatorId = selectors.builder.lesson.getCreatorId(id, state);
  const publication = size(selectors.builder.lesson.getPublications(id, state));
  const hasUnpublishedChanges = selectors.builder.lesson.hasUnpublishedChanges(
    id
  )(state);
  const folderId = selectors.builder.lesson.getFolderId(id, state);
  const allLessonsIds = keys(selectors.builder.getLessons(state));
  const isLessonPublishing = isRequestActive(
    REQUEST_NAME_LESSON_PUBLISH,
    state
  );

  if (creatorId && userId && creatorId !== userId) {
    return null;
  }

  return {
    id,
    name,
    type,
    userId,
    courseId,
    isResultsOpen: isOpen,
    router,
    folderId,
    isUnsaved,
    inBirdView,
    description,
    isUserAdmin,
    publication,
    isPersisting,
    allLessonsIds,
    isSidebarOpen,
    isOlympiadMode,
    isSettingsOpen,
    isLessonUploaded,
    minimalSuccessScore,
    hasUnpublishedChanges,
    isLessonPublishing,
  };
};

const mapDispatchToProps = {
  save: actions.builder.lesson.save,
  downloadLesson: actions.builder.downloadLesson,
  downloadLessons: actions.builder.downloadLessons,
  addPage: actions.builder.page.add,
  pastePage: actions.builder.page.paste,
  changeName: actions.builder.lesson.changeName,
  showResults: actions.results.open,
  showNotice: actions.notice.show,
  showSettings: actions.settings.open,
  toggleBirdView: actions.builder.lesson.toggleBirdView,
  changeDescription: actions.builder.lesson.changeDescription,
  publishLesson: actions.builder.lesson.publish,
  changeMinimalSuccessScore: actions.builder.lesson.changeMinimalSuccessScore,
};

const handlersEnhance = compose(
  withHandlers({
    save: ({ id, save }) => () => save(id),
    addPage: ({ id, addPage }) => () => addPage(id),
    pastePage: ({ id, pastePage }) => () => pastePage(id),
    changeName: ({ id, changeName }) => (newName) => changeName(id, newName),
    showResults: ({ id, showResults }) => () => showResults(id),
    showSettings: ({ id, showSettings }) => () => showSettings(id),
    goToLessons: ({ id, router, toggleBirdView, inBirdView }) => () => {
      router.push('/teach');
      if (inBirdView) {
        toggleBirdView(id);
      }
    },
    goToFolder: ({ router, folderId }) => () => {
      router.push(`/teach/lessons/folder/${folderId}`);
    },
    goToCourse: ({
      id,
      courseId,
      router,
      toggleBirdView,
      inBirdView,
    }) => () => {
      router.push(`/teach/course/${courseId}`);
      if (inBirdView) {
        toggleBirdView(id);
      }
    },
    toggleShareOpen: ({ isShareOpen, toggleShareOpen }) => () => { toggleShareOpen(!isShareOpen); },
    publish: ({ publishLesson, id }) => () => { publishLesson(id); },
    toggleBirdView: ({ id, toggleBirdView }) => () => toggleBirdView(id),
    changeDescription: ({ id, changeDescription }) => (newDescription) => {
      changeDescription(id, newDescription);
    },
    openInPresentation: ({ id }) => () => {
      window.open(`${process.env.BASENAME}presentation/lesson/${id}`, '_blank');
    },
    changeMinimalSuccessScore: ({ id, changeMinimalSuccessScore }) => (score) => {
      changeMinimalSuccessScore({ id, score });
    },
  }),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line fp/no-this
      const { downloadLesson, id, allLessonsIds } = this.props;
      if (!includes(id, allLessonsIds)) {
        downloadLesson({ id });
      }
    },
    componentWillReceiveProps(nextProps) {
      /* eslint-disable fp/no-this */
      if (
        this.props.isLessonPublishing &&
        !nextProps.isLessonPublishing &&
        !this.props.courseId
      ) {
        this.props.toggleShareOpen(true);
      }
      /* eslint-enable */
    },
  })
);

const contextEnhance = withContext({ inBuilder: bool.isRequired }, () => ({ inBuilder: true }));

export default compose(
  withTranslation('containers'),
  withState('isShareOpen', 'toggleShareOpen', false),
  contextEnhance,
  connect(mapStateToProps, mapDispatchToProps),
  pure,
  handlersEnhance
)(Lesson);
