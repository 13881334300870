import * as MATCH from '../../../constants/player/block/match';
import { createAction } from '../../../utils/actions';

export const setCombinedAnswers = (blockId, combinedAnswers) =>
  createAction(MATCH.SET_COMBINED_ANSWERS, { blockId, combinedAnswers });
export const moveAnswer = (blockId, answer, newBasketId, oldBasketId) =>
  createAction(MATCH.MOVE_ANSWER, {
    blockId,
    answer,
    newBasketId,
    oldBasketId,
  });
