import React from 'react';
import memoize from 'fast-memoize';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withState, compose, pure, withHandlers } from 'recompose';
import Joyride from 'react-joyride';

import OnboardingHint from '~/components/OnboardingHint';
import { setHintPosition } from '../helpers/onboarding';

const renderOnboardingHint = memoize((type, headText, bodyText, videoPath) => (
  <OnboardingHint
    type={type}
    headText={headText}
    bodyText={bodyText}
    videoPath={videoPath}
  />
));

// Шаги онбординга
const steps = [
  {
    target: '.-blocks-step',
    placement: 'right',
    placementBeacon: 'top',
    content: renderOnboardingHint(
      'blocks',
      'onboardinghint.step_1_head',
      'onboardinghint.step_1_text',
      '../../static/videos/block.mp4'
    ),
  },
  {
    target: '.-page-step',
    placement: 'center',
    placementBeacon: 'top',
    content: renderOnboardingHint(
      'page',
      'onboardinghint.step_2_head',
      'onboardinghint.step_2_text',
      '../../static/videos/pages.mp4'
    ),
  },
  {
    target: '.-library-step',
    placement: 'right',
    placementBeacon: 'top-end',
    content: renderOnboardingHint(
      'library',
      'onboardinghint.step_3_head',
      'onboardinghint.step_3_text',
      '../../static/videos/library.mp4'
    ),
  },
  {
    target: '.-share-step',
    placement: 'left',
    content: renderOnboardingHint(
      'share',
      'onboardinghint.step_4_head',
      'onboardinghint.step_4_text',
      '../../static/videos/sharing.mp4'
    ),
  },
];

const Onboarding = ({ t, stepIndex, isHintsEnabled, handleStateChange }) => (
  <div className="Onboarding">
    <Joyride
      disableOverlayClose
      disableOverlay
      hideBackButton
      callback={(tour) => handleStateChange(tour)}
      steps={steps}
      run={isHintsEnabled}
      locale={{
        back: t('onboarding.back'),
        close: t('onboarding.close'),
        last: t('onboarding.last'),
        next: t('onboarding.next'),
        skip: t('onboarding.skip'),
      }}
      styles={{
        options: {
          arrowColor: 'transparent',
        },
        beacon: {
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        },
        beaconInner: {
          backgroundColor: '#eebc08',
        },
        beaconOuter: {
          backgroundColor: 'rgba(255, 223, 93, 0.5)',
          border: 'none',
        },
        buttonClose: {
          cursor: 'pointer',
          color: '#afafaf',
        },
        buttonNext: {
          cursor: 'pointer',
          borderRadius: '62px',
          height: '60px',
          boxShadow: `inset 0 0 8px 0 rgba(255, 255, 255, 0.27),
            0 16px 32px -12px rgba(227, 163, 13, 0.62)`,
          backgroundImage: 'linear-gradient(to bottom, #ffdf5d, #f7c62b)',
          paddingLeft: '50px',
          paddingRight: '50px',
          color: '#fff',
          fontSize: '20px',
          margin: '-5px 0 8px 9px',
          fontFamily: "'CirceRounded', Tahoma, sans-serif",
          fontWeight: '500',
        },
        tooltip: setHintPosition(stepIndex),
      }}
    />
  </div>
);

const { bool, func, number } = PropTypes;

Onboarding.propTypes = {
  t: func.isRequired, // Функция перевода
  stepIndex: number.isRequired, // Номер текущего шага онбординга
  isHintsEnabled: bool, // Подсказки включены?
  handleStateChange: func.isRequired, // Управление состоянием онбординга
};

Onboarding.defaultProps = {
  isHintsEnabled: false,
};

const enhance = compose(
  pure,
  withTranslation('containers'),
  withState('stepIndex', 'updateStepIndex', 0),
  withHandlers({
    handleStateChange: ({ updateStepIndex, showHints }) => (tour) => {
      const { status, index } = tour;
      if (status === 'finished') {
        showHints();
      }
      updateStepIndex(index);
    },
  })
);

export default enhance(Onboarding);
