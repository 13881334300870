import { set, compose } from 'lodash/fp';
import * as SETTINGS from '../constants/settings';

export const defaultState = {
  isOpen: false, // Окно настроек открыто?
  lessonId: undefined, // ID урока, для которого выводятся настройки
};

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SETTINGS.OPEN: {
      const { lessonId } = action.payload;
      return compose(
        set('isOpen', true),
        set('lessonId', lessonId)
      )(state);
    }

    case SETTINGS.CLOSE: {
      return compose(
        set('isOpen', false),
        set('lessonId', undefined)
      )(state);
    }

    default:
      return state;
  }
};

export default settingsReducer;
