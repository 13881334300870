import { css } from 'styled-components';

export const notification = css`
  width: 52px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 52px;
  color: #767b84;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgba(229, 233, 236, 0.5);
  }
`;

export const notificationCounter = css`
  min-width: 19px;
  height: 19px;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 19px;
  background: #f25200;
  color: #fff;
  font-family: Gilroy;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  position: absolute;
  top: 13px;
  padding: 0 4px;
  right: 24px;
`;
