import { some, isEqual } from 'lodash/fp';

/**
 * Создание FSA
 */
export const createAction = (type, payload = {}, meta = {}, error = false) => ({
  type,
  payload,
  meta,
  error,
});

export const createErrorAction = (type) => (message) =>
  createAction(type, { message });

/**
 * Проверка совпадения типа экшена конструктора по типу блока или с исключениями
 */
export const matchBuilderActionType = (action, type, exceptions = []) =>
  !some(isEqual(action.type), exceptions) &&
  String(action.type.match(new RegExp(`@builder/${type}/.*`)));

/**
 * Получение типа блока из типа экшена конструктора
 */
export const getBuilderActionBlockType = (action) => {
  const matcher = action.type.match(/@builder\/block\/(\w+)\//);
  return matcher ? matcher[1] : 'block';
};
