import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'lodash/fp';
import { Help, Header } from '~/components';
import { NavUserBar } from '~/containers';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import Review from './Review/Review';
import Profile from './Profile/Profile';
import LessonsScreen from './Lessons';
import CoursesScreen from './Courses';
import withSSRHandler from '~/hocs/withSSRHandler';
import EmailAlert from '~/screens/EmailAlert';

const ScreenTeach = ({ t, userPlan }) => (
  <div>
    <EmailAlert />
    <Header
      items={[
        {
          text: t('teachHeader.lessonsOlympiads'),
          mobileText: t('teachHeader.lessonsOlympiadsMobile'),
          link: '/teach',
        },
        {
          text: t('teachHeader.courses'),
          mobileText: t('teachHeader.coursesMobile'),
          link: '/teach/courses',
          variant: 'courses',
        },
        {
          text: t('teachHeader.tasksReview'),
          mobileText: t('teachHeader.tasksReviewMobile'),
          link: '/teach/review',
        },
      ]}
      navbar={<NavUserBar type="teach" />}
      type="teach"
      userPlan={userPlan}
      displayNotifications
    />

    <Switch>
      <Route path="/teach/courses" component={CoursesScreen} />
      <Route path="/teach/profile" component={Profile} />
      <Route path="/teach/review" component={Review} />
      <Route path="/teach/lessons/folder/:folderId" component={LessonsScreen} />
      <Route path="/teach" component={LessonsScreen} />
    </Switch>

    <Help fixed variant="help-in-folder" />
  </div>
);

const { func, string } = PropTypes;

ScreenTeach.propTypes = {
  t: func,
  userPlan: string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { folderId = ownProps.match.params.id, history: router } = ownProps;
  const userId = selectors.auth.getUserId(state);
  const allFolders = selectors.builder.getFolders(state);
  const allResults = selectors.study.getResults(state);
  const folderName = folderId && state.builder.folders[folderId].name;
  const list = selectors.builder.getUserSortedFolderLessonsIds(
    userId,
    folderId,
    state
  );
  const isHintsEnabled = selectors.builder.isHintsEnabled(state);
  const { isOpen } = selectors.results.getData(state);
  const userName = selectors.auth.getUserName(state);
  const { planName: userPlan } = selectors.auth.getUserSubscription(state);

  return {
    list,
    userId,
    router,
    folderId,
    userName,
    allFolders,
    folderName,
    allResults,
    userPlan,
    isHintsEnabled,
    isLessonResultsOpen: isOpen,
  };
};

const mapDispatchToProps = {
  moveLesson: actions.builder.lesson.move,
  addFolder: actions.builder.folder.add,
  download: actions.builder.downloadLessons,
  removeCourseRequest: actions.builder.course.removeConfirm,
};

export default compose(
  withTranslation('screens'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSSRHandler(({ download, userId }) => {
    download({ userId });
  })
)(ScreenTeach);
