import * as PLAYER from '../../constants/player';
import {
  LESSON_DOWNLOAD_STATUS_CLOSED,
  LESSON_DOWNLOAD_STATUS_DOWNLOADED,
  LESSON_DOWNLOAD_STATUS_DOWNLOADING,
  LESSON_DOWNLOAD_STATUS_ERROR,
  LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED,
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
  LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN,
  LESSON_DOWNLOAD_STATUS_LTI_CLOSED,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
} from '../../appConstants';

const lessonDownloadStatus = (state = null, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_LESSON:
    case PLAYER.DOWNLOAD_LESSON_PREVIEW: {
      return LESSON_DOWNLOAD_STATUS_DOWNLOADING;
    }

    case PLAYER.DOWNLOAD_LESSON_ERROR: {
      switch (action.payload.errorType) {
        case LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES:
          return LESSON_DOWNLOAD_STATUS_CLOSED;
        case LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED:
          return LESSON_DOWNLOAD_STATUS_LTI_CLOSED;
        case LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN:
          return LESSON_DOWNLOAD_STATUS_ERROR;
        case LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY:
          return LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY;
        case LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON:
          return LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON;
        default:
          return undefined;
      }
    }

    case PLAYER.DOWNLOAD_LESSON_SUCCESS: {
      return LESSON_DOWNLOAD_STATUS_DOWNLOADED;
    }

    default:
      return state;
  }
};

export default lessonDownloadStatus;
