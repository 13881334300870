import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeCard } from '~/actions/payment';

import { REQUEST_NAME_SUBSCRIBE } from '~/appConstants';
import { Dialog, Button, Icon, Checkbox } from '~/components';
import { isRequestActive } from '~/selectors/ui';
import * as s from '../styles/Plans.styles';

const getTermsLabel = (t) => {
  const text = t('subscriptionPlans.terms_text');
  const link = t('subscriptionPlans.terms_link');
  return `${text} <a href="https://coreapp.ai/docs/offer.pdf" target="_blank" rel='noreferrer noopener'>${link}</a>`;
};

const ChangeCard = ({ close, isOpen, goBack, title, description }) => {
  const { t } = useTranslation('screens');

  const dispatch = useDispatch();

  const isSubmitRequesting = useSelector(
    isRequestActive(REQUEST_NAME_SUBSCRIBE)
  );

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const submitAction = useCallback(() => {
    dispatch(changeCard());
  }, [dispatch]);

  return (
    <Dialog isOpen={isOpen} onClose={close} closable variant="plans">
      <div css={s.header}>
        <div className="back">
          <Button icon="arrow-left" onClick={goBack} variant="modal-back" />
        </div>
        <div css={s.title}>{title}</div>
        <div className="close">
          <Button icon="osh-remove" onClick={close} variant="settings-close" />
        </div>
      </div>
      <div css={s.space} />
      <div css={s.modalDescription}>{description}</div>
      <div css={s.space} />
      <div css={s.bottom}>
        <div css={s.bottomLeft}>
          <div css={s.bottomTitle}>{t('subscriptionPlans.payment')}</div>
          <div css={s.divider} />
          <div css={s.bottomRow}>
            <div css={s.infoText}>{t('subscriptionPlans.sum')}</div>
            <span css={s.priceText}>1</span>
            &nbsp;
            <span css={s.rText}>₽</span>
          </div>
          <div />
          {/* <div css={s.descriptionText}>
            {t('subscriptionPlans.description')}
          </div> */}
        </div>
        <div css={s.bottomRight}>
          <Icon css={s.icon} name="osh-visa-pay" />
          <Icon css={s.icon} name="osh-maestro-master-pay" />
          <Icon name="osh-mir-pay" />
          <br />
          <Icon css={s.tinkoffIcon} name="osh-tinkoff-pay" />
        </div>
      </div>
      <div css={s.terms}>
        <Checkbox
          label={getTermsLabel(t)}
          checked={isTermsAccepted}
          onCheck={() => setIsTermsAccepted(!isTermsAccepted)}
        />
      </div>
      <div css={s.buttons}>
        <Button
          variant="course-confirm big"
          text={t('subscriptionPlans.pay')}
          onClick={submitAction}
          disabled={isSubmitRequesting || !isTermsAccepted}
          loading={isSubmitRequesting}
        />
      </div>
    </Dialog>
  );
};

const { bool, func, string } = PropTypes;

ChangeCard.propTypes = {
  title: string,
  description: string,
  close: func.isRequired,
  goBack: func.isRequired,
  isOpen: bool.isRequired,
};

export default ChangeCard;
