import { set, compose } from 'lodash/fp';

import * as AUTH from '../constants/auth';
import * as PLAYER from '../constants/player';
import * as BUILDER from '../constants/builder';
import {
  PLAN_NAME_FREE,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_INACTIVE,
} from '~/appConstants';
import {
  changePlanSuccess,
  disableAutoPaymentSuccess,
  enableAutoPaymentSuccess,
  removeSubscriptionSuccess,
} from '~/actions/payment';

const defaultState = {
  id: undefined,
  name: undefined,
  email: undefined,
  isAdmin: false,
  isLoggedIn: false,
  secret: undefined,
  role: undefined,
  planName: undefined,
  verified: undefined,
  verificationEmailSent: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH.LOGOUT:
    case PLAYER.LOGOUT: {
      return defaultState;
    }

    case BUILDER.SEND_SECRET_SUCCESS: {
      const { secret } = action.payload;
      return set(['secret'], secret, state);
    }

    case AUTH.REQUEST_FORBIDDEN: {
      return set(['isLoggedIn'], false, state);
    }

    case AUTH.RESEND_VERIFICATION_EMAIL_SUCCESS: {
      const { date } = action.payload;
      return set(['verificationEmailSent'], date, state);
    }

    case AUTH.SIGN_IN: {
      const {
        userId,
        userName,
        userEmail,
        isAdmin,
        secret,
        role,
        verified,
        subscription = {
          planName: PLAN_NAME_FREE,
        },
      } = action.payload;

      return compose(
        set('id', userId),
        set('name', userName),
        set('email', userEmail),
        set('isAdmin', isAdmin),
        set('secret', secret),
        set('role', role),
        set('subscription', subscription),
        set('isLoggedIn', true),
        set('verified', verified)
      )(state);
    }

    case AUTH.SET_ROLE: {
      const { role } = action.payload;
      return set(['role'], role)(state);
    }

    case AUTH.CHANGE_NAME_SUCCESS: {
      const { name } = action.payload;
      return set('name', name, state);
    }

    case disableAutoPaymentSuccess.toString(): {
      return set(
        ['subscription', 'status'],
        SUBSCRIPTION_STATUS_INACTIVE,
        state
      );
    }

    case removeSubscriptionSuccess.toString():
    case changePlanSuccess.toString(): {
      const { subscription } = action.payload;
      return set('subscription', subscription, state);
    }

    case enableAutoPaymentSuccess.toString(): {
      return set(['subscription', 'status'], SUBSCRIPTION_STATUS_ACTIVE, state);
    }

    default:
      return state;
  }
};
