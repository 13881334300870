import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Text from '~/components/Text';
import Button from '~/components/Button';
import NextCardSelector from './NextCardSelector';
import AnswerScoreInput from './AnswerScoreInput';
import * as selectors from '~/selectors';
import * as talkActions from '~/actions/builder/block/talk';
import * as s from '../styles/BuilderAnswer.styles';

const BuilderAnswer = ({ blockId, answerId, i, cardId }) => {
  const { t } = useTranslation('containersBlock');
  const { content } = useSelector(selectors.builder.getBlock(blockId));

  const dispatch = useDispatch();
  const removeAnswer = () =>
    dispatch(talkActions.removeAnswer({ blockId, cardId, answerId }));
  const changeScore = (score) =>
    dispatch(talkActions.changeAnswerScore({ blockId, answerId, score }));

  const answer = content.answers[answerId];

  const changeAnswerTarget = (target) =>
    dispatch(talkActions.changeAnswerTarget({ blockId, answerId, target }));

  const addCard = () =>
    dispatch(talkActions.addCard({ blockId, targetedAnswerId: answerId }));
  const { target } = answer;

  const changeAnswerText = (text) =>
    dispatch(talkActions.changeAnswerText({ blockId, text, answerId }));

  return (
    <s.Container>
      <div css={s.top}>
        <div css={s.letter}>{String.fromCharCode(65 + i)} </div>
        <Text
          css={s.title}
          multiline
          value={answer?.answer || ''}
          onChange={changeAnswerText}
          editable
          placeholder={t('talk.inputAnswerVariant')}
        />
      </div>
      <div css={s.bottom}>
        <NextCardSelector
          selectedTarget={target}
          onCreateCard={addCard}
          onChange={changeAnswerTarget}
          blockId={blockId}
          cardId={cardId}
        />
        <s.BottomTitle>{t('talk.nextScreen')}</s.BottomTitle>
        <s.BottomTitle right>{t('talk.scoreForAnswer')}</s.BottomTitle>
        <AnswerScoreInput onChange={changeScore} value={answer.score} />
      </div>
      <Button css={s.removeButton} icon="cross" onClick={removeAnswer} />
    </s.Container>
  );
};

const { string, number } = PropTypes;

BuilderAnswer.propTypes = {
  i: number.isRequired,
  blockId: string.isRequired,
  answerId: string.isRequired,
  cardId: string.isRequired,
};

export default BuilderAnswer;
