import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, compact, get, getOr } from 'lodash/fp';
import { DragSource, DropTarget } from 'react-dnd';
import { getContext, withHandlers, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  RESULT_STATUS_COMPLETE,
  LESSON_TYPE_EXAM,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';

import { getDropPosition, connectDnD } from '../../utils/drag';
import { withDnDListener, withDnDImagePreview } from '../../utils/component';
import { Embed, Video, Editor, VR } from '../../components/Block';
import { combineStyles } from '../../utils/styles';
import { Controls } from '../../components';
import * as selectors from '../../selectors';
import * as context from '../../utils/context';
import * as actions from '../../actions';
import Quiz from './Quiz';
import Exam from './Exam';
import Match from './Match';
import Weight from './Weight';
import Answer from './Answer';
import Images from './Images';
import Survey from './Survey';
import Document from './Document';
import Feedback from './Feedback';
import FillBlank from './FillBlank';
import Instruction from './Instruction';
import '../../styles/Block.scss';
import Talk from '~/containers/Block/Talk/Talk';

const Block = ({
  t,
  type,
  clean,
  clone,
  remove,
  variant,
  inReview,
  controls,
  inBuilder,
  collection,
  isDragOver,
  isDragging,
  dropPosition,
  isEmphasized,
  renderContent,
  inPresentation,
  connectDragSource,
  connectDropTarget,
}) =>
  connectDropTarget(
    <div
      className={combineStyles('Block', [
        isDragging && 'dragging',
        type.toLowerCase(),
        inPresentation && 'in-presentation',
        isEmphasized && 'emphasized',
        variant,
        isDragOver && dropPosition && `block-drag-${dropPosition}`,
        collection
          ? Object.keys(collection).length === 1 && 'single-image'
          : undefined,
      ])}
    >
      <div className="content">
        {isDragOver && <div className="drag-stub" />}
        {renderContent()}
      </div>
      {inBuilder && !inReview && (
        <div className="controls">
          {controls}
          {connectDragSource(
            <div>
              <Controls
                type="dropdown"
                items={compact([
                  type !== 'Images' && {
                    icon: 'osh-clearcontent',
                    text: t('index.clearContent'),
                    action: clean,
                  },
                  {
                    icon: 'osh-duplicate',
                    text: t('index.dublicate'),
                    action: clone,
                  },
                  {
                    icon: 'osh-trash',
                    text: t('index.remove'),
                    action: remove,
                  },
                ])}
                variant="block"
                position="bottom"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );

const { bool, number, string, object, func, array, oneOfType } = PropTypes;

Block.propTypes = {
  t: func.isRequired, // Функция перевода
  id: string.isRequired, // ID блока
  type: string.isRequired, // Тип блока
  index: number, // Индекс блока на странице
  clone: func.isRequired, // Клонировать блок
  remove: func.isRequired, // Удалить блок
  pageId: string, // ID страницы блока
  variant: oneOfType([array, string]), // Вариант оформления
  content: object.isRequired, // Контент блока
  controls: object, // Дополнительные контролы блока
  inReview: bool, // В результатах?
  inBuilder: bool, // В плеере?
  isDragOver: bool.isRequired, // Над блоком что-то перетягивается?
  isDragging: bool.isRequired, // Блок перетягивается?
  isEmphasized: bool, // Блок выделен
  changeContent: func.isRequired, // Изменить содержимое блока
  inPresentation: bool,
  connectDragSource: func.isRequired, // Подключить источник DnD
  connectDropTarget: func.isRequired, // Подключить приемник DnD
};

Block.defaultProps = {
  index: undefined,
  pageId: undefined,
  variant: undefined,
  controls: undefined,
  inReview: undefined,
  inBuilder: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    theme,
    pageId,
    results,
    fontSize,
    lessonId,
    inPlayer,
    inReview,
    inPreview,
    inBuilder,
    inSafeMode,
    isDragging,
    resultIndex,
    inPresentation,
    connectDragSource,
    connectDropTarget,
  } = ownProps;
  const getPageData = (selector) =>
    pageId &&
    (inPlayer || inPresentation) &&
    context[selector]({ inPlayer, inPresentation })(pageId, state); // eslint-disable-line import/namespace
  const getBlockData = (selector) =>
    id &&
    (inPlayer || inPresentation) &&
    context[selector]({ inPlayer, inPresentation })(id, state); // eslint-disable-line import/namespace
  const lessonType = selectors.player.getLessonType(state);
  const isPageInExamMode = inBuilder
    ? selectors.builder.isPageInExamMode(pageId, state)
    : getPageData('isPageInExamMode') || lessonType === LESSON_TYPE_EXAM;
  const isPageCompleted = getPageData('isPageCompleted');
  const isPageInVerifyMode = getPageData('isPageInVerifyMode');
  const isBlockInVerifyMode = getBlockData('isBlockInVerifyMode');
  const isOlympiadMode = lessonType === LESSON_TYPE_OLYMPIAD;
  const isNotRegularBlock =
    isPageInExamMode || (isOlympiadMode && !isPageInVerifyMode);
  const contexts = context.create({
    theme,
    inPlayer,
    fontSize,
    inReview,
    inBuilder,
    inSafeMode,
    isOlympiadMode,
    inPresentation,
    isPageCompleted,
    isPageInExamMode,
    isNotRegularBlock,
    isPageInVerifyMode,
    isBlockInVerifyMode,
  });

  const { type, content } = context.getBlock(contexts)(
    id,
    state,
    lessonId,
    resultIndex,
    results
  );
  const { collection } = content;
  const isDropEnabled = inBuilder;
  const isDragEnabled = inBuilder;

  const result = inPreview
    ? {}
    : inPresentation
    ? get(['presentation', 'result'], state)
    : inReview
    ? results[resultIndex]
    : get(['player', 'result'], state);

  const execution = getOr({}, ['blocks', id], result);

  const isLessonComplete = get('status', result) === RESULT_STATUS_COMPLETE;

  return {
    id,
    type,
    content,
    lessonId,
    contexts,
    execution,
    isDragging,
    collection,
    inPresentation,
    isLessonComplete,
    connectDropTarget: connectDnD(isDropEnabled, connectDropTarget),
    connectDragSource: connectDnD(isDragEnabled, connectDragSource),
  };
};

const contextEnhance = getContext({
  inPreview: bool, // В плеере?
  inPlayer: bool, // В плеере?
  inReview: bool, // В окне просмотра результатов?
  inBuilder: bool, // В конструкторе?
  inPresentation: bool, // В режиме презентации?
  isPageCompleted: bool, // Задания на странице пройдены?
  isPageInExamMode: bool, // Страница в контрольном режиме?
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  updateBlockInPresentation: func,
});

const renderContent = (
  id,
  type,
  content,
  contexts,
  execution,
  onChange,
  lessonId,
  setControls,
  isLessonComplete,
  updateBlockInPresentation,
  userData
) =>
  React.createElement(
    {
      Video,
      Embed,
      VR,
      Text: Editor,
      Images,
      Instruction,
      Quiz,
      Answer,
      Exam,
      Match,
      Weight,
      Document,
      Feedback,
      Survey,
      FillBlank,
      Talk,
    }[type],
    {
      // TODO remove spread, make it props:
      ...content,
      ...contexts,
      ...execution,
      // onChange используется только в компонентах без редюсеров
      onChange,
      setControls,
      blockId: id,
      blockType: type,
      lessonId,
      userData,
      isLessonComplete,
      updateBlockInPresentation,
    },
    null
  );

const mapDispatchToProps = {
  clean: actions.builder.block.clean,
  clone: actions.builder.block.clone,
  remove: actions.builder.block.remove,
  changeContent: actions.builder.block.changeContent,
};

const handlersEnhance = compose(
  withState('controls', 'setControls', undefined),
  withHandlers({
    clean: ({ clean, id }) => () => clean(id),
    share: ({ share, id }) => () => share(id),
    clone: ({ clone, id, pageId }) => () => clone(id, pageId),
    remove: ({ remove, id, pageId }) => () => remove(id, pageId),
    renderContent: ({
      id,
      type,
      content,
      contexts,
      lessonId,
      userData,
      execution,
      setControls,
      changeContent,
      isLessonComplete,
      updateBlockInPresentation,
    }) => () =>
      renderContent(
        id,
        type,
        content,
        contexts,
        execution,
        (newContent) => changeContent(id, newContent),
        lessonId,
        setControls,
        isLessonComplete,
        updateBlockInPresentation,
        userData
      ),
  })
);

const dragSource = {
  beginDrag: (props) => {
    const { id, index, pageId, onDragEnd, onDragStart = () => {} } = props;
    onDragStart();
    return {
      id,
      index,
      pageId,
      onDragEnd,
    };
  },
  endDrag: (props) => {
    const { onDragFinally = () => {} } = props;
    onDragFinally();
  },
};

export const dropTarget = {
  hover(props, monitor, component) {
    // Установка позиции добавления блока
    const dropPosition = getDropPosition(monitor, component);
    if (monitor.getItem().dropPosition !== dropPosition) {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      monitor.getItem().dropPosition = dropPosition; // Для источника
      component.setState({ dropPosition }); // Для обертки
    }
  },
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      // Блок со страницы
      case 'page-block': {
        const { id, dropPosition, onDragEnd } = monitor.getItem();
        // Перетягиваемый блок
        const sourceId = monitor.getItem().id;
        const sourcePageId = monitor.getItem().pageId;
        const sourceIndex = monitor.getItem().index || 0; // Если блок перетягивается из библиотеки
        // Принимающий блок
        const receiverId = props.id;
        const receiverIndex = props.index;
        const receiverPageId = props.pageId;
        // Старый и новый индексы перетягиваемого блока
        const oldIndex = sourceIndex;
        const newIndex =
          sourceId === receiverId // Перетягивается сам на себя
            ? receiverIndex
            : receiverPageId === sourcePageId // Перетягивается на другую страницу
            ? sourceIndex < receiverIndex
              ? receiverIndex - { top: 1, bottom: 0 }[dropPosition]
              : receiverIndex - { top: 0, bottom: -1 }[dropPosition]
            : receiverIndex + { top: 0, bottom: 1 }[dropPosition];
        // Старая и новая страница перетягиваемого блока
        const oldPageId = sourcePageId;
        const newPageId = receiverPageId;
        onDragEnd(id, oldIndex, newIndex, oldPageId, newPageId); // moveBlock
        break;
      }
      // Блок из сайдбара
      case 'sidebar-block': {
        const {
          dragData: { type },
          onDragEnd,
          dropPosition,
        } = monitor.getItem();
        const { pageId, index } = props;
        onDragEnd(
          // addBlock
          pageId,
          type,
          index + { top: 0, bottom: 1 }[dropPosition]
        );
        break;
      }
      default:
        break;
    }
  },
};

const collectDropTarget = (dndConnect, monitor) => ({
  isDragOver: monitor.isOver(),
  connectDropTarget: dndConnect.dropTarget(),
});

const collectDragSource = (dndConnect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: dndConnect.dragSource(),
  connectDragPreview: dndConnect.dragPreview(),
});

export default compose(
  withTranslation('containersBlock'),
  contextEnhance,
  DropTarget(['sidebar-block', 'page-block'], dropTarget, collectDropTarget),
  DragSource('page-block', dragSource, collectDragSource),
  withDnDListener('dropPosition'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance,
  withDnDImagePreview
)(Block);
