import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { ThemedButton, Sticky } from '../../components';
import { combineStyles } from '../../utils/styles';
import Page from '../Page';
import RouterLink from './RouterLink';
import * as actions from '../../actions';

// TODO: Helmet для страниц

const PlayerPage = ({
  t,
  pageId,
  lessonId,
  lessonUrl,
  isPreview,
  nextPageId,
  prevPageId,
  createPageUrl,
  isOlympiadMode,
  completeLesson,
  totalTasksCount,
  isLessonComplete,
  onLessonComplete,
  executedBlocksCount,
}) => (
  <div className="page">
    <Page id={pageId} lessonId={lessonId} isInModal />
    <Sticky>
      <div className="controls-top">
        <div className="close-icon">
          <RouterLink to={lessonUrl}>
            <ThemedButton
              icon="osh-remove"
              minimal
              variant="close-page-modal-icon"
            />
          </RouterLink>
        </div>
        {prevPageId && (
          <div className="previous-page-region">
            <RouterLink to={createPageUrl(prevPageId)}>
              <ThemedButton
                icon="osh-arrow"
                minimal
                variant="player-previous-page"
              />
            </RouterLink>
          </div>
        )}
        {nextPageId && (
          <div className="next-page-region">
            <RouterLink to={createPageUrl(nextPageId)}>
              <ThemedButton
                icon="osh-arrow"
                minimal
                variant="player-next-page"
              />
            </RouterLink>
          </div>
        )}
      </div>
    </Sticky>
    <div className="controls-bottom">
      <div className="close-button">
        <RouterLink to={lessonUrl}>
          <ThemedButton
            icon="grid-view"
            text={t('index.returnToTable')}
            variant="close-page-modal-button"
          />
        </RouterLink>
      </div>
      {nextPageId && (
        <div className="next-page-button">
          <RouterLink to={createPageUrl(nextPageId)}>
            <ThemedButton
              icon="chevron-right"
              text={t('index.nextPage')}
              variant="next-page-modal-button"
              direction="right"
            />
          </RouterLink>
        </div>
      )}
      {!isPreview && !nextPageId && !isLessonComplete && completeLesson && (
        <div className="send-lesson">
          {isOlympiadMode ? (
            <ThemedButton
              icon="osh-done"
              text={t('index.finishAndResult')}
              variant={combineStyles('player-send-lesson', ['send-olympiad'])}
              onClick={onLessonComplete}
            />
          ) : (
            <ThemedButton
              icon="osh-done"
              text={t('index.finishAndSend')}
              variant="player-send-lesson"
              onClick={onLessonComplete}
              disabled={executedBlocksCount < totalTasksCount}
            />
          )}
        </div>
      )}
    </div>
  </div>
);

const { func, string, bool, number } = PropTypes;

PlayerPage.propTypes = {
  t: func.isRequired,
  pageId: string.isRequired,
  lessonId: string.isRequired,
  lessonUrl: string.isRequired,
  isPreview: bool,
  nextPageId: string,
  prevPageId: string,
  createPageUrl: func.isRequired,
  completeLesson: func,
  isOlympiadMode: bool.isRequired,
  totalTasksCount: number.isRequired,
  onLessonComplete: func,
  isLessonComplete: bool.isRequired,
  executedBlocksCount: number.isRequired,
};

const mapStateToProps = (state) => ({
  lesson: state.player.lesson,
  lessonId: state.player.lesson.id,
});

const mapDispatchToProps = { changeActivePage: actions.player.changeActivePage };

const enhance = compose(
  withTranslation('containersPlayer'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(({ lesson, pageId }) => {
    const lessonPagesIds = lesson.content.pagesIds;
    const index = lessonPagesIds.findIndex((id) => id === pageId);

    return {
      lessonPagesIds, // вынести в родителя
      nextPageId: lessonPagesIds[index + 1],
      prevPageId: lessonPagesIds[index - 1],
    };
  }),
  withHandlers({
    onLessonComplete: ({ completeLesson, toggleShowCompleteAlert }) => () => {
      toggleShowCompleteAlert(true);
      completeLesson();
    },
    createPageUrl: ({ lessonUrl }) => (pageId) => `${lessonUrl}/${pageId}`,
  })
);

export default enhance(PlayerPage);
