export const COMPLETE = '@player/lesson/Закончить прохождение урока';
export const REEXECUTE = '@player/lesson/Выполнить урок повторно';
export const SEND = '@player/lesson/Отправить урок на проверку';
export const SEND_ERROR = '@player/lesson/Ошибка отправки урока на проверку';
export const SEND_SUCCESS = '@player/lesson/Урок успешно отправлен на проверку';
export const SET_RUN_TIME =
  '@player/lesson/Установить время с начала выполнения урока';
export const START_EXECUTION = '@player/lesson/Начало прохождения урока';
export const CONNECT_USER_TO_RESULT_SUCCESS =
  '@player/lesson/Данные пользователя успешно сохранены в результате';
export const CONNECT_USER_TO_RESULT_ERROR =
  '@player/lesson/Ошибка сохранения данных пользователя в результате';
export const START_NEW_VERSION = '@player/lesson/Открыть новую версию урока';
export const CONTINUE_OLD_VERSION =
  '@player/lesson/Продолжить старую версию урока';
