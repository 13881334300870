import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@blueprintjs/select';
import { connect } from 'react-redux';
import MomentLocaleUtils from 'react-day-picker/moment'; // eslint-disable-line import/no-extraneous-dependencies
import { DateRangePicker } from '@blueprintjs/datetime';
import { withTranslation, useTranslation } from 'react-i18next';
import { compose, withState } from 'recompose';
import {
  filter,
  orderBy,
  map,
  keyBy,
  compact,
  sumBy,
  size,
  mapValues,
  constant,
} from 'lodash/fp';
import { Tabs, Icon, Input, Button } from '~/components';
import * as selectors from '~/selectors';
import { MonitoringStudentsList } from '~/containers';
import { RESULT_STATUS_COMPLETE } from '~/appConstants';
import '~/styles/CourseMonitoring.scss';
import 'moment/locale/ru';

const CourseMonitoring = ({
  t,
  name,
  sortedPublications,
  activeTab,
  setActiveTab,
  setIsActiveSearch,
  isActiveSearch,
  setSearchValue,
  searchValue,
  sortField,
  setSortField,
  tabsData,
}) => {
  const [dates, setDates] = React.useState([null, null])
  const [start, end] = dates
  const { i18n } = useTranslation('components')
  return (
    <div className="CourseMonitoring">
      {isActiveSearch && (
        <div className="search">
          <div className="search-close">
            <Icon
              onClick={() => setIsActiveSearch(false)}
              name="osh-remove"
              size="40px"
              color="#b2b6b8"
            />
          </div>
          <Input
            debounceTime={0}
            variant="in-monitoring-search"
            placeholder={t('courseMonitoring.searchPlaceholder')}
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
      )}
      <div className="container">
        <div className="search-icon" onClick={() => setIsActiveSearch(true)}>
          <Icon name="search" color="#767b84" size={20} />
        </div>
        <div className="tabs">
          <Tabs onChange={(id) => setActiveTab(id)} selectedTabId={activeTab}>
            {(start && end ? ['all'] : ['all', 'in-process', 'not-start', 'passed']).map((tabName) => (
              <Tabs.Tab
                key={tabName}
                id={tabName}
                title={start && end ? null : (
                  <Tabs.TitleWithCounter
                    count={tabsData[tabName].length}
                    title={t(`monitoringTabs.${tabName}`)}
                    isActive={tabName === activeTab}
                  />
                )}
                panel={
                  <MonitoringStudentsList
                    name={name}
                    dates={dates}
                    publications={sortedPublications}
                    students={tabsData[tabName].filter((student) =>
                      `${student.name}|${student.email}`
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )}
                  />
                }
              />
            ))}
            <Tabs.Expander />
            <Select
              filterable={false}
              items={['name', 'percentResult', 'executionTime']}
              popoverProps={{
                popoverClassName: 'select-locale-popover',
                canEscapeKeyClose: true,
              }}
              itemRenderer={(item, { modifiers, index }) => (
                <li key={index}>
                  <Button
                    minimal
                    text={t(`courseMonitoring.sortBy.${item}`)}
                    variant="select-locale"
                    active={modifiers.active}
                    onClick={() => setSortField(item)}
                  />
                </li>
              )}
            >
              <div className="sort-title">
                {t(`courseMonitoring.sortBy.${sortField}`)}&nbsp;
                <Icon name="chevron-down" variant="sort-expander" />
              </div>
            </Select>
          </Tabs>
        </div>
        <div className="filter">
          <p>{t('courseMonitoring.filter')}</p>
          <DateRangePicker
            value={dates}
            locale={i18n.language}
            onChange={setDates}
            localeUtils={MomentLocaleUtils}
          />
        </div>
      </div>
    </div>
  );
}

const { string, func, bool, array, object } = PropTypes;

CourseMonitoring.propTypes = {
  t: func.isRequired,
  name: string,
  tabsData: object.isRequired,
  sortedPublications: array.isRequired,
  activeTab: string.isRequired,
  searchValue: string.isRequired,
  setSearchValue: func.isRequired,
  isActiveSearch: bool.isRequired,
  setActiveTab: func.isRequired,
  sortField: string.isRequired,
  setSortField: func.isRequired,
  setIsActiveSearch: func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const { sortField } = ownProps;
  const course = selectors.builder.getCourse(id, state) || {};
  const lessonsIds = selectors.builder.getCourseLessonsIds(id)(state) || [];

  const { results, publications, name } = course;
  const publicationsByLessonId = keyBy('lessonId')(publications);

  const sortedPublications = compose(
    compact,
    map((lessonId) => publicationsByLessonId[lessonId])
  )(lessonsIds);

  const secToMin = (sec = 0) => Math.floor(sec / 60);
  const safeDivide = (a, b) => (b === 0 ? 1 : a / b);
  const students = compose(
    orderBy(sortField, sortField === 'name' ? 'asc' : 'desc'),
    map((student) => {
      const studentResults = compose(
        map((result) => ({
          ...result,
          executionTime: secToMin(result.executionTime),
          value: safeDivide(result.correctCount, result.totalCount),
        })),
        filter({ userId: student.userId })
      )(results);

      const completedResults = filter({ status: RESULT_STATUS_COMPLETE })(
        studentResults
      );
      const completedResultsSize = size(completedResults);

      return {
        ...student,
        completedLessons: filter({ userId: student.userId }, results),
        results: keyBy('lessonId', studentResults),
        executionTime: sumBy('executionTime', completedResults),
        completedResultsSize,
        percentCompleted: safeDivide(completedResultsSize, publications.length),
        percentResult: safeDivide(
          sumBy('value', completedResults),
          sortedPublications.length
        ),
      };
    })
  )(selectors.builder.course.getActiveStudents(id, state) || []);

  const tabsData = mapValues((filterFn) =>
    students.filter(({ completedLessons }) => filterFn(completedLessons.length))
  )({
    all: constant(true),
    'in-process': (count) => count > 0 && count < lessonsIds.length,
    'not-start': (count) => count === 0,
    passed: (count) => count === lessonsIds.length && lessonsIds.length !== 0,
  });

  return {
    name,
    tabsData,
    sortedPublications,
    lessonsIds,
    course,
    results,
  };
};

export default compose(
  withState('isActiveSearch', 'setIsActiveSearch', false),
  withState('sortField', 'setSortField', 'name'),
  withState('searchValue', 'setSearchValue', ''),
  withState('activeTab', 'setActiveTab', 'all'),
  connect(mapStateToProps),
  withTranslation('screens')
)(CourseMonitoring);
