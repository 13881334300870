/* eslint-disable fp/no-mutation */

import objectId from 'bson-objectid';

import * as COURSE from '../../constants/builder/course';
import { createAction } from '../../utils/actions';

export const add = () => createAction(COURSE.ADD, { id: objectId.generate() });
add.toString = () => COURSE.ADD;

export const changeName = (id, newName) =>
  createAction(COURSE.CHANGE_NAME, { id, newName });
changeName.toString = () => COURSE.CHANGE_NAME;

export const changeDescription = (id, newDescription) =>
  createAction(COURSE.CHANGE_DESCRIPTION, { id, newDescription });
changeDescription.toString = () => COURSE.CHANGE_DESCRIPTION;

export const change = (id, newDescription) =>
  createAction(COURSE.CHANGE_DESCRIPTION, { id, newDescription });

export const togglePublic = (id, publicMode) =>
  createAction(COURSE.TOGGLE_PUBLIC_MODE, { id, publicMode });
togglePublic.toString = () => COURSE.TOGGLE_PUBLIC_MODE;

export const setStatus = ({ id, status }) =>
  createAction(COURSE.SET_STATUS, { id, status });
setStatus.toString = () => COURSE.SET_STATUS;

export const setStatusSuccess = ({ id, status }) =>
  createAction(COURSE.SET_STATUS_SUCCESS, { id, status });
setStatusSuccess.toString = () => COURSE.SET_STATUS_SUCCESS;

export const setStatusError = () => createAction(COURSE.SET_STATUS_ERROR);
setStatusError.toString = () => COURSE.SET_STATUS_ERROR;

export const save = (id) => createAction(COURSE.SAVE, { id });
save.toString = () => COURSE.SAVE;

export const saveError = () => createAction(COURSE.SAVE_ERROR);

export const saveSuccess = () => createAction(COURSE.SAVE_SUCCESS);

export const removeCancel = () => createAction(COURSE.REMOVE_CANCEL);

export const removeAccept = (id) => createAction(COURSE.REMOVE_ACCEPT, { id });

export const removeSuccess = (id) =>
  createAction(COURSE.REMOVE_SUCCESS, { id });

export const removeError = (message) =>
  createAction(COURSE.REMOVE_ERROR, { message });

export const removeConfirm = (id, msg) =>
  createAction(COURSE.REMOVE_CONFIRM, { id, msg });

export const sendStudentsInvites = ({ courseId, emails }) =>
  createAction(COURSE.SEND_STUDENTS_INVITES, {
    courseId,
    emails,
  });

export const sendStudentsInvitesError = () =>
  createAction(COURSE.SEND_STUDENTS_INVITES_ERROR);

export const sendStudentsInvitesSuccess = (id, addedEmails, invalidQuantity) =>
  createAction(COURSE.SEND_STUDENTS_INVITES_SUCCESS, {
    id,
    addedEmails,
    invalidQuantity,
  });

export const sendTutorsInvites = ({ courseId, emails }) =>
  createAction(COURSE.SEND_TUTORS_INVITES, {
    courseId,
    emails,
  });

export const sendTutorsInvitesError = () =>
  createAction(COURSE.SEND_TUTORS_INVITES_ERROR);

export const sendTutorsInvitesSuccess = (id, addedEmails) =>
  createAction(COURSE.SEND_TUTORS_INVITES_SUCCESS, { id, addedEmails });

export const removeStudent = ({ id, email }) =>
  createAction(COURSE.REMOVE_STUDENT, { id, email });

export const removeStudentCancel = ({ id, email }) =>
  createAction(COURSE.REMOVE_STUDENT_CANCEL, { id, email });

export const removeStudentSuccess = ({ id, email }) =>
  createAction(COURSE.REMOVE_STUDENT_SUCCESS, { id, email });

export const removeTutor = ({ id, email }) =>
  createAction(COURSE.REMOVE_TUTOR, { id, email });

export const removeTutorSuccess = ({ id, email }) =>
  createAction(COURSE.REMOVE_TUTOR_SUCCESS, { id, email });

export const downloadStudents = (id) =>
  createAction(COURSE.DOWNLOAD_STUDENTS, { id });

export const downloadStudentsError = () =>
  createAction(COURSE.DOWNLOAD_STUDENTS_ERROR);

export const downloadStudentsSuccess = (studentsList) =>
  createAction(COURSE.DOWNLOAD_STUDENTS_SUCCESS, { studentsList });

export const moveLesson = ({ id, lessonId, oldIndex, newIndex }) =>
  createAction(COURSE.MOVE_LESSON, { id, lessonId, oldIndex, newIndex });
