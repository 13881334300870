import { css } from 'styled-components';

export const container = css`
  display: flex;
  flex-flow: ${(props) =>
    props.theme.isMobile ? 'column wrap' : 'row nowrap'};
`;

export const icon = css`
  font-size: 20px;
  color: #fff;
  line-height: 0;
  margin-top: -2px;
  margin-right: 16px;
`;

export const line = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const text = css`
  font-family: CirceRounded;
  font-size: 15px;
  line-height: 1.13;
  color: #fff;
`;

export const button = css`
  margin-top: ${(props) => (props.theme.isMobile ? '10px' : '0')};
  opacity: 0.7;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.21;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  transition: opacity 0.2s ease;

  :hover {
    transition: opacity 0.2s ease;
    opacity: 1;
  }
  &:first-child {
    margin-left: ${(props) => (props.theme.isMobile ? '-10px' : '16px')};
  }
`;
