import { set, unset } from 'lodash/fp';
import { TUTOR_STATUS_ACTIVE } from '~/appConstants';
import { DOWNLOAD_COURSES_SUCCESS } from '~/constants/builder';
import {
  DOWNLOAD_COURSE_SUCCESS,
  JOIN_COURSE_SUCCESS,
  REJECT_COURSE_SUCCESS,
} from '~/constants/tutors';

const defaultState = {};

const coursesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_COURSE_SUCCESS: {
      const {
        payload: { course },
      } = action;
      return set(course.id, course, state);
    }

    case DOWNLOAD_COURSES_SUCCESS: {
      const {
        payload: { asTutor },
      } = action;
      return asTutor;
    }

    case JOIN_COURSE_SUCCESS: {
      const {
        payload: { courseId },
      } = action;
      return set(
        [courseId, 'tutorInvite', 'status'],
        TUTOR_STATUS_ACTIVE,
        state
      );
    }

    case REJECT_COURSE_SUCCESS: {
      const {
        payload: { courseId },
      } = action;
      return unset([courseId, 'tutorInvite'], state);
    }
    default:
      return state;
  }
};

export default coursesReducer;
