import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withState, compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { SelectLocale } from '..';
import { Button, Input, Text } from '../../components';
import * as actions from '../../actions';
import '../../styles/Player/EnterByCode.scss';

const EnterByCode = ({ t, code, sendCode, changeCode }) => (
  <div className="EnterByCode">
    <div className="logo" />
    <div className="header">
      <div className="title">
        <Text value={t('enterbycode.enter')} variant="head-dark-center" />
      </div>
      <div className="info">
        <Text
          value={t('enterbycode.enterCode')}
          variant="medium-lighten-center"
        />
      </div>
    </div>
    <div className="input">
      <Input
        value={code}
        maxLen={4}
        variant="giant"
        onChange={changeCode}
        uppercase
        onPressEnter={(newCode) => sendCode(newCode)}
      />
    </div>
    <div className="shape" />
    <div className="button">
      <Button
        disabled={code ? code.length < 4 : true}
        text={t('enterbycode.goToLesson')}
        variant="player-confirm"
        onClick={() => sendCode(code)}
      />
    </div>
    <div className="locale">
      <SelectLocale variant="in-code" />
    </div>
  </div>
);

const { string, func } = PropTypes;

EnterByCode.propTypes = {
  t: func.isRequired, // Функция перевода
  code: string, // Код урока
  sendCode: func.isRequired, // Войти в плеер по коду
  changeCode: func.isRequired, // Ввод кода урока
};

EnterByCode.defaultProps = {
  code: undefined,
};

const enhance = withState('code', 'changeCode', undefined);

const mapDispatchToProps = {
  sendCode: actions.player.enterByCode,
};

export default compose(
  withTranslation('containersPlayer'),
  connect(
    undefined,
    mapDispatchToProps
  ),
  enhance
)(EnterByCode);
