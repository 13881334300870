import { set, compose } from 'lodash/fp';
import * as AUTH from '../constants/auth';
import * as COURSE from '../constants/course';
import * as NOTICE from '../constants/notice';
import * as PLAYER from '../constants/player';
import * as LIBRARY from '../constants/library';
import * as BUILDER from '../constants/builder';
import * as RESULTS from '../constants/results';
import * as UPLOADER from '../constants/uploader';
import * as study from '../actions/study';
import * as payment from '../actions/payment';
import {
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
  LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
} from '../appConstants';
import { GET_PAYMENT_HISTORY_ERROR } from '~/actions/profile';

export const defaultState = {
  id: undefined,
  type: 'default',
  message: undefined,
};

const generateId = () => Date.now();

const setMessage = (
  type,
  message,
  error,
  actionText,
  id = generateId(),
  progress
) =>
  compose(
    set('id', id),
    set('type', type),
    set('message', message),
    set('actionText', actionText),
    set('error', error),
    set('progress', progress)
  );

const noticeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NOTICE.SHOW: {
      const {
        type = 'default',
        message,
        error,
        actionText,
        id,
      } = action.payload;
      return setMessage(type, message, error, actionText, id)(state);
    }

    case PLAYER.LESSON.SEND_ERROR: {
      const { message } = action.payload;
      return setMessage('error', undefined, message)(state);
    }

    case RESULTS.DOWNLOAD_ERROR: {
      return setMessage('error', 'notice.resultsDownloadError')(state);
    }

    case AUTH.REQUEST_ERROR: {
      const { message } = action.payload;
      return setMessage('error', message)(state);
    }

    case AUTH.REQUEST_FORBIDDEN: {
      return setMessage('error', 'notice.requestForbidden')(state);
    }

    case BUILDER.LESSON.PUBLISH_SUCCESS: {
      return setMessage('success', 'notice.lessonPublished')(state);
    }

    case BUILDER.LESSON.CLONE: {
      const { courseId } = action.payload;
      if (courseId) {
        return setMessage('success', 'notice.lessonAddedToCourse')(state);
      } else {
        return setMessage('success', 'notice.lessonClone')(state);
      }
    }

    case BUILDER.LESSON.REMOVE_SUCCESS: {
      return setMessage('success', 'notice.lessonRemoveSuccess')(state);
    }

    case BUILDER.LESSON.REMOVE_ERROR: {
      return setMessage('success', 'notice.lessonRemoveError')(state);
    }

    case BUILDER.DOWNLOAD_LESSONS_ERROR: {
      const { message } = action.payload;
      return setMessage('error', 'notice.builderDownloadLessonError', message)(
        state
      );
    }

    case BUILDER.DOWNLOAD_LESSON_ERROR: {
      const { message } = action.payload;
      return setMessage('error', 'notice.builderDownloadLessonError', message)(
        state
      );
    }

    case String(study.results.downloadError): {
      return setMessage('error', 'notice.builderDownloadResultsError')(state);
    }

    case BUILDER.LESSON.IMPORT_FROM_PLAYER: {
      return setMessage('success', 'notice.lessonImportFromPlayer')(state);
    }

    case RESULTS.SEND_MESSAGE_ERROR: {
      return setMessage('error', 'notice.resultsSendMessageError')(state);
    }

    case PLAYER.DOWNLOAD_LESSON_ERROR: {
      const { errorType } = action.payload;
      return [
        LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
        LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED,
        LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
        LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
      ].includes(errorType)
        ? state
        : setMessage('error', 'notice.playerDownloadLessonError')(state);
    }

    case PLAYER.DOWNLOAD_EXECUTION_ERROR: {
      const { message } = action.payload;
      return setMessage(
        'error',
        'notice.playerDownloadExecutionError',
        message
      )(state);
    }

    case LIBRARY.EDIT.SAVE_ERROR: {
      const { message } = action.payload;
      return setMessage('error', 'notice.libraryEditSaveError', message)(state);
    }

    case LIBRARY.EDIT.SAVE_SUCCESS: {
      return setMessage('success', 'notice.libraryEditSaveSuccess')(state);
    }

    case LIBRARY.EDIT.REMOVE_ERROR: {
      return setMessage('error', 'notice.libraryEditRemoveError')(state);
    }

    case LIBRARY.EDIT.REMOVE_SUCCESS: {
      return setMessage('success', 'notice.libraryEditRemoveSuccess')(state);
    }

    case PLAYER.ENTER_BY_CODE_ERROR: {
      return setMessage('warning', 'notice.enterByCodeError')(state);
    }

    case UPLOADER.UPLOAD_ERROR: {
      const { message } = action.payload;
      return setMessage('error', 'notice.uploadError', message)(state);
    }

    case UPLOADER.SET_UPLOAD_PROGRESS: {
      const { newProgress, isNoticeProgress } = action.payload;
      return setMessage(
        'success',
        undefined,
        undefined,
        undefined,
        undefined,
        isNoticeProgress ? newProgress : undefined
      )(state);
    }

    case BUILDER.FOLDER.REMOVE_SUCCESS: {
      return setMessage('success', 'notice.folderRemoveSuccess')(state);
    }

    case BUILDER.FOLDER.REMOVE_ERROR: {
      return setMessage('success', 'notice.folderRemoveError')(state);
    }

    case BUILDER.COURSE.REMOVE_SUCCESS: {
      return setMessage('success', 'notice.courseRemoveSuccess')(state);
    }

    case BUILDER.COURSE.REMOVE_ERROR: {
      return setMessage('success', 'notice.courseRemoveError')(state);
    }

    case BUILDER.COURSE.SET_STATUS_SUCCESS: {
      const { status } = action.payload;
      return setMessage('success', `notice.courseSetStatus_${status}`)(state);
    }

    case GET_PAYMENT_HISTORY_ERROR: {
      return setMessage('error', 'notice.getHistoryError')(state);
    }

    case String(payment.changePlanSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.planPayed')(state);
    }

    case String(payment.subscribePlanSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.planPayed')(state);
    }

    case String(payment.subscribePlanError): {
      // const { message } = action.payload;
      // ToDo nik как передать message в нотис?
      return setMessage('error', 'notice.planPayingError')(state);
    }

    case String(payment.changeCardSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.changeCardSuccess')(state);
    }

    case String(payment.enableAutoPaymentSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.enableAutoPaymentSuccess')(state);
    }

    case String(payment.disableAutoPaymentSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.disableAutoPaymentSuccess')(state);
    }

    case String(payment.removeSubscriptionSuccess): {
      // const { planName } = action.payload;
      // ToDo nik как передать planName в нотис?
      return setMessage('success', 'notice.removeSubscriptionSuccess')(state);
    }

    case String(payment.changeCardError): {
      // const { message } = action.payload;
      // ToDo nik как передать message в нотис?
      return setMessage('error', 'notice.changeCardError')(state);
    }

    case COURSE.JOIN_SUCCESS: {
      return setMessage('success', 'notice.courseJoinSuccess')(state);
    }

    default:
      return state;
  }
};

export default noticeReducer;
