import {
  get,
  size,
  find,
  getOr,
  compose,
  isEqual,
  includes,
  toInteger,
} from 'lodash/fp';
import {
  LESSON_DOWNLOAD_STATUS_DOWNLOADED,
  RESULT_STATUS_COMPLETE,
} from '~/appConstants';

export const getLessons = (state) => {
  const lesson = get(['player', 'lesson'], state);
  return { [lesson.id]: lesson };
}; // TODO: remove: нужно для клонирования

export const getBlocks = (state) => get(['player', 'blocks'], state);

export const getBlock = (blockId, state) =>
  compose(
    get(blockId),
    getBlocks
  )(state);

export const getPages = (state) => get(['player', 'pages'], state);
export const getPage = (pageId, state) => get(pageId, getPages(state));

// Блок
export const isBlockInVerifyMode = (blockId, state) =>
  compose(
    includes(blockId),
    get(['player', 'result', 'verifyModeBlocksIds'])
  )(state);

export const getCombinedAnswers = (blockId, state) =>
  get(['blocks', blockId, 'combinedAnswers'], state.player.result);

export const getBasketSelectedAnswers = (blockId, basketId, state) =>
  get(
    ['blocks', blockId, 'answers', basketId, 'selectedAnswers'],
    state.player.result
  );

// Страница
export const isPageViewed = (pageId, state) =>
  compose(
    includes(pageId),
    get(['player', 'result', 'viewedPagesIds'])
  )(state);

export const isPageCompleted = (pageId, state) =>
  compose(
    includes(pageId),
    get(['player', 'result', 'completedPagesIds'])
  )(state);

export const isPageInExamMode = (pageId, state) =>
  get('inExamMode', getPage(pageId, state));

export const isPageInVerifyMode = (pageId, state) =>
  compose(
    includes(pageId),
    get(['player', 'result', 'verifyModePagesIds'])
  )(state);

export const isLessonComplete = compose(
  isEqual(RESULT_STATUS_COMPLETE),
  get(['player', 'result', 'status'])
);

// Урок
export const getLessonRunTime = (lessonId, state) =>
  getOr(0, ['player', 'result', 'runTime'], state);
export const getLessonEndDate = (lessonId, state) =>
  get(['meta', 'endDate'], state.player.lesson);
export const getLessonStartDate = (lessonId, state) =>
  get(['meta', 'startDate'], state.player.lesson);
export const getLessonTimeLimit = (lessonId, state) =>
  get(['meta', 'timeLimit'], state.player.lesson);
export const isLessonWithDateRange = (lessonId, state) =>
  get(['meta', 'withDateRange'], state.player.lesson);
export const getLessonType = (state) =>
  get(['meta', 'type'], get(['player', 'lesson'], state));
export const isLessonDownloaded = (state) =>
  compose(
    isEqual(LESSON_DOWNLOAD_STATUS_DOWNLOADED),
    get(['player', 'lessonDownloadStatus'])
  )(state);

// Quiz
export const displayedAnswersQuantity = (blockId, state) =>
  get(['content', 'displayedAnswersQuantity'], getBlock(blockId, state));

export const getQuizShuffledAnswers = (blockId, state) =>
  get(['player', 'result', 'blocks', blockId, 'shuffledAnswers'], state);

// Настройки олимпиады
export const getOlympiadSettings = (lessonId, state) =>
  get(['meta', 'settings', 'olympiad'], state.player.lesson);

export const getContentFileName = (blockId, state) =>
  get(['content', 'fileName'], getBlock(blockId, state));

export const getContentIsProtected = (blockId, state) =>
  get(['content', 'isProtected'], getBlock(blockId, state));

export const getLastPublication = (state) =>
  compose(
    size,
    get(['meta', 'publications'])
  )(state.player.lesson);

export const getPreviouslyLtiResultId = (lessonId, publication, state) => {
  const { previouslyExecutedResults } = state.player;
  return compose(
    get('id'),
    find({
      lessonId,
      isLtiLesson: true,
      publication: publication && toInteger(publication),
    })
  )(previouslyExecutedResults);
};
