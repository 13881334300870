import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash/fp';
import { format } from 'date-fns';
import localeRu from 'date-fns/locale/ru';
import localeEn from 'date-fns/locale/en';

import Icon from '~/components/Icon';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as s from '../../styles/Notification.styles';
import RouterLink from '~/containers/player/RouterLink';

const locales = {
  ru: localeRu,
  en: localeEn,
  kz: localeRu,
};

const AnswerReviewNotification = ({
  id,
  date,
  isUnread,
  data: { pageId, blockId, resultId, isApproved, answerName, teacherName },
}) => {
  const { t, i18n } = useTranslation('components');

  const dateString = format(date * 1000, 'DD MMMM HH:mm', {
    locale: locales[i18n.languages[0]],
  });

  return (
    <RouterLink
      to={{
        pathname: `/player/resume/${resultId}`,
        state: { fromNotification: id, pageId, blockId },
      }}
    >
      <div css={s.item} isUnread={isUnread}>
        <div css={s.avatar}>
          <div css={s.itemApproved} isApproved={isApproved}>
            {isApproved ? (
              <Icon name="small-tick" />
            ) : (
              <Icon name="small-cross" />
            )}
          </div>
        </div>
        <div css={s.itemContent}>
          <strong>{teacherName}</strong>
          {` ${t(
            isApproved ? 'notification.approved' : 'notification.reject'
          )} ${t('notification.answerToTheTask')} «${truncate(
            {
              length: 20,
              omission: '…',
            },
            answerName
          )}» ${t('notification.leftComment')}`}
          <div css={s.date}>{dateString}</div>
        </div>
      </div>
    </RouterLink>
  );
};

const { shape, string, bool, number } = PropTypes;

AnswerReviewNotification.propTypes = {
  id: string.isRequired,
  data: shape({
    answerName: string.isRequired,
    teacherName: string.isRequired,
    isApproved: bool.isRequired,
    blockId: string.isRequired,
    lessonId: string.isRequired,
    publication: number.isRequired,
    pageId: string.isRequired,
  }).isRequired,
  date: number.isRequired,
  isUnread: bool,
};

export default withMobileDetect(pure(AnswerReviewNotification));
