import { put, take } from 'redux-saga/effects';
import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import { apiRequest } from '../../request';

/**
 * Удаление папки с сервера
 */
export default function* remove() {
  while (true) {
    const {
      payload: { id: courseId },
    } = yield take(BUILDER.COURSE.REMOVE_ACCEPT);
    // Данные для запроса
    const data = { id: courseId };
    const response = yield apiRequest('course/delete', data);
    if (response) {
      if (!response.error) {
        yield put(actions.builder.course.removeSuccess(courseId));
      } else {
        yield put(actions.builder.course.removeError(response.msg));
      }
    }
  }
}
