import objectId from 'bson-objectid';
import {
  set,
  get,
  keys,
  getOr,
  filter,
  values,
  isEmpty,
  compose,
  includes,
} from 'lodash/fp';
import { push } from 'connected-react-router';
import * as LESSON from '~/constants/builder/lesson';
import * as BLOCK from '~/constants/builder/block';
import * as QUIZ from '~/constants/builder/block/quiz';
import * as MATCH from '~/constants/builder/block/match';
import * as TALK from '~/actions/builder/block/talk';
import * as FILLBLANK from '~/constants/builder/block/fillblank';
import * as EXAM from '~/constants/builder/block/exam';
import * as PAGE from '~/constants/builder/page';

import * as selectors from '../../selectors';
import * as context from '../../utils/context';
import * as helpers from '../../helpers';
import * as actions from '../../actions';
import {
  matchBuilderActionType,
  getBuilderActionBlockType,
} from '../../utils/actions';
import { UPLOAD_SUCCESS } from '../../constants/uploader';
import { taskTypes } from '~/appConstants';

const generateId = objectId.generate;
const generateTimestamp = Date.now;

/**
 * Создание нового урока
 */
export const add = (state) => (next) => (action) => {
  if (action.type === LESSON.ADD) {
    const pageId = generateId();
    const creatorId = selectors.auth.getUserId(state.getState());
    const createdAt = helpers.lesson.convertTimestamp(generateTimestamp());
    return next(
      set(
        'meta',
        {
          pageId,
          createdAt,
          creatorId,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};

/**
 * Клонирование урока в конструкторе или импортирование из плеера
 */
export const clone = (state) => (next) => (action) => {
  if ([LESSON.CLONE, LESSON.IMPORT_FROM_PLAYER].includes(action.type)) {
    const lessonId = action.payload.id;
    const { content, courseId, notOpenAfterClone } = action.payload;
    const store = state.getState();
    const contexts = {
      inPlayer: action.type === LESSON.IMPORT_FROM_PLAYER,
      inBuilder: action.type === LESSON.CLONE,
    };
    const userId = selectors.auth.getUserId(store);
    const allPages = content
      ? content.pages
      : context.getPages(contexts)(store);
    const allBlocks = content
      ? content.blocks
      : context.getBlocks(contexts)(store);
    // ToDo можно заменить allLessons на lesson, здесь нет смысла в allLessons
    const allLessons = content
      ? content.lesson
      : contexts.inPlayer
      ? { [lessonId]: store.player.lesson }
      : context.getLessons(contexts)(store);

    const {
      content: { pagesIds },
      meta: { folderId },
    } = allLessons[lessonId];
    // ToDo передавать lesson вместо lessonId и allLessons
    const blocksIds = helpers.lesson.getBlocksIds(
      lessonId,
      allLessons,
      allPages,
      allBlocks
    );
    const { lesson, pages, blocks } = helpers.lesson.clone(
      folderId,
      lessonId,
      userId,
      pagesIds,
      blocksIds,
      allLessons,
      allPages,
      allBlocks,
      courseId
    );
    const result = next(
      set(
        'meta',
        {
          clonedPages: pages,
          clonedBlocks: blocks,
          clonedLesson: lesson,
        },
        action
      )
    );
    if (!notOpenAfterClone) {
      state.dispatch(push(`/builder/lesson/${keys(lesson)[0]}`));
    }
    return result;
  } else {
    return next(action);
  }
};

/**
 * Удаление урока
 */
export const remove = (state) => (next) => (action) => {
  if (action.type === LESSON.REMOVE_SUCCESS) {
    const lessonId = action.payload.id;
    const store = state.getState();
    const allPages = selectors.builder.getPages(store);
    const allBlocks = selectors.builder.getBlocks(store);
    const allLessons = selectors.builder.getLessons(store);
    const lessonPagesIds = allLessons[lessonId].content.pagesIds;
    const lessonBlocksIds = helpers.lesson.getBlocksIds(
      lessonId,
      allLessons,
      allPages,
      allBlocks
    );
    return next(
      set(
        'meta',
        {
          removedPagesIds: lessonPagesIds,
          removedBlocksIds: lessonBlocksIds,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};

/**
 * Определение изменений в уроке
 */
export const detectChanges = (state) => (next) => (action) => {
  const store = state.getState();
  const allPages = selectors.builder.getPages(store);
  const allLessons = selectors.builder.getLessons(store);
  const clonedLessonId = action.meta && keys(action.meta.clonedLesson)[0];
  const saveLessonRequest = (lessonId) =>
    state.dispatch(actions.builder.lesson.save(lessonId));

  switch (action.type) {
    case matchBuilderActionType(action, 'lesson', [
      LESSON.SAVE,
      LESSON.PUBLISH,
      LESSON.PUBLISH_SUCCESS,
      LESSON.PUBLISH_ERROR,
      LESSON.SAVE_ERROR,
      LESSON.SAVE_SUCCESS,
      LESSON.OPEN_SIDEBAR,
      LESSON.CLOSE_SIDEBAR,
      LESSON.REMOVE_CONFIRM,
      LESSON.REMOVE_ACCEPT,
      LESSON.REMOVE_ERROR,
      LESSON.REMOVE_SUCCESS,
      LESSON.TOGGLE_BIRD_VIEW,
    ]): {
      const lessonId = action.payload.id || action.payload.lessonId;
      next(action);
      return saveLessonRequest(clonedLessonId || lessonId);
    }

    case matchBuilderActionType(action, 'page'): {
      const pageId = action.payload.id; // eslint-disable-line more/no-duplicated-chains
      const lessonId = getOr(
        helpers.page.getLessonId(pageId, allLessons),
        'lessonId',
        action.payload
      );
      next(action);
      return saveLessonRequest(lessonId);
    }

    case matchBuilderActionType(action, 'block'): {
      const blockId = {
        answer: action.payload.blockId,
        block: action.payload.id,
        quiz: action.payload.blockId,
        match: action.payload.blockId,
        exam: action.payload.blockId,
        images: action.payload.blockId,
        fillblank: action.payload.blockId,
        feedback: action.payload.blockId,
        document: action.payload.blockId,
        talk: action.payload.blockId,
      }[getBuilderActionBlockType(action)];
      const pageId = getOr(
        helpers.block.getPageId(blockId, allPages),
        'pageId',
        action.payload
      );
      const lessonId = helpers.page.getLessonId(pageId, allLessons);
      next(action);
      return saveLessonRequest(lessonId);
    }
    case UPLOAD_SUCCESS: {
      const { objectId: uploadObjectId, blockId } = action.payload;
      const pageId = helpers.block.getPageId(
        blockId || uploadObjectId,
        allPages
      );
      const lessonId =
        getOr(
          helpers.page.getLessonId(pageId, allLessons),
          'lessonId',
          action.payload
        ) || uploadObjectId;
      const { pathname } = store.router.location;
      if (pathname === `/builder/lesson/${lessonId}`) {
        next(action);
        return saveLessonRequest(lessonId);
      } else {
        return next(action);
      }
    }
    default:
      return next(action);
  }
};

/**
 * Проверка требуется ли новая публикация
 */
export const checkPublicationInvalidate = (state) => (next) => (action) => {
  const store = state.getState();

  switch (action.type) {
    case TALK.ADD_CARD:
    case TALK.REMOVE_CARD_ACCEPT:
    case TALK.CLEAR_CONTENT:
    case TALK.CLONE_CARD:
    case TALK.ADD_ANSWER:
    case TALK.REMOVE_ANSWER:
    case TALK.CHANGE_ANSWER_TEXT:
    case TALK.CHANGE_ANSWER_TARGET:
    case TALK.CHANGE_ANSWER_SCORE:
    case TALK.CHANGE_MINIMAL_SUCCESS_SCORE:
    case TALK.CHANGE_CARD_TYPE:
    case TALK.CHANGE_CARD_TARGET:
    case MATCH.ADD_BASKET:
    case MATCH.REMOVE_BASKET:
    case MATCH.CHANGE_ANSWER:
    case FILLBLANK.CHANGE_QUESTION:
    case EXAM.ADD_CORRECT_ANSWER:
    case EXAM.CHANGE_CORRECT_ANSWER:
    case EXAM.TOGGLE_CORRECT_ANSWER_TYPE:
    case EXAM.REMOVE_CORRECT_ANSWER: {
      const allPages = selectors.builder.getPages(store);
      const { blockId } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const pageId = helpers.block.getPageId(blockId, allPages);
      const id = helpers.page.getLessonId(pageId, allLessons);
      next(action);

      return state.dispatch(
        actions.builder.lesson.setHasUnpublishedChanges({ id })
      );
    }

    case QUIZ.ADD_ANSWER:
    case QUIZ.SET_ANSWER:
    case QUIZ.TOGGLE_TYPE:
    case QUIZ.CHANGE_ANSWER:
    case QUIZ.REMOVE_ANSWER: {
      const allPages = selectors.builder.getPages(store);
      const { blockId } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const pageId = helpers.block.getPageId(blockId, allPages);
      const id = helpers.page.getLessonId(pageId, allLessons);
      const blockType = get('type')(selectors.builder.getBlock(blockId, store));

      if (includes(blockType, taskTypes)) {
        state.dispatch(actions.builder.lesson.setHasUnpublishedChanges({ id }));
      }

      return next(action);
    }

    case BLOCK.CLEAN: {
      const { id: blockId } = action.payload;
      const allPages = selectors.builder.getPages(store);
      const allLessons = selectors.builder.getLessons(store);
      const pageId = helpers.block.getPageId(blockId, allPages);
      const lessonId = helpers.page.getLessonId(pageId, allLessons);
      const blockType = get('type')(selectors.builder.getBlock(blockId, store));

      if (includes(blockType, taskTypes)) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }

      return next(action);
    }

    case BLOCK.REMOVE: {
      const { id, pageId } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const lessonId = helpers.page.getLessonId(pageId, allLessons);
      const blockType = get('type')(selectors.builder.getBlock(id, store));

      if (includes(blockType, taskTypes)) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }

      return next(action);
    }

    case BLOCK.CLONE: {
      const { id, pageId } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const lessonId = helpers.page.getLessonId(pageId, allLessons);
      const blockType = get('type')(selectors.builder.getBlock(id, store));

      if (includes(blockType, taskTypes)) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }

      return next(action);
    }

    case BLOCK.ADD: {
      const { pageId, type } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const lessonId = helpers.page.getLessonId(pageId, allLessons);

      if (includes(type, taskTypes)) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }
      return next(action);
    }

    case PAGE.CLONE: {
      const { lessonId } = action.payload;
      const hasTestBlock = !compose(
        isEmpty,
        filter((block) => includes(block.type, taskTypes)),
        values,
        get(['meta', 'clonedBlocks'])
      )(action);

      if (hasTestBlock) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }

      return next(action);
    }

    case PAGE.REMOVE: {
      const { lessonId } = action.payload;
      const allBlocks = selectors.builder.getBlocks(store);
      const hasTestBlock = !compose(
        isEmpty,
        filter((blockId) =>
          includes(get([blockId, 'type'], allBlocks), taskTypes)
        ),
        values,
        get(['meta', 'removedBlocksIds'])
      )(action);

      if (hasTestBlock) {
        state.dispatch(
          actions.builder.lesson.setHasUnpublishedChanges({ id: lessonId })
        );
      }

      return next(action);
    }

    case PAGE.DISABLE_SHUFFLE:
    case PAGE.ENABLE_SHUFFLE: {
      const { id: pageId } = action.payload;
      const allLessons = selectors.builder.getLessons(store);
      const id = helpers.page.getLessonId(pageId, allLessons);
      state.dispatch(actions.builder.lesson.setHasUnpublishedChanges({ id }));

      return next(action);
    }

    default:
      return next(action);
  }
};
