import React from 'react';
import PropTypes from 'prop-types';

import formatDate from 'date-fns/format';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { combineStyles } from '../utils/styles';
import * as actions from '../actions';
import '../styles/ResultThumbnail.scss';
import Text from '~/components/Text';
import Hyperlink from '~/components/Hyperlink';
import { DraggedButton as Button } from '~/components/Button';

const ResultThumbnail = ({
  t,
  id,
  name,
  router,
  isMobile,
  passedAt,
  externalLink,
  messagesUnread,
  // reexecuteLesson,
  isLessonComplete,
  messagesQuantity,
}) => (
  <div
    className={combineStyles('ResultThumbnail', isMobile && 'mobile')}
    key={id}
  >
    {messagesQuantity ? (
      <div className={combineStyles('messages', messagesUnread && 'unread')}>
        {messagesQuantity}
      </div>
    ) : (
      undefined
    )}
    <div className="name">
      <Text value={name} variant="result-name" />
    </div>
    <div className="thumbnail">
      <div className="date">
        <Text
          value={t('resultthumbnail.resultDate')}
          variant="result-date-title"
        />
        <Text
          value={formatDate(passedAt * 1000, 'DD.MM.YYYY')}
          variant="result-date"
        />
      </div>
      <div className="result-button">
        {externalLink ? (
          isLessonComplete === true ? (
            <Hyperlink
              text={t('resultthumbnail.viewSummary')}
              variant="reexecute-result"
              href={`http://${externalLink}/player/resume/${id}`}
            />
          ) : (
            <Hyperlink
              text={t('resultthumbnail.continue')}
              variant="reexecute-result"
              href={`http://${externalLink}/player/resume/${id}`}
            />
          )
        ) : isLessonComplete === true ? (
          <>
            <Button
              text={t('resultthumbnail.viewSummary')}
              variant="reexecute-result"
              onClick={() => {
                router.push(`/player/resume/${id}`);
              }}
            />
            {/* <Button
              text={t('resultthumbnail.retry')}
              variant="reexecute-result"
              onClick={() => reexecuteLesson()}
            /> */}
          </>
        ) : (
          <Button
            text={t('resultthumbnail.continue')}
            variant="reexecute-result"
            onClick={() => router.push(`/player/resume/${id}`)}
          />
        )}
      </div>
    </div>
  </div>
);

const { string, number, bool, object, func } = PropTypes;

ResultThumbnail.propTypes = {
  t: func.isRequired, // Функция перевода
  id: string.isRequired, // ID Прохождения
  name: string, // Имя прохождения
  router: object, // Роутер
  passedAt: number.isRequired, // Дата прохождения
  isMobile: bool.isRequired, // Есть непрочитанные сообщения?
  externalLink: string, // Настройки урока
  messagesUnread: bool, // Есть непрочитанные сообщения?
  messagesQuantity: number, // Количество сообщений
  isLessonComplete: bool, // Урок пройден?
  // reexecuteLesson: func.isRequired, // Сбросить все старые результаты урока
};

ResultThumbnail.defaultProps = { messagesUnread: false };

const mapStateToProps = (state, ownProps) => {
  const messagesQuantity = ownProps.messages.length;
  const messagesUnread = true;
  return {
    messagesUnread,
    messagesQuantity,
  };
};

const mapDispatchToProps = {
  reexecuteLesson: actions.player.lesson.reexecute,
};

const enhance = withHandlers({
  reexecuteLesson: ({ lessonId, reexecuteLesson, publication }) => () => {
    reexecuteLesson({ lessonId, publication });
  },
});

export default compose(
  withTranslation('components'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(ResultThumbnail);
