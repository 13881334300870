import { get, set } from 'lodash/fp';

import {
  DOWNLOAD_LESSON_SUCCESS,
  SET_NEW_RESULT,
} from '~/actions/presentation';
import contentReducer from './player/contentReducer';

export const defaultState = {
  pages: {},
  blocks: {},
  lesson: undefined,
  result: undefined,
};

const presentationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_LESSON_SUCCESS: {
      const {
        payload: { lesson, pages, blocks },
      } = action;

      return { lesson, pages, blocks };
    }

    case SET_NEW_RESULT: {
      // eslint-disable-next-line more/no-duplicated-chains
      return set('result', action.payload.result, state);
    }

    // -------------- Внутренний редюсер --------------

    case String(action.type.match(/@player\/.*/)): {
      return get(['meta', 'context', 'inPresentation'], action)
        ? set('result', contentReducer(state.result, action), state)
        : state;
    }

    default:
      return state;
  }
};

export default presentationReducer;
