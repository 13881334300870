import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isNull, isUndefined } from 'lodash/fp';
import { downloadAnswerBlocks } from '~/actions/review';
import { selectAnswerBlocksTabData } from '~/selectors/review';

export const useGetAnswerBlocks = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState(null);
  const [isActiveFilter, toggleIsActiveFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    courseId: null,
    lessonId: null,
  });
  const answerBlocks = useSelector(selectAnswerBlocksTabData(sort));

  useEffect(() => {
    dispatch(
      downloadAnswerBlocks({
        courseId: undefined,
        lessonId: undefined,
      })
    );
  }, [dispatch]);

  const onFilterChange = useCallback(
    (filters) => {
      const lessonId = get(['lessonId'])(filters);
      const courseId = get(['courseId'])(filters);
      toggleIsActiveFilter(true);
      setFilterData(filters);

      dispatch(
        downloadAnswerBlocks({
          lessonId: isNull(lessonId) ? undefined : lessonId,
          courseId: isUndefined(courseId)
            ? null
            : isNull(courseId)
            ? undefined
            : courseId,
        })
      );
    },
    [dispatch, setFilterData]
  );

  const onFilterReset = useCallback(() => {
    toggleIsActiveFilter(false);
    setFilterData({
      courseId: null,
      lessonId: null,
    });

    dispatch(
      downloadAnswerBlocks({
        courseId: undefined,
        lessonId: undefined,
      })
    );
  }, [dispatch, setFilterData, toggleIsActiveFilter]);

  const onSortChange = useCallback((sortField) => setSort(sortField), []);

  return {
    filterData,
    answerBlocks,
    isActiveFilter,
    onSortChange,
    onFilterReset,
    onFilterChange,
  };
};
