import { all, put, takeLatest } from 'redux-saga/effects';
import { size } from 'lodash/fp';

import { REQUEST_NAME_SEND_INVITES } from '~/appConstants';
import * as COURSE from '~/constants/builder/course';
import * as actions from '~/actions';
import * as helpers from '~/helpers';
import { apiRequest } from '../../request';

/**
 * Отправка приглашений на курс пользователям
 */
export function* sendStudentsInvites() {
  yield takeLatest(COURSE.SEND_STUDENTS_INVITES, function*(action) {
    const {
      payload: { courseId, emails },
    } = yield action;

    const filteredEmails = helpers.course.filterEmails(emails);

    const response = yield apiRequest(
      'course/students/send_invites',
      { courseId, emails: filteredEmails },
      'post',
      { name: REQUEST_NAME_SEND_INVITES }
    );

    if (!response) {
      return;
    }

    const { error, payload } = response.data;
    if (error) {
      yield put(actions.builder.course.sendStudentsInvitesError());
      return;
    }

    const invalidQuantity =
      size(emails) -
      size(filteredEmails) +
      size(payload.filter((email) => !email.valid));

    yield put(
      actions.builder.course.sendStudentsInvitesSuccess(
        courseId,
        payload,
        invalidQuantity
      )
    );
  });
}
/**
 * Отправка приглашений на курс пользователям
 */
export function* sendTutorsInvites() {
  yield takeLatest(COURSE.SEND_TUTORS_INVITES, function*(action) {
    const {
      payload: { courseId, emails },
    } = yield action;

    const response = yield apiRequest(
      'course/tutors/send_invites',
      { courseId, emails },
      'post',
      { name: REQUEST_NAME_SEND_INVITES }
    );
    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      yield put(actions.builder.course.sendTutorsInvitesError());
      return;
    }

    yield put(
      actions.builder.course.sendTutorsInvitesSuccess(courseId, payload)
    );
  });
}
export default function*() {
  yield all([sendTutorsInvites(), sendStudentsInvites()]);
}
