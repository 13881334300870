import objectId from 'bson-objectid';
import { push } from 'connected-react-router';
import {
  all,
  call,
  getContext,
  put,
  select,
  takeEvery,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { get, trim } from 'lodash/fp';

import {
  AUTH_MODE_LOGIN,
  AUTH_MODE_REGISTER,
  AUTH_ROLE_TEACHER,
  LESSON_TYPE_OLYMPIAD,
  REQUEST_NAME_AUTH,
  REQUEST_NAME_AUTH_RECOVERY,
  REQUEST_NAME_AUTH_SAVE_PASSWORD,
  REQUEST_NAME_CHECK_VERIFICATION_CODE,
  REQUEST_NAME_SEND_VERIFICATION_EMAIL,
} from '~/appConstants';
import * as selectors from '../../selectors';
import * as AUTH from '../../constants/auth';
import * as PLAYER from '../../constants/player';
import * as auth from '~/actions/auth';
import * as player from '../../actions/player';
import * as builder from '../../actions/builder';
import * as notice from '../../actions/notice';
import * as metrika from '../../actions/metrika';
import * as BUILDER from '../../constants/builder';
import * as actions from '../../actions';
import { apiRequest, getErrorMessage } from '../request';

const api = {
  recovery: 'login/recovery',
  setRole: 'me/set_role',
};

function* sendRegister({ data }) {
  const response = yield apiRequest('register', data, 'post', {
    name: REQUEST_NAME_AUTH,
  });

  if (!response) {
    yield put(metrika.send('AUTH_REGISTER_ERROR', 'no_response'));
  }

  const { msg, error } = response.data;
  if (error) {
    yield put(metrika.send('AUTH_REGISTER_ERROR', msg));
  }

  return response;
}

function* sendLogin({ data }) {
  return yield apiRequest('login', data, 'post', {
    name: REQUEST_NAME_AUTH,
  });
}

function* sendRequest({ mode, form, resultFromUrl, goto }) {
  const i18n = yield getContext('i18n');

  const { email, password, firstName, lastName } = form;

  const payload =
    mode === AUTH_MODE_LOGIN
      ? {
          username: trim(email),
          password,
        }
      : mode === AUTH_MODE_REGISTER
      ? {
          mail: trim(email),
          password,
          givenName: `${firstName} ${lastName}`,
        }
      : null;

  const data = {
    ...payload,
    result: resultFromUrl,
  };

  const response =
    mode === AUTH_MODE_LOGIN
      ? yield sendLogin({ data })
      : yield sendRegister({ data });

  if (!response) {
    return response;
  }

  const { msg, error } = response.data;

  if (error) {
    yield put(auth.requestError(getErrorMessage({ i18n, msg })));
    return response;
  }

  yield put(metrika.send(`AUTH_${mode.toUpperCase()}`));
  const {
    payload: {
      id: userId,
      givenName: userName,
      admin: isAdmin,
      firstLogin,
      mail: userEmail,
      secret,
      key,
      role,
      subscription,
      verified,
    },
  } = response.data;

  // Отправка экшена на успешную авторизацию не в анонимном режиме
  yield put(
    auth.requestSuccess({
      userId,
      userName,
      userEmail,
      isAdmin,
      secret,
      key,
      role,
      subscription,
    })
  );

  yield put(
    auth.signIn({
      userId,
      userName,
      userEmail,
      isAdmin,
      secret,
      role,
      subscription,
      verified,
    })
  );

  if (firstLogin) {
    yield put(builder.showHints());
  }

  if (!role && goto === '/') {
    yield put(push('/select-role'));
  } else if (goto === '/') {
    yield put(push(role === AUTH_ROLE_TEACHER ? '/teach' : '/study'));
  } else if (goto) {
    yield put(push(goto));
  }

  return response;
}

export function* watchPlayerSendRequest() {
  yield takeEvery(PLAYER.SEND_REQUEST, function*(action) {
    const {
      payload: { mode, form, result: resultFromUrl }, // TODO выяснить, что это за result
    } = yield action;

    const response = yield sendRequest({ mode, form, resultFromUrl });

    if (!response || response.data.error) {
      // TODO: handle error
      return;
    }

    const {
      payload: { id: userId, givenName: userName },
    } = response.data;

    const state = yield select();

    const isOlympiadMode =
      selectors.player.getLessonType(state) === LESSON_TYPE_OLYMPIAD;
    yield put(player.login({ isOlympiadMode, mode, userName, userId }));
  });
}

export function* watchSendRequest() {
  yield takeEvery(AUTH.SEND_REQUEST, function*(action) {
    const {
      payload: { mode, form, goto },
    } = yield action;

    yield sendRequest({ mode, form, goto });
  });
}

export function* watchChangeRole() {
  yield takeLatest(AUTH.SET_ROLE, function*(action) {
    const {
      payload: { role },
    } = action;
    const response = yield call(apiRequest, api.setRole, { role });

    if (response) {
      const { error } = response.data;
      if (!error) {
        yield put(actions.auth.setRoleSuccess(role));
        yield put(push(role === 'teacher' ? '/teach' : '/study'));
      } else {
        yield put(actions.auth.setRoleError(error));
      }
    } else {
      yield put(actions.auth.setRoleError());
    }
  });
}

export function* watchLogout() {
  yield takeLatest(AUTH.LOGOUT, function*() {
    yield apiRequest('logout', null, 'get');
  });
}

/**
 * Отправка секрета
 */
export function* sendSecret() {
  yield takeLatest(BUILDER.SEND_SECRET, function*(action) {
    const { userId } = action.payload;
    const generatedSecret = objectId.generate();
    const data = { userId };
    const response = yield apiRequest(`login/secret/${generatedSecret}`, data);
    if (response) {
      const { error } = response.data;
      if (!error) {
        yield put(actions.builder.sendSecretSuccess(generatedSecret));
      } else {
        yield put(actions.builder.sendSecretError(error));
      }
    } else {
      yield put(actions.builder.sendSecretError());
    }
  });
}

export function* sendRecoveryRequest() {
  const i18n = yield getContext('i18n');
  yield takeLatest(AUTH.RECOVERY_REQUEST, function*(action) {
    const {
      payload: { email },
    } = action;

    const response = yield apiRequest(api.recovery, { email }, 'post', {
      name: REQUEST_NAME_AUTH_RECOVERY,
    });

    if (!response) {
      return;
    }

    const {
      data: { error, msg },
    } = response;

    if (error) {
      yield put(auth.requestError(getErrorMessage({ i18n, msg })));
      return;
    }

    yield put(push('/recovery-sent'));
  });
}

export function* sendNewPassword() {
  const i18n = yield getContext('i18n');

  yield takeLatest(AUTH.SEND_NEW_PASSWORD, function*(action) {
    const {
      payload: { password, changePasswordId },
    } = action;

    const response = yield apiRequest(
      `login/sendNewPassword/${changePasswordId}`,
      { password },
      'post',
      {
        name: REQUEST_NAME_AUTH_SAVE_PASSWORD,
      }
    );

    if (!response) {
      return;
    }

    const {
      data: { error, msg },
    } = response;

    if (error) {
      yield put(auth.requestError(getErrorMessage({ i18n, msg })));
      return;
    }

    yield put(notice.show('notice.passwordChangeSuccess', 'success'));
    yield put(push('/auth'));
  });
}

function* watchChangeName() {
  yield takeLatest(AUTH.CHANGE_NAME, function*(action) {
    const {
      payload: { name },
    } = action;

    const response = yield apiRequest('user/change-name', { name }, 'post', {
      name: REQUEST_NAME_AUTH,
    });

    if (!response) {
      return;
    }

    const {
      data: { error },
    } = response;

    if (error) {
      return;
    }

    yield put(auth.changeNameSuccess({ name }));
    yield put(notice.show('Имя изменено', 'success'));
  });
}

function* watchSendVerificationEmail() {
  const name = REQUEST_NAME_SEND_VERIFICATION_EMAIL;
  yield takeLatest(AUTH.RESEND_VERIFICATION_EMAIL, function*() {
    yield apiRequest('user/resend-verification', {}, 'get', { name });
    yield put(auth.reSendVerificationEmailSuccess(new Date()));
  });
}

function* verifyEmailByCode() {
  const {
    payload: { code },
  } = yield take(AUTH.VERIFY_EMAIL);

  const response = yield apiRequest(`user/verify-email/${code}`, {}, 'get', {
    name: REQUEST_NAME_CHECK_VERIFICATION_CODE,
  });

  if (!response || get(['data', 'error'], response)) {
    yield put(actions.ui.showNotFoundPage());
    return;
  }

  yield put(notice.show('notice.emailVerificationSuccess', 'success'));
  yield put(actions.ui.closeVerificationAlert());
  yield put(push('/'));
}

export default function*() {
  yield all([
    watchPlayerSendRequest(),
    watchSendRequest(),
    watchChangeRole(),
    sendSecret(),
    sendRecoveryRequest(),
    sendNewPassword(),
    watchLogout(),
    watchChangeName(),
    watchSendVerificationEmail(),
    verifyEmailByCode(),
  ]);
}
