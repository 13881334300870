import { get, compose, includes, curry, constant } from 'lodash/fp';

export const isRequestActive = curry((name, state) =>
  compose(
    includes(name),
    get(['ui', 'activeRequests'])
  )(state)
);

export const getBuilder = (state) => get(['ui', 'builder'], state);

// TODO: Временно закомменчено, пока не будет реализован функционал изменения почты
// export const isVerificationAlertVisible = get([
//   'ui',
//   'isVerificationAlertVisible',
// ]);

export const isVerificationAlertVisible = constant(false);
