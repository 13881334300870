import { css } from 'styled-components';
import noAvatar from '../../../../../static/img/no-avatar.png';

import {
  STATUS_APPROVED,
  STATUS_NOT_CHECKED,
  STATUS_REJECTED,
} from '~/appConstants';

export const container = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  ${(props) =>
    props.theme.isMobile
      ? css`
          min-height: 0;
        `
      : css`
          min-height: calc(100vh - 60px);
        `}
`;

export const content = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  padding-bottom: 40px;

  ${(props) =>
    props.theme.isMobile
      ? css`
          height: auto;
          width: 100%;
          min-width: 0;
          margin-left: 0;
          padding: 45px 0;
        `
      : css`
          width: 100%;
          height: calc(100vh - 60px);
          padding-top: 45px;
        `}
`;

export const noTasks = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 81px;
  margin-bottom: 40px;
`;

export const noTasksEmoji = css`
  font-size: 90px;
  height: 118px;
`;

export const noTasksText = css`
  font-family: Gilroy;
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  color: #b2b6b8;
`;

export const line = css`
  background: ${(props) => (props.selected ? '#fdf9e8' : '#fff')};
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease;

  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 11px 24px;
          margin-bottom: 5px;
        `
      : css`
          width: 100%;
          height: 56px;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 11px 64px 11px 24px;
          margin-bottom: 1px;
        `}

  &:hover {
    background: ${(props) =>
      props.selected ? '#fdf9e8' : 'rgba(255, 255, 255, 0.5)'};
  }
`;

export const user = css`
  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
          margin-bottom: 20px;
        `
      : css`
          width: 180px;
          flex: 0 0 180px;

          @media (max-width: 768px) {
            width: 100px;
            flex: 0 0 100px;
          }
        `}
`;

export const userName = css`
  font-family: CirceRounded;
  font-size: 15px;
  line-height: 1.27;
  color: #45494e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const userEmail = css`
  font-family: CirceRounded;
  font-size: 12px;
  color: #969ca6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const center = css`
  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100% - 212px);

          @media (max-width: 768px) {
            width: calc(100% - 120px);
          }
        `}
`;

export const question = css`
  font-family: CirceRounded;
  font-size: 15px;
  line-height: 1.27;
  color: #45494e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const breadcrumbs = css`
  font-family: CirceRounded;
  font-size: 12px;
  color: #969ca6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
  }
`;

export const date = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #aeb3bd;
  width: 70px;
  flex: 0 0 70px;
`;

export const taskContainer = css`
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const taskHeader = css`
  width: 100%;
  padding: 24px 90px 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media (max-width: 1279px) {
    padding-top: 8px;
    padding-right: 24px;
  }
`;

export const close = css`
  width: 42px;
  height: 42px;
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const closeBack = css`
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 3px 15px;

  @media (min-width: 1280px) {
    display: none;
  }
`;

export const taskHeaderUser = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const taskHeaderAvatar = css`
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
  margin-right: 13px;
`;

export const taskHeaderName = css`
  width: 100%;
`;

export const taskInfo = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 9px 24px 22px;
  border-bottom: 1px solid #e5e9ec;
`;

export const taskInfoPart = css`
  width: 295px;
  padding-right: 20px;
`;

export const taskInfoTitle = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #afafaf;
  text-transform: uppercase;
`;

export const taskInfoName = css`
  display: flex;
  color: #45494e;

  &:hover {
    color: #767b84 !important;
    .Icon {
      background: #e5e9ec;
    }
  }
`;

export const scroll = css`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: flex-start;
`;

export const footer = css`
  width: 100%;
  box-sizing: border-box;
  align-self: flex-end;
`;

export const taskContent = css`
  width: 100%;
  padding: 24px;
`;

const taskStatusBgColorMap = {
  [STATUS_APPROVED]: '#8acf00',
  [STATUS_REJECTED]: '#f25200',
  [STATUS_NOT_CHECKED]: '#45494e',
};

export const taskStatus = css`
  padding: 0 12px;
  height: 18px;
  line-height: 18px;
  opacity: 0.9;
  border-radius: 2px;
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff;
  width: auto;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  background-color: ${({ status }) => taskStatusBgColorMap[status]};
`;

export const taskTitle = css`
  width: 100%;
  margin-top: 19px;
  font-family: Gilroy;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #45494e;

  &:hover {
    color: #45494e !important;
    .Icon {
      background: #e5e9ec;
    }
  }
`;

export const taskTitleInternal = css`
  flex-grow: 2;
`;

export const taskDescription = css`
  width: 100%;
  margin-top: 8px;
  font-family: CirceRounded;
  font-size: 16px;
  line-height: 1.25;
  color: #767b84;
`;

export const taskAnswer = css`
  width: 100%;
  padding: 0 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  opacity: ${(props) => (props.isAnswerBlocked ? 'unset' : 0.4)};
`;

export const taskAnswerContainer = css`
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.status === 'approved'
      ? '#8acf00'
      : props.status === 'reject'
      ? '#f25200'
      : '#d0d3d7'};
  background-color: #fff;
  padding: 8px 16px;
  box-sizing: border-box;
  order: 2;
`;

export const taskAnswerHeader = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const taskAnswerUser = css`
  font-family: CirceRounded;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.33;
  color: #45494e;
`;

export const taskAnswerDate = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #aeb3bd;
`;

export const taskAnswerText = css`
  padding-top: 4px;
`;

export const taskAnswerImages = css`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const taskAnswerImage = css`
  display: block;
  width: 80px;
  height: 80px;
`;

export const taskAnswerAvatar = css`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});

  ${(props) =>
    props.position === 'left'
      ? css`
          order: 1;
          margin-right: 8px;
        `
      : css`
          order: 3;
          margin-left: 8px;
        `}
`;

export const teacherButtons = css`
  height: 56px;
  width: 100%;
  display: flex;

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    cursor: pointer;
    font-family: CirceRounded;
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
    color: #fff;
    font-weight: normal;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 0 17px;
      line-height: 1.4;
    }

    > .icon {
      font-size: 20px;
      margin-right: 32px;

      @media (max-width: 768px) {
        margin-right: 15px;
      }
    }
  }
`;

export const teacherChangeBlocked = css`
  font-family: CirceRounded;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  opacity: 0.9;
  height: 57px;
  color: #e6a42f;
  border-top: 1px solid rgba(242, 200, 1, 0.32);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba(242, 200, 1, 0.1);
  }
`;

export const approveButton = css`
  background: #8acf00;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default !important;
    `}

  &:hover {
    background: #82c102;
  }
`;

export const disapproveButton = css`
  background: #f25200;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default !important;
    `}

  &:hover {
    background: #e24e02;
  }
`;

export const commentField = css`
  padding: 21px 24px 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #e5e9ec;
  font-family: CirceRounded;
  font-size: 15px;
  line-height: 1.2;
  color: #45494e;
`;

export const commentFiles = css`
  padding: 16px 24px 24px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const editor = css`
  width: 100%;
`;

export const uploader = css`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 24px;
  margin-top: -7px;
  cursor: pointer;
`;

export const loader = css`
  width: 100%;
`;

export const loaderContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const rejection = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const rejectionContent = css`
  text-align: center;
  color: #767b84;
`;

export const rejectionIcon = css`
  color: #e5e9ec;
`;

export const linkIcon = css`
  color: #f2c801;
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-top: -20px;
  font-size: 14px;
  flex-shrink: 0;

  .bp3-icon {
    display: block;
  }
`;

export const iconTitle = css`
  flex-grow: 2;
  font-family: CirceRounded;
  font-size: 14px;
  width: 28px;
  height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
