import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { map } from 'lodash/fp';
import { useTranslation, Trans } from 'react-i18next';
import Dialog from '~/components/Dialog';
import Controls from '~/components/Controls';
import Link from '~/components/Link';
import Text from '~/components/Text';
import Icon from '~/components/Icon';
import Froala from '~/components/Froala';
import Button from '~/components/Button';
import BuilderCard from './components/BuilderCard';
import * as selectors from '~/selectors';
import * as talkActions from '~/actions/builder/block/talk';
import * as actions from '~/actions';
import * as s from './styles/TalkBuilder.styles';

const TalkBuilder = ({ blockId, lessonId }) => {
  const { t } = useTranslation('containersBlock');
  const [isOpen, setOpen] = useState(false);
  const { content } = useSelector(selectors.builder.getBlock(blockId));

  const isPersisting = useSelector(
    selectors.builder.lesson.isPersisting(lessonId)
  );
  const isUnsaved = useSelector(selectors.builder.lesson.isUnsaved(lessonId));
  const cards = map((cardId) => content.cards[cardId], content.cardsIds);

  const dispatch = useDispatch();
  const saveLesson = () => dispatch(actions.builder.lesson.save(lessonId));
  const addCard = () => dispatch(talkActions.addCard({ blockId }));
  const changeName = (name) =>
    dispatch(talkActions.changeName({ blockId, name }));
  const changeDescription = (description) =>
    dispatch(talkActions.changeDescription({ blockId, description }));
  const changeMinimalSuccessScore = (score) =>
    dispatch(talkActions.changeMinimalSuccessScore({ blockId, score }));

  const indexToChar = (index) => String.fromCharCode(65 + index);

  const linksForCards = {};
  cards.forEach((card, cardI) => {
    card.answersIds.forEach((answerId, answerI) => {
      const targetId = content.answers[answerId].target?.cardId;
      const link = `${cardI + 1}${indexToChar(answerI)}`;
      // eslint-disable-next-line fp/no-mutation
      linksForCards[targetId] = [...(linksForCards[targetId] || []), link];
    });
  });

  return (
    <div css={s.container}>
      <Dialog usePortal variant="results" isOpen={isOpen}>
        <div css={s.dialog}>
          <div css={s.header}>
            <Link
              onClick={() => setOpen(false)}
              withIcon="arrow-left"
              variant="header-backlink"
              css={s.headerBackLink}
            />
            <input
              type="number"
              css={s.headerCounter}
              onClick={() => {
                if (content.minimalSuccessScore === 0) {
                  changeMinimalSuccessScore('');
                }
              }}
              onChange={(e) => changeMinimalSuccessScore(e.target.value)}
              onBlur={(e) => changeMinimalSuccessScore(+e.target.value)}
              value={content.minimalSuccessScore}
            />
            <div css={s.headerText}>
              <Trans t={t} i18nKey="talk.minimalScoresTitle" />
            </div>
            <Icon
              css={s.helpIcon}
              name="help"
              position="bottom"
              variant="gray-help"
              tooltip={t('talk.minScoreTooltip')}
            />
            <Controls
              type="panel"
              items={[
                isPersisting
                  ? {
                      icon: 'osh-savewarning-base',
                      variant: 'gray',
                      text: t('talk.saveProgress'),
                      spinner: isPersisting,
                    }
                  : isUnsaved
                  ? {
                      icon: 'osh-savewarning-bad',
                      action: saveLesson,
                      variant: 'red',
                      text: t('talk.connectionError'),
                    }
                  : {
                      icon: 'osh-savewarning-good',
                      variant: 'green',
                      text: t('talk.changesSaved'),
                    },
              ]}
              variant="talk-block-header"
            />
          </div>
          <div css={s.body}>
            {cards.map(({ id }, i) => (
              <BuilderCard
                links={linksForCards[id]}
                blockId={blockId}
                key={id}
                i={i}
                cardId={id}
              />
            ))}
            <Button
              icon="osh-addnew"
              variant="add-page-new"
              css={s.addCard}
              onClick={addCard}
              text={t('talk.addScreen')}
            />
          </div>
        </div>
      </Dialog>
      <Text
        css={s.name}
        value={content.name}
        variant="answer-question"
        editable
        multiline
        onChange={changeName}
        placeholder={t('talk.inputTaskName')}
      />
      <div css={s.description}>
        <Froala
          mode="editor"
          placeholder={t('talk.inputTaskDescription')}
          toolbarButtons={[
            'bold',
            'align',
            'quote',
            'italic',
            'indent',
            'outdent',
            'formatUL',
            'formatOL',
            'underline',
            'insertLink',
            'insertTable',
            'insertImage',
            'strikeThrough',
            'paragraphFormat',
          ]}
          content={content.description}
          onChange={changeDescription}
        />
      </div>
      <div css={s.content} onClick={() => setOpen(true)}>
        <Icon css={s.icon} name="osh-pt-icon-osh-dialog" />
        <div css={s.actionText}>{t('talk.actionText')}</div>
        <Button
          variant="block-talk"
          onClick={() => setOpen(true)}
          text={t('talk.edit')}
        />
      </div>
    </div>
  );
};

const { string } = PropTypes;

TalkBuilder.propTypes = {
  blockId: string.isRequired,
  lessonId: string.isRequired,
};

export default TalkBuilder;
