export const OPEN = '@results/Открытие окна с результатами урока';
export const CLOSE = '@results/Закрытие окна с результатами урока';
export const DOWNLOAD = '@results/Загрузка результатов выполнения урока';
export const SELECT_RESULT =
  '@results/Показать результаты урока для выбранного ученика';
export const SELECT_PAGE =
  '@results/Показать результаты для выбранной страницы';
export const DOWNLOAD_ERROR =
  '@results/Ошибка при загрузке результатов выполнения урока';
export const DOWNLOAD_SUCCESS =
  '@results/Результаты выполнения урока успешно загружены';
export const RESET_CONFIRM = '@builder/lesson/Запрос на удаление результатов';
export const RESET_CANCEL = '@builder/lesson/Отмена сброса результатов';
export const RESET_ACCEPT =
  '@builder/lesson/Подтверждение удаления результатов';
export const RESET_ERROR = '@results/Ошибка сброса результатов';
export const RESET_SUCCESS = '@results/Результаты урока сброшены';
export const SEND_MESSAGE = '@results/Отправка сообщения';
export const SEND_MESSAGE_ERROR = '@results/Ошибка при отправке сообщения';
export const SEND_MESSAGE_SUCCESS = '@results/Сообщение отправлено';
export const DOWNLOAD_PUBLIC_OLYMPIAD_RESULT =
  '@results/Загрузка публичного результата урока';
export const DOWNLOAD_PUBLIC_OLYMPIAD_RESULT_ERROR =
  '@results/Ошибка загрузки публичного результата урока';
export const DOWNLOAD_PUBLIC_OLYMPIAD_RESULT_SUCCESS =
  '@results/Загрузка публичного результата урока прошла успешно';
