/* eslint-disable fp/no-mutation */
import { createAction, createErrorAction } from '../../utils/actions';

const DOWNLOAD_COURSES = '@study/Загрузить все курсы созданные пользователем';
const DOWNLOAD_COURSES_ERROR = '@study/Ошибка загрузки курсов пользователя';
const DOWNLOAD_COURSES_SUCCESS = '@study/Курсы пользователя успешно загружены';

export const download = () => createAction(DOWNLOAD_COURSES);
download.toString = () => DOWNLOAD_COURSES;

export const downloadError = createErrorAction(DOWNLOAD_COURSES_ERROR);
downloadError.toString = () => DOWNLOAD_COURSES_ERROR;

export const downloadSuccess = (downloadedCourses) =>
  createAction(DOWNLOAD_COURSES_SUCCESS, { downloadedCourses });
downloadSuccess.toString = () => DOWNLOAD_COURSES_SUCCESS;
