import { put, takeEvery, take, race, all } from 'redux-saga/effects';

import * as COURSE from '~/constants/builder/course';
import * as NOTICE from '~/constants/notice';
import * as actions from '~/actions';
import { apiRequest } from '~/sagas/request';

function* watchRemoveStudent() {
  yield takeEvery(COURSE.REMOVE_STUDENT, function*(action) {
    const { id, email } = action.payload;
    const noticeId = Date.now();
    yield put(
      actions.notice.showSuccess({
        message: 'notice.removeStudentFromCourse',
        actionText: 'notice.cancel',
        id: noticeId,
      })
    );

    const expectedAction = (expectedType) => ({ type, payload }) =>
      type === expectedType && payload.id === noticeId;

    // TODO: тут реализована отмена удаления ученика из курса.
    //  Но фактически удаления не происходит, хотя сообщение говорит о том, что пользователь удалён
    //  Запрос на удаление происходит только после закрытия notice
    //  Если пользователь закроет страницу до того, как исчезнет notice, удаления не произойдет
    //  Не ясно, правильно ли это с точки зрения UX
    const [isActionPressed] = yield race([
      take(expectedAction(NOTICE.ON_ACTION)),
      take(expectedAction(NOTICE.ON_DISMISS)),
    ]);

    if (isActionPressed) {
      yield put(actions.builder.course.removeStudentCancel({ id, email }));
      yield put(
        actions.notice.showSuccess({ message: 'notice.actionCanceled' })
      );
    } else {
      yield put(actions.builder.course.removeStudentSuccess({ id, email }));
      yield put(actions.builder.course.save(id));
    }
  });
}

function* watchRemoveTutor() {
  yield takeEvery(COURSE.REMOVE_TUTOR, function*(action) {
    const { id, email } = action.payload;
    const noticeId = Date.now();

    const response = yield apiRequest('course/tutors/remove', {
      courseId: id,
      email,
    });

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      return;
    }

    yield put(actions.builder.course.removeTutorSuccess({ id, email }));

    yield put(
      actions.notice.showSuccess({
        // TODO: сделать сообщение удаления для туторов (сейчас для учеников)
        message: 'notice.removeStudentFromCourse',
        id: noticeId,
      })
    );
  });
}

export default function*() {
  yield all([watchRemoveStudent(), watchRemoveTutor()]);
}
