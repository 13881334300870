import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, branch, renderComponent, withProps, pure } from 'recompose';
import { connect, useDispatch } from 'react-redux';

import {
  LESSON_DOWNLOAD_STATUS_CLOSED,
  LESSON_DOWNLOAD_STATUS_DOWNLOADED,
  LESSON_DOWNLOAD_STATUS_DOWNLOADING,
  LESSON_DOWNLOAD_STATUS_ERROR,
  LESSON_DOWNLOAD_STATUS_LTI_CLOSED,
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
} from '../../appConstants';
import { downloadLesson } from '../../actions/player';

import Error from './downloadErrors/Error';
import Closed from './downloadErrors/Closed';
import LtiClosed from './downloadErrors/LtiClosed';
import CourseError from './downloadErrors/CourseError';
import withSSRHandler from '../../hocs/withSSRHandler';

const PlayerLoader = ({
  lesson,
  lessonDownloadStatus,
  lessonId,
  publication,
}) => {
  /**
   * useEffect здесь как временное решение, потому что урок по SRR не может скачать
   * уже пройденный lti урок. У SSR для этого нет result из localStorage браузера.
   * Нужно что-то с этим делать во время следующей переработки плеера
   * */
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadLesson({ lessonId, publication }));
  }, [dispatch, lessonId, publication]);

  switch (lessonDownloadStatus) {
    case LESSON_DOWNLOAD_STATUS_ERROR:
      return <Error lesson={lesson} />;
    case LESSON_DOWNLOAD_STATUS_LTI_CLOSED:
      return <LtiClosed lessonId={lessonId} publication={publication} />;
    case LESSON_DOWNLOAD_STATUS_CLOSED:
      return <Closed lesson={lesson} />;
    case LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY:
      return (
        <CourseError error={LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY} />
      );
    case LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON:
      return (
        <CourseError
          error={LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON}
        />
      );
    case LESSON_DOWNLOAD_STATUS_DOWNLOADING:
      return null; // ToDo добавить лоадер
    default:
      return null;
  }
};

const { object, oneOf, string } = PropTypes;

PlayerLoader.propTypes = {
  lesson: object,
  lessonDownloadStatus: oneOf([
    LESSON_DOWNLOAD_STATUS_ERROR,
    LESSON_DOWNLOAD_STATUS_CLOSED,
    LESSON_DOWNLOAD_STATUS_LTI_CLOSED,
    LESSON_DOWNLOAD_STATUS_DOWNLOADING,
    LESSON_DOWNLOAD_STATUS_DOWNLOADED,
    LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
  ]),
  lessonId: string.isRequired,
  publication: string,
};

const mapStateToProps = (state) => ({
  lessonDownloadStatus: state.player.lessonDownloadStatus,
  lesson: state.player.lesson,
});

const mapDispatchToProps = { downloadLesson };

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(({ match: { params: { lessonId, publication } } }) => ({
    lessonId,
    publication,
  })),
  // eslint-disable-next-line no-shadow
  withSSRHandler(({ downloadLesson, lessonId, publication }) => {
    downloadLesson({ lessonId, publication });
  }),
  // lifecycle({
  //   componentDidUpdate(prevProps) {
  //     /* eslint-disable fp/no-this */
  //     const {
  //       downloadLesson: downloadLessonFunc,
  //       lessonId,
  //       publication,
  //     } = this.props;
  //
  //     if (prevProps.match.params.lessonId !== lessonId) {
  //       downloadLessonFunc({ lessonId, publication });
  //     }
  //   },
  // }),
  branch(
    (props) =>
      props.lessonDownloadStatus !== LESSON_DOWNLOAD_STATUS_DOWNLOADED ||
      props.lessonId !== props.lesson.id,
    renderComponent(PlayerLoader)
  ),
  pure
);
