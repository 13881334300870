import { compose, set } from 'lodash/fp';

import * as PLAYER from '../../constants/player';
import {
  LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES,
  LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY,
  LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON,
} from '../../appConstants';

export default (state = null, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_LESSON_SUCCESS: {
      return action.payload.lesson;
    }

    // TODO: Это тут зачем?
    case PLAYER.DOWNLOAD_LESSON_ERROR: {
      const { errorType, errorData } = action.payload;

      if (errorType === LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY) {
        return set(['meta', 'courseId'], errorData.courseId, state);
      }

      if (errorType === LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON) {
        return compose(
          set(['meta', 'courseStatus'], errorData.courseStatus),
          set(['meta', 'courseId'], errorData.courseId)
        )(state);
      }

      if (errorType !== LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES) {
        return state;
      }

      return compose(
        set(['meta', 'startDate'], errorData.startDate),
        set(['meta', 'endDate'], errorData.endDate)
      )(state);
    }

    default:
      return state;
  }
};
