import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import {
  compose,
  eq,
  find,
  get,
  isMatch,
  mapValues,
  pick,
  shuffle,
  take as lodashTake,
  getOr,
  omit,
  set,
  reduce,
} from 'lodash/fp';
import objectId from 'bson-objectid';
import { REHYDRATE } from 'redux-persist';

import * as PLAYER from '~/constants/player';
import * as actions from '~/actions';
import request, { apiRequest } from '~/sagas/request';
import {
  RESULT_STATUS_ACTIVE,
  RESULT_STATUS_COMPLETE,
  RESULT_STATUS_CREATED_WITH_LTI,
} from '~/appConstants';
import * as selectors from '~/selectors';
import {
  clearResult,
  clearUserExecution,
  downloadLesson,
  hideOfferNewVersion,
  showOfferNewVersion,
  showUserWelcome,
} from '~/actions/player';
import { complete } from '~/actions/player/lesson';

const api = (resultId) => `${process.env.API_URL}/result/${resultId}`;

function* updateResultPages({ result }) {
  const lessonPages = yield select(get(['player', 'pages']));

  return reduce((resultPages, { isShuffleBlocks, blocksIds, id }) => {
    const resultPage = getOr({}, ['pages', id], result);

    return set(
      id,
      {
        ...resultPage,
        displayedBlocksIds: isShuffleBlocks
          ? get(['displayedBlocksIds'], resultPage)
          : blocksIds,
      },
      resultPages
    );
  }, {})(lessonPages);
}

function* downloadExecution({ resultId }) {
  const response = yield call(request, api(resultId), null, 'get');

  if (!response) {
    return;
  }

  const {
    data: {
      error,
      payload: { result },
    },
  } = response;

  if (error) {
    yield put(actions.player.downloadExecutionError(error));
    return;
  }
  yield put(actions.player.downloadExecutionSuccess({ result }));
}

function* downloadUserExecution() {
  yield takeEvery(PLAYER.DOWNLOAD_PREVIOUS_EXECUTION, function*() {
    const lessonId = yield select(get(['player', 'lesson', 'id']));
    const publication = yield select(
      get(['player', 'lesson', 'meta', 'publication'])
    );
    const publicationPath = publication ? `/${publication}` : '';
    const apiUrl = `previousResult/${lessonId}${publicationPath}`;
    const response = yield apiRequest(apiUrl, null, 'get');
    if (!response) {
      return;
    }

    const {
      data: {
        error,
        payload: { result },
      },
    } = response;

    if (error) {
      yield put(actions.player.downloadExecutionError(error));
      return;
    }

    const pages = yield updateResultPages({ result });
    yield put(
      actions.player.downloadUserExecutionSuccess({
        result: result && { ...result, pages },
      })
    );
    yield put(actions.player.checkPreviousExecution());
  });
}

function* loadPreviousExecution() {
  const result = yield select(
    get(['player', 'previouslyExecutedResult', 'result'])
  );
  if (!result) {
    return;
  }

  yield put(actions.player.downloadExecutionSuccess({ result }));
}

export function* createEmptyResultData() {
  const state = yield select();

  const pagesIds = yield select(
    get(['player', 'lesson', 'content', 'pagesIds'])
  );

  const pages = yield select(
    compose(
      pick(pagesIds),
      get(['player', 'pages'])
    )
  );

  const resultPages = mapValues(
    ({ blocksIds, isShuffleBlocks, displayedBlocks }) => {
      const displayedBlocksIds = isShuffleBlocks
        ? compose(
            lodashTake(displayedBlocks),
            shuffle
          )(blocksIds)
        : blocksIds;

      return {
        displayedBlocksIds,
      };
    }
  )(pages);

  const userName = selectors.auth.getUserName(state);
  const mail = selectors.auth.getUserEmail(state);
  const userId = selectors.auth.getUserId(state);

  return {
    pages: resultPages,
    status: RESULT_STATUS_ACTIVE,
    viewedPagesIds: [],
    userName,
    mail,
    userId,
    executionTime: 0,
  };
}

function* createEmptyResult({ lessonId, publication }) {
  const id = yield call(objectId.generate);
  const newResultData = yield createEmptyResultData();
  yield put(
    actions.player.setNewResult({
      result: {
        id,
        lessonId,
        publication,
        ...newResultData,
      },
    })
  );
}

function* watchResumeExecution() {
  yield takeEvery(PLAYER.RESUME_EXECUTION, function*(action) {
    const {
      payload: { resultId },
    } = action;

    yield downloadExecution({ resultId });

    const result = yield select(get(['player', 'result']));
    const { lessonId, publication } = result;

    const locationState = yield select(get(['router', 'location', 'state']));

    const { pageId, blockId } = locationState || {};
    const basePath = `/player/lesson/${lessonId}/${publication}`;

    const pathWithPage = pageId ? `${basePath}/${pageId}` : basePath;
    const pathWithBlock = pageId ? `${pathWithPage}#${blockId}` : pathWithPage;

    yield put(replace(pathWithBlock));
  });
}

function* watchReexecute() {
  yield takeEvery(PLAYER.LESSON.REEXECUTE, function*(action) {
    const { lessonId, publication } = action.payload;
    yield put(clearResult({ lessonId, publication }));
    yield createEmptyResult({ lessonId, publication });
    yield put(push(`/player/lesson/${lessonId}/${publication}`));
  });
}

function* takeRehydratedResults() {
  while (true) {
    const state = yield select();
    const { previouslyExecutedResults } = state.player;
    // eslint-disable-next-line no-underscore-dangle
    if (previouslyExecutedResults._persist.rehydrated) {
      return previouslyExecutedResults;
    }

    yield take(REHYDRATE);
  }
}

function* watchStartExecution() {
  yield takeLatest(PLAYER.LESSON.START_EXECUTION, function*(action) {
    const {
      payload: { lessonId, publication },
    } = action;
    const state = yield select();
    const { result } = state.player;
    if (isMatch({ status: RESULT_STATUS_CREATED_WITH_LTI }, result)) {
      const newResultData = yield createEmptyResultData();
      yield put(
        actions.player.setNewResult({
          result: {
            ...omit('mail', newResultData),
            ...omit('pages', result),
            status: RESULT_STATUS_ACTIVE,
          },
        })
      );
      return;
    }

    const previouslyExecutedResults = yield takeRehydratedResults();
    const isLoggedIn = selectors.auth.isLoggedIn(state);

    const existingResult = find(
      { lessonId, publication },
      previouslyExecutedResults
    );

    if (existingResult && !isLoggedIn) {
      yield downloadExecution({ resultId: existingResult.id });
    } else if (isLoggedIn) {
      yield put(actions.player.downloadUserExecution());
      yield createEmptyResult({ lessonId, publication });
    } else {
      yield createEmptyResult({ lessonId, publication });
    }
    const isLtiLesson = yield select(get(['player', 'result', 'isLtiLesson']));
    const status = yield select(get(['player', 'result', 'status']));

    const isLessonComplete = status === RESULT_STATUS_COMPLETE;
    const courseId = get(['player', 'lesson', 'meta', 'courseId'], state);

    if (isLoggedIn && !courseId && !isLtiLesson && !isLessonComplete) {
      yield put(showUserWelcome());
    }

    if (!isLoggedIn && !isLtiLesson && !isLessonComplete) {
      yield put(actions.player.checkPreviousExecution());
    }
  });
}

function* watchCheckPreviousExecution() {
  yield takeEvery(PLAYER.CHECK_PREVIOUS_EXECUTION, function*() {
    const state = yield select();
    const lastPublication = selectors.player.getLastPublication(state);
    const isLoggedIn = selectors.auth.isLoggedIn(state);
    const publication = yield select(
      get(['player', 'lesson', 'meta', 'publication'])
    );

    if (!isLoggedIn) {
      yield put(clearUserExecution());
    }

    const isLtiLesson = yield select(get(['player', 'result', 'isLtiLesson']));
    const resultPublication = yield select(
      get(['player', 'previouslyExecutedResult', 'result', 'publication'])
    );
    const isActualPublication = publication === lastPublication;
    const status = yield select(get(['player', 'result', 'status']));
    const isLessonComplete = status === RESULT_STATUS_COMPLETE;

    if (
      (!isLtiLesson &&
        !isLessonComplete &&
        resultPublication &&
        resultPublication !== lastPublication) ||
      !isActualPublication
    ) {
      yield put(showOfferNewVersion());
    }

    if (resultPublication) {
      yield loadPreviousExecution();
    }
  });
}

function* watchStartNewVersion() {
  yield takeEvery(PLAYER.LESSON.START_NEW_VERSION, function*({ payload }) {
    const { lessonId, publication } = payload;
    const state = yield select();
    const previousResultPublication = yield select(
      get(['player', 'previouslyExecutedResult', 'result', 'publication'])
    );
    if (previousResultPublication) {
      yield loadPreviousExecution();
      yield put(clearUserExecution());
      yield put(complete());
    }

    const lastPublication = selectors.player.getLastPublication(state);

    if (lastPublication !== publication) {
      yield put(complete());
      yield put(downloadLesson({ lessonId, publication: lastPublication }));
    }

    const resultPublication = yield select(
      get(['player', 'result', 'publication'])
    );
    yield put(clearResult({ lessonId, publication: resultPublication }));
    yield createEmptyResult({ lessonId, publication: lastPublication });
    yield put(hideOfferNewVersion());
    yield put(push(`/player/lesson/${lessonId}`));
    yield put(actions.player.lesson.send());
  });
}

function* watchContinueOldVersion() {
  yield takeEvery(PLAYER.LESSON.CONTINUE_OLD_VERSION, function*({ payload }) {
    const { lessonId, publication } = payload;
    const state = yield select();
    const previousPublication = yield select(
      get(['player', 'previouslyExecutedResult', 'result', 'publication'])
    );
    yield loadPreviousExecution();

    if (previousPublication && !eq(previousPublication, publication)) {
      yield put(downloadLesson({ lessonId, publication: previousPublication }));
      yield put(push(`/player/lesson/${lessonId}/${previousPublication}`));
    }

    const isLoggedIn = selectors.auth.isLoggedIn(state);
    const resultPublication = yield select(
      get(['player', 'result', 'publication'])
    );
    if (!isLoggedIn && !eq(resultPublication, publication)) {
      yield put(downloadLesson({ lessonId, publication: resultPublication }));
      yield put(push(`/player/lesson/${lessonId}/${resultPublication}`));
    }

    yield put(hideOfferNewVersion());
    yield put(actions.player.lesson.send());
  });
}

function* watchUpdateExecutionResult() {
  yield takeEvery(PLAYER.UPDATE_EXECUTION_RESULT, function*() {
    const result = yield select(get(['player', 'result']));
    if (!result.pages) return
    const pages = yield updateResultPages({ result });
    yield put(actions.player.updateExecutionResultSuccess({ pages }));
  });
}

export default function*() {
  yield takeEvery(PLAYER.LOGIN, function*() {
    const state = yield select();
    if (selectors.auth.isLoggedIn(state)) {
      yield put(actions.player.downloadUserExecution());
    }
  });

  yield all([
    watchReexecute(),
    watchStartExecution(),
    watchResumeExecution(),
    watchStartNewVersion(),
    downloadUserExecution(),
    watchContinueOldVersion(),
    watchCheckPreviousExecution(),
    watchUpdateExecutionResult(),
  ]);
}
