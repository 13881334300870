import { call, put, select, getContext } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { constant, get } from 'lodash/fp';

import * as actions from '~/actions';
import { requestEnd, requestStart, showNotFoundPage } from '~/actions/ui';

export const getErrorMessage = ({ msg, i18n }) =>
  i18n.t(`sagas:responses.${msg}`, {
    defaultValue: msg,
  });

/**
 * Отправка запроса и обработка ответа
 * В случае успешного выполнения запроса возвращается тело ответа,
 * во всех остальных случаях возвращается null, который должен быть
 * дополнительно обработан в саге, которая запускает данную сагу
 */
export default function* request(
  url,
  data,
  method = 'post',
  { showNotFoundOn404 } = {}
) {
  const i18n = yield getContext('i18n');
  const axios = yield getContext('axios');
  const state = yield select();

  try {
    const response = yield call(axios.request, {
      url,
      method,
      data,
      timeout: process.env.NODE_ENV === 'production' ? 3000000 : 0,
      validateStatus: constant(true),
      withCredentials: true,
    });

    if (response.status === 200) {
      return response;
    }

    if (response.status === 403) {
      yield put(actions.auth.requestForbidden());
      const { pathname } = yield select(get(['router', 'location']));

      yield put(push(`/auth?goto=${pathname}`));
      return null;
    }

    if (response.status === 404) {
      if (showNotFoundOn404) {
        yield put(showNotFoundPage());
        return null;
      }

      yield put(actions.auth.requestError('Ошибка 404'));
      return null;
    }

    yield put(
      actions.auth.requestError(`Неопределенная ошибка: ${response.statusText}`)
    );
    return null;
  } catch (err) {
    if (/builder\/lesson\//gi.test(state.router.location.pathname)) {
      return null;
    }

    yield put(
      actions.auth.requestError(
        `Ошибка при выполнении запроса: ${getErrorMessage({
          i18n,
          msg: err,
        })}`
      )
    );
    return null;
  }
}

export function* apiRequest(
  url,
  data,
  method = 'post',
  { name, showNotFoundOn404 } = {}
) {
  if (name) {
    yield put(requestStart({ name }));
  }

  const response = yield request(
    `${process.env.API_URL}/${url}`,
    data,
    method,
    { name, showNotFoundOn404 }
  );

  if (name) {
    yield put(requestEnd({ name }));
  }

  return response;
}
