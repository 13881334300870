import { set, get, compose, values, compact, isEmpty } from 'lodash/fp';
import * as BUILDER from '../constants/builder';

/**
 * Определения контекста редактирования блока для выбора области
 * данных (редюсера), в которой необходимо произвести изменения.
 *
 * Используется для редактирования блока в окне
 * шаринга, окне поиска по библиотеке, редактировании
 * в конструкторе, выполнении в плеере или презентации
 */
export const getBlockContext = (state) => (next) => (action) => {
  if (
    // Редактирование блоков без редюсеров в конструкторе или библиотеке
    action.type === BUILDER.BLOCK.CHANGE_CONTENT ||
    // Редактирование блоков с редюсерами  в конструкторе или библиотеке или плеере или презентации
    action.type.match(/@(builder|player)\/block\/(\w+)\/.*/) ||
    action.type.match(/@player\/block\/.*/)
  ) {
    const store = state.getState();
    const library = get('library', store);
    const route = get(['router', 'location', 'pathname'], store);
    const contextFromRoute = get(1, route.match(/\/(\w+)\/.*/));
    const isLibrarySearchInEditMode = library.search.inEditMode;
    const isLibrarySearchOpen = library.search.isOpen;
    const isLibraryShareOpen = library.share.isOpen;
    const isLibraryEditOpen = isLibrarySearchOpen && isLibrarySearchInEditMode;
    const context = {
      inPlayer: contextFromRoute === 'player',
      inBuilder:
        !isLibraryEditOpen &&
        !isLibraryShareOpen &&
        !isLibrarySearchOpen &&
        contextFromRoute === 'builder',
      inLibraryEdit: isLibraryEditOpen,
      inLibraryShare: isLibraryShareOpen,
      inPresentation: contextFromRoute === 'presentation',
      inPreview: contextFromRoute === 'preview',
    };
    if (
      compose(
        isEmpty,
        compact,
        values
      )(context)
    ) {
      // eslint-disable-next-line no-console
      console.error('Не удалось определить контекст редактирования блока');
    }
    return next(set(['meta', 'context'], context, action));
  } else {
    return next(action);
  }
};

/**
 * Определения контекста работы со страницей
 */
export const getPageContext = (state) => (next) => (action) => {
  if (action.type.match(/@player\/page\/.*/)) {
    // Действия со страницей в плеере или презентации
    const store = state.getState();
    const route = get(['router', 'location', 'pathname'], store);
    const contextFromRoute = get(1, route.match(/\/(\w+)\/.*/));
    const context = {
      inPlayer: contextFromRoute === 'player',
      inBuilder: contextFromRoute === 'builder',
      inPresentation: contextFromRoute === 'presentation',
    };
    if (
      compose(
        isEmpty,
        compact,
        values
      )(context)
    ) {
      // eslint-disable-next-line no-console
      console.error('Не удалось определить контекст редактирования страницы');
    }
    return next(set(['meta', 'context'], context, action));
  } else {
    return next(action);
  }
};
