import React from 'react';
import PropTypes from 'prop-types';

import { pure, compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { formatCardDate } from '../utils/format';
import { combineStyles } from '../utils/styles';
import { TLesson } from '../@types';
import '../styles/LessonThumbnail.scss';
import Thumbnail from '~/components/Thumbnail';
import Controls from '~/components/Controls';
import { DraggedButton as Button } from '~/components/Button';
import Share from '~/containers/Share';
import { LESSON_TYPE_OLYMPIAD } from '~/appConstants';

const LessonThumbnail = ({
  t,
  id,
  i18n,
  lesson: {
    meta: { createdAt, demo: isLessonExample, type },
    content: { name, pagesIds },
  },
  edit,
  remove,
  save,
  isMobile,
  showResults,
  isShareOpen,
  isMoveToOpen,
  toggleMoveTo,
  toggleShareOpen,
  downloadAndCloneLesson,
}) => (
  <div
    className={combineStyles('LessonThumbnail', isMobile && 'mobile')}
    key={id}
  >
    <Thumbnail
      icon="osh-emptystate"
      title={name}
      pagesCount={pagesIds.length}
      variant="lesson"
      canDrag
      lessonType={type}
      withBadge={isLessonExample}
      onClick={!isMobile ? () => edit(id) : undefined}
      description={`${t('lessonthumbnails.created')} ${formatCardDate(
        createdAt,
        i18n
      )}`}
    />
    <div className="buttons">
      <Controls
        usePortal
        type="dropdown"
        lessonId={id}
        isMoveToOpen={isMoveToOpen}
        toggleMoveTo={toggleMoveTo}
        saveLesson={() => save(id, t('lessonthumbnails.moveSuccess'))}
        canDrag
        items={
          !isMobile
            ? [
                {
                  icon: 'osh-edit',
                  text: t('lessonthumbnails.edit'),
                  action: () => edit(id),
                },
                {
                  icon: 'osh-duplicate',
                  text: t('lessonthumbnails.dublicate'),
                  action: () => downloadAndCloneLesson({ id }),
                },
                {
                  icon: 'osh-duplicate',
                  noDismissPopoverOnClick: true,
                  text: t('lessonthumbnails.moveTo'),
                  action: () => toggleMoveTo(true),
                },
                {
                  icon: 'osh-trash',
                  text: t('lessonthumbnails.remove'),
                  action: () => remove(id),
                },
              ].filter(Boolean)
            : [
                {
                  icon: 'osh-trash',
                  text: t('lessonthumbnails.remove'),
                  action: () => remove(id),
                },
              ].filter(Boolean)
        }
        variant="lessons"
      />
      <div className="share">
        <Button
          minimal
          icon="osh-share"
          variant="share-thumbnail-controls-toggler"
          onClick={() => toggleShareOpen(!isShareOpen)}
        />
      </div>
      {isShareOpen && (
        <Share
          lessonId={id}
          icon="osh-share"
          isOpen={isShareOpen}
          lessonName={name}
          close={() => toggleShareOpen(false)}
          text={t('lesson.controlShare')}
        />
      )}
      {type !== LESSON_TYPE_OLYMPIAD && !isMobile && (
        <div className="button">
          <Button
            minimal
            icon="osh-results"
            variant="remove-image"
            onClick={() => showResults(id)}
          />
        </div>
      )}
    </div>
  </div>
);

const { string, func, bool, object } = PropTypes;

LessonThumbnail.propTypes = {
  t: func.isRequired, // Функция перевода
  id: string.isRequired, // ID урока
  i18n: object.isRequired,
  lesson: TLesson, // что же это ?)
  edit: func.isRequired, // Редактировать урок
  downloadAndCloneLesson: func.isRequired, // Копировать урок
  remove: func.isRequired, // Удалить урок
  save: func.isRequired, // Сохранение урока
  isShareOpen: bool, // Окно результатов открыто?
  isMobile: bool.isRequired, // Окно результатов открыто?
  showResults: func.isRequired, // Удалить урок
  isMoveToOpen: bool, // Окно перемещения урока открыто?
  toggleMoveTo: func, // Функция открытия окна перемещения урока
  toggleShareOpen: func, // Окно шаринга открыто?
};

const mapDispatchToProps = {
  save: actions.builder.lesson.save,
  downloadAndCloneLesson: actions.builder.lesson.downloadAndClone,
  closeResults: actions.results.close,
  showResults: actions.results.open,
  remove: actions.builder.lesson.removeConfirm,
};

const mapStateToProps = (state, ownProps) => ({
  lesson: selectors.builder.getLesson(ownProps.id, state),
});

LessonThumbnail.defaultProps = {
  lesson: null,
  isShareOpen: false,
  isMoveToOpen: false,
  toggleMoveTo: undefined,
  toggleShareOpen: undefined,
};

export default compose(
  withRouter,
  withTranslation('components'),
  withMobileDetect,
  withState('isShareOpen', 'toggleShareOpen', false),
  withState('isMoveToOpen', 'toggleMoveTo', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    edit: ({ history, closeResults }) => (lessonId) => {
      history.push(`/builder/lesson/${lessonId}`);
      closeResults();
    },
  }),
  pure
)(LessonThumbnail);
