/* eslint-disable more/no-numeric-endings-for-variables */

export const COURSE_USER_STATUS_INVITED = 'invited';
export const COURSE_USER_STATUS_ACTIVE = 'active';
export const COURSE_USER_STATUS_NOT_INVITED = 'not_invited';

export const COURSE_STATUS_NOT_ACTIVE = 'not_active';
export const COURSE_STATUS_ACTIVE = 'active';
export const COURSE_STATUS_FINISHED = 'finished';

export const RESULT_STATUS_COMPLETE = 'complete';
export const RESULT_STATUS_ACTIVE = 'active';
export const RESULT_STATUS_CREATED_WITH_LTI = 'created_with_lti';

export const QUIZ_BLOCK_TYPE_RADIO = 'radio';
export const QUIZ_BLOCK_TYPE_CHECKBOX = 'checkbox';

export const TALK_CARD_TYPE_QUESTION = 'question';
export const TALK_CARD_TYPE_INFORMATION = 'information';
export const TALK_CARD_TYPE_FINAL = 'final';

export const TALK_TARGET_TYPE_CONTENT_CARD = 'content_card';
export const TALK_TARGET_TYPE_FINAL = 'final';

export const LESSON_DOWNLOAD_STATUS_ERROR = 'error';
export const LESSON_DOWNLOAD_STATUS_CLOSED = 'closed';
export const LESSON_DOWNLOAD_STATUS_LTI_CLOSED = 'lti_closed';
export const LESSON_DOWNLOAD_STATUS_DOWNLOADED = 'downloaded';
export const LESSON_DOWNLOAD_STATUS_DOWNLOADING = 'downloading';

export const LESSON_TYPE_REGULAR = 'regular';
export const LESSON_TYPE_EXAM = 'exam';
export const LESSON_TYPE_OLYMPIAD = 'olympiad';

export const LESSON_EXECUTE_STATUS_PROCESS = 'process';
export const LESSON_EXECUTE_STATUS_COMPLETE = 'complete';
export const LESSON_EXECUTE_STATUS_INCOMPLETE = 'incomplete';

// TODO: по всей видимости, не нужны дополнительные статусы. Можно сразу из download передавать нужные константы
export const LESSON_DOWNLOAD_STATUS_ERROR_OUT_OF_DATES = 'OUT_OF_DATES';
export const LESSON_DOWNLOAD_STATUS_ERROR_LTI_CLOSED = 'LTI_CLOSED';
export const LESSON_DOWNLOAD_STATUS_ERROR_COURSE_MEMBERS_ONLY =
  'COURSE_MEMBERS_ONLY';
export const LESSON_DOWNLOAD_STATUS_ERROR_NOT_ACTIVE_COURSE_LESSON =
  'not_active_course_lesson';
export const LESSON_DOWNLOAD_STATUS_ERROR_UNKNOWN = 'UNKNOWN';

export const AUTH_MODE_REGISTER = 'register';
export const AUTH_MODE_LOGIN = 'login';
export const AUTH_MODE_ANONYMOUS = 'anonymous';

export const UPLOAD_TYPE_COURSE = 'course';
export const UPLOAD_TYPE_LESSON = 'lesson';
export const UPLOAD_TYPE_ANSWER = 'answer';

export const AUTH_ROLE_TEACHER = 'teacher';
export const AUTH_ROLE_STUDENT = 'student';

export const BLOCK_PASS_STATUS_SAVED = 'saved';
export const BLOCK_PASS_STATUS_NOT_PASSED_YET = 'notPassedYet'; // блок не пройден, но урок еще не завершен
export const BLOCK_PASS_STATUS_NOT_PASSED = 'notPassed';
export const BLOCK_PASS_STATUS_IN_PROCESS = 'inProcess';
export const BLOCK_PASS_STATUS_PASSED_CORRECTLY = 'passedCorrectly';
export const BLOCK_PASS_STATUS_PASSED_INCORRECTLY = 'passedIncorrectly';

export const REQUEST_NAME_AUTH = 'auth';
export const REQUEST_NAME_LESSON_PUBLISH = 'lesson_publish';
export const REQUEST_NAME_AUTH_RECOVERY = 'auth_recovery';
export const REQUEST_NAME_AUTH_SAVE_PASSWORD = 'auth_save_password';
export const REQUEST_NAME_SUBSCRIBE = 'subscribe';
export const REQUEST_NAME_SEND_INVITES = 'send_invites';
export const REQUEST_NAME_CHANGE_COURSE_STATUS = 'change_course_status';
export const REQUEST_NAME_EXTRA_DAYS = 'extra_days';
export const REQUEST_NAME_COURSE_DOWNLOADING = 'course_downloading';
export const REQUEST_NAME_LESSON_DOWNLOADING = 'lesson_downloading';
export const REQUEST_NAME_RESULT_DOWNLOADING = 'result_downloading';
export const REQUEST_NAME_ANSWER_BLOCK_DOWNLOADING = 'answer_block_downloading';
export const REQUEST_NAME_ANSWER_BLOCKS_LIST_DOWNLOADING =
  'answer_blocks_list_downloading';
export const REQUEST_NAME_ADD_NEW_REPLY = 'add_new_reply';
export const REQUEST_NAME_DOWNLOAD_NOTIFICATIONS = 'download_notifications';
export const REQUEST_NAME_SET_VIEWED_NOTIFICATIONS = 'set_viewed_notifications';
export const REQUEST_NAME_SEND_VERIFICATION_EMAIL =
  'auth_send_verification_email;';

export const REQUEST_NAME_CHECK_VERIFICATION_CODE =
  'auth_check_verification_code;';

export const PLAN_NAME_FREE = 'Free';
export const PLAN_NAME_NUM1 = '#1';
export const PLAN_NAME_NUM3 = '#3';
export const PLAN_NAME_NUM7 = '#7';
export const PLAN_NAME_NUM10 = '#10';
// export const PLAN_NAME_CUSTOM = 'Custom';

export const SUBSCRIPTION_STATUS_ACTIVE = 'ACTIVE';
export const SUBSCRIPTION_STATUS_INACTIVE = 'INACTIVE';
export const SUBSCRIPTION_STATUS_DELETED = 'DELETED';

export const MODAL_NAME_PLANS = 'plans';
export const MODAL_COURSE_STATUS = 'courseStatus';

export const taskTypes = [
  'Exam',
  'Quiz',
  'Match',
  'Weight',
  'FillBlank',
  'Talk',
  'Answer',
];

export const STATUS_APPROVED = 'approved';
export const STATUS_NOT_CHECKED = 'not-checked';
export const STATUS_REJECTED = 'rejected';

export const TUTOR_STATUS_ACTIVE = 'active';
export const TUTOR_STATUS_INVITED = 'invited';

export const NOTIFICATION_TYPE_ANSWER_REVIEW = 'ANSWER_REVIEW';
export const NOTIFICATION_TYPE_TUTOR_INVITE = 'TUTOR_INVITE';
