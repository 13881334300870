import { createAction } from '../utils/actions';

export const START_PRESENTATION = '@presentation/Начать презентацию';
export const DOWNLOAD_LESSON_SUCCESS =
  '@presentation/Загрузка урока с сервера выполнена';

export const SET_NEW_RESULT = '@presentation/Установка нового результата';

export const startPresentation = ({ lessonId }) =>
  createAction(START_PRESENTATION, { lessonId });

export const downloadLessonSuccess = ({ lesson, pages, blocks }) =>
  createAction(DOWNLOAD_LESSON_SUCCESS, { lesson, pages, blocks });

export const setNewResult = ({ result }) =>
  createAction(SET_NEW_RESULT, { result });
