import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import * as AUTH from '~/constants/auth';
import * as PLAYER from '~/constants/player';

import ui from './ui';
import app from './app';
import auth from './auth';
import cache from './cache';
import study from './study';
import player from './player';
import notice from './notice';
import confirm from './confirm';
import builder from './builder';
import library from './library';
import results from './results';
import settings from './settings';
import clypboard from './clypboard';
import presentation from './presentation';
import publicOlympiadResult from './publicOlympiadResult';
import course from './course';
import payment from './payment';
import review from './review';
import notification from './notification';
import tutors from './tutors';

export default (history) => (state, action) => {
  const isLogout = action.type === AUTH.LOGOUT || action.type === PLAYER.LOGOUT;
  const newState = isLogout ? { router: state.router } : state;

  if (isLogout) {
    localStorage.removeItem('persist:player.previouslyExecutedResults');
    localStorage.removeItem('persist:player.theme');
  }

  return combineReducers({
    app, // Данные приложения, свойства, версия миграций
    ui, // Данные UI для всей системы
    auth, // Авторизация
    cache, // Кэш загружаемых данных
    player, // Плеер
    notice, // Уведомления
    confirm, // Подтверждения действий
    library, // Библиотека
    builder, // Конструктор
    results, // Результаты
    settings, // Настройки
    study,
    clypboard, // Клипбоард (copy/paste операции)
    presentation,
    course,
    payment,
    publicOlympiadResult,
    review,
    notification,
    tutors,
    router: connectRouter(history),
  })(newState, action);
};
