import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/select';
import { DraggedButton as Button } from './Button';
import { combineStyles } from '../utils/styles';

const Select = ({ items, variant, onChange }) => (
  <div className={combineStyles('Select', variant)}>
    <Blueprint.Select items={items} onItemSelect={onChange} usePortal={false}>
      <Button text={items[items.length - 1]} />
    </Blueprint.Select>
  </div>
);

const { oneOfType, array, string, func } = PropTypes;

Select.propTypes = {
  items: array, // Массив вариантов в селекте
  variant: oneOfType([array, string]), // Вариант оформления
  onChange: func.isRequired, // Действие при изменении селекта
};

Select.defaultProps = {
  items: [],
  variant: undefined,
};

export default pure(Select);
