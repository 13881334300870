import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyEmail } from '~/actions/auth';

const VerifyEmail = ({
  match: {
    params: { code },
  },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyEmail(code));
  }, [code, dispatch]);

  return '';
};

export default VerifyEmail;
