import { set, compose, update, map } from 'lodash/fp';

import {
  DOWNLOAD_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  MARK_AS_VIEWED_SUCCESS,
} from '~/actions/study/notifications';

const defaultState = {
  count: 0,
  notifications: [],
};

const notificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_COUNT_SUCCESS: {
      const { count } = action.payload;
      return set('count', count, state);
    }

    case MARK_AS_VIEWED_SUCCESS: {
      const { count, ids } = action.payload;
      return compose(
        update(
          'notifications',
          map((notification) =>
            ids.includes(notification.id)
              ? set('isViewed', true, notification)
              : notification
          )
        ),
        set('count', count)
      )(state);
    }

    case DOWNLOAD_NOTIFICATIONS_SUCCESS: {
      const { notifications } = action.payload;
      return set('notifications', notifications, state);
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
