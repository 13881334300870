import React from 'react';
import PropTypes from 'prop-types';

import { isArray } from 'lodash/fp';

import { Button, Dialog, Text } from '../../../components';
import '../../../styles/Confirm.scss';

const AutoPaymentConfirm = ({
  isOpen,
  message,
  cancelText,
  acceptText,
  onCancel,
  onAccept,
}) => (
  <Dialog isOpen={isOpen} variant="confirm" className="Library" usePortal>
    {isArray(message) ? (
      message.map((text) => (
        <div className="text" key={text}>
          <Text value={text} variant="in-confirm" />
        </div>
      ))
    ) : (
      <div className="text">
        <Text value={message} variant="in-confirm" />
      </div>
    )}
    <div className="controls">
      <Button text={cancelText} variant="confirm-cancel" onClick={onCancel} />
      <Button text={acceptText} variant="confirm-accept" onClick={onAccept} />
    </div>
  </Dialog>
);

const { bool, func, string, oneOfType, array } = PropTypes;

AutoPaymentConfirm.propTypes = {
  isOpen: bool.isRequired, // Конфирм открыта?
  message: oneOfType([array, string]), // Текст сообщения, может быть несколько абзацев
  cancelText: string, // Текст на кнопке отмены
  acceptText: string, // Текст на кнопке подтверждения
  onCancel: func, // Действие при отмене
  onAccept: func, // Дейтсвие при подтверждении
};

AutoPaymentConfirm.defaultProps = {
  message: '',
  cancelText: '',
  acceptText: '',
};

export default AutoPaymentConfirm;
