import objectId from 'bson-objectid';
import * as PAGE from '../../constants/builder/page';
import { createAction } from '../../utils/actions';

export const add = (lessonId, index) =>
  createAction(PAGE.ADD, { id: objectId.generate(), index, lessonId });
export const paste = (lessonId) => createAction(PAGE.PASTE, { lessonId });
export const move = (id, lessonId, oldIndex, newIndex) =>
  createAction(PAGE.MOVE, { id, oldIndex, newIndex, lessonId });
export const clone = (id, lessonId) =>
  createAction(PAGE.CLONE, { id, lessonId });
export const remove = (id, lessonId) =>
  createAction(PAGE.REMOVE, { id, lessonId });
export const changeName = (id, newName) =>
  createAction(PAGE.CHANGE_NAME, { id, newName });
export const toggleExamMode = (id) =>
  createAction(PAGE.TOGGLE_EXAM_MODE, { id });
export const enableShuffle = ({ displayedBlocks, id }) =>
  createAction(PAGE.ENABLE_SHUFFLE, { displayedBlocks, id });
export const disableShuffle = ({ id }) =>
  createAction(PAGE.DISABLE_SHUFFLE, { id });
export const changeBlocksQuantity = (count, id) =>
  createAction(PAGE.CHANGE_BLOCKS_QUANTITY, { count, id });
