import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash/fp';
import { pure, compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import logo from 'Custom/logo.png';
import withMobileDetect from '~/hocs/withMobileDetect';
import Link from '~/components/Link';
// import Icon from '~/components/Icon';
import SelectLocale from '~/containers/SelectLocale';
import Notification from '~/components/Notification/Notification';
import * as s from './styles/Header.styles';
import NotificationsIcon from '~/components/NotificationsIcon';

const Header = ({
  // type,
  items,
  theme,
  navbar,
  history,
  leftLink,
  backLink,
  isMobile,
  // userPlan,
  isLtiLesson,
  displayNotifications,
}) => {
  // const { nextPaymentAt } =
  //   useSelector(selectors.auth.getUserSubscription) || {};
  // const daysBeforeExpire = Math.floor(
  //   Math.abs(new Date(nextPaymentAt) - new Date() / 1000) / 86400
  // );
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const menu = (
    <div css={s.links}>
      {items.map((item, index) => (
        <Link
          key={index}
          text={isMobile && item.mobileText ? item.mobileText : item.text}
          to={item.link}
          variant={[
            'header-link',
            item.variant,
            get(['location', 'pathname'], history) === item.link && 'active',
          ]}
        />
      ))}
    </div>
  );

  return (
    <>
      <div css={s.container} theme={theme}>
        <div css={s.leftPart}>
          {leftLink || (
            <>
              {backLink ? (
                <div css={s.backlink}>
                  <Link
                    to={backLink}
                    withIcon="arrow-left"
                    variant="header-backlink"
                  />
                </div>
              ) : (
                <div css={s.logo}>
                  <img css={s.logoImg} src={logo} alt="core" />
                </div>
              )}
              {!isMobile && !isEmpty(items) && menu}
            </>
          )}
        </div>
        <div css={s.rightPart}>
          {displayNotifications && !isLtiLesson && (
            <NotificationsIcon onClick={() => setNotificationOpen(true)} />
          )}
          {isNotificationOpen && !isLtiLesson && (
            <Notification
              close={setNotificationOpen}
              isOpen={isNotificationOpen}
            />
          )}

          {!isLtiLesson ? navbar : null}
          {!backLink && <SelectLocale minimal variant={['in-header', theme]} />}
        </div>
      </div>
      {isMobile && !isEmpty(items) && <div css={s.menu}>{menu}</div>}
    </>
  );
};

const {
  oneOfType,
  string,
  arrayOf,
  shape,
  object,
  node,
  bool,
} = PropTypes;

Header.propTypes = {
  backLink: string,
  navbar: node,
  theme: string,
  // type: string,
  history: object,
  leftLink: node,
  isMobile: bool.isRequired,
  isLtiLesson: bool,
  items: arrayOf(
    // Массив внутренних элементов контрола
    oneOfType([
      shape({
        text: string, // Иконка
        link: string, // Иконка
      }),
    ]).isRequired
  ),
  // userPlan: string,
  displayNotifications: bool,
};

Header.defaultProps = {
  // type: '',
  items: [],
  backLink: '',
  // userPlan: '',
};

export default compose(
  withTranslation('components'),
  withMobileDetect,
  withRouter,
  pure
)(Header);
