import { put, call, take, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { get } from 'lodash/fp';
import * as PLAYER from '../../constants/player';
import * as actions from '../../actions';
import * as lesson from './lesson';
import { trySendResult } from './lesson/send';
import request from '../request';
import { LESSON_TYPE_OLYMPIAD } from '~/appConstants';

export { lesson };

const api = (code) => `${process.env.API_URL}/lesson/code/${code}`;

/**
 * Получение ID урока по коду и переход в плеер в случае успеха
 */
export function* getLessonIdByCode() {
  while (true) {
    const {
      payload: { code },
    } = yield take(PLAYER.ENTER_BY_CODE);
    if (code) {
      const response = yield call(request, api(code), null, 'get');
      if (response) {
        const { error, lessonId, publications } = response.data;
        if (lessonId && !error) {
          yield put(push(`/player/lesson/${lessonId}/${publications.length}`));
        } else {
          yield put(actions.player.enterByCodeError());
        }
      } else {
        yield put(actions.player.enterByCodeError());
      }
    }
  }
}

export function* watchPreLogout() {
  yield takeEvery(PLAYER.PRE_LOGOUT, function*() {
    const state = yield select();
    if (!state.player.lesson.meta.type === LESSON_TYPE_OLYMPIAD) {
      yield call(trySendResult);
    }
    const { lessonId, publication } = yield select(get(['player', 'result']));
    yield put(actions.player.logout());
    yield put(actions.player.lesson.reexecute({ lessonId, publication }));
  });
}
