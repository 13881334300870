import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { lifecycle } from 'recompose';

import {
  getLessonCorrectTasksCount,
  getLessonTotalTasksCount,
} from '~/helpers/player/tasksCount';
import OlympiadResult from '~/components/OlympiadResult';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { lessonId } = ownProps;
  const totalTasksCount = getLessonTotalTasksCount(state);
  const correctTasksCount = getLessonCorrectTasksCount(state);
  const { levels } = selectors.player.getOlympiadSettings(lessonId, state);

  return {
    levels,
    totalTasksCount,
    correctTasksCount,
  };
};

const mapDispatchToProps = {
  completeLesson: actions.player.lesson.complete,
};

const enhance = lifecycle({
  componentDidMount() {
    // eslint-disable-next-line fp/no-this
    const { prevOlympiadResult, completeLesson } = this.props;
    if (!prevOlympiadResult) {
      completeLesson();
    }
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(OlympiadResult);
