import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Dialog, Text } from '../components';
import '../styles/Confirm.scss';

const Confirm = ({
  t,
  isOpen,
  message,
  cancelText,
  acceptText,
  onCancel,
  onAccept,
}) => (
  <Dialog
    isOpen={isOpen}
    variant="confirm"
    className="Library"
    // usePortal={false}
  >
    <Text value={t(message)} variant="in-confirm" />
    <div className="controls">
      <Button
        text={t(cancelText)}
        variant="confirm-cancel"
        onClick={onCancel}
      />
      <Button
        text={t(acceptText)}
        variant="confirm-accept"
        onClick={onAccept}
      />
    </div>
  </Dialog>
);

const { bool, func, string } = PropTypes;

Confirm.propTypes = {
  t: func.isRequired, // Функция перевода
  isOpen: bool, // Конфирм открыта?
  message: string, // Текст сообщения
  cancelText: string, // Текст на кнопке отмены
  acceptText: string, // Текст на кнопке подтверждения
  onCancel: func, // Действие при отмене
  onAccept: func, // Дейтсвие при подтверждении
};

Confirm.defaultProps = {
  isOpen: false,
  message: '',
  cancelText: '',
  acceptText: '',
  onCancel: undefined,
  onAccept: undefined,
};

const mapStateToProps = (state) => ({
  isOpen: state.confirm.isOpen,
  message: state.confirm.message,
  cancelText: state.confirm.cancelText,
  acceptText: state.confirm.acceptText,
  onCancel: state.confirm.onCancel,
  onAccept: state.confirm.onAccept,
});

const mergeProps = (stateProps, dispatchProps) => {
  const { onAccept, onCancel } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...stateProps,
    onCancel: () => dispatch(onCancel),
    onAccept: () => dispatch(onAccept),
  };
};

export default compose(
  connect(
    mapStateToProps,
    null,
    mergeProps
  ),
  withTranslation('containers')
)(Confirm);
