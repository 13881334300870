import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragSource } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../utils/styles';
import { isTouchDevice } from '../utils/drag';
import '../styles/DraggableTag.scss';
import Text from '~/components/Text';
import Icon from '~/components/Icon';

const DraggableTag = ({
  t,
  value,
  noDrag,
  variant,
  inPlayer,
  inReview,
  isDragging,
  noCheckMode,
  isOlympiadMode,
  connectDragSource,
}) => (
  <div
    className={combineStyles('DraggableTag', [
      isDragging && 'hidden',
      (!noCheckMode || inReview) && !isTouchDevice && variant,
      inPlayer && 'in-player',
    ])}
  >
    {inReview || noDrag ? (
      <div className="text">
        <div className="content">
          {(!noCheckMode || inReview || isOlympiadMode) &&
            !isTouchDevice &&
            variant && (
              <Text
                value={
                  variant === 'success'
                    ? t('draggabletag.true')
                    : t('draggabletag.false')
                }
                variant={['match-check', variant]}
              />
            )}
          <Text value={value} variant={['tag-text', inPlayer && 'in-player']} />
        </div>
      </div>
    ) : (
      connectDragSource(
        <div className="text">
          <div className="content">
            {(!noCheckMode || inReview || isOlympiadMode) &&
              !isTouchDevice &&
              variant && (
                <Text
                  value={
                    variant === 'success'
                      ? t('draggabletag.true')
                      : t('draggabletag.false')
                  }
                  variant={['match-check', variant]}
                />
              )}
            <Text
              value={value}
              variant={['tag-text', inPlayer && 'in-player']}
            />
            <Icon name="drag-handle-vertical" variant="tag-draggable" />
          </div>
        </div>
      )
    )}
  </div>
);

const { string, func, oneOfType, array, bool } = PropTypes;

DraggableTag.propTypes = {
  t: func.isRequired, // Функция перевода
  value: string, // Текст тэга при создании напрямую
  noDrag: bool, // Запретить перетаскивать?
  variant: oneOfType([array, string]), // Вариант оформления
  inPlayer: bool, // В плеере
  inReview: bool, // В результатах
  isDragging: bool.isRequired, // Флаг перетаскивания
  noCheckMode: bool, // Показывать правильность ответов?
  isOlympiadMode: bool, // Режим олимпиады включен?
  connectDragSource: func, // Подключение dnd
};

DraggableTag.defaultProps = {
  value: undefined,
  noDrag: false,
  variant: undefined,
  inPlayer: false,
  inReview: undefined,
  noCheckMode: undefined,
  isOlympiadMode: false,
  connectDragSource: undefined,
};

const dragSource = {
  beginDrag: (props) => {
    const { variant, basketId } = props;
    return {
      answer: props.value,
      variant,
      basketId,
    };
  },
  // endDrag: (props, monitor) => console.log(monitor),
};

const collectDragSource = (connectDrag, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: connectDrag.dragSource(),
  connectDragPreview: connectDrag.dragPreview(),
});

export default compose(
  withTranslation('components'),
  DragSource('tag', dragSource, collectDragSource)
)(DraggableTag);
