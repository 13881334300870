import * as results from '../../actions/study/results';

export const defaultState = {};

const resultsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case String(results.downloadSuccess): {
      const { downloadedResults } = action.payload;
      return downloadedResults;
    }

    default:
      return state;
  }
};

export default resultsReducer;
