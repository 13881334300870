import { shape, number, arrayOf, bool, string } from 'prop-types';

export const TLesson = shape({
  meta: shape({
    code: string,
    type: string,
    creatorId: string,
    createdAt: number,
    folderId: string,
    withDateRange: bool,
    publications: arrayOf(number),
  }),
  content: shape({
    pagesIds: arrayOf(string),
    name: string,
    description: string,
  }),
});

export const TResult = shape({
  content: shape({
    result: shape({
      id: string,
      lessonId: string,
      publication: string,
      pages: shape({}),
      status: string,
      viewedPagesIds: arrayOf(string),
      blocks: shape({}),
      activePage: string,
    }),
  }),
  isLessonComplete: bool,
  lesson: string,
  mail: string,
  meta: shape({
    activePage: string,
    lastModified: number,
  }),
  modified: number,
  publication: string,
  user: string,
  userId: string,
  id: string,
});

export const TPublication = shape({
  // publications: 1,
  lessonId: string, // '5d3f3d1e6b66b786849ffa5d',
  content: shape({
    pages: shape({}),
    blocks: shape({}),
    lesson: shape({}),
  }),
  modified: number,
  created: number,
  id: string,
});

export const TCourse = shape({
  id: string,
  user: string,
  modified: number,
  name: string,
  description: string,
  lessonsIds: arrayOf(string),
});
