import * as QUIZ from './quiz';
import * as EXAM from './exam';
import * as TALK from './talk';
import * as MATCH from './match';
import * as ANSWER from './answer';
import * as FEEDBACK from './feedback';
import * as FILLBLANK from './fillblank';

export { QUIZ, EXAM, ANSWER, FILLBLANK, FEEDBACK, MATCH, TALK };

export const RESET =
  '@player/block/Сбросить результаты выполнения заданий в блоке';
export const VERIFY =
  '@player/block/Проверить правильные ответы заданий в блоке';
