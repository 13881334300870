import { getOr, curry, filter, orderBy, mapValues, isBoolean } from 'lodash/fp';
import {
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_NOT_CHECKED,
} from '~/appConstants';

export const selectAnswerBlocks = curry((sort, state) =>
  orderBy('date', sort === 'newFirst' ? 'asc' : 'desc')(
    getOr([], ['review', 'answerBlocks'], state)
  )
);

export const selectAnswerBlocksTabData = curry((sort, state) =>
  mapValues((filterFn) =>
    filter(({ isApproved }) => filterFn(isApproved))(
      selectAnswerBlocks(sort, state)
    )
  )({
    [STATUS_NOT_CHECKED]: (isApproved) => !isBoolean(isApproved),
    [STATUS_APPROVED]: (isApproved) => isApproved === true,
    [STATUS_REJECTED]: (isApproved) => isApproved === false,
  })
);
