import Cookies from 'js-cookie';
import * as AUTH from '../constants/auth';
import * as PLAYER from '../constants/player';

/**
 * Установка куки при вторизации (для редиректа с лендинга)
 */
export const setLoggedIn = () => (next) => (action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_EXECUTION_SUCCESS:
      Cookies.set('user', action.payload.result.userName, { domain: '.sdot.ttc.kz' });
      break;
    case AUTH.REQUEST_SUCCESS:
      Cookies.set('is_logged_in', 1);
      Cookies.set('key', action.payload.key);
      Cookies.set('user', action.payload.userName, { domain: '.sdot.ttc.kz' });
      break;
    case AUTH.LOGOUT:
    case PLAYER.LOGOUT:
      Cookies.set('is_logged_in', 0);
      Cookies.remove('key');
      Cookies.remove('user');
      break;
    default:
  }

  return next(action);
};
