import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { isMobile } = state.ui;
  return { isMobile };
};

const withMobileDetect = connect(mapStateToProps);

export default withMobileDetect;
