import { put, takeLatest } from 'redux-saga/effects';
import { get, reduce, assign } from 'lodash/fp';
import { downloadTutorLessonsSuccess } from '~/actions/tutors';
import { apiRequest } from '~/sagas/request';
import { DOWNLOAD_LESSONS } from '~/constants/tutors';

export function* watchDownloadTutorLessons() {
  yield takeLatest(DOWNLOAD_LESSONS, function*() {
    const response = yield apiRequest('lesson/tutor/list', null, 'get');

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      return;
    }

    const { lessons } = payload;
    const tutorLessons = reduce(
      (downloadedLessons, lesson) =>
        assign(downloadedLessons, get(['content', 'lesson'], lesson)),
      {}
    )(lessons);

    yield put(downloadTutorLessonsSuccess({ lessons: tutorLessons }));
  });
}
