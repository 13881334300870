import { css } from 'styled-components';
import noAvatar from '../../../static/img/no-avatar.png';

export const container = css`
  padding: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const topContainer = css`
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 526px;
  height: 114px;
  border-radius: 3px;
  border: solid 1px #e5e9ec;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #45494e;
`;

export const avatar = css`
  margin-top: 22px;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
`;

export const bottomText = css`
  margin-top: 20px;
  display: block;
  font-family: Gilroy;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  color: #45494e;
`;
