import { set, compose } from 'lodash/fp';
import * as actions from '../actions';
import * as BUILDER from '../constants/builder';
import * as RESULTS from '../constants/results';
import * as CONFIRM from '../constants/confirm';
import * as talk from '../actions/builder/block/talk';

export const defaultState = {
  isOpen: false,
  message: 'confirm.defaultMessage',
  cancelText: 'confirm.cancelText',
  acceptText: 'confirm.acceptText',
};

const setConfirm = (
  isOpen,
  message,
  onCancel,
  onAccept,
  cancelText,
  acceptText
) =>
  compose(
    set('isOpen', isOpen),
    set('onCancel', onCancel),
    set('message', message),
    set('onAccept', onAccept),
    set('cancelText', cancelText),
    set('acceptText', acceptText)
  );

const confirmReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CONFIRM.SHOW: {
      return set('isOpen', true, state);
    }

    case CONFIRM.CLOSE:
    case RESULTS.RESET_CANCEL:
    case RESULTS.RESET_ACCEPT:
    case BUILDER.LESSON.REMOVE_CANCEL:
    case BUILDER.LESSON.REMOVE_ACCEPT:
    case BUILDER.FOLDER.REMOVE_CANCEL:
    case BUILDER.FOLDER.REMOVE_ACCEPT:
    case BUILDER.COURSE.REMOVE_CANCEL:
    case BUILDER.COURSE.REMOVE_ACCEPT:
    case talk.REMOVE_CARD_ACCEPT:
    case talk.REMOVE_CARD_CANCEL: {
      return set('isOpen', false, state);
    }

    case BUILDER.LESSON.REMOVE_CONFIRM: {
      return setConfirm(
        true,
        'confirm.removeLesson',
        actions.builder.lesson.removeCancel(),
        actions.builder.lesson.removeAccept(action.payload.id),
        'confirm.cancelText',
        'confirm.remove'
      )(state);
    }

    case BUILDER.FOLDER.REMOVE_CONFIRM: {
      return setConfirm(
        true,
        action.payload.msg,
        actions.builder.folder.removeCancel(),
        actions.builder.folder.removeAccept(action.payload.id),
        'confirm.cancelText',
        'confirm.remove'
      )(state);
    }

    case BUILDER.COURSE.REMOVE_CONFIRM: {
      return setConfirm(
        true,
        action.payload.msg || 'confirm.removeCourse',
        actions.builder.course.removeCancel(),
        actions.builder.course.removeAccept(action.payload.id),
        'confirm.cancelText',
        'confirm.remove'
      )(state);
    }

    case RESULTS.RESET_CONFIRM: {
      return setConfirm(
        true,
        'confirm.resetResults',
        actions.results.resetCancel(),
        actions.results.resetAccept(
          action.payload.lessonId,
          action.payload.publication
        ),
        'confirm.cancelText',
        'confirm.reset'
      )(state);
    }

    case talk.REMOVE_CARD_CONFIRM: {
      const { blockId, cardId } = action.payload;
      return setConfirm(
        true,
        'confirm.removeTalkBlock',
        talk.removeCardCancel(),
        talk.removeCardAccept({ blockId, cardId }),
        'confirm.cancelText',
        'confirm.remove'
      )(state);
    }

    default:
      return state;
  }
};

export default confirmReducer;
