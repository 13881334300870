import { useHistory, useLocation } from 'react-router';
import {
  addQueryParam,
  parseQueryParams,
  removeQueryParam,
} from '~/utils/query-params';

export const useQueryParameters = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const parameters = parseQueryParams(search);
  const url = `${pathname}${search}`;

  return {
    parameters,
    removeQueryParam: (paramName) =>
      history.push(removeQueryParam(paramName, url)),
    addQueryParam: (param) => history.push(addQueryParam(param, url)),
  };
};
