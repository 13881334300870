import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { lifecycle, withHandlers } from 'recompose';

import * as actions from '../actions';
import '../styles/Hyperlink.scss';

// TODO loader не работает (нет стилей для такого класса)
const PlayerResultsLoader = () => <div className="loader" />;

const mapDispatchToProps = {
  resumeExecution: actions.player.resumeExecution,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  compose(
    withHandlers({
      resumeExecution: ({ resumeExecution, match }) => () =>
        resumeExecution({ resultId: match.params.resultId }),
    }),
    lifecycle({
      componentDidMount() {
        // eslint-disable-next-line fp/no-this
        this.props.resumeExecution();
      },
    })
  )
)(PlayerResultsLoader);
