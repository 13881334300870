import {
  get,
  getOr,
  map,
  size,
  pick,
  keys,
  first,
  update,
  filter,
  pickBy,
  compose,
  flatten,
  mapKeys,
  compact,
  isEmpty,
  includes,
  identity,
  zipObject,
} from 'lodash/fp';
import memoize from 'fast-memoize';
import objectId from 'bson-objectid';

export const generateId = objectId.generate;

// Получить ID урока по ID страницы
export const getLessonId = (pageId, allLessons) =>
  compose(
    first,
    keys,
    pickBy((lesson) => includes(pageId, get(['content', 'pagesIds'], lesson)))
  )(allLessons);

export const hasBlocks = (pageId, allPages) =>
  get([pageId, 'blocksIds'], allPages)
    ? get([pageId, 'blocksIds'], allPages).length > 0
    : false;

// Получить коллекцию ID блоков определенного типа
export const getBlocksIdsByType = memoize(
  (pageId, blockType, allBlocks, allPages) =>
    filter(
      (blockId) => get([blockId, 'type'], allBlocks) === blockType,
      get([pageId, 'blocksIds'], allPages)
    )
);

// Получить коллекцию ID блоков определенных типов
export const getBlocksIdsByTypes = memoize(
  (pageId, blockTypes, allBlocks, allPages) =>
    filter(
      (blockId) => includes(get([blockId, 'type'], allBlocks), blockTypes),
      get([pageId, 'blocksIds'], allPages)
    )
);

// Страница только с блоками Quiz?
export const checkQuizPage = memoize(
  (pageId, blockTypes, allBlocks, allPages) =>
    isEmpty(get([pageId, 'blocksIds'], allPages))
      ? false
      : isEmpty(
          filter(
            (blockId) =>
              !includes(get([blockId, 'type'], allBlocks), blockTypes),
            get([pageId, 'blocksIds'], allPages)
          )
        )
);

// Счетчик видимых комментариев одного блока Quiz
export const getCommentsVisibleBlock = (selectedAnswersIds, comments) =>
  compose(
    size,
    compact,
    map((answerId) => includes(answerId, keys(comments)))
  )(selectedAnswersIds);

// Счетчик видимых комментариев блоков Quiz и Survey и Weight на одной странице
export const getCommentsVisibleCount = ({
  allBlocks,
  allPages,
  pageId,
  result,
}) => {
  const blocks = compose(
    flatten,
    map((type) => getBlocksIdsByType(pageId, type, allBlocks, allPages))
  )(['Quiz', 'Survey', 'Weight']);

  return blocks
    .filter((blockId) =>
      getOr([], ['pages', pageId, 'displayedBlocksIds'], result).includes(
        blockId
      )
    )
    .reduce(
      (accumulator, blockId) =>
        getCommentsVisibleBlock(
          get([blockId, 'selectedAnswersIds'], result.blocks),
          pickBy(identity, get([blockId, 'content', 'comments'], allBlocks))
        ) + accumulator,
      0
    );
};

export const clone = (pageId, allPages, allBlocks) => {
  const originalClonedPageIdMapper = { [pageId]: generateId() };
  const getClonedPageId = () => get(pageId, originalClonedPageIdMapper);
  const pageBlocksIds = get([pageId, 'blocksIds'], allPages);
  const originalClonedBlockIdMapper = zipObject(
    pageBlocksIds,
    map(generateId, pageBlocksIds)
  );

  const getClonedBlockId = (blockId) =>
    get(blockId, originalClonedBlockIdMapper);

  const clonedPage = {
    [getClonedPageId()]: compose(
      update('blocksIds', map(getClonedBlockId)),
      get(pageId)
    )(allPages),
  };
  const clonedPageBlocks = mapKeys(
    getClonedBlockId,
    pick(pageBlocksIds, allBlocks)
  );
  return {
    page: clonedPage,
    blocks: clonedPageBlocks,
  };
};
