import { all } from 'redux-saga/effects';

import {
  watchDownloadCourse,
  watchJoinCourse,
  watchRejectCourse,
} from '~/sagas/tutors/courses';
import { watchDownloadTutorLessons } from '~/sagas/tutors/lessons';

export default function*() {
  yield all([
    watchJoinCourse(),
    watchRejectCourse(),
    watchDownloadCourse(),
    watchDownloadTutorLessons(),
  ]);
}
