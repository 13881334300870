import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import logo from 'Custom/logo.png';
import { connect } from 'react-redux';
import { Intent, ProgressBar } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';
import { DragSource, DropTarget } from 'react-dnd';
import { compact, pick, isEmpty, compose, get, indexOf, pickBy } from 'lodash/fp';
import { getContext, lifecycle, withHandlers } from 'recompose';
import NoSSR from 'react-no-ssr';

import withTheming from '~/hocs/withTheming';
import { withDnDImagePreview, withDnDListener } from '../utils/component';
import {
  BlocksTypes,
  Button,
  ChartThermometer,
  CircleProgressBar,
  Controls,
  Icon,
  Input,
  Switch,
  Text,
  Tooltip,
} from '../components';
import { connectDnD, getDropPosition } from '../utils/drag';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as context from '../utils/context';
import * as actions from '../actions';
import * as helpers from '../helpers';
import '../styles/Page.scss';
import {
  RESULT_STATUS_COMPLETE,
  LESSON_TYPE_EXAM,
  LESSON_TYPE_OLYMPIAD,
} from '../appConstants';
import {
  getPageCorrectTasksCount,
  getPageTotalTasksCount,
  getExecutedBlocksCount,
} from '../helpers/player/tasksCount';
import DotLoader from '~/components/DotLoader';
import Blocks from '~/containers/Blocks';

const Page = ({
  t,
  id,
  copy,
  name,
  clone,
  theme,
  remove,
  variant,
  getOrder,
  fontSize,
  inPlayer,
  complete,
  isViewed,
  lessonId,
  inResult,
  hasBlocks,
  reexecute,
  usersData,
  inBuilder,
  printPage,
  isInModal,
  changeName,
  isDragging,
  inExamMode,
  inBirdView,
  inSafeMode,
  isDragOver,
  setShuffle,
  isTestsPage,
  isCompleted,
  inThumbnail,
  testsInPage,
  publication,
  dropPosition,
  inVerifyMode,
  isExamLesson,
  isOlympiadMode,
  toggleExamMode,
  displayedBlocks,
  isShuffleBlocks,
  totalTasksCount,
  isLessonComplete,
  correctTasksCount,
  connectDragSource,
  connectDropTarget,
  executedBlocksCount,
  closeLessonBirdView,
  changeBlocksQuantity,
  commentsVisibleCount,
}) =>
  inPlayer && isInModal ? (
    <div
      id={id}
      className={combineStyles('Page', [
        'modal',
        inExamMode && 'exam-mode',
        !hasBlocks && 'empty',
        inSafeMode && theme,
        process.env.CUSTOM_NAME,
      ])}
    >
      {totalTasksCount > 0 && (
        <div className="progress">
          <ProgressBar
            value={executedBlocksCount / totalTasksCount}
            intent={Intent.SUCCESS}
            stripes={false}
          />
        </div>
      )}
      {inExamMode && (
        <div
          className={combineStyles('label', inSafeMode && [fontSize, theme])}
        >
          <Text
            value={t('page.examPage')}
            variant={
              inSafeMode
                ? ['page-in-exam-mode-label', fontSize, theme]
                : 'page-in-exam-mode-label'
            }
          />
          <div
            className={combineStyles('icon', inSafeMode && [fontSize, theme])}
          >
            <Icon
              name="help"
              tooltip={t('page.examPageHint')}
              variant={
                inSafeMode
                  ? ['page-name-icon-in-exam-mode', fontSize, theme]
                  : 'page-name-icon-in-exam-mode'
              }
            />
          </div>
        </div>
      )}
      <div className={combineStyles('name', inSafeMode && [fontSize, theme])}>
        <img className="logo" src={logo} alt="logo" />
        <div className="text">
          <Text
            value={name}
            variant={[
              'page-name',
              inExamMode && 'exam-mode',
              inSafeMode && fontSize,
              inSafeMode && theme,
            ]}
          />
        </div>
      </div>
      <div className="blocks">
        <Blocks
          pageId={id}
          lessonId={lessonId}
          isInModal={isInModal}
          isLessonComplete={isLessonComplete}
        />
      </div>
      <div
        className={combineStyles('buttons', inSafeMode && [fontSize, theme])}
      >
        {isCompleted && (
          <div
            className={combineStyles('hint', inSafeMode && [fontSize, theme])}
          >
            <div
              className={combineStyles('icon', inSafeMode && [fontSize, theme])}
            >
              <Icon name="lock" />
            </div>
            <div className="text">
              <Text
                value={t('page.answersSaved')}
                variant={
                  inSafeMode ? ['page-hint', fontSize, theme] : 'page-hint'
                }
              />
            </div>
          </div>
        )}
        {inVerifyMode && (
          <div
            className={combineStyles('hint', inSafeMode && [fontSize, theme])}
          >
            <div className="icon">
              <Icon
                name="osh-info"
                variant={inSafeMode ? ['info', fontSize, theme] : 'info'}
              />
            </div>
            <div className="text">
              <Text
                value={`${correctTasksCount} ${t(
                  'page.completedTextOutOf'
                )} ${totalTasksCount} ${t('page.completedText')}`}
                variant={
                  inSafeMode ? ['page-hint', fontSize, theme] : 'page-hint'
                }
              />
            </div>
          </div>
        )}
        {totalTasksCount > 0 && (
          <div className="button">
            {inExamMode && !isOlympiadMode ? (
              isCompleted ? (
                !inVerifyMode && (
                  <Button
                    icon="refresh"
                    text={t('page.changeAnswers')}
                    variant={
                      inSafeMode
                        ? ['page-reexecute-quizes', fontSize, theme]
                        : 'page-reexecute-quizes'
                    }
                    onClick={reexecute}
                  />
                )
              ) : (
                <Button
                  icon="osh-done"
                  text={t('page.completeExam')}
                  variant={
                    inSafeMode
                      ? ['page-complete-quizes', fontSize, theme]
                      : 'page-complete-quizes'
                  }
                  onClick={complete}
                />
              )
            ) : (
              undefined
            )}
          </div>
        )}
      </div>
    </div>
  ) : (inPlayer && inThumbnail) || inResult ? (
    <div
      id={id}
      className={combineStyles('Page', [
        'preview',
        variant,
        inSafeMode && theme,
        inSafeMode && fontSize,
        isViewed && !inResult && 'completed',
        inExamMode && 'exam-mode',
        inResult && 'in-result',
        variant,
        process.env.CUSTOM_NAME,
      ])}
    >
      {!inResult &&
        !!commentsVisibleCount &&
        isLessonComplete &&
        !inExamMode && (
          <div
            className={combineStyles('comments', [
              isViewed && 'viewed',
              inSafeMode && [fontSize, theme],
            ])}
          >
            {commentsVisibleCount}
          </div>
        )}
      {!inResult && (
        <div className="indicator">
          <div className="number">{getOrder(id)}</div>
          {isViewed && !inExamMode && !isOlympiadMode && !isExamLesson && (
            <CircleProgressBar
              total={totalTasksCount}
              value={correctTasksCount}
              variant="in-page-preview"
            />
          )}
        </div>
      )}
      {!inResult && (
        <div className="status">
          <NoSSR onSSR={<DotLoader size="small" />}>
            <Icon
              name={isViewed ? 'eye-on' : 'eye-off'}
              large
              variant={combineStyles(
                `page-preview-status-${isViewed ? 'completed' : 'unviewed'}`,
                inSafeMode && [fontSize, theme]
              )}
            />
            <Text
              value={isViewed ? t('page.seen') : t('page.notSeen')}
              variant={combineStyles(
                `page-preview-status-${isViewed ? 'completed' : 'unviewed'}`,
                inSafeMode && [fontSize, theme]
              )}
            />
          </NoSSR>
        </div>
      )}
      {inExamMode && (
        <div className="label">
          <Text
            value={t('page.examPage')}
            variant={combineStyles(
              'page-in-exam-mode-label',
              inSafeMode && [fontSize, theme]
            )}
          />
          <div
            className={combineStyles('icon', inSafeMode && [fontSize, theme])}
          >
            <Icon
              name="help"
              tooltip={t('page.examPageHint2')}
              variant={
                inSafeMode
                  ? ['page-name-icon-in-exam-mode', fontSize, theme]
                  : 'page-name-icon-in-exam-mode'
              }
            />
          </div>
        </div>
      )}
      <div className="name">
        <Text
          value={name}
          variant={combineStyles(
            'page-preview-name',
            inResult && 'preview-in-result',
            inSafeMode && [fontSize, theme]
          )}
        />
      </div>
      {usersData && (
        <div className="users">
          {usersData.map(
            (item, index) =>
              item.user && (
                <Tooltip
                  key={index}
                  content={item.user}
                  position="top"
                  variant="result-name-label"
                >
                  <div
                    className={combineStyles(
                      'user',
                      item.lastModified
                        ? item.onlineStatus &&
                            (+new Date() - item.lastModified) / 3600000 < 3 &&
                            'online'
                        : ''
                    )}
                  >
                    {/* eslint-disable-next-line unicorn/prefer-string-slice */}
                    {item.user.substring(0, 1)}
                  </div>
                </Tooltip>
              )
          )}
        </div>
      )}
      <div className="blocks">
        <BlocksTypes
          pageId={id}
          variant={variant}
          inResult={inResult}
          inPlayer={inPlayer}
          inBuilder={inBuilder}
          inBirdView={inBuilder && inBirdView}
          inThumbnail={inThumbnail}
          lessonId={lessonId}
        />
      </div>
      {inVerifyMode && isOlympiadMode && totalTasksCount > 0 && (
        <ChartThermometer
          theme={theme}
          variant="small"
          fontSize={fontSize}
          valueMax={totalTasksCount}
          valueCurrent={correctTasksCount}
          inSafeMode={inSafeMode}
          innerResult
        />
      )}
    </div>
  ) : inBuilder && !inResult ? (
    connectDropTarget(
      <div
        id={id}
        className={combineStyles('Page', [
          'constructor',
          inExamMode && 'exam-mode',
          inBirdView && 'bird-view',
          isDragOver && dropPosition && `drag-over-${dropPosition}`,
          isDragging && 'dragging',
          !hasBlocks && 'empty',
          process.env.CUSTOM_NAME,
        ])}
        onClick={() => inBirdView && closeLessonBirdView()}
      >
        <div className="controls">
          {!inBirdView && (
            <Controls
              icon={inExamMode ? 'osh-kontrolnaya enabled' : 'osh-kontrolnaya'}
              type="dropdown"
              usePortal
              items={compact([
                <div key="" className="toggler">
                  <div className="info">
                    <Text
                      value={t('page.makeExam')}
                      variant="page-controls-exam-mode-toggler"
                    />
                    <Icon
                      name="help"
                      tooltip={t('page.makeExamHint')}
                      variant="page-controls-exam-mode-toggler"
                    />
                  </div>
                  <div className="switch exam-mode">
                    <Switch
                      label={t('page.exam')}
                      checked={inExamMode}
                      onChange={toggleExamMode}
                      variant="page-exam"
                    />
                  </div>
                </div>,
              ])}
              variant="page-exam"
              position="bottom"
            />
          )}

          {!inBirdView && (
            <Controls
              icon={
                isTestsPage
                  ? isShuffleBlocks
                    ? 'osh-random randomized'
                    : 'osh-random'
                  : 'osh-random-disabled'
              }
              type="dropdown"
              usePortal
              items={compact([
                !isTestsPage && (
                  <div className="warning">
                    <Icon name="info-sign" variant="page-random-warning" />
                    <Text
                      value={t('page.randomWarning')}
                      variant="page-random-warning"
                    />
                  </div>
                ),
                <React.Fragment key="item">
                  <div className="switch toggler">
                    <Switch
                      label={t('page.randomization')}
                      checked={isShuffleBlocks}
                      disabled={!isTestsPage}
                      onChange={() => setShuffle(!isShuffleBlocks)}
                      variant="page-random"
                    />
                    <div className="icon">
                      <Icon
                        name="help"
                        variant="small-gray"
                        tooltip={t('page.randomHint')}
                      />
                    </div>
                  </div>
                  {isShuffleBlocks && (
                    <div className="quantity toggler">
                      <div className="icon">
                        <Icon
                          name="help"
                          variant="small-gray"
                          tooltip={t('page.randomQuantityHint')}
                        />
                      </div>
                      <Text
                        value={t('page.randomQuantity')}
                        variant="shuffle-answers"
                      />
                      <Input
                        type="numeric"
                        value={displayedBlocks}
                        minValue={1}
                        disabled={testsInPage.length <= 1}
                        maxValue={
                          testsInPage.length > 1
                            ? testsInPage.length
                            : undefined
                        }
                        variant="page-quantity"
                        onChange={changeBlocksQuantity}
                      />
                    </div>
                  )}
                </React.Fragment>,
              ])}
              variant="page-random"
              position="bottom"
            />
          )}

          {connectDragSource(
            <div
              className="control"
              onClick={(event) => event.stopPropagation()}
            >
              <Controls
                type="dropdown"
                usePortal
                items={[
                  {
                    icon: 'osh-duplicate',
                    text: t('page.dublicate'),
                    action: clone,
                  },
                  {
                    icon: 'osh-copy',
                    text: t('page.copy'),
                    action: copy,
                  },
                  {
                    icon: 'osh-trash',
                    text: t('page.remove'),
                    action: remove,
                  },
                  {
                    icon: 'osh-print',
                    text: t('page.print'),
                    action: () => printPage(id, lessonId, publication),
                  },
                ]}
                variant={inBirdView ? 'block' : 'page'}
                position={inBirdView ? 'bottom' : 'rightTop'}
              />
            </div>
          )}
        </div>
        {inExamMode && (
          <div className="label">
            <Text
              value={t('page.examPage')}
              variant="page-in-exam-mode-label"
            />
            {!inBirdView && (
              <div className="icon">
                <Icon
                  name="help"
                  tooltip={t('page.examPageHint3')}
                  variant="page-name-icon-in-exam-mode"
                />
              </div>
            )}
          </div>
        )}
        <div className="name">
          <img className="logo" src={logo} alt="logo" />
          <div className="text">
            <Text
              value={name || ''}
              variant={[
                'page-name',
                inExamMode && 'exam-mode',
                inBirdView && 'bird-view',
              ]}
              editable
              multiline
              onChange={changeName}
              placeholder={t('page.newPage')}
            />
          </div>
        </div>
        <div className="blocks">
          <Blocks pageId={id} lessonId={lessonId} />
        </div>
        {inBirdView && (
          <BlocksTypes
            pageId={id}
            variant={variant}
            inResult={inResult}
            inPlayer={inPlayer}
            inBuilder={inBuilder}
            inBirdView={inBuilder && inBirdView}
            inThumbnail={inThumbnail}
          />
        )}
      </div>
    )
  ) : (
    undefined
  );

const { string, bool, oneOf, array, number, func } = PropTypes;

Page.propTypes = {
  t: func, // Функция перевода
  id: string.isRequired, // ID страницы
  name: string, // Имя страницы
  theme: string, // Цветовая тема в режиме ОВЗ
  clone: func.isRequired, // Клонирование страницы
  copy: func.isRequired, // Копирование страницы
  remove: func.isRequired, // Удаление страницы
  variant: string, // Вариант оформления
  lessonId: string.isRequired, // ID урока
  fontSize: string, // Размер шрифта в режиме ОВЗ
  complete: func.isRequired, // Завершить выполнение заданий на странице
  inResult: bool,
  isViewed: bool, // Страница в плеере просмотрена?
  inPlayer: bool, // В плеере?
  getOrder: func.isRequired, // Получение порядкового номера страницы
  usersData: array, // Данные о пользователях
  isInModal: bool, // Режим просмотра страницы в плеере
  inBuilder: bool, // В конструкторе?
  hasBlocks: bool.isRequired, // На странице есть блоки?
  reexecute: func.isRequired, // Повторно выполнить задания на странице
  printPage: func.isRequired, // Печать страницы
  isDragOver: bool.isRequired, // Над страницей что-то перетягивается?
  isDragging: bool.isRequired, // Страница перетягивается?
  inExamMode: bool, // Страница контрольная?
  changeName: func.isRequired, // Переименование заголовка страницы
  inBirdView: bool, // В режиме бёрдвью?
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
  publication: number,
  isTestsPage: bool.isRequired, // Страница только с блоками Quiz?
  isCompleted: bool, // Задания на странице пройдены?
  inThumbnail: bool, // В режиме предпросмотра страницы в плеере?
  testsInPage: array, // Количество блоков тестирования на странице
  inVerifyMode: bool, // Задания на странице в режиме проверки?
  isExamLesson: bool,
  dropPosition: oneOf(['bottom', 'top']), // Позиция вставки перетаскиваемой страницы
  setShuffle: func, // Переключение режима рандомизации вопросов
  toggleExamMode: func.isRequired, // Переключить режим контроля для страницы
  isOlympiadMode: bool, // В режиме олимпиады?
  displayedBlocks: number, // Количество отображаемых блоков на странице при рандомизации
  totalTasksCount: number.isRequired, // Количество заданий на странице
  isShuffleBlocks: bool, // Режим рандомизации включен?
  isLessonComplete: bool, // Урок пройден?
  executedBlocksCount: number, // Количество пройденных заданий
  correctTasksCount: number.isRequired, // Количество правильно выполненных заданий
  connectDragSource: func.isRequired, // Подключить источник DnD
  connectDropTarget: func.isRequired, // Подключить приемник DnD
  closeLessonBirdView: func.isRequired, // Закрыть режим бёрдвью
  changeBlocksQuantity: func, // Изменить количество отображаемых блоков при рандомизации
  commentsVisibleCount: number, // Количество видимых комментариев на страинце
};

Page.defaultProps = {
  t: func.isRequired,
  theme: 'light',
  inResult: false,
  fontSize: 'small',
  testsInPage: [],
  isOlympiadMode: false,
  isShuffleBlocks: false,
};

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    inResult,
    inPlayer,
    lessonId,
    usersData,
    inBuilder,
    isInModal,
    connectDropTarget,
    connectDragSource,
  } = ownProps;

  const contexts = {
    inPlayer,
    inBuilder: inResult || inBuilder,
  };
  const order = get(['player', 'lesson', 'content', 'pagesIds'], state);
  const allPages = context.getPages(contexts)(state);
  
  // Фильтрация блоков для опросника
  const currentResult = inPlayer && state.player.result;
  const selectedBlocksIds = currentResult ? currentResult.selectedBlocksIds : []
  const allBlocks = isEmpty(selectedBlocksIds)
    ? context.getBlocks(contexts)(state)
    : pick(selectedBlocksIds, context.getBlocks(contexts)(state));

  const page = get(id, allPages) || {};

  const totalTasksCount = getPageTotalTasksCount(page, selectedBlocksIds, state);
  const correctTasksCount = getPageCorrectTasksCount(page, selectedBlocksIds, state);

  const hasBlocks = helpers.page.hasBlocks(id, allPages);
  const inBirdView = selectors.builder.lesson.inBirdView(lessonId, state);
  const isViewed = selectors.player.isPageViewed(id, state);

  const lessonType = selectors.player.getLessonType(state);
  const isOlympiadMode = lessonType === LESSON_TYPE_OLYMPIAD;
  const isExamLesson = lessonType === LESSON_TYPE_EXAM;

  // ? isCompleted -- используется?
  const isCompleted = inPlayer
    ? context.isPageCompleted(contexts)(id, state)
    : undefined;

  const inVerifyMode = inPlayer
    ? context.isPageInVerifyMode(contexts)(id, state)
    : undefined;

  const { name, inExamMode, isShuffleBlocks, displayedBlocks } = page;

  const testsInPage = helpers.page.getBlocksIdsByTypes(
    id,
    ['Quiz', 'Exam', 'Match', 'Weight', 'FillBlank', 'Answer', 'Survey'],
    allBlocks,
    allPages
  );

  const isTestsPage = helpers.page.checkQuizPage(
    id,
    ['Quiz', 'Exam', 'Match', 'Weight', 'FillBlank', 'Answer', 'Survey'],
    allBlocks,
    allPages
  );

  const blocksOnPage = pickBy((block, blockId) => (page.blocksIds || []).includes(blockId), allBlocks)
  const executedBlocksCount = getExecutedBlocksCount(state, blocksOnPage);
  const isLessonComplete =
    get('status', currentResult) === RESULT_STATUS_COMPLETE;
  const commentsVisibleCount =
    currentResult &&
    helpers.page.getCommentsVisibleCount({
      allBlocks,
      allPages,
      pageId: id,
      result: currentResult,
    });

  return {
    name,
    page,
    order,
    isViewed: isViewed || ownProps.isViewed,
    lessonId,
    hasBlocks,
    isInModal,
    usersData,
    inBirdView,
    inExamMode,
    isTestsPage,
    testsInPage,
    isCompleted,
    isExamLesson,
    inVerifyMode,
    currentResult,
    isOlympiadMode,
    displayedBlocks,
    totalTasksCount,
    isShuffleBlocks,
    isLessonComplete,
    correctTasksCount,
    connectDropTarget: connectDnD(inBirdView, connectDropTarget),
    connectDragSource: connectDnD(inBirdView, connectDragSource),
    executedBlocksCount,
    commentsVisibleCount,
  };
};

const mapDispatchToProps = {
  copy: actions.clypboard.copyPage,
  clone: actions.builder.page.clone,
  remove: actions.builder.page.remove,
  complete: actions.player.page.complete,
  reexecute: actions.player.page.reexecute,
  changeName: actions.builder.page.changeName,
  toggleExamMode: actions.builder.page.toggleExamMode,
  toggleLessonBirdView: actions.builder.lesson.toggleBirdView,

  enableShuffle: actions.builder.page.enableShuffle,
  disableShuffle: actions.builder.page.disableShuffle,
  // saveShuffleAnswers: actions.player.page.saveShuffleAnswers,
  changeBlocksQuantity: actions.builder.page.changeBlocksQuantity,
};

const scrollToPage = (pageId) =>
  setTimeout(() => document.getElementById(pageId).scrollIntoView(), 0);

const handlers = withHandlers({
  copy: (props) => () => props.copy(props.id, props.lessonId),
  clone: (props) => () => props.clone(props.id, props.lessonId),
  remove: (props) => () => props.remove(props.id, props.lessonId),
  getOrder: ({ order }) => (pageId) => indexOf(pageId, order) + 1,
  complete: (props) => () => props.complete(props.id),
  reexecute: (props) => () => props.reexecute(props.id),
  changeName: (props) => (newName) => props.changeName(props.id, newName),
  toggleExamMode: (props) => () => props.toggleExamMode(props.id),
  closeLessonBirdView: (props) => () => {
    props.toggleLessonBirdView(props.lessonId);
    scrollToPage(props.id);
  },
  setShuffle: ({ enableShuffle, disableShuffle, testsInPage, id }) => (
    isShuffleBlocks
  ) =>
    isShuffleBlocks
      ? enableShuffle({ id, displayedBlocks: testsInPage.length })
      : disableShuffle({ id }),
  changeBlocksQuantity: (props) => (count) =>
    props.changeBlocksQuantity(count, props.id),
});

const handlersEnhance = withHandlers({
  printPage: () => (id, lessonId, publication) => {
    window.open(
      `${process.env.BASENAME}player/print/${lessonId}/${publication}/${id}`,
      '_blank'
    );
  },
});

const stateEnhance = getContext({
  inPlayer: bool,
  inBuilder: bool,
});

const collectDropTarget = (dndConnect, monitor) => ({
  isDragOver: monitor.isOver({ shallow: true }),
  connectDropTarget: dndConnect.dropTarget(),
});

const collectDragSource = (dndConnect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: dndConnect.dragSource(),
  connectDragPreview: dndConnect.dragPreview(),
});

const dragSource = {
  beginDrag: (props) => {
    const { id, index, lessonId, onDragEnd } = props;
    return {
      id,
      index,
      lessonId,
      onDragEnd,
    };
  },
};

export const dropTarget = {
  hover(props, monitor, component) {
    const dropPosition = getDropPosition(monitor, component);
    if (monitor.getItem().dropPosition !== dropPosition) {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      monitor.getItem().dropPosition = dropPosition;
      component.setState({ dropPosition });
    }
  },
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      case 'page': {
        const { id, dropPosition, onDragEnd } = monitor.getItem();
        const {
          id: sourceId,
          index: sourceIndex,
          lessonId: sourceLessonId,
        } = monitor.getItem();
        const { id: receiverId, index: receiverIndex } = props;
        const oldIndex = sourceIndex;
        const newIndex =
          sourceId === receiverId
            ? receiverIndex
            : receiverIndex + { top: 0, bottom: 1 }[dropPosition];
        onDragEnd(id, sourceLessonId, oldIndex, newIndex);
        break;
      }
      case 'sidebar-page': {
        const { onDragEnd, dropPosition } = monitor.getItem();
        const { index, lessonId } = props;
        onDragEnd(lessonId, index + { top: 0, bottom: 1 }[dropPosition]);
        break;
      }
      default:
        break;
    }
  },
};

export default compose(
  withTranslation('containers'),
  stateEnhance,
  DragSource('page', dragSource, collectDragSource),
  DropTarget(['page', 'sidebar-page'], dropTarget, collectDropTarget),
  withDnDListener('dropPosition'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTheming,
  withDnDImagePreview,
  handlers,
  handlersEnhance,
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidUpdate() {
      const {
        isShuffleBlocks,
        isTestsPage,
        setShuffle,
        displayedBlocks,
        testsInPage,
        changeBlocksQuantity,
      } = this.props;

      if (isShuffleBlocks && !isTestsPage) {
        setShuffle(false);
      }

      if (displayedBlocks && testsInPage.length < displayedBlocks) {
        changeBlocksQuantity(testsInPage.length);
      }
    },
    /* eslint-enable */
  })
)(Page);
