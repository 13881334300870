import React from 'react';
import memoize from 'fast-memoize';
import PropTypes from 'prop-types';
import { keys, reverse } from 'lodash/fp';
import withMobileDetect from '~/hocs/withMobileDetect';
import { ResultThumbnail } from '../components';
import { combineStyles } from '../utils/styles';
import '../styles/UserResults.scss';

const renderResultThumbnail = memoize(
  (
    resultId,
    resultName,
    passedAt,
    lessonId,
    messages,
    publication,
    isLessonComplete,
    externalLink,
    router
  ) => (
    <ResultThumbnail
      key={resultId}
      id={resultId}
      router={router}
      name={resultName}
      messages={messages}
      lessonId={lessonId}
      passedAt={passedAt}
      publication={publication}
      externalLink={externalLink}
      isLessonComplete={isLessonComplete}
    />
  )
);

const UserResults = ({ allResults, router, isMobile }) => (
  <div className={combineStyles('UserResults', isMobile && 'mobile')}>
    <div className="results-list">
      {reverse(keys(allResults)).map((resultId) =>
        !allResults[resultId].isLtiLesson
          ? renderResultThumbnail(
              resultId,
              allResults[resultId].lessonName,
              allResults[resultId].passedAt,
              allResults[resultId].lessonId,
              allResults[resultId].comments || [],
              allResults[resultId].publication,
              allResults[resultId].isLessonComplete,
              allResults[resultId].lessonSettings &&
                allResults[resultId].lessonSettings.externalLink,
              router
            )
          : undefined
      )}
    </div>
  </div>
);

const { object, bool } = PropTypes;

UserResults.propTypes = {
  router: object.isRequired, // Роутер
  isMobile: bool.isRequired,
  allResults: object.isRequired, // Список всех прохождений
};

export default withMobileDetect(UserResults);
