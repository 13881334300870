import styled, { css } from 'styled-components';

export const Row = styled.div`
  border-bottom: solid 1px #e5e9ec;

  :first-child {
    border-top: solid 1px #e5e9ec;
  }
`;

export const RowContent = styled.div`
  transition: height 0.4s ease;
  height: ${(p) => (p.opened ? 'auto' : 0)};
  overflow: hidden;
`;

export const body = css`
  width: 100%;
`;

export const uploader = css`
  margin-top: -16px;
`;

export const imageContainer = css`
  padding: 24px 0;
`;

export const addIntervalHint = css`
  margin: 16px 0;
  font-family: CirceRounded;
  font-size: 14px;
  color: #b2b6b8;
`;

export const addNewHintIcon = css`
  margin-left: 10px;
  margin-top: -4px;
`;

export const img = css`
  max-width: 100%;
  max-height: 177px;
  height: auto;
`;

export const imageHint = css`
  margin-top: 11px;
`;

export const rowTitle = css`
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const rowTitleText = css`
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  color: #45494e;
`;

export const score = css`
  font-family: CirceRounded;
  font-size: 14px;
  color: #b2b6b8;
  margin-right: auto;
`;

export const icon = css`
  color: #b2b6b8;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;

  :hover {
    background-color: #f4f6f7;
  }

  transition: transform 0.2s ease;

  ${(p) =>
    p.up &&
    css`
      transform: rotate(180deg);
    `}
`;

export const removeIcon = css`
  ${icon}
  padding-top: 4px;
  font-size: 21px;
`;

export const interval = css`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;

export const input = css`
  .bp3-input {
    border: solid 1px #d0d3d7;
    font-family: CirceRounded;
    font-size: 14px !important;
    color: #45494e;
    padding: 0 10px !important;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const froalaInput = css`
  .editor {
    border: solid 1px #d0d3d7;
    font-family: CirceRounded;
    font-size: 14px !important;
    color: #45494e;
    padding: 15px 10px 5px !important;
    min-height: 50px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 3px;
    background: #fff;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    a {
      font-family: CirceRounded;
      font-size: 14px !important;
    }
  }
`;

export const inputLittle = css`
  ${input};
  .bp3-input {
    height: 34px !important;
    text-align: center;
  }
`;

export const subtitle = css`
  font-family: CirceRounded;
  font-size: 16px;
  color: #45494e;
  margin-bottom: 8px;
  margin-top: 18px;
`;

export const addIntervalButton = css`
  margin-top: 16px;
  height: 29px;
  display: flex;
  align-items: center;
  font-family: CirceRounded;
  font-size: 16px;
  color: #767b84;
  cursor: pointer;
`;

export const addNewLittle = css`
  font-size: 12px;
  margin-right: 8px;
`;

export const divider = css`
  margin: 0 16px;
  font-family: CirceRounded;
  font-size: 16px;
  text-align: center;
  color: #45494e;
`;
