export const ADD = '@builder/course/Создание курса';
export const SAVE = '@builder/course/Сохранение курса';
export const SAVE_ERROR = '@builder/course/Не удалось сохранить курс';
export const CHANGE_NAME = '@builder/course/Переименование курса';
export const TOGGLE_PUBLIC_MODE =
  '@builder/course/Переключение режима открытого урока';
export const SAVE_SUCCESS = '@builder/course/Курс успешно сохранен';
export const CHANGE_DESCRIPTION = '@builder/course/Изменение описания курса';
export const SET_STATUS = '@builder/course/Установить статус курса';
export const SET_STATUS_SUCCESS =
  '@builder/course/Установлен статус курса успешно';
export const SET_STATUS_ERROR =
  '@builder/course/Ошибка установки статуса курса';

export const REMOVE_ERROR = '@builder/course/Не удалось удалить курс';
export const REMOVE_ACCEPT = '@builder/course/Подтверждение удаления курса';
export const REMOVE_CANCEL = '@builder/course/Отмена удаления курса';
export const REMOVE_SUCCESS = '@builder/course/Курс успешно удален';
export const REMOVE_CONFIRM = '@builder/course/Запрос на удаление курса';

export const REMOVE_STUDENT = '@builder/course/Удаление пользователя из курса';
export const REMOVE_STUDENT_CANCEL =
  '@builder/course/Отмена удаления пользователя из курса';
export const REMOVE_STUDENT_SUCCESS =
  '@builder/course/Удаление пользователя из курса прошло успешно';

export const REMOVE_TUTOR = '@builder/course/Удаление тьютора из курса';
export const REMOVE_TUTOR_SUCCESS =
  '@builder/course/Удаление тьютора из курса прошло успешно';

export const SEND_STUDENTS_INVITES =
  '@builder/course/Отправка приглашений пользователям';
export const SEND_STUDENTS_INVITES_ERROR =
  '@builder/course/Ошибка отправки приглашений пользователям';
export const SEND_STUDENTS_INVITES_SUCCESS =
  '@builder/course/Приглашения пользователям успешно отправлены';

export const SEND_TUTORS_INVITES =
  '@builder/course/Отправка приглашений тьюторам';
export const SEND_TUTORS_INVITES_ERROR =
  '@builder/course/Ошибка отправки приглашений тьюторам';
export const SEND_TUTORS_INVITES_SUCCESS =
  '@builder/course/Приглашения тьюторам успешно отправлены';

export const DOWNLOAD_STUDENTS =
  '@builder/course/Загрузка список приглашенных учеников';
export const DOWNLOAD_STUDENTS_ERROR =
  '@builder/course/Ошибка загрузки списка приглашенных учеников';
export const DOWNLOAD_STUDENTS_SUCCESS =
  '@builder/course/Список приглашенных учеников успешно загружен';

export const MOVE_LESSON = '@builder/course/Переместить урок на новую позицию';
