import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withHandlers } from 'recompose';
import { compose, filter } from 'lodash/fp';
import withMobileDetect from '~/hocs/withMobileDetect';
import { CourseThumbnail, ProgressBar } from '~/components';
import DotLoader from '~/components/DotLoader';
import { combineStyles } from '~/utils/styles';
import * as selectors from '~/selectors';
import { isRequestActive } from '~/selectors/ui';
import { TCourse } from '~/@types';
import {
  REQUEST_NAME_COURSE_DOWNLOADING,
  RESULT_STATUS_COMPLETE,
} from '~/appConstants';
import '~/styles/screens/StudyCourses.scss';

const ScreenStudyCourses = ({ isMobile, allCourses, openCourse }) => {
  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_COURSE_DOWNLOADING)
  );

  return isLoading ? (
    <DotLoader className="center" />
  ) : (
    <div className={combineStyles('ScreenStudyCourses', isMobile && 'mobile')}>
      <div className="items">
        {allCourses.map((course) => {
          const { id, publicationsIds = [], results = [] } = course;
          const completedResults = filter({ status: RESULT_STATUS_COMPLETE })(
            results
          );
          const result = completedResults.length / publicationsIds.length;
          return (
            <div key={course.id} className="item">
              <CourseThumbnail course={course} onClick={() => openCourse(id)}>
                <div className="CourseProgress">
                  <div className="text">
                    {completedResults.length}
                    <span className="lighter">/{publicationsIds.length}</span>
                  </div>
                  <ProgressBar width={378} value={result} />
                </div>
              </CourseThumbnail>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const { func, arrayOf, bool } = PropTypes;

ScreenStudyCourses.propTypes = {
  isMobile: bool.isRequired,
  openCourse: func.isRequired, // Добавить курс
  allCourses: arrayOf(TCourse), // Список курсов
};

const mapStateToProps = (state) => ({
  allCourses: selectors.study.getSortedCourses(state),
});

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  connect(mapStateToProps),
  withHandlers({
    openCourse: ({ history }) => (id) => history.push(`/player/course/${id}`),
  })
)(ScreenStudyCourses);
