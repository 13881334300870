import React from 'react';
import bowser from 'bowser';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { DefaultButton as Button } from './Button';
import { isTouchDevice } from '../utils/drag';
import Icon from '~/components/Icon';
import DraggableTag from '~/components/DraggableTag';

const inIE = bowser.msie || bowser.msedge;
const notInIE = !bowser.msie && !bowser.msedge;

const getStyles = (currentOffset) => {
  if (currentOffset) {
    const { x, y } = currentOffset;
    const transform = `translate3d(${x}px, ${y}px, 0)`;
    return { transform, WebkitTransform: transform };
  } else {
    return { display: 'none' };
  }
};

const getComponent = (item, itemType) => {
  switch (itemType) {
    case 'sidebar-page':
    case 'sidebar-block': {
      const {
        dragData: { icon, name },
      } = item;
      return notInIE && <Button icon={icon} text={name} variant={itemType} />;
    }
    case 'page':
    case 'page-block': {
      return (
        inIE && (
          <div
            style={{
              width: 250,
              height: 250,
              border: 'solid 1px #e7e7e7',
              display: 'flex',
              opacity: 0.75,
              boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.09)',
              transform: 'translate(-100%, 0%)',
              alignItems: 'center',
              background: 'white',
              justifyContent: 'center',
            }}
          >
            <Icon name="osh-block-page" size={60} />
          </div>
        )
      );
    }
    case 'tag': {
      return (
        isTouchDevice && (
          <div
            style={{
              position: 'relative',
              cursor: 'move',
              height: 'initial',
              width: 100,
              zIndex: 12,
              maxWidth: 'none',
              boxShadow: '0 0 1px 0 rgba(59, 59, 59, 0.24)',
              lineHeight: 'initial',
              marginRight: '5px',
              borderRadius: '71px',
              backgroundImage: 'none',
              marginBottom: '7px',
            }}
          >
            <DraggableTag variant={item.variant} value={item.answer} />
          </div>
        )
      );
    }
    default:
      return null;
  }
};

const CustomDragLayer = ({ item, itemType, isDragging, currentOffset }) =>
  isDragging && (
    <div
      style={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
        cursor: '-webkit-grabbing',
        position: 'fixed',
        pointerEvents: 'none',
      }}
    >
      <div style={getStyles(currentOffset)}>{getComponent(item, itemType)}</div>
    </div>
  );

const { object, string, shape, number, bool } = PropTypes;

CustomDragLayer.propTypes = {
  item: object,
  itemType: string,
  currentOffset: shape({
    x: number.isRequired,
    y: number.isRequired,
  }),
  isDragging: bool.isRequired,
};

const collect = (monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  currentOffset: monitor.getSourceClientOffset(),
});

export default DragLayer(collect)(CustomDragLayer);
