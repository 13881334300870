import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, get, map, find, compact } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withProps } from 'recompose';
import { useHistory } from 'react-router-dom';

import withMobileDetect from '~/hocs/withMobileDetect';

import * as s from '../courses/styles/Course.styles';

import { Button, Icon, Link, Text, Help } from '~/components';
import { COURSE_STATUS_ACTIVE, TUTOR_STATUS_INVITED } from '~/appConstants';
import * as selectors from '~/selectors';
import { combineStyles } from '~/utils/styles';
import '~/styles/Player/Course.scss';
import DotLoader from '~/components/DotLoader';
import { downloadCourse, joinCourse, rejectCourse } from '~/actions/tutors';
import CourseLessonThumbnail from './CourseLessonThumbnail';

const Course = ({ t, courseId, isMobile }) => {
  const router = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadCourse({ courseId }));
  }, [courseId, dispatch]);

  const course = useSelector(selectors.tutors.getCourse(courseId));
  const coverImageUrl = get(['cover', 'source'], course);
  const lessons = compose(
    compact,
    map((id) => get(['lessons', id], course)),
    get('lessonsIds')
  )(course);

  // TODO: заменить на request
  const isUploaded = !!course && courseId === course.id;
  const { name, status, description } = course || {};
  const isCourseActive = status === COURSE_STATUS_ACTIVE;

  const tutorStatus = get(['tutorInvite', 'status'], course);

  return (
    <div className={combineStyles('PlayerCourse', isMobile && 'mobile')}>
      <div
        className="info"
        style={
          coverImageUrl && {
            backgroundImage: `
              linear-gradient(
                to bottom,
                rgba(69, 73, 78, 0.55) 0%,
                rgba(69, 73, 78, 0.9) 100%
              ),
              url("${coverImageUrl}")
            `,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }
        }
      >
        {isUploaded && (
          <div className="center">
            <div className={combineStyles('status', status)}>
              <Link
                css={s.backLink}
                variant="course-back-teacher"
                withIcon="arrow-left"
                to="/teach/courses"
              />
              <div
                className={combineStyles('icon', isCourseActive && 'active')}
              >
                <Icon name={`osh-course-${status}`} />
              </div>
              <div className="text">
                <Text value={t(`course.status_${status}`)} />
              </div>
            </div>
            <Text value={name} variant="course-name" />
            <Text value={description} variant="course-description" />
          </div>
        )}
      </div>
      <div className="content">
        {isUploaded ? (
          <div className="lessons-list">
            {lessons.map((lesson, index) => {
              const publication = compose(
                find({ lessonId: lesson.id }),
                get('publications')
              )(course);

              return (
                <CourseLessonThumbnail
                  key={lesson.id}
                  index={index}
                  lesson={lesson}
                  publication={publication}
                />
              );
            })}
          </div>
        ) : (
          <DotLoader css={s.loader} />
        )}
      </div>
      <div className="panel">
        <div className="section">
          <Button
            minimal
            icon="person"
            text={t('course.students')}
            variant="lesson-controls"
            onClick={() => router.push(`/teach/course/${course.id}/students?type=tutor`)}
          />
        </div>
        <div className="section">
          <Button
            minimal
            icon="horizontal-bar-chart"
            text={t('course.monitoring')}
            variant="lesson-controls"
            onClick={() => router.push(`/teach/course/${course.id}/monitoring?type=tutor`)}
          />
        </div>
        <div className="section">
          <Help inPanel disabledTooltip variant="help-in-course-panel" />
        </div>
      </div>
      {tutorStatus === TUTOR_STATUS_INVITED && (
        <div className="footer">
          <Button
            css={s.buttonJoin}
            minimal
            text={t('course.joinTutor')}
            variant="join-course"
            onClick={() => dispatch(joinCourse({ courseId }))}
          />
          <Button
            css={s.buttonReject}
            minimal
            text={t('course.rejectTutor')}
            variant="join-course"
            onClick={() => dispatch(rejectCourse({ courseId }))}
          />
        </div>
      )}
    </div>
  );
};

const { string, func, bool } = PropTypes;

Course.propTypes = {
  t: func.isRequired,
  courseId: string.isRequired,
  isMobile: bool.isRequired,
};

export default compose(
  withTranslation('containersPlayer'),
  withProps(({ match }) => ({
    courseId: match.params.id,
  })),
  withMobileDetect
)(Course);
