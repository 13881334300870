import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { withTranslation } from 'react-i18next';
import { lifecycle, withProps } from 'recompose';
import { Route, Switch } from 'react-router-dom';

import { Header } from '~/components';

import * as actions from '~/actions';
import ScreenCourse from './CourseIndex';
import ScreenCourseMonitoring from './CourseMonitoring';
import ScreenCourseStudents from './CourseStudents';
import ScreenCourseTutors from './CourseTutors';

const Course = ({ id, t }) => {
  const query = new URLSearchParams(useLocation().search)
  const type = query.get('type')
  return (
    <>
      <Route
        path="/teach/course/:id/:type(monitoring|students)"
        render={() => (
          <Header
            backLink={`/${type === 'tutor' ? 'tutor' : 'teach'}/course/${id}`}
            items={[
              {
                text: t('courseHeader.students'),
                link: `/teach/course/${id}/students${type === 'tutor' ? '?type=tutor' : ''}`,
              },
              {
                text: t('courseHeader.monitoring'),
                link: `/teach/course/${id}/monitoring${type === 'tutor' ? '?type=tutor' : ''}`,
              },
            ]}
          />
        )}
      />
      <Route
        path="/teach/course/:id/:type(tutors)"
        render={() => (
          <Header
            backLink={`/teach/course/${id}`}
            items={[
              {
                text: t('courseHeader.tutors'),
                link: `/teach/course/${id}/tutors`,
              },
            ]}
          />
        )}
      />
      <Switch>
        <Route
          path="/teach/course/:id/monitoring"
          component={ScreenCourseMonitoring}
        />
        <Route
          path="/teach/course/:id/students"
          component={ScreenCourseStudents}
        />
        <Route path="/teach/course/:id/tutors" component={ScreenCourseTutors} />
        <Route path="/teach/course/:id" exact component={ScreenCourse} />
      </Switch>
    </>
  );
}

const { string, func } = PropTypes;

Course.propTypes = {
  t: func.isRequired,
  id: string.isRequired,
};

const mapDispatchToProps = {
  downloadCourse: actions.builder.downloadCourse,
};

export default compose(
  withTranslation('screens'),
  connect(
    null,
    mapDispatchToProps
  ),
  withProps((props) => ({
    id: props.match.params.id,
  })),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      const { downloadCourse, match } = this.props;
      downloadCourse(match.params.id);
    },
  })
)(Course);
