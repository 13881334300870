import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'lodash/fp';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withPropsOnChange,
  withHandlers,
} from 'recompose';
import * as Blueprint from '@blueprintjs/core';
import withMobileDetect from '~/hocs/withMobileDetect';

import { combineStyles } from '../utils/styles';
import '../styles/Text.scss';

const createClassName = ({ variant, className, isMobile }) =>
  combineStyles(['Text', className], [isMobile && 'mobile', variant]);

const Text = ({
  value,
  variant,
  editable,
  minWidth,
  disabled,
  onChange,
  maxLines,
  isMobile,
  onConfirm,
  multiline,
  className,
  placeholder,
  confirmOnEnterKey,
  setInternalValue,
  internalValue,
}) =>
  editable ? ( // Режим редактирования
    <div className={createClassName({ className, variant, isMobile })}>
      <Blueprint.EditableText
        minWidth={minWidth}
        disabled={disabled}
        maxLines={maxLines}
        confirmOnEnterKey={confirmOnEnterKey}
        multiline={multiline}
        onConfirm={onConfirm || onChange}
        onChange={setInternalValue}
        placeholder={placeholder || internalValue}
        value={internalValue}
      />
    </div>
  ) : (
    // Режим просмотра
    <div className={createClassName({ className, variant, isMobile })}>
      {value}
    </div>
  );

const { bool, array, func, number, string, oneOfType } = PropTypes;

Text.propTypes = {
  value: oneOfType([string, number]), // Текст
  internalValue: oneOfType([string, number]), // Текст
  variant: oneOfType([array, string]), // Вариант оформления
  maxLines: number, // Максимальное количество строк ДО скролла
  editable: bool, // Компонент в режиме редактирования?
  minWidth: number, // Минимальная ширина поля в режиме редактирования
  disabled: bool, // Отключен?
  isMobile: bool.isRequired,
  onChange: func, // Вызывается при изменении текста
  setInternalValue: func,
  onConfirm: func, // Вызывается при потере фокуса или при нажатии enter
  multiline: bool, // Текст в несколько строк в режиме редактирования
  placeholder: string, // Плейсхолдер в режиме редактирования
  className: string,
  confirmOnEnterKey: bool, // Запускает onConfirm и переходит на новую строку при нажатии enter в multiline mode?
};

Text.defaultProps = {
  value: '',
  placeholder: '',
};

export default compose(
  withState('internalValue', 'setInternalValue', ''),
  withMobileDetect,
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      this.props.setInternalValue(this.props.value);
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        this.props.setInternalValue(nextProps.value);
      }
    },
    /* eslint-enable */
  }),
  withPropsOnChange([], ({ onChange = () => {}, debounceTime = 500 }) => ({
    debouncedOnChange: debounce(debounceTime, onChange),
  })),
  withHandlers({
    setInternalValue: ({ debouncedOnChange, setInternalValue }) => (value) => {
      debouncedOnChange(value);
      setInternalValue(value);
    },
  }),
  pure
)(Text);
