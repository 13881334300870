import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Overlay.scss';

const Overlay = ({ isOpen, variant, onClose, children }) => (
  <div className={combineStyles('Overlay', variant)}>
    <Blueprint.Overlay
      isOpen={isOpen}
      onClose={onClose}
      className={`Overlay ${Blueprint.Classes.OVERLAY_SCROLL_CONTAINER}`}
    >
      {children}
    </Blueprint.Overlay>
  </div>
);

const { bool, oneOfType, any, func, array, string } = PropTypes;

Overlay.propTypes = {
  isOpen: bool, // Открыт?
  variant: oneOfType([array, string]), // Вариант оформления
  onClose: func, // Действие при закрытии
  children: any.isRequired, // Внутренний контент
};

Overlay.defaultProps = {
  isOpen: false,
  onClose: undefined,
  variant: undefined,
};

export default pure(Overlay);
