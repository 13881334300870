import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { keys, includes, isEmpty } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import {
  BLOCK_PASS_STATUS_SAVED,
  BLOCK_PASS_STATUS_NOT_PASSED,
  BLOCK_PASS_STATUS_IN_PROCESS,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import { combineStyles } from '../../../utils/styles';
import { Text } from '../..';
import '../../../styles/Status.scss';
import * as helpers from '../../../helpers';

const quizStatus = (t) => ({
  saved: t(`status.${BLOCK_PASS_STATUS_SAVED}`),
  notPassed: t(`status.${BLOCK_PASS_STATUS_NOT_PASSED}`),
  inProcess: t(`status.${BLOCK_PASS_STATUS_IN_PROCESS}`),
  passedCorrectly: t(`status.${BLOCK_PASS_STATUS_PASSED_CORRECTLY}`),
  passedIncorrectly: t(`status.${BLOCK_PASS_STATUS_PASSED_INCORRECTLY}`),
});

const Status = ({
  t,
  blockType,
  matchProgress,
  isNotRegularBlock,
  correctAnswersIds,
  selectedAnswersIds,
  correctMatchAnswers,
  selectedMatchAnswers,
}) => (
  <div className="Status">
    <Text
      value={
        {
          Quiz: helpers.block.indetifyStatus(
            selectedAnswersIds,
            correctAnswersIds,
            quizStatus(t),
            isNotRegularBlock
          ),
          Weight: isEmpty(selectedAnswersIds)
            ? quizStatus(t).notPassed
            : quizStatus(t).saved,
          Survey: isEmpty(selectedAnswersIds)
            ? quizStatus(t).notPassed
            : quizStatus(t).saved,
          Match: helpers.block.indetifyMatchStatus(
            correctMatchAnswers,
            selectedMatchAnswers,
            quizStatus(t),
            isNotRegularBlock
          ),
        }[blockType]
      }
      variant={combineStyles(
        'quiz-status',
        helpers.block.indetifyStatus(
          selectedAnswersIds,
          correctAnswersIds,
          quizStatus(t),
          isNotRegularBlock
        ) === quizStatus(t).passedCorrectly
          ? 'passed'
          : helpers.block.indetifyStatus(
              selectedAnswersIds,
              correctAnswersIds,
              quizStatus(t),
              isNotRegularBlock
            ) === quizStatus(t).passedIncorrectly
          ? 'passed'
          : helpers.block.indetifyStatus(
              selectedAnswersIds,
              correctAnswersIds,
              quizStatus(t),
              isNotRegularBlock
            ) === quizStatus(t).saved
          ? 'saved'
          : undefined
      )}
    />
    {!isNotRegularBlock && blockType === 'Match' && (
      <div className="status-indicator">
        {matchProgress.map((step, index) => (
          <div
            key={index}
            className={combineStyles(
              'marker',
              includes(
                step[[keys(step)]],
                correctMatchAnswers[keys(step)].answers
              )
                ? 'green'
                : 'red'
            )}
          />
        ))}
      </div>
    )}
    {!isNotRegularBlock && blockType === 'Quiz' && (
      <div className="status-indicator">
        {selectedAnswersIds.map((id, index) => (
          <div
            key={index}
            className={combineStyles(
              'marker',
              includes(id, correctAnswersIds) ? 'green' : 'red'
            )}
          />
        ))}
      </div>
    )}
  </div>
);

const { arrayOf, string, bool, object, array, oneOfType, func } = PropTypes;

Status.propTypes = {
  t: func.isRequired, // Функция перевода
  blockType: string.isRequired, // Тип блока
  matchProgress: array, // Прогресс прохождения блока Классификация
  isNotRegularBlock: bool, // Блок находится в контрольной странице или в олимпиаде?
  correctAnswersIds: arrayOf(string.isRequired), // Правильные ответы
  selectedAnswersIds: arrayOf(string.isRequired), // Выбранные ответы
  correctMatchAnswers: object, // Правильные ответы для блока Match
  selectedMatchAnswers: oneOfType([array, object]), // Выбранные ответы для блока Match
};

Status.defaultProps = {
  matchProgress: [],
  isNotRegularBlock: undefined,
  correctAnswersIds: [],
  selectedAnswersIds: [],
  correctMatchAnswers: undefined,
  selectedMatchAnswers: {},
};

export default pure(withTranslation('componentsQuiz')(Status));
