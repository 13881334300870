import { css } from 'styled-components';

export const container = css`
  padding: 30px;
`;

export const content = css`
  margin-top: 24px;
  border-radius: 4px;
  border: solid 1px #e5e9ec;
`;
