import { set, compose } from 'lodash/fp';

import pagesReducer, { defaultState as pagesState } from './pages';
import blocksReducer, { defaultState as blocksState } from './blocks';
import foldersReducer, { defaultState as foldersState } from './folders';
import lessonsReducer, { defaultState as lessonsState } from './lessons';
import coursesReducer, { defaultState as coursesState } from './courses';

export const defaultState = {
  pages: pagesState,
  blocks: blocksState,
  folders: foldersState,
  courses: coursesState,
  lessons: lessonsState,
};

const builderReducer = (state = defaultState, action) =>
  compose(
    set('pages', pagesReducer(state.pages, action)),
    set('blocks', blocksReducer(state.blocks, action)),
    set('folders', foldersReducer(state.folders, action)),
    set('courses', coursesReducer(state.courses, action)),
    set('lessons', lessonsReducer(state.lessons, action))
  )(state);

export default builderReducer;
