import { set } from 'lodash/fp';

import * as PLAYER from '../../constants/player';

const defaultState = {
  isUserWelcomeVisible: false,
  isOfferNewPublicationVisible: false,
};

const ui = (state = defaultState, action) => {
  switch (action.type) {
    case PLAYER.SHOW_USER_WELCOME: {
      return set('isUserWelcomeVisible', true, state);
    }

    case PLAYER.HIDE_USER_WELCOME: {
      return set('isUserWelcomeVisible', false, state);
    }

    case PLAYER.SHOW_OFFER_NEW_VERSION: {
      return set('isOfferNewPublicationVisible', true, state);
    }

    case PLAYER.HIDE_OFFER_NEW_VERSION: {
      return set('isOfferNewPublicationVisible', false, state);
    }

    default:
      return state;
  }
};

export default ui;
