import { set } from 'lodash/fp';
import * as APP from '../constants/app';

export const defaultState = {
  version: 2,
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case APP.SET_VERSION:
      return set('version', action.payload.newVersion, state);

    default:
      return state;
  }
};

export default appReducer;
