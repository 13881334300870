/* eslint-disable more/no-window */

import { all, put, select, takeLatest } from 'redux-saga/effects';
import { replace as routerReplace } from 'connected-react-router';
import { compose } from 'lodash/fp';

import * as actions from '~/actions';
import { addQueryParam, removeQueryParam } from '~/utils/query-params';
import { apiRequest } from '~/sagas/request';
import {
  REQUEST_NAME_EXTRA_DAYS,
  REQUEST_NAME_SUBSCRIBE,
} from '~/appConstants';
import {
  REMOVE_SUBSCRIPTION,
  removeSubscriptionSuccess,
  REQUEST_EXTRA_DAYS,
} from '~/actions/payment';

function* watchSubscribe() {
  yield takeLatest(actions.payment.subscribePlan, function*({ payload }) {
    const { name } = payload;
    const { href } = window.location;

    const successUrl = compose(
      addQueryParam('paymentStatus=success'),
      addQueryParam('modal=courseStatus'),
      removeQueryParam('modal')
    )(href);

    const failUrl = addQueryParam('paymentStatus=error', href);
    const response = yield apiRequest(
      'subscription/subscribe',
      {
        planName: name,
        successUrl,
        failUrl,
        language: 'ru', // TODO set correct language
      },
      'post',
      { name: REQUEST_NAME_SUBSCRIBE }
    );

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      yield put(actions.payment.subscribePlanError(error));
      return;
    }

    const { paymentUrl } = response.data.payload;
    // eslint-disable-next-line fp/no-mutation
    window.location = paymentUrl;
  });
}

function* watchChangePlan() {
  yield takeLatest(actions.payment.changePlan, function*({ payload }) {
    const { name } = payload;

    const response = yield apiRequest(
      'subscription/change-plan',
      { planName: name },
      'post',
      { name: REQUEST_NAME_SUBSCRIBE }
    );

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      yield put(actions.payment.changePlanError(error));
      return;
    }

    const { subscription } = response.data.payload;

    const { router } = yield select();
    yield put(
      routerReplace(removeQueryParam('modal')(router.location.pathname))
    );
    yield put(actions.payment.changePlanSuccess({ subscription }));
  });
}

function* watchChangeCard() {
  yield takeLatest(actions.payment.changeCard, function*() {
    const { href } = window.location;

    const successUrl = addQueryParam('changeCardStatus=success', href);
    const failUrl = addQueryParam('changeCardStatus=error', href);

    const response = yield apiRequest('subscription/change-card', {
      successUrl,
      failUrl,
      language: 'ru', // TODO set correct language
    });

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      yield put(actions.payment.changeCardError(error));
      return;
    }

    const { paymentUrl } = response.data.payload;
    // eslint-disable-next-line fp/no-mutation
    window.location = paymentUrl;
  });
}

function* watchDisableAutoPayment() {
  yield takeLatest(actions.payment.disableAutoPayment, function*() {
    const response = yield apiRequest('subscription/disable-auto-payment');

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      // TODO: обработать ошибку остановки подписки
      yield put(actions.payment.disableAutoPaymentError(error));
      return;
    }

    // TODO: how to handle?
    yield put(actions.payment.disableAutoPaymentSuccess());
  });
}

function* watchEnableAutoPayment() {
  yield takeLatest(actions.payment.enableAutoPayment, function*() {
    const response = yield apiRequest('subscription/enable-auto-payment');

    if (!response) {
      return;
    }

    const { error } = response.data;
    if (error) {
      // TODO: обработать ошибку остановки подписки
      yield put(actions.payment.enableAutoPaymentError(error));
      return;
    }

    // TODO: how to handle?
    yield put(actions.payment.enableAutoPaymentSuccess());
  });
}

function* watchGetExtraDays() {
  yield takeLatest(REQUEST_EXTRA_DAYS, function*(action) {
    const { planName } = action.payload;

    const response = yield apiRequest(
      'subscription/get-extra-days',
      { planName },
      'post',
      { name: REQUEST_NAME_EXTRA_DAYS }
    );

    if (!response) {
      return;
    }
    const { error } = response.data;
    if (error) {
      return;
    }

    const {
      payload: { extraDays },
    } = response.data;
    yield put(actions.payment.setExtraDays({ extraDays }));
  });
}

function* watchRemoveSubscription() {
  yield takeLatest(REMOVE_SUBSCRIPTION, function*() {
    const response = yield apiRequest('subscription/unsubscribe');

    if (!response) {
      return;
    }
    const { error } = response.data;
    if (error) {
      return;
    }

    const {
      payload: { subscription },
    } = response.data;
    yield put(removeSubscriptionSuccess({ subscription }));
  });
}

export default function*() {
  yield all([
    watchSubscribe(),
    watchChangePlan(),
    watchDisableAutoPayment(),
    watchEnableAutoPayment(),
    watchGetExtraDays(),
    watchRemoveSubscription(),
    watchChangeCard(),
  ]);
}
