import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import localeRu from 'date-fns/locale/ru';
import localeEn from 'date-fns/locale/en';
import withMobileDetect from '~/hocs/withMobileDetect';
import * as s from '../../styles/Notification.styles';
import RouterLink from '~/containers/player/RouterLink';

const locales = {
  ru: localeRu,
  en: localeEn,
  kz: localeRu,
};

// TODO: i18n
const AnswerReviewNotification = ({
  id,
  date,
  isUnread,
  data: { courseName, courseId, teacherName },
}) => {
  const { i18n } = useTranslation('components');

  const dateString = format(date * 1000, 'DD MMMM HH:mm', {
    locale: locales[i18n.languages[0]],
  });

  return (
    <RouterLink
      to={{
        pathname: `/tutor/course/${courseId}`,
        state: { fromNotification: id },
      }}
    >
      <div css={s.item} isUnread={isUnread}>
        <div css={s.avatar} />
        <div css={s.itemContent}>
          <strong>{teacherName}</strong>
          <span>
            {' '}
            добавил вас в курс «{courseName}» как тьютора. Подтвердите или
            отклоните участие.
          </span>
          <div css={s.date}>{dateString}</div>
        </div>
      </div>
    </RouterLink>
  );
};

const { shape, string, bool, number } = PropTypes;

AnswerReviewNotification.propTypes = {
  id: string.isRequired,
  data: shape({
    answerName: string.isRequired,
    teacherName: string.isRequired,
    isApproved: bool.isRequired,
    blockId: string.isRequired,
    lessonId: string.isRequired,
    publication: number.isRequired,
    pageId: string.isRequired,
  }).isRequired,
  date: number.isRequired,
  isUnread: bool,
};

export default withMobileDetect(pure(AnswerReviewNotification));
