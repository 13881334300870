/* eslint-disable more/no-window */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

// import { YMInitializer } from 'react-yandex-metrika';
import { AppContainer } from 'react-hot-loader';
import * as Sentry from '@sentry/browser';
import { FocusStyleManager } from '@blueprintjs/core';
import { createBrowserHistory } from 'history';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import { persistStore } from 'redux-persist';
import { isMobile } from 'react-device-detect';

import '../static/fonts/fonts.scss';
import './styles.scss';

import createStore from './store';
import rootSaga from './sagas';
import createI18n from './i18n';
import App from './app';
import { oneOf } from './utils/fp';
import { signIn } from './actions/auth';
import { setIsMobile } from './actions/ui';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://51c28b7a2a7640369846b369299e0682@sentry.io/5175415',
//     environment: process.env.BUILD_NAME,
//     release: process.env.SENTRY_RELEASE_ID,
//   });
// }

FocusStyleManager.onlyShowFocusOnTabs();

/* eslint-disable fp/no-delete, no-underscore-dangle */
const initialState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
/* eslint-enable fp/no-delete, no-underscore-dangle */

const history = createBrowserHistory({ basename: process.env.BASENAME });

const i18n = createI18n({ detector: BrowserLanguageDetector });

const sagaMiddleware = createSagaMiddleware({
  context: {
    i18n,
    axios: axios.create(),
  },
  onError: (error) => {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console, no-unused-expressions
    process.env.BUILD_NAME !== 'prod' && console.error(error);
  },
});
const store = createStore({ history, initialState, sagaMiddleware });

persistStore(store);

sagaMiddleware.run(rootSaga);

// eslint-disable-next-line fp/no-mutation
window.hydration = !process.env.IS_DEV_SERVER;

// eslint-disable-next-line fp/no-mutation,unicorn/prefer-add-event-listener
window.onerror = (msg, url, lineNo, columnNo, error) => {
  // * https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#Notes
  // * Ловит не все ошибки, поэтому пока закомменчено
  // const string = msg ? msg.toLowerCase() : '';
  // const substring = 'script error';
  // if (!string.includes(substring)) {
  //   return false;
  // }

  const extras = { msg, url, lineNo, columnNo };
  Sentry.withScope((scope) => {
    scope.setExtras(extras);
    if (error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(msg, 'error');
    }
  });

  return false;
};

const reactDOMRender = process.env.IS_DEV_SERVER
  ? ReactDOM.render
  : ReactDOM.hydrate;

const reactRoot = document.getElementById('container');

ReactGA.initialize('UA-131027554-1');

const render = (Component) => {
  reactDOMRender(
    <AppContainer warnings={false}>
      <div>
        <Component i18n={i18n} store={store} history={history} />
        {/* {process.env.NODE_ENV === 'production' && (
          <YMInitializer
            accounts={[51575900]}
            options={{
              webvisor: true,
              clickmap: true,
              trackHash: true,
              trackLinks: true,
              accurateTrackBounce: true,
            }}
          />
        )} */}
      </div>
    </AppContainer>,
    reactRoot,
    () => {
      // eslint-disable-next-line fp/no-mutation
      window.hydration = false;
    }
  );
};

if (process.env.IS_DEV_SERVER) {
  reactDOMRender(<div>Waiting for user profile loading...</div>, reactRoot);

  axios // eslint-disable-line more/no-then
    .get(`${process.env.API_URL}/me`, {
      withCredentials: true,
      validateStatus: oneOf([200, 403]),
    })
    .then(({ status, data: { payload } }) => {
      if (status === 200) {
        const {
          id,
          admin,
          givenName,
          mail,
          secret,
          role,
          subscription,
          verified,
        } = payload;

        store.dispatch(
          signIn({
            userId: id,
            userName: givenName,
            userEmail: mail,
            isAdmin: admin,
            secret,
            role,
            subscription,
            verified,
          })
        );
      }

      store.dispatch(setIsMobile({ isMobile }));

      render(App);

      if (module.hot) {
        module.hot.accept('./app', () => {
          render(App);
        });
      }
    });
} else {
  render(App);
}
