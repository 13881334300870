import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import objectId from 'bson-objectid';
import { get, compose } from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import { withHandlers, withState } from 'recompose';
import withMobileDetect from '~/hocs/withMobileDetect';
import {
  Text,
  Link,
  LessonThumbnail,
  TeachFolderThumbnail,
} from '~/components';
import {
  LESSON_TYPE_REGULAR,
  REQUEST_NAME_LESSON_DOWNLOADING,
} from '~/appConstants';
import { Folders, Results, Templates } from '~/containers';
import DotLoader from '~/components/DotLoader';
import CreateLessonButton from '~/containers/CreateLessonButton';
import { combineStyles } from '~/utils/styles';
import * as selectors from '~/selectors';
import * as actions from '~/actions';
import { isRequestActive } from '~/selectors/ui';
import '~/styles/Folder.scss';

const ScreenTeach = ({
  t,
  list,
  router,
  isMobile,
  folderId,
  addFolder,
  folderName,
  moveLesson,
  openFolder,
  allFolders,
  lessonType,
  showTemplates,
  isHintsEnabled,
  templatesIsOpen,
  changeLessonType,
  isLessonResultsOpen,
}) => {
  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_LESSON_DOWNLOADING)
  );

  return isLoading ? (
    <DotLoader className="center" />
  ) : (
    <div className={combineStyles('Folder', isMobile && 'mobile')}>
      {!isMobile && (
        <div className={combineStyles('control', folderId && 'in-folder')}>
          <CreateLessonButton
            variant="plus"
            folderId={folderId}
            addFolder={addFolder}
            showTemplates={showTemplates}
            changeLessonType={changeLessonType}
          />
        </div>
      )}
      <div className="content">
        <div className="folder-name-row">
          {folderId && (
            <Link
              folderId={folderId}
              canDrag
              withIcon="arrow-left"
              variant="in-folder-back"
              onDragEnd={moveLesson}
              active
              onClick={() => router.push('/teach')}
            />
          )}
          {folderId && <div className="folder-name">{folderName}</div>}
        </div>

        {!isMobile && (
          <Templates
            isOpen={templatesIsOpen}
            router={router}
            folderId={folderId}
            lessonType={lessonType}
            showTemplates={showTemplates}
          />
        )}
        {folderId && isMobile && (
          <div className="all-lessons">
            <Link
              withIcon="arrow-left"
              folderId={folderId}
              text={t('folder.allLessons')}
              active
              variant="back-all-lessons"
              onClick={() => router.push('/builder/')}
            />
          </div>
        )}
        {isLessonResultsOpen && <Results />}
        {!folderId && (
          <Folders
            ThumbnailComponent={TeachFolderThumbnail}
            openFolder={openFolder}
          />
        )}
        {!folderId && Object.keys(allFolders).length >= 1 && (
          <div className="lessons-title">
            <Text value={t('folder.lessons')} variant="lessons-list-title" />
          </div>
        )}
        <div className={combineStyles('lessons-list', isMobile && 'mobile')}>
          {list.map((lessonId) => (
            <LessonThumbnail
              id={lessonId}
              key={lessonId}
              isHintsEnabled={isHintsEnabled}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const { func, string, object, bool, arrayOf } = PropTypes;

ScreenTeach.propTypes = {
  t: func.isRequired, // Функция перевода
  list: arrayOf(string).isRequired, // Список уроков внутри папки
  router: object.isRequired, // Роутер
  folderId: string, // ID папки
  isMobile: bool.isRequired,
  addFolder: func.isRequired, // Добавить папку
  allFolders: object.isRequired, // Список папок
  moveLesson: func.isRequired, // Переместить урок
  openFolder: func.isRequired, // Открыть папку
  folderName: string, // Название папки
  lessonType: string.isRequired, // Тип создаваемого урока
  showTemplates: func.isRequired, // Открыть окно создания урока
  isHintsEnabled: bool.isRequired, // Подсказки по интерфейсу включены?
  templatesIsOpen: bool, // Окно создания урока открыто?
  changeLessonType: func.isRequired, // Выбрать тип создаваемого урока
  isLessonResultsOpen: bool, // Окно результатов открыто?
};

ScreenTeach.defaultProps = {
  folderId: '',
  folderName: undefined,
  templatesIsOpen: false,
  isLessonResultsOpen: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const {
    folderId = ownProps.match.params.folderId,
    history: router,
  } = ownProps;
  const userId = selectors.auth.getUserId(state);
  const allFolders = selectors.builder.getFolders(state);

  const folderName =
    folderId && get(['builder', 'folders', folderId, 'name'], state);
  const list = selectors.builder.getUserSortedFolderLessonsIds(
    userId,
    folderId,
    state
  );

  const isHintsEnabled = selectors.builder.isHintsEnabled(state);
  const { isOpen } = selectors.results.getData(state);

  return {
    list,
    router,
    folderId,
    allFolders,
    folderName,
    isHintsEnabled,
    isLessonResultsOpen: isOpen,
  };
};

const mapDispatchToProps = {
  addFolder: actions.builder.folder.add,
  moveLesson: actions.builder.lesson.move,
};

const enhance = compose(
  withState('templatesIsOpen', 'showTemplates', false),
  withState('lessonType', 'changeLessonType', LESSON_TYPE_REGULAR),
  withHandlers({
    addFolder: ({ addFolder }) => () => {
      const newFolderId = objectId.generate();
      addFolder(newFolderId);
    },
    openFolder: ({ router }) => (folderId) =>
      router.push(`/teach/lessons/folder/${folderId}`),
  })
);

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(ScreenTeach);
