import React from 'react';
import PropTypes from 'prop-types';

import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';

import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import NotFound from './NotFound';
import { Auth, Lesson, Presentation, PrintPage } from '../containers';
import PlayerResultsLoader from '../components/PlayerResultsLoader';
import EnterByCode from '../containers/player/EnterByCode';
import PlayerCourse from '../containers/courses/Course';
import PassRecovery from '../containers/Auth/PassRecovery';
import RecoverySent from '../containers/Auth/RecoverySent';
import NewPassword from '../containers/Auth/NewPassword';
import Preview from '../containers/player/Preview';
import Player from '../containers/player/Player';
import ScreenStudy from '../screens/Study/Study';
import ScreenTeach from '../screens/Teach/Teach';
import ScreenTeachCourse from '../screens/Teach/Course/Course';
import ScreenSelectRole from '../screens/SelectRole';
import ScreenSharedOlympiadResult from '../screens/SharedOlympiadResult';
import TutorCourse from '~/containers/tutors/Course';
import VerifyEmail from './VerifyEmail';

const Router = ({ history }) => {
  const isNotFoundPage = useSelector(get(['ui', 'isNotFoundPage']));

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <div>
          {isNotFoundPage ? (
            <NotFound />
          ) : (
            <Switch>
              <Route path="/verify/:code" exact component={VerifyEmail} />
              <Route path="/player/course/:id" exact component={PlayerCourse} />
              <Route path="/tutor/course/:id" exact component={TutorCourse} />

              <PrivateRoute
                path="/teach/course/:id"
                component={ScreenTeachCourse}
              />

              <Redirect exact from="/" to="/select-role" />
              <Redirect exact from="/builder/" to="/teach" />
              <Redirect
                from="/builder/folder/:id"
                to="/teach/lessons/folder/:id"
              />
              <PrivateRoute path="/builder/lesson/:id" component={Lesson} />
              <PrivateRoute
                path="/player/print/:lessonId/:publication?/:pageId?"
                component={PrintPage}
              />
              <PrivateRoute
                path="/presentation/lesson/:lessonId"
                component={Presentation}
              />
              <Route
                path="/preview/lesson/:publicationId/:pageId?"
                component={Preview}
              />
              <Route
                path="/player/lesson/:lessonId/:publication(\d{1,16})?/:pageId?"
                component={Player}
              />
              <Route
                path="/shared-olympiad-result/:resultId"
                component={ScreenSharedOlympiadResult}
              />
              <Route
                path="/player/resume/:resultId"
                component={PlayerResultsLoader}
              />
              <PrivateRoute path="/study" component={ScreenStudy} />
              <PrivateRoute path="/teach" component={ScreenTeach} />
              <PrivateRoute path="/select-role" component={ScreenSelectRole} />
              <Route path="/code" component={EnterByCode} />
              <Route path="/auth" component={Auth} />
              <Route path="/recovery" component={PassRecovery} />
              <Route path="/recovery-sent" component={RecoverySent} />
              <Route
                path="/newpassword/:changePasswordId"
                component={NewPassword}
              />
              <Route component={NotFound} />
            </Switch>
          )}
        </div>
      </ScrollToTop>
    </ConnectedRouter>
  );
};

Router.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Router;
