import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';
import * as Yup from 'yup';

import Input from '../../components/Input';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(), // TODO сообщение об ошибки для name
});

const Name = ({
  t,
  values,
  isSubmitting,
  setFieldValue,
  handleSubmit,
  variant,
}) => (
  <div className="Name">
    <Input
      usePortal
      autoFocus
      debounceTime={100}
      value={values.name || ''}
      type="text"
      variant={variant}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('name', value)}
      placeholder={t('name.fullname')}
      onPressEnter={handleSubmit}
    />
  </div>
);

const { string, func, bool, shape, array } = PropTypes;

Name.propTypes = {
  t: func.isRequired, // Функция перевода
  values: shape({
    name: string.isRequired,
  }).isRequired, // Ввод Имени
  isSubmitting: bool.isRequired,
  setFieldValue: func.isRequired,
  handleSubmit: func.isRequired,
  variant: array.isRequired,
};

export default compose(
  withTranslation('componentsAuth'),
  withProps(({ errors, touched, inSafeMode, fontSize }) => ({
    variant: [
      'welcome',
      inSafeMode && ['safe-mode', fontSize],
      errors.name && touched.name && 'error',
    ],
  }))
)(Name);
