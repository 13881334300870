import * as QUIZ from '../../../constants/player/block/quiz';

import { createAction } from '../../../utils/actions';

export const selectAnswer = ({
  blockId,
  answerId,
  isNotRegularBlock,
  blockType,
  quizType,
}) =>
  createAction(QUIZ.SELECT_ANSWER, {
    answerId,
    blockId,
    isNotRegularBlock,
    blockType,
    quizType,
  });

export const saveShuffleAnswers = (blockId, shuffledAnswers) =>
  createAction(QUIZ.SAVE_SHUFFLE_ANSWERS, { blockId, shuffledAnswers });

export const changeAnswerOther = (blockId, answer) =>
  createAction(QUIZ.CHANGE_ANSWER_OTHER, { blockId, answer });
