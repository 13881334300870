import * as exam from './exam';
import * as quiz from './quiz';
import * as talk from './talk';
import * as match from './match';
import * as answer from './answer';
import * as feedback from './feedback';
import * as fillblank from './fillblank';
import * as BLOCK from '../../../constants/player/block';
import { createAction } from '../../../utils/actions';

export { exam, quiz, answer, fillblank, feedback, match, talk };

export const reset = (id) => createAction(BLOCK.RESET, { id });
export const verify = (id) => createAction(BLOCK.VERIFY, { id });
