import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, getOr, head, last, isMatch, get } from 'lodash/fp';
import { lifecycle, withContext, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import logo from 'Custom/logo.png';
import withMobileDetect from '~/hocs/withMobileDetect';

import withTheming from '~/hocs/withTheming';
import Button from '~/components/Button';
import Text from '~/components/Text';
import Help from '~/components/Help';
import HelpRow from '~/components/HelpRow';

import { combineStyles } from '~/utils/styles';
import * as selectors from '~/selectors';
import * as helpers from '~/helpers';
import * as actions from '~/actions';
import '~/styles/Player/index.scss';
import {
  getLessonCorrectTasksCount,
  getLessonTotalTasksCount,
} from '~/helpers/player/tasksCount';
import AppearanceBar from './AppearanceBar';
import PlayerPage from './PlayerPage';
import PlayerIndex from './PlayerIndex';
import withPreviewLoader from './withPreviewLoader';
import { LESSON_TYPE_OLYMPIAD } from '~/appConstants';

const type = 'teacher';
const Preview = ({
  t,
  theme,
  pageId,
  fontSize,
  isMobile,
  courseId,
  lessonUrl,
  toggleMode,
  inSafeMode,
  isLoggedIn,
  changeTheme,
  lessonRunTime,
  isOlympiadMode,
  lessonPagesIds,
  changeFontSize,
  totalTasksCount,
  lessonTimeLimit,
  lessonDescription,
  correctTasksCount,
  addLessonInBuilder,
  viewLessonInBuilder,
  isLessonAlreadyCloned,
  olympiadSettings: {
    partnersLogos: olympiadPartnersLogos,
    descriptionInResult: olympiadDescriptionInResult,
  },
}) => (
  <div
    className={combineStyles('Player', [
      lessonTimeLimit && lessonRunTime === lessonTimeLimit && 'time-over',
      inSafeMode && theme,
      isMobile && 'mobile',
    ])}
  >
    {inSafeMode && (
      <AppearanceBar
        changeFontSize={changeFontSize}
        changeTheme={changeTheme}
        toggleMode={toggleMode}
        fontSize={fontSize}
        theme={theme}
      />
    )}
    {pageId ? (
      <PlayerPage
        lessonUrl={lessonUrl}
        pageId={pageId}
        isOlympiadMode={isOlympiadMode}
        isLessonComplete={false}
        toggleShowCompleteAlert={() => {}}
      />
    ) : (
      <PlayerIndex
        type={type}
        isOlympiadMode={isOlympiadMode}
        olympiadPartnersLogos={olympiadPartnersLogos}
        olympiadDescriptionInResult={olympiadDescriptionInResult}
        isLessonComplete={false}
        lessonDescription={lessonDescription}
        lessonPagesIds={lessonPagesIds}
        totalTasksCount={totalTasksCount}
        correctTasksCount={correctTasksCount}
      />
    )}
    {!inSafeMode && (
      <Button
        icon="eye-open"
        text={t('index.visuallyImpairedVersion')}
        variant={combineStyles(
          'toggle-safemode',
          type === 'teacher' && 'preview'
        )}
        onClick={toggleMode}
      />
    )}
    {!courseId && (
      <div className={combineStyles('clone-panel', isMobile && 'mobile')}>
        <div className="left">
          <div className="logo">
            <img className="logo-img" src={logo} alt="core" />
          </div>
          <div className="info">
            <div className="author">
              <Text
                text={t('index.author')}
                variant="player-clone-panel-info-author"
              />
            </div>
            <div className="openschool">
              <Text
                value={t('index.core')}
                variant="player-clone-panel-info-openschool"
              />
            </div>
          </div>
        </div>
        {!isLessonAlreadyCloned && (
          <div className="clone">
            <Button
              text={t('index.addToMyLessons')}
              variant="player-send-lesson-edit"
              onClick={addLessonInBuilder}
            />
          </div>
        )}
        {isLessonAlreadyCloned && isLoggedIn && (
          <div className="edit">
            <Button
              text={t('index.seeInMyLessons')}
              variant="player-clone-panel-clone"
              onClick={viewLessonInBuilder}
            />
          </div>
        )}
      </div>
    )}
    {isMobile ? (
      <HelpRow inPlayer />
    ) : (
      <Help inPlayer fixed variant="help-in-folder" />
    )}
  </div>
);

const { string, number, bool, func, arrayOf, object } = PropTypes;

Preview.propTypes = {
  t: func, // Функция перевода
  theme: string.isRequired, // Цветовая тема в плеере
  fontSize: string.isRequired, // Размер шрифта в плеере
  lessonUrl: string.isRequired,
  isMobile: bool.isRequired,
  courseId: string,
  toggleMode: func.isRequired, // Переключить режим отображения плеера
  isLoggedIn: bool, // Учитель залогинен
  inSafeMode: bool.isRequired, // Плеер в режиме ОВЗ?
  changeTheme: func.isRequired, // Выбрать цветовую тему в плеере
  lessonRunTime: number, // Время, прошедшее с начала выполнения урока
  isOlympiadMode: bool, // В режиме олимпиады?
  changeFontSize: func.isRequired, // Выбрать размер шрифта в плеере
  lessonPagesIds: arrayOf(string), // ID страниц урока
  lessonTimeLimit: number, // Ограничение по времени для урока
  totalTasksCount: number, // Общее количество вопросов в уроке
  correctTasksCount: number, // Количество верных пройденных вопросов в уроке
  lessonDescription: string, // Описание урока
  addLessonInBuilder: func.isRequired, // Добавить урок в конструктор
  viewLessonInBuilder: func.isRequired, // Перейти в добавленный урок в конструктор
  isLessonAlreadyCloned: bool.isRequired, // Урок уже был клонирован в конструктор?
  pageId: string,
  olympiadSettings: object,
};

Preview.defaultProps = {
  t: func.isRequired,
  isOlympiadMode: false,
  lessonPagesIds: [],
  olympiadSettings: {
    partnersLogos: {},
  },
};

const mapStateToProps = (state, ownProps) => {
  const {
    // lessonId,
    pageId: pageIdInModal,
    publication,
  } = ownProps.match.params;
  const { history: router } = ownProps;
  const {
    id: lessonId,
    content: {
      name: lessonName,
      pagesIds: lessonPagesIds,
      description: lessonDescription,
    } = {},
  } = getOr({}, ['player', 'lesson'], state);
  const courseId = get(['player', 'lesson', 'meta', 'courseId'], state);
  const isLoggedIn = selectors.auth.isLoggedIn(state);

  const currentResult = state.player.result;
  const allBuilderLessons = selectors.builder.getLessons(state);
  const isLessonAlreadyCloned = helpers.lesson.isCloned(
    lessonId,
    allBuilderLessons
  );
  const lessonTimeLimit = selectors.player.getLessonTimeLimit(lessonId, state);
  const isOlympiadMode =
    selectors.player.getLessonType(state) === LESSON_TYPE_OLYMPIAD;
  const clonedLessonId = helpers.lesson.getCloneId(lessonId, allBuilderLessons);
  const lessonRunTime = selectors.player.getLessonRunTime(lessonId, state);
  const isLessonWithDateRange = selectors.player.isLessonWithDateRange(
    lessonId,
    state
  );
  const lessonStartDate = selectors.player.getLessonStartDate(lessonId, state);
  const lessonEndDate = selectors.player.getLessonEndDate(lessonId, state);
  const isNextPageExists = last(lessonPagesIds) !== pageIdInModal;
  const isPreviousPageExists = head(lessonPagesIds) !== pageIdInModal;
  const allPages = selectors.player.getPages(state);

  // Фильтрация блоков для опросника
  const { selectedBlocksIds } = currentResult || {}

  const totalTasksCount = getLessonTotalTasksCount(state, selectedBlocksIds);
  const correctTasksCount = getLessonCorrectTasksCount(state, selectedBlocksIds);

  const olympiadSettings = selectors.player.getOlympiadSettings(
    lessonId,
    state
  );

  return {
    type,
    router,
    allPages,
    lessonId,
    courseId,
    isLoggedIn,
    lessonName,
    publication,
    lessonRunTime,
    lessonEndDate,
    pageIdInModal,
    currentResult,
    lessonPagesIds,
    clonedLessonId,
    isOlympiadMode,
    lessonTimeLimit,
    lessonStartDate,
    totalTasksCount,
    isNextPageExists,
    olympiadSettings,
    correctTasksCount,
    lessonDescription,
    isPreviousPageExists,
    isLessonAlreadyCloned,
    isLessonWithDateRange,
  };
};

const mapDispatchToProps = {
  verify: actions.player.page.verify,
  openPage: actions.player.page.open,
  startExecution: actions.player.lesson.startExecution,
  changeActivePage: actions.player.changeActivePage,
  addLessonInBuilder: actions.builder.lesson.importFromPlayer,
};

const enhance = compose(
  withRouter,
  withHandlers({
    addLessonInBuilder: ({
      router,
      addLessonInBuilder,
      isLoggedIn,
      lessonId,
    }) => () => {
      if (isLoggedIn) {
        addLessonInBuilder(lessonId);
      } else {
        router.push('/auth/');
      }
    },
    viewLessonInBuilder: ({ router, clonedLessonId }) => () =>
      router.push(`/builder/lesson/${clonedLessonId}`),
  }),
  withContext(
    {
      inPlayer: PropTypes.bool.isRequired,
      inPreview: PropTypes.bool.isRequired,
    },
    () => ({
      inPreview: true,
      inPlayer: true,
    })
  ),
  withProps(({ match }) => ({
    pageId: match.params.pageId,
    lessonUrl: `/preview/lesson/${match.params.publicationId}`,
  })),
  lifecycle({
    /* eslint-disable fp/no-this */
    componentDidMount() {
      const {
        lessonId,
        publication,
        currentResult,
        startExecution,
      } = this.props;

      if (currentResult && isMatch({ lessonId, publication }, currentResult)) {
        this.props.changeActivePage(this.props.match.params.pageId);
        return;
      }

      startExecution({ lessonId, publication });
    },
    componentDidUpdate(prevProps) {
      const { result, pageId } = this.props;

      if (prevProps.pageId !== pageId || (!prevProps.result && result)) {
        this.props.changeActivePage(pageId);
      }
    },
    /* eslint-enable fp/no-this */
  })
);

export default compose(
  withPreviewLoader,
  withTranslation('containersPlayer'),
  withMobileDetect,
  withRouter,
  connect((state) => ({
    lessonDownloadStatus: state.player.lessonDownloadStatus,
  })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTheming,
  enhance
)(Preview);
