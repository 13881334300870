import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import * as Blueprint from '@blueprintjs/core';
import { combineStyles } from '../utils/styles';
import '../styles/Slider.scss';

const Slider = ({ value, variant, minValue, maxValue, onChange }) => (
  <div className={combineStyles('Slider', variant)}>
    <Blueprint.Slider
      min={minValue}
      max={maxValue}
      value={value}
      stepSize={1}
      onChange={onChange}
      labelStepSize={maxValue - 1}
    />
  </div>
);

const { string, array, oneOfType, number, func } = PropTypes;

Slider.propTypes = {
  value: number.isRequired, // Значение
  variant: oneOfType([array, string]), // Вариант оформления
  minValue: number, // Минимальное значение
  maxValue: number.isRequired, // Максимальное значение
  onChange: func.isRequired, // Функция изменения
};

Slider.defaultProps = {
  minValue: 0,
  variant: undefined,
};

export default pure(Slider);
