import { cond, isEqual, constant, compose, map } from 'lodash/fp';

/**
 * Сопоставление с образцом
 * @param {string[][]} rules - правила сопоставления
 * @param {any} value - значение
 */
// [[1, true], [2, false]] -> 1 -> true
export const match = compose(
  cond,
  map(([matcher, result]) => [isEqual(matcher), constant(result)])
);

export const oneOf = (array) => array.includes.bind(array);
