import React from 'react';
import PropTypes from 'prop-types';

import objectId from 'bson-objectid';
import memoize from 'fast-memoize';

import { compose, get, join, drop, isEmpty } from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import { withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import Text from '~/components/Text';
import Froala from '~/components/Froala';
import Uploader from '~/containers/Uploader';
import FilePreview from '~/components/FilePreview';
import * as actions from '~/actions';
import * as s from './styles/Feedback.styles';

const renderFilePreview = memoize(({ item, remove, isCanRemove }) => {
  const { source } = item[1];
  const filenameWithHash = source
    // eslint-disable-next-line no-useless-escape
    .replace(/^.*[\\\/]/, '')
    .replace(/\.[^/.]+$/, '')
    .split('-');
  const filename = join('-', drop(1, filenameWithHash));
  const extension = source.match(/[^.]+$/)[0];

  return (
    <div key={item[0]}>
      <FilePreview
        name={filename}
        source={item[1].source}
        extension={extension}
        isCanRemove={isCanRemove}
        remove={remove}
      />
    </div>
  );
});

const Feedback = ({
  theme,
  blockId,
  fontSize,
  question,
  inReview,
  inPlayer,
  userData,
  inBuilder,
  userAnswer,
  inSafeMode,
  description,
  changeReply,
  removeImage,
  inPresentation,
  changeQuestion,
  isPageCompleted,
  isLessonComplete,
  changeDescription,
  isBlockInVerifyMode,
}) => {
  const { t } = useTranslation('containersBlock');
  const userName = useSelector(get(['player', 'result', 'userName']));

  return (
    <div css={s.container}>
      <div css={s.question}>
        {inBuilder ? (
          <Text
            value={question}
            variant="answer-question"
            editable
            multiline
            onChange={changeQuestion}
            placeholder={t('feedback.changeQuestion')}
          />
        ) : (
          question
        )}
      </div>
      <div css={s.description}>
        {inBuilder ? (
          <Froala
            mode="editor"
            toolbarButtons={[
              'bold',
              'align',
              'quote',
              'italic',
              'indent',
              'outdent',
              'formatUL',
              'formatOL',
              'underline',
              'insertLink',
              'insertTable',
              'insertImage',
              'strikeThrough',
              'paragraphFormat',
            ]}
            placeholder={t('feedback.enterDescription')}
            content={description}
            onChange={changeDescription}
          />
        ) : (
          <Froala mode="preview" content={description} />
        )}
      </div>
      <div css={s.reply}>
        {inPlayer || inPresentation ? (
          <Text
            value={userAnswer.reply}
            variant={
              inSafeMode ? ['feedback-text', fontSize, theme] : 'feedback-text'
            }
            editable={!(isBlockInVerifyMode || isPageCompleted)}
            multiline
            onChange={changeReply}
            placeholder={t('feedback.changeAnswer')}
          />
        ) : inReview ? (
          <Text
            value={userAnswer.reply || t('answer.noAnswer')}
            variant="feedback-text"
          />
        ) : (
          <Text
            value={t('answer.answersField')}
            variant="feedback-text-preview"
          />
        )}

        {userAnswer.files && (
          <div css={s.images}>
            {!isEmpty(userAnswer.files) &&
              Object.entries(userAnswer.files).map((item) =>
                inReview
                  ? renderFilePreview({ item })
                  : renderFilePreview({
                      item,
                      remove: () => removeImage(item[0], blockId),
                      isCanRemove: !isLessonComplete,
                    })
              )}
          </div>
        )}
      </div>

      <div css={s.bottom}>
        <div css={s.user}>
          <div css={s.userAvatar} />
          <div css={s.userName}>
            {inBuilder
              ? inReview
                ? userData.user
                : t('feedback.student')
              : userName}
          </div>
        </div>

        {inPlayer && !isLessonComplete && (
          <div css={s.uploader}>
            <Uploader
              variant={
                inSafeMode
                  ? ['feedback-uploader', fontSize, theme]
                  : 'feedback-uploader'
              }
              type="answer"
              icon="paperclip"
              blockId={blockId}
              filePath={[blockId, 'userAnswer', 'files', objectId.generate()]}
              title={t('answer.attachFile')}
              inPlayer
              fileType="any"
              isNoticeProgress
              uploadType="block"
            />
            <div className="tooltip">
              <Icon
                usePortal
                tooltip={
                  <div>
                    <div>{t('answer.attachFileTooltipLine1')}</div>
                    <div>{t('answer.attachFileTooltipLine2')}</div>
                    <div>{t('answer.attachFileTooltipLine3')}</div>
                  </div>
                }
                name="help"
                position="right"
                variant="sidebar-tooltip"
              />
            </div>
          </div>
        )}

        {inBuilder && !inReview && (
          <div css={s.imageReply}>
            <div css={s.uploaderIcon}>
              <Icon name="paperclip" />
            </div>
            <Text value={t('feedback.attachFileHint')} variant="image-answer" />
          </div>
        )}
      </div>
    </div>
  );
};

const { bool, string, func, object } = PropTypes;

Feedback.propTypes = {
  theme: string,
  blockId: string.isRequired,
  question: string,
  fontSize: string,
  userData: object,
  inPlayer: bool,
  inReview: bool,
  inBuilder: bool,
  inSafeMode: bool,
  userAnswer: object,
  description: string,
  changeReply: func.isRequired,
  removeImage: func.isRequired,
  inPresentation: bool,
  changeQuestion: func.isRequired,
  isPageCompleted: bool,
  isLessonComplete: bool,
  changeDescription: func.isRequired,
  isBlockInVerifyMode: bool,
};

Feedback.defaultProps = {
  theme: 'light',
  fontSize: 'small',
  userData: {},
  userAnswer: {},
};

const mapDispatchToProps = {
  changeReply: actions.player.block.feedback.changeReply,
  removeImage: actions.player.block.feedback.removeImage,
  changeQuestion: actions.builder.block.feedback.changeQuestion,
  changeDescription: actions.builder.block.feedback.changeDescription,
};

const handlersEnhance = withHandlers({
  changeReply: (props) => (newReply) =>
    props.changeReply(props.blockId, newReply),
  changeQuestion: (props) => (newQuestion) =>
    props.changeQuestion(props.blockId, newQuestion),
  changeDescription: (props) => (newDescription) =>
    props.changeDescription(props.blockId, newDescription),
});

export default compose(
  connect(
    undefined,
    mapDispatchToProps
  ),
  handlersEnhance
)(Feedback);
