import { get } from 'lodash/fp';

export const getUserId = get(['auth', 'id']);
export const isLoggedIn = get(['auth', 'isLoggedIn']);
export const isUserAdmin = get(['auth', 'isAdmin']);
export const getUserName = get(['auth', 'name']);
export const getUserSubscription = get(['auth', 'subscription']);
export const getUserEmail = get(['auth', 'email']);
export const getUserSecret = get(['auth', 'secret']);
export const getUserRole = get(['auth', 'role']);
export const isEmailVerified = get(['auth', 'verified']);
export const isVerificationEmailSent = get(['auth', 'verificationEmailSent']);
