import React from 'react';
import PropTypes, { node } from 'prop-types';

import { format } from 'date-fns';

import Froala from '~/components/Froala';
import * as s from '~/screens/Teach/Review/styles/Review.styles';

const dateFormat = 'DD.MM.YY HH:mm';

const ReviewAnswer = ({
  isApproved,
  studentName,
  studentFiles,
  teacherName,
  teacherFiles,
  studentAnswer,
  studentAnswerDate,
  teacherComment,
  teacherCommentDate,
  renderFilePreview,
  isAnswerBlocked,
}) => (
  <>
    <div css={s.taskAnswer}>
      <div css={s.taskAnswerContainer}>
        <div css={s.taskAnswerHeader}>
          <div css={s.taskAnswerUser}>{studentName}</div>
          <div css={s.taskAnswerDate}>
            {studentAnswerDate && format(studentAnswerDate, dateFormat)}
          </div>
        </div>
        <div css={s.taskAnswerText}>
          <Froala
            mode="preview"
            variant="review-answer"
            content={studentAnswer}
          />
        </div>
        <div css={s.taskAnswerImages}>
          {studentFiles &&
            Object.entries(studentFiles).map((item) =>
              renderFilePreview({ item })
            )}
        </div>
      </div>
      <div css={s.taskAnswerAvatar} position="right" />
    </div>

    {teacherComment && (
      <div css={s.taskAnswer} isAnswerBlocked={isAnswerBlocked}>
        <div
          css={s.taskAnswerContainer}
          status={
            isApproved
              ? 'approved'
              : isApproved === undefined || isApproved === ''
              ? 'not-checked'
              : 'reject'
          }
        >
          <div css={s.taskAnswerHeader}>
            <div css={s.taskAnswerUser}>{teacherName}</div>
            <div css={s.taskAnswerDate}>
              {teacherCommentDate && format(teacherCommentDate, dateFormat)}
            </div>
          </div>
          <div css={s.taskAnswerText}>
            <Froala
              mode="editor"
              variant="editor-answer"
              content={teacherComment}
            />
          </div>
          <div css={s.taskAnswerImages}>
            {teacherFiles &&
              Object.entries(teacherFiles).map((item) =>
                renderFilePreview({ item })
              )}
          </div>
        </div>
        <div css={s.taskAnswerAvatar} position="left" />
      </div>
    )}
  </>
);

const { string, bool, object, number } = PropTypes;

ReviewAnswer.propTypes = {
  studentName: string.isRequired,
  studentAnswer: string.isRequired,
  studentFiles: object,
  teacherFiles: object,
  teacherComment: string,
  teacherName: string,
  isApproved: bool,
  renderFilePreview: node,
  studentAnswerDate: number,
  teacherCommentDate: number,
  isAnswerBlocked: bool,
};

export default ReviewAnswer;
