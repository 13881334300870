/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { isLoggedIn } from '~/selectors/auth';

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/auth', search: `?goto=${props.location.pathname}` }}
        />
      )
    }
    {...rest}
  />
);

const { func, oneOfType, object, bool } = PropTypes;

PrivateRoute.propTypes = {
  component: oneOfType([func.isRequired, object]),
  isAuthenticated: bool.isRequired,
  location: object,
};

export default connect((state) => ({
  isAuthenticated: isLoggedIn(state),
}))(PrivateRoute);
