import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { Button, Text, SocialShare } from '~/components';
import OlympiadResult from '~/components/OlympiadResult';

import { combineStyles } from '~/utils/styles';
import * as selectors from '~/selectors';
import '~/styles/OlympiadSettings.scss';
import WelcomePreview from '../WelcomePreview';

const tabs = ['general', 'auth', 'result'];

const countMiddleLevelScore = ({ maxScore, minScore }) =>
  +minScore + Math.round((+maxScore - +minScore) / 2);

const OlympiadSettings = ({
  t,
  lessonId,
  changeTab,
  currentTab,
  selectedLevel,
  lessonName,
  totalTasksCount,
  lessonDescription,
  olympiadSettings: {
    levels,
    authDescription = {},
    authImage = {},
    authTitle,
    buttonText,
    descriptionInResult,
    partnersLogos = {},
  },
}) => (
  <div className="OlympiadSettings">
    <div className="panel">
      {tabs.map((item, index) => (
        <div className="tab" key={index}>
          <Button
            minimal
            text={t(`olympiadsettings.${item}`)}
            variant={['settings-tab', currentTab === item && 'active']}
            onClick={() => changeTab(item)}
          />
        </div>
      ))}
    </div>
    <div className="preview">
      {currentTab === 'general' && (
        <div className="general">
          <div className="lesson">
            {partnersLogos.isShow && (
              <div className={combineStyles('partners', 'olympiad')}>
                {partnersLogos.source ? (
                  <img src={partnersLogos.source} alt="" />
                ) : (
                  <Text
                    value="LOGO LOGO LOGO LOGO LOGO"
                    variant="settings-partners-preview"
                  />
                )}
              </div>
            )}
            <div className="name">
              <Text value={lessonName} variant="player-lesson-name" />
            </div>
            <div className="description">
              <Text
                value={lessonDescription}
                variant="player-lesson-description"
              />
            </div>
          </div>
        </div>
      )}
      {currentTab === 'auth' && (
        <div className="auth">
          <WelcomePreview
            lessonId={lessonId}
            authTitle={authTitle}
            authImage={authImage}
            buttonText={buttonText}
            partnersLogos={partnersLogos}
            authDescription={authDescription}
          />
        </div>
      )}
      {currentTab === 'result' && (
        <>
          <div className="general">
            <div className="lesson">
              {partnersLogos.isShow && (
                <div className={combineStyles('partners', 'olympiad')}>
                  {partnersLogos.source ? (
                    <img src={partnersLogos.source} alt="" />
                  ) : (
                    <Text
                      value="LOGO LOGO LOGO LOGO LOGO"
                      variant="settings-partners-preview"
                    />
                  )}
                </div>
              )}
              <div className="name">
                <Text value={lessonName} variant="player-lesson-name" />
              </div>
              {descriptionInResult && (
                <div className="description">
                  <Text
                    value={lessonDescription}
                    variant="player-lesson-description"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="result">
            <OlympiadResult
              selectedLevel={levels[selectedLevel]}
              correctTasksCount={Math.min(
                totalTasksCount,
                countMiddleLevelScore(levels[selectedLevel])
              )}
              bottom={<SocialShare previewMode />}
              totalTasksCount={totalTasksCount}
            />
          </div>
        </>
      )}
    </div>
  </div>
);

const { func, string, object, number } = PropTypes;

OlympiadSettings.propTypes = {
  t: func.isRequired, // Функция перевода
  lessonId: string, // ID урока
  changeTab: func.isRequired, // Функция смены вкладок
  currentTab: string, // Текущая вкладка
  lessonName: string, // Имя урока
  totalTasksCount: number, // Общее количество заданий
  selectedLevel: number,
  olympiadSettings: object, // Объект данных настройки олимпиады
  lessonDescription: string, // Описание урока
};

OlympiadSettings.defaultProps = {
  lessonId: undefined,
  currentTab: 'general',
  lessonName: undefined,
  totalTasksCount: undefined,
  olympiadSettings: {
    authDescription: {},
    authImage: {},
    partnersLogos: {},
    levels: [],
  },
  lessonDescription: undefined,
};

const mapStateToProps = (state) => {
  const { lessonId } = state.settings;
  const lessonName = selectors.builder.lesson.getName(lessonId, state);
  const lessonDescription = selectors.builder.lesson.getDescription(
    lessonId,
    state
  );
  return {
    lessonId,
    lessonName,
    lessonDescription,
  };
};

export default compose(
  withTranslation('containers'),
  connect(
    mapStateToProps,
    undefined
  )
)(OlympiadSettings);
