import { takeLatest, put, select } from 'redux-saga/effects';

import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import { apiRequest } from '~/sagas/request';
import { REQUEST_NAME_LESSON_PUBLISH } from '~/appConstants';

/**
 * Публикация урока
 */
export default function* watchLessonPublish() {
  yield takeLatest(BUILDER.LESSON.PUBLISH, function*(action) {
    const { id } = action.payload;
    const data = { lessonId: id };
    const isLessonUpdated = yield select(
      selectors.builder.lesson.hasUnpublishedChanges(id)
    );
    if (!isLessonUpdated) {
      yield put(
        actions.notice.show('notice.lessonAlreadyPublished', 'success')
      );
      return;
    }

    const response = yield apiRequest('publish', data, 'post', {
      name: REQUEST_NAME_LESSON_PUBLISH,
    });

    if (!response) {
      yield put(actions.builder.lesson.publishError(id));
      return;
    }

    const {
      error,
      payload: { publications, publicationsIds },
    } = response.data;

    if (error) {
      yield put(actions.builder.lesson.publishError(id));
      return;
    }

    yield put(
      actions.builder.lesson.publishSuccess(id, publications, publicationsIds)
    );
  });
}
