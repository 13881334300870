import { set, omit, update, curry } from 'lodash/fp';
import { toggle } from '../../../utils/array';
import * as EXAM from '../../../constants/builder/block/exam';

export const defaultState = {
  question: undefined, // Текст вопроса
  description: undefined, // Описание
  correctAnswers: {}, // Правильные ответы
  disorderlyCorrectAnswersIds: [], // ID ответов типа - "произвольная последовательность"
};

const examReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EXAM.CHANGE_QUESTION: {
      const { newQuestion } = action.payload;
      return set('question', newQuestion, state);
    }

    case EXAM.CHANGE_DESCRIPTION: {
      const { newDescription } = action.payload;
      return set('description', newDescription, state);
    }

    case EXAM.ADD_CORRECT_ANSWER: {
      const { answerId } = action.payload;
      return set(['correctAnswers', answerId], '', state);
    }

    case EXAM.CHANGE_CORRECT_ANSWER: {
      const { answerId, newAnswer } = action.payload;
      return set(['correctAnswers', answerId], newAnswer, state);
    }

    case EXAM.REMOVE_CORRECT_ANSWER: {
      const { answerId } = action.payload;
      return update('correctAnswers', omit(answerId), state);
    }

    case EXAM.TOGGLE_CORRECT_ANSWER_TYPE: {
      const { answerId } = action.payload;
      return update('disorderlyCorrectAnswersIds', toggle(answerId), state);
    }

    default:
      return state;
  }
};

export default curry(examReducer);
