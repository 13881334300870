import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { pure, withProps, withHandlers, withState } from 'recompose';
import { Icon, Text, Switch, Hyperlink, LinesTruncate } from '~/components';
import { combineStyles } from '../../utils/styles';
import withMobileDetect from '~/hocs/withMobileDetect';
import Uploader from '~/containers/Uploader';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import '../../styles/Block/Document.scss';

const Document = ({
  t,
  source,
  blockId,
  isMobile,
  onChange,
  fileName,
  extension,
  inEditMode,
  isProtected,
  inFullSreen,
  inPreviewMode,
  changeFileName,
  toggleFullScreen,
  toggleProtection,
}) => (
  <div
    className={combineStyles('Document', [
      inPreviewMode && 'in-player',
      isMobile && 'mobile',
    ])}
  >
    {source ? (
      <div className="content">
        {inPreviewMode && extension === 'html' && (
          <button
            type="button"
            onClick={toggleFullScreen}
            className={inFullSreen ? 'full-screen' : ''}
          >
            {inFullSreen ? 'Закрыть' : 'На весь экран'}
           </button>
        )}
        {extension === 'html' ? (
          <iframe
            src={source}
            width="1000px"
            title="presentation"
            height="600px"
            className={inFullSreen ? 'full-screen' : ''}
            frameBorder="0"
            allowFullScreen
          />
        ) : ['ppt', 'pptx'].includes(extension) ? (
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=https:${source}`}
            width="1000px"
            height="600px"
            title="presentation"
            frameBorder="0"
          />
        ) : (
          <div className="row">
            <div className="icon">
              <Icon name={`osh-${extension}`} variant="document" />
            </div>
            <div className="name">
              {inPreviewMode ? (
                <LinesTruncate
                  text={fileName}
                  maxLines={3}
                  variant="document-name"
                />
              ) : (
                <Text
                  editable
                  multiline
                  value={fileName}
                  onChange={changeFileName}
                  variant="document-name"
                  placeholder={t('document.enterFileName')}
                />
              )}
            </div>
          </div>
        )}
        {/* {inPreviewMode && (
          <div className="fullsize">
            <Hyperlink
              newTab
              href={
                extension === 'pdf'
                  ? `https:${source}`
                  : `https://view.officeapps.live.com/op/embed.aspx?src=https:${source}`
              }
              variant="document-fullsize"
            />
          </div>
        )} */}
        {!['ppt', 'pptx', 'html'].includes(extension) && (
          <div className="actions">
            <div className="buttons">
              <div className="button">
                <Hyperlink
                  newTab
                  text={t('document.show')}
                  href={
                    extension === 'pdf'
                      ? `https:${source}`
                      : `https://view.officeapps.live.com/op/embed.aspx?src=https:${source}`
                  }
                  variant="document"
                />
              </div>
              {(inEditMode || !isProtected) && (
                <div className="button">
                  <Hyperlink
                    download
                    href={source}
                    text={t('document.download')}
                    variant="document"
                    newTab={extension === 'pdf'}
                  />
                </div>
              )}
            </div>
            {inEditMode && (
              <div className="protection">
                <Switch
                  label="Запретить скачивание"
                  checked={isProtected}
                  onChange={toggleProtection}
                />
              </div>
            )}
          </div>
        )}
      </div>
    ) : inEditMode ? (
      <div className="uploader">
        <Uploader
          withFileName
          uploadType="block"
          icon="osh-documentupload"
          title={t('document.uploadDocument')}
          blockId={blockId}
          filePath={[blockId, 'content']}
          onURLChange={onChange}
          fileType="document"
          subtitle={t('document.subtitle')}
        />
      </div>
    ) : inPreviewMode ? (
      <div className="empty">
        <div className="icon">
          <Icon name="osh-documentupload" />
        </div>
        <div className="text">
          <Text value={t('document.notLoaded')} variant="empty-block-text" />
        </div>
      </div>
    ) : (
      undefined
    )}
  </div>
);

const { bool, string, func } = PropTypes;

Document.propTypes = {
  t: func.isRequired, // Функция перевода
  source: string, // URL
  blockId: string.isRequired, // ID блока
  fileName: string, // Имя файла
  onChange: func.isRequired, // При изменении блока
  isMobile: bool.isRequired, // При изменении блока
  extension: string, // Расширение файла
  inEditMode: bool, // В режиме редактирования?
  inFullSreen: bool.isRequired, // Полноэкранный режим
  isProtected: bool, // Скачивание файла запрещено?
  inPreviewMode: bool, // В режиме просмотра/выполнения?
  changeFileName: func, // Изменить имя файла
  toggleFullScreen: func.isRequired, // Переключение полноэкранного режима
  toggleProtection: func, // Изменение статуса защиты от скачивания
};

Document.defaultProps = {
  source: undefined,
  fileName: undefined,
  extension: undefined,
  inEditMode: undefined,
  inPreviewMode: undefined,
  changeFileName: undefined,
};

const mapStateToProps = (
  state,
  { blockId, source, inPlayer, inPresentation }
) => {
  const fileName = inPlayer
    ? selectors.player.getContentFileName(blockId, state)
    : inPresentation
      ? selectors.presentation.getDocumentFileName(blockId, state)
      : selectors.builder.block.getContentFileName(blockId, state);
  const isProtected = inPlayer
    ? selectors.player.getContentIsProtected(blockId, state)
    : inPresentation
      ? selectors.presentation.getDocumentIsProtected(blockId, state)
      : selectors.builder.block.getContentIsProtected(blockId, state);
  const extension = source && source.match(/[^.]+$/)[0];

  return {
    fileName,
    extension,
    isProtected,
  };
};

const mapDispatchToProps = {
  changeFileName: actions.builder.block.document.changeFileName,
  toggleProtection: actions.builder.block.document.toggleProtection,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation('containersBlock'),
  withState('inFullSreen', 'setInFullSreen', false),
  withMobileDetect,
  pure,
  withProps(
    ({
      inPlayer,
      inBuilder,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
    })
  ),
  withHandlers({
    toggleFullScreen: ({ inFullSreen, setInFullSreen }) => setInFullSreen(!inFullSreen),
    onChange: ({ onChange }) => (newURL) => onChange({ source: newURL }),
    toggleProtection: ({ toggleProtection, blockId, isProtected }) => () =>
      toggleProtection({ blockId, isEnable: !isProtected }),
    changeFileName: ({ changeFileName, blockId }) => (fileName) =>
      changeFileName({ blockId, fileName }),
  })
);

export default enhance(Document);
