import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Status from './Status';
import Button from '~/components/Button';
import * as selectors from '~/selectors';
import * as s from './styles/NotFound.styles';

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation('containers');
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const userRole = useSelector(selectors.auth.getUserRole);
  const urlName =
    userRole === 'teacher'
      ? 'teach'
      : userRole === 'student'
      ? 'study'
      : 'auth';

  return (
    <Status code={404}>
      <div css={s.container}>
        <div css={s.errorImg} />
        <h1 css={s.errorCode}>404</h1>
        <div css={s.errorText}>{t('notFound.pageNotFound')}</div>
        {isLoggedIn && (
          <div css={s.errorButton}>
            <Button
              text={t('notFound.goToBack')}
              large
              variant="auth-send"
              onClick={() => history.push(`/${urlName}`)}
            />
          </div>
        )}
      </div>
    </Status>
  );
};

export default NotFound;
