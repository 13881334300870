import React from 'react';
import PropTypes from 'prop-types';

import 'moment/locale/ru'; // eslint-disable-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { get, head } from 'lodash/fp';
import MomentLocaleUtils from 'react-day-picker/moment'; // eslint-disable-line import/no-extraneous-dependencies
import { DateRangeInput, TimePrecision } from '@blueprintjs/datetime';
import { Position } from '@blueprintjs/core';
import { pure } from 'recompose';

import { combineStyles } from '../utils/styles';
import '../styles/DateRange.scss';

// Локализация инпута с датами
const WEEKDAYS_SHORT = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};
const localeUtils = {
  ...MomentLocaleUtils,
  // eslint-disable-next-line unicorn/prefer-string-slice
  formatWeekdayShort: (i, locale) => WEEKDAYS_SHORT[locale.substring(0, 2)][i],
};

const DateRange = ({ label, value, variant, onChange }) => {
  const { t, i18n } = useTranslation('components');

  return (
    <div className={combineStyles('DateRange', [variant, label && 'label'])}>
      <DateRangeInput
        value={value}
        locale={head(get('languages', i18n))}
        format="DD/MM/YYYY HH:mm"
        formatDate={(date) => date.toLocaleString()}
        parseDate={(string) => new Date(string)}
        onChange={onChange}
        localeUtils={localeUtils}
        endInputProps={{ placeholder: t('input.endOfAccess') }}
        startInputProps={{ placeholder: t('input.startOfAccess') }}
        outOfRangeMessage={t('input.unavailableDate')}
        invalidDateMessage={t('input.wrongFormat')}
        overlappingDatesMessage={t('input.collapseDates')}
        timePrecision={TimePrecision.MINUTE}
        popoverProps={{
          usePortal: false,
          position: Position.TOP,
        }}
      />
    </div>
  );
};

const { string, oneOfType, func, array } = PropTypes;

DateRange.propTypes = {
  label: string, // Метка у инпута
  value: array, // Значение инпута с тэгами или с датами
  variant: oneOfType([array, string]), // Вариант исполнения
  onChange: func, // Вызывается при изменении значения инпута
};

export default pure(DateRange);
