import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { connect /* useSelector */ } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
// import { format } from 'date-fns';

import ProfilePlan from './ProfilePlan';
import ProfileHistory from './ProfileHistory';
import ProfileIntegration from './ProfileIntegration';
import ProfileSecurity from './ProfileSecurity';
// import plansDataMap from '~/plansDataMap';
import { Tabs, Text } from '~/components';
import * as selectors from '~/selectors';
import '../../../styles/Profile.scss';
import {
  AUTH_ROLE_STUDENT,
  // PLAN_NAME_FREE,
  // SUBSCRIPTION_STATUS_INACTIVE,
} from '~/appConstants';

const Profile = ({
  t,
  userName,
  userEmail,
  userPlan,
  userRole,
  // activeCourses,
  // subscriptionStatus,
}) => {
  // const subscription = useSelector(selectors.auth.getUserSubscription);
  // const nextPaymentAt =
  //   subscription.nextPaymentAt && new Date(subscription.nextPaymentAt * 1000);

  const [activeTab, setActiveTab] = useState('integration');

  // const planData = plansDataMap[userPlan];

  const isStudent = userRole === AUTH_ROLE_STUDENT;
  const tabsList = isStudent ? ['security'] : ['integration', 'security'];

  useEffect(() => {
    setActiveTab(isStudent ? 'security' : 'integration');
  }, [isStudent]);

  return (
    <div className="Profile">
      <div className="info">
        <div className="inner">
          <div className="user">
            <div className="avatar" />
            <div className="user-info">
              <div className="name">
                <Text value={userName} />
              </div>
              <div className="email">
                <Text value={userEmail} />
              </div>
            </div>
          </div>

          {/* {!isStudent && (
            <div className="plan">
              <div className="cell">
                <div className="value">
                  <Text value={planData.title} />
                </div>
                <div className="label">
                  <Text value={t('profile.myPlan')} />
                </div>
              </div>
              <div className="cell">
                <div className="value">
                  <Text
                    value={
                      planData.coursesCount
                        ? `${activeCourses}/${planData.coursesCount}`
                        : '-'
                    }
                  />
                </div>
                <div className="label">
                  <Text value={t('profile.runningCourses')} />
                </div>
              </div>
              <div className="cell">
                <div className="value">
                  <Text
                    value={
                      userPlan === PLAN_NAME_FREE
                        ? '∞'
                        : format(nextPaymentAt, 'DD.MM.YY')
                    }
                  />
                </div>
                <div className="label">
                  <Text
                    value={
                      userPlan === PLAN_NAME_FREE ||
                      subscriptionStatus === SUBSCRIPTION_STATUS_INACTIVE
                        ? t('profile.paidUntil')
                        : t('profile.nextDebit')
                    }
                  />
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <div className="content">
        <div className="inner">
          <Tabs
            renderActiveTabPanelOnly
            selectedTabId={activeTab}
            onChange={(id) => setActiveTab(id)}
          >
            {tabsList.map((tabName) => (
              <Tabs.Tab
                key={tabName}
                id={tabName}
                title={
                  <Text
                    value={t(`profile.${tabName}`)}
                    variant={['profile-tab', tabName === activeTab && 'active']}
                  />
                }
                panel={
                  tabName === 'plan' ? (
                    <ProfilePlan userPlan={userPlan} />
                  ) : tabName === 'history' ? (
                    <ProfileHistory />
                  ) : tabName === 'integration' ? (
                    <ProfileIntegration />
                  ) : tabName === 'security' ? (
                    <ProfileSecurity />
                  ) : null
                }
              />
            ))}

            <Tabs.Expander />
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const { func, string /* number */ } = PropTypes;

Profile.propTypes = {
  t: func.isRequired,
  userName: string,
  userEmail: string,
  userPlan: string.isRequired,
  userRole: string.isRequired,
  // activeCourses: number.isRequired,
  // subscriptionStatus: string,
};

const mapStateToProps = (state) => {
  const userName = selectors.auth.getUserName(state);
  const userEmail = selectors.auth.getUserEmail(state);
  const userRole = selectors.auth.getUserRole(state);
  const {
    planName: userPlan,
    status: subscriptionStatus,
  } = selectors.auth.getUserSubscription(state);
  const activeCourses = selectors.builder.getActiveCoursesCount(state);

  return {
    userName,
    userPlan,
    userEmail,
    userRole,
    activeCourses,
    subscriptionStatus,
  };
};

export default compose(
  pure,
  withTranslation('screens'),
  connect(mapStateToProps, null)
)(Profile);
