import { set } from 'lodash/fp';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as PLAYER from '../../constants/player';

const defaultState = {
  theme: 'light', // Цветовая тема плеера
  fontSize: 'small', // Размер шрифта в плеере
  inSafeMode: false, // Режим отображения плеера
};

const ui = (state = defaultState, action) => {
  switch (action.type) {
    case PLAYER.TOGGLE_MODE: {
      return set('inSafeMode', !state.inSafeMode, state);
    }

    case PLAYER.CHANGE_THEME: {
      const { theme } = action.payload;
      return set('theme', theme, state);
    }

    case PLAYER.CHANGE_FONT_SIZE: {
      const { fontSize } = action.payload;
      return set('fontSize', fontSize, state);
    }

    default:
      return state;
  }
};

export default persistReducer({ key: 'player.theme', storage }, ui);
