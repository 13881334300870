import * as LESSON from './lesson';
import * as FOLDER from './folder';
import * as COURSE from './course';
import * as BLOCK from './block';
import * as PAGE from './page';

export const SHOW_HINTS = '@builder/Показать подсказки';
export const SEND_SECRET = '@builder/Отправка секрета на сервер';
export const SEND_SECRET_ERROR = '@builder/Ошибка отправки секрета';
export const SEND_SECRET_SUCCESS = '@builder/Секрет успешно отправлен';
export const TOGGLE_PROFILE = '@builder/Показать окно профиля';
export const DOWNLOAD_LESSON = '@builder/Загрузка урока';
export const DOWNLOAD_LESSONS =
  '@builder/Загрузить все уроки созданные пользователем';
export const DOWNLOAD_LESSON_ERROR = '@builder/Ошибка загрузки урока';
export const DOWNLOAD_LESSONS_ERROR =
  '@builder/Ошибка загрузки урока уроков пользователя';
export const DOWNLOAD_LESSON_SUCCESS = '@builder/Урок успешно загружен';
export const DOWNLOAD_LESSONS_SUCCESS =
  '@builder/Уроки пользователя успешно загружены';

export const DOWNLOAD_COURSES =
  '@builder/Загрузить все курсы созданные пользователем';
export const DOWNLOAD_COURSES_ERROR =
  '@builder/Ошибка загрузки курсов пользователя';
export const DOWNLOAD_COURSES_SUCCESS =
  '@builder/Курсы пользователя успешно загружены';
export const DOWNLOAD_COURSE = '@builder/Загрузка курса';
export const DOWNLOAD_COURSE_ERROR = '@builder/Ошибка загрузки курса';
export const DOWNLOAD_COURSE_SUCCESS = '@builder/Курс успешно загружен';

export { PAGE, BLOCK, LESSON, FOLDER, COURSE };
