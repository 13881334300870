import React from 'react';
import PropTypes from 'prop-types';

import {
  NOTIFICATION_TYPE_ANSWER_REVIEW,
  NOTIFICATION_TYPE_TUTOR_INVITE,
} from '~/appConstants';
import TutorInviteNotification from '~/components/Notification/types/TutorInviteNotification';
import AnswerReviewNotification from '~/components/Notification/types/AnswerReviewNotification';

const getComponent = (type) => {
  switch (type) {
    case NOTIFICATION_TYPE_ANSWER_REVIEW: {
      return AnswerReviewNotification;
    }
    case NOTIFICATION_TYPE_TUTOR_INVITE: {
      return TutorInviteNotification;
    }
    default: {
      return null;
    }
  }
};

const NotificationItem = ({ id, type, date, isUnread, data }) => {
  const Component = getComponent(type);
  return <Component id={id} date={date} isUnread={isUnread} data={data} />;
};

const { string, object, number, oneOf, bool } = PropTypes;

NotificationItem.propTypes = {
  id: string.isRequired,
  type: oneOf([NOTIFICATION_TYPE_ANSWER_REVIEW, NOTIFICATION_TYPE_TUTOR_INVITE])
    .isRequired,
  date: number.isRequired,
  isUnread: bool.isRequired,
  data: object,
};

export default NotificationItem;
