/* eslint-disable fp/no-mutation */
import { createAction, createErrorAction } from '~/utils/actions';

export const SUBSCRIBE = '@payment/Оплатить';
export const SUBSCRIBE_ERROR = '@payment/Ошибка оплаты';
export const SUBSCRIBE_SUCCESS = '@payment/Оплата прошла успешно';

export const CHANGE_PLAN = '@payment/Перейти на другой тариф';
export const CHANGE_PLAN_ERROR = '@payment/Ошибка перехода на другой тариф';
export const CHANGE_PLAN_SUCCESS =
  '@payment/Переход на другой тариф прошел успешно';

export const DISABLE_AUTO_PAYMENT = '@payment/Деактивировать подписку';
export const DISABLE_AUTO_PAYMENT_SUCCESS =
  '@payment/Деактивация подписки прошла успешно';
export const DISABLE_AUTO_PAYMENT_ERROR =
  '@payment/Ошибка деактивации подписки';

export const ENABLE_AUTO_PAYMENT = '@payment/Активировать подписку';
export const ENABLE_AUTO_PAYMENT_SUCCESS =
  '@payment/Активация подписки прошла успешно';
export const ENABLE_AUTO_PAYMENT_ERROR = '@payment/Ошибка активации подписки';

export const REQUEST_EXTRA_DAYS =
  '@payment/Запрос дополнительных дней при смене тарифа';
export const SET_EXTRA_DAYS =
  '@payment/Установка дополнительных дней при смене тарифа';

export const CHANGE_CARD = '@payment/Изменить карту оплаты';
export const CHANGE_CARD_SUCCESS = '@payment/Карта изменена успешно';
export const CHANGE_CARD_ERROR = '@payment/Ошибка изменения карты';

export const REMOVE_SUBSCRIPTION = '@payment/Запрос удаления подписки';
export const REMOVE_SUBSCRIPTION_SUCCESS =
  '@payment/Успешное удаление подписки';
export const REMOVE_SUBSCRIPTION_ERROR =
  '@payment/Ошибка запроса удаления подписки';

export const subscribePlan = ({ name }) => createAction(SUBSCRIBE, { name });
subscribePlan.toString = () => SUBSCRIBE;

export const subscribePlanSuccess = ({ planName }) =>
  createAction(SUBSCRIBE_SUCCESS, { planName });
subscribePlanSuccess.toString = () => SUBSCRIBE_SUCCESS;

export const subscribePlanError = createErrorAction(SUBSCRIBE_ERROR);
subscribePlanError.toString = () => SUBSCRIBE_ERROR;

export const changePlan = ({ name }) => createAction(CHANGE_PLAN, { name });
changePlan.toString = () => CHANGE_PLAN;

export const changePlanSuccess = ({ subscription }) =>
  createAction(CHANGE_PLAN_SUCCESS, { subscription });
changePlanSuccess.toString = () => CHANGE_PLAN_SUCCESS;

export const changePlanError = createErrorAction(CHANGE_PLAN_ERROR);
changePlanError.toString = () => CHANGE_PLAN_ERROR;

export const enableAutoPayment = () => createAction(ENABLE_AUTO_PAYMENT);
enableAutoPayment.toString = () => ENABLE_AUTO_PAYMENT;

export const enableAutoPaymentSuccess = () =>
  createAction(ENABLE_AUTO_PAYMENT_SUCCESS);
enableAutoPaymentSuccess.toString = () => ENABLE_AUTO_PAYMENT_SUCCESS;

export const enableAutoPaymentError = createErrorAction(
  ENABLE_AUTO_PAYMENT_ERROR
);
enableAutoPaymentError.toString = () => ENABLE_AUTO_PAYMENT_ERROR;

export const disableAutoPayment = () => createAction(DISABLE_AUTO_PAYMENT);
disableAutoPayment.toString = () => DISABLE_AUTO_PAYMENT;

export const disableAutoPaymentSuccess = () =>
  createAction(DISABLE_AUTO_PAYMENT_SUCCESS);
disableAutoPaymentSuccess.toString = () => DISABLE_AUTO_PAYMENT_SUCCESS;

export const disableAutoPaymentError = createErrorAction(
  DISABLE_AUTO_PAYMENT_ERROR
);
disableAutoPaymentError.toString = () => DISABLE_AUTO_PAYMENT_ERROR;

export const requestExtraDays = ({ planName }) =>
  createAction(REQUEST_EXTRA_DAYS, { planName });

export const setExtraDays = ({ extraDays }) =>
  createAction(SET_EXTRA_DAYS, { extraDays });

export const changeCard = () => createAction(CHANGE_CARD);
changeCard.toString = () => CHANGE_CARD;

export const changeCardSuccess = createErrorAction(CHANGE_CARD_SUCCESS);
changeCardSuccess.toString = () => CHANGE_CARD_SUCCESS;

export const changeCardError = createErrorAction(CHANGE_CARD_ERROR);
changeCardError.toString = () => CHANGE_CARD_ERROR;

export const removeSubscription = () => createAction(REMOVE_SUBSCRIPTION);
removeSubscription.toString = () => REMOVE_SUBSCRIPTION;

export const removeSubscriptionSuccess = ({ subscription }) =>
  createAction(REMOVE_SUBSCRIPTION_SUCCESS, { subscription });
removeSubscriptionSuccess.toString = () => REMOVE_SUBSCRIPTION_SUCCESS;

export const removeSubscriptionError = createErrorAction(
  REMOVE_SUBSCRIPTION_ERROR
);
removeSubscriptionError.toString = () => REMOVE_SUBSCRIPTION_ERROR;
