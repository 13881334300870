import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { compose, filter, map, uniq } from 'lodash/fp';
import { isEmail } from '../utils/validation';

export const filterEmails = (tags) =>
  compose(
    uniq,
    filter(isEmail),
    map((tag) => tag.toLowerCase())
  )(tags);


/** Экспортирование результатов курса в XLSX */
export const exportResults = (results, publications, name) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Результаты прохождения курса')
  const style = {
    row: { font: { size: 15 } },
    header : {
      font: { bold: true, size: 15 },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EEEEEEEE' }
      }
    }
  }
  // eslint-disable-next-line fp/no-mutation
  worksheet.columns = [
    { header: 'Слушатель', key: 'user', width: 40, style: style.row },
    { header: 'Email', key: 'email', width: 40, style: style.row },
    { header: 'Пройдено уроков', key: 'completed', width: 40, style: style.row },
    { header: 'Время (мин)', key: 'time', width: 40, style: style.row },
    { header: 'Результат (%)', key: 'result', width: 40, style: style.row },
  ]
  const rows = results.map((result) => ({ 
    user: result.name,
    email: result.email,
    completed: `${result.completedResultsSize}/${publications.length}`,
    time: result.executionTime,
    result: result.percentResult * 100
  }))
  worksheet.addRows(rows)
  // eslint-disable-next-line fp/no-mutation
  worksheet.getRow(1).font = style.header.font
  // eslint-disable-next-line fp/no-mutation
  worksheet.getRow(1).fill = style.header.fill
  // eslint-disable-next-line more/no-then
  workbook.xlsx.writeBuffer({ base64: true }).then((data) => {
    saveAs(
      new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }), name || '?',
    )
  })
}