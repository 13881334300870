import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import withTheming from '~/hocs/withTheming';
import { Overlay, Text, Button, Link } from '../../components';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/UserWelcome.scss';

const UserWelcome = ({
  t,
  theme,
  isOpen,
  onClose,
  fontSize,
  onLogout,
  userName,
  inSafeMode,
  toggleMode,
}) => (
  <Overlay isOpen={isOpen && !!userName}>
    <div
      className={combineStyles('UserWelcome', [
        inSafeMode && 'safe-mode',
        inSafeMode && theme,
      ])}
    >
      <div className="title">
        <Text
          value={t('userwelcome.enter')}
          variant={combineStyles([
            'text-head-dark',
            'center',
            inSafeMode && fontSize,
            inSafeMode && theme,
          ])}
        />
      </div>
      <div className="image" />
      <Text
        value={`${t('userwelcome.signInAs')} ${userName}`}
        variant="continue-passing"
      />
      <div className="buttons">
        <Button
          text={t('userwelcome.continue')}
          variant={combineStyles(
            'player-welcome',
            inSafeMode && [fontSize, theme]
          )}
          onClick={onClose}
        />
        <Link
          text={t('userwelcome.signInAnother')}
          variant={combineStyles(
            'player-logout',
            inSafeMode && [fontSize, theme]
          )}
          onClick={() => {
            onLogout();
            onClose();
          }}
        />
      </div>
      <div className="bottom-button">
        <Button
          text={
            inSafeMode
              ? t('userwelcome.normalVersion')
              : t('userwelcome.visuallyImpairedVersion')
          }
          variant={combineStyles(
            'player-toggle-mode',
            inSafeMode && 'safe-mode'
          )}
          icon="eye-open"
          onClick={toggleMode}
        />
      </div>
    </div>
  </Overlay>
);

const { bool, func, string } = PropTypes;

UserWelcome.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string.isRequired, // Тема внешнего вида плеера
  fontSize: string.isRequired, // Размер шрифта в плеере
  inSafeMode: bool.isRequired, // Плеер в режиме для ОВЗ?
  onLogout: func.isRequired,
  isOpen: bool.isRequired, // Окно открыто?
  userName: string, // Имя пользователя
  onClose: func.isRequired,
  toggleMode: func.isRequired, // Переключение мода внешнего вида плеера
};

export default compose(
  withTheming,
  withTranslation('containersPlayer')
)(UserWelcome);
