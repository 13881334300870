import { set, compose, assign } from 'lodash/fp';

import * as PAGE from '../constants/builder/page';
import * as CLYPBOARD from '../constants/clypboard';

export const defaultState = {
  pages: {},
  blocks: {},
};

const clypboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLYPBOARD.COPY_PAGE: {
      const {
        meta: { clonedPage, clonedBlocks },
      } = action;
      return compose(
        set('blocks', clonedBlocks),
        set('pages', clonedPage)
      )(state);
    }

    case PAGE.PASTE: {
      return assign({}, defaultState);
    }

    default:
      return state;
  }
};

export default clypboardReducer;
