import { get } from 'lodash/fp';

export const getResultByLessonId = (lessonId, state) => {
  const resultId = get(['course', 'resultsByLessonId', lessonId])(state);
  return get(['course', 'results', resultId])(state);
};
export const getCourse = get(['course', 'course']);
export const getResults = get(['course', 'results']);
export const getPublication = (publicationId) =>
  get(['course', 'publications', publicationId]);
