import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { pure } from 'recompose';
import { useTranslation } from 'react-i18next';
import {
  MODAL_NAME_PLANS,
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM10,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
} from '~/appConstants';
import PlanContent from '../components/PlanContent';
import Emoji, { SAD } from '~/components/Emoji';
import { Button, Hyperlink, Text } from '~/components';
import { useQueryParameters } from '~/hooks/useQueryParameters';
import { usePaymentNotice } from '~/hooks/usePaymentNotice';
import Plans from '../Plans';
import ChangeCard from './ChangeCard';

import '../../../styles/Profile.scss';
import * as selectors from '~/selectors';
import { removeSubscription } from '~/actions/payment';
import * as s from './styles/ProfilePlan.styles';
import Icon from '~/components/Icon';
import RemovePaymentConfirm from '~/screens/Teach/Profile/RemovePaymentConfirm';
import AutoPaymentSwitch from '~/screens/Teach/Profile/AutoPaymentSwitch';

const plansToDisplay = [
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
  PLAN_NAME_NUM10,
  // PLAN_NAME_CUSTOM,
];

const ProfilePlan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');

  const [
    isRemovePaymentConfirmVisible,
    setRemovePaymentConfirmVisible,
  ] = useState(false);

  const [isChangeCardModalVisible, setChangeCardModalVisible] = useState(false);

  const { planName: userPlan, pan: userCard, nextPaymentAt } = useSelector(
    selectors.auth.getUserSubscription
  );

  const [selectedPlan, selectPlan] = useState(PLAN_NAME_NUM1);
  const {
    parameters: { modal },
    removeQueryParam,
    addQueryParam,
  } = useQueryParameters();
  const disabledPlans = [
    // PLAN_NAME_CUSTOM,
    userPlan !== PLAN_NAME_FREE && PLAN_NAME_FREE,
  ];
  const daysBeforeExpire = Math.floor(
    Math.abs(new Date(nextPaymentAt) - new Date() / 1000) / 86400
  );

  const { paymentNotice } = usePaymentNotice();

  useEffect(() => {
    paymentNotice();
  }, [paymentNotice]);

  return (
    <>
      <RemovePaymentConfirm
        isOpen={isRemovePaymentConfirmVisible}
        message={[t('profile.cardRemoveText1'), t('profile.cardRemoveText2')]}
        acceptText={t('profile.paymentInfoRemove')}
        cancelText={t('profile.cardRemoveCancel')}
        onAccept={() => {
          setRemovePaymentConfirmVisible(false);
          dispatch(removeSubscription());
        }}
        onCancel={() => setRemovePaymentConfirmVisible(false)}
      />
      <div className="ProfilePlan">
        {daysBeforeExpire < 4 && (
          <div className="alert">
            <div className="icon">
              <Emoji symbol={SAD} />
            </div>
            <div className="text">
              <Text value={t('profile.expireAlert')} />
            </div>
          </div>
        )}

        <Plans
          isOpen={modal === MODAL_NAME_PLANS}
          close={() => removeQueryParam('modal')}
          goBack={() => removeQueryParam('modal')}
          defaultSelectedPlan={selectedPlan}
        />

        <ChangeCard
          title={t('changeCard.changeCardTitle')}
          description={t('subscriptionPlans.changeCardDescription')}
          isOpen={isChangeCardModalVisible}
          close={() => setChangeCardModalVisible(false)}
          goBack={() => setChangeCardModalVisible(false)}
        />

        <div className="plans">
          {plansToDisplay.map((name) => (
            <div className="plan" key={name}>
              <PlanContent planName={name} />
              {name === userPlan && name !== PLAN_NAME_FREE ? (
                <div className="button">
                  <AutoPaymentSwitch />
                </div>
              ) : (
                name !== PLAN_NAME_FREE &&
                !disabledPlans.includes(name) && (
                  <div className="button">
                    <Button
                      onClick={() => {
                        selectPlan(name);
                        addQueryParam(`modal=${MODAL_NAME_PLANS}`);
                      }}
                      text={t('profile.select')}
                      variant="select-plan"
                    />
                  </div>
                )
              )}
            </div>
          ))}
        </div>

        <div className="more">
          <Hyperlink
            newTab
            href="https://coreapp.ai/docs/plans.pdf"
            text={t('profile.moreAboutPlans')}
            variant="more-about-plans"
          />
        </div>

        {userCard && (
          <div css={s.changePlan}>
            <div css={s.cardLeft}>
              <h3>{t('profile.paymentInfoTitle')}</h3>
              <div css={s.cardIcon}>
                <Icon name="credit-card" size={38} color="#d0d3d7" />
              </div>
              <div css={s.cardNumber}>
                <Text value={userCard} />
              </div>
            </div>
            <div css={s.cardRight}>
              <div css={s.cardButton}>
                <Button
                  minimal
                  variant="transparent-button"
                  text={t('profile.paymentInfoChange')}
                  onClick={() => setChangeCardModalVisible(true)}
                />
              </div>
              <div css={s.cardButton}>
                <Button
                  minimal
                  variant="transparent-button"
                  text={t('profile.paymentInfoRemove')}
                  onClick={() => setRemovePaymentConfirmVisible(true)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="info">
          <div className="column">
            <div className="title">
              <Text value={t('profile.infoProlong')} />
            </div>
            <div className="text">
              <Text value={t('profile.infoProlongText')} />
            </div>
          </div>
          <div className="column">
            <div className="title">
              <Text value={t('profile.infoChangePlan')} />
            </div>
            <div className="text">
              <Text value={t('profile.infoChangePlanText')} />
              <Hyperlink
                newTab
                href="https://coreapp.ai"
                value={t('profile.infoChangePlanDetails')}
                variant="more-plans"
              />
            </div>
          </div>
          <div className="column">
            <div className="title">
              <Text value={t('profile.infoFree')} />
            </div>
            <div className="text">
              <Text value={t('profile.infoFreeText')} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default pure(ProfilePlan);
