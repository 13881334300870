import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withContext, withProps } from 'recompose';
import Input from '../components/Input';
import Button from '../components/Button';
import Blocks from '~/containers/Blocks';
import { downloadLesson } from '~/actions/player';
import * as s from './styles/PrintPage.styles';

const PrintPage = ({ t, pageId, lessonId, publication }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line more/no-window
  const printPage = () => window.print();

  useEffect(() => {
    dispatch(downloadLesson({ lessonId, publication }));
  }, [dispatch, lessonId, publication]);

  return (
    <div css={s.container}>
      <div css={s.header}>
        <Button
          icon="osh-print"
          text={t('printpage.print')}
          variant="print-page"
          onClick={printPage}
        />
      </div>
      <div css={s.pupil}>
        <Input
          large
          label={t('printpage.name')}
          variant="in-print-name"
          readonly
        />
        <Input
          large
          label={t('printpage.date')}
          variant="in-print-date"
          readonly
        />
        <Input
          large
          label={t('printpage.grade')}
          variant="in-print-class"
          readonly
        />
      </div>
      <div css={s.page}>
        <Blocks pageId={pageId} lessonId={lessonId} inPrintPage />
      </div>
      <div css={s.footer}>
        <Button
          icon="osh-print"
          text={t('printpage.print')}
          variant="print-page"
          onClick={printPage}
        />
      </div>
    </div>
  );
};

const { string, bool, func, number } = PropTypes;

PrintPage.propTypes = {
  t: func.isRequired, // Функция перевода
  pageId: string.isRequired, // ID страницы для печати
  lessonId: string.isRequired, // ID урока
  publication: number,
};

export default compose(
  withTranslation('containers'),
  withProps(({ match: { params: { pageId, lessonId, publication } } }) => ({
    pageId,
    lessonId,
    publication,
  })),
  withContext({ inPlayer: bool.isRequired }, () => ({
    inPlayer: true,
  }))
)(PrintPage);
