import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import { compose, trim, size } from 'lodash/fp';
import { connect } from 'react-redux';
import { pure } from 'recompose';
import { useTranslation } from 'react-i18next';
import { Button, Text, Icon } from '../components';
import { combineStyles } from '../utils/styles';
import * as actions from '../actions';
import '../styles/Messenger.scss';

const Messenger = ({
  messages,
  resultId,
  userName,
  inPlayer,
  lessonId,
  sendMessage,
  resultIndex,
  showMessenger,
}) => {
  const { t } = useTranslation('containers');
  const [newMessage, changeMessage] = useState('');
  const messageList = useRef(null);

  const sendAndClear = () => {
    sendMessage({
      resultId,
      newMessage,
      lessonId,
      resultIndex,
      messageIndex: messages.length,
    });
    changeMessage('');
  };

  useEffect(() => {
    const {
      current: { scrollHeight },
    } = messageList;
    // eslint-disable-next-line fp/no-mutation
    messageList.current.scrollTop = scrollHeight;
  });

  return (
    <div className="Messenger">
      <div className="header">
        <Button
          minimal
          icon="arrow-right"
          variant="messenger-back"
          onClick={() => showMessenger(false)}
        />
        <Text value={userName} variant="username-in-messenger" />
      </div>
      <div className="messenger">
        <div className="messages" ref={messageList}>
          {!messages.length && (
            <div className="empty">
              <Icon name="osh-comment" variant="empty-messenger" />
              <div className="text">{t('messenger.empty')}</div>
            </div>
          )}
          {messages.map((item, index) => (
            <div
              className={combineStyles('message', !messages.viewed && 'unread')}
              key={index}
            >
              <div className="header">
                <div className="author">
                  <Text value={item.author} variant="message-author" />
                </div>
                <div className="date">
                  <Text
                    value={formatDate(item.lastModified * 1000, 'DD.MM.YYYY')}
                    variant="message-date"
                  />
                </div>
              </div>
              <div className="text">
                <Text value={item.message} variant="message-text" />
              </div>
            </div>
          ))}
        </div>
        {!inPlayer && (
          <div className="new">
            <Text
              value={newMessage}
              variant="messenger-input"
              editable
              multiline
              maxLines={5}
              onConfirm={size(trim(newMessage)) && sendAndClear}
              onChange={changeMessage}
              placeholder={t('messenger.enterMessage')}
            />
            <Button
              minimal
              disabled={!size(trim(newMessage))}
              icon="circle-arrow-right"
              variant="messenger-send"
              onClick={sendAndClear}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const { string, func, array, bool, number } = PropTypes;

Messenger.propTypes = {
  lessonId: string,
  inPlayer: bool,
  messages: array,
  resultId: string,
  userName: string,
  sendMessage: func,
  resultIndex: number,
  showMessenger: func,
};

Messenger.defaultProps = {
  messages: [],
  inPlayer: false,
};

const mapStateToProps = (state, ownProps) => {
  const { newMessage } = ownProps;
  return { newMessage };
};

const mapDispatchToProps = {
  sendMessage: actions.results.sendMessage,
};

export default compose(
  pure,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Messenger);
