import { css } from 'styled-components';

export const container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 11;

  ${(props) =>
    props.theme.isMobile
      ? css`
          height: auto;
        `
      : css`
          height: 60px;
          padding: 0 16px;
          flex-flow: row nowrap;
        `}

  ${(props) =>
    props.theme === 'dark' &&
    css`
      background: none;
      padding: 0;
    `}
`;

export const leftPart = css`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
          justify-content: space-between;
        `
      : css`
          justify-content: flex-start;
        `}

  > .leftlink {
    &.-mobile {
      padding-left: 16px;
    }
  }
`;

export const logo = css`
  height: 48px;

  ${(props) =>
    props.theme.isMobile
      ? css`
          margin-right: auto;
          margin-left: 16px;
        `
      : css`
          margin-right: 28px;
        `}
`;

export const logoImg = css`
  display: block;
  height: 48px;
`;

export const backlink = css`
  ${(props) =>
    props.theme.isMobile &&
    css`
      padding-left: 16px;
    `}
`;

export const links = css`
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.theme.isMobile &&
    css`
      order: 10;
      width: 100%;
      margin-top: -8px;
    `}
`;

export const rightPart = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}
`;

export const menu = css`
  width: 100%;
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;
