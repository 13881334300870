import { concat } from 'lodash/fp';
import { call, put, take, takeEvery, getContext } from 'redux-saga/effects';
import * as UPLOADER from '../constants/uploader';
import * as actions from '../actions';
import uploadFileChannel from './channels/uploadFile';
import { send } from '~/actions/player/lesson';

/**
 * Загрузка файла на сервер
 * https://decembersoft.com/posts/file-upload-progress-with-redux-saga/

 * TODO:
 *  - Использовать эндпоинт для загрузки всех
 *  - типов файлов а не только изображений
 */
function* uploadFile(
  objectId,
  file,
  filePath,
  fileType,
  uploadType,
  pathLastName,
  withFileName,
  inPlayer,
  isNoticeProgress
) {
  const channel = yield call(
    uploadFileChannel,
    `${process.env.API_URL}/upload/${fileType}`,
    file
  );

  while (true) {
    const { progress = 0, error, success, response } = yield take(channel);

    const i18n = yield getContext('i18n');

    if (error) {
      yield put(
        actions.uploader.uploadError(
          objectId,
          i18n.t('sagas:uploader.serverNoResponse'),
          filePath
        )
      );
      return;
    }
    if (success) {
      const jsonResponse = JSON.parse(response);

      if (jsonResponse.error) {
        const { msg } = jsonResponse;
        const errors = {
          no_files_found: i18n.t('sagas:uploader.noFilesFound'),
          unsupported_file_type: i18n.t('sagas:uploader.unsupportedFileType'),
          cant_create_directory: i18n.t('sagas:uploader.cantCreateDirectory'),
          file_is_too_large: i18n.t('sagas:uploader.fileIsTooLarge'),
          unsupported_file_extension: i18n.t(
            'sagas:uploader.unsupportedFileExtension'
          ),
          cant_load_file: i18n.t('sagas:uploader.cantLoadFile'),
        };
        yield put(
          actions.uploader.uploadError(
            objectId,
            errors[msg],
            filePath,
            uploadType,
            pathLastName
          )
        );
      } else {
        const source = jsonResponse.payload.files[0].link;
        yield put(
          actions.uploader.uploadSuccess(
            objectId,
            source,
            filePath,
            uploadType,
            pathLastName,
            withFileName,
            inPlayer
          )
        );

        if (inPlayer) {
          yield put(send());
        }
      }

      return;
    }
    const updateFilePath = pathLastName
      ? concat([...filePath], pathLastName)
      : [...filePath];
    yield put(
      actions.uploader.setUploadProgress(
        objectId,
        progress,
        updateFilePath,
        uploadType,
        isNoticeProgress
      )
    );
  }
}

export default function*() {
  yield takeEvery(UPLOADER.START_UPLOAD, function* watcher(action) {
    const {
      objectId,
      file,
      filePath,
      fileType,
      uploadType,
      pathLastName,
      withFileName,
      inPlayer,
      isNoticeProgress,
    } = action.payload;
    yield call(
      uploadFile,
      objectId,
      file,
      filePath,
      fileType,
      uploadType,
      pathLastName,
      withFileName,
      inPlayer,
      isNoticeProgress
    );
  });
}
