import { set } from 'lodash/fp';
import * as COURSE from '../../constants/builder/course';
import * as selectors from '../../selectors';
import * as helpers from '../../helpers';

const generateTimestamp = Date.now;

/**
 * Создание нового курса
 */

export const add = (state) => (next) => (action) => {
  if (action.type === COURSE.ADD) {
    const creatorId = selectors.auth.getUserId(state.getState());
    const createdAt = helpers.lesson.convertTimestamp(generateTimestamp());
    return next(
      set(
        'meta',
        {
          creatorId,
          createdAt,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};
