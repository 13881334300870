import {
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
  PLAN_NAME_NUM10,
} from '~/appConstants';

export default {
  [PLAN_NAME_FREE]: {
    coursesCount: 0,
    title: 'Free',
    price: '0',
    activeCoursesText: 'subscriptionPlans.freeActiveCourses',
  },
  [PLAN_NAME_NUM1]: {
    coursesCount: 1,
    title: 'На старт 1',
    price: 990,
  },
  [PLAN_NAME_NUM3]: {
    title: 'На старт 3',
    coursesCount: 3,
    price: 2800,
  },
  [PLAN_NAME_NUM7]: {
    title: 'На старт 7',
    coursesCount: 7,
    price: 5950,
  },
  [PLAN_NAME_NUM10]: {
    title: 'На старт 10',
    coursesCount: 10,
    price: 7900,
  },
  // [PLAN_NAME_CUSTOM]: {
  //   coursesCount: 0,
  //   price: '-',
  //   activeCoursesText: 'subscriptionPlans.customActiveCourses',
  //   bottomText: 'subscriptionPlans.customPrice',
  // },
};
