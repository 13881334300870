/* eslint-disable fp/no-mutation */
import { createAction } from '../../utils/actions';

export const DOWNLOAD = '@builder/results/Загрузить прохождения пользователя';
export const DOWNLOAD_ERROR =
  '@builder/results/Ошибка при загрузке прохождений пользователя';
export const DOWNLOAD_SUCCESS =
  '@builder/results/Прохождения пользователя успешно загружены';

export const download = (userId, search) =>
  createAction(DOWNLOAD, { userId, search });
download.toString = () => DOWNLOAD;

export const downloadSuccess = (downloadedResults) =>
  createAction(DOWNLOAD_SUCCESS, { downloadedResults });
downloadSuccess.toString = () => DOWNLOAD_SUCCESS;

export const downloadError = (error) => createAction(DOWNLOAD_ERROR, { error });
downloadError.toString = () => DOWNLOAD_ERROR;
