import { put, takeLatest } from 'redux-saga/effects';
import {
  downloadCourseSuccess,
  joinCourseSuccess,
  rejectCourseSuccess,
} from '~/actions/tutors';
import { apiRequest } from '~/sagas/request';
import * as actions from '~/actions';
import {
  DOWNLOAD_COURSE,
  JOIN_COURSE,
  REJECT_COURSE,
} from '~/constants/tutors';

export function* watchDownloadCourse() {
  yield takeLatest(DOWNLOAD_COURSE, function*(action) {
    const {
      payload: { courseId },
    } = action;

    const response = yield apiRequest(`course/tutor/${courseId}`, null, 'get', {
      showNotFoundOn404: true,
    });

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      return;
    }

    const { course } = payload;

    yield put(downloadCourseSuccess({ course }));
  });
}
export function* watchJoinCourse() {
  yield takeLatest(JOIN_COURSE, function*(action) {
    const { courseId } = action.payload;

    const response = yield apiRequest(
      `course/tutor/join/${courseId}`,
      null,
      'get'
    );

    if (!response) {
      return;
    }

    const { error } = response.data;

    if (error) {
      return;
    }

    yield put(joinCourseSuccess({ courseId }));
    // TODO: i18n
    yield put(actions.notice.show('Участие в курсе подтверждено', 'success'));
  });
}

export function* watchRejectCourse() {
  yield takeLatest(REJECT_COURSE, function*(action) {
    const { courseId } = action.payload;

    const response = yield apiRequest(
      `course/tutor/reject/${courseId}`,
      null,
      'get'
    );

    if (!response) {
      return;
    }

    const { error } = response.data;

    if (error) {
      return;
    }

    yield put(rejectCourseSuccess({ courseId }));
    // TODO: i18n
    yield put(actions.notice.show('Участие в курсе отклонено', 'success'));
  });
}
