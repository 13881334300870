import { createAction } from '~/utils/actions';

export const GET_PAYMENT_HISTORY =
  '@builder/profile/Получение истории платежей';
export const GET_PAYMENT_HISTORY_ERROR =
  '@builder/profile/Ошибка получения истории платежей';
export const GET_PAYMENT_HISTORY_SUCCESS =
  '@builder/profile/Успешное получение истории платежей';

export const getHistory = () => createAction(GET_PAYMENT_HISTORY);

export const getHistorySuccess = ({ history }) =>
  createAction(GET_PAYMENT_HISTORY_SUCCESS, { history });

export const getHistoryError = () => createAction(GET_PAYMENT_HISTORY_ERROR);
