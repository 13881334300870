import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';

import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from 'redux';

import * as PLAYER from '../constants/player';
import middlewares from '../middlewares';
import rootReducer from '../reducers';
import { SEND, SEND_SUCCESS } from '~/constants/player/lesson';

const createStore = ({ history, initialState = {}, sagaMiddleware }) => {
  /* eslint-disable more/no-window, no-underscore-dangle */
  const composeEnhancers =
    process.env.BUILD_NAME !== 'prod' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
          actionsBlacklist: [
            LOCATION_CHANGE,
            PLAYER.LESSON.SET_RUN_TIME,
            PLAYER.SET_EXECUTION_TIME,
            SEND_SUCCESS,
            SEND,
          ],
        })
      : compose;

  return createReduxStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares, routerMiddleware(history), sagaMiddleware)
    )
  );
};

export default createStore;
