import { get, filter, compose } from 'lodash/fp';

export const getNotificationsCount = get(['notification', 'count']);

export const getUnviewedNotifications = compose(
  filter({ isViewed: false }),
  get(['notification', 'notifications'])
);

export const getViewedNotifications = compose(
  filter({ isViewed: true }),
  get(['notification', 'notifications'])
);
