export const OPEN = '@library/search/block/Открыть поиск по библиотеке';
export const HIDE = '@library/search/block/Скрыть поиск по библиотеке';
export const SHOW = '@library/search/block/Показать поиск по библиотеке';
export const CLOSE = '@library/search/block/Закрыть поиск по библиотеке';
export const SELECT_TAG =
  '@library/search/block/Выбрать тэг из найденных в библиотеке для поиска блоков';
export const REMOVE_TAG =
  '@library/search/block/Удалить тэг из фильтра поиска блоков по библиотеке';
export const TOGGLE_TYPE =
  '@library/search/block/Отметить тип блока в фильтры поиска';
export const IMPORT_TAGS =
  '@library/search/block/Импортировать найденные теги из библиотеки';
export const IMPORT_BLOCKS =
  '@library/search/block/Импортировать найденные на сервере блоки';
export const TOGGLE_SUBJECT =
  '@library/search/block/Отметить предмет блока в фильтры поиска';
export const CHANGE_TAGS_FILTER =
  '@library/search/block/Изменить значение поля поиска тэгов по библиотеке';
