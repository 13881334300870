import objectId from 'bson-objectid';
import { createAction } from '~/utils/actions';

export const ADD_CARD = '@builder/block/talk/Добавление страницы';
export const REMOVE_CARD_CONFIRM =
  '@builder/block/talk/Модальное окно подтверждения удаление страницы';
export const REMOVE_CARD_ACCEPT =
  '@builder/block/talk/Удаление страницы подтверждено';
export const REMOVE_CARD_CANCEL =
  '@builder/block/talk/Удаление страницы не подтверждено';
export const ADD_ANSWER = '@builder/block/talk/Добавление ответа';
export const REMOVE_ANSWER = '@builder/block/talk/Удаление ответа';
export const REMOVE_IMAGE = '@builder/block/talk/Удаление изображения';
export const CHANGE_ANSWER_TEXT = '@builder/block/talk/Изменение текста ответа';
export const CHANGE_CARD_QUESTION = '@builder/block/talk/Изменение вопроса';
export const CHANGE_CARD_INFORMATION =
  '@builder/block/talk/Изменение текста информационной карточки';
export const CHANGE_NAME = '@builder/block/talk/Изменение названия задания';
export const CHANGE_DESCRIPTION =
  '@builder/block/talk/Изменение описания задания';
export const CHANGE_ANSWER_TARGET =
  '@builder/block/talk/Изменение целевой карточки ответа';
export const CHANGE_ANSWER_SCORE =
  '@builder/block/talk/Изменение баллов ответа';
export const CHANGE_CARD_TYPE = '@builder/block/talk/Изменение типа карточки';
export const CLONE_CARD = '@builder/block/talk/Клонирование карточки';
export const CLEAR_CONTENT = '@builder/block/talk/Очистка контента';
export const MOVE_CARD_TO_START =
  '@builder/block/talk/Переместить карточку в начало';
export const CHANGE_CARD_TARGET =
  '@builder/block/talk/Изменить целевую карточку карточки';
export const CHANGE_MINIMAL_SUCCESS_SCORE =
  '@builder/block/talk/Изменение минимального количества баллов для прохождения';

export const addCard = ({ blockId, targetedAnswerId, targetedCardId }) =>
  createAction(ADD_CARD, {
    blockId,
    targetedCardId,
    targetedAnswerId,
    cardId: objectId.generate(),
  });
export const removeCardConfirm = ({ blockId, cardId }) =>
  createAction(REMOVE_CARD_CONFIRM, { blockId, cardId });
export const removeCardAccept = ({ blockId, cardId }) =>
  createAction(REMOVE_CARD_ACCEPT, { blockId, cardId });
export const removeCardCancel = () => createAction(REMOVE_CARD_CANCEL);
export const addAnswer = ({ blockId, cardId }) =>
  createAction(ADD_ANSWER, { blockId, cardId, answerId: objectId.generate() });
export const cloneCard = ({ blockId, cardId }) =>
  createAction(CLONE_CARD, { blockId, cardId, newCardId: objectId.generate() });
export const clearContent = ({ blockId, cardId }) =>
  createAction(CLEAR_CONTENT, { blockId, cardId });
export const moveCardToStart = ({ blockId, cardId }) =>
  createAction(MOVE_CARD_TO_START, { blockId, cardId });
export const removeAnswer = ({ blockId, cardId, answerId }) =>
  createAction(REMOVE_ANSWER, { blockId, cardId, answerId });
export const changeAnswerText = ({ blockId, answerId, text }) =>
  createAction(CHANGE_ANSWER_TEXT, { blockId, answerId, text });
export const changeName = ({ blockId, name }) =>
  createAction(CHANGE_NAME, { blockId, name });
export const changeDescription = ({ blockId, description }) =>
  createAction(CHANGE_DESCRIPTION, { blockId, description });
export const changeCardQuestion = ({ blockId, cardId, question }) =>
  createAction(CHANGE_CARD_QUESTION, { blockId, cardId, question });
export const changeCardInformation = ({ blockId, cardId, information }) =>
  createAction(CHANGE_CARD_INFORMATION, { blockId, cardId, information });
export const changeAnswerTarget = ({ blockId, answerId, target }) =>
  createAction(CHANGE_ANSWER_TARGET, { blockId, answerId, target });
export const changeAnswerScore = ({ blockId, answerId, score }) =>
  createAction(CHANGE_ANSWER_SCORE, { blockId, answerId, score });
export const changeMinimalSuccessScore = ({ blockId, score }) =>
  createAction(CHANGE_MINIMAL_SUCCESS_SCORE, { blockId, score });
export const removeImage = ({ blockId, cardId }) =>
  createAction(REMOVE_IMAGE, { blockId, cardId });
export const changeCardType = ({ blockId, cardId, type }) =>
  createAction(CHANGE_CARD_TYPE, { blockId, cardId, type });
export const changeCardTarget = ({ blockId, cardId, target }) =>
  createAction(CHANGE_CARD_TARGET, { blockId, cardId, target });
