import { css } from 'styled-components';
import noAvatar from '../../../static/img/no-avatar.png';

export const container = css`
  box-sizing: border-box;
  min-height: 100vh;
  top: 0;
  right: 0;
  box-shadow: inset 0 0 6px 0 rgba(255, 255, 255, 0.27);
  background-color: #fff;
  z-index: 10;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
  justify-self: flex-end;
  align-self: flex-end;

  ${(props) =>
    props.theme.isMobile
      ? css`
          width: 100%;
          padding: 0 0 33px;
        `
      : css`
          width: 471px;
          padding: 33px 0;
        `}
`;

export const close = css`
  position: absolute;
  top: 19px;
  width: 42px;
  height: 42px;
  color: #b2b6b8;
  z-index: 20;

  ${(props) =>
    props.theme.isMobile
      ? css`
          right: auto;
          left: 11px;
          top: 15px;
        `
      : css`
          right: 479px;
        `}
`;

export const header = css`
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.theme.isMobile
      ? css`
          padding: 17px 16px 0 70px;
        `
      : css`
          padding: 0 32px;
        `}
`;

export const headerTitle = css`
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #45494e;
`;

export const headerMarkAll = css`
  font-family: CirceRounded;
  font-size: 14px;
  color: #767b84;
  cursor: pointer;

  ${(props) =>
    props.theme.isMobile &&
    css`
      width: 120px;
    `}
`;

export const typeTitle = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #aeb3bd;
  margin-top: 24px;
  padding: 8px 32px;
  text-transform: uppercase;
`;

export const content = css`
  width: 100%;
`;

export const item = css`
  padding: 16px 32px;
  margin-bottom: 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  transition: background 0.2s ease;
  cursor: pointer;

  ${(props) =>
    props.theme.isMobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 16px 32px;

          &:hover {
            background: rgba(229, 233, 236, 0.7);
            transition: background 0.2s ease;
          }
        `}

  ${(props) =>
    props.isUnread &&
    css`
      background: rgba(229, 233, 236, 0.4);
    `}
`;

export const avatar = css`
  width: 42px;
  height: 42px;
  flex: 0 0 42px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
  margin-right: 12px;
  position: relative;
`;

export const itemContent = css`
  width: 100%;
  font-family: CirceRounded;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #45494e;
`;

export const date = css`
  font-family: CirceRounded;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #aeb3bd;
  text-transform: uppercase;
  margin-top: 5px;
`;

export const itemApproved = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 26px;
  left: 26px;
  font-size: 12px;
  color: #fff;
  line-height: 0;

  ${(props) =>
    props.isApproved
      ? css`
          background: #8acf00;
        `
      : css`
          background: #f25200;
        `}
`;

export const loader = css`
  width: 100%;
`;

export const loaderContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const loaderContainerSmall = css`
  width: 100%;
  min-width: 210px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: -5px 0;
`;

export const emptyContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const empty = css`
  position: relative;
  width: 100%;
`;

export const emptyLabel = css`
  padding-top: 16px;
  font-family: CirceRounded;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #767b84;
`;
