import { createAction } from '~/utils/actions';

export const DOWNLOAD_ANSWER_BLOCKS =
  '@review/Загрузка списка результов открытого вопроса для проверки учителем';
export const DOWNLOAD_ANSWER_BLOCK =
  '@review/Загрузка результа открытого вопроса для проверки учителем';
export const DOWNLOAD_ANSWER_BLOCKS_SUCCESS =
  '@review/Загружен список результов открытого вопроса для проверки учителем';
export const DOWNLOAD_ANSWER_BLOCK_SUCCESS =
  '@review/Загружен результ открытого вопроса для проверки учителем';
export const SEND_TEACHER_COMMENT =
  '@review/Отправить комментарий учителя к блоку открытого вопроса';
export const SEND_TEACHER_COMMENT_SUCCESS =
  '@review/Успешно отправлен комментарий учителя к блоку открытого вопроса';
export const REMOVE_FILE = '@review/Удалить файл';
export const CLEAR_FILES_TO_SEND = '@review/Очистка файлов для отправкиа';
export const UPDATE_ANSWER_BLOCK_STATUS_SUCCESS =
  '@review/Изменить статус результата открытого вопроса в списке результов';
export const UNLOCK_ANSWER_BLOCK_REVIEW =
  '@review/Разблокировать ревью открытого вопроса';

export const downloadAnswerBlocks = ({ courseId, lessonId }) =>
  createAction(DOWNLOAD_ANSWER_BLOCKS, { courseId, lessonId });

export const downloadAnswerBlocksSuccess = ({ answerBlocks }) =>
  createAction(DOWNLOAD_ANSWER_BLOCKS_SUCCESS, { answerBlocks });

export const downloadAnswerBlock = ({ answerBlockResultId }) =>
  createAction(DOWNLOAD_ANSWER_BLOCK, { answerBlockResultId });

export const unlockAnswerBlockReview = ({ answerBlockResultId }) =>
  createAction(UNLOCK_ANSWER_BLOCK_REVIEW, { answerBlockResultId });

export const downloadAnswerBlockSuccess = ({
  date,
  reply,
  question,
  lessonName,
  courseName,
  isApproved,
  description,
  studentName,
  teacherName,
  studentEmail,
  teacherComment,
  isAlreadyInReview,
  teacherCommentDate,
  isTutor,
  courseId,
  publicationId,
  pageId,
  blockId,
}) =>
  createAction(DOWNLOAD_ANSWER_BLOCK_SUCCESS, {
    date,
    reply,
    question,
    lessonName,
    courseName,
    isApproved,
    description,
    studentName,
    teacherName,
    studentEmail,
    teacherComment,
    isAlreadyInReview,
    teacherCommentDate,
    isTutor,
    courseId,
    publicationId,
    pageId,
    blockId,
  });

export const sendTeacherComment = ({
  files,
  comment,
  isApproved,
  answerBlockResultId,
}) =>
  createAction(SEND_TEACHER_COMMENT, {
    files,
    comment,
    isApproved,
    answerBlockResultId,
  });

export const sendTeacherCommentSuccess = ({
  isApproved,
  teacherFiles,
  teacherComment,
  teacherName,
}) =>
  createAction(SEND_TEACHER_COMMENT_SUCCESS, {
    isApproved,
    teacherFiles,
    teacherComment,
    teacherName,
  });

export const removeFile = ({ id }) => createAction(REMOVE_FILE, { id });
export const clearFilesToSend = () => createAction(CLEAR_FILES_TO_SEND);

export const updateAnswerBlockStatusSuccess = ({
  isApproved,
  answerBlockResultId,
}) =>
  createAction(UPDATE_ANSWER_BLOCK_STATUS_SUCCESS, {
    isApproved,
    answerBlockResultId,
  });
