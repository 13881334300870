import { set, curry } from 'lodash/fp';
import * as DOCUMENT from '../../../constants/builder/block/document';

export const defaultState = {
  source: undefined,   // Путь до файла
  fileName: undefined, // Название файла
  isProtected: false, // Запрет скачивания файла
};

const documentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOCUMENT.CHANGE_FILE_NAME: {
      const { fileName } = action.payload;
      return set('fileName', fileName, state);
    }

    case DOCUMENT.TOGGLE_PROTECTION: {
      const { isEnable } = action.payload;
      return set('isProtected', isEnable, state);
    }

    default:
      return state;
  }
};

export default curry(documentReducer);
