export const DOWNLOAD_COURSE = '@course/Загрузка курса для тьютора';
export const DOWNLOAD_COURSE_SUCCESS =
  '@course/Загрузка курса для тьютора успешна';

export const JOIN_COURSE = '@course/Присоединение тьютора к курсу';
export const JOIN_COURSE_SUCCESS =
  '@course/Успешное присоединение тьютора к курсу';

export const REJECT_COURSE = '@course/Отказ от присоединения тьютора к курсу';
export const REJECT_COURSE_SUCCESS =
  '@course/Успешный отказ от присоединения тьютора к курсу';

export const DOWNLOAD_LESSONS = '@lessons/Загрузка уроков для тьютора';
export const DOWNLOAD_LESSONS_SUCCESS =
  '@lessons/Загрузка уроков для тьютора успешна';
