import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { get, keys, indexOf, compose } from 'lodash/fp';
import { combineStyles } from '../utils/styles';
import { intersect } from '../utils/array';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/Pages.scss';
import Page from '~/containers/Page';

const Pages = ({
  ids,
  getOrder,
  movePage,
  lessonId,
  inBirdView,
  publication,
}) => (
  <div className={combineStyles('Pages', inBirdView && 'bird-view')}>
    {ids.map((pageId) => (
      <div
        key={pageId}
        style={{
          order: getOrder(pageId),
        }}
        className="page"
      >
        <Page
          id={pageId}
          publication={publication}
          index={getOrder(pageId)}
          lessonId={lessonId}
          onDragEnd={movePage}
        />
      </div>
    ))}
  </div>
);

const { bool, arrayOf, func, string, number } = PropTypes;

Pages.propTypes = {
  ids: arrayOf(string).isRequired, // Массив ID страниц урока
  getOrder: func.isRequired, // Получение порядкового номера страницы
  movePage: func.isRequired, // Перемещение страницу
  lessonId: string.isRequired, // ID урока
  inBirdView: bool.isRequired, // В режиме бёрдвью?
  publication: number,
};

const mapStateToProps = (state, ownProps) => {
  const { lessonId } = ownProps;
  const inBirdView = selectors.builder.lesson.inBirdView(lessonId, state);
  const order = get(
    ['content', 'pagesIds'],
    selectors.builder.getLesson(lessonId, state)
  );
  const ids = intersect(order, keys(selectors.builder.getPages(state)));
  return {
    ids,
    order,
    lessonId,
    inBirdView,
  };
};

const mapDispatchToProps = {
  movePage: actions.builder.page.move,
};

const handlersEnhance = withHandlers({
  getOrder: ({ order }) => (pageId) => indexOf(pageId, order),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance
)(Pages);
