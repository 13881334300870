import objectId from 'bson-objectid';
import * as EXAM from '../../../constants/builder/block/exam';
import { createAction } from '../../../utils/actions';

export const changeQuestion = (blockId, newQuestion) =>
  createAction(EXAM.CHANGE_QUESTION, { blockId, newQuestion });
export const addCorrectAnswer = (blockId) =>
  createAction(EXAM.ADD_CORRECT_ANSWER, {
    blockId,
    answerId: objectId.generate(),
  });
export const changeDescription = (blockId, newDescription) =>
  createAction(EXAM.CHANGE_DESCRIPTION, { blockId, newDescription });
export const removeCorrectAnswer = (blockId, answerId) =>
  createAction(EXAM.REMOVE_CORRECT_ANSWER, { blockId, answerId });
export const changeCorrectAnswer = (blockId, answerId, newAnswer) =>
  createAction(EXAM.CHANGE_CORRECT_ANSWER, { blockId, answerId, newAnswer });
export const toggleCorrectAnswerType = (blockId, answerId) =>
  createAction(EXAM.TOGGLE_CORRECT_ANSWER_TYPE, { blockId, answerId });
