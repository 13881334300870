import React from 'react';
import PropTypes from 'prop-types';

import { times } from 'lodash/fp';

import * as s from './styles/DotLoader.styles';

const DotLoader = ({ className, size }) => (
  <div css={s.container} className={className}>
    <div css={s.dotLoader}>
      {times(
        (i) => (
          <div css={s.dot} size={size} key={i} />
        ),
        3
      )}
    </div>
  </div>
);

const { string, oneOf } = PropTypes;
DotLoader.propTypes = {
  className: string,
  size: oneOf(['small', 'medium']),
};

DotLoader.defaultProps = {
  size: 'medium',
};

export default DotLoader;
