import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BLOCK_PASS_STATUS_NOT_PASSED,
  BLOCK_PASS_STATUS_NOT_PASSED_YET,
  BLOCK_PASS_STATUS_IN_PROCESS,
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';

import Text from '~/components/Text';
import * as s from '../styles/FinalScreen.styles';

const FinalScreen = ({ score, blockStatus }) => {
  const { t } = useTranslation('containersBlock');

  const emoji = {
    [BLOCK_PASS_STATUS_PASSED_CORRECTLY]: '🙂',
    [BLOCK_PASS_STATUS_PASSED_INCORRECTLY]: '🙁',
    [BLOCK_PASS_STATUS_IN_PROCESS]: '🤓',
    [BLOCK_PASS_STATUS_NOT_PASSED]: '😐',
    [BLOCK_PASS_STATUS_NOT_PASSED_YET]: '😐',
  }[blockStatus];

  const showScores = [
    BLOCK_PASS_STATUS_PASSED_CORRECTLY,
    BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
  ].includes(blockStatus);

  return (
    <div css={s.final}>
      <div className="title">
        <Text value={t('talk.result')} />
      </div>
      <div className="score">{`${showScores ? score : ''} ${emoji}`}</div>
      <div className="status">
        <Text value={t(`talk.status.${blockStatus}`)} />
      </div>
    </div>
  );
};

const { number, string } = PropTypes;

FinalScreen.propTypes = {
  blockStatus: string.isRequired,
  score: number.isRequired,
};

export default FinalScreen;
