import React from 'react';
import { pure } from 'recompose';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { combineStyles } from '../utils/styles';
import '../styles/Stub.scss';

/* Заглушка для вставки элементов в пустые места */

const Stub = ({ isDragOver, connectDropTarget }) =>
  connectDropTarget(
    <div className={combineStyles('Stub', isDragOver && 'drag-over')} />
  );

const { bool, func } = PropTypes;

Stub.propTypes = {
  isDragOver: bool.isRequired, // Над блоком что-то перетягивается?
  connectDropTarget: func.isRequired, // Подключить приемник DnD
};

const dropTarget = {
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      // Блок со страницы
      case 'page-block': {
        const { id, onDragEnd } = monitor.getItem();
        // Старая и новая страница перетягиваемого блока
        const oldPageId = monitor.getItem().pageId;
        const newPageId = props.pageId;
        // Старый и новый индексы перетягиваемого блока
        const oldIndex = monitor.getItem().index;
        const newIndex = props.index;
        onDragEnd(id, oldIndex, newIndex, oldPageId, newPageId); // moveBlock
        break;
      }
      // Блок из сайдбара
      case 'sidebar-block': {
        const {
          dragData: { type },
          onDragEnd,
        } = monitor.getItem();
        const { index, pageId } = props;
        onDragEnd(pageId, type, index); // addBlock
        break;
      }
      // Блок из библиотеки
      case 'library-block': {
        const { onDragEnd, dropPosition } = monitor.getItem();
        const { index, pageId } = props;
        onDragEnd(index + { top: 0, bottom: 1 }[dropPosition], pageId); // addBlock из поиска по библиотеке
        break;
      }
      default:
        break;
    }
  },
};

const collectDropTarget = (dndConnect, monitor) => ({
  isDragOver: monitor.isOver({ shallow: true }),
  connectDropTarget: dndConnect.dropTarget(),
});

export default compose(
  DropTarget(
    ['page-block', 'sidebar-block', 'library-block'],
    dropTarget,
    collectDropTarget
  ),
  pure
)(Stub);
