import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { isNumber, constant } from 'lodash/fp';
import { combineStyles } from '../utils/styles';
import '../styles/Tag.scss';
import Text from '~/components/Text';
import { DraggedButton as Button } from '~/components/Button';

const Tag = ({
  tag,
  key,
  text,
  type,
  index,
  variant,
  minimal,
  onRemove,
  getTagDisplayValue,
}) => (
  <div
    className={combineStyles(cx('Tag', { '-minimal': minimal }), variant)}
    key={key}
  >
    {type && <Text value={type} variant={combineStyles('tag-type', variant)} />}
    {(text || getTagDisplayValue(tag)) && (
      <Text
        value={text || getTagDisplayValue(tag)}
        variant={combineStyles('tag-text', variant)}
      />
    )}
    {(isNumber(index) || isNumber(key)) && onRemove && (
      <Button
        icon="cross"
        minimal
        variant="tag-remove"
        onClick={() =>
          isNumber(index) ? onRemove(index) : isNumber(key) ? onRemove(key) : {}
        }
      />
    )}
  </div>
);

const { bool, string, object, number, func } = PropTypes;

Tag.propTypes = {
  tag: object, // Внутренний объект тэга для react-tagsinput
  key: number, // Индекс тэга для react-tagsinput
  text: string, // Текст тэга при создании напрямую
  type: string.isRequired, // Тип тэга
  index: number, // Индекс тега при создании напрямую
  variant: string, // Вариант оформления
  minimal: bool, // Минимальное оформление
  onRemove: func, // При удалении тэга
  getTagDisplayValue: func, // Вычисление имени тэга внутри react-tagsinput
};

Tag.defaultProps = {
  tag: {},
  key: null,
  text: null,
  index: null,
  variant: undefined,
  minimal: false,
  onRemove: constant(null),
  getTagDisplayValue: constant(null),
};

export default Tag;
