import { all } from 'redux-saga/effects';
import save from './save';
import remove from './remove';
import sendInvites from './sendInvites';
import download from './download';
import removeUser from './removeUser';

export default function*() {
  yield all([save(), remove(), download(), sendInvites(), removeUser()]);
}
