import { css } from 'styled-components';
import noAvatar from '../../../../static/img/no-avatar.png';

export const container = css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;

  @media (max-width: 1023px) {
    padding: 20px 10px;
  }
`;

export const question = css`
  font-family: Gilroy;
  font-weight: 600;
  font-size: ${({ theme: { fontSize } }) =>
    fontSize === 'medium' ? '33px' : fontSize === 'large' ? '44px' : '22px'};

  line-height: ${({ theme: { fontSize } }) =>
    fontSize === 'medium' ? '39px' : fontSize === 'large' ? '50px' : '1.5'};

  color: ${({ theme: { theme } }) =>
    theme === 'light'
      ? '#000'
      : theme === 'dark'
      ? '#fff'
      : theme === 'blue'
      ? '#143958'
      : '#45484e'};
`;

export const description = css`
  font-family: CirceRounded;
  font-weight: normal;
  margin-bottom: 12px;
  line-height: 1.5;
  color: #8d8d8d;
  font-size: ${({ theme: { fontSize } }) =>
    fontSize === 'medium' ? '27px' : fontSize === 'large' ? '36px' : '18px'};
`;

export const reply = css`
  font-family: CirceRounded;
  font-size: 18px;
  line-height: 1.5;
  color: #45484e;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-top: 2px solid #d0d3d7;
  border-bottom: 2px solid #d0d3d7;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 22px 0 0;
    border-color: #d0d3d7 transparent transparent transparent;
    position: absolute;
    bottom: -18px;
    left: 16px;
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 17px 0 0;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -14px;
    left: 18px;
  }
`;

export const imageReply = css`
  display: flex;
  font-family: CirceRounded;
  color: #95979a;
  font-size: 22px;
  align-items: center;
  flex-direction: row;
`;

export const images = css`
  display: flex;
  flex-flow: row wrap;
`;

export const uploader = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > .tooltip {
    margin-left: 6px;
  }
`;

export const image = css`
  width: 171px;
  height: 169px;
  margin: 15px 14px 0 0;
`;

export const bottom = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

export const user = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const userAvatar = css`
  width: 45px;
  height: 45px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${noAvatar});
`;

export const userName = css`
  font-family: CirceRounded;
  font-size: 16px;
  line-height: 1.13;
  color: #767b84;
  margin-left: 9px;
`;

export const uploaderIcon = css`
  margin-top: -7px;
`;
