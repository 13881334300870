/* eslint react/no-array-index-key: 2 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { Trans, withTranslation } from 'react-i18next';
import { withProps } from 'recompose';

import { Button, Text, Link } from '../components';
import { combineStyles } from '../utils/styles';
import * as selectors from '../selectors';
import * as actions from '../actions';
import '../styles/Sidebar.scss';

const Sidebar = ({
  t,
  type,
  open,
  close,
  isOpen,
  variant,
  addPage,
  addBlock,
  isInFolder,
  inBirdView,
  goToFolder,
  goToCourse,
  goToLessons,
  isHintsEnabled,
  buttonTooltipContent,
}) => (
  <div className={combineStyles('Sidebar', [variant, isOpen && 'visible'])}>
    {!inBirdView && (
      <Link
        text={goToCourse ? t('folder.returnToCourse') : t('folder.myLessons')}
        variant={['my-lessons']}
        withIcon="arrow-left"
        onClick={isInFolder ? goToFolder : goToCourse || goToLessons}
      />
    )}
    {!inBirdView && (
      <Button
        text={isOpen ? t('sidebar.panelUnfix') : t('sidebar.panelFix')}
        icon={isOpen ? 'lock' : 'unlock'}
        variant={['sidebar-toggler', variant]}
        onClick={isOpen ? close : open}
      />
    )}
    <div
      className={combineStyles('items', [
        isHintsEnabled && 'blocks-step',
        inBirdView && 'bird-view',
      ])}
    >
      <div className="scroll">
        <div className="section">
          {!inBirdView && (
            <div className="title">
              <Text
                value={t('sidebar.infoElements')}
                variant={['sidebar-subtitle', inBirdView && 'bird-view']}
              />
            </div>
          )}
          {{
            block: [
              {
                type: 'Text',
                name: t('sidebar.blockText'),
                icon: 'osh-block-text',
                helpTooltip: t('sidebar.blockTextHint'),
              },
              {
                type: 'Instruction',
                name: t('sidebar.blockInstruction'),
                icon: 'osh-block-instruction',
                helpTooltip: t('sidebar.blockInstructionHint'),
              },
              {
                type: 'Video',
                name: t('sidebar.blockVideo'),
                icon: 'osh-block-video',
                helpTooltip: t('sidebar.blockVideoHint'),
              },
              {
                type: 'Images',
                name: t('sidebar.blockImage'),
                icon: 'osh-block-img',
                helpTooltip: t('sidebar.blockImageHint'),
              },
              {
                type: 'Embed',
                name: t('sidebar.blockEmbed'),
                icon: 'osh-block-interactive',
                helpTooltip: t('sidebar.blockEmbedHint'),
              },
              {
                type: 'Document',
                name: t('sidebar.blockDocument'),
                icon: 'osh-block-page',
                helpTooltip: t('sidebar.blockDocumentHint'),
              },
              {
                type: 'VR',
                name: t('sidebar.blockVR'),
                icon: 'osh-block-vr',
                helpTooltip: t('sidebar.blockVRHint'),
              },
            ],
            page: [
              {
                name: t('sidebar.blockPage'),
                icon: 'osh-block-page',
                helpTooltip: t('sidebar.blockPageHint'),
              },
            ],
          }[type].map((item) => (
            <div className="item" key={item.name}>
              <Button
                text={item.name}
                icon={item.icon}
                type={type}
                iconTooltipContent={item.helpTooltip}
                buttonTooltipContent={buttonTooltipContent}
                canDrag
                variant={`sidebar-${type}`}
                dragData={item}
                onDragEnd={
                  {
                    page: addPage,
                    block: addBlock,
                  }[type]
                }
              />
            </div>
          ))}
        </div>
        {!inBirdView && (
          <div className="section">
            <div className="title">
              <Text
                value={t('sidebar.quizElements')}
                variant={['sidebar-subtitle', inBirdView && 'bird-view']}
              />
            </div>
            {{
              block: [
                {
                  type: 'Quiz',
                  name: t('sidebar.blockQuiz'),
                  icon: 'osh-block-quiz-checkbox',
                  helpTooltip: t('sidebar.blockQuizHint'),
                },
                {
                  type: 'Weight',
                  name: t('sidebar.blockWeight'),
                  icon: 'osh-block-quiz-checkbox',
                  helpTooltip: t('sidebar.blockWeightHint'),
                },
                {
                  type: 'Answer',
                  name: t('sidebar.blockAnswer'),
                  icon: 'osh-block-openquestion',
                  helpTooltip: t('sidebar.blockAnswerHint'),
                },
                {
                  type: 'Match',
                  name: t('sidebar.blockMatch'),
                  icon: 'osh-block-classification',
                  helpTooltip: t('sidebar.blockMatchHint'),
                },
                {
                  type: 'Exam',
                  name: t('sidebar.blockExam'),
                  icon: 'osh-block-autoopenquestion',
                  helpTooltip: t('sidebar.blockExamHint'),
                },
                {
                  type: 'FillBlank',
                  name: t('sidebar.blockFillblank'),
                  icon: 'osh-fill-the-blank',
                  helpTooltip: t('sidebar.blockFillblankHint'),
                },
                {
                  type: 'Talk',
                  name: t('sidebar.blockTalk'),
                  icon: 'osh-dialog',
                  helpTooltip: t('sidebar.blockTalkHint'),
                },
              ],
            }[type].map((item) => (
              <div className="item" key={item.type}>
                <Button
                  text={item.name}
                  icon={item.icon}
                  type={type}
                  canDrag
                  iconTooltipContent={item.helpTooltip}
                  buttonTooltipContent={buttonTooltipContent}
                  variant={`sidebar-${type}`}
                  dragData={item}
                  onDragEnd={
                    {
                      block: addBlock,
                    }[type]
                  }
                />
              </div>
            ))}
          </div>
        )}
        {!inBirdView && (
          <div className="section">
            <div className="title">
              <Text
                value={t('sidebar.reflectionElements')}
                variant={['sidebar-subtitle', inBirdView && 'bird-view']}
              />
            </div>
            {{
              block: [
                {
                  type: 'Survey',
                  name: t('sidebar.blockSurvey'),
                  icon: 'osh-block-quiz-radio',
                  helpTooltip: t('sidebar.blockSurveyHint'),
                },
                {
                  type: 'Feedback',
                  name: t('sidebar.blockFeedback'),
                  icon: 'osh-block-feedback',
                  helpTooltip: t('sidebar.blockFeedbackHint'),
                },
              ],
            }[type].map((item) => (
              <div className="item" key={item.type}>
                <Button
                  text={item.name}
                  icon={item.icon}
                  type={type}
                  canDrag
                  iconTooltipContent={item.helpTooltip}
                  buttonTooltipContent={buttonTooltipContent}
                  variant={`sidebar-${type}`}
                  dragData={item}
                  onDragEnd={
                    {
                      block: addBlock,
                    }[type]
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);

const { bool, func, oneOf, array, string, element, oneOfType } = PropTypes;

Sidebar.propTypes = {
  t: func.isRequired,
  type: oneOf(['page', 'block']).isRequired, // Тип сайдбара
  open: func.isRequired, // Открыть сайдбар
  close: func.isRequired, // Закрыть сайдбар
  isOpen: bool.isRequired, // Библиотека открыта?
  variant: oneOfType([array, string]), // Вариант оформления
  addPage: func.isRequired, // Добавить страницу из библиотеки
  addBlock: func.isRequired, // Добавить блок из библиотеки
  inBirdView: bool, // В режиме бёрдвью?
  goToCourse: func,
  isInFolder: bool,
  goToFolder: func,
  goToLessons: func, // Роутера
  isHintsEnabled: bool, // Подсказки по интерфейсу включены?
  buttonTooltipContent: element.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: selectors.builder.lesson.isSidebarOpen(state),
  isHintsEnabled: selectors.builder.isHintsEnabled(state),
});

const mapDispatchToProps = {
  open: actions.builder.lesson.openSidebar,
  close: actions.builder.lesson.closeSidebar,
  addPage: actions.builder.page.add,
  addBlock: actions.builder.block.add,
};

export default compose(
  withTranslation('containers'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(({ t }) => ({
    buttonTooltipContent: (
      <Trans i18nKey="sidebar.dragHint" t={t}>
        <strong>Drag</strong> to the page
        <br /> to add to
        <br /> the lesson
      </Trans>
    ),
  }))
)(Sidebar);
