import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { get } from 'lodash/fp';

import {
  DOWNLOAD_NOTIFICATIONS,
  downloadNotificationsSuccess,
  GET_NOTIFICATIONS_COUNT,
  getNotificationsCountSuccess,
  MARK_AS_VIEWED,
  markAsViewed,
  markAsViewedSuccess,
} from '~/actions/study/notifications';
import { apiRequest } from '~/sagas/request';
import {
  REQUEST_NAME_DOWNLOAD_NOTIFICATIONS,
  REQUEST_NAME_SET_VIEWED_NOTIFICATIONS,
} from '~/appConstants';

function* watchDownloadNotifications() {
  yield takeLatest(DOWNLOAD_NOTIFICATIONS, function*() {
    const response = yield apiRequest(
      'notification/get-notifications',
      null,
      'get',
      { name: REQUEST_NAME_DOWNLOAD_NOTIFICATIONS }
    );

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      return;
    }

    const { notifications } = payload;

    yield put(downloadNotificationsSuccess({ notifications }));
  });
}

function* watchGetNotificationsCount() {
  yield takeLatest(GET_NOTIFICATIONS_COUNT, function*() {
    const response = yield apiRequest('notification/count', null, 'get');
    if (!response) {
      return;
    }

    const { error } = response.data;

    if (error) {
      return;
    }

    const { count } = response.data.payload;

    yield put(getNotificationsCountSuccess({ count }));
  });
}

function* watchMarkAsViewed() {
  yield takeLatest(MARK_AS_VIEWED, function*({ payload: { ids } }) {
    const response = yield apiRequest(
      'notification/set-viewed',
      { ids },
      'post',
      { name: REQUEST_NAME_SET_VIEWED_NOTIFICATIONS }
    );

    if (!response) {
      return;
    }

    const { error, payload } = response.data;

    if (error) {
      return;
    }

    const { count } = payload;

    yield put(markAsViewedSuccess({ count, ids }));
  });
}

function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, function*() {
    const fromNotification = yield select(
      get(['router', 'location', 'state', 'fromNotification'])
    );

    if (fromNotification) {
      yield put(markAsViewed({ ids: [fromNotification] }));
    }
  });
}

export default function*() {
  yield all([
    watchDownloadNotifications(),
    watchGetNotificationsCount(),
    watchMarkAsViewed(),
    watchLocationChange(),
  ]);
}
