import React from 'react';
import Cookies from 'js-cookie';
import PropTypes, { array } from 'prop-types';
import { connect } from 'react-redux';
import { curry, compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { pure, withProps, withHandlers } from 'recompose';
import { Text, Input, Link, Checkbox } from '..';
import '../../styles/Block/VR.scss';

const handleChange = curry((onChange, value) => onChange({ source: value }));

const VR = ({ t, source, onChange, onClick, inEditMode, inReview, inPreviewMode, results }) => (
  <div className="VR">
    {inReview ? (
      <div className='results'>
        {results.map((result, index) => (
          <div className='result' key={index}>
            <Text value={result.question} variant="results-subtitle" />
            <Checkbox disabled checked style={result.isCorrect ? 'success' : 'error'}/>
          </div>
        ))}
      </div>
    ) : inEditMode ? (
      <div className="uploader">
        <div className="text">
          <Text value="VR" variant="uploader-title" />
        </div>
        <div className="input">
          <Input
            large
            value={source}
            variant="uploader-input"
            onChange={handleChange(onChange)}
            debounceTime={500}
            placeholder={t('vr.link')}
          />
        </div>
      </div>
    ) : inPreviewMode ?
      source ? (
        <div className="link">
          <div className="text">
            <Link text={t('vr.click')} onClick={onClick} />
          </div>
        </div>
      ) : (
        <div className="empty">
          <div className="text">
            <Text value={t('vr.empty')} variant="empty-block-text" />
          </div>
        </div>
      ) : null}
  </div>
);

const { string, bool, func } = PropTypes;

VR.propTypes = {
  t: func.isRequired, // Функция перевода
  source: string, // URL
  onChange: func.isRequired, // При изменении блока
  onClick: func.isRequired,
  inReview: bool,
  results: array,
  inEditMode: bool, // В режиме редактирования?
  inPreviewMode: bool, // В режиме просмотра/выполнения?
};

VR.defaultProps = {
  source: undefined,
  inEditMode: undefined,
  inPreviewMode: undefined,
};

const mapStateToProps = (state) => {
  if (!state.results.isOpen) return { results: [] }
  const { lessonId } = state.results
  if (!lessonId) return { results: [] }
  const { resultIndex } = state.results
  const results = state.results.lessons[lessonId]?.results
  const result = results?.[resultIndex]
  const vr = result?.vr
  return { results: vr || [] }
}

const enhance = compose(
  pure,
  connect(mapStateToProps),
  withTranslation('componentsBlock'),
  withHandlers({ onClick: ({ source }) => () => {
    const token = Cookies.get('token')
    if (!token) {
      // eslint-disable-next-line no-console
      console.error('Не передан токен VR')
      return
    }
    const url = `${source}&token=${token}`
    window.open(url)
  }}),
  withProps(
    ({
      inPlayer,
      inReview,
      inBuilder,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inReview,
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
    })
  )
);

export default enhance(VR);
