import { connect } from 'react-redux';
import * as actions from '~/actions';

const mapStateToProps = (state) => {
  const { theme, inSafeMode, fontSize } = state.player.theme;
  return {
    inSafeMode,
    theme,
    fontSize,
  };
};

const mapDispatchToProps = {
  toggleMode: actions.player.toggleMode,
  changeTheme: actions.player.changeTheme,
  changeFontSize: actions.player.changeFontSize,
};

const withTheming = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withTheming;
