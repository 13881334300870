/* eslint-disable no-continue, generator-star-spacing */

import {
  call,
  fork,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { get } from 'lodash/fp';

import { apiRequest } from '~/sagas/request';
import * as PLAYER from '../../../constants/player';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import { LESSON_TYPE_OLYMPIAD } from '~/appConstants';
import * as ANSWER from '~/constants/player/block/answer';
import * as FEEDBACK from '~/constants/player/block/feedback';

const olympiadActions = [
  PLAYER.PAGE.OPEN,
  PLAYER.BLOCK.QUIZ.SELECT_ANSWER,
  PLAYER.BLOCK.QUIZ.CHANGE_ANSWER_OTHER,
];

export function* sendResult() {
  const result = yield select(get(['player', 'result']));

  if (!result) {
    return;
  }

  const lastModified = yield call(Date.now);

  const data = {
    ...result,
    lastModified,
  };

  const response = yield apiRequest('result/save', data);
  if (!response) {
    return;
  }

  const { error } = response.data;

  if (error) {
    yield put(actions.player.lesson.sendError(error));
    return;
  }

  yield put(actions.player.lesson.sendSuccess({ result }));
}

function* watchSend() {
  yield takeLatest(PLAYER.LESSON.SEND, sendResult);
}

export function* trySendResult() {
  const state = yield select();

  if (!state.player.result) {
    return; // этой проверки вообще быть не должно быть уже, резалт у нас должен быть всегда когда эти экшны выполняются
  }

  if (yield select(selectors.player.isLessonComplete)) {
    return;
  }

  yield call(sendResult);
}

/**
 * Отправка данных о прохождении урока
 */
export default function*() {
  yield fork(watchSend);

  yield takeEvery(PLAYER.LOGIN, function*({ payload }) {
    const isLessonComplete = yield select(selectors.player.isLessonComplete);
    if (payload.userId && isLessonComplete) {
      const id = yield select(get(['player', 'result', 'id']));
      const response = yield apiRequest('result/connect_user_to_result', {
        id,
      });
      const { error } = response.data;
      if (error) {
        yield put(actions.player.lesson.connectUserToResultError(error));
        return;
      }
      yield put(actions.player.lesson.connectUserToResultSuccess({ id }));
    }
  });

  yield takeEvery(PLAYER.LESSON.COMPLETE, function*() {
    yield put(actions.player.lesson.send());
  });

  yield takeEvery(olympiadActions, function*({ type, payload }) {
    const state = yield select();
    const isOlympiad = state.player.lesson.meta.type === LESSON_TYPE_OLYMPIAD;
    if (isOlympiad) {
      return;
    }

    if (type === PLAYER.BLOCK.QUIZ.SELECT_ANSWER && payload.isNotRegularBlock) {
      return;
    }

    yield trySendResult();
  });

  yield takeLatest(
    [
      PLAYER.PAGE.VERIFY,
      PLAYER.PAGE.COMPLETE,
      PLAYER.BLOCK.VERIFY,
      PLAYER.BLOCK.RESET,
      PLAYER.BLOCK.MATCH.MOVE_ANSWER,
      PLAYER.CHANGE_ACTIVE_PAGE,
      ANSWER.CHANGE_REPLY,
      FEEDBACK.CHANGE_REPLY,
    ],
    trySendResult
  );
}
