import { put, takeLatest } from 'redux-saga/effects';
import {
  compact,
  compose,
  find,
  keyBy,
  map,
  mapValues,
  reduce,
  get,
  last,
} from 'lodash/fp';
import * as COURSE from '~/constants/course';
import * as actions from '~/actions';
import { apiRequest } from '~/sagas/request';
import { LESSON_TYPE_EXAM } from '~/appConstants';

const isExamPassed = ({ lesson, publicationResult }) => {
  if (!publicationResult) {
    return false;
  }

  const minimalSuccessScore = get(
    ['meta', 'settings', 'minimalSuccessScore'],
    lesson
  );

  return (
    publicationResult.totalCount === 0 ||
    Math.ceil(
      (publicationResult.correctCount / publicationResult.totalCount) * 100
    ) >= minimalSuccessScore
  );
};

export default function* download() {
  yield takeLatest(COURSE.DOWNLOAD_COURSE, function*({ payload: { id } }) {
    const response = yield apiRequest(`player/course/${id}`, null, 'get', {
      showNotFoundOn404: true,
    });

    if (!response) {
      return;
    }

    const {
      data: { error, payload: course },
    } = response;

    if (error) {
      yield put(actions.builder.downloadCourseError(error));
      return;
    }

    const results = keyBy('id', course.results);
    const resultsByLessonId = compose(
      mapValues('id'),
      keyBy('lessonId')
    )(course.results);

    // TODO: refactor?
    const publications = compose(
      reduce((result, publication) => {
        const prevPublication = last(result);

        if (!prevPublication) {
          return [...result, { ...publication, isBlocked: false }];
        }

        if (prevPublication.isBlocked) {
          return [...result, { ...publication, isBlocked: true }];
        }

        const prevPublicationLesson =
          prevPublication.content.lesson[prevPublication.lessonId];
        const isPrevLessonExam =
          prevPublicationLesson.meta.type === LESSON_TYPE_EXAM;

        if (!isPrevLessonExam) {
          return [...result, { ...publication, isBlocked: false }];
        }

        return [
          ...result,
          { ...publication, isBlocked: !prevPublication.isExamPassed },
        ];
      }, []),
      map((publication) => {
        const { lessonId } = publication;
        const lesson = publication.content.lesson[lessonId];
        const isExam = lesson.meta.type === LESSON_TYPE_EXAM;

        if (!isExam) {
          return publication;
        }

        const publicationResult = find({ lessonId }, results);

        const isPassed = isExamPassed({
          publicationResult,
          lesson,
        });

        return {
          ...publication,
          isExamPassed: isPassed,
        };
      }),
      compact,
      map((lessonId) => find({ lessonId }, course.publications))
    )(course.lessonsIds);

    const publicationsIds = compose(
      map('id'),
      compact,
      map((lessonId) => find({ lessonId }, course.publications))
    )(course.lessonsIds);

    yield put(
      actions.course.downloadCourseSuccess({
        course: {
          ...course,
          publicationsIds,
        },
        publications: keyBy('id', publications),
        results,
        resultsByLessonId,
      })
    );
  });
}
