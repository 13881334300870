import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  subscribePlan,
  changePlan,
  requestExtraDays,
  setExtraDays,
} from '~/actions/payment';

import {
  REQUEST_NAME_SUBSCRIBE,
  // PLAN_NAME_CUSTOM,
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
  PLAN_NAME_NUM10,
  REQUEST_NAME_EXTRA_DAYS,
} from '~/appConstants';
import * as selectors from '~/selectors';
import { Dialog, Button, Icon, Collapse, Checkbox } from '~/components';
import PlanContent from './components/PlanContent';
import CollapseContent from '~/components/Collapse/CollapseContent';
import { isRequestActive } from '~/selectors/ui';
import plansDataMap from '~/plansDataMap';
import * as s from './styles/Plans.styles';
import Emoji, { MAN_SHRUGGING } from '~/components/Emoji';
import DotLoader from '~/components/DotLoader';

const getTermsLabel = (t) => {
  const text = t('subscriptionPlans.terms_text');
  const link = t('subscriptionPlans.terms_link');
  return `${text} <a href="https://coreapp.ai/docs/offer.pdf" target="_blank" rel='noreferrer noopener'>${link}</a>`;
};

const plansToDisplay = [
  PLAN_NAME_FREE,
  PLAN_NAME_NUM1,
  PLAN_NAME_NUM3,
  PLAN_NAME_NUM7,
  PLAN_NAME_NUM10,
  // PLAN_NAME_CUSTOM,
];

const Plans = ({ close, isOpen, goBack, defaultSelectedPlan }) => {
  const { t } = useTranslation('screens');

  const dispatch = useDispatch();

  const { planName: userPlan, pan: userCard } = useSelector(
    selectors.auth.getUserSubscription
  );

  const activeCourses = useSelector(selectors.builder.getActiveCoursesCount);
  const extraDays = useSelector(selectors.payment.getExtraDays);

  const isSubmitRequesting = useSelector(
    isRequestActive(REQUEST_NAME_SUBSCRIBE)
  );
  const isExtraDaysRequesting = useSelector(
    isRequestActive(REQUEST_NAME_EXTRA_DAYS)
  );

  const [selectedPlan, selectPlan] = useState(userPlan);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const submitAction = useCallback(() => {
    if (!userCard) {
      dispatch(subscribePlan({ name: selectedPlan }));
    } else {
      dispatch(changePlan({ name: selectedPlan }));
    }
  }, [selectedPlan, dispatch, userCard]);

  useEffect(() => {
    if (defaultSelectedPlan) {
      selectPlan(defaultSelectedPlan);
    }
  }, [defaultSelectedPlan]);

  useEffect(() => {
    setIsTermsAccepted(false);
  }, [selectedPlan]);

  useEffect(() => {
    if (
      selectedPlan &&
      selectedPlan !== PLAN_NAME_FREE &&
      userPlan !== PLAN_NAME_FREE &&
      userPlan !== selectedPlan
    ) {
      dispatch(requestExtraDays({ planName: selectedPlan }));
    } else {
      dispatch(setExtraDays({ extraDays: 0 }));
    }
  }, [dispatch, selectedPlan, userPlan]);

  const disabledPlans = [
    // PLAN_NAME_CUSTOM,
    userPlan !== PLAN_NAME_FREE && PLAN_NAME_FREE,
  ];

  const selectedPlanData = plansDataMap[selectedPlan];

  const activeCollapseName = isExtraDaysRequesting
    ? 'loader'
    : activeCourses > selectedPlanData.coursesCount
    ? 'activeCoursesLimit'
    : activeCourses <= selectedPlanData.coursesCount &&
      ![userPlan, PLAN_NAME_FREE].includes(selectedPlan)
    ? 'payment'
    : 'more';

  return (
    <Dialog isOpen={isOpen} onClose={close} closable variant="plans">
      <div css={s.header}>
        <div className="back">
          <Button icon="arrow-left" onClick={goBack} variant="modal-back" />
        </div>
        <div css={s.title}>{t('subscriptionPlans.title')}</div>

        <Button
          variant="rounded"
          className="more"
          text={
            <a
              href="https://coreapp.ai/docs/plans.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('subscriptionPlans.more')}
            </a>
          }
        />
        <div className="close">
          <Button icon="osh-remove" onClick={close} variant="settings-close" />
        </div>
      </div>
      <div css={s.container}>
        <div css={s.plansRow}>
          {plansToDisplay.map((name) => (
            <s.PlanCard
              disabled={disabledPlans.includes(name)}
              key={name}
              onClick={() => !disabledPlans.includes(name) && selectPlan(name)}
              active={name === selectedPlan}
            >
              <PlanContent planName={name} />
            </s.PlanCard>
          ))}
        </div>
      </div>
      <Collapse activeName={activeCollapseName}>
        <CollapseContent name="loader">
          <DotLoader css={s.dotLoader} />
        </CollapseContent>
        <CollapseContent name="activeCoursesLimit">
          <div css={s.bottom}>
            <Emoji css={s.emoji} symbol={MAN_SHRUGGING} />
            <div css={s.limitText}>
              <Trans t={t} i18nKey="subscriptionPlans.activeCoursesLimit" />
            </div>
            <div css={s.whiteCard}>
              <div className="title">
                {t('subscriptionPlans.activeCourses')}
              </div>
              <div className="counter">{activeCourses}</div>
            </div>
          </div>
        </CollapseContent>
        <CollapseContent name="payment">
          <div css={s.bottom}>
            <div css={s.bottomLeft}>
              <div css={s.bottomTitle}>{t('subscriptionPlans.payment')}</div>
              <div css={s.divider} />
              {userCard && (
                <div css={s.bottomRow}>
                  <div css={s.infoText}>{t('subscriptionPlans.pan')}</div>
                  <span css={s.periodText}>{userCard}</span>
                </div>
              )}
              <div css={s.bottomRow}>
                <div css={s.infoText}>{t('subscriptionPlans.sum')}</div>
                <span css={s.priceText}>{selectedPlanData.price}</span>
                &nbsp;
                <span css={s.rText}>₽</span>
              </div>
              <div css={s.bottomRow}>
                <div css={s.infoText}>{t('subscriptionPlans.period')}:</div>
                <div css={s.periodText}>
                  {t('subscriptionPlans.oneMonth')}{' '}
                  {!!extraDays &&
                    `+ ${t('subscriptionPlans.bonusDays', {
                      count: extraDays,
                    })}`}
                </div>
              </div>
              {!!extraDays && (
                <div css={s.bonusText}>{t('subscriptionPlans.withBonus')}</div>
              )}
              <div />
              <div css={s.divider} />
              <div css={s.descriptionText}>
                {t('subscriptionPlans.description')}
              </div>
            </div>
            <div css={s.bottomRight}>
              <Icon css={s.icon} name="osh-visa-pay" />
              <Icon css={s.icon} name="osh-maestro-master-pay" />
              <Icon name="osh-mir-pay" />
              <br />
              <Icon css={s.tinkoffIcon} name="osh-tinkoff-pay" />
            </div>
          </div>
          <div css={s.terms}>
            <Checkbox
              label={getTermsLabel(t)}
              checked={isTermsAccepted}
              onCheck={() => setIsTermsAccepted(!isTermsAccepted)}
            />
          </div>
          <div css={s.buttons}>
            <Button
              // css={s.button}
              variant="course-confirm big"
              text={t('subscriptionPlans.pay')}
              onClick={submitAction}
              disabled={isSubmitRequesting || !isTermsAccepted}
              loading={isSubmitRequesting}
            />
          </div>
          {userCard && (
            <div css={s.otherCard}>
              <Button
                minimal
                icon="credit-card"
                text={t('subscriptionPlans.payOtherCard')}
                variant="pay-other-card"
                disabled={isSubmitRequesting || !isTermsAccepted}
                onClick={() => dispatch(subscribePlan({ name: selectedPlan }))}
              />
            </div>
          )}
        </CollapseContent>
      </Collapse>
    </Dialog>
  );
};

const { bool, func, string } = PropTypes;

Plans.propTypes = {
  close: func.isRequired,
  goBack: func.isRequired,
  defaultSelectedPlan: string,
  isOpen: bool.isRequired,
};

export default Plans;
