import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '~/components/Text';
import {
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import * as s from '../styles/TalkPlayer.styles';

const PlayerStatus = ({ blockStatus }) => {
  const { t } = useTranslation('containersBlock');

  return (
    <div css={s.status}>
      <div className="text">
        <Text value={t(`status.${blockStatus}`)} />
        {[
          BLOCK_PASS_STATUS_PASSED_CORRECTLY,
          BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
        ].includes(blockStatus) && (
          <div css={s.statusMarker} status={blockStatus} />
        )}
      </div>
    </div>
  );
};

const { string } = PropTypes;

PlayerStatus.propTypes = {
  blockStatus: string.isRequired,
};

export default PlayerStatus;
