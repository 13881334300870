import { combineReducers } from 'redux';

import ui from './ui';
import theme from './theme';
import pages from './pages';
import blocks from './blocks';
import lesson from './lesson';
import result from './result';
import lessonDownloadStatus from './lessonDownloadStatus';
import previouslyExecutedResults from './previouslyExecutedResults';
import previouslyExecutedResult from './previouslyExecutedResult';

export default combineReducers({
  ui,
  pages,
  theme,
  blocks,
  lesson,
  result,
  lessonDownloadStatus,
  previouslyExecutedResults,
  previouslyExecutedResult,
});
