import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Button, Text } from '../../components';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/AppearanceBar.scss';

const AppearanceBar = ({
  t,
  theme,
  fontSize,
  toggleMode,
  changeTheme,
  changeFontSize,
}) => (
  <div className="AppearanceBar">
    <div className="font-size-block">
      <Text value={t('appearancebar.fontSize')} variant="appearance-title" />
      {['small', 'medium', 'large'].map((name, index) => (
        <Button
          key={index}
          text="a"
          onClick={() => changeFontSize(name)}
          variant={combineStyles(
            `font-size-${name}`,
            fontSize === name && 'active'
          )}
        />
      ))}
    </div>
    <div className="theme-block">
      <Text value={t('appearancebar.siteColor')} variant="appearance-title" />
      {['light', 'dark', 'blue'].map((name, index) => (
        <Button
          key={index}
          text="a"
          onClick={() => changeTheme(name)}
          variant={combineStyles(`theme-${name}`, theme === name && 'active')}
        />
      ))}
    </div>
    <Button
      text={t('appearancebar.normalVersion')}
      variant="player-bar-toggle-mode"
      icon="eye-open"
      onClick={toggleMode}
    />
  </div>
);

const { func, string } = PropTypes;

AppearanceBar.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Цветовая тема в плеере
  fontSize: string, // Размер шрифта в плеере
  toggleMode: func.isRequired, // Переключить режим плеера
  changeTheme: func.isRequired, // Смена цветовой темы в плеере
  changeFontSize: func.isRequired, // Смена размера шрифта в плеере
};

AppearanceBar.defaultProps = {
  theme: 'light',
  fontSize: 'small',
};
export default withTranslation('containersPlayer')(pure(AppearanceBar));
