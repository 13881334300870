import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TopAlert from '~/components/TopAlert';
import { Text, Icon, Button } from '~/components';
import * as s from './styles/EmailAlert.styles';
import { isRequestActive, isVerificationAlertVisible } from '~/selectors/ui';
import { REQUEST_NAME_SEND_VERIFICATION_EMAIL } from '~/appConstants';
import { reSendVerificationEmail } from '~/actions/auth';
import { isVerificationEmailSent } from '~/selectors/auth';
import withMobileDetect from '~/hocs/withMobileDetect';
import { closeVerificationAlert } from '~/actions/ui';

const EmailAlert = () => {
  const { t } = useTranslation('screens');
  const isSent = useSelector(isVerificationEmailSent);
  const isVisible = useSelector(isVerificationAlertVisible);
  const alertText = isSent ? t('emailAlert.sentText') : t('emailAlert.text');

  const dispatch = useDispatch();

  const isEmailRequesting = useSelector(
    isRequestActive(REQUEST_NAME_SEND_VERIFICATION_EMAIL)
  );

  const buttonSendCaption = isEmailRequesting
    ? t('emailAlert.buttonSendingCaption')
    : t('emailAlert.buttonSendCaption');

  const buttonCloseCaption = t('emailAlert.buttonCloseCaption');

  const sendEmail = () => {
    if (isEmailRequesting) {
      return;
    }
    dispatch(reSendVerificationEmail());
  };

  const closeAlert = () => {
    dispatch(closeVerificationAlert());
  };

  return (
    isVisible && (
      <TopAlert>
        <div css={s.icon}>
          <Icon name="envelope" />
        </div>
        <div css={s.container}>
          <div css={s.line}>
            <div css={s.text}>
              <Text value={alertText} />
            </div>
          </div>
          <div css={s.line}>
            {isSent && (
              <div css={s.button}>
                <Button
                  minimal
                  variant="topalert"
                  text={buttonCloseCaption}
                  onClick={closeAlert}
                />
              </div>
            )}
            <div css={s.button}>
              <Button
                minimal
                variant="topalert"
                text={buttonSendCaption}
                onClick={sendEmail}
              />
            </div>
          </div>
        </div>
      </TopAlert>
    )
  );
};

export default withMobileDetect(EmailAlert);
