import * as LESSON from '../../constants/player/lesson';
import { createAction } from '../../utils/actions';

export const complete = () => createAction(LESSON.COMPLETE);

export const reexecute = ({ lessonId, publication }) =>
  createAction(LESSON.REEXECUTE, { lessonId, publication });

export const sendError = (message) =>
  createAction(LESSON.SEND_ERROR, { message });

export const setRunTime = (newRunTime) =>
  createAction(LESSON.SET_RUN_TIME, { newRunTime });

export const send = () => createAction(LESSON.SEND);

export const sendSuccess = ({ result }) =>
  createAction(LESSON.SEND_SUCCESS, { result });

export const startExecution = ({ lessonId, publication }) =>
  createAction(LESSON.START_EXECUTION, { lessonId, publication });

export const connectUserToResultSuccess = ({ result }) =>
  createAction(LESSON.CONNECT_USER_TO_RESULT_SUCCESS, { result });

export const connectUserToResultError = (message) =>
  createAction(LESSON.CONNECT_USER_TO_RESULT_ERROR, { message });

export const startNewVersion = ({ lessonId, publication }) =>
  createAction(LESSON.START_NEW_VERSION, { lessonId, publication });
export const continueOldVersion = ({ lessonId, publication }) =>
  createAction(LESSON.CONTINUE_OLD_VERSION, { lessonId, publication });
