import objectId from 'bson-objectid';
import { set } from 'lodash/fp';
import * as BLOCK from '../../constants/builder/block';

/**
 * Добавление блока Exam
 */
export const add = (state) => (next) => (action) => {
  if (action.type === BLOCK.ADD && action.payload.type === 'Exam') {
    const isLibraryOpen = state.getState().library.search.isOpen;
    if (isLibraryOpen) {
      return next(action);
    }
    return next(
      set(
        ['meta', 'content'],
        {
          correctAnswers: {
            [objectId.generate()]: '',
          },
          disorderlyCorrectAnswersIds: [],
        },
        action
      )
    );
  } else {
    return next(action);
  }
};
