import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import parseTimestamp from 'date-fns/parse';
import { compose, isFinite } from 'lodash/fp';
import { withHandlers, withProps, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import { minLessonTimeLimit } from '~/reducers/builder/lessons';
import {
  Button,
  Dialog,
  Icon,
  Input,
  Switch,
  Text,
  DateRange,
} from '~/components';
import { combineStyles } from '~/utils/styles';
import * as actions from '~/actions';
import * as selectors from '~/selectors';
import * as helpers from '~/helpers';
import '~/styles/Settings.scss';
import OlympiadSettings from './OlympiadSettings';
import Leveling from './Leveling';
import Uploader from '../Uploader';

/*
сюда нужно добавить аккордеон с с новым скорингом
*/

const Settings = ({
  t,
  close,
  isOpen,
  lessonId,
  changeTab,
  currentTab,
  isUserAdmin,
  selectLevel,
  externalLink,
  selectedLevel,
  lessonEndDate,
  isOlympiadMode,
  lessonStartDate,
  lessonTimeLimit,
  totalTasksCount,
  isLessonLtiAccess,
  changeExternalLink,
  toggleLessonLtiAccess,
  isLessonWithDateRange,
  changeLessonDateRange,
  toggleLessonDateRange,
  changeLessonTimeLimit,
  toggleLessonTimeLimit,
  changeOlympiadSettings,
  olympiadSettings,
  olympiadSettings: {
    authDescription: olympiadAuthDescription = {},
    authImage: olympiadAuthImage = {},
    authTitle: olympiadAuthTitle,
    buttonText: olympiadButtonText,
    descriptionInResult: olympiadDescriptionInResult,
    partnersLogos: olympiadPartnersLogos = {},
  },
}) => (
  <Dialog
    canEscapeKeyClose
    isOpen={isOpen}
    onClose={close}
    variant="settings"
    className="Settings"
  >
    <div className="Settings">
      {isOlympiadMode ? (
        <div className={combineStyles('header', 'olympiad-settings')}>
          <div className="save" />
          <div className="close">
            <Button
              icon="osh-remove"
              onClick={close}
              text={t('settings.close')}
              variant="settings-dark-close"
            />
          </div>
        </div>
      ) : (
        <div className="header">
          <Text value={t('settings.settings')} variant="settings-title" />
          <div className="close">
            <Button
              icon="osh-remove"
              onClick={close}
              variant="settings-close"
            />
          </div>
        </div>
      )}

      {(!isOlympiadMode || (isOlympiadMode && currentTab === 'general')) && (
        <div className="content">
          {/* Ссылка на внешний плеер */}
          {isUserAdmin && (
            <div className="item">
              <Text
                value={t('settings.externalLink')}
                variant="settings-field-label"
              />
              <Input
                onChange={changeExternalLink}
                variant="settings-field"
                value={externalLink}
              />
            </div>
          )}

          {/* Ограничение прохождения урока по времени */}
          <div className="item">
            <div className="time-limiter">
              <div className="toggler">
                <div className="switch">
                  <Switch
                    checked={isFinite(lessonTimeLimit)}
                    onChange={toggleLessonTimeLimit}
                    variant="in-settings"
                  />
                </div>
                <div className="text">
                  <Text
                    value={t('settings.timeLimit')}
                    variant="settings-label"
                  />
                  <div className="icon">
                    <Icon
                      name="help"
                      variant="small-gray"
                      tooltip={t('settings.timeLimitHint')}
                    />
                  </div>
                </div>
              </div>
              {isFinite(lessonTimeLimit) && (
                <div className="changer">
                  <div className="input">
                    <Input
                      icon="time"
                      type="numeric"
                      value={lessonTimeLimit}
                      minValue={minLessonTimeLimit / 60}
                      onChange={changeLessonTimeLimit}
                      stepSize={5}
                      variant="lesson-time-limit"
                    />
                  </div>
                  <div className="info">
                    <Text
                      value={t('settings.minutes')}
                      variant="text-mini-gray"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Ограничение прохождения урока по дате */}
          <div className="item">
            <div className="date-range">
              <div className="toggler">
                <div className="switch">
                  <Switch
                    checked={isLessonWithDateRange}
                    onChange={toggleLessonDateRange}
                    variant="in-settings"
                  />
                </div>
                <div className="text">
                  <Text
                    value={t('settings.dateLimit')}
                    variant="settings-label"
                  />
                  <div className="icon">
                    <Icon
                      name="help"
                      variant="small-gray"
                      tooltip={t('settings.dateLimitHint')}
                    />
                  </div>
                </div>
              </div>
              {isLessonWithDateRange && (
                <div className="changer">
                  <div className="input">
                    <DateRange
                      value={[lessonStartDate, lessonEndDate]}
                      onChange={changeLessonDateRange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {isOlympiadMode && (
            <div className="item">
              <div className="image">
                <div className="toggler">
                  <div className="switch">
                    <Switch
                      checked={olympiadPartnersLogos.isShow}
                      onChange={() =>
                        changeOlympiadSettings(
                          lessonId,
                          ['partnersLogos', 'isShow'],
                          !olympiadPartnersLogos.isShow
                        )
                      }
                      variant="in-settings"
                    />
                  </div>
                  <div className="text">
                    <Text
                      value={t('settings.partnersLogos')}
                      variant="settings-label"
                    />
                  </div>
                </div>
                {olympiadPartnersLogos.isShow &&
                  (olympiadPartnersLogos.source ? (
                    <div className="preview">
                      <img src={olympiadPartnersLogos.source} alt="" />
                      <Button
                        minimal
                        icon="delete"
                        onClick={() =>
                          changeOlympiadSettings(
                            lessonId,
                            ['partnersLogos', 'source'],
                            null
                          )
                        }
                        text={t('settings.removeImage')}
                        variant="settings-image-remove"
                      />
                    </div>
                  ) : (
                    <div className="uploader">
                      <Uploader
                        variant="in-settings"
                        type="settings"
                        icon="osh-imageupload"
                        lessonId={lessonId}
                        pathLastName="partnersLogos"
                        filePath={[lessonId, 'meta', 'settings', 'olympiad']}
                        title={t('settings.uploadImage')}
                        fileType="image"
                        uploadType="lesson"
                      />
                      <div className="hint">
                        <Text
                          value={t('settings.partnersHint')}
                          variant="settings-uploader-hint"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* Ограничение урока по LTI */}
          <div className="item">
            <div className="date-range">
              <div className="toggler">
                <div className="switch">
                  <Switch
                    checked={isLessonLtiAccess}
                    onChange={toggleLessonLtiAccess}
                    variant="in-settings"
                    disabled={isLessonLtiAccess === true}
                  />
                </div>
                <div className="text">
                  <Text
                    value={t('settings.ltiLabel')}
                    variant="settings-label"
                  />
                  <div className="icon">
                    <Icon
                      name="help"
                      variant="small-gray"
                      tooltip={t('settings.ltiHint')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOlympiadMode && currentTab === 'auth' && (
        <div className="content">
          <div className="item">
            <Text value={t('settings.title')} variant="settings-field-label" />
            <Input
              onChange={(value) =>
                changeOlympiadSettings(lessonId, 'authTitle', value)
              }
              variant="settings-field"
              value={t(olympiadAuthTitle)}
            />
          </div>

          <div className="item">
            <div className="toggler">
              <div className="switch">
                <Switch
                  checked={olympiadAuthDescription.isShow}
                  onChange={() =>
                    changeOlympiadSettings(
                      lessonId,
                      ['authDescription', 'isShow'],
                      !olympiadAuthDescription.isShow
                    )
                  }
                  variant="in-settings"
                />
              </div>
              <div className="text">
                <Text
                  value={t('settings.description')}
                  variant="settings-field-label"
                />
              </div>
            </div>
            {olympiadAuthDescription.isShow && (
              <div className="input">
                <Input
                  onChange={(value) =>
                    changeOlympiadSettings(
                      lessonId,
                      ['authDescription', 'text'],
                      value
                    )
                  }
                  variant="settings-field"
                  value={t(olympiadAuthDescription.text)}
                />
              </div>
            )}
          </div>

          <div className="item">
            <div className="toggler">
              <div className="switch">
                <Switch
                  checked={olympiadAuthImage.isShow}
                  onChange={() =>
                    changeOlympiadSettings(
                      lessonId,
                      ['authImage', 'isShow'],
                      !olympiadAuthImage.isShow
                    )
                  }
                  variant="in-settings"
                />
              </div>
              <div className="text">
                <Text
                  value={t('settings.authImage')}
                  variant="settings-label"
                />
              </div>
            </div>
            <div className={combineStyles('image', 'big')}>
              {olympiadAuthImage.isShow &&
                (olympiadAuthImage.source ? (
                  <div className="preview">
                    <img src={olympiadAuthImage.source} alt="" />
                    <Button
                      minimal
                      icon="delete"
                      onClick={() =>
                        changeOlympiadSettings(
                          lessonId,
                          ['authImage', 'source'],
                          null
                        )
                      }
                      text={t('settings.removeImage')}
                      variant="settings-image-remove"
                    />
                  </div>
                ) : (
                  <div className="uploader">
                    <Uploader
                      variant="in-settings"
                      type="settings"
                      icon="osh-imageupload"
                      lessonId={lessonId}
                      pathLastName="authImage"
                      filePath={[lessonId, 'meta', 'settings', 'olympiad']}
                      title={t('settings.uploadImage')}
                      fileType="image"
                      uploadType="lesson"
                    />
                    <div className="hint">
                      <Text
                        value={t('settings.imageHint')}
                        variant="settings-uploader-hint"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="item">
            <Text value={t('settings.button')} variant="settings-field-label" />
            <Input
              onChange={(value) =>
                changeOlympiadSettings(lessonId, 'buttonText', value)
              }
              variant="settings-field"
              value={t(olympiadButtonText)}
            />
          </div>
        </div>
      )}

      {isOlympiadMode && currentTab === 'result' && (
        <div className="content">
          <div className="item">
            <Switch
              label={t('settings.description')}
              checked={olympiadDescriptionInResult}
              onChange={() =>
                changeOlympiadSettings(
                  lessonId,
                  ['descriptionInResult'],
                  !olympiadDescriptionInResult
                )
              }
              variant="in-settings"
            />
            <div className="hint">
              <Text
                value={t('settings.descriptionInResultHint')}
                variant="settings-uploader-hint"
              />
            </div>
          </div>

          <div className="item">
            <div className="title">
              <Text
                value={t('settings.scoreTitle')}
                variant="settings-score-title"
              />
            </div>
            <div className="time-limiter">
              <div className="toggler">
                <div className="text">
                  <Text
                    value={t('settings.maxScore')}
                    variant="settings-label"
                  />
                  <div className="icon">
                    <Icon
                      name="help"
                      variant="small-gray"
                      tooltip={t('settings.maxScoreHint')}
                    />
                  </div>
                </div>
                <Text value={totalTasksCount} variant="settings-max-score" />
              </div>
            </div>
          </div>

          <Leveling selectLevel={selectLevel} lessonId={lessonId} />
        </div>
      )}
    </div>
    {isOlympiadMode && (
      <OlympiadSettings
        lessonId={lessonId}
        changeTab={changeTab}
        currentTab={currentTab}
        selectedLevel={selectedLevel}
        selectLevel={selectLevel}
        isOlympiadMode={isOlympiadMode}
        totalTasksCount={totalTasksCount}
        olympiadSettings={olympiadSettings}
      />
    )}
  </Dialog>
);

const { bool, string, func, number, instanceOf, object } = PropTypes;

Settings.propTypes = {
  t: func.isRequired, // Функция перевода
  close: func.isRequired, // Закрыть окно результатов
  selectLevel: func.isRequired,
  selectedLevel: number.isRequired,
  isOpen: bool, // Окно результатов открыто?
  lessonId: string, // ID урока
  changeTab: func.isRequired, // Функция смены вкладки
  currentTab: string, // Функция смены вкладки
  isUserAdmin: bool.isRequired, // Пользователь имеет статус админа?
  externalLink: string, // Ссылка на внешний плеер
  lessonEndDate: instanceOf(Date), // Дата, начиная с которой можно будет проходить урок
  isOlympiadMode: bool, // Режим олимпиады включен?
  lessonStartDate: instanceOf(Date), // Дата, до которой нужно пройти урок
  lessonTimeLimit: number, // Ограничение по времени на прохождение урока
  totalTasksCount: number, // Количество заданий
  olympiadSettings: object, // Объект данных настройки олимпиады
  isLessonLtiAccess: bool, // Доступ ограничен по LTI?
  changeExternalLink: func.isRequired, // Изменить ссылку на внешний плеер
  toggleLessonLtiAccess: func, // Переключения доступа по LTI
  isLessonWithDateRange: bool, // В уроке включено ограничение по датам?
  changeLessonDateRange: func.isRequired, // Изменение дат прохождения урока
  toggleLessonDateRange: func.isRequired, // Включить/выключить доступность урока в определенные даты
  changeLessonTimeLimit: func.isRequired, // Изменить ограничение по времени для урока
  toggleLessonTimeLimit: func.isRequired, // Включить/выключить ограничение по времени для урока
  changeOlympiadSettings: func, // Изменение настроек олимпиады
};

Settings.defaultProps = {
  isOpen: false,
  lessonId: undefined,
  externalLink: undefined,
  lessonEndDate: undefined,
  isOlympiadMode: false,
  lessonStartDate: undefined,
  lessonTimeLimit: undefined,
  totalTasksCount: undefined,
  olympiadSettings: {
    authDescription: {},
    authImage: {},
    partnersLogos: {},
    results: {
      levels: [],
    },
  },
  isLessonLtiAccess: false,
  toggleLessonLtiAccess: undefined,
  isLessonWithDateRange: false,
  changeOlympiadSettings: undefined,
};

const mapStateToProps = (state) => {
  const { isOpen, lessonId } = state.settings;

  const totalTasksCount = helpers.settings.getTotalTasksCount(lessonId, state);
  const externalLink = selectors.builder.lesson.getExternalLink(
    lessonId,
    state
  );
  const isLessonLtiAccess = selectors.builder.lesson.getLessonLtiAccess(
    lessonId,
    state
  );
  const lessonEndDate = selectors.builder.lesson.getEndDate(lessonId, state);
  const lessonStartDate = selectors.builder.lesson.getStartDate(
    lessonId,
    state
  );
  const lessonTimeLimit = selectors.builder.lesson.getTimeLimit(
    lessonId,
    state
  );
  const olympiadSettings = selectors.builder.lesson.getOlympiadSettings(
    lessonId,
    state
  );
  const isLessonWithDateRange = selectors.builder.lesson.withDateRange(
    lessonId,
    state
  );

  return {
    isOpen,
    lessonId,
    externalLink,
    lessonEndDate,
    lessonStartDate,
    lessonTimeLimit,
    totalTasksCount,
    olympiadSettings,
    isLessonLtiAccess,
    isLessonWithDateRange,
  };
};

const mapDispatchToProps = {
  close: actions.settings.close,
  showNotice: actions.notice.show,
  changeExternalLink: actions.builder.lesson.changeExternalLink,
  toggleLessonLtiAccess: actions.builder.lesson.toggleLessonLtiAccess,
  changeLessonDateRange: actions.builder.lesson.changeDateRange,
  toggleLessonDateRange: actions.builder.lesson.toggleDateRange,
  changeLessonTimeLimit: actions.builder.lesson.changeTimeLimit,
  toggleLessonTimeLimit: actions.builder.lesson.toggleTimeLimit,
  changeOlympiadSettings: actions.builder.lesson.changeOlympiadSettings,
};

const enhance = compose(
  withProps(({ lessonEndDate, lessonTimeLimit, lessonStartDate }) => ({
    lessonEndDate: lessonEndDate ? parseTimestamp(lessonEndDate * 1000) : null,
    lessonStartDate: lessonStartDate
      ? parseTimestamp(lessonStartDate * 1000)
      : null,
    lessonTimeLimit: lessonTimeLimit / 60,
  })),
  withState('currentTab', 'changeTab', 'general'),
  withState('selectedLevel', 'selectLevel', 0),
  withHandlers({
    changeExternalLink: (props) => (externalLink) =>
      props.changeExternalLink(
        props.lessonId,
        helpers.lesson.clearURL(externalLink)
      ),
    changeLessonDateRange: (props) => ([newStartDate, newEndDate]) =>
      props.changeLessonDateRange(props.lessonId, newStartDate, newEndDate),
    toggleLessonDateRange: (props) => () =>
      props.toggleLessonDateRange(props.lessonId),
    changeLessonTimeLimit: (props) => (newTimeLimit) =>
      props.changeLessonTimeLimit(props.lessonId, newTimeLimit * 60),
    toggleLessonTimeLimit: (props) => () =>
      props.toggleLessonTimeLimit(props.lessonId),
    toggleLessonLtiAccess: (props) => () =>
      props.toggleLessonLtiAccess(props.lessonId, props.isLessonLtiAccess),
  })
);

export default compose(
  withTranslation('containers'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance
)(Settings);
