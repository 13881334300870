import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { useSelector } from 'react-redux';
import { find, getOr, get, isUndefined } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import {
  selectCourseSelectOptions,
  selectLessonSelectOptions,
} from '~/selectors/builder/main';
import Suggest from '~/components/Suggest';
import { Icon } from '~/components';
import * as s from './styles/ReviewAnswerBlocksFilter.styles';

const ReviewAnswerBlocksFilter = ({ onFilter, filterData, onFilterReset }) => {
  const courseId = get(['courseId'], filterData);
  const lessonId = get(['lessonId'], filterData);
  const { t } = useTranslation('screens');
  const courses = useSelector(selectCourseSelectOptions(t));
  const lessons = useSelector(selectLessonSelectOptions(t, courseId));

  const onChangeCourse = useCallback(
    ({ id }) => onFilter({ courseId: id, lessonId: null }),
    [onFilter]
  );

  const onChangeLesson = useCallback(
    ({ id, parentId }) =>
      onFilter({
        lessonId: id,
        courseId: parentId,
      }),
    [onFilter]
  );

  const onResetFilters = useCallback(() => onFilterReset(), [onFilterReset]);

  return (
    <div css={s.filterBlocks}>
      <div
        css={s.filterIcon}
        isActive={!!courseId || !!lessonId || isUndefined(courseId)}
        onClick={onResetFilters}
      >
        <Icon name="filter-remove" color="#b2b6b8" size={20} />
      </div>
      <Suggest
        items={courses}
        title={t('review.course')}
        placeholder={t('review.filter.searchCourse')}
        selectedId={courseId}
        selectedName={getOr(
          t('review.filter.noName'),
          'name',
          find({ id: courseId }, courses)
        )}
        onChange={onChangeCourse}
      />
      <div css={s.filterBlocksArrow}>
        <Icon name="chevron-right" color="#b2b6b8" size={14} />
      </div>
      <Suggest
        items={lessons}
        title={t('review.lesson')}
        placeholder={t('review.filter.searchLesson')}
        selectedId={lessonId}
        selectedName={getOr(
          t('review.filter.noName'),
          'name',
          find({ id: lessonId }, lessons)
        )}
        onChange={onChangeLesson}
      />
    </div>
  );
};

const { func, object } = PropTypes;

ReviewAnswerBlocksFilter.propTypes = {
  onFilter: func,
  filterData: object,
  onFilterReset: func,
};
export default pure(ReviewAnswerBlocksFilter);
