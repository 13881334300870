export const ADD_ANSWER = '@builder/block/quiz/Добавление нового ответа';
export const ADD_ANSWER_OTHER = '@builder/block/quiz/Добавление ответа Другое';
export const REMOVE_ANSWER_OTHER = '@builder/block/quiz/Удаление ответа Другое';
export const SET_ANSWER = '@builder/block/quiz/Установка правильного ответа';
export const MOVE_ANSWER = '@builder/block/quiz/Перемещение ответа';
export const TOGGLE_TYPE = '@builder/block/quiz/Переключение типа теста';
export const CHANGE_ANSWER = '@builder/block/quiz/Изменение текста ответа';
export const REMOVE_ANSWER = '@builder/block/quiz/Удаление ответа';
export const TOGGLE_COMMENT = '@builder/block/quiz/Переключить отображение комментария к ответу';
export const CHANGE_COMMENT = '@builder/block/quiz/Изменение комментария к ответу';
export const TOGGLE_SHUFFLE = '@builder/block/quiz/Переключение режима перемешивания ответов';
export const CHANGE_QUANTITY = '@builder/block/quiz/Изменение количества ответов в тесте';
export const CHANGE_QUESTION = '@builder/block/quiz/Изменение текста вопроса';
export const CHANGE_DESCRIPTION = '@builder/block/quiz/Изменение описания вопроса';
// Для весовых вопросов
export const CHANGE_LEVEL = '@builder/block/quiz/Изменение уровня вопроса';
export const CHANGE_GROUP = '@builder/block/quiz/Изменение группы вопроса';
export const CHANGE_WEIGHT = '@builder/block/quiz/Изменение веса ответа';
export const CHANGE_COMPETENCE = '@builder/block/quiz/Изменение компетенции вопроса';