import { set, get } from 'lodash/fp';
import * as PAGE from '../../constants/builder/page';
import * as CLYPBOARD from '../../constants/clypboard';
import * as helpers from '../../helpers';
/**
 * Клонирование страницы
 */
export const clone = (state) => (next) => (action) => {
  if (action.type === PAGE.CLONE || action.type === CLYPBOARD.COPY_PAGE) {
    const pageId = action.payload.id;
    const allPages = state.getState().builder.pages;
    const allBlocks = state.getState().builder.blocks;
    const { page, blocks } = helpers.page.clone(pageId, allPages, allBlocks);
    return next(
      set(
        'meta',
        {
          clonedPage: page,
          clonedBlocks: blocks,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};

/**
 * Вставка урока.
 * Достаем из стора clypboard предварительно скопированные страницы
 */
export const paste = (state) => (next) => (action) => {
  if (action.type === PAGE.PASTE) {
    const { pages, blocks } = state.getState().clypboard;
    return next(
      set(
        'meta',
        {
          storedPages: pages,
          storedBlocks: blocks,
        },
        action
      )
    );
  } else {
    return next(action);
  }
};

/**
 * Удаление страницы
 */
export const remove = (state) => (next) => (action) => {
  if (action.type === PAGE.REMOVE) {
    const pageId = action.payload.id;
    const allPages = state.getState().builder.pages;
    const pageBlocksIds = get([pageId, 'blocksIds'], allPages);
    return next(set('meta', { removedBlocksIds: pageBlocksIds }, action));
  } else {
    return next(action);
  }
};
