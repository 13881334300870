import objectId from 'bson-objectid';
import * as BLOCK from '../../../constants/builder/block';
import { createAction } from '../../../utils/actions';

import * as quiz from './quiz';
import * as exam from './exam';
import * as match from './match';
import * as answer from './answer';
import * as images from './images';
import * as feedback from './feedback';
import * as document from './document';
import * as fillblank from './fillblank';
import * as talk from './talk';

export {
  exam,
  quiz,
  talk,
  match,
  answer,
  images,
  document,
  feedback,
  fillblank,
};

export const add = (pageId, type, index, content) =>
  createAction(BLOCK.ADD, {
    id: objectId.generate(),
    type,
    index,
    pageId,
    content,
  });
export const move = (id, oldIndex, newIndex, oldPageId, newPageId) =>
  createAction(BLOCK.MOVE, { id, oldIndex, newIndex, newPageId, oldPageId });
export const clone = (id, pageId) => createAction(BLOCK.CLONE, { id, pageId });
export const clean = (id) => createAction(BLOCK.CLEAN, { id });
export const remove = (id, pageId) =>
  createAction(BLOCK.REMOVE, { id, pageId });
export const changeContent = (id, newContent) =>
  createAction(BLOCK.CHANGE_CONTENT, { id, newContent });
export const changeComment = (id, type, comment) =>
  createAction(BLOCK.CHANGE_COMMENT, { id, type, comment });
