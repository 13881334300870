import React from 'react';
import PropTypes from 'prop-types';
import { compose, isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import { withHandlers, lifecycle, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  BLOCK_PASS_STATUS_PASSED_CORRECTLY,
  BLOCK_PASS_STATUS_PASSED_INCORRECTLY,
} from '~/appConstants';
import {
  Text,
  Froala,
  Button,
  Input,
  Basket,
  DraggableTag,
} from '../../components';
import { Status, BlockComment } from '../../components/Block';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as helpers from '../../helpers';
import '../../styles/Match.scss';

const Match = ({
  t,
  blockId,
  comment,
  progress,
  inPlayer,
  inReview,
  blockType,
  inBuilder,
  isCorrect,
  inLibraryEdit,
  isOlympiadMode,
  inLibraryShare,
  inPresentation,
  inLibrarySearch,
  isPageInExamMode,
  isNotRegularBlock,
  isPageInVerifyMode,
  baskets,
  answers,
  question,
  addBasket,
  moveAnswer,
  description,
  removeBasket,
  changeAnswers,
  changeQuestion,
  combinedAnswers,
  changeBasketName,
  isLessonComplete,
  changeDescription,
  playerCombinedAnswers,
}) =>
  inPlayer || inPresentation || inReview ? (
    <div className="Match">
      <div className="question">
        <Text value={question} variant="answer-question" />
      </div>
      <div className="description">
        <Froala mode="preview" content={description} />
      </div>
      <div className="scroll">
        <div
          className={combineStyles('baskets', [
            `count-${Object.keys(baskets).length}`,
            'in-player',
          ])}
        >
          {Object.keys(baskets).map((basketId) => (
            <div className="basket" key={basketId}>
              <Basket
                variant="in-player"
                blockId={blockId}
                inReview={inReview}
                basketId={basketId}
                inPlayer={inPlayer}
                inPresentation={inPresentation}
                moveAnswer={moveAnswer}
                name={baskets[basketId].name}
                isOlympiadMode={isOlympiadMode}
                isPageInVerifyMode={isPageInVerifyMode}
                isPageInExamMode={isPageInExamMode}
                answers={baskets[basketId].answers}
                isLessonComplete={isLessonComplete}
                userAnswers={
                  inReview &&
                  (answers &&
                    answers[basketId] &&
                    answers[basketId].selectedAnswers)
                }
              />
            </div>
          ))}
        </div>
        <div
          className={combineStyles('names', [
            `count-${Object.keys(baskets).length}`,
            'in-player',
          ])}
        >
          {Object.keys(baskets).map((basketId, index) => (
            <div className="name" key={index}>
              <Text
                value={baskets[basketId].name}
                variant="match-basket-name"
                multiline
              />
            </div>
          ))}
        </div>
      </div>
      <div className="answers">
        {(inReview ? combinedAnswers : playerCombinedAnswers).map(
          (answer, index) => (
            <DraggableTag
              key={index}
              value={answer}
              inPlayer={inPlayer || inPresentation}
              noDrag={isLessonComplete}
              inReview={inReview}
              isOlympiadMode={isOlympiadMode}
              variant={inReview && 'in-result'}
            />
          )
        )}
      </div>
      <Status
        blockType={blockType}
        matchProgress={progress}
        correctMatchAnswers={baskets}
        selectedMatchAnswers={answers}
        isNotRegularBlock={isNotRegularBlock}
      />
      {isOlympiadMode
        ? isLessonComplete &&
          !isEmpty(comment) && (
            <BlockComment
              inPlayer={inPlayer || inPresentation}
              blockId={blockId}
              comments={comment}
              blockPassStatus={
                isCorrect
                  ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                  : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
              }
            />
          )
        : // Ниже проверка на isEmpty, для того чтобы определить, что блок пройден
          !isPageInExamMode &&
          (isEmpty(playerCombinedAnswers) || isLessonComplete) &&
          !isEmpty(comment) &&
          !inPresentation && (
            <BlockComment
              inPlayer={inPlayer || inPresentation}
              blockId={blockId}
              comments={comment}
              blockPassStatus={
                isCorrect
                  ? BLOCK_PASS_STATUS_PASSED_CORRECTLY
                  : BLOCK_PASS_STATUS_PASSED_INCORRECTLY
              }
            />
          )}
    </div>
  ) : inBuilder || inLibraryEdit || inLibraryShare || inLibrarySearch ? (
    <div className="Match">
      <div className="question">
        <Text
          value={question}
          variant="answer-question"
          editable
          multiline
          onChange={changeQuestion}
          placeholder={t('match.changeQuestion')}
        />
      </div>
      <div className="description">
        <Froala
          mode="editor"
          toolbarButtons={[
            'bold',
            'align',
            'quote',
            'italic',
            'indent',
            'outdent',
            'formatUL',
            'formatOL',
            'underline',
            'insertLink',
            'insertTable',
            'insertImage',
            'strikeThrough',
            'paragraphFormat',
          ]}
          content={description}
          onChange={changeDescription}
        />
      </div>
      <div className="scroll">
        <div
          className={combineStyles(
            'baskets',
            `count-${Object.keys(baskets).length}`
          )}
        >
          {Object.keys(baskets).map((basketId) => (
            <div className="basket" key={basketId}>
              <div className="input">
                <Input
                  value={baskets[basketId].answers}
                  variant="match-tags"
                  withTags
                  placeholder={t('match.writeWord')}
                  onChange={(newAnswers = baskets[basketId].answers) =>
                    changeAnswers(basketId, newAnswers)
                  }
                />
              </div>
              {Object.keys(baskets).length > 1 && (
                <div className="button">
                  <Button
                    minimal
                    icon="cross"
                    variant="match-basket-remove"
                    onClick={() => removeBasket(basketId)}
                  />
                </div>
              )}
            </div>
          ))}
          {Object.keys(baskets).length < 4 && (
            <div className="button">
              <Button
                icon="osh-addnew-little"
                text={t('match.addBasket')}
                variant="match-add-basket"
                onClick={addBasket}
              />
            </div>
          )}
        </div>
        <div
          className={combineStyles(
            'names',
            `count-${Object.keys(baskets).length}`
          )}
        >
          {Object.keys(baskets).map((basketId, index) => (
            <div className="name" key={index}>
              <Text
                value={baskets[basketId].name}
                variant="match-basket-name"
                editable
                multiline
                onChange={(newBasketName) =>
                  changeBasketName(basketId, newBasketName)
                }
                placeholder={t('match.changeBasketName')}
              />
            </div>
          ))}
          {Object.keys(baskets).length < 4 && <div className="fake-name" />}
        </div>
      </div>
      {!isPageInExamMode && (
        <BlockComment blockId={blockId} comments={comment} />
      )}
    </div>
  ) : (
    undefined
  );

const {
  string,
  object,
  func,
  bool,
  arrayOf,
  array,
  oneOfType,
  oneOf,
} = PropTypes;

Match.propTypes = {
  t: func.isRequired, // Функция перевода
  blockId: string.isRequired, // ID блока
  comment: object, // Комментарии к блоку
  progress: array, // Прогресс прохождения блока
  answers: oneOfType([array, object]), // Ответы пользователя
  blockType: oneOf(['Match']).isRequired, // Тип блока
  inPlayer: bool, // В плеере?
  inReview: bool, // В окне результатов?
  inBuilder: bool, // В конструкторе?
  isCorrect: bool.isRequired, // Задание выполнено верно?
  inLibraryEdit: bool, // В окне редактирования библиотеки?
  inLibraryShare: bool, // В окне шаринга библиотеки?
  inPresentation: bool, // В режиме презентации?
  inLibrarySearch: bool, // В окне поиска блоков?
  isOlympiadMode: bool, // Блок в Олимпиаде?
  combinedAnswers: arrayOf(string), // Массив со всеми ответами в случайном порядке каждой корзины
  isPageInExamMode: bool, // Блок на контрольной странице?
  isNotRegularBlock: bool, // Блок не находится на контрольной странице или в олимпиаде?
  isPageInVerifyMode: bool, // Задания на странице в режиме проверки?
  baskets: object, // Массив ID корзин
  question: string, // Вопрос
  addBasket: func.isRequired, // Функция добавления корзины
  description: string, // Описание
  moveAnswer: func, // Переместить ответ
  removeBasket: func.isRequired, // Функция удаления корзины
  changeAnswers: func.isRequired, // Функция изменения ответа
  changeQuestion: func.isRequired, // Функция изменения вопроса
  isLessonComplete: bool, // Блок в режиме просмотра?
  changeBasketName: func.isRequired, // Функция изменения названия корзины
  changeDescription: func.isRequired, // Функция изменения описания
  playerCombinedAnswers: arrayOf(string), // Массив со всеми ответами для плеера
};

Match.defaultProps = {
  // theme: 'light',
  // fontSize: 'small',
  comment: {},
  answers: [],
  progress: [],
  inPlayer: undefined,
  inReview: undefined,
  inBuilder: undefined,
  inLibraryEdit: undefined,
  inPresentation: undefined,
  isOlympiadMode: undefined,
  inLibraryShare: undefined,
  inLibrarySearch: undefined,
  combinedAnswers: [],
  isPageInExamMode: undefined,
  isNotRegularBlock: undefined,
  isPageInVerifyMode: undefined,
  baskets: {},
  question: undefined,
  moveAnswer: undefined,
  description: undefined,
  isLessonComplete: false,
  playerCombinedAnswers: [],
};

const mapStateToProps = (state, ownProps) => {
  const {
    blockId,
    progress,
    isOlympiadMode,
    isPageInExamMode,
    inPresentation,
  } = ownProps;
  const playerCombinedAnswers = inPresentation
    ? selectors.presentation.getCombinedAnswers(blockId, state)
    : selectors.player.getCombinedAnswers(blockId, state);
  const basketSelectedAnswers = (basketId) =>
    inPresentation
      ? selectors.presentation.getBasketSelectedAnswers(
          blockId,
          basketId,
          state
        )
      : selectors.player.getBasketSelectedAnswers(blockId, basketId, state);

  return {
    blockId,
    progress,
    isOlympiadMode,
    isPageInExamMode,
    basketSelectedAnswers,
    playerCombinedAnswers,
  };
};

const mapDispatchToProps = {
  addBasket: actions.builder.block.match.addBasket,
  moveAnswer: actions.player.block.match.moveAnswer,
  removeBasket: actions.builder.block.match.removeBasket,
  changeAnswers: actions.builder.block.match.changeAnswers,
  changeQuestion: actions.builder.block.match.changeQuestion,
  changeBasketName: actions.builder.block.match.changeBasketName,
  changeDescription: actions.builder.block.match.changeDescription,
  setCombinedAnswers: actions.player.block.match.setCombinedAnswers,
};

const handlersEnhance = withHandlers({
  addBasket: (props) => () => props.addBasket(props.blockId),
  removeBasket: (props) => (basketId) =>
    props.removeBasket(props.blockId, basketId),
  changeAnswers: (props) => (basketId, newAnswers) =>
    props.changeAnswers(props.blockId, basketId, newAnswers),
  changeQuestion: (props) => (newQuestion) =>
    props.changeQuestion(props.blockId, newQuestion),
  changeBasketName: (props) => (basketId, newBasketName) =>
    props.changeBasketName(props.blockId, basketId, newBasketName),
  changeDescription: (props) => (newDescription) =>
    props.changeDescription(props.blockId, newDescription),
  setCombinedAnswers: (props) => () =>
    props.setCombinedAnswers(
      props.blockId,
      helpers.block.shuffleBasketsAnswers(props.baskets)
    ),
});

const propsEnhance = withProps(({ answers, baskets }) => ({
  isCorrect: helpers.block.isMatchCorrect(answers, baskets),
}));

export default compose(
  withTranslation('containersBlock'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  handlersEnhance,
  propsEnhance,
  lifecycle({
    componentDidMount() {
      const {
        baskets,
        blockId,
        setCombinedAnswers,
        playerCombinedAnswers,
      } = this.props; // eslint-disable-line fp/no-this
      if (!playerCombinedAnswers) {
        setCombinedAnswers(
          blockId,
          helpers.block.shuffleBasketsAnswers(baskets)
        );
      }
    },
  })
)(Match);
