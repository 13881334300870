import { createAction } from '~/utils/actions';
import {
  DOWNLOAD_COURSE,
  DOWNLOAD_COURSE_SUCCESS,
  JOIN_COURSE,
  JOIN_COURSE_SUCCESS,
  REJECT_COURSE,
  REJECT_COURSE_SUCCESS,
  DOWNLOAD_LESSONS,
  DOWNLOAD_LESSONS_SUCCESS,
} from '~/constants/tutors';

export const downloadCourse = ({ courseId }) =>
  createAction(DOWNLOAD_COURSE, { courseId });

export const downloadCourseSuccess = ({ course }) =>
  createAction(DOWNLOAD_COURSE_SUCCESS, { course });

export const joinCourse = ({ courseId }) =>
  createAction(JOIN_COURSE, { courseId });

export const joinCourseSuccess = ({ courseId }) =>
  createAction(JOIN_COURSE_SUCCESS, { courseId });

export const rejectCourse = ({ courseId }) =>
  createAction(REJECT_COURSE, { courseId });

export const rejectCourseSuccess = ({ courseId }) =>
  createAction(REJECT_COURSE_SUCCESS, { courseId });

export const downloadTutorLessons = () => createAction(DOWNLOAD_LESSONS);

export const downloadTutorLessonsSuccess = ({ lessons }) =>
  createAction(DOWNLOAD_LESSONS_SUCCESS, { lessons });
