import React from 'react';

import { compose, branch, lifecycle } from 'recompose';

export const SSRContext = React.createContext(false);

const withSSRHandler = (handler) =>
  compose(
    // eslint-disable-next-line react/display-name
    (WrappedComponent) => (props) => (
      <SSRContext.Consumer>
        {(preventHandlers) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <WrappedComponent {...props} preventHandlers={preventHandlers} />
        )}
      </SSRContext.Consumer>
    ),
    branch(
      // eslint-disable-next-line more/no-window
      ({ preventHandlers }) => !preventHandlers && !window.hydration,
      lifecycle({
        componentWillMount() {
          handler(this.props); // eslint-disable-line fp/no-this
        },
      })
    )
  );

export default withSSRHandler;
