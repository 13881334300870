import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect, useSelector } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import withMobileDetect from '~/hocs/withMobileDetect';

import Login, {
  validationSchema as loginSchema,
} from '~/containers/Auth/Login';
import Register, {
  validationSchema as registerSchema,
} from '~/containers/Auth/Register';
import {
  AUTH_MODE_LOGIN,
  AUTH_MODE_REGISTER,
  REQUEST_NAME_AUTH,
} from '~/appConstants';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import '../../styles/Auth.scss';
import '../../styles/ktz/Auth.scss';
import { isRequestActive } from '~/selectors/ui';
import SelectLocale from '~/containers/SelectLocale';
import Quote from '~/components/Auth/Quote';
import SwitchMode from '~/components/Auth/SwitchMode';
import { DraggedButton as Button } from '~/components/Button';
// import HelpRow from '~/components/HelpRow';

const { bool, oneOf, func, string, oneOfType, shape, object } = PropTypes;

const getDefaultValues = ({ mode }) => {
  switch (mode) {
    case AUTH_MODE_REGISTER:
      return {
        email: '',
        password: '',
        lastName: '',
        firstName: '',
        termsAgree: false,
      };
    case AUTH_MODE_LOGIN:
      return {
        email: '',
        password: '',
      };
    default:
      return null;
  }
};

const Form = ({
  mode,
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  handleSubmit,
}) => {
  const Component =
    mode === AUTH_MODE_LOGIN
      ? Login
      : mode === AUTH_MODE_REGISTER
      ? Register
      : null;

  return (
    <Component
      values={values}
      errors={errors}
      touched={touched}
      isSubmitting={isSubmitting}
      setFieldValue={setFieldValue}
      handleSubmit={handleSubmit}
    />
  );
};

Form.propTypes = {
  mode: string.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  isSubmitting: bool.isRequired,
  setFieldValue: func.isRequired,
  handleSubmit: func.isRequired,
};

const Auth = ({
  t,
  mode,
  values,
  errors,
  touched,
  setMode,
  isMobile,
  handleSubmit,
  setFieldValue,
}) => {
  const isSubmitting = useSelector(isRequestActive(REQUEST_NAME_AUTH));

  return (
    <div className={combineStyles('Auth', [isMobile && 'mobile'])}>
      <>
        <SelectLocale variant="in-auth" />
        <Quote />
      </>
      <div className="content">
        <SwitchMode
          mode={mode}
          setMode={setMode}
          context="auth"
          isLoading={isSubmitting}
        />
        <div className="form">
          <Form
            mode={mode}
            values={values}
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
          />
        </div>
        {
          <div className="send">
            <Button
              fill
              text={
                {
                  [AUTH_MODE_LOGIN]: t('index.enter'),
                  [AUTH_MODE_REGISTER]: t('index.register'),
                }[mode]
              }
              type="primary"
              large
              loading={isSubmitting}
              disabled={isSubmitting}
              variant="auth-send"
              onClick={handleSubmit}
            />
          </div>
        }
        {/* {mode === AUTH_MODE_LOGIN && (
          <div className="recover">
            <Link to="/recovery">{t('index.forget')}</Link>
          </div>
        )} */}
        {/* <HelpRow /> */}
      </div>
    </div>
  );
};

Auth.propTypes = {
  t: func.isRequired,
  mode: oneOf([AUTH_MODE_LOGIN, AUTH_MODE_REGISTER]).isRequired, // Тип авторизации - логин или регистрация
  setMode: func.isRequired, // Выбрать тип авторизации
  isMobile: bool.isRequired,
  handleSubmit: func.isRequired,
  values: oneOfType([
    shape({
      email: string.isRequired,
      password: string.isRequired,
    }).isRequired,
    shape({
      email: string.isRequired,
      password: string.isRequired,
      lastName: string.isRequired,
      firstName: string.isRequired,
      termsAgree: bool.isRequired,
    }).isRequired,
  ]).isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  setFieldValue: func.isRequired,
};

const mapDispatchToProps = {
  sendRequest: actions.auth.sendRequest,
};

export default compose(
  withTranslation('containersAuth'),
  withMobileDetect,
  connect(
    null,
    mapDispatchToProps
  ),
  withState('mode', 'setMode', AUTH_MODE_LOGIN),
  withFormik({
    mapPropsToValues: ({ mode }) => getDefaultValues({ mode }),
    handleSubmit: (values, { props: { mode, sendRequest, location } }) => {
      const { goto = '/' } = queryString.parse(location.search);
      sendRequest({ mode, goto, form: values });
    },
    validationSchema: ({ mode }) => {
      switch (mode) {
        case AUTH_MODE_REGISTER:
          return registerSchema;
        case AUTH_MODE_LOGIN:
          return loginSchema;
        default:
          return null;
      }
    },
  }),
  withHandlers({
    setMode: ({ resetForm, setMode }) => (mode) => {
      resetForm(getDefaultValues({ mode }));
      setMode(mode);
    },
  })
)(Auth);
