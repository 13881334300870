import {
  set,
  curry,
  compose,
  omit,
  update,
  filter,
  pull,
  findIndex,
  eq,
  identity,
  mapValues,
} from 'lodash/fp';
import { push, insertAt } from '~/utils/array';
import {
  TALK_CARD_TYPE_QUESTION,
  TALK_TARGET_TYPE_CONTENT_CARD,
} from '~/appConstants';
import * as TALK from '~/actions/builder/block/talk';

export const defaultState = {
  name: '',
  description: '',
  minimalSuccessScore: 0,
  cards: {},
  cardsIds: [],
  answers: {},
};

const createCard = (id) => ({
  id,
  question: '',
  information: '',
  type: TALK_CARD_TYPE_QUESTION,
  answersIds: [],
});

const createTarget = (cardId) => ({
  cardId,
  type: TALK_TARGET_TYPE_CONTENT_CARD,
});

const talkReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TALK.ADD_CARD: {
      const { cardId, targetedAnswerId, targetedCardId } = action.payload;
      return compose(
        targetedAnswerId
          ? set(['answers', targetedAnswerId, 'target'], createTarget(cardId))
          : identity,
        targetedCardId
          ? set(['cards', targetedCardId, 'target'], createTarget(cardId))
          : identity,
        set(['cards', cardId], createCard(cardId)),
        update('cardsIds', push(cardId))
      )(state);
    }

    case TALK.REMOVE_CARD_ACCEPT: {
      const { cardId } = action.payload;

      const clearTargets = mapValues((value) =>
        value?.target?.cardId === cardId ? omit('target', value) : value
      );

      return compose(
        update(['cards'], clearTargets),
        update(['answers'], clearTargets),
        update(['cards'], omit(cardId)),
        update('cardsIds', pull(cardId))
      )(state);
    }

    case TALK.MOVE_CARD_TO_START: {
      const { cardId } = action.payload;
      return update(
        'cardsIds',
        compose(
          insertAt(0, cardId),
          filter((id) => cardId !== id)
        )
      )(state);
    }

    case TALK.CLEAR_CONTENT: {
      const { cardId } = action.payload;
      return set(['cards', cardId], createCard(cardId))(state);
    }

    case TALK.CLONE_CARD: {
      const { cardId, newCardId } = action.payload;

      return compose(
        update(['cards'], (cards) =>
          set([newCardId], set('id', newCardId, cards[cardId]), cards)
        ),
        update('cardsIds', (cardIds) => {
          const newIndex = findIndex(eq(cardId), cardIds) + 1;

          return [
            ...cardIds.slice(0, newIndex),
            newCardId,
            ...cardIds.slice(newIndex),
          ];
        })
      )(state);
    }

    case TALK.ADD_ANSWER: {
      const { cardId, target, answerId } = action.payload;
      return compose(
        set(['answers', answerId], { id: answerId, score: 0, target }),
        update(['cards', cardId, 'answersIds'], push(answerId))
      )(state);
    }

    case TALK.REMOVE_ANSWER: {
      const { cardId, answerId } = action.payload;
      return compose(
        update(['answers', answerId], omit(answerId)),
        update(['cards', cardId, 'answersIds'], pull(answerId))
      )(state);
    }

    case TALK.CHANGE_ANSWER_TEXT: {
      const { answerId, text } = action.payload;
      return set(['answers', answerId, 'answer'], text)(state);
    }

    case TALK.REMOVE_IMAGE: {
      const { cardId } = action.payload;
      return set(['cards', cardId, 'image'], null)(state);
    }

    case TALK.CHANGE_NAME: {
      const { name } = action.payload;
      return set(['name'], name)(state);
    }
    case TALK.CHANGE_DESCRIPTION: {
      const { description } = action.payload;
      return set(['description'], description)(state);
    }
    case TALK.CHANGE_CARD_QUESTION: {
      const { cardId, question } = action.payload;
      return set(['cards', cardId, 'question'], question)(state);
    }
    case TALK.CHANGE_CARD_INFORMATION: {
      const { cardId, information } = action.payload;
      return set(['cards', cardId, 'information'], information)(state);
    }

    case TALK.CHANGE_ANSWER_TARGET: {
      const { answerId, target } = action.payload;
      return set(['answers', answerId, 'target'], target)(state);
    }

    case TALK.CHANGE_ANSWER_SCORE: {
      const { answerId, score } = action.payload;
      return set(['answers', answerId, 'score'], score)(state);
    }

    case TALK.CHANGE_MINIMAL_SUCCESS_SCORE: {
      const { score } = action.payload;
      return set(['minimalSuccessScore'], score)(state);
    }

    case TALK.CHANGE_CARD_TYPE: {
      const { cardId, type } = action.payload;
      return set(['cards', cardId, 'type'], type)(state);
    }

    case TALK.CHANGE_CARD_TARGET: {
      const { cardId, target } = action.payload;
      return set(['cards', cardId, 'target'], target)(state);
    }

    default:
      return state;
  }
};

export default curry(talkReducer);
