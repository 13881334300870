import { css } from 'styled-components';

export const row = css`
  display: flex;
`;

export const field = css`
  flex-grow: 1;
  margin-right: 34px;
`;

export const link = css`
  display: inline-block;
  vertical-align: top;
  font-family: 'CirceRounded', Tahoma, sans-serif;
  font-size: 15px;
  box-sizing: border-box;
  color: #767b84 !important;
  height: 37px;
  line-height: 35px;
  border-radius: 19px;
  border: solid 1px #d0d3d7;
  padding: 0 28px;
  text-decoration: none;
  margin-top: 28px;
`;
