import React, { useEffect } from 'react';

import { isEmpty } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Text } from '~/components';
import * as actions from '~/actions';
import '~/styles/Profile.scss';
import { getPaymentHistory } from '~/selectors/profile';

const ProfileHistory = () => {
  const { t } = useTranslation('screens');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.builder.profile.getHistory());
  }, [dispatch]);

  const history = useSelector(getPaymentHistory());

  return (
    <div className="ProfileHistory">
      {!isEmpty(history) ? (
        <table className="table">
          <tbody>
            <tr>
              <th>{t('profileHistory.date')}</th>
              <th>{t('profileHistory.plan')}</th>
              <th>{t('profileHistory.nextPaymentAt')}</th>
              <th>{t('profileHistory.amount')}</th>
            </tr>
            {history.map(({ date, planTitle, amount, nextPaymentAt }) => (
              <tr key={date}>
                <td className="date">{date}</td>
                <td className="plan">{planTitle}</td>
                <td className="plan">{nextPaymentAt}</td>
                <td className="amount">{amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text">
          <Text value={t('profile.noPayment')} />
        </div>
      )}
    </div>
  );
};

export default ProfileHistory;
