import { all } from 'redux-saga/effects';

import * as builder from './builder';
import * as library from './library';
import * as results from './results';
import * as player from './player';
import * as study from './study';
import * as course from './course';
import * as presentation from './presentation';
import * as publicOlympiadResult from './publicOlympiadResult';
import payment from './payment';
import uploader from './uploader';
import auth from './auth/auth';
import { getHistory } from '~/sagas/profile';
import review from './review';
import answer from './player/block/answer';
import notifications from '~/sagas/notifications';
import tutors from './tutors';

export default function* sagas() {
  yield all([
    study.downloadAllCourses(),
    study.downloadAllResults(),
    builder.lesson.download(),
    builder.lesson.remove(),
    builder.lesson.publish(),
    builder.lesson.save(),
    builder.course(),
    builder.folder.save(),
    builder.folder.remove(),
    library.block.remove(),
    library.block.find(),
    library.block.save(),
    library.tag.find(),
    results.reset(),
    results.download(),
    results.sendMessage(),
    publicOlympiadResult.downloadPublicOlympiadResult(),
    auth(),
    course.join(),
    course.download(),
    player.lesson.send(),
    player.lesson.download(),
    player.lesson.execution(),
    player.getLessonIdByCode(),
    player.watchPreLogout(),
    presentation.watchStartPresentation(),
    uploader(),
    payment(),
    getHistory(),
    review(),
    answer(),
    notifications(),
    tutors(),
  ]);
}
