import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from '~/components';
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_DELETED,
} from '~/appConstants';
import * as s from '~/screens/Teach/Profile/styles/ProfilePlan.styles';
import { disableAutoPayment, enableAutoPayment } from '~/actions/payment';
import * as selectors from '~/selectors';
import AutoPaymentConfirm from '~/screens/Teach/Profile/AutoPaymentConfirm';
import ChangeCard from '~/screens/Teach/Profile/ChangeCard';

const AutoPaymentSwitch = () => {
  const { t } = useTranslation('screens');

  const { status } = useSelector(selectors.auth.getUserSubscription);

  const [isAutoPaymentConfirmVisible, setAutoPaymentConfirmVisible] = useState(
    false
  );

  const [isChangeCardModalVisible, setChangeCardModalVisible] = useState(false);

  const dispatch = useDispatch();

  const onSwitchChange = () => {
    switch (status) {
      case SUBSCRIPTION_STATUS_ACTIVE:
        return setAutoPaymentConfirmVisible(true);
      case SUBSCRIPTION_STATUS_DELETED:
        return setChangeCardModalVisible(true);
      default:
        return dispatch(enableAutoPayment());
    }
  };

  return (
    <div className="switch toggler">
      <ChangeCard
        title={t('changeCard.autoprolongTitle')}
        description={t('subscriptionPlans.prolongationDescription')}
        isOpen={isChangeCardModalVisible}
        close={() => setChangeCardModalVisible(false)}
        goBack={() => setChangeCardModalVisible(false)}
      />
      <AutoPaymentConfirm
        isOpen={isAutoPaymentConfirmVisible}
        message={[
          t('changeCard.changeCardText1'),
          t('changeCard.changeCardText2'),
        ]}
        acceptText={t('changeCard.switchOff')}
        cancelText={t('changeCard.cancel')}
        onAccept={() => {
          setAutoPaymentConfirmVisible(false);
          dispatch(disableAutoPayment());
        }}
        onCancel={() => setAutoPaymentConfirmVisible(false)}
      />
      <Switch
        label={t('profile.prolongation')}
        checked={status === SUBSCRIPTION_STATUS_ACTIVE}
        css={s.prolongationSwitch}
        onChange={onSwitchChange}
      />
    </div>
  );
};

export default AutoPaymentSwitch;
