import React from 'react';
import PropTypes from 'prop-types';

import Text from '~/components/Text';
import Froala from '~/components/Froala';
import FinalScreen from './components/FinalScreen';
import PlayerStatus from './components/PlayerStatus';
import withBlockStatus from './components/withBlockStatus';
import * as s from './styles/TalkReview.styles';

const TalkReview = ({ name, blockStatus, description, score }) => (
  <div css={s.container}>
    <Text value={name} variant="answer-question" multiline />
    <Froala mode="preview" variant="talk-description" content={description} />
    <div css={s.content}>
      <FinalScreen blockStatus={blockStatus} score={score} />
    </div>
    <PlayerStatus blockStatus={blockStatus} />
  </div>
);

const { string, number } = PropTypes;

TalkReview.propTypes = {
  name: string.isRequired,
  score: number.isRequired,
  description: string.isRequired,
  blockStatus: string.isRequired,
};

export default withBlockStatus(TalkReview);
