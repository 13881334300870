import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { map, filter, isEmpty, toLower, includes, isNull } from 'lodash/fp';
import ClickOutside from 'react-click-outside';
import Input from '~/components/Input';
import Button from '~/components/Button';
import LinesTruncate from '~/components/LinesTruncate';
import * as s from './styles/Suggest.styles';

const Suggest = ({
  items,
  title,
  selectedId,
  placeholder,
  selectedName,
  onChange,
}) => {
  const [isDropdownVisible, toggleDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('screens');
  const filteredItems = filter((item) =>
    includes(toLower(searchValue), toLower(item.name))
  )(items);

  const handleSelect = useCallback(
    (selectedItem) => {
      onChange(selectedItem);
      setSearchValue('');
      toggleDropdownVisible(false);
    },
    [onChange]
  );

  return (
    <ClickOutside
      css={s.container}
      onClickOutside={() => toggleDropdownVisible(false)}
    >
      <div
        css={s.dropdownWrap}
        onClick={() => toggleDropdownVisible(!isDropdownVisible)}
      >
        <div css={s.inputLabel}>{title}</div>
        <Input
          debounceTime={300}
          placeholder={
            isDropdownVisible && !isNull(selectedId)
              ? selectedName
              : placeholder
          }
          value={isDropdownVisible ? searchValue : selectedName}
          onChange={setSearchValue}
        />
        <Button
          css={s.dropdownButton}
          icon={isDropdownVisible ? 'chevron-up' : 'chevron-down'}
          minimal
        />
      </div>
      <div css={s.dropdownList} isVisible={isDropdownVisible}>
        {isEmpty(filteredItems) ? (
          <div css={s.noResult} onClick={() => toggleDropdownVisible(false)}>
            {t('review.filter.noResults')}
          </div>
        ) : (
          map(
            (item) => (
              <div
                key={`item_${item.id}`}
                css={s.dropdownItem}
                isSelected={selectedId === item.id}
                onClick={() => handleSelect(item)}
              >
                <LinesTruncate
                  text={item.name || t('review.filter.noName')}
                  maxLines={1}
                  variant={
                    item.name ? 'dropdown-item' : ['dropdown-item', 'light']
                  }
                />
              </div>
            ),
            filteredItems
          )
        )}
      </div>
    </ClickOutside>
  );
};

const { array, func, string } = PropTypes;

Suggest.propTypes = {
  items: array, // Массив вариантов в селекте
  title: string,
  selectedId: string,
  selectedName: string,
  placeholder: string,
  onChange: func.isRequired, // Действие при изменении селекта
};

Suggest.defaultProps = {
  items: [],
  selectedName: '',
};

export default pure(Suggest);
