import * as COURSE from '~/constants/course';
import { createAction } from '~/utils/actions';

export const downloadCourse = ({ id }) =>
  createAction(COURSE.DOWNLOAD_COURSE, { id });

export const downloadCourseSuccess = ({
  course,
  publications,
  results,
  resultsByLessonId,
}) =>
  createAction(COURSE.DOWNLOAD_COURSE_SUCCESS, {
    course,
    publications,
    results,
    resultsByLessonId,
  });

export const downloadCourseError = (message) =>
  createAction(COURSE.DOWNLOAD_COURSE_ERROR, { message });

export const join = ({ id }) => createAction(COURSE.JOIN, { id });

export const joinSuccess = () => createAction(COURSE.JOIN_SUCCESS);

export const joinError = (message) =>
  createAction(COURSE.JOIN_ERROR, { message });
