import { get, set, head, values, compose } from 'lodash/fp';
import * as helpers from '../../../helpers';
import * as BLOCK from '../../../constants/builder/block';

/**
 * Клонирование блока
 */
export const clone = (state) => (next) => (action) => {
  if (action.type === BLOCK.CLONE) {
    const blockId = action.payload.id;
    const allBlocks = state.getState().builder.blocks;
    const fullClonedBlock = helpers.block.clone(blockId, allBlocks);
    const blockType = compose(
      get('type'),
      head,
      values
    )(fullClonedBlock);

    const clonedBlock =
      blockType === 'Talk'
        ? helpers.block.cleanTalkFromTargets(fullClonedBlock)
        : fullClonedBlock;

    return next(set('meta', { clonedBlock }, action));
  } else {
    return next(action);
  }
};
