import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { drop, get, isBoolean, join } from 'lodash/fp';
import memoize from 'fast-memoize';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import objectId from 'bson-objectid';
import { Link } from 'react-router-dom';

import Button from '~/components/Button';
import Text from '~/components/Text';
import Icon from '~/components/Icon';
import Froala from '~/components/Froala';
import Uploader from '~/containers/Uploader';
import FilePreview from '~/components/FilePreview';
import Dialog from '~/components/Dialog';
import DotLoader from '~/components/DotLoader';
import { isRequestActive } from '~/selectors/ui';
import ReviewAnswer from '~/screens/Teach/Review/ReviewAnswer';
import * as s from './styles/Review.styles';
import {
  clearFilesToSend,
  downloadAnswerBlock,
  removeFile,
  sendTeacherComment,
  unlockAnswerBlockReview,
} from '~/actions/review';
import { getLastIndex } from '~/utils/array';
import {
  REQUEST_NAME_ANSWER_BLOCK_DOWNLOADING,
  STATUS_APPROVED,
  STATUS_NOT_CHECKED,
  STATUS_REJECTED,
  UPLOAD_TYPE_ANSWER,
} from '~/appConstants';
import '../../../styles/Portal.scss';
import '../../../styles/Backdrop.scss';

const statusTextMap = {
  [STATUS_APPROVED]: 'review.statusComplete',
  [STATUS_NOT_CHECKED]: 'review.statusNotCheck',
  [STATUS_REJECTED]: 'review.statusNotPassed',
};

const renderFilePreview = memoize(({ item, remove, isCanRemove }) => {
  const { source, status } = item[1];
  const filenameWithHash =
    (!status || status === 'success') && source
      ? source
          // eslint-disable-next-line no-useless-escape
          .replace(/^.*[\\\/]/, '')
          .replace(/\.[^/.]+$/, '')
          .split('-')
      : '';
  const filename = filenameWithHash && join('-', drop(1, filenameWithHash));
  const extension =
    !status || status === 'success' ? source.match(/[^.]+$/)[0] : '';

  return (
    (!status || status === 'success') && (
      <div key={item[0]}>
        <FilePreview
          name={filename}
          remove={remove}
          source={item[1].source}
          extension={extension}
          isCanRemove={isCanRemove}
        />
      </div>
    )
  );
});

const ReviewTask = (props) => {
  const { answerBlockResultId, close } = props;
  const { t } = useTranslation('screens');

  const dispatch = useDispatch();

  const [isAnswerBlocked, changeAnswerBlocked] = useState(false);
  const [commentToSend, setCommentToSend] = useState('');

  const answerBlockResult = useSelector(get(['review', 'answerBlockResult']));
  const filesToSend = useSelector(get(['review', 'filesToSend']));

  const reply = get('reply', answerBlockResult);
  const lastIndex = getLastIndex(reply);

  const isLoading = useSelector(
    isRequestActive(REQUEST_NAME_ANSWER_BLOCK_DOWNLOADING)
  );

  useEffect(() => {
    changeAnswerBlocked(!!get([lastIndex, 'teacherComment'], reply));
    setCommentToSend('');
  }, [answerBlockResult, reply, lastIndex]);

  useEffect(() => {
    answerBlockResultId && dispatch(clearFilesToSend());
  }, [dispatch, answerBlockResultId]);

  useEffect(() => {
    answerBlockResultId &&
      dispatch(downloadAnswerBlock({ answerBlockResultId }));
    return () => dispatch(unlockAnswerBlockReview({ answerBlockResultId }));
  }, [dispatch, answerBlockResultId]);

  const changeStatus = useCallback(() => {
    setCommentToSend(get([lastIndex, 'teacherComment'], reply));
    changeAnswerBlocked(false);
    dispatch(clearFilesToSend());
  }, [dispatch, reply, lastIndex, changeAnswerBlocked, setCommentToSend]);

  const {
    isTutor,
    courseId,
    publicationId,
    isAlreadyInReview: isAnswerAlreadyInReview,
    isApproved: isLastApproved,
    pageId,
    blockId,
  } = answerBlockResult || {};

  const status = isLastApproved
    ? STATUS_APPROVED
    : !isBoolean(isLastApproved)
    ? STATUS_NOT_CHECKED
    : STATUS_REJECTED;

  return answerBlockResult ? (
    <Dialog
      canEscapeKeyClose
      isOpen={!!answerBlockResult}
      variant="review-task"
      className="ReviewTask"
    >
      <div css={s.taskContainer}>
        {isLoading ? (
          <div css={s.loaderContainer}>
            <DotLoader css={s.loader} />
          </div>
        ) : isAnswerAlreadyInReview ? (
          <div css={s.scroll}>
            <div css={s.taskHeader}>
              <div css={s.close}>
                <Button
                  minimal
                  icon="osh-remove"
                  onClick={close}
                  variant="profile-close"
                />
              </div>
            </div>
            <div css={s.rejection}>
              <div css={s.rejectionContent}>
                <Icon name="eye-on" size="52px" css={s.rejectionIcon} />
                <div>Ответ проверяет другой тьютор.</div>
                <div>Попробуйте открыть позже.</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div css={s.scroll}>
              <div css={s.closeBack}>
                {/* FIXME: похоже, что эта кнопка не отображается */}
                <Button
                  minimal
                  icon="arrow-left"
                  onClick={close}
                  variant="review-back"
                />
              </div>
              <div css={s.taskHeader}>
                <div css={s.taskHeaderAvatar} />
                <div css={s.taskHeaderName}>
                  <div css={s.userName}>{answerBlockResult.studentName}</div>
                </div>
                <div css={s.close}>
                  <Button
                    minimal
                    icon="osh-remove"
                    onClick={close}
                    variant="profile-close"
                  />
                </div>
              </div>
              <div css={s.taskInfo}>
                {answerBlockResult.courseName && (
                  <div css={s.taskInfoPart}>
                    <div css={s.taskInfoTitle}>
                      <Text value={t('review.course')} />
                    </div>
                    <Link
                      css={s.taskInfoName}
                      target="_blank"
                      to={
                        isTutor
                          ? `/tutor/course/${courseId}`
                          : `/teach/course/${courseId}`
                      }
                    >
                      <div css={s.iconTitle}>
                        {answerBlockResult.courseName}
                      </div>
                      <Icon name="share" css={s.linkIcon} />
                    </Link>
                  </div>
                )}
                <div css={s.taskInfoPart}>
                  <div css={s.taskInfoTitle}>
                    <Text value={t('review.lesson')} />
                  </div>
                  <Link
                    css={s.taskInfoName}
                    target="_blank"
                    to={`/preview/lesson/${publicationId}`}
                  >
                    <div css={s.iconTitle}>{answerBlockResult.lessonName}</div>
                    <Icon name="share" css={s.linkIcon} />
                  </Link>
                </div>
              </div>
              <div css={s.taskContent}>
                <div css={s.taskStatus} status={status}>
                  {t(statusTextMap[status])}
                </div>
                <Link
                  css={s.taskTitle}
                  to={`/preview/lesson/${publicationId}/${pageId}#${blockId}`}
                  target="_blank"
                >
                  <div css={s.taskTitleInternal}>
                    {answerBlockResult.question}
                  </div>
                  <Icon name="share" css={s.linkIcon} />
                </Link>
                <div css={s.taskDescription}>
                  <Froala
                    mode="preview"
                    content={answerBlockResult.description}
                  />
                </div>
              </div>
              {answerBlockResult.reply &&
                answerBlockResult.reply.map(
                  (
                    {
                      studentAnswer,
                      studentFiles,
                      teacherComment,
                      teacherFiles,
                      isApproved,
                      teacherName,
                      studentAnswerDate,
                      teacherCommentDate,
                    },
                    index
                  ) => (
                    <ReviewAnswer
                      key={index}
                      studentName={answerBlockResult.studentName}
                      studentAnswer={studentAnswer}
                      studentAnswerDate={studentAnswerDate}
                      studentFiles={studentFiles}
                      teacherComment={teacherComment}
                      teacherCommentDate={teacherCommentDate}
                      teacherFiles={teacherFiles}
                      teacherName={teacherName}
                      isApproved={isApproved}
                      renderFilePreview={renderFilePreview}
                      isAnswerBlocked={isAnswerBlocked}
                    />
                  )
                )}
            </div>

            <div css={s.footer}>
              {isAnswerBlocked ? (
                <div css={s.teacherChangeBlocked} onClick={changeStatus}>
                  {t('review.changeStatus')}
                </div>
              ) : (
                <>
                  <div css={s.commentField}>
                    <div css={s.editor}>
                      <Froala
                        mode="editor"
                        pastePlain
                        theme="simple"
                        toolbarButtons={[]}
                        content={commentToSend}
                        onChange={setCommentToSend}
                        className="empty"
                      />
                    </div>
                    <div css={s.uploader}>
                      <Uploader
                        isNoticeProgress
                        uploadType={UPLOAD_TYPE_ANSWER}
                        variant="in-task-check"
                        type="answer"
                        icon="paperclip"
                        filePath={['filesToSend', objectId.generate()]}
                        fileType="any"
                      />
                    </div>
                  </div>
                  {filesToSend && (
                    <div css={s.commentFiles}>
                      {Object.entries(filesToSend).map((item) =>
                        renderFilePreview({
                          item,
                          isCanRemove: true,
                          remove: () => dispatch(removeFile({ id: item[0] })),
                        })
                      )}
                    </div>
                  )}
                  <div css={s.teacherButtons}>
                    <div
                      onClick={() => {
                        commentToSend &&
                          dispatch(
                            sendTeacherComment({
                              files: filesToSend,
                              comment: commentToSend,
                              isApproved: true,
                              answerBlockResultId,
                            })
                          );
                        setCommentToSend(undefined);
                      }}
                      css={s.approveButton}
                      disabled={!commentToSend}
                    >
                      <div className="icon">
                        <Icon name="tick-circle" />
                      </div>
                      {t('review.taskAccept')}
                    </div>
                    <div
                      onClick={() => {
                        commentToSend &&
                          dispatch(
                            sendTeacherComment({
                              files: filesToSend,
                              comment: commentToSend,
                              isApproved: false,
                              answerBlockResultId,
                            })
                          );
                        setCommentToSend(undefined);
                      }}
                      css={s.disapproveButton}
                      disabled={!commentToSend}
                    >
                      <div className="icon">
                        <Icon name="delete" />
                      </div>
                      {t('review.taskReject')}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  ) : null;
};

const { string, func } = PropTypes;

ReviewTask.propTypes = {
  answerBlockResultId: string,
  close: func.isRequired,
};

export default pure(ReviewTask);
