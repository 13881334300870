import * as ANSWER from '../../../constants/player/block/answer';

import { createAction } from '../../../utils/actions';

export const ADD_NEW_REPLY = '@player/block/answer/Добавление нового ответа';
export const ADD_NEW_REPLY_SUCCESS =
  '@player/block/answer/Добавление нового ответа успешно';
export const REMOVE_FILE_FROM_REPLY =
  '@player/block/answer/Удаление файла из нового ответа';

export const removeImage = (imageId, blockId) =>
  createAction(ANSWER.REMOVE_IMAGE, { imageId, blockId });

export const changeReply = (blockId, newReply) =>
  createAction(ANSWER.CHANGE_REPLY, { blockId, newReply, date: Date.now() });

export const addNewReply = ({ reply, resultId, blockId }) =>
  createAction(ADD_NEW_REPLY, { reply, resultId, blockId });

export const addNewReplySuccess = ({ reply, blockId }) =>
  createAction(ADD_NEW_REPLY_SUCCESS, { reply, blockId });

export const removeFileFromReply = ({ reply, blockId, id }) =>
  createAction(REMOVE_FILE_FROM_REPLY, { reply, blockId, id });
