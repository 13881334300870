import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, head } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { withHandlers, branch, renderNothing } from 'recompose';
import { withRouter, useHistory } from 'react-router-dom';
import withMobileDetect from '~/hocs/withMobileDetect';

import * as selectors from '~/selectors';
import * as actions from '~/actions';
import { Button, Link, Popover, Text } from '~/components';
import { combineStyles } from '../utils/styles';
import '../styles/NavUserBar.scss';

const NavUserBar = ({
  t,
  type,
  theme,
  variant,
  setRole,
  userName,
  onLogout,
  fontSize,
  isMobile,
  inSafeMode,
  ltiUserName,
  isLtiLesson,
}) => {
  const history = useHistory();
  const userFirstName = head(
    (isLtiLesson ? ltiUserName : userName).trim().split(' ')
  );

  return (
    <div
      className={combineStyles('NavUserBar', [variant, isMobile && 'mobile'])}
    >
      <Popover
        variant="userbar"
        position="bottomRight"
        target={
          <div className="user">
            <div className="text">
              <div className="name">
                <Text
                  value={
                    isMobile
                      ? userFirstName
                      : isLtiLesson
                      ? ltiUserName
                      : userName
                  }
                />
              </div>
              <div className="role">
                <Text
                  value={t(
                    `navuserbar.${type === 'teach' ? 'teacher' : 'student'}`
                  )}
                />
              </div>
            </div>
            <div className={combineStyles('avatar', inSafeMode && theme)} />
          </div>
        }
        content={
          <>
            <Button
              minimal
              text={t(
                type === 'teach'
                  ? 'navuserbar.enterAsAStudent'
                  : 'navuserbar.enterAsATeacher'
              )}
              icon={`osh-${type === 'teach' ? 'student' : 'teacher'}`}
              onClick={() => setRole(type === 'teach' ? 'student' : 'teacher')}
              variant={[
                'navuserbar-dropdown',
                'underscored',
                inSafeMode && fontSize,
                inSafeMode && theme,
              ]}
            />
            <Link
              withIcon="mugshot"
              variant="navuserbar-dropdown"
              text={t('userbar.profile')}
              onClick={() =>
                type === 'teach'
                  ? history.push('/teach/profile')
                  : history.push('/study/profile')
              }
            />
            <Button
              minimal
              text={t('navuserbar.exit')}
              icon="osh-logout"
              onClick={onLogout}
              variant={[
                'navuserbar-dropdown',
                inSafeMode && fontSize,
                inSafeMode && theme,
              ]}
            />
          </>
        }
      />
    </div>
  );
};

const { oneOf, string, func, oneOfType, bool, array } = PropTypes;

NavUserBar.propTypes = {
  t: func.isRequired,
  theme: string, // Цветовая тема в режиме ОВЗ
  setRole: func,
  isMobile: bool.isRequired,
  type: oneOf(['teach', 'learn']), // Вариант оформления
  variant: oneOfType([array, string]), // Вариант оформления
  onLogout: func.isRequired, // Разлогиниться
  fontSize: string, // Размер шрифта в режиме ОВЗ
  userName: string, // Имя пользователя
  inSafeMode: bool, // В режиме ОВЗ?
  ltiUserName: string,
  isLtiLesson: bool,
};

NavUserBar.defaultProps = {
  theme: 'light',
  fontSize: 'small',
};

const mapDispatchToProps = {
  logout: actions.auth.logout,
  setRole: actions.auth.setRole,
};

const enhance = withHandlers({
  onLogout: ({ logout, history }) => () => {
    logout();
    history.push('/auth');
  },
});

const mapStateToProps = (state) => {
  const userName = selectors.auth.getUserName(state);
  const isLoggedIn = selectors.auth.isLoggedIn(state);

  return {
    userName,
    isLoggedIn,
  };
};

export default compose(
  withTranslation('containers'),
  withMobileDetect,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance,
  branch((props) => !props.isLoggedIn, renderNothing)
)(NavUserBar);
