import { set, curry, last, omit, update, compose } from 'lodash/fp';
import * as IMAGES from '../../../constants/builder/block/images';

export const defaultState = {};

const imagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case IMAGES.CHANGE_QUANTITY: {
      const { imageId, count, collection } = action.payload;
      const collectionLength = Object.keys(collection).length;
      if (count > collectionLength) {
        if (count === collectionLength) {
          return state;
        }
        return set(
          ['collection', imageId],
          {
            source: '',
            width: 790,
            description: '',
          },
          state
        );
      } else {
        if (count === collectionLength) {
          return state;
        }
        return update('collection', omit(last(Object.keys(collection))), state);
      }
    }

    case IMAGES.CHANGE_URL: {
      const { imageId, newContent } = action.payload;
      return set(['collection', imageId, 'source'], newContent, state);
    }

    case IMAGES.CHANGE_SIZE: {
      const { imageId, newWidth } = action.payload;
      return set(['collection', imageId, 'width'], newWidth, state);
    }

    case IMAGES.REMOVE_IMAGE: {
      const { imageId } = action.payload;
      return compose(
        set(['collection', imageId, 'source'], ''),
        set(['collection', imageId, 'description'], '')
      )(state);
    }

    case IMAGES.CHANGE_DESCRIPTION: {
      const { imageId, newDescription } = action.payload;
      return set(['collection', imageId, 'description'], newDescription, state);
    }

    default: {
      return state;
    }
  }
};

export default curry(imagesReducer);
