import { set, compose, omit } from 'lodash/fp';
import * as RESULTS from '../constants/results';

export const defaultState = {
  isOpen: false, // Окно результатов открыто?
  lessons: {}, // Результаты уроков
  /* Пример структуры lessons:
  {
    1: [ // ID урока
      { // Результат
        id: 5a967da723e61f151eee7723,
        user: 'name',
        activePage: 5a967e917836cb630b6f03d4,
        onlineStatus: true,
        content: {
          pages: {
            1: {...},
            2: {...},
          },
          lesson: {
            1: {...},
          },
          blocks: {
            1: {...},
            2: {...}
          },
        }
      }
    ]
  }
  */
  lessonId: undefined, // ID урока, для которого выводятся результаты
  resultIndex: undefined, // Индекс результатов (для выбора из массива результатов урока)
};

const resultsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESULTS.OPEN: {
      const { lessonId } = action.payload;
      return compose(
        set('isOpen', true),
        set('lessonId', lessonId)
      )(state);
    }

    case RESULTS.CLOSE: {
      return compose(
        set('isOpen', false),
        set('lessonId', undefined)
      )(state);
    }

    case RESULTS.SELECT_RESULT: {
      const { resultIndex } = action.payload;
      return set('resultIndex', resultIndex, state);
    }

    case RESULTS.SELECT_PAGE: {
      const { pageId } = action.payload;
      return set('activePage', pageId, state);
    }

    case RESULTS.DOWNLOAD_SUCCESS: {
      const { lessonId, results, publication } = action.payload;
      return compose(
        set(['lessons', lessonId, 'results'], results),
        set(['lessons', lessonId, 'publication'], publication)
      )(state);
    }

    case RESULTS.RESET_SUCCESS: {
      const { lessonId } = action.payload;
      return omit(['lessons', lessonId], state);
    }

    case RESULTS.SEND_MESSAGE_SUCCESS: {
      const { comment, lessonId, resultIndex, messageIndex } = action.payload;
      return set(
        ['lessons', lessonId, 'results', resultIndex, 'comments', messageIndex],
        comment
      )(state);
    }

    default:
      return state;
  }
};

export default resultsReducer;
