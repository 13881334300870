import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import withMobileDetect from '~/hocs/withMobileDetect';

import { Button, Text, Quote, Input, Link } from '../../components';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import '../../styles/Auth/PassRecovery.scss';
import { isRequestActive } from '~/selectors/ui';
import { REQUEST_NAME_AUTH_RECOVERY } from '~/appConstants';
import SelectLocale from '~/containers/SelectLocale';

const PassRecovery = ({
  t,
  email,
  isMobile,
  isLoading,
  updateEmail,
  sendRecoveryRequest,
}) => {
  const isSubmitDisabled = useSelector(
    isRequestActive(REQUEST_NAME_AUTH_RECOVERY)
  );

  return (
    <div className={combineStyles('PassRecovery', isMobile && 'mobile')}>
      <SelectLocale variant="in-auth" />
      <Quote />
      <div className="content">
        <Text
          value={t('recovery.recoveryTitle')}
          variant="pass-recovery-title"
        />
        <Text
          value={t('recovery.recoverySubTitle')}
          variant="pass-recovery-sub-title"
        />
        <div className="form">
          <Input
            type="text"
            large
            label={t('login.email')}
            disabled={isSubmitDisabled}
            placeholder={t('login.email')}
            onChange={updateEmail}
            value={email}
          />
        </div>
        <div className="send">
          <Button
            fill
            text={t('recovery.restorePassword')}
            disabled={isSubmitDisabled}
            type="primary"
            large
            loading={isLoading}
            variant="auth-send"
            onClick={sendRecoveryRequest}
          />
        </div>
        <Link
          to="/auth"
          text={t('recovery.returnToAuth')}
          variant="return-to-auth"
        />
      </div>
    </div>
  );
};

const { func, string, bool } = PropTypes;

PassRecovery.propTypes = {
  t: func.isRequired,
  isLoading: bool.isRequired,
  sendRecoveryRequest: func.isRequired,
  email: string.isRequired,
  isMobile: bool.isRequired,
  updateEmail: func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.auth;
  return { isLoading };
};

const mapDispatchToProps = {
  sendRecoveryRequest: actions.auth.sendRecoveryRequest,
};

export default compose(
  withTranslation('containersAuth'),
  withMobileDetect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('email', 'updateEmail', ''),
  withHandlers({
    sendRecoveryRequest: ({ sendRecoveryRequest, email }) => () =>
      sendRecoveryRequest({ email }),
  })
)(PassRecovery);
