import { get, assign } from 'lodash/fp';
import { all, put, takeEvery } from 'redux-saga/effects';
import * as BUILDER from '../../../constants/builder';
import * as actions from '../../../actions';
import { apiRequest } from '../../request';
import { REQUEST_NAME_LESSON_DOWNLOADING } from '~/appConstants';

const api = {
  one: (lessonId) => `lesson/${lessonId}`,
  all: (userId) => `lesson/user/${userId}`,
};

/**
 * Загрузка урока по ID
 */
function* one({ id }) {
  const response = yield apiRequest(api.one(id), null, 'get', {
    name: REQUEST_NAME_LESSON_DOWNLOADING,
    showNotFoundOn404: true,
  });

  if (!response) {
    return {};
  }

  const { error } = response.data;
  const {
    data: {
      payload: {
        lesson: { content },
      },
    },
  } = response;

  const downloadedPages = get('pages', content);
  const downloadedBlocks = get('blocks', content);
  const downloadedLessons = get('lesson', content);

  return {
    error,
    downloadedPages,
    downloadedBlocks,
    downloadedLessons,
  };
}

function* downloadLesson(id) {
  const {
    error,
    downloadedPages,
    downloadedBlocks,
    downloadedLessons,
  } = yield one({ id });

  if (error) {
    return yield put(actions.builder.downloadLessonError(error));
  }

  return yield put(
    actions.builder.downloadLessonSuccess({
      downloadedLessons,
      downloadedPages,
      downloadedBlocks,
    })
  );
}

function* watchDownloadLesson() {
  yield takeEvery(BUILDER.DOWNLOAD_LESSON, function*(action) {
    const { id } = action.payload;

    yield downloadLesson(id);
  });
}

function* watchDownloadAndCloneLesson() {
  yield takeEvery(BUILDER.LESSON.DOWNLOAD_AND_CLONE, function*(action) {
    const { id, courseId } = action.payload;

    const { type } = yield downloadLesson(id);
    if (type === BUILDER.DOWNLOAD_LESSON_SUCCESS) {
      yield put(actions.builder.lesson.clone({ id, courseId }));
    }
  });
}

/**
 * Загрузка и импортирование всех уроков и папок созданных пользователем
 */
function* watchDownloadAll() {
  yield takeEvery(BUILDER.DOWNLOAD_LESSONS, function*(action) {
    const {
      payload: { userId },
    } = action;

    const response = yield apiRequest(api.all(userId), null, 'get', {
      name: REQUEST_NAME_LESSON_DOWNLOADING,
    });

    if (!response) {
      return;
    }

    const {
      data: { error, payload },
    } = response;

    const parseData = (type, path = 'lessons') =>
      payload[path].reduce(
        (items, item) => assign(items, get(['content', type], item)),
        {}
      );
    const downloadedPages = parseData('pages');
    const downloadedBlocks = parseData('blocks');
    const downloadedLessons = parseData('lesson');
    const downloadedFolders = parseData('folder', 'folders');
    if (!error) {
      yield put(
        actions.builder.downloadLessonsSuccess(
          downloadedLessons,
          downloadedPages,
          downloadedBlocks,
          downloadedFolders
        )
      );
    } else {
      yield put(actions.builder.downloadLessonsError(error));
    }
  });
}

export default function*() {
  yield all([
    watchDownloadLesson(),
    watchDownloadAll(),
    watchDownloadAndCloneLesson(),
  ]);
}
