import { css } from 'styled-components';

export const name = css`
  margin-top: 20px;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: #45494e;
`;

export const more = css`
  margin-top: 10px;
  font-family: CirceRounded;
  font-size: 14px;
  line-height: 1.07;
  text-align: center;
  color: #767b84;
`;

export const price = css`
  margin-top: 10px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #45494e;
`;

export const monthPrice = css`
  margin-top: 2px;
  font-family: CirceRounded;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.33px;
  text-align: center;
  color: #95979a;
`;

export const activeCourses = css`
  margin-top: 10px;
  font-family: CirceRounded;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #45494e;
`;

export const divider = css`
  margin-top: 17px;
  width: 53px;
  height: 2px;
  border: solid 1px #e5e9ec;
`;
