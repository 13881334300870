import React from 'react';
import PropTypes from 'prop-types';
import { get, pick, values, isEmpty, head } from 'lodash/fp';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import withMobileDetect from '~/hocs/withMobileDetect';
import {
  TALK_CARD_TYPE_FINAL,
  TALK_CARD_TYPE_QUESTION,
  TALK_CARD_TYPE_INFORMATION,
} from '~/appConstants';
import Button from '~/components/Button';
import Text from '~/components/Text';
import Froala from '~/components/Froala';
import PlayerStatus from './components/PlayerStatus';
import FinalScreen from './components/FinalScreen';
import withBlockStatus from './components/withBlockStatus';
import * as actions from '~/actions';
import * as s from './styles/TalkPlayer.styles';

const TalkPlayer = ({
  t,
  name,
  card,
  score,
  cardImage,
  nextScreen,
  description,
  selectAnswer,
  blockStatus,
  answersByCard,
  isBlockPassed,
  inPresentation,
  updateBlockInPresentation,
}) => (
  <s.Container>
    <div css={s.name}>
      <Text value={name} />
    </div>
    <div css={s.description}>
      <Froala mode="preview" variant="talk-description" content={description} />
    </div>

    {isBlockPassed ? (
      <div css={s.card}>
        <FinalScreen blockStatus={blockStatus} score={score} />
      </div>
    ) : (
      <div css={s.card}>
        {cardImage && cardImage.source && (
          <div css={s.image}>
            <img
              src={cardImage.source}
              alt=""
              onLoad={() =>
                updateBlockInPresentation
                  ? inPresentation && updateBlockInPresentation()
                  : undefined
              }
            />
          </div>
        )}
        <div css={s.question}>
          {card.type === TALK_CARD_TYPE_INFORMATION ? (
            <Froala content={card.information} mode="preview" />
          ) : (
            <Text value={card.question} />
          )}
        </div>
        {card.type === TALK_CARD_TYPE_INFORMATION ? (
          <div css={s.answers}>
            <Button
              text={t('talk.next')}
              variant="talk-next"
              onClick={() => nextScreen(card.target)}
            />
          </div>
        ) : (
          (card.type === TALK_CARD_TYPE_QUESTION ||
            !isEmpty(answersByCard)) && (
            <div css={s.answers}>
              {answersByCard.map((item, index) => (
                <div
                  css={s.answer}
                  key={item.id}
                  onClick={() => selectAnswer(item.id, item.target, item.score)}
                >
                  <div css={s.letter}>{String.fromCharCode(65 + index)} </div>
                  <Text value={item.answer} />
                </div>
              ))}
            </div>
          )
        )}
      </div>
    )}

    <PlayerStatus blockStatus={blockStatus} />
  </s.Container>
);

const { func, array, string, number, object, bool } = PropTypes;

TalkPlayer.propTypes = {
  t: func.isRequired,
  card: object.isRequired,
  name: string,
  score: number,
  cardImage: object,
  description: string,
  selectAnswer: func.isRequired,
  nextScreen: func.isRequired,
  isBlockPassed: bool.isRequired,
  answersByCard: array.isRequired,
  blockStatus: string.isRequired,
  inPresentation: bool,
  updateBlockInPresentation: func,
};

TalkPlayer.defaultProps = {
  cardImage: {},
};

const mapStateToProps = (state, ownProps) => {
  const {
    cards,
    answers,
    cardsIds,
    currentTarget: { cardId: currentCardId, type: currentTargetType } = {},
  } = ownProps;
  const startCardId = head(cardsIds);
  const getCard = (cardId) => get(cardId, cards);
  const getAnswersByQuestion = (card) =>
    values(pick(get('answersIds', card), answers));
  const card = getCard(currentCardId || startCardId);
  const answersByCard = getAnswersByQuestion(card);
  const cardImage = get('image', card);
  const isBlockPassed = currentTargetType === TALK_CARD_TYPE_FINAL;

  return {
    card,
    cardImage,
    isBlockPassed,
    answersByCard,
  };
};

const mapDispatchToProps = {
  selectAnswer: actions.player.block.talk.selectAnswer,
  nextScreen: actions.player.block.talk.nextScreen,
};

const enhance = withHandlers({
  selectAnswer: ({ selectAnswer, blockId, score = 0 }) => (
    answerId,
    target,
    currentScore = 0
  ) =>
    selectAnswer({
      blockId,
      answerId,
      target,
      score: currentScore + score,
    }),
  nextScreen: ({ nextScreen, blockId }) => (target) =>
    nextScreen({
      blockId,
      target,
    }),
});

export default compose(
  withMobileDetect,
  withTranslation('containersBlock'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withBlockStatus,
  enhance
)(TalkPlayer);
