import { put, take, call, select } from 'redux-saga/effects';
import { set, invert } from 'lodash/fp';
import * as LIBRARY from '../../../constants/library';
import * as actions from '../../../actions';
import request from '../../request';

//* Рефакторинг

const api = `${process.env.API_URL}/block/search`;

/**
 * Поиск блока в библиотеке
 */
export default function* find() {
  while (true) {
    yield take([
      LIBRARY.EDIT.SAVE_SUCCESS,
      LIBRARY.EDIT.REMOVE_SUCCESS,
      LIBRARY.SEARCH.OPEN,
      LIBRARY.SEARCH.SELECT_TAG,
      LIBRARY.SEARCH.REMOVE_TAG,
      LIBRARY.SEARCH.TOGGLE_TYPE,
      LIBRARY.SEARCH.TOGGLE_SUBJECT,
    ]);
    const {
      tags: { selected: tags },
      types,
      subjects,
    } = yield select((state) => state.library.search);
    const tagConverter = {
      'Имя личности': 'persons',
      Тема: 'themes',
      Дата: 'dates',
      Термин: 'terms',
    };
    const data = {
      type: types,
      subject: subjects,
      tags: tags.map((tag) => ({
        // Тэги в формате сервера
        category: tagConverter[tag.type],
        tag: tag.text,
      })),
    };
    const response = yield call(request, api, data);
    const blocksById = response.data.payload.blocks.reduce(
      (blocks, block) =>
        set(
          block.id,
          {
            type: block.type,
            tags: block.tags.map((tag) => ({
              // Тэги в формате клиента
              type: invert(tagConverter)[tag.category],
              text: tag.tag,
            })),
            content: block.content,
          },
          blocks
        ),
      {}
    );
    if (response) {
      const { error, msg } = response;
      if (!error) {
        yield put(actions.library.search.importBlocks(blocksById));
      } else {
        yield put(actions.notice.show(msg, 'error'));
      }
    }
  }
}
