import React from 'react';
import PropTypes from 'prop-types';
import { combineStyles } from '../utils/styles';
import '../styles/ChartThermometer.scss';

/* График-градусник прохождения заданий: x из y */

const ChartThermometer = ({
  theme,
  variant,
  valueMax,
  fontSize,
  inSafeMode,
  innerResult,
  valueCurrent,
  isResultSeparation,
  olympiadSuccessLimit,
}) => (
  <div
    className={combineStyles(
      'ChartThermometer',
      variant,
      inSafeMode && [fontSize, theme]
    )}
  >
    <div
      className={combineStyles('progress', inSafeMode && [fontSize, theme])}
      style={{ width: `${(valueCurrent / valueMax) * 100}%` }}
    />
    {innerResult && (
      <div className="result">
        <span>{valueCurrent}</span>/<span>{valueMax}</span>
      </div>
    )}
    {isResultSeparation && (
      <div
        className={combineStyles('icon', inSafeMode && [fontSize, theme])}
        style={{ left: `${(olympiadSuccessLimit / valueMax) * 100}%` }}
      />
    )}
  </div>
);

const { string, bool, number, oneOfType, array } = PropTypes;

ChartThermometer.propTypes = {
  theme: string, // Цветовая тема в режиме ОВЗ
  variant: oneOfType([array, string]), // Вариант оформления
  fontSize: string, // Размер шрифта в режиме ОВЗ
  valueMax: number, // Максимальное значение для градусника
  inSafeMode: bool, // В режиме ОВЗ?
  innerResult: bool, // Результаты прохождения внутри графика
  valueCurrent: number, // Значение прохождения
  isResultSeparation: bool, // Есть минимальное колиечество правильных ответов?
  olympiadSuccessLimit: number, // Минимальное колиечство правильных ответов
};

ChartThermometer.defaultProps = {
  theme: 'light',
  variant: undefined,
  fontSize: 'small',
  valueMax: 1,
  inSafeMode: undefined,
  innerResult: false,
  valueCurrent: 1,
  isResultSeparation: false,
  olympiadSuccessLimit: undefined,
};

export default ChartThermometer;
