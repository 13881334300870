import * as auth from './auth';
import * as notice from './notice';
import * as player from './player';
import * as study from './study';
import * as builder from './builder';
import * as confirm from './confirm';
import * as metrika from './metrika';
import * as library from './library';
import * as results from './results';
import * as settings from './settings';
import * as uploader from './uploader';
import * as clypboard from './clypboard';
import * as presentation from './presentation';
import * as course from './course';
import * as payment from './payment';
import * as tutors from './tutors';
import * as ui from './ui';

export {
  auth,
  study,
  player,
  notice,
  builder,
  confirm,
  library,
  results,
  metrika,
  uploader,
  settings,
  clypboard,
  presentation,
  course,
  payment,
  tutors,
  ui,
};
