import { all, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_NEW_REPLY,
  addNewReplySuccess,
} from '~/actions/player/block/answer';
import { apiRequest } from '~/sagas/request';
import { REQUEST_NAME_ADD_NEW_REPLY } from '~/appConstants';

function* watchAddNewAnswer() {
  yield takeLatest(ADD_NEW_REPLY, function*(action) {
    const {
      payload: { resultId, reply, blockId },
    } = action;

    const response = yield apiRequest(
      'result/add-new-answer',
      {
        reply,
        resultId,
        blockId,
      },
      'post',
      { name: REQUEST_NAME_ADD_NEW_REPLY }
    );

    if (!response) {
      return;
    }

    // TODO: чисто технически может получиться так, что у юзера в сторе будет уже
    //  другой результат в момент, когда придёт ответ с сервера.
    //  В таком случае, reply может примениться к неправильному результату и затем сохраниться на сервер
    //  (на сервер отправляется весь результат из стора). Нужно разобраться, может ли это являться проблемой
    const {
      data: { error },
    } = response;

    if (error) {
      return;
    }

    yield put(addNewReplySuccess({ reply, blockId }));
  });
}

export default function*() {
  yield all([watchAddNewAnswer()]);
}
