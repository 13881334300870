import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withHandlers, withProps, pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Froala, Controls, Icon } from '../../components';
import { combineStyles } from '../../utils/styles';
import * as actions from '../../actions';
import '../../styles/Instruction.scss';

const Instruction = ({
  t,
  text,
  type,
  theme,
  fontSize,
  onChange,
  inSafeMode,
  inEditMode,
  changeContent,
  selectedBlock,
  inPreviewMode,
}) => (
  <div
    className={combineStyles('Instruction', [
      type,
      inSafeMode && theme,
      inSafeMode && fontSize,
    ])}
  >
    <div
      className={combineStyles('instruction-content', [
        inEditMode && 'edit-mode',
        inSafeMode && theme,
        inSafeMode && fontSize,
      ])}
    >
      <Icon
        name={type}
        variant={
          inSafeMode
            ? ['instruction-icon', type, fontSize, theme]
            : ['instruction-icon', type]
        }
      />
      {inEditMode ? (
        <div className="text">
          <Froala
            mode="editor"
            variant="instruction"
            toolbarButtons={[
              'paragraphFormat',
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'insertTable',
              'quote',
              'formatOL',
              'formatUL',
              'indent',
              'outdent',
              'insertLink',
              'insertImage',
              'align',
            ]}
            content={text}
            onChange={onChange}
          />
          <Controls
            type="dropdown"
            usePortal
            icon="caret-down"
            items={[
              {
                icon: 'info-sign',
                action: () => changeContent('info-sign', t('instruction.info')),
                text: t('instruction.info'),
              },
              {
                icon: 'unpin',
                action: () =>
                  changeContent('unpin', t('instruction.instruction')),
                text: t('instruction.instruction'),
              },
              {
                icon: 'locate',
                action: () =>
                  changeContent('locate', t('instruction.goalSettings')),
                text: t('instruction.goalSettings'),
              },
              {
                icon: 'help',
                action: () =>
                  changeContent('help', t('instruction.questionState')),
                text: t('instruction.questionState'),
              },
              {
                icon: 'lightbulb',
                action: () =>
                  changeContent('lightbulb', t('instruction.topic')),
                text: t('instruction.topic'),
              },
            ]}
            variant="inner-block"
            buttonText={t(selectedBlock)}
          />
        </div>
      ) : inPreviewMode ? (
        <div className={combineStyles('text', inSafeMode && [fontSize, theme])}>
          <Froala
            mode="preview"
            content={text}
            variant={
              inSafeMode ? ['instruction', fontSize, theme] : 'instruction'
            }
          />
        </div>
      ) : (
        undefined
      )}
    </div>
  </div>
);

const { bool, string, func } = PropTypes;

Instruction.propTypes = {
  t: func.isRequired, // Функция перевода
  text: string, // Текст
  type: string, // Внутренний тип блока инструкции
  theme: string, // Цветовая тема в режиме ОВЗ
  onChange: func.isRequired, // При изменении блока
  fontSize: string, // Размер шрифта в режиме ОВЗ
  inEditMode: bool, // В режиме редактирования?
  inSafeMode: bool, // В режиме ОВЗ?
  changeContent: func, // Сменить контент блока
  selectedBlock: string, // Текущий выбранный блок
  inPreviewMode: bool, // В режиме просмотра/выполнения?
};

Instruction.defaultProps = {
  text: undefined,
  theme: 'light',
  type: 'info-sign',
  fontSize: 'small',
  inSafeMode: undefined,
  inEditMode: undefined,
  changeContent: undefined,
  selectedBlock: undefined,
  inPreviewMode: undefined,
};

const mapDispatchToProps = {
  changeContent: actions.builder.block.changeContent,
};

const enhance = compose(
  pure,
  withProps(
    ({
      inPlayer,
      inBuilder,
      inLibraryEdit,
      inPresentation,
      inLibraryShare,
      inLibrarySearch,
    }) => ({
      inEditMode:
        (inBuilder && !inLibrarySearch) || inLibraryEdit || inLibraryShare,
      inPreviewMode: inPlayer || inPresentation || inLibrarySearch,
    })
  ),
  withHandlers({
    onChange: ({ onChange }) => (value) => onChange({ text: value }),
    changeContent: ({ blockId, changeContent }) => (type, selectedBlock) =>
      changeContent(blockId, { type, selectedBlock }),
  })
);

export default compose(
  withTranslation('containersBlock'),
  connect(
    undefined,
    mapDispatchToProps
  ),
  enhance
)(Instruction);
