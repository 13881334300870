import { get } from 'lodash/fp';
import { getBlock } from './main';

export const getImageCollection = (blockId, state) =>
  get(['content', 'collection'], getBlock(blockId, state));

export const getContentImageSource = (blockId, imageId, state) =>
  get(['content', 'collection', imageId, 'source'], getBlock(blockId, state));

export const getImageUploaderStatus = (blockId, imageId, state) =>
  get(
    ['content', 'collection', imageId, 'uploader', 'status'],
    getBlock(blockId, state)
  );

export const getImageUploaderProgress = (blockId, imageId, state) =>
  get(
    ['content', 'collection', imageId, 'uploader', 'progress'],
    getBlock(blockId, state)
  );
