import { createAction } from '~/utils/actions';

export const DOWNLOAD_NOTIFICATIONS = '@study/Загрузить уведомления';
export const DOWNLOAD_NOTIFICATIONS_SUCCESS =
  '@study/Успешная загрузка уведомлений';
export const GET_NOTIFICATIONS_COUNT = '@study/Получить количество уведомлений';
export const GET_NOTIFICATIONS_COUNT_SUCCESS =
  '@study/Успешное получение количества уведомлений';
export const MARK_AS_VIEWED = '@study/отметить нотификации как прочитанные';
export const MARK_AS_VIEWED_SUCCESS =
  '@study/Успешно отмечены нотификации как прочитанные';

export const downloadNotifications = () => createAction(DOWNLOAD_NOTIFICATIONS);

export const downloadNotificationsSuccess = ({ notifications }) =>
  createAction(DOWNLOAD_NOTIFICATIONS_SUCCESS, { notifications });

export const getNotificationsCount = () =>
  createAction(GET_NOTIFICATIONS_COUNT);

export const getNotificationsCountSuccess = ({ count }) =>
  createAction(GET_NOTIFICATIONS_COUNT_SUCCESS, { count });

export const markAsViewed = ({ ids }) => createAction(MARK_AS_VIEWED, { ids });

export const markAsViewedSuccess = ({ count, ids }) =>
  createAction(MARK_AS_VIEWED_SUCCESS, { count, ids });
