import React from 'react';
import PropTypes from 'prop-types';

import { compose, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';

import {
  LESSON_DOWNLOAD_STATUS_DOWNLOADED,
  LESSON_DOWNLOAD_STATUS_DOWNLOADING,
  LESSON_DOWNLOAD_STATUS_ERROR,
} from '../../appConstants';
import { downloadLessonPreview } from '../../actions/player';

import Error from './downloadErrors/Error';
import withSSRHandler from '../../hocs/withSSRHandler';

const PreviewLoader = ({ lesson, lessonDownloadStatus }) => {
  switch (lessonDownloadStatus) {
    case LESSON_DOWNLOAD_STATUS_ERROR:
      return <Error lesson={lesson} />;
    case LESSON_DOWNLOAD_STATUS_DOWNLOADING:
      return null; // ToDo добавить лоадер
    default:
      return null;
  }
};

PreviewLoader.propTypes = {
  lesson: PropTypes.object,
  lessonDownloadStatus: PropTypes.oneOf([
    LESSON_DOWNLOAD_STATUS_ERROR,
    LESSON_DOWNLOAD_STATUS_DOWNLOADING,
    LESSON_DOWNLOAD_STATUS_DOWNLOADED,
  ]),
};

const mapStateToProps = (state) => ({
  lessonDownloadStatus: state.player.lessonDownloadStatus,
  lesson: state.player.lesson,
});

const mapDispatchToProps = { downloadLessonPreview };

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  // eslint-disable-next-line no-shadow
  withSSRHandler(({ match, downloadLessonPreview }) => {
    const { publicationId } = match.params;
    downloadLessonPreview({ publicationId });
  }),
  branch(
    (props) => props.lessonDownloadStatus !== LESSON_DOWNLOAD_STATUS_DOWNLOADED,
    renderComponent(PreviewLoader)
  )
);
