import React from 'react';
import PropTypes from 'prop-types';

import { compose, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Input from '~/components/Input';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('login.enterCorrectEmail')
    .required('login.enterEmail'),
  password: Yup.string()
    .min(6, 'login.shortPassword')
    .required('login.enterPassword'),
});

const Login = ({
  t,
  values,
  isSubmitting,
  setFieldValue,
  getFieldLabel,
  getFieldVariant,
  handleSubmit,
}) => (
  <form className="Login">
    <Input
      key="email"
      type="text"
      name="email"
      autocomplete="home email"
      large
      value={values.email}
      label={values.email ? getFieldLabel('email') : null}
      variant={getFieldVariant('email')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('email', value)}
      placeholder={t('login.email')}
      onPressEnter={handleSubmit}
    />

    <Input
      key="password"
      type="password"
      autocomplete="on"
      large
      value={values.password}
      label={values.password ? getFieldLabel('password') : null}
      variant={getFieldVariant('password')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('password', value)}
      placeholder={t('login.password')}
      onPressEnter={handleSubmit}
      withVisibilityToggler
    />
  </form>
);

const { func, bool, shape, string } = PropTypes;

Login.propTypes = {
  t: func.isRequired,
  values: shape({
    email: string.isRequired,
    password: string.isRequired,
  }).isRequired,
  setFieldValue: func.isRequired,
  getFieldLabel: func.isRequired,
  getFieldVariant: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired, // Идет запрос на сервер?
};

export default compose(
  withTranslation('containersAuth'),
  withHandlers({
    getFieldLabel: ({ t, errors, touched }) => (name) =>
      t(errors[name] && touched[name] ? errors[name] : `login.${name}`),
    getFieldVariant: ({ errors, touched }) => (name) =>
      errors[name] && touched[name] ? 'error' : undefined,
  })
)(Login);
