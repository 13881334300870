import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import withTheming from '~/hocs/withTheming';
import Button from '~/components/Button';
import { LESSON_TYPE_REGULAR, LESSON_TYPE_EXAM } from '~/appConstants';
import Emoji, { ALERT, HAPPY, UNHAPPY, BLOCKED } from '~/components/Emoji';
import Auth from './Auth';
import { combineStyles } from '../../utils/styles';
import '../../styles/Player/Alert.scss';
import Overlay from '~/components/Overlay';
import Text from '~/components/Text';
import Hyperlink from '~/components/Hyperlink';
import Link from '~/components/Link';

const Alert = ({
  t,
  theme,
  isOpen,
  // onClose,
  fontSize,
  courseUrl,
  inSafeMode,
  lessonType,
  isLtiLesson,
  ltiReturnUrl,
  onCloseWindow,
  goToNextLesson,
  isLessonBlocked,
  nextCourseLessonUrl,
  isAuthAfterComplete,
  isExamSuccessComplete,
}) => {
  const [retryAlert] = useState(false);

  return (
    <Overlay isOpen={isOpen}>
      {!retryAlert ? (
        <div
          className={combineStyles('Alert', inSafeMode && ['safe-mode', theme])}
        >
          {isLessonBlocked ? (
            <div className="emoji">
              <Emoji symbol={BLOCKED} label="blocked" />
            </div>
          ) : lessonType === LESSON_TYPE_REGULAR || isExamSuccessComplete ? (
            <div className="emoji">
              <Emoji symbol={HAPPY} label="happy" />
            </div>
          ) : (
            <div className="emoji">
              <Emoji symbol={UNHAPPY} label="unhappy" />
            </div>
          )}

          <div className="text">
            <Text
              value={
                isLessonBlocked
                  ? t('alert.lessonBlocked')
                  : isLtiLesson ||
                    lessonType === LESSON_TYPE_REGULAR ||
                    isExamSuccessComplete
                  ? t('alert.lessonPassed')
                  : t('alert.lessonNotPassed')
              }
              variant={inSafeMode ? ['welcome', fontSize, theme] : 'welcome'}
            />
          </div>

          {isLessonBlocked && (
            <div className="subtext">{t('alert.blockedLessonSubtext')}</div>
          )}

          {isAuthAfterComplete && !isLtiLesson && (
            <div className="auth">
              <Auth isAuthAfterComplete={isAuthAfterComplete} />
            </div>
          )}

          {ltiReturnUrl && (
            <div className="button">
              <Hyperlink
                newTab
                href={ltiReturnUrl}
                variant={['lti-return', 'in-modal']}
                text={t('index.toLtiCabinet')}
              />
            </div>
          )}
          {!isAuthAfterComplete && (
            <div className="button">
              {courseUrl ? (
                <>
                  <Link
                    to={courseUrl}
                    text={t('alert.goToCourse')}
                    variant="player-alert"
                  />

                  {lessonType === LESSON_TYPE_EXAM
                    ? isExamSuccessComplete &&
                      nextCourseLessonUrl && (
                        <Button
                          onClick={() => {
                            goToNextLesson();
                            onCloseWindow(false);
                          }}
                          minimal
                          text={t('alert.goToNextLesson')}
                          variant="player-alert-transparent"
                        />
                      )
                    : nextCourseLessonUrl && (
                        <Button
                          onClick={() => {
                            goToNextLesson();
                            onCloseWindow(false);
                          }}
                          minimal
                          text={t('alert.goToNextLesson')}
                          variant="player-alert-transparent"
                        />
                      )}
                </>
              ) : (
                <Button
                  minimal
                  text={
                    isLessonBlocked ? t('alert.ok') : t('alert.goToResults')
                  }
                  variant={
                    isLtiLesson
                      ? inSafeMode
                        ? ['player-alert-minimal', fontSize, theme]
                        : 'player-alert-minimal'
                      : inSafeMode
                      ? ['player-alert', fontSize, theme]
                      : 'player-alert'
                  }
                  onClick={() => onCloseWindow(false)}
                />
              )}
            </div>
          )}
          {/* {!isLtiLesson && !isLessonBlocked && (
            <div className="button">
              <Button
                text={t('alert.retry')}
                variant={
                  inSafeMode
                    ? ['player-alert-minimal', fontSize, theme]
                    : 'player-alert-minimal'
                }
                onClick={courseUrl ? () => showRetryAlert(true) : onClose}
              />
            </div>
          )} */}
        </div>
      ) : (
        <div
          className={combineStyles('Alert', inSafeMode && ['safe-mode', theme])}
        >
          <div className="emoji">
            <Emoji symbol={ALERT} label={alert} />
          </div>
          {/* <div className="retry">
            <Text value={t('alert.lessonRetryMessage')} />
          </div>
          <div className="button">
            <Button
              minimal
              text={t('alert.retry')}
              variant={
                inSafeMode ? ['player-alert', fontSize, theme] : 'player-alert'
              }
              onClick={() => {
                onClose();
                showRetryAlert(false);
              }}
            />
          </div> */}
          <div className="button">
            <Link
              to={courseUrl}
              text={t('alert.goToCourse')}
              variant="player-alert-small"
            />
          </div>
        </div>
      )}
    </Overlay>
  );
};

const { bool, func, string } = PropTypes;

Alert.propTypes = {
  t: func.isRequired, // Функция перевода
  theme: string, // Цветовая тема в режиме ОВЗ
  isOpen: bool, // Окно открыто?
  // onClose: func.isRequired, // Действие при закрытии окна
  fontSize: string, // Размер шрифта в режиме ОВЗ
  courseUrl: string,
  inSafeMode: bool.isRequired, // В режиме ОВЗ?
  lessonType: string,
  isLtiLesson: bool, // Урок с доступом по LTI?
  ltiReturnUrl: string, // Ссылка возврата на платформу при доступе по LTI
  onCloseWindow: func.isRequired, // Флаг локального переключения видимости окна
  goToNextLesson: func,
  isLessonBlocked: bool,
  nextCourseLessonUrl: string, // URL следующего урока в курсе
  isAuthAfterComplete: bool.isRequired, // Авторизация предложена после завершения урока?
  isExamSuccessComplete: bool, // контрольный урок пройден успешно?
};

Alert.defaultProps = {
  isOpen: false,
  isLtiLesson: false,
};

export default compose(
  withTranslation('containersPlayer'),
  withTheming
)(Alert);
