import { compose, keys, pull, set, update } from 'lodash/fp';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  REQUEST_END,
  REQUEST_START,
  SET_IS_MOBILE,
  SHOW_NOT_FOUND_PAGE,
  CLOSE_VERIFICATION_ALERT,
} from '~/actions/ui';

import { toggle, uniqPush } from '../utils/array';
import * as BUILDER from '../constants/builder';
import * as AUTH from '~/constants/auth';

export const defaultState = {
  builder: {
    persistingLessonsIds: [], // ID уроков, находящихся в процессе сохранения
    unsavedLessonsIds: [], // ID уроков, которые имеют несохраненные изменения
    uploadedLessonsIds: [], // ID уроков загруженных на сервер
    isLessonSidebarOpen: true, // Сайдбар в уроке конструктора открыт?
    isOffline: false, // Пользователь в оффлайн режиме?
    isHintsEnabled: false, // Подсказки по интерфейсу включены?
    inBirdViewLessonsIds: [], // ID уроков в бёрдвью
  },
  isMobile: false,
  activeRequests: [],
  isNotFoundPage: false,
  isVerificationAlertVisible: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    // -------------- Конструктор --------------

    case BUILDER.SHOW_HINTS: {
      const { isHintsEnabled } = state.builder;
      return set(['builder', 'isHintsEnabled'], !isHintsEnabled, state);
    }

    case BUILDER.LESSON.SAVE_SUCCESS: {
      const { id } = action.payload;
      return compose(
        update(['builder', 'uploadedLessonsIds'], uniqPush(id)),
        update(['builder', 'unsavedLessonsIds'], pull(id)),
        update(['builder', 'persistingLessonsIds'], pull(id)),
        set(['builder', 'isOffline'], false)
      )(state);
    }

    case BUILDER.LESSON.REMOVE_SUCCESS: {
      const { id } = action.payload;
      return compose(
        update(['builder', 'uploadedLessonsIds'], pull(id)),
        update(['builder', 'unsavedLessonsIds'], pull(id)),
        update(['builder', 'persistingLessonsIds'], pull(id))
      )(state);
    }

    case BUILDER.DOWNLOAD_LESSON_SUCCESS:
    case BUILDER.DOWNLOAD_LESSONS_SUCCESS:
    case BUILDER.DOWNLOAD_COURSE_SUCCESS: {
      const { downloadedLessons } = action.payload;
      return update(
        ['builder', 'uploadedLessonsIds'],
        uniqPush(keys(downloadedLessons)),
        state
      );
    }

    case BUILDER.LESSON.ADD: {
      const { id } = action.payload;
      return update(['builder', 'unsavedLessonsIds'], uniqPush(id), state);
    }

    case BUILDER.LESSON.OPEN_SIDEBAR: {
      return set(['builder', 'isLessonSidebarOpen'], true, state);
    }

    case BUILDER.LESSON.CLOSE_SIDEBAR: {
      return set(['builder', 'isLessonSidebarOpen'], false, state);
    }

    case BUILDER.LESSON.SAVE: {
      const { id } = action.payload;
      return update(['builder', 'persistingLessonsIds'], uniqPush(id), state);
    }

    case BUILDER.LESSON.SAVE_ERROR: {
      const { id } = action.payload;
      return compose(
        update(['builder', 'unsavedLessonsIds'], uniqPush(id)),
        update(['builder', 'persistingLessonsIds'], pull(id)),
        set(['builder', 'isOffline'], true)
      )(state);
    }

    case BUILDER.LESSON.TOGGLE_BIRD_VIEW: {
      const { id } = action.payload;
      return update(['builder', 'inBirdViewLessonsIds'], toggle(id), state);
    }

    case SET_IS_MOBILE: {
      const { isMobile } = action.payload;
      return set('isMobile', isMobile, state);
    }

    case REQUEST_START: {
      const { name } = action.payload;
      return update('activeRequests', uniqPush(name), state);
    }

    case REQUEST_END: {
      const { name } = action.payload;
      return update('activeRequests', pull(name), state);
    }

    case SHOW_NOT_FOUND_PAGE: {
      return set('isNotFoundPage', true, state);
    }

    case AUTH.SIGN_IN: {
      const { verified } = action.payload;
      return set('isVerificationAlertVisible', !verified, state);
    }

    case CLOSE_VERIFICATION_ALERT: {
      return set('isVerificationAlertVisible', false, state);
    }

    case LOCATION_CHANGE: {
      const { isFirstRendering } = action.payload;
      if (isFirstRendering) {
        return state;
      }
      return set('isNotFoundPage', false, state);
    }

    default:
      return state;
  }
};

export default uiReducer;
