import { css } from 'styled-components';

const extensionColors = {
  jpg: '#8acf00',
  jpeg: '#8acf00',
  png: '#8acf00',
  gif: '#8acf00',
  pdf: '#d3132f',
  doc: '#2159aa',
  docx: '#2159aa',
  xls: '#4c9315',
  ppt: '#ea6b03',
  pptx: '#ea6b03',
  mp4: '#e6a42f',
  mp3: '#f2c801',
  wav: '#f2c801',
};

export const container = css`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const item = css`
  display: block;
  max-width: 165px;
  min-width: 96px;
  box-sizing: border-box;
  height: 56px;
  border-radius: 4px;
  background: rgba(229, 233, 236, 0.35);
  padding: 16px 16px 16px 60px;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  pointer-events: all;

  ${(props) =>
    props.theme.isMobile &&
    css`
      padding-right: 50px;
      max-width: 190px;
    `}

  > .remove {
    position: absolute;
    top: 8px;
    right: 4px;
    opacity: 0;
    transition: opacity 0.2s ease;

    ${(props) =>
      props.theme.isMobile &&
      css`
        opacity: 1;
      `}
  }

  &:hover > .remove {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
`;

export const itemLink = css`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  > .itemLink {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 !important;
  }
`;

export const extension = css`
  display: block;
  width: 48px;
  height: 56px;
  line-height: 54px;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  color: ${(props) =>
    props.extension && extensionColors[props.extension]
      ? extensionColors[props.extension]
      : '#8acf00'};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.extension && extensionColors[props.extension]
      ? extensionColors[props.extension]
      : '#8acf00'};
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.6px;
`;

export const fileName = css`
  display: block;
  font-family: CirceRounded;
  font-size: 12px;
  color: #767b84;
  padding: 5px 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
