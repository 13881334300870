import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import '~/styles/screens/CoursesMobileInfo.scss';

const CoursesMobileInfo = ({ t }) => (
  <div className="CoursesMobileInfo">
    <div className="info">
      <div className="icon">
        <span role="img" aria-label="crane">
          🏗️
        </span>
      </div>
      <div className="text">{t('coursesBlocked.mobileInfo')}</div>
    </div>
  </div>
);

const { func } = PropTypes;

CoursesMobileInfo.propTypes = {
  t: func.isRequired,
};

export default withTranslation('components')(CoursesMobileInfo);
