import {
  get,
  getOr,
  values,
  orderBy,
  compose,
  entries,
  pickBy,
  omitBy,
  map,
  curry,
  size,
  keys,
  omit,
  filter,
  isUndefined,
  merge,
  includes,
  find,
} from 'lodash/fp';
import memoize from 'fast-memoize';
import { getCoursesList, getTutorLessons } from '~/selectors/tutors';

import { COURSE_STATUS_NOT_ACTIVE, COURSE_STATUS_ACTIVE } from '~/appConstants';

export const getUIPath = (param) =>
  Array.isArray(param) ? ['ui', 'builder', ...param] : ['ui', 'builder', param];

export const getDataPath = (param) =>
  Array.isArray(param) ? ['builder', ...param] : ['builder', param];

export const getLessons = (state) => get(getDataPath('lessons'), state);
const sortLessons = compose(
  map('0'),
  orderBy('1.meta.createdAt', 'desc'),
  entries
);

export const getCourseLessonsIds = (courseId) =>
  getOr([], ['builder', 'courses', courseId, 'lessonsIds']);
export const getCourseLessonsPublications = (courseId) =>
  getOr([], ['builder', 'courses', courseId, 'publications']);
export const getCourseStatus = (courseId) =>
  getOr(COURSE_STATUS_NOT_ACTIVE, ['builder', 'courses', courseId, 'status']);

export const getUserSortedFolderLessonsIds = memoize(
  (userId, folderId, state) => {
    const allLessons = getLessons(state);

    const allFolderLessons = pickBy(
      (lesson) => lesson.meta && lesson.meta.folderId === folderId,
      allLessons
    );

    const lessonsWithoutFolder = omitBy(get('meta.folderId'), allLessons);
    const sortedLessons = folderId ? allFolderLessons : lessonsWithoutFolder;

    return compose(
      sortLessons,
      pickBy(
        (lesson) =>
          lesson.meta &&
          lesson.meta.creatorId === userId &&
          !lesson.meta.courseId
      )
    )(sortedLessons);
  }
);

export const getLesson = (lessonId, state) => get(lessonId, getLessons(state));
export const getBlocks = (state) => get(getDataPath('blocks'), state);
export const getBlock = curry((blockId, state) =>
  get(blockId, getBlocks(state))
);
export const getPages = (state) => get(getDataPath('pages'), state);
export const getPage = (pageId, state) => get(pageId, getPages(state));
export const isOffline = (state) => get(getUIPath(['isOffline']), state);

export const getFolders = (state) => get(getDataPath('folders'), state);

export const getCourses = get(['builder', 'courses']);
export const getActiveCoursesCount = compose(
  size,
  filter({ status: COURSE_STATUS_ACTIVE }),
  get(['builder', 'courses'])
);

export const getCourse = curry((id, state) =>
  get(['builder', 'courses', id], state)
);

export const getSortedCourses = compose(
  orderBy('modified', 'desc'),
  values,
  get(getDataPath('courses'))
);

export const isHintsEnabled = (state) =>
  get(getUIPath(['isHintsEnabled']), state);
export const isPageInExamMode = (pageId, state) =>
  get('inExamMode', getPage(pageId, state));

const selectAllCourses = (state) =>
  merge(getCoursesList(state), getCourses(state));

export const selectCourseSelectOptions = curry((t, state) => [
  { id: null, name: t('review.filter.allCourses') },
  { id: undefined, name: t('review.filter.allLessonsOutsideOfCourses') },
  ...map(
    (course) => ({ id: course.id, name: course.name }),
    selectAllCourses(state)
  ),
]);

const selectCourseIdForLesson = curry((lessonId, state) =>
  compose(
    get('id'),
    find((course) => includes(lessonId, get('lessonsIds', course)))
  )(selectAllCourses(state))
);

export const selectLessonSelectOptions = curry((t, courseId, state) => {
  const filterLessons = merge(getTutorLessons(state), getLessons(state));

  const lessonList = compose(
    map((id) => ({
      id,
      name: get([id, 'content', 'name'], filterLessons),
      parentId: selectCourseIdForLesson(id, state),
    })),
    keys,
    omit([undefined])
  )(filterLessons);

  const lessons =
    courseId || isUndefined(courseId)
      ? filter({ parentId: courseId }, lessonList)
      : lessonList;

  return [
    { id: null, name: t('review.filter.allLessons'), parentId: courseId },
    ...lessons,
  ];
});
