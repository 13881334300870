/* eslint-disable fp/no-this */
import CryptoJS from 'crypto-js';

// eslint-disable-next-line fp/no-class
class Encryption {
  get encryptMethodLength() {
    const { encryptMethod } = this;
    const aesNumber = encryptMethod.match(/\d+/)[0];
    return parseInt(aesNumber, 10);
  }

  get encryptKeySize() {
    const aesNumber = this.encryptMethodLength;
    return parseInt(aesNumber / 8, 10);
  }

  // eslint-disable-next-line class-methods-use-this,lodash/prefer-constant,lodash-fp/prefer-constant
  get encryptMethod() {
    return 'AES-256-CBC';
  }

  decrypt(encryptedString, key) {
    const json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    );

    const salt = CryptoJS.enc.Hex.parse(json.salt);
    const iv = CryptoJS.enc.Hex.parse(json.iv);

    const encrypted = json.ciphertext; // no need to base64 decode.

    // eslint-disable-next-line fp/no-let
    let iterations = parseInt(json.iterations, 10);
    if (iterations <= 0) {
      // eslint-disable-next-line fp/no-mutation
      iterations = 999;
    }
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encrypt(string, key) {
    const iv = CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 999;
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(string, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv,
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    const output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations,
    };

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
  }
}

export default Encryption;
