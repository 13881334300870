import React from 'react';
import PropTypes from 'prop-types';

import { pure, compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import { get, last } from 'lodash/fp';

import withMobileDetect from '~/hocs/withMobileDetect';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { combineStyles } from '../utils/styles';
import { formatCardDate } from '../utils/format';
import { TLesson } from '../@types';
import '../styles/CourseLessonThumbnail.scss';
import Text from '~/components/Text';
import { DraggedButton as Button } from '~/components/Button';
import Icon from '~/components/Icon';
import Share from '~/containers/Share';
import Controls from '~/components/Controls';
import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_REGULAR,
  LESSON_TYPE_OLYMPIAD,
} from '~/appConstants';

const iconLessonType = {
  [LESSON_TYPE_EXAM]: 'osh-lesson-exam',
  [LESSON_TYPE_REGULAR]: 'osh-lesson-regular',
  [LESSON_TYPE_OLYMPIAD]: 'osh-lesson-olympiad',
};

const CourseLessonThumbnail = ({
  t,
  id,
  i18n,
  edit,
  save,
  clone,
  index,
  remove,
  lesson,
  isMobile,
  lessonType,
  isDragging,
  isShareOpen,
  isMoveToOpen,
  toggleMoveTo,
  toggleShareOpen,
  publicationTime,
  isLessonUpdated,
  connectDragSource,
  connectDropTarget,
  connectDragPreview,
}) => {
  if (!lesson) return null
  const {
    meta: { courseId, type },
    content: { name },
  } = lesson
  return connectDragPreview(
    connectDropTarget(
      <div
        key={id}
        className={combineStyles('CourseLessonThumbnail', [
          isMobile && 'mobile',
          isDragging && 'dragging',
        ])}
        onClick={!isMobile ? () => edit(id) : undefined}
      >
        <div className={combineStyles('number', type)}>{index + 1}</div>
        <div className="content">
          <div className="title">{name}</div>
          <div className="status">
            {!publicationTime ? (
              <Text
                variant="course-thumbnail-red"
                value={t('lessonthumbnails.notPublished')}
              />
            ) : isLessonUpdated ? (
              <Text
                variant="course-thumbnail-red"
                value={t('lessonthumbnails.updated')}
              />
            ) : (
              <Text
                variant="course-thumbnail-grey"
                value={`${t('lessonthumbnails.publishedAt')} (${formatCardDate(
                  publicationTime,
                  i18n
                )})`}
              />
            )}
          </div>
        </div>
        <div className="buttons" onClick={(e) => e.stopPropagation()}>
          <div className="share">
            <Button
              minimal
              icon="osh-share"
              variant="share-thumbnail-course-toggler"
              onClick={() => toggleShareOpen(!isShareOpen)}
            />
          </div>
          {connectDragSource(
            <div>
              <Controls
                usePortal
                type="dropdown"
                lessonId={id}
                courseId={courseId}
                isMoveToOpen={isMoveToOpen}
                toggleMoveTo={toggleMoveTo}
                saveLesson={() => save(id, t('lessonthumbnails.moveSuccess'))}
                items={[
                  {
                    icon: 'osh-edit',
                    text: t('lessonthumbnails.edit'),
                    action: () => edit(id),
                  },
                  {
                    icon: 'osh-duplicate',
                    text: t('lessonthumbnails.dublicate'),
                    action: () => clone({ id, courseId }),
                  },
                  {
                    icon: 'osh-duplicate',
                    noDismissPopoverOnClick: true,
                    text: t('lessonthumbnails.moveTo'),
                    action: () => toggleMoveTo(true),
                  },
                  {
                    icon: 'osh-trash',
                    text: t('lessonthumbnails.remove'),
                    action: () => remove(id),
                  },
                ].filter(Boolean)}
                variant="lessons"
              />
            </div>
          )}
        </div>

        <div className="type">
          <Icon
            name={iconLessonType[lessonType]}
            variant="lesson-thumbnail-type"
            position="top"
          />
        </div>

        {/*
          ToDo nik вынести окно share из всех тумбнейлов в корень и открывать экшном
          для оптимизации, особенно с флагом lazy=false в Dialog
        */}
        <Share
          lessonId={id}
          icon="osh-share"
          isOpen={isShareOpen}
          lessonName={name}
          close={() => toggleShareOpen(false)}
          text={t('lesson.controlShare')}
        />
      </div>
    )
  );
}

const { string, func, bool, object, number } = PropTypes;

CourseLessonThumbnail.propTypes = {
  t: func.isRequired,
  id: string.isRequired, // ID урока
  i18n: object.isRequired,
  index: number,
  isMobile: bool.isRequired,
  lesson: TLesson, // что же это ?)
  edit: func.isRequired, // Редактировать урок
  clone: func.isRequired, // Копировать урок
  remove: func.isRequired, // Удалить урок
  save: func.isRequired, // Сохранение урока
  lessonType: string,
  isShareOpen: bool, // Окно результатов открыто?
  isMoveToOpen: bool, // Окно перемещения урока открыто?
  toggleMoveTo: func, // Функция открытия окна перемещения урока
  toggleShareOpen: func, // Окно шаринга открыто?
  isLessonUpdated: bool.isRequired,
  publicationTime: number,
};

const mapDispatchToProps = {
  save: actions.builder.lesson.save,
  clone: actions.builder.lesson.clone,
  closeResults: actions.results.close,
  remove: actions.builder.lesson.removeConfirm,
  moveLesson: actions.builder.course.moveLesson,
};

const mapStateToProps = (state, ownProps) => {
  const lesson = selectors.builder.getLesson(ownProps.id, state);
  const lessonType = selectors.builder.lesson.getLessonType(ownProps.id, state);

  return {
    lesson,
    lessonType,
    publicationTime: last(get(['meta', 'publications'], lesson)),
    isLessonUpdated: selectors.builder.lesson.hasUnpublishedChanges(
      ownProps.id
    )(state),
  };
};

// eslint-disable-next-line no-shadow
const collectDragSource = (connect, monitor) => ({
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});

const collectDropTarget = (dndConnect) => ({
  connectDropTarget: dndConnect.dropTarget(),
});

// eslint-disable-next-line fp/no-let
let oldIndex = null;
const dropTarget = {
  drop() {
    // eslint-disable-next-line fp/no-mutation
    oldIndex = null;
  },
  hover(props, monitor) {
    const { courseId: id, moveLesson, index: hoverIndex } = props;

    const item = monitor.getItem();
    const { lessonId } = item;

    if (oldIndex === null) {
      // eslint-disable-next-line fp/no-mutation
      oldIndex = item.index;
    }
    const newIndex = hoverIndex + (hoverIndex > oldIndex ? 1 : 0);

    if (oldIndex === newIndex - 1 || oldIndex === newIndex) {
      return;
    }

    moveLesson({ id, lessonId, oldIndex, newIndex });
    // eslint-disable-next-line fp/no-mutation
    oldIndex = oldIndex < newIndex ? newIndex - 1 : newIndex;
  },
};

export default compose(
  withRouter,
  withTranslation('components'),
  withMobileDetect,
  withState('isShareOpen', 'toggleShareOpen', false),
  withState('isMoveToOpen', 'toggleMoveTo', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    edit: ({ history, closeResults }) => (lessonId) => {
      history.push(`/builder/lesson/${lessonId}`);
      closeResults();
    },
  }),
  DragSource(
    'lesson',
    {
      beginDrag: (props) => ({
        type: 'lesson',
        index: props.index,
        lessonId: props.id,
      }),
      endDrag: () => {
        // eslint-disable-next-line fp/no-mutation
        oldIndex = null;
      },
    },
    collectDragSource
  ),
  DropTarget(['lesson'], dropTarget, collectDropTarget),
  pure
)(CourseLessonThumbnail);
