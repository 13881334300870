import { compose, set, unset } from 'lodash/fp';
import * as PLAYER from '../../constants/player';

export default (state = null, action) => {
  switch (action.type) {
    case PLAYER.DOWNLOAD_USER_EXECUTION_SUCCESS: {
      const { result } = action.payload;
      return compose(
        set('result', result),
        set('isDownloading', false)
      )(state);
    }

    case PLAYER.HIDE_OFFER_NEW_VERSION:
    case PLAYER.CLEAR_USER_EXECUTION: {
      return compose(
        unset('result'),
        set('isDownloading', false)
      )(state);
    }

    case PLAYER.DOWNLOAD_PREVIOUS_EXECUTION: {
      return compose(
        unset('result'),
        set('isDownloading', true)
      )(state);
    }

    default:
      return state;
  }
};
