import { css } from 'styled-components';

export const container = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: ${(props) => (props.theme.isMobile ? 'flex-start' : 'center')};
  justify-content: ${(props) =>
    props.theme.isMobile ? 'flex-start' : 'center'};
  padding: ${(props) => (props.theme.isMobile ? '16px' : '10px 20px')};
  background: #f25200;
`;
