import {
  assign,
  compose,
  concat,
  first,
  get,
  includes,
  indexOf,
  isNumber,
  keys,
  map,
  max,
  omit,
  omitBy,
  pull,
  pullAt,
  set,
  update,
} from 'lodash/fp';
import objectIdGenerator from 'bson-objectid';

import {
  LESSON_TYPE_EXAM,
  LESSON_TYPE_OLYMPIAD,
  UPLOAD_TYPE_LESSON,
} from '~/appConstants';
import { insertAt, push } from '~/utils/array';
import * as helpers from '~/helpers';
import uploaderReducer from '~/reducers/uploader';
import * as PAGE from '~/constants/builder/page';
import * as LESSON from '~/constants/builder/lesson';
import * as FOLDER from '~/constants/builder/folder';
import * as COURSE from '~/constants/builder/course';
import * as BUILDER from '~/constants/builder';
import * as UPLOADER from '~/constants/uploader';

export const defaultState = {};

const defaultLessonTimeLimit = 900; // Дефолтное время выполнения урока в секундах
export const minLessonTimeLimit = 60; // Минимально возможно время выполнения урока в секундах

const createLesson = (
  creatorId,
  pagesIds,
  createdAt,
  folderId,
  courseId,
  type
) => ({
  // Данные, которые являются общими для всех публикаций урока и не меняются
  courseId,
  meta: {
    code: undefined, // Уникальный код урока
    endDate: undefined, // Дата, до которой нужно пройти урок
    creatorId, // ID создателя урока
    createdAt, // Дата создания урока
    folderId, // ID папки, в которой находится урок
    courseId,
    type, // Тип урока
    startDate: undefined, // Дата, начиная с которой можно будет проходить урок
    timeLimit: undefined, // Ограничение по времени выполнения урока в секундах
    withDateRange: false, // C ограничением дат выполнения
    ltiAccess: true, // По дефолту урок доступен только по LTI
    publications: [], // Массив с датами уже сделанных публикаций
    settings: {
      minimalSuccessScore: type === LESSON_TYPE_EXAM ? 80 : undefined,
      olympiad:
        type === LESSON_TYPE_OLYMPIAD
          ? {
              authTitle: 'settings.defaultAuthTitle',
              authImage: {},
              buttonText: 'settings.defaultButtonText',
              descriptionInResult: true,
              authDescription: {
                isShow: true,
                text: 'settings.defaultAuthDescription',
              },
              partnersLogos: {},
              levels: [
                {
                  id: objectIdGenerator.generate(),
                  minScore: 0,
                  maxScore: 1,
                  subtitle: 'settings.defaultResultSubtitle',
                  text: 'settings.defaultResultText',
                  image: {},
                },
              ],
            }
          : undefined,
    },
    hasUnpublishedChanges: true,
  },
  // Данные, которые могут меняться в разных версиях публикации
  content: {
    name: undefined, // Имя урока
    pagesIds, // Список ID страниц урока
    description: undefined, // Описание урока,
  },
});

const applyUploaderReducer = (objectId, state, action, filePath) =>
  set(
    filePath ? [...filePath, 'uploader'] : [objectId, 'content', 'uploader'],
    uploaderReducer(
      get(
        filePath
          ? [...filePath, 'uploader']
          : [objectId, 'content', 'uploader'],
        state
      ),
      action
    )
  );

const lessonsReducer = (state = defaultState, action) => {
  switch (action.type) {
    // -------------- Уроки --------------

    case LESSON.ADD: {
      const {
        payload: { id, folderId, courseId, type },
        meta: { pageId, creatorId, createdAt },
      } = action;
      return set(
        id,
        createLesson(creatorId, [pageId], createdAt, folderId, courseId, type),
        state
      );
    }

    case LESSON.CLONE: {
      const {
        meta: { clonedLesson },
      } = action;
      return assign(state, clonedLesson);
    }

    case LESSON.MOVE: {
      const { lessonId, folderId } = action.payload;
      if (includes(folderId, state[lessonId].meta)) {
        return set([lessonId, 'meta', 'folderId'], '', state);
      } else {
        return set([lessonId, 'meta', 'folderId'], folderId, state);
      }
    }

    case LESSON.PUBLISH_SUCCESS: {
      const { id, publications, publicationsIds } = action.payload;
      return compose(
        set([id, 'meta', 'publications'], publications),
        set([id, 'meta', 'publicationsIds'], publicationsIds),
        set([id, 'meta', 'hasUnpublishedChanges'], false)
      )(state);
    }

    case LESSON.CHANGE_NAME: {
      const { id, newName } = action.payload;
      return set([id, 'content', 'name'], newName, state);
    }

    case LESSON.SAVE_SUCCESS: {
      const { id, code } = action.payload;
      return set([id, 'meta', 'code'], code, state);
    }

    case LESSON.REMOVE_SUCCESS: {
      const { id } = action.payload;
      return omit(id, state);
    }

    case LESSON.TOGGLE_TIME_LIMIT: {
      const { id } = action.payload;
      const timeLimitPath = [id, 'meta', 'timeLimit'];
      return set(
        timeLimitPath,
        get(timeLimitPath, state) ? undefined : defaultLessonTimeLimit,
        state
      );
    }

    case LESSON.TOGGLE_LESSON_LTI_ACCESS: {
      const { id, isLessonLtiAccess } = action.payload;
      return set([id, 'meta', 'ltiAccess'], !isLessonLtiAccess, state);
    }

    case LESSON.CHANGE_TIME_LIMIT: {
      const { id, newTimeLimit } = action.payload;
      return set(
        [id, 'meta', 'timeLimit'],
        newTimeLimit >= minLessonTimeLimit
          ? newTimeLimit
          : get([id, 'meta', 'timeLimit'], state),
        state
      );
    }

    case LESSON.TOGGLE_DATE_RANGE: {
      const { id } = action.payload;
      const withDateRange = get([id, 'meta', 'withDateRange'], state);
      return compose(
        set([id, 'meta', 'withDateRange'], !withDateRange),
        set([id, 'meta', 'endDate'], null),
        set([id, 'meta', 'startDate'], null)
      )(state);
    }

    case LESSON.CHANGE_DATE_RANGE: {
      const { id, newEndDate, newStartDate } = action.payload;
      return compose(
        set(
          [id, 'meta', 'endDate'],
          newEndDate ? helpers.lesson.convertTimestamp(newEndDate) : null
        ),
        set(
          [id, 'meta', 'startDate'],
          newStartDate ? helpers.lesson.convertTimestamp(newStartDate) : null
        )
      )(state);
    }

    case LESSON.CHANGE_DESCRIPTION: {
      const { id, newDescription } = action.payload;
      return set([id, 'content', 'description'], newDescription, state);
    }

    case LESSON.CHANGE_EXTERNAL_LINK: {
      const { id, newExternalLink } = action.payload;
      return set(
        [id, 'meta', 'settings', 'externalLink'],
        newExternalLink,
        state
      );
    }

    case LESSON.IMPORT_FROM_PLAYER: {
      const {
        meta: { clonedLesson },
      } = action;
      return assign(clonedLesson, state);
    }

    case LESSON.CHANGE_MINIMAL_SUCCESS_SCORE: {
      const { id, score } = action.payload;
      return set([id, 'meta', 'settings', 'minimalSuccessScore'], score)(state);
    }

    case LESSON.CHANGE_OLYMPIAD_SETTINGS: {
      const { id, path, value } = action.payload;
      const fullPath = concat([id, 'meta', 'settings', 'olympiad'], path);
      return set(fullPath, value, state);
    }

    case LESSON.ADD_OLYMPIAD_LEVELING_INTERVAL: {
      const { lessonId } = action.payload;
      const maxScore = compose(
        max,
        map('maxScore'),
        get([lessonId, 'meta', 'settings', 'olympiad', 'levels'])
      )(state);
      return update(
        [lessonId, 'meta', 'settings', 'olympiad', 'levels'],
        push({
          id: objectIdGenerator.generate(),
          minScore: +maxScore + 1,
          maxScore: +maxScore + 2,
          subtitle: 'settings.defaultResultSubtitle',
          text: 'settings.defaultResultText',
          image: {},
        }),
        state
      );
    }

    case LESSON.REMOVE_OLYMPIAD_LEVELING_INTERVAL: {
      const { lessonId, index } = action.payload;

      return update(
        [lessonId, 'meta', 'settings', 'olympiad', 'levels'],
        (levels) => levels.filter((_, i) => i !== index),
        state
      );
    }

    case LESSON.SET_HAS_UNPUBLISHED_CHANGES: {
      const { id } = action.payload;
      return set([id, 'meta', 'hasUnpublishedChanges'], true, state);
    }

    // -------------- Страницы --------------

    case PAGE.ADD: {
      const { id, index, lessonId } = action.payload;
      return update(
        [lessonId, 'content', 'pagesIds'],
        isNumber(index) ? insertAt(index, id) : push(id),
        state
      );
    }

    case PAGE.MOVE: {
      const { id, lessonId, oldIndex, newIndex } = action.payload;
      return update(
        [lessonId, 'content', 'pagesIds'],
        oldIndex > newIndex
          ? compose(
              insertAt(newIndex, id),
              pullAt(oldIndex)
            )
          : compose(
              pullAt(oldIndex),
              insertAt(newIndex, id)
            )
      )(state);
    }

    case PAGE.CLONE: {
      const {
        payload: { id, lessonId },
        meta: { clonedPage },
      } = action;
      const clonedPageId = first(keys(clonedPage));
      const insertionIndex =
        indexOf(id, get([lessonId, 'content', 'pagesIds'], state)) + 1;
      return update(
        [lessonId, 'content', 'pagesIds'],
        insertAt(insertionIndex, clonedPageId),
        state
      );
    }

    case PAGE.PASTE: {
      const {
        payload: { lessonId },
        meta: { storedPages },
      } = action;
      return update(
        [lessonId, 'content', 'pagesIds'],
        push(keys(storedPages)),
        state
      );
    }

    case PAGE.REMOVE: {
      const { id, lessonId } = action.payload;
      return update([lessonId, 'content', 'pagesIds'], pull(id), state);
    }

    case FOLDER.REMOVE_SUCCESS: {
      const { id: folderId } = action.payload;
      return omitBy((lesson) => lesson.meta.folderId === folderId, state);
    }

    case COURSE.REMOVE_SUCCESS: {
      const { id: courseId } = action.payload;
      return omitBy((lesson) => lesson.meta.courseId === courseId, state);
    }

    // -------------- Конструктор --------------

    case BUILDER.DOWNLOAD_LESSON_SUCCESS: {
      const { downloadedLessons } = action.payload;
      return assign(downloadedLessons, state);
    }

    case BUILDER.DOWNLOAD_COURSE_SUCCESS:
    case BUILDER.DOWNLOAD_LESSONS_SUCCESS: {
      const { downloadedLessons } = action.payload;
      return downloadedLessons;
    }

    // -------------- Загрузчик файлов --------------

    case UPLOADER.UPLOAD_SUCCESS: {
      const {
        objectId,
        source,
        filePath,
        uploadType,
        pathLastName,
        inPlayer,
      } = action.payload;

      if (uploadType !== UPLOAD_TYPE_LESSON) return state;

      const updateFilePath = pathLastName
        ? concat([...filePath], pathLastName)
        : [...filePath];

      if (inPlayer) {
        return state;
      } else {
        return compose(
          set(
            updateFilePath
              ? [...updateFilePath, 'source']
              : [objectId, 'content', 'source'],
            source
          ),
          applyUploaderReducer(
            objectId,
            state,
            action,
            updateFilePath,
            uploadType
          )
        )(state);
      }
    }

    case String(action.type.match(/@uploader\/.*/)): {
      const { objectId, filePath, uploadType } = action.payload;

      if (uploadType !== UPLOAD_TYPE_LESSON) return state;

      /* filePath - путь до ссылки на клиенте
      задается в качестве массива в параметрах компонента Uploader,
      если внутри массива есть 'execution' - загрузка выполнена из плеера
      */
      return applyUploaderReducer(objectId, state, action, filePath)(state);
    }

    default:
      return state;
  }
};

export default lessonsReducer;
