import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import withMobileDetect from '~/hocs/withMobileDetect';
import { combineStyles } from '../../../utils/styles';
import { Icon, Help, HelpRow } from '../../../components';

const Error = ({ t, isMobile }) => (
  <div className={combineStyles('Player', isMobile && 'mobile')}>
    <Helmet>
      <title>{t('index.downloadError')}</title>
    </Helmet>
    <div className="error">
      <Icon name="warning-sign" variant="player-error" />
    </div>
    {isMobile ? (
      <HelpRow inPlayer />
    ) : (
      <Help inPlayer fixed variant="help-in-folder" />
    )}
  </div>
);

const { bool, func } = PropTypes;

Error.propTypes = {
  t: func.isRequired,
  isMobile: bool.isRequired,
};

export default compose(
  withTranslation('containersPlayer'),
  withMobileDetect
)(Error);
