import React from 'react';
import PropTypes from 'prop-types';

import { compose, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import withMobileDetect from '~/hocs/withMobileDetect';

import { combineStyles } from '../../utils/styles';
import '../../styles/Auth/Register.scss';
import Input from '~/components/Input';
import Checkbox from '~/components/Checkbox';

import * as s from './styles/Register.styles';

/* eslint-disable more/no-numeric-endings-for-variables */
const getTermsLabel = (t) => {
  const termsText1 = t('register.terms_text_1');
  const termsLink = t('register.terms_link');
  const termsText2 = t('register.terms_text_2');
  return `${termsText1} <a href="https://coreapp.ai/docs/offer.pdf" target="_blank">${termsLink}</a> ${termsText2}`;
};
/* eslint-enable more/no-numeric-endings-for-variables */

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('login.enterCorrectEmail')
    .required('login.enterEmail'),
  password: Yup.string()
    .min(6, 'login.shortPassword')
    .required('login.enterPassword'),
  lastName: Yup.string().required('register.enterLastName'),
  firstName: Yup.string().required('register.enterFirstName'),
  termsAgree: Yup.boolean().oneOf([true], 'register.checkTermsAgree'),
});

const Register = ({
  t,
  values,
  isMobile,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  getFieldLabel,
  getFieldVariant,
}) => (
  <div className={combineStyles('Register', isMobile && 'mobile')}>
    <Input
      key="email"
      type="email"
      name="email"
      autocomplete="on"
      large
      value={values.email || ''}
      label={getFieldLabel('email')}
      variant={getFieldVariant('email')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('email', value)}
      placeholder={t('register.email')}
      onPressEnter={handleSubmit}
    />
    <Input
      key="password"
      type="password"
      autocomplete="on"
      large
      value={values.password || ''}
      label={getFieldLabel('password')}
      variant={getFieldVariant('password')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('password', value)}
      placeholder={t('register.password')}
      onPressEnter={handleSubmit}
      withVisibilityToggler
    />
    <Input
      key="lastName"
      type="text"
      large
      value={values.lastName || ''}
      label={getFieldLabel('lastName')}
      variant={getFieldVariant('lastName')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('lastName', value)}
      placeholder={t('register.lastName')}
      onPressEnter={handleSubmit}
    />
    <Input
      key="firstName"
      type="text"
      large
      value={values.firstName || ''}
      label={getFieldLabel('firstName')}
      variant={getFieldVariant('firstName')}
      disabled={isSubmitting}
      onChange={(value) => setFieldValue('firstName', value)}
      placeholder={t('register.firstName')}
      onPressEnter={handleSubmit}
    />
    <div className="info" css={s.terms}>
      <Checkbox
        label={getTermsLabel(t)}
        checked={values.termsAgree || false}
        onCheck={() => setFieldValue('termsAgree', !values.termsAgree)}
        variant={['terms-agree', getFieldVariant('termsAgree')]}
      />
    </div>
  </div>
);

const { string, bool, func, shape } = PropTypes;

Register.propTypes = {
  t: func.isRequired,
  values: shape({
    email: string.isRequired,
    password: string.isRequired,
    lastName: string.isRequired,
    firstName: string.isRequired,
    termsAgree: bool.isRequired,
  }).isRequired,
  isMobile: bool.isRequired,
  setFieldValue: func.isRequired,
  getFieldLabel: func.isRequired,
  getFieldVariant: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired, // Идет запрос на сервер?
};

export default compose(
  withTranslation('containersAuth'),
  withMobileDetect,
  withHandlers({
    getFieldLabel: ({ t, errors, touched }) => (name) =>
      t(errors[name] && touched[name] ? errors[name] : `register.${name}`),
    getFieldVariant: ({ errors, touched }) => (name) =>
      errors[name] && touched[name] ? 'error' : undefined,
  })
)(Register);
