import { css } from 'styled-components';

export const container = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const pupil = css`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 34px 0;
  pointer-events: none;
`;

export const header = css`
  margin-top: 20px;

  @media print {
    display: none;
  }
`;

export const footer = css`
  margin: 20px 0;

  @media print {
    display: none;
  }
`;

export const page = css`
  width: 90%;
  pointer-events: none;
`;
