import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '~/actions';
import { apiRequest } from './request';
import { GET_PAYMENT_HISTORY } from '~/actions/profile';

/**
 * Получение истории платежей пользователя
 */
export function* getHistory() {
  yield takeLatest(GET_PAYMENT_HISTORY, function*() {
    const response = yield apiRequest('subscription/history', null, 'get');

    const {
      data: { error, payload },
    } = response;

    if (!error) {
      yield put(
        actions.builder.profile.getHistorySuccess({ history: payload })
      );
    } else {
      yield put(actions.builder.profile.getHistoryError(error));
    }
  });
}
