export const LOGOUT = '@AUTH/Логаут';

export const SEND_REQUEST = '@AUTH/Запрос на сервер';
export const REQUEST_ERROR = '@AUTH/Ошибка на сервере';
export const REQUEST_SUCCESS = '@AUTH/Запрос успешно завершен';
export const REQUEST_FORBIDDEN = '@AUTH/Нет доступа';

export const RECOVERY_REQUEST = '@AUTH/Запрос на восстановление пароля';
export const SEND_NEW_PASSWORD =
  '@AUTH/Отправка нового пароля на восстановление';

export const SIGN_IN = '@AUTH/Сохранение данных логина';

export const SET_ROLE = '@AUTH/Установить роль пользователя';
export const SET_ROLE_ERROR = '@AUTH/Ошибка установки роль пользователя';
export const SET_ROLE_SUCCESS = '@AUTH/Роль пользователя установлена';

export const CHANGE_NAME = '@AUTH/Изменение имени';
export const CHANGE_NAME_SUCCESS = '@AUTH/Имя успешно изменено';

export const VERIFY_EMAIL = '@AUTH/Подтверждение почтового ящика';
export const RESEND_VERIFICATION_EMAIL =
  '@AUTH/Запрос на отправку письма с подтверждением';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  '@AUTH/Письмо с подтверждением отправлено';
